import { isUndefined } from 'lodash-es';

export function ifNotUndefined<T, R>(
  value: T | undefined,
  cb: (value: T) => R
): R | undefined {
  if (isUndefined(value)) {
    return undefined;
  }
  return cb(value);
}

export function nullifyFalsy<T, R>(value: T | undefined) {
  return ifNotUndefined(value, (v) => v || null);
}
