import React, { useState } from 'react';
import clsx from 'clsx';
import { TextField, TextFieldClasses } from '../../TextField/TextField';
import { ActionButton, ActionType } from '../../ActionButton/ActionButton';
import { Loader } from '../../Loader/Loader';
import s from './DocumentSectionTitleEdit.module.scss';

export interface DocumentSectionTitleEditClasses {
  documentSectionTitleEdit?: string;
  textField?: TextFieldClasses;
  actions?: string;
}

export interface DocumentSectionTitleEditProps {
  onCancel: () => void;
  onSubmit: (value: string) => void;
  loading?: boolean;
  classes?: DocumentSectionTitleEditClasses;
  defaultValue?: string;
}

export const DocumentSectionTitleEdit: React.FC<DocumentSectionTitleEditProps> = ({
  defaultValue,
  onCancel,
  onSubmit,
  loading,
  classes
}) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <div
      className={clsx(
        s.DocumentSectionTitleEdit,
        classes?.documentSectionTitleEdit
      )}
    >
      <TextField
        value={value}
        classes={{
          root: clsx(
            s.DocumentSectionTitleEdit__textField,
            classes?.textField?.root
          ),
          input: clsx(
            s.DocumentSectionTitleEdit__input,
            classes?.textField?.input
          ),
          ...classes?.textField
        }}
        onChange={(val) => {
          setValue(val);
        }}
        onFocus={(e) => {
          if (value === 'Новый раздел') {
            e.target.select();
          }
        }}
        autoFocus
      />
      <div
        className={clsx(s.DocumentSectionTitleEdit__actions, classes?.actions)}
      >
        {loading ? (
          <Loader size={20} className={s.DocumentSectionTitleEdit__loader} />
        ) : (
          <ActionButton
            actionType={ActionType.approve}
            disabled={!value}
            onClick={() => {
              if (value) {
                onSubmit(value);
              }
            }}
          />
        )}
        <ActionButton actionType={ActionType.cancel} onClick={onCancel} />
      </div>
    </div>
  );
};
