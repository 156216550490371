import React from 'react';
import clsx from 'clsx';
import s from './TextFieldPreview.module.scss';

export interface TextFieldPreviewClasses {
  root?: string;
  title?: string;
  content?: string;
}

export interface TextFieldPreviewProps {
  className?: string;
  classes?: TextFieldPreviewClasses;
  title?: string;
  textOverflow?: boolean;
  vertical?: boolean;
  simpleView?: boolean;
  children?: React.ReactNode;
}

export const TextFieldPreview: React.FC<TextFieldPreviewProps> = ({
  className,
  classes,
  title,
  textOverflow = true,
  vertical,
  simpleView,
  children
}) => {
  return (
    <div
      className={clsx(s.TextFieldPreview, className, classes?.root, {
        [s.TextFieldPreview_vertical]: vertical,
        [s.TextFieldPreview_simple]: simpleView
      })}
    >
      <div
        className={clsx(s.TextFieldPreview__title, classes?.title, {
          [s.TextFieldPreview__title_simple]: simpleView
        })}
      >
        {title}
      </div>
      <div
        className={clsx(s.TextFieldPreview__content, classes?.content, {
          [s.TextFieldPreview__content_overflow]: textOverflow,
          [s.TextFieldPreview__content_simple]: simpleView
        })}
      >
        {children}
      </div>
    </div>
  );
};
