import { fileDownloadUrl } from '../../config';
import { FileTypeEnum } from '../../store/FileTypeEnum';

export function useFileSrc(
  fileId?: number | string | null,
  type: FileTypeEnum = FileTypeEnum.files
) {
  // todo подумать над авторизацией
  return fileId ? `${fileDownloadUrl}/${type}/${fileId}` : null;
}
