import React from 'react';
import clsx from 'clsx';
import { TableColumn, TableRowData } from '../Table';
import s from './TableHeading.module.scss';

interface TableHeadingClasses {
  row?: string;
  cell?: string;
  actions?: string;
}

interface TableHeadingProps {
  data: TableRowData[];
  columns: TableColumn[];
  hasActions?: boolean;
  classes?: TableHeadingClasses;
}

export const TableHeading: React.FC<TableHeadingProps> = ({
  data,
  columns,
  hasActions,
  classes
}) => {
  return (
    <tr className={clsx(s.TableHeading, classes?.row)}>
      {columns.map((column, iColumn) => {
        const TitleComponent = column.titleComponent;

        return (
          <th
            className={clsx(s.TableHeading__Cell, classes?.cell)}
            key={iColumn}
          >
            {TitleComponent ? (
              <TitleComponent
                data={data}
                field={column.field}
                title={column.title}
              />
            ) : (
              column.title
            )}
          </th>
        );
      })}
      {hasActions && (
        <td className={clsx(s.TableHeading__Cell, classes?.actions)}>
          Действия
        </td>
      )}
    </tr>
  );
};
