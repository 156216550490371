import React, { useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useLocationQuery } from '../../../common/hooks/utils/useLocationQuery';
import { URL_KEY_FILTER_SKILL_IDS, URL_KEY_PAGE } from '../../../store/urlKeys';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { ReactComponent as IconDoc } from '../../../assets/img/icons/Document.svg';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { useUsers } from '../../../graphql/hooks/useUsers';
import { QueryPagination } from '../../../common/components/ui/QueryPagination/QueryPagination';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import {
  getBreadcrumbRouteLink,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { routeLinks } from '../../routeLinks';
import { UserPermission } from '../../../utils/permissions';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { IconButton } from '../../../common/components/ui/IconButton/IconButton';
import { ReactComponent as FilterIcon } from '../../../assets/img/icons/Filter.svg';
import { BottomSheet } from '../../../common/components/ui/BottomSheet/BottomSheet';
import { UsersList } from './UsersList/UsersList';
import { useUsersFilter } from './useUsersFilter';
import { useExportUsersToXlsx } from './useExportUsersToXlsx';
import s from './UsersPage.module.scss';

export function getUsersPageBreadcrumbs(): IBreadcrumbsItem[] {
  return [getBreadcrumbRouteLink(routeLinks.users)];
}

export default function UsersPage({ match }: RouteComponentProps) {
  const [query, locationState] = useLocationQuery([URL_KEY_PAGE] as const);
  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.UserCrud);
  const { isDesktop, isMobile } = useWindowSize();
  const handleSkillSelect = useCallback(
    (id) => {
      locationState.changeQuery({
        [URL_KEY_FILTER_SKILL_IDS]: [id],
        [URL_KEY_PAGE]: 1
      });
    },
    [locationState]
  );

  const [filterComponent, filter, filterSearchComponent] = useUsersFilter({
    showSearchInput: isDesktop
  });

  const page = query[URL_KEY_PAGE] || 1;
  const usersQuery = useUsers({
    filter,
    pagination: { page }
  });
  const users = usersQuery.state.data?.list;
  const header = usersQuery.state.data?.header;
  const hasUsers = users && users.length > 0;
  const [showFilter, setShowFilter] = useState(false);
  const {
    run: exportListToXlsx,
    loading: exportListToXlsxIsLoading
  } = useExportUsersToXlsx({ filter });
  return (
    <PageLayout>
      <LayoutContent>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={isDesktop ? 8 : 12}>
            <PageTitle
              className={s.UsersPage__title}
              title={routeLinks.users.text}
              showHeading={true}
              addButton={
                isMobile ? (
                  <IconButton
                    variant={
                      showFilter
                        ? ButtonVariant.primary
                        : ButtonVariant.secondary
                    }
                    icon={<FilterIcon />}
                    onClick={() => setShowFilter(true)}
                  />
                ) : null
              }
            >
              {!isDesktop && !isMobile && (
                <IconButton
                  variant={
                    showFilter ? ButtonVariant.primary : ButtonVariant.secondary
                  }
                  icon={<FilterIcon />}
                  onClick={() => setShowFilter(true)}
                />
              )}
              <BottomSheet
                title={'Фильтры'}
                open={!isDesktop && showFilter}
                onDismiss={() => setShowFilter(false)}
                snapPoints={({ minHeight }) => minHeight}
              >
                {filterComponent}
              </BottomSheet>
              {canEdit && (
                <div className={s.UsersPage__titleExt}>
                  <Button
                    variant={ButtonVariant.secondary}
                    size={ButtonSize.small}
                    iconLeft={<IconDoc />}
                    onClick={() => exportListToXlsx()}
                    loading={exportListToXlsxIsLoading}
                  >
                    Экспортировать список в xlsx
                  </Button>
                </div>
              )}
            </PageTitle>
            {!isDesktop && filterSearchComponent}
            <div className={s.UsersPage__list}>
              {queryLoader(usersQuery) || (!hasUsers && <NoData />) || (
                <UsersList data={users} onSkillSelect={handleSkillSelect} />
              )}
            </div>
            {(hasUsers || page > 1) && header && (
              <QueryPagination header={header} pathname={routeLinks.users.to} />
            )}
          </LayoutGrid.GridItem>
          {isDesktop && (
            <LayoutGrid.GridItem cols={4}>
              {filterComponent}
            </LayoutGrid.GridItem>
          )}
        </LayoutGrid>
      </LayoutContent>
    </PageLayout>
  );
}
