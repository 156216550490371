import React, { useState } from 'react';
import {
  GridItem,
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import {
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { ReactComponent as PlusIcon } from '../../../assets/img/icons/plus.svg';
import { Tabs } from '../../../common/components/ui/Tabs/Tabs';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { IBreadcrumbsItem } from '../../../common/components/ui/Breadcrumbs';
import { OfferSourcesEnum } from '../../../graphql/types';
import { BasePageInterface } from '../../../common/components/utils/BasePageInterface';
import { routeLinks } from '../../routeLinks';
import { UserPermission } from '../../../utils/permissions';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { URL_KEY_SEARCH } from '../../../store/urlKeys';
import { FilterSearch } from '../../../common/components/ui/Filter/FilterSearch';
import { Modal } from '../../../common/components/ui/Modal/Modal';
import { IconButton } from '../../../common/components/ui/IconButton/IconButton';
import { ReactComponent as IconFilter } from '../../../assets/img/icons/Filter.svg';
import { IconButtonCreate } from '../../../common/components/ui/IconButtonCreate/IconButtonCreate';
import { Offers } from './Offers';
import { useOffersFilter } from './OffersFilter/useOffersFilter';
import s from './OffersPage.module.scss';
import offersFiltersStyles from './OffersFilter/useOffersFilter.module.scss';

const tabs = [
  {
    id: OfferSourcesEnum.Partner,
    title: 'От партнёров'
  },
  {
    id: OfferSourcesEnum.Mipt,
    title: 'Бонусы МФТИ'
  }
];

export interface GetOffersPageBreadcrumbsArgs {
  offersSource?: OfferSourcesEnum;
}

export function getOffersPageBreadcrumbs({
  offersSource
}: GetOffersPageBreadcrumbsArgs): IBreadcrumbsItem[] {
  return [
    {
      name:
        offersSource === OfferSourcesEnum.Mipt
          ? 'Бонусы МФТИ'
          : offersSource === OfferSourcesEnum.Partner
          ? 'Бонусы от партнёров'
          : routeLinks.offers.text,
      path: offersSource
        ? `${routeLinks.offers.to}?tab=${offersSource}`
        : routeLinks.offers.to
    }
  ];
}

export default function OffersPage({ history }: BasePageInterface<{}>) {
  const [filterComponent, filter, handleFilterChange] = useOffersFilter();

  const getTabFilters = (source: OfferSourcesEnum) => {
    return { source, ...filter };
  };

  const tabRenderFns = {
    [OfferSourcesEnum.Partner]: () => (
      <Offers
        filter={getTabFilters(OfferSourcesEnum.Partner)}
        history={history}
      />
    ),
    [OfferSourcesEnum.Mipt]: () => (
      <Offers filter={getTabFilters(OfferSourcesEnum.Mipt)} history={history} />
    )
  };

  function renderTab({ id }) {
    return tabRenderFns[id]?.() || null;
  }

  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.OfferCrud);

  const [isOpen, setOpen] = useState(false);
  const { isTablet, isMobile } = useWindowSize();

  return (
    <PageLayout>
      <LayoutContent>
        <LayoutGrid>
          <GridItem cols={8}>
            <PageTitle
              title={routeLinks.offers.text}
              caption={
                <>
                  Здесь размещены бонусы, уникальные предложения и&nbsp;акции
                  от&nbsp;МФТИ и&nbsp;партнеров университета.
                </>
              }
              className={s.OffersPage__title}
              captionClassName={s.OffersPage__caption}
              showHeading
              addButton={
                isMobile ? <IconButtonCreate to={'/offers/create'} /> : null
              }
            >
              {canEdit && !isMobile && (
                <ButtonLink
                  className={s.OffersPage__addButton}
                  to={'/offers/create'}
                  iconLeft={<PlusIcon />}
                  variant={ButtonVariant.secondary}
                  size={ButtonSize.small}
                >
                  Добавить бонус
                </ButtonLink>
              )}
              {isMobile && (
                <IconButton
                  onClick={() => setOpen(true)}
                  icon={<IconFilter />}
                  variant={ButtonVariant.tertiary}
                  size={ButtonSize.medium}
                />
              )}
            </PageTitle>
            {isMobile && (
              <div className={s.OffersPage__search}>
                <FilterSearch
                  value={filter.search ?? undefined}
                  onChange={handleFilterChange(URL_KEY_SEARCH)}
                  placeholder={'Поиск по бонусам'}
                  className={offersFiltersStyles.OffersFilter__filter}
                  classes={{
                    filter: { content: s.OffersPage__filterRoot },
                    searchField: { root: s.OffersPage__searchField }
                  }}
                />
              </div>
            )}
            <Tabs tabs={tabs} children={renderTab} />
          </GridItem>
          {!isMobile && (
            <GridItem cols={isTablet ? 3 : 4}>{filterComponent}</GridItem>
          )}
        </LayoutGrid>

        {isMobile && (
          <Modal
            className={s.OffersPage__modal}
            title={'Фильтры'}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
          >
            <div className={s.OffersPage__modalContent}>{filterComponent}</div>
          </Modal>
        )}
      </LayoutContent>
    </PageLayout>
  );
}
