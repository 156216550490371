import { useContextStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { useEffect, useRef } from 'react';
import { STORE_LOCATION } from '../../store/storeKeys';

export function useScrollTopOnRouteChange() {
  const locationStore = useContextStore<LocationStore>(STORE_LOCATION);
  const prevPath = useRef<string>(window.location.pathname);
  useEffect(() => {
    const unsubscribe = locationStore.history.listen((location) => {
      if (prevPath.current !== location.pathname) {
        window.scrollTo(0, 0);
      }
      prevPath.current = location.pathname;
    });
    return () => {
      unsubscribe();
    };
  }, [locationStore]);
}
