import gql from 'graphql-tag';

export const QUERY_GET_BASE_PERMISSIONS = gql`
  query queryGetPermissions {
    basePermissions {
      code
      name
      type
    }
  }
`;
