import React, { useMemo } from 'react';
import { EMDASH, joinNonEmpty } from '@proscom/ui-utils';
import { TextFieldPreview } from '../../TextFieldPreview/TextFieldPreview';
import { DegreeType } from '../../../../../graphql/types';
import s from './UserGeneral.module.scss';

export interface UserDegreesProps {
  degrees: DegreeType[];
}

export const UserDegrees: React.FC<UserDegreesProps> = ({ degrees }) => {
  const degreesStr = useMemo(() => {
    return joinNonEmpty(
      degrees.map((d) => d.c_name),
      ', '
    );
  }, [degrees]);

  return (
    <TextFieldPreview
      classes={{
        content: s.UserGeneral__degreesContent
      }}
      title={'Ученая степень, ученое звание'}
      textOverflow={false}
      vertical
    >
      {degreesStr || EMDASH}
    </TextFieldPreview>
  );
};
