import React, { useCallback } from 'react';
import CKEditor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor as CKEditorReact } from '@ckeditor/ckeditor5-react';
import clsx from 'clsx';
import { HEADER_HEIGHT } from '../../../../store/scssExports';
import s from './TextEditor.module.scss';

const defaultToolbarItems = [
  // 'heading',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  // 'highlight',
  '|',
  'link',
  'bulletedlist',
  // 'numberedlist',
  // 'alignment',
  '|',
  // 'blockquote',
  // 'inserttable',
  // 'indent',
  // 'outdent',
  // 'horizontalline',
  // '|',
  'undo',
  'redo'
];
const toolbarWithUploadItems = [
  'heading',
  'bold',
  'italic',
  'underline',
  'link',
  'highlight',
  '|',
  'bulletedlist',
  'numberedlist',
  'alignment',
  '|',
  'imageupload',
  // 'imageResize',
  'image',
  'blockquote',
  'inserttable',
  'indent',
  'outdent',
  'horizontalline',
  '|',
  'undo',
  'redo'
];

const imageConfig = {
  // Configure the available styles.
  styles: ['alignLeft', 'alignCenter', 'alignRight'],

  // Configure the available image resize options.
  resizeOptions: [
    {
      name: 'imageResize:original',
      label: 'Original',
      value: null
    },
    {
      name: 'imageResize:50',
      label: '50%',
      value: '50'
    },
    {
      name: 'imageResize:75',
      label: '75%',
      value: '75'
    }
  ],

  // You need to configure the image toolbar, too, so it shows the new style
  // buttons as well as the resize buttons.
  toolbar: [
    'imageStyle:alignLeft',
    'imageStyle:alignCenter',
    'imageStyle:alignRight',
    '|',
    'imageResize',
    '|',
    'imageTextAlternative'
  ]
};

const defaultConfig = {
  toolbar: {
    items: defaultToolbarItems,
    viewportTopOffset: +HEADER_HEIGHT + 8
  },
  link: {
    addTargetToExternalLinks: true
  },
  disallowedContent: 'script'
  // removePlugins: ['Image']
};

export interface TextEditorProps {
  className?: string;
  value: string;
  onChange: (value: string) => void;
  options?: any;
  // uploadOptions?: { url: string; token: string };
}

export const TextEditor: React.FC<TextEditorProps> = ({
  className,
  value,
  onChange,
  options
  // uploadOptions
}) => {
  const handleChange = useCallback(
    (e, editor) => {
      const data = editor.getData();
      onChange?.(data);
    },
    [onChange]
  );

  // const cfg = useMemo(() => {
  //   const baseCfg = { ...defaultConfig, ...options };
  //   if (uploadOptions) {
  //     baseCfg.toolbar = toolbarWithUpload;
  //     baseCfg.image = imageConfig;
  //     baseCfg.simpleUpload = {
  //       uploadUrl: uploadOptions.url,
  //       withCredentials: true,
  //       headers: {
  //         'X-CSRF-TOKEN': 'CSRF-Token',
  //         Authorization: `Bearer ${uploadOptions.token}`
  //       }
  //     };
  //   }
  //
  //   return baseCfg;
  // }, [options, uploadOptions]);

  return (
    <div className={clsx(s.TextEditor, className)}>
      <CKEditorReact
        editor={CKEditor}
        config={{
          ...defaultConfig,
          ...options
        }}
        data={value}
        onChange={handleChange}
        // onReady={(e) => {
        //   console.log(e)
        // }}
      />
    </div>
  );
};
