import compareVersions from 'compare-versions';

export const isReactNativeApp = () =>
  !!(window && window['ReactNativeWebView']);

export function postMessageNative(type, data) {
  if (
    !window['ReactNativeWebView'] ||
    !window['ReactNativeWebView'].postMessage
  ) {
    return;
  }
  window['ReactNativeWebView'].postMessage(
    JSON.stringify({
      type,
      data
    })
  );
}

export function checkAppVersion(min) {
  return (
    window['nativeAppVersion'] &&
    compareVersions(window['nativeAppVersion'], min) >= 0
  );
}

export const NATIVE_MESSAGE_AUTH = 'auth';
