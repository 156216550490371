import React from 'react';
import { SpecificContentBlockEditableProps } from '../types';
import { TextEditor } from '../../TextEditor/TextEditor';
import { IContentText } from '../../Form/FormContentEditor/Content';

export const TextBlockEditable: React.FC<
  SpecificContentBlockEditableProps<IContentText>
> = ({ data, onChange, className }) => {
  return (
    <TextEditor
      className={className}
      value={data.content || ''}
      onChange={(content) => onChange({ content })}
    />
  );
};
