import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { useUser } from '../../../graphql/hooks/useUser';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { routeLinks } from '../../routeLinks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { Users } from '../../../store/users/Users';
import { UserUtils } from '../../../store/users/UserUtils';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import {
  Breadcrumbs,
  getBreadcrumbItemEntity,
  IBreadcrumbsEntity,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { getUsersPageBreadcrumbs } from '../list/UsersPage';
import { UserForm } from '../../../common/components/ui/UserForm/UserForm';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { UserPermission } from '../../../utils/permissions';
import s from './UserEditPage.module.scss';

export interface UserEditPageProps
  extends RouteComponentProps<{ userId: string }> {
  history: AppHistoryType;
}

export function getUserEditPageBreadcrumbs({
  user
}: {
  user: IBreadcrumbsEntity;
}): IBreadcrumbsItem[] {
  return [
    ...getUsersPageBreadcrumbs(),
    getBreadcrumbItemEntity(routeLinks.users.to, user),
    {
      name: 'Редактирование',
      path: `${routeLinks.users.to}/${user.id}/edit`
    }
  ];
}

export default function UserEditPage({ match, history }: UserEditPageProps) {
  const { userId } = match.params;
  const { user: currentUser } = useCurrentUser();
  const canEditRoles = useHasPermission(currentUser, UserPermission.UserCrud);

  const updateUser = useGraphqlMutation(Users.updateUser);
  const updateUserRun = updateUser.run;
  const updateUserLoading = updateUser.loading;

  const userQuery = useUser(userId);
  const user = userQuery.state.data;
  const userName = UserUtils.getName(user) || '';

  const breadcrumbs = useMemo(
    () =>
      getUserEditPageBreadcrumbs({
        user: {
          id: userId,
          name: userName
        }
      }),
    [userId, userName]
  );

  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
      setSubmitting(true);

      const input = UserUtils.fromUpdateForm(data, canEditRoles);

      updateUserRun({ variables: { input } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Пользователь обновлен');
            history.push(`${routeLinks.users.to}/${result.data.result.id}`);
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при изменении пользователя. Попробуйте снова'
          );
        })
        .then(() => {
          setSubmitting(false);
        });
    },
    [updateUserRun, history, canEditRoles]
  );

  const handleCancel = useCallback(() => {
    if (window.confirm('Вы действительно хотите отменить редактирование?')) {
      history.goBack(`${routeLinks.users.to}/${userId}`);
    }
  }, [userId, history]);

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle
          className={s.UserEditPage__pageTitle}
          title={[userName, 'Редактирование']}
          showHeading={true}
        />

        {queryLoader(userQuery) ||
          (user && (
            <UserForm
              user={user}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              submissionLoading={updateUserLoading}
            />
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
