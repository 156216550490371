import { useCallback } from 'react';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { MutationOptions } from 'apollo-client';
import { MUTATION_CREATE_OTT_FOR_POLL_ATTEMPTS_REPORT_DOWNLOAD } from '../../graphql/mutations/oneTimeTokens';
import { handleDefaultError } from '../../utils/handleDefaultError';
import { pollAttemptsExportUrl } from '../../config';
import { useGraphqlMutation } from './utils/useGraphqlMutation';

const exportOtt: MutationOptions<{ result: string }, { pollId: string }> = {
  mutation: MUTATION_CREATE_OTT_FOR_POLL_ATTEMPTS_REPORT_DOWNLOAD
};

export const useExportPollAttempts = () => {
  const { run, loading } = useGraphqlMutation(exportOtt);

  const handleExport = useCallback(
    (pollId) => {
      run({
        variables: {
          pollId
        }
      })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error(
              'Unexpected response. Expected to have { result: string }'
            );
          }
          window.open(
            `${pollAttemptsExportUrl}?poll_id=${pollId}&token=${result.data?.result}`
          );
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(err, 'Невозможно скачать файл. Попробуйте снова');
        });
    },
    [run]
  );

  return {
    loading,
    handle: handleExport
  };
};
