import React from 'react';
import { LandingLayoutContent } from '../../../common/components/Layout/Layout';
import mainFigureSrc from '../../../assets/img/welcome/figure_main.png';
import s from './Main.module.scss';

export const Main = () => {
  return (
    <LandingLayoutContent className={s.Main} innerClassName={s.Main__inner}>
      <div className={s.Main__content}>
        <div className={s.Main__text}>
          Университет, <br />
          который открывает <br />
          новые возможности
        </div>
      </div>

      <div className={s.Main__figure}>
        <img src={mainFigureSrc} alt="" />
      </div>
    </LandingLayoutContent>
  );
};
