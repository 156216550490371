import gql from 'graphql-tag';

export const MUTATION_CREATE_OTT_FOR_FILE_DOWNLOAD = gql`
  mutation mutationCreateOttForFileDownload($fileId: ID!) {
    result: createOttForFileDownload(file_id: $fileId)
  }
`;

export const MUTATION_CREATE_OTT_FOR_POLL_ATTEMPTS_REPORT_DOWNLOAD = gql`
  mutation mutationCreateOttForPollAttemptsReportDownload($pollId: ID!) {
    result: createOttForPollAttemptsReportDownload(poll_id: $pollId)
  }
`;

export const MUTATION_CREATE_OTT_FOR_MANUAL_UPLOAD = gql`
  mutation mutationCreateOttForManualUpload {
    result: createOttForManualUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_DOCUMENT_UPLOAD = gql`
  mutation mutationCreateOttForDocumentUpload {
    result: createOttForDocumentUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_NOTICE_IMAGE_UPLOAD = gql`
  mutation mutationCreateOttForNoticeImageUpload {
    result: createOttForNoticeImageUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_ARTICLE_COVER_UPLOAD = gql`
  mutation mutationCreateOttForArticleCoverUpload {
    result: createOttForArticleCoverUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_ARTICLE_IMAGE_UPLOAD = gql`
  mutation mutationCreateOttForArticleImageUpload {
    result: createOttForArticleImageUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_EVENT_COVER_UPLOAD = gql`
  mutation mutationCreateOttForEventCoverUpload {
    result: createOttForEventCoverUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_OFFER_COVER_UPLOAD = gql`
  mutation mutationCreateOttForOfferCoverUpload {
    result: createOttForOfferCoverUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_OFFER_PARTNER_LOGO_UPLOAD = gql`
  mutation mutationCreateOttForOfferPartnerLogoUpload {
    result: createOttForOfferPartnerLogoUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_PERSONNEL_RESERVE_COVER_UPLOAD = gql`
  mutation mutationCreateOttForPersonnelReserveCoverUpload {
    result: createOttForPersonnelReserveCoverUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_AVATAR_UPLOAD = gql`
  mutation mutationCreateOttForAvatarUpload {
    result: createOttForAvatarUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_EXTERNAL_POLL_COVER_UPLOAD = gql`
  mutation mutationCreateOttForExternalPollCoverUpload {
    result: createOttForExternalPollCoverUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_USERS_EXPORT_DOWNLOAD = gql`
  mutation mutationCreateOttForUsersExportDownload {
    result: createOttForUsersExportDownload
  }
`;

export const MUTATION_CREATE_OTT_FOR_INTERNSHIP_COVER_UPLOAD = gql`
  mutation mutationCreateOttForInternshipCoverUpload {
    result: createOttForInternshipCoverUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_INTERNSHIP_MANUAL_UPLOAD = gql`
  mutation mutationCreateOttForInternshipManualUpload {
    result: createOttForInternshipManualUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_COURSE_IMAGE_UPLOAD = gql`
  mutation mutationCreateOttForCourseImageUpload {
    result: createOttForCourseImageUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_ASSESSMENT_REPORT_DOWNLOAD = gql`
  mutation mutationCreateOttForAssessmentReport($reportId: ID!) {
    result: createOttForAssessmentReportDownload(
      assessment_report_id: $reportId
    )
  }
`;

export const MUTATION_CREATE_OTT_FOR_COURSE_DOCUMENT_UPLOAD = gql`
  mutation mutationCreateOttForCourseDocumentUpload {
    result: createOttForCourseDocumentUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_FEEDBACK_DOCUMENT_UPLOAD = gql`
  mutation mutationCreateOttForFeedbackDocumentUpload {
    result: createOttForFeedbackDocumentUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_OFFER_DOCUMENT_UPLOAD = gql`
  mutation mutationCreateOttForOfferDocumentUpload {
    result: createOttForOfferDocumentUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_ARTICLE_DOCUMENT_UPLOAD = gql`
  mutation mutationCreateOttForArticleDocumentUpload {
    result: createOttForArticleDocumentUpload
  }
`;

export const MUTATION_CREATE_OTT_FOR_FEEDBACKS_EXPORT_DOWNLOAD = gql`
  mutation mutationCreateOttForFeedbacksExportDownload {
    result: createOttForFeedbacksExportDownload
  }
`;
