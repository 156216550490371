import React, { useCallback } from 'react';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { toast } from 'react-toastify';
import { TableRowProps } from '../../../common/components/ui/Table/Table';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { Manuals } from '../../../store/manuals/Manuals';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { ActionRow } from '../../../common/components/ui/Table/ActionRow/ActionRow';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { ManualTypeEnum } from '../../../graphql/types';

export const ManualsRow: React.FC<TableRowProps> = (props) => {
  const deleteManual = useGraphqlMutation(Manuals.DeleteManual);
  const deleteManualRun = deleteManual.run;
  const handleDeleteManual = useCallback(
    ({ row }) => {
      const dialogText = (() => {
        switch (row.type) {
          case ManualTypeEnum.Manual:
            return 'инструкцию';
          case ManualTypeEnum.Infographics:
            return 'инфографику';
          case ManualTypeEnum.Video:
            return 'видеоролик';
          default:
            return 'инструкцию';
        }
      })();

      const toastSuccessText = (() => {
        switch (row.type) {
          case ManualTypeEnum.Manual:
            return 'Инструкция удалена';
          case ManualTypeEnum.Infographics:
            return 'Инфографика удалена';
          case ManualTypeEnum.Video:
            return 'Видеоролик удален';
          default:
            return 'Инструкция удалена';
        }
      })();

      const toastErrorText = (() => {
        switch (row.type) {
          case ManualTypeEnum.Manual:
            return 'инструкции';
          case ManualTypeEnum.Infographics:
            return 'инфографики';
          case ManualTypeEnum.Video:
            return 'видеоролика';
          default:
            return 'инструкции';
        }
      })();

      if (window.confirm(`Вы действительно хотите удалить ${dialogText}?`)) {
        deleteManualRun({ variables: { id: row.id } })
          .then((result) => {
            if (!result.data?.result) {
              throw new Error('UnexpectedResult');
            }
            toast.success(toastSuccessText);
          })
          .catch((err) => {
            if (err instanceof AsyncSingletonError) return;
            handleDefaultError(
              err,
              `Произошла ошибка при удалении ${toastErrorText}. Попробуйте снова`
            );
          });
      }
    },
    [deleteManualRun]
  );

  const updateManual = useGraphqlMutation(Manuals.UpdateManual);
  const updateManualRun = updateManual.run;
  const handleUpdateManual = useCallback(
    ({ row, state, cancelEdit }) => {
      const toastErrorText = (() => {
        switch (row.type) {
          case ManualTypeEnum.Manual:
            return 'инструкции';
          case ManualTypeEnum.Infographics:
            return 'инфографики';
          case ManualTypeEnum.Video:
            return 'видеоролика';
          default:
            return 'инструкции';
        }
      })();

      updateManualRun({
        variables: {
          input: {
            id: row.id,
            type: row.type,
            name: state.name,
            file_id: row.file_id ?? undefined,
            external_link: row.external_link || undefined
          }
        }
      })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          cancelEdit();
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            `Произошла ошибка при изменении ${toastErrorText}. Попробуйте снова`
          );
        });
    },
    [updateManualRun]
  );

  const { isMobile } = useWindowSize();

  return (
    <ActionRow
      onDeleteRow={handleDeleteManual}
      onSubmitRow={handleUpdateManual}
      showTitle={isMobile}
      {...props}
    />
  );
};
