import React from 'react';
import { Card } from '../../Card/Card';
import { Pattern, PatternsComponent } from '../../Card/patterns';
import { BlueLink } from '../../BlueLink/BlueLink';
import s from './CardSystemLinks.module.scss';

export interface CardSystemLinksProps {
  link: string;
  name: string;
  pattern: Pattern;
}

export const CardSystemLinks: React.FC<CardSystemLinksProps> = ({
  pattern,
  link,
  name
}) => {
  return (
    <Card
      title={name}
      actions={<BlueLink href={link} hasArrow />}
      extContent={PatternsComponent[pattern]}
      classes={{
        root: s.CardSystemLinks,
        title: s.CardSystemLinks__title
      }}
    />
  );
};
