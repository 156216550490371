import React, { useCallback } from 'react';
import { useField } from 'formik';
import { DatePicker, DatePickerProps } from '../../DatePicker/DatePicker';

export interface FormDatePickerProps
  extends Pick<
    DatePickerProps,
    | 'className'
    | 'classes'
    | 'label'
    | 'placeholder'
    | 'minDate'
    | 'maxDate'
    | 'excludeDates'
    | 'disabled'
  > {
  name: string;
}

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
  name,
  ...props
}) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(
    name
  );

  const handleBlur = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  return (
    <DatePicker
      value={value}
      errorMessage={touched && error ? error : undefined}
      onChange={setValue}
      onBlur={handleBlur}
      {...props}
    />
  );
};
