import { IQueryTransformer } from '@proscom/prostore-react-router';
import { formatDateIso, tryParseIso } from '@proscom/ui-utils-date';

export const startDateTransformer: IQueryTransformer<string | undefined> = {
  parse(value) {
    if (value && typeof value === 'string') {
      if (tryParseIso(value)) {
        return value + 'T00:00:00Z';
      }
    }
    return undefined;
  },
  stringify(value: string | undefined) {
    return value ? formatDateIso(new Date(value)) : undefined;
  }
};
