import React from 'react';
import clsx from 'clsx';
import { Dropdown } from '../Dropdown/Dropdown';
import { CardDotsButton } from '../CardDotsButton/CardDotsButton';
import { ContextMenu, ContextMenuProps } from './ContextMenu';
import s from './ContextMenu.module.scss';

export enum ContextMenuButtonPosition {
  left = 'left',
  right = 'right'
}

export interface ContextMenuButtonClasses {
  root?: string;
  button?: string;
}

export interface ContextMenuButtonProps extends ContextMenuProps {
  className?: string;
  classes?: ContextMenuButtonClasses;
  position?: ContextMenuButtonPosition;
}

export const ContextMenuButton: React.FC<ContextMenuButtonProps> = ({
  className,
  classes,
  position = ContextMenuButtonPosition.right,
  items
}) => {
  return (
    <Dropdown
      className={clsx(s.ContextMenuButton, className, classes?.root)}
      classes={{
        content: clsx(
          s.ContextMenuButton__content,
          s[`ContextMenuButton__content_${position}`]
        )
      }}
      content={() => <ContextMenu items={items} />}
    >
      {(props) => (
        <CardDotsButton
          className={clsx(s.ContextMenuButton__button, classes?.button)}
          {...props}
        />
      )}
    </Dropdown>
  );
};
