import gql from 'graphql-tag';
import { FRAGMENT_USER_NOTIFICATIONS_SETTINGS } from '../fragments/users';

// todo use fragment for result?
export const MUTATION_UPDATE_USER_SETTINGS = gql`
  mutation mutationUpdateUserSettings($input: UserSettingsInput!) {
    result: updateSettings(input: $input) {
      ...UserNotificationsSettings
    }
  }

  ${FRAGMENT_USER_NOTIFICATIONS_SETTINGS}
`;
