import gql from 'graphql-tag';
import { FRAGMENT_PAGINATION_HEADER } from '../fragments/common';
import {
  FRAGMENT_PROJECT_FULL,
  FRAGMENT_PROJECT_SNIPPET
} from '../fragments/projects';

export const QUERY_GET_PROJECTS = gql`
  query queryGetProjects($input: ProjectPageInput) {
    projectsPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...ProjectSnippet
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
  ${FRAGMENT_PROJECT_SNIPPET}
`;

export const QUERY_GET_PROJECT = gql`
  query queryGetProject($id: ID!) {
    project(id: $id) {
      ...ProjectFull
    }
  }

  ${FRAGMENT_PROJECT_FULL}
`;
