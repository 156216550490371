import React, { useCallback } from 'react';
import { isNil } from 'lodash-es';
import { declinedText } from '@proscom/ui-utils';
import { useStore } from '@proscom/prostore-react';
import { LayoutGrid } from '../../../common/components/Layout/Layout';
import { UserPerson } from '../../../common/components/ui/UserBlocks/UserPerson/UserPerson';
import {
  Button,
  ButtonBaseColor,
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { routeLinks } from '../../routeLinks';
import { ReactComponent as IconEdit } from '../../../assets/img/icons/edit.svg';
import {
  UserPageBlock,
  UserPageBlockVariant
} from '../../../common/components/ui/UserBlocks/UserPageBlock/UserPageBlock';
import { UserGeneral } from '../../../common/components/ui/UserBlocks/UserGeneral/UserGeneral';
import { UserEducations } from '../../../common/components/ui/UserBlocks/UserEducations/UserEducations';
import { UserPublish } from '../../../common/components/ui/UserBlocks/UserPublish/UserPublish';
import { UserJobs } from '../../../common/components/ui/UserBlocks/UserJobs/UserJobs';
import { UserSkills } from '../../../common/components/ui/UserBlocks/UserSkills/UserSkills';
import { UserInterests } from '../../../common/components/ui/UserBlocks/UserInterests/UserInterests';
import { UserPositions } from '../../../common/components/ui/UserBlocks/UserPositions/UserPositions';
import { UserAbout } from '../../../common/components/ui/UserBlocks/UserAbout/UserAbout';
import { URL_KEY_DIRECTOR_ID } from '../../../store/urlKeys';
import { UserType } from '../../../graphql/types';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { useLoginOps } from '../../../common/hooks/useLoginOps';
import { AuthStore } from '../../../store/AuthStore';
import { STORE_AUTH } from '../../../store/storeKeys';
import s from './UserPage.module.scss';

export interface UserPageContentProps {
  user: UserType;
  canEdit: boolean;
  editLink: string;
}

export function UserPageContent({
  user,
  canEdit,
  editLink
}: UserPageContentProps) {
  const { isDesktop, isTablet } = useWindowSize();
  const [auth, authStore] = useStore<AuthStore>(STORE_AUTH);
  const {
    authWithId,
    loginOp: { loading: authLoading }
  } = useLoginOps(auth, authStore);

  const currentUserId = auth?.authData?.user?.id;
  const userId = user.id;
  const canAuthAsUser =
    canEdit && currentUserId?.toString() !== userId.toString();

  const handleLoginAsUser = useCallback(async () => {
    if (canAuthAsUser) {
      await authWithId(userId);
    }
  }, [authWithId, userId, canAuthAsUser]);

  const hasPositions = user.positions && user.positions.length > 0;
  const hasSubordinates =
    !isNil(user.count_subordinates) && user.count_subordinates > 0;

  const hasEducations = user.educations && user.educations.length > 0;
  const hasPubs = user.publications && user.publications.length > 0;
  const hasJobs = user.work_experiences && user.work_experiences.length > 0;
  const hasSkills = user.skills && user.skills.length > 0;
  const hasInterests =
    user.areas_of_interest && user.areas_of_interest.length > 0;
  const hasAbout = user.about;

  return (
    <>
      <div className={s.UserPage__main}>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={isDesktop ? 8 : isTablet ? 9 : 4}>
            <UserPerson user={user} />
          </LayoutGrid.GridItem>
          <LayoutGrid.GridItem cols={isDesktop ? 4 : isTablet ? 2 : 4}>
            {canEdit && (
              <div className={s.UserPage__extActions}>
                <ButtonLink
                  variant={ButtonVariant.secondary}
                  size={ButtonSize.small}
                  to={editLink}
                  iconLeft={<IconEdit />}
                  loading={authLoading}
                >
                  Редактировать
                </ButtonLink>
                {canAuthAsUser && (
                  <Button
                    variant={ButtonVariant.secondary}
                    size={ButtonSize.small}
                    baseColor={ButtonBaseColor.red}
                    loading={authLoading}
                    onClick={handleLoginAsUser}
                  >
                    {isDesktop
                      ? 'Авторизоваться под пользователем'
                      : 'Авторизоваться'}
                  </Button>
                )}
              </div>
            )}
          </LayoutGrid.GridItem>
        </LayoutGrid>
      </div>

      <div className={s.UserPage__blocks}>
        <UserPageBlock title={'Общая информация'}>
          <UserGeneral
            birthday={user.birthday}
            email={user.email}
            campus={user.campus}
            degrees={user.degrees}
            contacts={user.contacts}
            workPhone={user.work_phone}
            mobilePhone={user.mobile_phone}
          />
        </UserPageBlock>

        {hasEducations && (
          <UserPageBlock title={'Образование'}>
            <UserEducations educations={user.educations} />
          </UserPageBlock>
        )}

        {hasPubs && (
          <UserPageBlock title={'Публикации и исследования'}>
            <UserPublish publications={user.publications} />
          </UserPageBlock>
        )}

        {(hasJobs || hasSkills || hasInterests || hasAbout) && (
          <UserPageBlock title={'Профессиональные достижения'}>
            {hasJobs && (
              <UserPageBlock
                title={'Опыт работы'}
                variant={UserPageBlockVariant.small}
              >
                <UserJobs jobs={user.work_experiences} />
              </UserPageBlock>
            )}

            {hasSkills && (
              <UserPageBlock
                title={'Компетенции'}
                variant={UserPageBlockVariant.small}
              >
                <UserSkills skills={user.skills} />
              </UserPageBlock>
            )}

            {hasInterests && (
              <UserPageBlock
                title={'Сферы профессиональных интересов'}
                variant={UserPageBlockVariant.small}
              >
                <UserInterests interests={user.areas_of_interest} />
              </UserPageBlock>
            )}

            {hasAbout && (
              <UserPageBlock
                title={'Личные интересы и хобби'}
                variant={UserPageBlockVariant.small}
              >
                <UserAbout about={user.about} />
              </UserPageBlock>
            )}
          </UserPageBlock>
        )}

        {(hasPositions || hasSubordinates) && (
          <UserPageBlock
            title={
              user.positions && user.positions.length > 1
                ? 'Должности в МФТИ'
                : 'Должность в МФТИ'
            }
          >
            {hasPositions && (
              <UserPageBlock variant={UserPageBlockVariant.small}>
                <UserPositions userId={user.id} positions={user.positions} />
              </UserPageBlock>
            )}

            {hasSubordinates && (
              <UserPageBlock variant={UserPageBlockVariant.small}>
                <ButtonLink
                  size={ButtonSize.small}
                  variant={ButtonVariant.secondary}
                  to={`${routeLinks.users.to}?${URL_KEY_DIRECTOR_ID}=${userId}`}
                >
                  {declinedText(
                    user.count_subordinates,
                    '$n подчинённый',
                    '$n подчинённых',
                    '$n подчинённых'
                  )}{' '}
                  &rsaquo;
                </ButtonLink>
              </UserPageBlock>
            )}
          </UserPageBlock>
        )}
      </div>
    </>
  );
}
