import React, { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import {
  NotificationTypesEnum,
  UserSettingsType
} from '../../../graphql/types';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { UserSettings } from '../../../store/userSettings/UserSettings';
import { UserSettingsUtils } from '../../../store/userSettings/UserSettingsUtils';
import { useUser } from '../../../graphql/hooks/useUser';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { getProfilePageBreadcrumbs } from '../../profile/index/ProfilePage';
import { Breadcrumbs } from '../../../common/components/ui/Breadcrumbs';
import { routeLinks } from '../../routeLinks';
import { Button } from '../../../common/components/ui/Button';
import { FormCheckboxArray } from '../../../common/components/ui/Form/FormCheckbox/FormCheckbox';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import s from './SettingsPage.module.scss';

const settingsTypeLabelMap: Omit<
  Record<keyof UserSettingsType, string>,
  '__typename'
> = {
  article_notifications: 'Новости',
  poll_notifications: 'Опросы',
  course_notifications: 'Курсы',
  event_notifications: 'События',
  internship_notifications: 'Стажировки',
  offer_notifications: 'Бонусы',
  project_notifications: 'Проекты'
};

const notificationTypeLabelMap = {
  [NotificationTypesEnum.Email]: 'E-mail'
  // todo: add `push notifications` when available
};
const breadcrumbs = [
  ...getProfilePageBreadcrumbs(),
  {
    name: routeLinks.settings.text,
    path: routeLinks.settings.to
  }
];

export function SettingsPage() {
  const { user: currentUser } = useCurrentUser();
  const userQuery = useUser(currentUser?.id);
  const user = userQuery.state.data;

  const initialValues = useMemo(() => {
    return UserSettingsUtils.toForm(user?.settings);
  }, [user]);

  const updateSettingsMutation = useGraphqlMutation(
    UserSettings.updateSettings
  );

  const onSubmit = useCallback(
    (data, formik) => {
      updateSettingsMutation
        .run({ variables: { input: UserSettingsUtils.fromForm(data) } })
        .then((v) => {
          toast.success('Настройки обновлены');
        })
        .finally(() => {
          formik.setSubmitting(false);
        })
        .catch((e) => {
          if (e instanceof AsyncSingletonError) return;
          console.log(e, e.message, JSON.stringify(e));
          handleDefaultError(e, 'Произошла ошибка при обновлении настроек');
        });
    },
    [updateSettingsMutation]
  );

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle title={'Настройки'} showHeading />
        <div className={s.SettingsPage__content}>
          {queryLoader(userQuery) || (
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {(props) => (
                <SettingsPageForm {...props}>
                  <Button
                    className={s.SettingsPage__submitButton}
                    type={'submit'}
                    onClick={props.submitForm}
                    loading={updateSettingsMutation.loading}
                  >
                    Сохранить
                  </Button>
                </SettingsPageForm>
              )}
            </Formik>
          )}
        </div>
      </LayoutContent>
    </PageLayout>
  );
}

function SettingsPageForm({ children, ...props }) {
  return (
    <Form>
      <Title
        className={s.SettingsPage__settingsTableTitle}
        variant={TitleVariant.h3}
      >
        Уведомления
      </Title>
      <div className={s.SettingsPage__settingsTable}>
        {Object.entries(settingsTypeLabelMap).map(([typeValue, typeLabel]) => (
          <div
            className={s.SettingsPage__settingsTableRow}
            key={`${typeValue}Label`}
          >
            <div className={s.SettingsPage__settingsTableData}>{typeLabel}</div>
            {Object.entries(notificationTypeLabelMap).map(
              ([notificationTypeValue, notificationTypeLabel]) => (
                <div
                  key={`${typeValue}.${notificationTypeValue}`}
                  className={s.SettingsPage__settingsTableData}
                >
                  <FormCheckboxArray
                    name={typeValue}
                    checkedValue={notificationTypeValue}
                    label={notificationTypeLabel}
                  />
                </div>
              )
            )}
          </div>
        ))}
      </div>
      {children}
    </Form>
  );
}
