import React, { ReactNodeArray, useCallback, useMemo } from 'react';
import ReactSelect, {
  components,
  IndicatorContainerProps,
  OptionTypeBase,
  Props as ReactSelectProps,
  ValueContainerProps
} from 'react-select';
import { declinedText } from '@proscom/ui-utils';
import clsx from 'clsx';
import { MultiValueGenericProps } from 'react-select/src/components/MultiValue';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import s from './Select.module.scss';

export const selectDefaults = {
  placeholder: 'Выберите',
  noOptionsMessage: () => 'Нет данных',
  loadingMessage: () => 'Загрузка...',
  formatCreateLabel: (value) => `Добавить "${value}"`
};

export enum SelectSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export interface SelectClasses {
  root?: string;
  label?: string;
  input?: string;
  helperText?: string;
}

export interface SelectOptionProps extends OptionTypeBase {}

export interface SelectProps<IsMulti extends boolean = false>
  extends Omit<
    ReactSelectProps<OptionTypeBase, IsMulti>,
    'value' | 'onChange'
  > {
  classes?: SelectClasses;
  size?: SelectSize;
  label?: string;
  value?: string | string[];
  onChange?: (ids: string | string[]) => void;
  errorMessage?: string;
  hintMessage?: string;
}

export const MultiValueLabel = React.memo(function MultiValueLabel(
  props: MultiValueGenericProps<any>
) {
  return (
    <components.MultiValueLabel {...props}>
      <span title={(props.children as unknown) as string}>
        {props.children}
      </span>
    </components.MultiValueLabel>
  );
});

export const ValueContainer = React.memo(function ValueContainer({
  children,
  ...props
}: ValueContainerProps<any, true>) {
  const [values, input] = children as ReactNodeArray;
  return (
    <components.ValueContainer {...props}>
      {Array.isArray(values) ? (
        <>
          {values?.[0]} {values?.[1]} {values.length > 1 ? '...' : null}
        </>
      ) : (
        values
      )}
      {input}
    </components.ValueContainer>
  );
});
export const IndicatorsContainer = React.memo(function IndicatorsContainer(
  props: IndicatorContainerProps<any, true>
) {
  const { children, className, cx, getStyles } = props;
  const selectedOptionsCount = props.getValue().length;
  return (
    <div
      style={getStyles('indicatorsContainer', props)}
      className={cx(
        {
          indicators: true
        },
        className
      )}
    >
      {selectedOptionsCount > 0 && (
        <div
          title={declinedText(
            selectedOptionsCount,
            'Выбрана $n опция',
            'Выбраны $n опции',
            'Выбрано $n опций'
          )}
        >
          ({selectedOptionsCount})
        </div>
      )}
      {children}
    </div>
  );
});

export const selectMultiAdditionalProps = (
  components: SelectProps['components']
) => ({
  hideSelectedOptions: false,
  components: {
    IndicatorsContainer,
    ValueContainer,
    MultiValueLabel,
    ...components
  }
});

export const Select: React.FC<SelectProps<boolean>> = ({
  className,
  classes,
  components,
  label,
  size = SelectSize.medium,
  errorMessage,
  hintMessage,
  options,
  value,
  isMulti,
  onChange,
  ...props
}) => {
  const handleChange = useCallback(
    (option) => {
      onChange?.(
        isMulti ? option?.map((v) => v.value) : option ? option.value : null
      );
    },
    [isMulti, onChange]
  );

  const selectValue = useMemo(() => {
    if (isMulti) {
      return options?.filter((opt) => value?.includes(opt.value));
    } else {
      return options?.find((option) => option.value === value);
    }
  }, [options, value, isMulti]);

  return (
    <label className={clsx(s.Select, className, classes?.root)}>
      {label && (
        <span className={clsx(s.Select__label, classes?.label)}>{label}</span>
      )}
      <ReactSelect
        className={clsx(
          s.Select__input,
          s[`Select__input_${size}`],
          {
            [s.Select__input_error]: !!errorMessage
          },
          classes?.input
        )}
        classNamePrefix={'hr-select'}
        options={options}
        value={selectValue ?? null}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        onChange={handleChange}
        {...(isMulti
          ? selectMultiAdditionalProps(components)
          : {
              components
            })}
        {...selectDefaults}
        {...props}
        // for dev
        // menuIsOpen={true}
      />
      <HelpText
        className={classes?.helperText}
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </label>
  );
};
