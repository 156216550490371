import { IUseGraphqlWatchQueryOptions } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { PersonnelReserveType } from '../../types';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import { QUERY_GET_PERSONNEL_RESERVE } from '../../queries/personnelReserve';

const queryOptions: IUseGraphqlWatchQueryOptions<
  { id: string } | null,
  PersonnelReserveType | null
> = {
  query: QUERY_GET_PERSONNEL_RESERVE,
  skipQuery: skipIfNull(null),
  mapData: (result) => result.personnelReserve
};

export function usePersonnelReserve(id?: string) {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: id
      ? {
          id
        }
      : null
  });
}
