import React, { useMemo } from 'react';
import { groupBy } from 'lodash-es';
import {
  LayoutContent,
  LayoutGrid
} from '../../common/components/Layout/Layout';
import { PageLayout } from '../../common/components/Layout/PageLayout';
import { PageTitle } from '../../common/components/ui/PageTitle/PageTitle';
import { useManuals } from '../../graphql/hooks/useManuals';
import { queryLoader } from '../../common/components/utils/queryLoader';
import { useCurrentUser } from '../../common/hooks/useCurrentUser';
import { useFileDownload } from '../../common/hooks/useFileDownload';
import { ManualCard } from '../../common/components/ui/Cards/ManualCard/ManualCard';
import { NoData } from '../../common/components/ui/NoData/NoData';
import { UserPermission } from '../../utils/permissions';
import {
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../common/components/ui/Button';
import {
  getCardPattern,
  Pattern
} from '../../common/components/ui/Card/patterns';
import { useHasPermission } from '../../common/hooks/useHasPermission';
import { routeLinks } from '../routeLinks';
import {
  getBreadcrumbRouteLink,
  IBreadcrumbsItem
} from '../../common/components/ui/Breadcrumbs';
import { FileTypeEnum } from '../../store/FileTypeEnum';
import { Title, TitleVariant } from '../../common/components/ui/Title/Title';
import { ManualType, ManualTypeEnum } from '../../graphql/types';
import { manualsGroupTitles } from './manuals.contants';
import s from './ManualsPage.module.scss';

const patterns: Pattern[] = [
  Pattern.rectangles,
  Pattern.circles,
  Pattern.triangles
];

export function getManualsPageBreadcrumbs(): IBreadcrumbsItem[] {
  return [getBreadcrumbRouteLink(routeLinks.manuals)];
}

export default function ManualsPage() {
  const manualsQuery = useManuals();
  const manuals = manualsQuery.state.data;

  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.ManualCrud);
  const { handle: handleDownloadFile } = useFileDownload(
    undefined,
    FileTypeEnum.manual
  );

  const groups = useMemo(() => {
    const object = {
      [ManualTypeEnum.Manual]: [] as ManualType[],
      [ManualTypeEnum.Infographics]: [] as ManualType[],
      [ManualTypeEnum.Video]: [] as ManualType[]
    };
    if (!manuals?.length) return object;
    return {
      ...object,
      ...groupBy(manuals, (m) => m.type)
    };
  }, [manuals]);

  return (
    <PageLayout>
      <LayoutContent>
        <PageTitle
          className={s.ManualsPage__title}
          title={routeLinks.manuals.text}
          showHeading
        >
          {canEdit && (
            <div>
              <ButtonLink
                to={`${routeLinks.manuals.to}/edit`}
                variant={ButtonVariant.secondary}
                size={ButtonSize.small}
              >
                Редактировать
              </ButtonLink>
            </div>
          )}
        </PageTitle>
        {queryLoader(manualsQuery) ||
          (!manuals || manuals.length === 0 ? (
            <NoData />
          ) : (
            <div className={s.ManualsGroups}>
              {Object.entries(groups).map(([key, items], iGroup) => (
                <div key={iGroup} className={s.ManualsGroup}>
                  <Title variant={TitleVariant.h3} withMargin>
                    {manualsGroupTitles[key]}
                  </Title>
                  {!items.length ? (
                    <NoData />
                  ) : (
                    <LayoutGrid>
                      {items.map((item) => (
                        <LayoutGrid.GridItem key={item.id} cols={4}>
                          <ManualCard
                            manual={item}
                            title={item.name}
                            pattern={getCardPattern(item.id, patterns)}
                            onClick={handleDownloadFile(item.file_id)}
                          />
                        </LayoutGrid.GridItem>
                      ))}
                    </LayoutGrid>
                  )}
                </div>
              ))}
            </div>
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
