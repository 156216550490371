import React from 'react';
import { Location } from 'history';
import { Helmet } from 'react-helmet';
import { PageLayout } from '../../common/components/Layout/PageLayout';
import { Main } from './Main/Main';
import { AboutHR } from './AboutHR/AboutHR';
import { Welcome } from './Welcome/Welcome';
import { TechHistory } from './TechHistory/TechHistory';
import { Tours } from './Tours/Tours';
import { Contacts } from './Contacts/Contacts';
// import { DressCode } from './DressCode/DressCode';
import { Banner } from './Banner/Banner';
import { Structure } from './Structure/Structure';
import { CampusMap } from './CampusMap/CampusMap';
import { Documents } from './Documents/Documents';
import s from './WelcomePage.module.scss';

export interface WelcomePageProps {
  location: Location;
}

export default function WelcomePage({ location }: WelcomePageProps) {
  return (
    <PageLayout contentClassName={s.WelcomePage__content} isLanding={true}>
      <Helmet title={'Работа в МФТИ'} />

      <Main />
      <AboutHR />
      <Welcome />
      <TechHistory />
      <Tours />
      <Structure />
      <Contacts />
      {/*<DressCode />*/}
      <Banner />
      <Documents />
      <CampusMap />
    </PageLayout>
  );
}
