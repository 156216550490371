import React from 'react';
import { FigureType, FigureVariant } from '../../../Figures/types';
import { CardPattern } from '../CardPattern/CardPattern';
import s from './RectanglesPattern.module.scss';

export const RectanglesPattern = React.memo(function RectanglesPattern() {
  return (
    <CardPattern
      className={s.RectanglesPattern}
      figures={[
        {
          className: s.RectanglesPattern_front,
          type: FigureType.rectangle,
          variant: FigureVariant.dark,
          size: 238
        },
        {
          className: s.RectanglesPattern_back,
          type: FigureType.rectangle,
          variant: FigureVariant.light,
          size: 238
        }
      ]}
    />
  );
});
