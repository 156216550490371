import { MutationOptions } from 'apollo-client';
import {
  CourseAnnounceCreateInput,
  CourseAnnounceUpdateInput,
  CourseLongreadCreateInput,
  CourseLongreadUpdateInput,
  CourseType
} from '../../graphql/types';
import { clearCache } from '../../graphql/queriesUtils';
import {
  MUTATION_CREATE_ANNOUNCE_COURSE,
  MUTATION_CREATE_COURSE_TEST_RESULT,
  MUTATION_CREATE_LONGREAD_COURSE,
  MUTATION_DELETE_COURSE,
  MUTATION_UPDATE_ANNOUNCE_COURSE,
  MUTATION_UPDATE_LONGREAD_COURSE
} from '../../graphql/mutations/courses';

export class Courses {
  static refetchQueries = [
    'queryGetCoursesPage',
    'queryGetUnpublishedCoursesPage',
    'getTagsOptions',
    'queryGetCourse'
  ];
  static updateQueries = [
    'coursesPage',
    'unpublishedCoursesPage',
    'tagsPage',
    'course'
  ];
  static updateFragments = ['CourseType'];

  public static createAnnounceCourse: MutationOptions<
    {
      result: CourseType;
    },
    {
      input: CourseAnnounceCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_ANNOUNCE_COURSE,
    refetchQueries: Courses.refetchQueries,
    update: clearCache(Courses.updateQueries, Courses.updateFragments)
  };

  public static createLongreadCourse: MutationOptions<
    {
      result: CourseType;
    },
    {
      input: CourseLongreadCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_LONGREAD_COURSE,
    refetchQueries: Courses.refetchQueries,
    update: clearCache(Courses.updateQueries, Courses.updateFragments)
  };

  public static updateAnnounceCourse: MutationOptions<
    {
      result: CourseType;
    },
    {
      input: CourseAnnounceUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_ANNOUNCE_COURSE,
    refetchQueries: Courses.refetchQueries,
    update: clearCache(Courses.updateQueries, Courses.updateFragments)
  };

  public static updateLongreadCourse: MutationOptions<
    {
      result: CourseType;
    },
    {
      input: CourseLongreadUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_LONGREAD_COURSE,
    refetchQueries: Courses.refetchQueries,
    update: clearCache(Courses.updateQueries, Courses.updateFragments)
  };

  public static deleteCourse: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_DELETE_COURSE,
    refetchQueries: Courses.refetchQueries,
    update: clearCache(Courses.updateQueries, Courses.updateFragments)
  };

  public static createCourseTestResult: MutationOptions<
    { result: boolean },
    { input: string }
  > = {
    mutation: MUTATION_CREATE_COURSE_TEST_RESULT,
    refetchQueries: ['queryGetCourseTestResult'],
    update: clearCache(['courseTestResult'])
  };
}
