import React from 'react';
import clsx from 'clsx';
import s from './PointScaleButton.module.scss';

export interface PointScaleButtonProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  label: string | number;
  name: string;
  className?: string;
}

export const PointScaleButton: React.FC<PointScaleButtonProps> = ({
  onChange,
  checked,
  label,
  name,
  className
}) => {
  return (
    <label className={clsx(s.PointScaleButton, className)}>
      <div
        className={clsx(s.PointScaleButton__label, {
          [s.PointScaleButton__label_checked]: checked
        })}
      >
        {label}
      </div>
      <input
        type={'radio'}
        name={name}
        onChange={onChange}
        value={label}
        className={s.PointScaleButton__input}
      />
    </label>
  );
};
