import ym, { YMInitializer } from 'react-yandex-metrika';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { isProductionBuild } from '../../../config';

const yandexMetrikaId = process.env.REACT_APP_YANDEX_METRIKA_ID as string;
const metrikaOptions = {
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true
};

// Активируем Яндекс метрику только при наличии id и продакшн сборке
const isActive =
  !!yandexMetrikaId && yandexMetrikaId.length > 0 && isProductionBuild;

export class YandexMetrikaUtils {
  static userParams(params: any) {
    if (!isActive) return;
    ym('userParams', params);
  }

  static hit(url: string) {
    if (!isActive) return;
    ym('hit', url);
  }

  static reachGoal(goalName: string, params?: any) {
    if (!isActive) return;
    ym('reachGoal', goalName, params);
  }
}

function YandexMetrikaComp() {
  const history = useHistory();
  const user = useCurrentUser();

  useEffect(() => {
    const userId = user.user?.id;
    if (userId) {
      YandexMetrikaUtils.userParams({
        UserId: userId
      });
    }
  }, [user]);

  useEffect(() => {
    return history.listen(() => {
      YandexMetrikaUtils.hit(window.location.href);
    });
  }, [history]);

  return (
    <YMInitializer accounts={[+yandexMetrikaId]} options={metrikaOptions} />
  );
}

export function YandexMetrika() {
  return isActive ? <YandexMetrikaComp /> : null;
}
