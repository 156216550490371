export enum FileTypeEnum {
  files = 'files',
  manual = 'manual',
  document = 'document',
  article_cover = 'article_cover',
  article_image = 'article_image',
  notice_image = 'notice_image',
  event_cover = 'event_cover',
  offer_cover = 'offer_cover',
  offer_partner_logo = 'offer_partner_logo',
  avatar = 'avatar',
  external_poll_cover = 'external_poll_cover',
  poll_cover = 'poll_cover',
  internship_cover = 'internship_cover',
  internship_manual = 'internship_manual',
  course_image = 'course_image',
  assessment_report = 'assessment-report',
  personnel_reserve_cover = 'personnel_reserve_cover',

  feedback_document = 'feedback_document',
  course_document = 'course_document',
  offer_document = 'offer_document',
  article_document = 'article_document'
}
