import React from 'react';
import clsx from 'clsx';
import {
  Title,
  TitleVariant
} from '../../../../common/components/ui/Title/Title';
import { ContentBlock } from '../../../../common/components/ui/ContentBlock/ContentBlock';
import { useFileSrc } from '../../../../common/hooks/useFileSrc';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { ImagePreview } from '../../../../common/components/ui/ImagePreview/ImagePreview';
import { Tag } from '../../../../common/components/ui/Tag/Tag';
import { IContentBlock } from '../../../../common/components/ui/Form/FormContentEditor/Content';
import {
  CourseCommonFields,
  CourseCommonFieldsProps
} from '../CourseCommonFields';
import { FileLoaderList } from '../../../../common/components/ui/FileLoader/FileLoaderList';
import { CourseType } from '../../../../graphql/types';
import s from './Course.module.scss';

export interface CourseProps extends CourseCommonFieldsProps {
  id?: string;
  coverImageId?: string;
  title?: string;
  contentBlocks?: IContentBlock[];
  tags: string[];
  courseSubject: string;
  document_files: CourseType['document_files'];
}

export const Course: React.FC<CourseProps> = ({
  title,
  tags,
  courseSubject,
  coverImageId,
  contentBlocks,
  document_files,
  ...fields
}) => {
  const imageSrc = useFileSrc(coverImageId, FileTypeEnum.course_image);

  return (
    <div className={s.Course}>
      <div className={s.Course__head}>
        <Title className={s.Course__title} variant={TitleVariant.h2}>
          {title || ''}
          {Boolean(courseSubject) && (
            <Tag className={s.Course__titleTag} isSelected>
              {courseSubject}
            </Tag>
          )}
        </Title>
        {imageSrc && (
          <ImagePreview
            className={s.Course__cover}
            classes={{
              image: s.Course__coverImage
            }}
            imageSrc={imageSrc}
            showBackfaceImage
          />
        )}
      </div>
      {!tags?.length ? null : (
        <div className={s.Course__section}>
          <div className={s.Course__skills}>
            {tags.map((tag) => (
              <Tag key={tag} className={s.Course__skill}>
                {tag}
              </Tag>
            ))}
          </div>
        </div>
      )}
      <CourseCommonFields {...fields} showTitle />
      <div className={s.Course__content}>
        {contentBlocks?.map((block, iBlock) => (
          <ContentBlock
            meta={{ fileType: FileTypeEnum.course_image }}
            key={iBlock}
            className={clsx(
              s.Course__contentBlock,
              s[`Article__contentBlock_${block.variant}`]
            )}
            block={block}
          />
        ))}
      </div>

      {document_files && document_files.length > 0 && (
        <FileLoaderList
          className={s.Course__files}
          title={'Файлы'}
          fileType={FileTypeEnum.course_document}
          files={document_files}
        />
      )}
    </div>
  );
};
