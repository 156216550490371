import React from 'react';
import { FormSelect } from '../../../common/components/ui/Form/FormSelect/FormSelect';
import { SelectClasses } from '../../../common/components/ui/Select/Select';
import { OfferSourcesEnum } from '../../../graphql/types';

const sourceOptions = [
  {
    label: 'От партнеров',
    value: OfferSourcesEnum.Partner
  },
  {
    label: 'Бонусы МФТИ',
    value: OfferSourcesEnum.Mipt
  }
];

export interface OfferSourceSelectProps {
  classes?: SelectClasses;
}

export const OfferSourceSelect: React.FC<OfferSourceSelectProps> = ({
  classes
}) => {
  return (
    <FormSelect
      label={'Источник'}
      name={'source'}
      options={sourceOptions}
      classes={classes}
      placeholder={'Выберите источник бонуса'}
    />
  );
};
