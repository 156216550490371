import React, { useCallback, useMemo, useState } from 'react';
import { FormikProps } from 'formik';
import { isWithinInterval } from 'date-fns';
import clsx, { ClassArray } from 'clsx';
import { VacationAppProps } from '../../../../store/vacations/VacationsUtils';
import { DateRangePickerValueType } from '../../../../common/components/ui/DatePicker/DateRangePicker';
import {
  checkDatesIncludeDate,
  getDateUtc,
  parseIsoToLocal
} from '../../../../utils/date/date';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { BottomSheet } from '../../../../common/components/ui/BottomSheet/BottomSheet';
import { ReactComponent as CalendarIcon } from '../../../../assets/img/icons/calendar.svg';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../../common/components/ui/Button';
import {
  Calendar,
  CalendarProps
} from '../../../../common/components/ui/Calendar/Calendar';
import {
  VacationApplicationType,
  VacationStatusesEnum
} from '../../../../graphql/types';
import s from './VacationAppForm.module.scss';

export interface VacationAppFormCalendarProps
  extends FormikProps<VacationAppProps>,
    Pick<CalendarProps, 'minDate' | 'maxDate' | 'excludeDates'> {
  daysAvailable: number | null;
  holidayDates: Date[];
  vacationApps?: VacationApplicationType[];
  isVacationsDisabled: boolean;
}

export const VacationAppFormCalendar: React.FC<VacationAppFormCalendarProps> = ({
  values,
  setFieldValue,
  daysAvailable,
  holidayDates,
  vacationApps,
  isVacationsDisabled,
  ...props
}) => {
  const { isMobile } = useWindowSize();
  const [showCalendar, setShowCalendar] = useState(false);

  const calendarValue: DateRangePickerValueType = useMemo(() => {
    return [parseIsoToLocal(values.start), parseIsoToLocal(values.end)];
  }, [values]);

  const sentForApprovalVacationApps = useMemo(() => {
    if (!vacationApps?.length) return [];
    return vacationApps.filter(
      (app) => app.status === VacationStatusesEnum.SentForApproval
    );
  }, [vacationApps]);

  const approvedVacationApps = useMemo(() => {
    if (!vacationApps?.length) return [];
    return vacationApps.filter(
      (app) => app.status === VacationStatusesEnum.Approved
    );
  }, [vacationApps]);

  const completeVacationApps = useMemo(() => {
    if (!vacationApps?.length) return [];
    return vacationApps.filter(
      (app) => app.status === VacationStatusesEnum.Complete
    );
  }, [vacationApps]);

  const handleSetDayClassName = useCallback(
    (date: Date) => {
      const classNames: ClassArray = [];

      if (checkDatesIncludeDate(holidayDates, date)) {
        classNames.push(s.Calendar__day_holiday);
      }

      sentForApprovalVacationApps.forEach((app) => {
        const start = new Date(getDateUtc(app.start));
        const end = new Date(getDateUtc(app.end));
        if (isWithinInterval(date, { start, end })) {
          classNames.push(s.Calendar__day_sentForApprovalVacationApp);
        }
      });
      approvedVacationApps.forEach((app) => {
        const start = new Date(getDateUtc(app.start));
        const end = new Date(getDateUtc(app.end));
        if (isWithinInterval(date, { start, end })) {
          classNames.push(s.Calendar__day_approvedVacationApp);
        }
      });
      completeVacationApps.forEach((app) => {
        const start = new Date(getDateUtc(app.start));
        const end = new Date(getDateUtc(app.end));
        if (isWithinInterval(date, { start, end })) {
          classNames.push(s.Calendar__day_completeVacationApp);
        }
      });

      return clsx(...classNames);
    },
    [
      holidayDates,
      sentForApprovalVacationApps,
      approvedVacationApps,
      completeVacationApps
    ]
  );

  const handleChange = useCallback(
    (values) => {
      setFieldValue('start', values[0]);
      setFieldValue('end', values[1]);
    },
    [setFieldValue]
  );

  const handleReset = useCallback(() => {
    setFieldValue('start', '');
    setFieldValue('end', '');
  }, [setFieldValue]);

  const calendar = (
    <Calendar
      classes={{
        title: s.VacationAppFormCalendar__title
      }}
      dayClassName={handleSetDayClassName}
      title={!isMobile ? 'Календарь' : ''}
      value={calendarValue}
      disabled={!daysAvailable || isVacationsDisabled}
      onReset={handleReset}
      onChange={handleChange}
      {...props}
    />
  );

  return isMobile ? (
    <>
      <Button
        className={s.VacationAppFormCalendar__calendarButton}
        variant={showCalendar ? ButtonVariant.primary : ButtonVariant.secondary}
        size={ButtonSize.large}
        iconLeft={<CalendarIcon />}
        disabled={!daysAvailable || isVacationsDisabled}
        onClick={() => setShowCalendar(true)}
      >
        Календарь
      </Button>
      <BottomSheet
        title={'Календарь'}
        open={showCalendar}
        onDismiss={() => setShowCalendar(false)}
        snapPoints={({ minHeight }) => minHeight}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          {calendar}
        </div>
      </BottomSheet>
    </>
  ) : (
    calendar
  );
};
