import React from 'react';
import { FilterSelect, FilterSelectProps } from '../../ui/Filter/FilterSelect';
import { TensesEnum } from '../../../../graphql/types';

const options = [
  {
    label: 'Прошедшие',
    value: TensesEnum.Past
  },
  {
    label: 'Текущие',
    value: TensesEnum.Present
  },
  {
    label: 'Предстоящие',
    value: TensesEnum.Future
  }
];

export interface FilterKindsProps extends FilterSelectProps {}

export const FilterTense: React.FC<FilterKindsProps> = ({
  value,
  ...props
}) => {
  return (
    <FilterSelect
      value={value}
      options={options}
      placeholder={props.placeholder || 'Интервал'}
      isClearable
      {...props}
    />
  );
};
