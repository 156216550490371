import gql from 'graphql-tag';

export const FRAGMENT_PERMISSION = gql`
  fragment PermissionProps on PermissionType {
    id
    object_id
    object_type
    role_id
    user_id
    base_permission_code
  }
`;

export const FRAGMENT_ROLE = gql`
  fragment RoleProps on RoleType {
    id
    code
    created_at
    name
    updated_at
    permissions {
      ...PermissionProps
    }
  }

  ${FRAGMENT_PERMISSION}
`;

export const FRAGMENT_USER = gql`
  fragment userProps on UserType {
    id
    email
    login
    name
    permissions {
      ...PermissionProps
    }
    roles {
      ...RoleProps
    }
  }

  ${FRAGMENT_PERMISSION}
  ${FRAGMENT_ROLE}
`;

export const FRAGMENT_REFRESH_TOKEN = gql`
  fragment RefreshTokenProps on UserAuthTokenType {
    expires_at
    token
    type
  }
`;

export const FRAGMENT_AUTH_RESPONSE = gql`
  fragment AuthResponseProps on AuthResponseType {
    accessToken
    refreshToken {
      ...RefreshTokenProps
    }
    user {
      ...userProps
    }
  }

  ${FRAGMENT_REFRESH_TOKEN}
  ${FRAGMENT_USER}
`;

export const MUTATION_USE_REFRESH_TOKEN = gql`
  mutation mutationUseRefreshToken($token: String!) {
    authData: useRefreshToken(token: $token) {
      ...AuthResponseProps
    }
  }

  ${FRAGMENT_AUTH_RESPONSE}
`;

export const MUTATION_LOGIN = gql`
  mutation mutationLogin($emailOrLogin: String!, $password: String!) {
    authData: login(emailOrLogin: $emailOrLogin, password: $password) {
      ...AuthResponseProps
    }
  }

  ${FRAGMENT_AUTH_RESPONSE}
`;

export const MUTATION_LOGIN_WITH_ID = gql`
  mutation mutationLoginWithId($id: Int!) {
    authData: loginWithId(id: $id) {
      ...AuthResponseProps
    }
  }

  ${FRAGMENT_AUTH_RESPONSE}
`;

export const MUTATION_LOGOUT = gql`
  mutation mutationLogout($token: String!) {
    success: logout(token: $token)
  }
`;
