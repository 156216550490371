import React from 'react';
import { get } from 'lodash-es';
import { TableRowData } from '../../../common/components/ui/Table/Table';
import {
  ButtonAnchor,
  ButtonSize
} from '../../../common/components/ui/Button';
import s from '../ManualsPage.module.scss';

export interface ExternalLinkProps {
  row: TableRowData;
  field: string;
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({ row, field }) => {
  const value = get(row, field, undefined);
  return (
    <ButtonAnchor
      classes={{
        root: s.ExternalLink,
        content: s.ExternalLink__content
      }}
      size={ButtonSize.small}
      href={value}
      target="_blank"
      rel="noopener noreferrer"
    >
      {value}
    </ButtonAnchor>
  );
};
