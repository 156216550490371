import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import {
  Breadcrumbs,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { routeLinks } from '../../routeLinks';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { getInternshipsPageBreadcrumbs } from '../list/InternshipsPage';
import { Internships } from '../../../store/internships/Internships';
import { InternshipUtils } from '../../../store/internships/InternshipUtils';
import { InternshipForm } from '../InternshipForm/InternshipForm';
import { useInternship } from '../../../graphql/hooks/internships/useInternship';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import s from './InternshipEditPage.module.scss';

export interface InternshipEditPageProps
  extends RouteComponentProps<{ internshipId: string }> {
  history: AppHistoryType;
}

export function getInternshipEditPageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    ...getInternshipsPageBreadcrumbs(),
    {
      name: 'Редактирование',
      path: `${routeLinks.internships.to}/edit`
    }
  ];
}

export default function InternshipEditPage({
  match,
  history
}: InternshipEditPageProps) {
  const { internshipId } = match.params;

  const updateInternship = useGraphqlMutation(Internships.updateInternship);
  const updateInternshipRun = updateInternship.run;
  const updateInternshipLoading = updateInternship.loading;

  const internshipQuery = useInternship(internshipId);
  const internship = internshipQuery.state.data;

  const breadcrumbs = useMemo(() => getInternshipEditPageBreadcrumbs(), []);

  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
      setSubmitting(true);

      const input = InternshipUtils.fromUpdateForm(data);

      updateInternshipRun({ variables: { input } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }

          toast.success('Стажировка обновлена');
          history.push(`${routeLinks.internships.to}/${result.data.result.id}`);
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при изменении стажировки. Попробуйте снова'
          );
        })
        .then(() => {
          setSubmitting(false);
        });
    },
    [updateInternshipRun, history]
  );

  const handleCancel = useCallback(() => {
    if (window.confirm('Вы действительно хотите отменить редактирование?')) {
      history.goBack(routeLinks.internships.to);
    }
  }, [history]);

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle
          className={s.ProjectCreatePage__pageTitle}
          title={'Редактирование стажировки'}
          showHeading={true}
        />
        {queryLoader(internshipQuery) ||
          (internship && (
            <InternshipForm
              internship={internship ?? undefined}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              submissionLoading={updateInternshipLoading}
            />
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
