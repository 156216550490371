import { useFileSrc } from '../../../hooks/useFileSrc';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { Avatar, AvatarProps } from './Avatar';

export interface AvatarFileProps extends AvatarProps {
  fileId?: string | null;
}

export function AvatarFile({ fileId, ...props }: AvatarFileProps) {
  const src = useFileSrc(fileId, FileTypeEnum.avatar);
  return <Avatar {...props} src={src} />;
}
