import React, { useCallback } from 'react';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { STORE_LOCATION } from '../../../../store/storeKeys';
import {
  TransformedQueryParams,
  URL_KEY_PAGE,
  URL_KEY_SEARCH
} from '../../../../store/urlKeys';
import { FilterSearch } from '../../../../common/components/ui/Filter/FilterSearch';
import { PersonnelReserveFilter } from '../../../../graphql/types';
import s from './useReserveFilter.module.scss';

export const useReserveFilter = () => {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const query = (locationState.query || {}) as TransformedQueryParams;
  const changeQuery = locationStore.changeQuery;

  const filter: PersonnelReserveFilter = {
    search: query[URL_KEY_SEARCH]
  };

  const handleFilterChange = useCallback(
    (key: string, valueConverter?: (value) => any) => {
      return (value) => {
        const val = valueConverter ? valueConverter(value) : value;

        changeQuery({
          [key]: val,
          [URL_KEY_PAGE]: 1
        });
      };
    },
    [changeQuery]
  );

  const filterComponent = (
    <div className={s.ReserveFilter}>
      <FilterSearch
        value={filter.search ?? ''}
        onChange={handleFilterChange(URL_KEY_SEARCH)}
        placeholder={'Поиск'}
        className={s.ReserveFilter__filter}
        classes={{
          filter: { content: s.OffersPage__filterRoot },
          searchField: { root: s.OffersPage__searchField }
        }}
      />
    </div>
  );

  return [filterComponent, filter, handleFilterChange] as const;
};
