import React, { useMemo } from 'react';
import { ContextMenu } from '../../ContextMenu/ContextMenu';
import { IContentBlock } from './Content';

export type IBlockMenuItems = {
  label: string;
  blockProps: () => IContentBlock;
}[];

export interface BlockAddMenuProps {
  onBlockSelect: (blockProps: IContentBlock) => void;
  items: IBlockMenuItems;
}

export const BlockAddMenu: React.FC<BlockAddMenuProps> = ({
  onBlockSelect,
  items: itemsProp
}) => {
  const items = useMemo(() => {
    return itemsProp.map((item) => {
      return {
        label: item.label,
        onClick: () => onBlockSelect(item.blockProps())
      };
    });
  }, [itemsProp, onBlockSelect]);

  return <ContextMenu items={items} />;
};
