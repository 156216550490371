import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormikValues, useField } from 'formik';
import { OptionTypeBase } from 'react-select/src/types';
import {
  Title,
  TitleVariant
} from '../../../../common/components/ui/Title/Title';
import { ProjectParticipants } from '../../ProjectParticipants/ProjectParticipants';
import { AsyncEntitySelect } from '../../../../common/components/ui/AsyncSelect/entities/AsyncEntitySelect';
import { Entity } from '../../../../common/components/ui/AsyncSelect/entities/types';
import { NoData } from '../../../../common/components/ui/NoData/NoData';
import s from './ProjectFormParticipants.module.scss';

export interface ProjectFormParticipantsProps {
  name: string;
  values?: FormikValues;
}

const emptyArray = [];

export const ProjectFormParticipants: React.FC<ProjectFormParticipantsProps> = ({
  name,
  values
}) => {
  const [{ value: valueProp }, meta, { setValue }] = useField(name);
  const head_user_id = values?.head_user_id;
  const value = valueProp || emptyArray;

  const [selectUpdateKey, setSelectUpdateKey] = useState(1);

  const handleDelete = useCallback(
    (id) => {
      setValue(value.filter((v) => v.user_id !== id));
    },
    [value, setValue]
  );

  const handleUserSelected = useCallback(
    (user: OptionTypeBase | null | undefined) => {
      if (!user) return;
      const userAlreadySelected = !!value?.find((v) => v.id === user.value);
      if (!userAlreadySelected) {
        setValue([
          ...value,
          { user: { id: user.value, name: user.label }, user_id: user.value }
        ]);
      }
    },
    [value, setValue]
  );

  /**
   * фильтруем из возможных участников:
   * - уже выбранных участников,
   * - уже выбранного руководителя
   */
  const usersToAddFilter = useMemo(() => {
    const excludeIds = value.map((v) => v.user_id);
    if (head_user_id) excludeIds.push(head_user_id);

    return excludeIds.length > 0
      ? {
          exclude_ids: excludeIds
        }
      : {};
  }, [value, head_user_id]);

  useEffect(() => {
    setSelectUpdateKey((prev) => prev + 1);
  }, [usersToAddFilter]);

  return (
    <div className={s.ProjectFormParticipants}>
      <div className={s.ProjectFormParticipants__head}>
        <Title
          className={s.ProjectFormParticipants__title}
          variant={TitleVariant.h2}
          title={'Участники'}
        />

        <AsyncEntitySelect
          key={selectUpdateKey}
          className={s.ProjectFormParticipants__select}
          entity={Entity.usersPage}
          placeholder={'Добавьте участника'}
          value={null}
          filter={usersToAddFilter}
          onChange={handleUserSelected}
        />
      </div>
      <div className={s.ProjectFormParticipants__list}>
        {value && value.length > 0 ? (
          <ProjectParticipants
            participants={value}
            onDeleteClick={handleDelete}
            isEditable
          />
        ) : (
          <NoData>Ни одного участника не добавлено</NoData>
        )}
      </div>
    </div>
  );
};
