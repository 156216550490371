import React from 'react';
import { ErrorPage } from '../../common/components/ui/ErrorPage/ErrorPage';

export default function InDevPage() {
  return (
    <ErrorPage
      title="Раздел в разработке"
      description="Скоро здесь будут новые полезные функции"
    />
  );
}
