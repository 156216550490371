import { MutationOptions } from 'apollo-client';
import {
  MUTATION_CREATE_MANUAL,
  MUTATION_DELETE_MANUAL,
  MUTATION_UPDATE_MANUAL
} from '../../graphql/mutations/manuals';
import {
  ManualCreateInput,
  ManualType,
  ManualUpdateInput
} from '../../graphql/types';
import { clearCache } from '../../graphql/queriesUtils';

export class Manuals {
  private static refetchQueries = ['queryGetManuals'];
  private static updateQueries = ['manuals'];
  private static updateFragments = ['ManualType'];

  public static CreateManual: MutationOptions<
    {
      result: ManualType;
    },
    {
      input: ManualCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_MANUAL,
    refetchQueries: Manuals.refetchQueries,
    update: clearCache(Manuals.updateQueries, Manuals.updateFragments)
  };

  public static UpdateManual: MutationOptions<
    {
      result: ManualType;
    },
    {
      input: ManualUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_MANUAL,
    refetchQueries: Manuals.refetchQueries,
    update: clearCache(Manuals.updateQueries, Manuals.updateFragments)
  };

  public static DeleteManual: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_DELETE_MANUAL,
    refetchQueries: Manuals.refetchQueries,
    update: clearCache(Manuals.updateQueries, Manuals.updateFragments)
  };
}
