import { Field } from 'formik';
import React from 'react';
import { BaseCheckbox, BaseCheckboxProps } from '../../Checkbox/Checkbox';

export interface FormCheckboxProps extends Omit<BaseCheckboxProps, 'name'> {
  name: string;
}

export function FormCheckbox({ name, ...props }: FormCheckboxProps) {
  return (
    <Field name={name}>
      {({ field }) => (
        <BaseCheckbox checked={field.value ?? false} {...field} {...props} />
      )}
    </Field>
  );
}
interface FormCheckboxArrayProps extends FormCheckboxProps {
  checkedValue: string | number;
}
export function FormCheckboxArray({
  name,
  checkedValue,
  ...props
}: FormCheckboxArrayProps) {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <BaseCheckbox
          {...field}
          checked={field.value?.includes(checkedValue)}
          onChange={() => {
            const currentValue = field.value || [];
            form.setFieldValue(
              name,
              currentValue.includes(checkedValue)
                ? currentValue.filter((x) => x !== checkedValue)
                : [...currentValue, checkedValue]
            );
          }}
          {...props}
        />
      )}
    </Field>
  );
}
