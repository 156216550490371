import gql from 'graphql-tag';

export const QUERY_GET_CAMPUSES = gql`
  query queryGetCampuses($filter: CampusFilter) {
    campuses(filter: $filter) {
      id
      name
    }
  }
`;
