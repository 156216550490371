import { tryParseJson } from '@proscom/ui-utils';
import {
  ContentBlockVariant,
  IContentBlock
} from '../common/components/ui/Form/FormContentEditor/Content';

export function parseBlocks(content: string): IContentBlock[] {
  return tryParseJson(content, []) || [];
}

export function parseBlocksWithDefaultValue(
  content?: string | null
): IContentBlock[] {
  if (!content) {
    return [
      {
        data: { content: '' },
        variant: ContentBlockVariant.text
      }
    ];
  }
  return parseBlocks(content);
}

export function stringifyBlocks(content: IContentBlock[]): string {
  try {
    return JSON.stringify(content);
  } catch (e) {
    console.error(e);
  }
  return '';
}
