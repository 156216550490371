import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import {
  TransformedQueryParams,
  URL_KEY_CONTEST,
  URL_KEY_DURATION,
  URL_KEY_FINANCIAL_CONDITIONS,
  URL_KEY_LOCATION,
  URL_KEY_PAGE,
  URL_KEY_PURPOSE,
  URL_KEY_SEARCH,
  URL_KEY_SUBJECT_IDS,
  URL_KEY_TAG_IDS,
  URL_KEY_TRAINEE_CATEGORY
} from '../../../store/urlKeys';
import { STORE_LOCATION } from '../../../store/storeKeys';
import { FilterSearch } from '../../../common/components/ui/Filter/FilterSearch';
import {
  InternshipContest,
  InternshipDuration,
  InternshipFinancialConditions,
  InternshipLocation,
  InternshipPurpose,
  InternshipsFilter,
  InternshipTraineeCategory,
  Maybe
} from '../../../graphql/types';
import { FilterSelectAsyncMulti } from '../../../common/components/ui/Filter/FilterSelectAsyncMulti';
import { Entity } from '../../../common/components/ui/AsyncSelect/entities/types';
import {
  internshipContestOptions,
  internshipDurationOptions,
  internshipFinancialConditionsOptions,
  internshipLocationOptions,
  internshipPurposeOptions,
  internshipTraineeCategoryOptions
} from '../../../store/internships/InternshipTest';
import { FilterSelect } from '../../../common/components/ui/Filter/FilterSelect';
import s from './InternshipsPage.module.scss';

export const useInternshipsFilter = (onFilterChange?: () => void) => {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const query = (locationState.query || {}) as TransformedQueryParams;
  const changeQuery = locationStore.changeQuery;

  const filter: InternshipsFilter = {
    search: query[URL_KEY_SEARCH],
    internship_subject_ids: query[URL_KEY_SUBJECT_IDS],
    tag_ids: query[URL_KEY_TAG_IDS],
    trainee_category: query[URL_KEY_TRAINEE_CATEGORY] as Maybe<
      InternshipTraineeCategory[]
    >,
    purpose: query[URL_KEY_PURPOSE] as Maybe<InternshipPurpose[]>,
    duration: query[URL_KEY_DURATION] as Maybe<InternshipDuration[]>,
    location: query[URL_KEY_LOCATION] as Maybe<InternshipLocation[]>,
    contest: query[URL_KEY_CONTEST] as Maybe<InternshipContest[]>,
    financial_conditions: query[URL_KEY_FINANCIAL_CONDITIONS] as Maybe<
      InternshipFinancialConditions[]
    >
  };

  const handleFilterChange = useCallback(
    (key: string, valueConverter?: (value) => any) => {
      return (value) => {
        const val = valueConverter ? valueConverter(value) : value;

        changeQuery({
          [key]: val,
          [URL_KEY_PAGE]: 1
        });

        if (onFilterChange) {
          setTimeout(() => {
            onFilterChange();
          }, 1);
        }
      };
    },
    [changeQuery, onFilterChange]
  );

  const filterComponent: React.ReactNode = (
    <div className={s.InternshipsFilter}>
      <FilterSearch
        className={clsx(
          s.InternshipsFilter__filter,
          s.InternshipsFilter__filter_search
        )}
        classes={{
          filter: {
            content: s.InternshipsFilter__filterSearchContent,
            label: s.InternshipsFilter__filterSearchLabel
          }
        }}
        label={'Поиск'}
        value={filter.search || ''}
        onChange={handleFilterChange(URL_KEY_SEARCH)}
      />

      <FilterSelectAsyncMulti
        className={s.InternshipsFilter__filter}
        entity={Entity.internshipSubjectsPage}
        label={'Тип'}
        value={filter.internship_subject_ids}
        onChange={handleFilterChange(URL_KEY_SUBJECT_IDS)}
      />
      <FilterSelectAsyncMulti
        className={s.InternshipsFilter__filter}
        entity={Entity.tagsPage}
        label={'Теги'}
        value={filter.tag_ids}
        onChange={handleFilterChange(URL_KEY_TAG_IDS)}
      />
      <FilterSelect
        className={s.InternshipsFilter__filter}
        label={'Категория стажирующихся'}
        options={internshipTraineeCategoryOptions}
        value={filter.trainee_category as string[]}
        isMulti
        onChange={handleFilterChange(URL_KEY_TRAINEE_CATEGORY)}
      />
      <FilterSelect
        className={s.InternshipsFilter__filter}
        label={'Цель стажировки'}
        options={internshipPurposeOptions}
        value={filter.purpose as string[]}
        isMulti
        onChange={handleFilterChange(URL_KEY_PURPOSE)}
      />
      <FilterSelect
        className={s.InternshipsFilter__filter}
        label={'Период стажировки'}
        options={internshipDurationOptions}
        value={filter.duration as string[]}
        isMulti
        onChange={handleFilterChange(URL_KEY_DURATION)}
      />
      <FilterSelect
        className={s.InternshipsFilter__filter}
        label={'Место стажировки'}
        options={internshipLocationOptions}
        value={filter.location as string[]}
        isMulti
        onChange={handleFilterChange(URL_KEY_LOCATION)}
      />
      <FilterSelect
        className={s.InternshipsFilter__filter}
        label={'Наличие конкурса'}
        options={internshipContestOptions}
        value={filter.contest as string[]}
        isMulti
        onChange={handleFilterChange(URL_KEY_CONTEST)}
      />
      <FilterSelect
        className={s.InternshipsFilter__filter}
        label={'Финансовые условия'}
        options={internshipFinancialConditionsOptions}
        value={filter.financial_conditions as string[]}
        isMulti
        onChange={handleFilterChange(URL_KEY_FINANCIAL_CONDITIONS)}
      />
    </div>
  );

  return [filterComponent, filter] as const;
};
