import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { ReactComponent as IconClose } from '../../../../assets/img/icons/Close.svg';
import { Icon } from '../Icon/Icon';
import s from './Tag.module.scss';

export interface TagClasses {
  root?: string;
  content?: string;
}

export interface TagProps {
  className?: string;
  classes?: TagClasses;
  isRemovable?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  isClickable?: boolean;
  to?: string;
  children?: string | number;
  onRemoveClick?: () => void;
  onClick?: () => void;
}

export const Tag: React.FC<TagProps> = ({
  className,
  classes,
  isRemovable,
  isSelectable,
  isSelected,
  isClickable,
  to,
  children,
  onRemoveClick,
  onClick
}) => {
  const Component = (to ? Link : 'div') as React.ElementType;

  return (
    <Component
      className={clsx(s.Tag, className, classes?.root, {
        [s.Tag_selected]: isSelected,
        [s.Tag_selectable]: isSelectable,
        [s.Tag_removable]: isRemovable,
        [s.Tag_clickable]: isClickable,
        [s.Tag_link]: !!to
      })}
      title={children}
      to={to}
      onClick={onClick}
    >
      <div className={clsx(s.Tag__content, classes?.content)}>{children}</div>
      {isRemovable && (
        <Icon
          className={s.Tag__removeButton}
          icon={IconClose}
          boxSize={12}
          onClick={onRemoveClick}
        />
      )}
    </Component>
  );
};
