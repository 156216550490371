import React, { useCallback, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import { queryLoader } from '../../../utils/queryLoader';
import { useAuthGraphqlQuery } from '../../../../hooks/utils/useAuthGraphqlQuery';
import { QUERY_GET_BASE_PERMISSIONS } from '../../../../../graphql/queries/basePermissions';
import {
  PermissionInput,
  PermissionTypeEnum,
  RolesEnum,
  RoleType,
  UserType
} from '../../../../../graphql/types';
import { BaseTable } from '../../Table/Table';
import { TableRow } from '../../Table/TableRow/TableRow';
import { TableCell } from '../../Table/TableCell/TableCell';
import { Button, ButtonBaseColor, ButtonVariant } from '../../Button';
import { ReactComponent as AddIcon } from '../../../../../assets/img/icons/Add.svg';
import { isAdmin } from '../../../../../utils/permissions';
import { UserProps } from '../../../../../store/users/UserUtils';
import { UserPermissionsUtils } from './UserPermissionsUtils';
import { PermissionInputs } from './PermissionInputs';
import s from './Permissions.module.scss';

function getRolePermission(roles, selectedRoles) {
  const roleId = selectedRoles?.[0]?.id;
  if (!roles || !roleId) {
    return null;
  }
  return roles?.find((role) => role.id === roleId);
}

export function filterPermissionByRole(
  permission,
  role,
  getPermBaseCode = (perm) => perm.base_permission_code
) {
  if (!role) {
    return permission;
  }
  return permission.filter((perm) => {
    // Если Админ, то убираем все права, ибо все уже доступно.
    if (role.code === RolesEnum.Admin) {
      return false;
    }
    if (role.permissions?.length > 0) {
      // Проверяем, находим идентичные права
      const rolePerm = role.permissions.find(
        (rolePerm) => rolePerm.base_permission_code === getPermBaseCode(perm)
      );

      // Если предоставлено таргетное право, то оставляем
      if (rolePerm && rolePerm.object_id) {
        return true;
      }
      // Если фильтруем массив прав, оставляем права с типом объект(таргетные права)
      if (rolePerm && rolePerm.type === PermissionTypeEnum.Object) {
        return true;
      }
      // Если право у роли есть, то исключаем из собственного списка.
      // noinspection RedundantIfStatementJS Для читаемости кода
      if (rolePerm) {
        return false;
      }
      return true;
    }
    return true;
  });
}

const permissionsQueryOptions = {
  query: QUERY_GET_BASE_PERMISSIONS,
  mapData: (v) => v.basePermissions
};

interface PermissionsEditFieldProps {
  name: string;
  roles?: RoleType[];
  user: UserType;
}

export const PermissionsEditField = React.memo(function PermissionsEditField({
  name,
  roles,
  user
}: PermissionsEditFieldProps) {
  const [, meta, { setValue }] = useField(name);
  const { values } = useFormikContext<UserProps>();
  const permissions = useMemo(() => meta.value || [], [meta]);
  const { user: currentUser } = useCurrentUser();
  const isCurrentUser = useMemo(
    () => currentUser && user.id === currentUser.id,
    [user, currentUser]
  );

  const handlePermissionAdd = useCallback(() => {
    const newPermission: PermissionInput = {
      base_permission_code: '',
      object_id: null,
      object_type: null
    };
    setValue([...permissions, newPermission]);
  }, [setValue, permissions]);

  const handlePermissionDelete = useCallback(
    (iPermission) => {
      setValue(
        permissions.filter((permission, index) => index !== iPermission)
      );
    },
    [setValue, permissions]
  );

  const permissionsQuery = useAuthGraphqlQuery({
    queryOptions: permissionsQueryOptions
  });

  const permissionsData = permissionsQuery.state.data;

  const role = useMemo(() => getRolePermission(roles, values?.roles), [
    roles,
    values
  ]);
  const userPermissionsMap = useMemo(() => {
    if (!currentUser) return {};
    let adminPermissions;
    if (isAdmin(currentUser)) {
      adminPermissions = UserPermissionsUtils.getAdminPermission(
        permissionsData
      );
    }
    return UserPermissionsUtils.getAllPermissionsMap(
      currentUser,
      adminPermissions
    );
  }, [permissionsData, currentUser]);

  const userPermissions = useMemo(() => {
    return UserPermissionsUtils.getPermissionsFromMap(userPermissionsMap);
  }, [userPermissionsMap]);

  const allUserPermission = useMemo(
    () => filterPermissionByRole(userPermissions, role),
    [role, userPermissions]
  );

  const setCurrentPermissions = useCallback(() => {
    setValue(allUserPermission);
  }, [setValue, allUserPermission]);
  const removeAllPermissions = useCallback(() => {
    setValue([]);
  }, [setValue]);

  const filteredPermission = useMemo(() => {
    return permissionsData?.filter((permission) =>
      allUserPermission.some(
        (filteredPerm) => permission.code === filteredPerm.base_permission_code
      )
    );
  }, [permissionsData, allUserPermission]);

  return (
    <BaseTable>
      {userPermissions && (
        <TableRow>
          <TableCell>
            {!isCurrentUser && (
              <Button
                className={s.ActionButton}
                variant={ButtonVariant.secondary}
                onClick={setCurrentPermissions}
              >
                Задать собственные права
              </Button>
            )}
            {permissions.length > 0 && (
              <Button
                className={s.ActionButton}
                variant={ButtonVariant.secondary}
                baseColor={ButtonBaseColor.red}
                onClick={removeAllPermissions}
              >
                Убрать все права
              </Button>
            )}
          </TableCell>
        </TableRow>
      )}
      {wrapTableCell(queryLoader(permissionsQuery)) ||
        (values.permissions && (
          <PermissionInputs
            onDelete={handlePermissionDelete}
            permissions={filteredPermission}
            userPermissionsMap={userPermissionsMap}
          />
        ))}
      <TableRow>
        <TableCell className={s.Cell_button} colSpan={2}>
          <Button
            iconLeft={<AddIcon />}
            variant={ButtonVariant.secondary}
            baseColor={ButtonBaseColor.green}
            onClick={handlePermissionAdd}
          >
            Добавить право
          </Button>
        </TableCell>
      </TableRow>
    </BaseTable>
  );
});

function wrapTableCell(children) {
  if (!children) return children;
  return (
    <TableRow>
      <TableCell>{children}</TableCell>
    </TableRow>
  );
}
