import React, { useCallback } from 'react';
import { Form, FormikProps } from 'formik';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { GridItem, LayoutGrid } from '../../../common/components/Layout/Layout';
import { FormCoverLoader } from '../../../common/components/ui/Form/FormCoverLoader/FormCoverLoader';
import { FormSection } from '../../../common/components/ui/Form/FormSection/FormSection';
import { FormTextField } from '../../../common/components/ui/Form/FormTextField/FormTextField';
import { TextFieldSize } from '../../../common/components/ui/TextField/TextField';
import { FormTextArea } from '../../../common/components/ui/Form/FormTextArea/FormTextArea';
import { Button, ButtonVariant } from '../../../common/components/ui/Button';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import { FormTextEditor } from '../../../common/components/ui/Form/FormTextEditor/FormTextEditor';
import { routeLinks } from '../../routeLinks';
import { PersonnelReserveFormValues } from '../../../store/personnelReserve/PersonnelReserveUtils';
import s from './ReserveForm.module.scss';

export interface ReserveFormProps
  extends FormikProps<PersonnelReserveFormValues> {
  history: AppHistoryType;
  submissionLoading: boolean;
  reserveId?: string;
}

export const ReserveForm: React.FC<ReserveFormProps> = ({
  history,
  reserveId,
  submissionLoading
}) => {
  const handleCancel = useCallback(() => {
    if (window.confirm('Вы действительно хотите отменить редактирование?')) {
      let path = routeLinks.reserve.to;
      if (reserveId) path += `/${reserveId}`;
      history.goBack(path);
    }
  }, [history, reserveId]);

  return (
    <Form>
      <LayoutGrid>
        <GridItem cols={8}>
          <FormSection>
            <FormCoverLoader
              name={'cover_file_id'}
              fileType={FileTypeEnum.personnel_reserve_cover}
            />
          </FormSection>
          <FormSection>
            <FormTextField
              name={'name'}
              label={'Название'}
              size={TextFieldSize.large}
            />
          </FormSection>
          <FormSection>
            <FormTextArea
              name={'snippet'}
              label={'Краткое описание кадрового резерва'}
              hintMessage={'Отображается в карточке резерва под заголовком'}
            />
          </FormSection>
          <FormSection>
            <FormTextEditor name={'description'} />
          </FormSection>
        </GridItem>
      </LayoutGrid>
      <div className={s.ReserveForm__actions}>
        <Button variant={ButtonVariant.secondary} onClick={handleCancel}>
          Отменить всё
        </Button>
        <Button type={'submit'} loading={submissionLoading}>
          Сохранить изменения
        </Button>
      </div>
    </Form>
  );
};
