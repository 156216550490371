import { Routes, RouteType } from '../../common/components/utils/Routes';
import { RequireAuth } from '../RequireAuth';
import NotFoundPage from '../notFound/NotFoundPage';
import { hasPermission, UserPermission } from '../../utils/permissions';
import InternshipsPage from './list/InternshipsPage';
import InternshipPage from './index/InternshipPage';
import InternshipCreatePage from './create/InternshipCreatePage';
import InternshipEditPage from './edit/InternshipEditPage';
import { InternshipTestPage } from './test/InternshipTestPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: RequireAuth(InternshipsPage)
  },
  {
    path: '/create',
    exact: true,
    component: RequireAuth(InternshipCreatePage)
  },
  {
    path: '/test',
    exact: true,
    component: RequireAuth(InternshipTestPage)
  },
  {
    path: '/:internshipId',
    exact: true,
    component: RequireAuth(InternshipPage)
  },
  {
    path: '/:internshipId/edit',
    exact: true,
    component: RequireAuth(InternshipEditPage, (user, props) => {
      return hasPermission(user, UserPermission.InternshipCrud);
    })
  },
  {
    component: NotFoundPage
  }
];

const InternshipsRoutes = Routes(routes);

export default InternshipsRoutes;
