import { MutationOptions } from 'apollo-client';
import { UserType, UserUpdateInput } from '../../graphql/types';
import { clearCache } from '../../graphql/queriesUtils';
import { MUTATION_UPDATE_USER } from '../../graphql/mutations/users';
import { Entity } from '../../common/components/ui/AsyncSelect/entities/types';

export class Users {
  private static refetchQueries = [
    'getAreasOfInterestOptions',
    'getAreasOfInterest',
    'getSkills',
    'getSkillsOptions',
    'queryGetUsers',
    'queryGetUser',
    'queryGetPermissions',
    'queryGetRoles'
  ];
  private static updateQueries = [
    'areas_of_interest',
    Entity.areasOfInterestPage,
    'skills',
    Entity.skillsPage,
    'user',
    Entity.usersPage,
    Entity.rolesPage
  ];
  private static updateFragments = [
    'AreaOfInterestType',
    'SkillType',
    'UserType',
    'UserPageType',
    'RoleType',
    'PermissionType'
  ];

  public static updateUser: MutationOptions<
    {
      result: UserType;
    },
    {
      input: UserUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_USER,
    refetchQueries: Users.refetchQueries,
    update: clearCache(Users.updateQueries, Users.updateFragments)
  };
}
