import React, { useCallback, useMemo } from 'react';
import { Field, useField } from 'formik';
import { upperFirst } from 'lodash-es';

import {
  AsyncSelect,
  AsyncSelectSize
} from '../../../../common/components/ui/AsyncSelect/AsyncSelect';

import { BaseCheckbox } from '../../../../common/components/ui/Checkbox/Checkbox';
import { Card, CardStyle } from '../../../../common/components/ui/Card/Card';
import {
  Title,
  TitleVariant
} from '../../../../common/components/ui/Title/Title';
import {
  ActionButton,
  ActionType
} from '../../../../common/components/ui/ActionButton/ActionButton';

import { useAsyncEntityOptions } from '../../../../common/components/ui/AsyncSelect/entities/useAsyncEntityOptions';
import { Entity } from '../../../../common/components/ui/AsyncSelect/entities/types';
import {
  Button,
  ButtonBaseColor,
  ButtonSize,
  ButtonVariant
} from '../../../../common/components/ui/Button';
import s from './ParticipatingUnits.module.scss';

export function ParticipatingUnits({ name }) {
  const [LoadOptions] = useAsyncEntityOptions(Entity.unitsPage);

  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;
  const { error, touched } = meta;
  const [participantsAll] = useField<boolean>('participants_all');
  const hasValue = useMemo(() => value && value.length > 0, [value]);
  const resetValues = useCallback(() => {
    setValue([]);
  }, [setValue]);
  return (
    <div className={s.ParticipatingUnits}>
      <AsyncSelect
        label={'Выбор подразделений'}
        loadOptions={LoadOptions}
        onChange={(options) => {
          setValue(options);
        }}
        value={value}
        defaultOptions
        cacheOptions
        isMulti
        isClearable={false}
        size={AsyncSelectSize.large}
        hideValues={true}
        disabled={participantsAll.value}
        errorMessage={touched && error ? error : undefined}
        onBlur={field.onBlur}
      />
      {hasValue && !participantsAll.value && (
        <div className={s.ParticipatingUnits__participantsList}>
          <div className={s.ParticipatingUnits__participantsListHeader}>
            <Title variant={TitleVariant.h4}>Выбранные подразделения</Title>
            {value.length ? (
              <Button
                variant={ButtonVariant.secondary}
                size={ButtonSize.small}
                baseColor={ButtonBaseColor.red}
                onClick={resetValues}
              >
                Очистить
              </Button>
            ) : null}
          </div>
          <div className={s.ParticipatingUnits__unitsScrollContainer}>
            {value?.map((u, key) => (
              <Card
                key={u.id}
                className={s.ParticipatingUnits__unitCard}
                cardStyle={CardStyle.bordered}
              >
                <Title variant={TitleVariant.h4}>{upperFirst(u.name)}</Title>
                <Field
                  name={`participating_units[${key}].include_children`}
                  children={({ field }) => (
                    <BaseCheckbox
                      label={'Включить нижестоящие подразделения'}
                      checked={field.value}
                      rootProps={{
                        className: s.ParticipatingUnits__includeChildrenCheckbox
                      }}
                      {...field}
                    />
                  )}
                />
                <ActionButton
                  actionType={ActionType.delete}
                  className={s.ParticipatingUnits__unitCardRemove}
                  onClick={() => {
                    setValue(value?.filter((p) => u.id !== p.id));
                  }}
                />
              </Card>
            ))}
          </div>
        </div>
      )}
      <div className={s.ParticipatingUnits__participantsAll}>
        <Field
          name={'participants_all'}
          type={'checkbox'}
          children={({ field }) => (
            <BaseCheckbox
              label={'Доступно всем'}
              checked={field.value}
              rootProps={{
                className: s.ParticipatingUnits__participantsAllCheckbox
              }}
              {...field}
            />
          )}
        />
      </div>
    </div>
  );
}
