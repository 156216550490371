import React from 'react';
import { ResponsiveObject } from 'react-slick';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import nobelPic_1 from '../../../assets/img/enrollee/nobel-pic-1.png';
import nobelPic_2 from '../../../assets/img/enrollee/nobel-pic-2.png';
import nobelPic_3 from '../../../assets/img/enrollee/nobel-pic-3.png';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { ImagePreview } from '../../../common/components/ui/ImagePreview/ImagePreview';
import { SliderConvertible } from '../../../common/components/ui/Slider/Slider';
import { BREAKPOINT_VALUES, Breakpoints } from '../../../constants';
import s from './Nobel.module.scss';

const items = [
  {
    name: 'Пётр Капица',
    text: 'Нобелевский лауреат по физике 1978 года.',
    imageSrc: nobelPic_1
  },
  {
    name: 'Николай Семёнов',
    text: 'Нобелевский лауреат по химии 1956 года.',
    imageSrc: nobelPic_2
  },
  {
    name: 'Лев Ландау',
    text: 'Нобелевский лауреат по физике 1962 года.',
    imageSrc: nobelPic_3
  }
];

const sliderResponsive: ResponsiveObject[] = [
  {
    breakpoint: BREAKPOINT_VALUES[Breakpoints.tablet] - 1,
    settings: {
      slidesToShow: 1,
      dots: true
    }
  }
];

export const Nobel = () => {
  return (
    <LayoutContent className={s.Nobel}>
      <div className={s.Nobel__head}>
        <Title variant={TitleVariant.h2} className={s.Nobel__title}>
          Основатели Физтеха — нобелевские лауреаты
        </Title>
        <div className={s.Nobel__text}>
          Заложили основу образования в МФТИ — «систему Физтеха»: отбор самых
          талантливых абитуриентов и вовлечение студентов в реальную
          научно-исследовательскую работу под руководством выдающихся ученых в
          базовых организациях. Таких организаций-партнеров на Физтехе больше
          ста.
        </div>
      </div>

      <SliderConvertible
        config={{
          slidesToShow: 3,
          responsive: sliderResponsive
        }}
        convertedContent={
          <div className={s.Nobel__grid}>
            {items.map(({ name, text, imageSrc }, i) => (
              <div key={i} className={s.NobelCard}>
                <ImagePreview
                  classes={{
                    root: s.NobelCard__pic,
                    image: s.NobelCard__picImage
                  }}
                  imageSrc={imageSrc}
                />
                <div className={s.NobelCard__content}>
                  <Title
                    variant={TitleVariant.h4}
                    className={s.NobelCard__title}
                  >
                    {name}
                  </Title>
                  <div className={s.NobelCard__text}>{text}</div>
                </div>
              </div>
            ))}
          </div>
        }
      >
        {items.map(({ name, text, imageSrc }, i) => (
          <div key={i}>
            <div className={s.NobelCard}>
              <ImagePreview
                classes={{
                  root: s.NobelCard__pic,
                  image: s.NobelCard__picImage
                }}
                imageSrc={imageSrc}
              />
              <div className={s.NobelCard__content}>
                <Title variant={TitleVariant.h4} className={s.NobelCard__title}>
                  {name}
                </Title>
                <div className={s.NobelCard__text}>{text}</div>
              </div>
            </div>
          </div>
        ))}
      </SliderConvertible>
    </LayoutContent>
  );
};
