import React, { useCallback } from 'react';
import clsx from 'clsx';
import { OptionTypeBase } from 'react-select/src/types';
import { Entity } from '../AsyncSelect/entities/types';
import { AsyncSelectClasses } from '../AsyncSelect/AsyncSelect';
import {
  AsyncEntitySelect,
  AsyncEntitySelectProps
} from '../AsyncSelect/entities/AsyncEntitySelect';
import { Filter, FilterClasses, FilterProps } from './Filter';
import s from './Filter.module.scss';

export interface FilterSelectAsyncClasses {
  filter?: FilterClasses;
  select?: AsyncSelectClasses;
}

export interface FilterSelectAsyncSelfProps {
  classes?: FilterSelectAsyncClasses;
  onChange?: (value: string | null | undefined) => void;
}

export type FilterSelectAsyncProps<E extends Entity> = Omit<
  FilterProps,
  'classes'
> &
  AsyncEntitySelectProps<E> &
  FilterSelectAsyncSelfProps;

export function FilterSelectAsync<E extends Entity>({
  className,
  label,
  classes,
  onChange,
  ...props
}: FilterSelectAsyncProps<E>) {
  const handleChange = useCallback(
    (item: OptionTypeBase | null | undefined) => {
      onChange?.(item?.value);
    },
    [onChange]
  );

  return (
    <Filter
      className={clsx(s.FilterSelect, className)}
      classes={classes?.filter}
      label={label}
    >
      <AsyncEntitySelect
        {...props}
        classes={classes?.select}
        onChange={handleChange}
      />
    </Filter>
  );
}
