import React, { useCallback, useMemo } from 'react';
import { CoursesFilter } from '../../../../graphql/types';
import { useLocationQuery } from '../../../../common/hooks/utils/useLocationQuery';
import {
  URL_KEY_SHEET_VIEW,
  URL_KEY_SUBJECT_IDS
} from '../../../../store/urlKeys';
import { SheetView } from '../../../../common/types/sheet';
import { useCourseSubjectsPage } from '../../../../graphql/hooks/courses/useCoursesSubjects';
import { queryLoader } from '../../../../common/components/utils/queryLoader';
import { NoData } from '../../../../common/components/ui/NoData/NoData';
import { QueryPagination } from '../../../../common/components/ui/QueryPagination/QueryPagination';
import { routeLinks } from '../../../routeLinks';
import { CardCourseSubject } from '../../../../common/components/ui/Cards/CardCourseSubject/CardCourseSubject';
import s from './CoursesTiles.module.scss';

export interface CoursesTilesProps {
  coursesFilter?: CoursesFilter;
  page?: number;
}

export const CoursesTiles: React.FC<CoursesTilesProps> = ({
  coursesFilter,
  page
}) => {
  const [query, { changeQuery }] = useLocationQuery([] as const);

  const filter = useMemo(() => {
    return {
      input: {
        pagination: { page }
      },
      coursesFilter,
      coursesLimit: 6
    };
  }, [page, coursesFilter]);
  const courseSubjectsPageQuery = useCourseSubjectsPage(filter);
  const subjects = courseSubjectsPageQuery.state.data?.list;
  const header = courseSubjectsPageQuery.state.data?.header;

  const subjectsWithCourses = useMemo(() => {
    return subjects?.filter((s) => s.courses && s.courses.length > 0);
  }, [subjects]);
  const hasCourses = !!subjectsWithCourses && subjectsWithCourses.length > 0;

  const handleSubjectClick = useCallback(
    (subjectId) => {
      changeQuery({
        [URL_KEY_SUBJECT_IDS]: [subjectId],
        [URL_KEY_SHEET_VIEW]: SheetView.LIST
      });
    },
    [changeQuery]
  );

  return (
    queryLoader(courseSubjectsPageQuery) ||
    (!hasCourses && <NoData />) || (
      <>
        {subjectsWithCourses?.map((subject, iSubject) => (
          <div key={iSubject} className={s.CoursesTiles__item}>
            <CardCourseSubject
              {...subject}
              onClick={() => handleSubjectClick(subject.id)}
            />
          </div>
        ))}

        {header && (
          <QueryPagination header={header} pathname={routeLinks.courses.to} />
        )}
      </>
    )
  );
};
