import { shortenText } from '@proscom/ui-utils';
import { RouteLinkProps } from '../../../../routes/routeLinks';

export interface IBreadcrumbsEntity {
  id: string;
  name?: string;
}

export function getBreadcrumbItemEntity(
  path: string,
  entity: IBreadcrumbsEntity,
  overflowLength?: number
) {
  let name = entity.name;

  // Тернарный оператор нужен для TS, это не ошибка
  const shortText =
    name === undefined
      ? shortenText(name, overflowLength, 5)
      : shortenText(name, overflowLength, 5);

  return {
    name: shortText || '...',
    path: path + '/' + entity.id
  };
}

export function getBreadcrumbRouteLink(link: RouteLinkProps, query?: string) {
  return {
    name: link.text,
    path: query ? `${link.to}?${query}` : link.to
  };
}
