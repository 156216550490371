import React from 'react';
import { BaseLink } from '../../../../common/components/ui/BaseLink/BaseLink';
import { TableRowData } from '../../../../common/components/ui/Table/Table';
import s from './SystemLink.module.scss';

export interface SystemLinkProps {
  row: TableRowData;
  field: string;
}

export const SystemLink: React.FC<SystemLinkProps> = ({ row, field }) => (
  <BaseLink href={row[field]} className={s.SystemLink}>
    {row[field]}
  </BaseLink>
);
