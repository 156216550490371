import React, { ReactNode } from 'react';
import clsx from 'clsx';
import {
  Title,
  TitleVariant
} from '../../../../common/components/ui/Title/Title';
import s from './ContentBlock.module.scss';

export interface ContentBlockClasses {
  root?: string;
  label?: string;
  content?: string;
}

export interface ContentBlockProps {
  content: ReactNode;
  label?: string;
  className?: string;
  classes?: ContentBlockClasses;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  label,
  content,
  className,
  classes
}) => {
  return (
    <div className={clsx(s.ContentBlock, classes?.root, className)}>
      {label && (
        <Title
          variant={TitleVariant.h4}
          className={clsx(s.ContentBlock__label, classes?.label)}
        >
          {label}
        </Title>
      )}
      <div className={clsx(s.ContentBlock, classes?.content)}>{content}</div>
    </div>
  );
};
