import React from 'react';
import { PageLayout } from '../../Layout/PageLayout';
import { ErrorPageContent, ErrorPageContentProps } from './ErrorPageContent';

export interface ErrorPageProps extends ErrorPageContentProps {}

export function ErrorPage(props: ErrorPageProps) {
  return (
    <PageLayout isVerticalCentered={true}>
      <ErrorPageContent {...props} />
    </PageLayout>
  );
}
