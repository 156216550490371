import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { useUser } from '../../../graphql/hooks/useUser';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import {
  Breadcrumbs,
  getBreadcrumbItemEntity,
  IBreadcrumbsEntity,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { getUsersPageBreadcrumbs } from '../list/UsersPage';
import { routeLinks } from '../../routeLinks';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { UserUtils } from '../../../store/users/UserUtils';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { UserPermission } from '../../../utils/permissions';
import { UserPageContent } from './UserPageContent';

export interface UserPageProps extends RouteComponentProps<{ userId: string }> {
  history: AppHistoryType;
}

export function getUserPageBreadcrumbs({
  user
}: {
  user: IBreadcrumbsEntity;
}): IBreadcrumbsItem[] {
  return [
    ...getUsersPageBreadcrumbs(),
    getBreadcrumbItemEntity(routeLinks.users.to, user)
  ];
}

export default function UserPage({ match, history }: UserPageProps) {
  const { userId } = match.params;
  const { user: authUser } = useCurrentUser();
  const canEdit = useHasPermission(authUser, UserPermission.UserCrud);

  const userQuery = useUser(userId);
  const user = userQuery.state.data;
  const userName = UserUtils.getName(user);

  const breadcrumbs = useMemo(
    () =>
      getUserPageBreadcrumbs({
        user: {
          id: userId,
          name: userName
        }
      }),
    [userId, userName]
  );

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle title={[userName, 'Сотрудник']} />

        {queryLoader(userQuery) ||
          (!user ? (
            <NoData />
          ) : (
            <UserPageContent
              user={user}
              canEdit={canEdit}
              editLink={`${routeLinks.users.to}/${userId}/edit`}
            />
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
