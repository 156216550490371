import React from 'react';
import { useField } from 'formik';
import { TextArea, TextAreaProps } from '../../TextField/TextArea';

export interface FormTextAreaProps
  extends Omit<TextAreaProps, 'value' | 'onChange'> {
  name: string;
}

export const FormTextArea: React.FC<FormTextAreaProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { onChange, ...fieldProps } = field;

  const { error, touched } = meta;

  return (
    <TextArea
      rows={3}
      {...props}
      {...fieldProps}
      onChange={(value, e) => {
        onChange(e);
      }}
      errorMessage={touched && error ? error : undefined}
    />
  );
};
