import React, { useMemo } from 'react';
import { routeLinks } from '../../../../routeLinks';
import {
  ContextMenuButton,
  ContextMenuButtonPosition
} from '../../../../../common/components/ui/ContextMenu/ContextMenuButton';

export interface PollActionsProps {
  id: string;
}

export const PollActions: React.FC<PollActionsProps> = ({ id }) => {
  const actions = useMemo(() => {
    return [
      {
        label: 'Посмотреть результаты',
        to: `${routeLinks.polls.to}/${id}/attempts`
      }
    ];
  }, [id]);

  return (
    <ContextMenuButton
      items={actions}
      position={ContextMenuButtonPosition.left}
    />
  );
};
