import React from 'react';

export interface PollQuestionTitleProps {
  title: string;
  questionOrder?: number;
}

export const PollQuestionTitle: React.FC<PollQuestionTitleProps> = ({
  title,
  questionOrder
}) => {
  return (
    <div title={title} style={{ cursor: 'help' }}>
      Вопрос {questionOrder}
    </div>
  );
};
