import React from 'react';
import clsx from 'clsx';
import { Card, CardProps, CardVariant } from '../../Card/Card';
import { Button, ButtonAnchor, ButtonSize } from '../../Button';
import { Pattern, PatternsComponent } from '../../Card/patterns';
import { ReactComponent as PdfIcon } from '../../../../../assets/img/icons/pdf.svg';
import { ReactComponent as ArrowLinkIcon } from '../../../../../assets/img/icons/ArrowLink.svg';
import { ManualType, ManualTypeEnum } from '../../../../../graphql/types';
import s from './ManualCard.module.scss';

const buttonTitles = {
  [ManualTypeEnum.Video]: 'Открыть видео',
  [ManualTypeEnum.Infographics]: 'Посмотреть инфографику',
  [ManualTypeEnum.Manual]: 'Посмотреть инструкцию'
};

export interface ManualCardProps extends Omit<CardProps, 'actions'> {
  manual: ManualType;
  pattern: Pattern;
}

export const ManualCard: React.FC<ManualCardProps> = ({
  classes,
  manual,
  pattern,
  onClick,
  ...cardProps
}) => {
  const buttonText = buttonTitles[manual.type];

  const actions = manual.external_link ? (
    <ButtonAnchor
      classes={{
        root: s.ManualCard__button,
        iconLeft: s.ManualCard__buttonIconLeft
      }}
      iconLeft={<ArrowLinkIcon />}
      size={ButtonSize.large}
      href={manual.external_link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {buttonText}
    </ButtonAnchor>
  ) : (
    <Button
      classes={{
        root: s.ManualCard__button,
        iconLeft: s.ManualCard__buttonIconLeft
      }}
      iconLeft={<PdfIcon />}
      size={ButtonSize.large}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );

  return (
    <Card
      {...cardProps}
      classes={{
        ...classes,
        root: clsx(s.ManualCard, classes?.root),
        actions: s.ManualCard__actions
      }}
      variant={CardVariant.secondary}
      actions={actions}
      extContent={PatternsComponent[pattern]}
    />
  );
};
