import React from 'react';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { Card, CardStyle } from '../../../common/components/ui/Card/Card';
import { CardPattern } from '../../../common/components/ui/Card/patterns/CardPattern/CardPattern';
import {
  FigureType,
  FigureVariant
} from '../../../common/components/ui/Figures/types';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import QrCodeSrc from '../../../assets/img/enrollee/qr-ht-mipt.png';
import { ImagePreview } from '../../../common/components/ui/ImagePreview/ImagePreview';
import s from './Feedback.module.scss';

export const FeedBack = () => {
  return (
    <LayoutContent id={'feedback'} className={s.FeedBack}>
      <Title variant={TitleVariant.h2} className={s.FeedBack__title}>
        Обратная связь
      </Title>

      <Card
        className={s.FeedBackCard}
        cardStyle={CardStyle.bordered}
        extContent={
          <>
            <ImagePreview
              classes={{
                root: s.FeedbackCard__qr,
                image: s.FeedbackCard__qrImage
              }}
              imageSrc={QrCodeSrc}
            />

            <CardPattern
              className={s.FeedBackCard__pattern}
              classes={{
                item: s.FeedBackCard__patternItem
              }}
              figures={[
                {
                  className: s.FeedBackCard__circle,
                  type: FigureType.circle,
                  variant: FigureVariant.dark,
                  size: 'auto',
                  color: '#0075FF'
                }
              ]}
            />
          </>
        }
      >
        <div className={s.FeedBackCard__content}>
          <Title variant={TitleVariant.h3} className={s.FeedbackCard__title}>
            Добрый день! Вы обратились <br /> на горячую линию МФТИ
          </Title>
          <Title variant={TitleVariant.h4} className={s.FeedbackCard__warning}>
            Сканируйте QR-код и следуйте инструкциям <br />
            на экране своего смартфона
          </Title>
          <div className={s.FeedbackCard__text}>
            — Вся коммуникация носит конфиденциальный характер <br />— Мы
            получили Ваше обращение и ответим в течение 1—2 рабочих дней <br />
            — Конфиденциально высказывайте свои проблемы и предложения <br />—
            Доступно для обращений 24/7
          </div>
        </div>
      </Card>
    </LayoutContent>
  );
};
