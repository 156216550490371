import React, { FocusEventHandler, useState } from 'react';
import clsx from 'clsx';
import { ActionButton, ActionType } from '../ActionButton/ActionButton';
import {
  TextField,
  TextFieldClasses,
  TextFieldSize
} from '../TextField/TextField';
import { FileBox, LoadedFile } from '../FileBox/FileBox';
import { Loader } from '../Loader/Loader';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import {
  REQUIRED_MESSAGE,
  urlValidationSchema
} from '../../../../store/validationSchema';
import { FileUploadButton, FileUploadButtonClasses } from './FileUploadButton';
import s from './UploadField.module.scss';

export enum UploadFieldVariant {
  File = 'file',
  Link = 'link'
}

export interface UploadFieldClasses {
  root?: string;
  label?: string;
  input?: string;
  uploadButton?: string;
  actions?: string;
  gridItem?: string;
  textField?: TextFieldClasses;
  fileUploadButton?: FileUploadButtonClasses;
}

export type UploadFieldUploadPropsType = {
  fileName: string;
  file?: LoadedFile | null;
  link?: string;
};

export interface UploadFieldProps {
  label: string;
  onCancel: () => void;
  onUpload: (props: UploadFieldUploadPropsType) => void;
  variant?: UploadFieldVariant;
  fileType: FileTypeEnum;
  placeholder?: string;
  buttonText?: string;
  classes?: UploadFieldClasses;
  className?: string;
  loading?: boolean;
}

interface UploadFieldFileContentProps
  extends Pick<UploadFieldProps, 'classes' | 'fileType' | 'buttonText'> {
  fileName: string;
  file: LoadedFile | null;
  onUpload: (fileId: string, file: File) => void;
  onClear: () => void;
  onDelete: () => void;
}

const UploadFieldFileContent = ({
  classes,
  fileType,
  file,
  fileName,
  buttonText,
  onUpload,
  onClear,
  onDelete
}: UploadFieldFileContentProps) => {
  return file ? (
    <FileBox
      file={file}
      className={s.UploadField__file}
      editable={true}
      onDelete={() => {
        if (fileName === file?.file.name) {
          onClear();
        } else {
          onDelete();
        }
      }}
    />
  ) : (
    <FileUploadButton
      buttonText={buttonText}
      fileType={fileType}
      classes={{
        ...classes?.fileUploadButton,
        root: clsx(
          s.UploadField__fileLoadButton,
          classes?.fileUploadButton?.root
        )
      }}
      onFileUploaded={onUpload}
    />
  );
};

interface UploadFieldLinkContentProps {
  link: string;
  error: string;
  onChange: (value: string) => void;
  onValidate: (error: string) => void;
}

const UploadFieldLinkContent = ({
  link,
  error,
  onValidate,
  onChange
}: UploadFieldLinkContentProps) => {
  const handleBlur: FocusEventHandler = (e) => {
    try {
      urlValidationSchema
        .required(REQUIRED_MESSAGE)
        .validateSync((e.target as HTMLInputElement).value);
      onValidate('');
    } catch (err) {
      onValidate(err.message);
    }
  };

  return (
    <TextField
      classes={{
        root: s.UploadField__linkField,
        input: s.UploadField__linkInput,
        helperText: s.UploadField__hint
      }}
      value={link}
      size={TextFieldSize.large}
      label={'Ссылка'}
      placeholder={'https:// ...'}
      onChange={onChange}
      onBlur={handleBlur}
      errorMessage={error}
    />
  );
};

export const UploadField: React.FC<UploadFieldProps> = ({
  label,
  placeholder = 'Введите название...',
  buttonText,
  onCancel,
  onUpload,
  variant = UploadFieldVariant.File,
  fileType,
  loading,
  classes,
  className,
  children
}) => {
  const [fileName, setFileName] = useState('');
  const [link, setLink] = useState('');
  const [linkError, setLinkError] = useState('');
  const [file, setFile] = useState<LoadedFile | null>(null);

  const approveAvailable =
    fileName &&
    (file || link) &&
    (variant === UploadFieldVariant.File || !linkError);

  const handleFileDelete = () => {
    setFile(null);
  };

  const handleClear = () => {
    setFileName('');
    setLink('');
    setFile(null);
  };

  const handleCancel = () => {
    handleClear();
    onCancel();
  };

  const handleUpload = () => {
    if (!approveAvailable) return;
    onUpload({
      fileName,
      file,
      link
    });
    handleClear();
  };

  const handleFileUploaded = (fileId: string, file: File) => {
    if (!fileName) {
      setFileName(file.name);
    }
    setFile({
      file,
      fileId
    });
  };

  const content = (() => {
    switch (variant) {
      case UploadFieldVariant.File:
        return (
          <UploadFieldFileContent
            file={file}
            fileType={fileType}
            fileName={fileName}
            buttonText={buttonText}
            onUpload={handleFileUploaded}
            onClear={handleClear}
            onDelete={handleFileDelete}
          />
        );
      case UploadFieldVariant.Link:
        return (
          <UploadFieldLinkContent
            link={link}
            error={linkError}
            onValidate={setLinkError}
            onChange={setLink}
          />
        );
      default:
        return null;
    }
  })();

  return (
    <div className={clsx(s.UploadField, className, classes?.root)}>
      <div
        className={clsx(
          s.UploadField__gridItem,
          s.UploadField__gridTextField,
          classes?.gridItem
        )}
      >
        <TextField
          value={fileName}
          size={TextFieldSize.large}
          label={label}
          placeholder={placeholder}
          classes={{
            ...classes?.textField,
            root: clsx(s.UploadField__textField, classes?.textField?.root),
            input: clsx(s.UploadField__textInput, classes?.textField?.input)
          }}
          onFocus={(event) => {
            if (event.target.value === file?.file.name) {
              event.target.select();
            }
          }}
          onChange={setFileName}
        />
      </div>
      <div
        className={clsx(
          s.UploadField__gridItem,
          s.UploadField__gridButton,
          classes?.gridItem
        )}
      >
        {loading ? <Loader size={28} /> : content}
      </div>
      <div
        className={clsx(
          s.UploadField__gridItem,
          s.UploadField__gridActions,
          classes?.gridItem
        )}
      >
        <div className={s.UploadField__actionsWrapper}>
          <div className={clsx(s.UploadField__actions, classes?.actions)}>
            <ActionButton
              actionType={ActionType.approve}
              disabled={!approveAvailable}
              onClick={handleUpload}
            />
            <ActionButton
              actionType={ActionType.cancel}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
      {children && (
        <div
          className={clsx(
            s.UploadField__gridItem,
            s.UploadField__gridExt,
            classes?.gridItem
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};
