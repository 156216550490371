import { MutationOptions } from 'apollo-client';
import {
  VacationApplicationInput,
  VacationApplicationType,
  VacationScheduleInput,
  VacationScheduleType
} from '../../graphql/types';
import { clearCache } from '../../graphql/queriesUtils';
import {
  MUTATION_LEAVE_VACATION_APPLICATION,
  MUTATION_UPDATE_VACATION_SCHEDULE
} from '../../graphql/mutations/vacations';

export class Vacations {
  private static refetchQueries = [
    'queryGetVacationApplicationsPage',
    'queryGetVacationBalance',
    'queryGetVacationSchedules'
  ];
  private static updateQueries = [
    'vacationApplicationsPage',
    'vacationBalance',
    'vacationSchedules'
  ];
  private static updateFragments = [
    'VacationApplicationsPageType',
    'VacationBalanceType',
    'VacationScheduleType'
  ];

  public static leaveVacationApplication: MutationOptions<
    {
      result: VacationApplicationType;
    },
    {
      input: VacationApplicationInput;
    }
  > = {
    mutation: MUTATION_LEAVE_VACATION_APPLICATION,
    refetchQueries: Vacations.refetchQueries,
    update: clearCache(Vacations.updateQueries, Vacations.updateFragments)
  };

  public static updateVacationSchedule: MutationOptions<
    {
      result: VacationScheduleType[];
    },
    {
      input: VacationScheduleInput;
    }
  > = {
    mutation: MUTATION_UPDATE_VACATION_SCHEDULE,
    refetchQueries: Vacations.refetchQueries,
    update: clearCache(Vacations.updateQueries, Vacations.updateFragments)
  };
}
