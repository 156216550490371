import gql from 'graphql-tag';
import { FRAGMENT_EXTERNAL_POLL_FULL } from '../fragments/polls';

export const MUTATION_CREATE_EXTERNAL_POLL = gql`
  mutation mutationCreateExternalPoll($input: ExternalPollCreateInput!) {
    result: createExternalPoll(input: $input) {
      ...ExternalPollFull
    }
  }

  ${FRAGMENT_EXTERNAL_POLL_FULL}
`;

export const MUTATION_UPDATE_EXTERNAL_POLL = gql`
  mutation mutationUpdateExternalPoll($input: ExternalPollUpdateInput!) {
    result: updateExternalPoll(input: $input) {
      ...ExternalPollFull
    }
  }

  ${FRAGMENT_EXTERNAL_POLL_FULL}
`;

export const MUTATION_DELETE_EXTERNAL_POLL = gql`
  mutation mutationDeleteExternalPoll($id: ID!) {
    result: deleteExternalPoll(external_poll_id: $id)
  }
`;

export const MUTATION_CREATE_POLL_ATTEMPT = gql`
  mutation mutationCreatePollAttempt($input: PollAttemptCreateInput!) {
    result: createPollAttempt(input: $input) {
      id
      poll_id
    }
  }
`;
