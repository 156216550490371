import { useCallback, useEffect, useRef, useState } from 'react';
import { checkElementIsInView } from '../../utils/dom';

export type HashElementType = {
  el: HTMLElement;
  hash: string;
};

export function useHashInView(hashes: string[]) {
  const [hashesInView, setHashesInView] = useState<string[]>([]);
  const hashesInViewRef = useRef<string[]>([]);
  const hashElementsRef = useRef<HashElementType[]>([]);

  useEffect(() => {
    hashes.forEach((hash) => {
      const el = document.getElementById(hash);
      if (el) {
        hashElementsRef.current.push({
          el,
          hash
        });
      }
    });
  }, [hashes]);

  const handleCheck = useCallback(() => {
    const hashesInView = hashElementsRef.current
      .filter((hashEl) => {
        return checkElementIsInView(hashEl.el);
      })
      .map((el) => el.hash);

    const changed =
      hashesInView.length !== hashesInViewRef.current.length ||
      hashesInView.some((hash) => {
        return hashesInViewRef.current.indexOf(hash) === -1;
      });

    if (changed) {
      hashesInViewRef.current = hashesInView;
      setHashesInView(hashesInView);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('load', handleCheck);
    window.addEventListener('scroll', handleCheck);
    window.addEventListener('resize', handleCheck);

    return () => {
      window.removeEventListener('load', handleCheck);
      window.removeEventListener('scroll', handleCheck);
      window.removeEventListener('resize', handleCheck);
    };
  }, [handleCheck]);

  return hashesInView;
}
