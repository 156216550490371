import React, { FormEvent, useCallback, useState } from 'react';
import clsx from 'clsx';
import { TextField, TextFieldSize } from '../TextField/TextField';
import { ActionButton, ActionType } from '../ActionButton/ActionButton';
import s from './PriorityField.module.scss';

export interface PriorityFieldClasses {
  root?: string;
  label?: string;
  inputs?: string;
  actions?: string;
}

export interface PriorityFieldProps {
  className?: string;
  classes?: PriorityFieldClasses;
  priority?: number | null;
  label?: string;
  showLabel?: boolean;
  showActions?: boolean;
  onOrderChange?: (priority: number | null) => void;
  onOrderChangeSubmit?: (priority: number | null) => void;
}

export const PriorityField: React.FC<PriorityFieldProps> = ({
  className,
  classes,
  priority,
  label = 'Приоритет',
  showLabel = true,
  showActions = true,
  onOrderChange,
  onOrderChangeSubmit
}) => {
  const priorityOrder = priority?.toString() || '';

  const [orderNum, setOrderNum] = useState(priorityOrder);

  const handleOrderChangeSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onOrderChangeSubmit?.(orderNum ? +orderNum : null);
    },
    [onOrderChangeSubmit, orderNum]
  );

  const handleOrderChange = useCallback(
    (value) => {
      setOrderNum(value);
      onOrderChange?.(value ? +value : null);
    },
    [onOrderChange]
  );

  const handleOrderChangeCancel = useCallback(() => {
    setOrderNum(priorityOrder);
  }, [priorityOrder]);

  return (
    <form
      className={clsx(s.PriorityField, className, classes?.root)}
      onSubmit={handleOrderChangeSubmit}
      action="/"
    >
      {showLabel && label && (
        <span className={clsx(s.PriorityField__label, classes?.label)}>
          {label}
        </span>
      )}
      <div className={clsx(s.PriorityField__inputs, classes?.inputs)}>
        <TextField
          className={s.PriorityField__input}
          value={orderNum}
          size={TextFieldSize.small}
          onChange={handleOrderChange}
        />
        {showActions && orderNum !== priorityOrder && (
          <div className={clsx(s.PriorityField__actions, classes?.actions)}>
            <ActionButton
              className={s.PriorityField__action}
              actionType={ActionType.approve}
              type={'submit'}
            />
            <ActionButton
              className={s.PriorityField__action}
              actionType={ActionType.cancel}
              onClick={handleOrderChangeCancel}
            />
          </div>
        )}
      </div>
    </form>
  );
};
