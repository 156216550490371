import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { EMDASH, joinNonEmpty } from '@proscom/ui-utils';
import { FormikValues } from 'formik';
import {
  Table,
  TableColumn,
  TableRowProps
} from '../../../../common/components/ui/Table/Table';
import { TableRow } from '../../../../common/components/ui/Table/TableRow/TableRow';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { TableCell } from '../../../../common/components/ui/Table/TableCell/TableCell';
import { Modal } from '../../../../common/components/ui/Modal/Modal';
import {
  FormSection,
  FormSectionVariant
} from '../../../../common/components/ui/Form/FormSection/FormSection';
import { TitleVariant } from '../../../../common/components/ui/Title/Title';
import { useFeedbackPage } from '../../../../graphql/hooks/feedback/useFeedbackPage';
import { useLocationQuery } from '../../../../common/hooks/utils/useLocationQuery';
import { URL_KEY_FEEDBACK_ID, URL_KEY_PAGE } from '../../../../store/urlKeys';
import { queryLoader } from '../../../../common/components/utils/queryLoader';
import { NoData } from '../../../../common/components/ui/NoData/NoData';
import { routeLinks } from '../../../routeLinks';
import { QueryPagination } from '../../../../common/components/ui/QueryPagination/QueryPagination';
import { useFormatDate } from '../../../../common/hooks/useFormatDate';
import { DATE_FORMAT_FULL, setIsoEndOfDay } from '../../../../utils/date/date';
import { FeedbackExport } from '../../FeedbackExport/FeedbackExport';
import { useFeedback } from '../../../../graphql/hooks/feedback/useFeedback';
import { FeedbackType } from '../../../../graphql/types';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { FileLoaderList } from '../../../../common/components/ui/FileLoader/FileLoaderList';
import s from '../FeedbackPage.module.scss';

const columns: TableColumn[] = [
  {
    className: s.FeedbackCell_date,
    title: 'Дата создания',
    field: 'created_at'
  },
  {
    title: 'Контакт',
    field: 'contact'
  },
  {
    title: 'Тема обращения',
    field: 'topic'
  },
  {
    className: s.FeedbackCell_text,
    title: 'Сообщение',
    field: 'message'
  },
  {
    title: 'Файлы',
    field: 'files'
  }
];

interface FeedbackModalContentProps extends FeedbackType {}

const FeedbackModalContent: React.FC<FeedbackModalContentProps> = (data) => {
  const date = useFormatDate(data.created_at, DATE_FORMAT_FULL);

  const name = useMemo(() => {
    return joinNonEmpty(
      [data.creator.surname, data.creator.name, data.creator.patronymic],
      ' '
    );
  }, [data]);

  return (
    <div className={s.FeedbackModalContent}>
      <FormSection
        variant={FormSectionVariant.medium}
        titleVariant={TitleVariant.h3}
        title={'Дата создания обращения:'}
      >
        {date || EMDASH}
      </FormSection>
      <FormSection
        variant={FormSectionVariant.medium}
        titleVariant={TitleVariant.h3}
        title={'Контакт:'}
      >
        {name && <div className={s.FeedbackModalContent__name}>{name}</div>}
        <div className={s.FeedbackModalContent__email}>
          {data.creator.email}
        </div>
        {data.phone && (
          <div className={s.FeedbackModalContent__phone}>{data.phone}</div>
        )}
      </FormSection>
      <FormSection
        variant={FormSectionVariant.medium}
        titleVariant={TitleVariant.h3}
        title={'Тема обращения:'}
      >
        {data.topic}
      </FormSection>
      <FormSection
        variant={FormSectionVariant.medium}
        titleVariant={TitleVariant.h3}
        title={'Сообщение:'}
      >
        <div className={s.FeedbackModalContent__text}>{data.message}</div>
      </FormSection>

      {data.document_files && data.document_files.length > 0 && (
        <FormSection
          variant={FormSectionVariant.medium}
          titleVariant={TitleVariant.h3}
          title={'Файлы'}
        >
          <FileLoaderList
            className={s.FeedbackModalContent__files}
            fileType={FileTypeEnum.feedback_document}
            files={data.document_files}
          />
        </FormSection>
      )}
    </div>
  );
};

const FeedbackRow: React.FC<TableRowProps> = ({ row, columns, onClick }) => {
  const date = useFormatDate(row.created_at, DATE_FORMAT_FULL);

  const name = useMemo(() => {
    return joinNonEmpty(
      [row.creator.surname, row.creator.name, row.creator.patronymic],
      ' '
    );
  }, [row]);

  return (
    <TableRow className={s.FeedbackRow} onClick={onClick}>
      <TableCell className={clsx(s.FeedbackCell, s.FeedbackCell_date)}>
        <div className={s.FeedbackCell__content}>{date || EMDASH}</div>
      </TableCell>

      <TableCell className={clsx(s.FeedbackCell, s.FeedbackCell_contact)}>
        <div className={s.FeedbackCell__content}>
          {name && <div className={s.FeedbackCell__name}>{name}</div>}
          <div className={s.FeedbackCell__email}>{row.creator.email}</div>
          {row.phone && (
            <div className={s.FeedbackCell__phone}>{row.phone}</div>
          )}
        </div>
      </TableCell>

      <TableCell className={clsx(s.FeedbackCell, s.FeedbackCell_topic)}>
        <div className={s.FeedbackCell__content}>{row.topic || EMDASH}</div>
      </TableCell>

      <TableCell className={clsx(s.FeedbackCell, s.FeedbackCell_message)}>
        <div className={s.FeedbackCell__content}>{row.message || EMDASH}</div>
      </TableCell>

      <TableCell className={clsx(s.FeedbackCell, s.FeedbackCell_files)}>
        <div className={s.FeedbackCell__content}>
          {row.document_files?.length || ''}
        </div>
      </TableCell>
    </TableRow>
  );
};

export const FeedbackList = () => {
  const [modalActive, setModalActive] = useState(false);
  const { isMobile } = useWindowSize();
  const [query, locationStore] = useLocationQuery([
    URL_KEY_PAGE,
    URL_KEY_FEEDBACK_ID
  ] as const);
  const changeQuery = locationStore.changeQuery;
  const feedbackId = query[URL_KEY_FEEDBACK_ID];
  const [
    exportFilterValues,
    setExportFilterValues
  ] = useState<FormikValues | null>(null);

  const feedbackQuery = useFeedback(feedbackId);
  const feedbackItem = feedbackQuery.state.data;

  const feedbackPageQuery = useFeedbackPage({
    filter: {
      date_range: {
        start: exportFilterValues?.start_from,
        end: setIsoEndOfDay(exportFilterValues?.end_at)
      }
    },
    pagination: { page: query[URL_KEY_PAGE] }
  });
  const feeds = feedbackPageQuery.state.data?.list;
  const header = feedbackPageQuery.state.data?.header;
  const hasFeed = !!feeds && feeds.length > 0;

  const handleRowClick = useCallback(
    (data) => {
      changeQuery({
        [URL_KEY_FEEDBACK_ID]: data.id
      });
    },
    [changeQuery]
  );

  const handleModalClosed = useCallback(() => {
    changeQuery({
      [URL_KEY_FEEDBACK_ID]: undefined
    });
  }, [changeQuery]);

  useEffect(() => {
    setModalActive(!!feedbackId);
  }, [feedbackId]);

  return (
    <>
      <div className={s.FeedbackList__export}>
        <FeedbackExport
          exportDisabled={!hasFeed}
          onFormChange={setExportFilterValues}
        />
      </div>

      {queryLoader(feedbackPageQuery) ||
        (hasFeed ? (
          <>
            <Table
              columns={columns}
              data={feeds || []}
              rowComponent={FeedbackRow}
              onRowClick={handleRowClick}
              hideHeader={isMobile}
            />

            {hasFeed && header && (
              <QueryPagination
                header={header}
                pathname={routeLinks.feedback.to}
              />
            )}

            <Modal
              className={s.FeedbackList__modal}
              title={'Обращение'}
              isOpen={modalActive}
              onClose={() => setModalActive(false)}
              onClosed={handleModalClosed}
            >
              {queryLoader(feedbackQuery) ||
                (feedbackItem && <FeedbackModalContent {...feedbackItem} />)}
            </Modal>
          </>
        ) : (
          <NoData />
        ))}
    </>
  );
};
