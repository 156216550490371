export class CustomError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, this.constructor.prototype);
    this.name = this.constructor.name;
  }

  toJSON() {
    return {
      message: this.message,
      stack: this.stack
    };
  }
}
