import gql from 'graphql-tag';
import { FRAGMENT_OFFER_FULL } from '../fragments/offers';

export const MUTATION_CREATE_OFFER = gql`
  mutation mutationCreateOffer($input: OfferCreateInput!) {
    result: createOffer(input: $input) {
      ...OfferFull
    }
  }

  ${FRAGMENT_OFFER_FULL}
`;

export const MUTATION_UPDATE_OFFER = gql`
  mutation mutationUpdateOffer($input: OfferUpdateInput!) {
    result: updateOffer(input: $input) {
      ...OfferFull
    }
  }

  ${FRAGMENT_OFFER_FULL}
`;

export const MUTATION_DELETE_OFFER = gql`
  mutation mutationDeleteOffer($id: ID!) {
    result: deleteOffer(offer_id: $id)
  }
`;
