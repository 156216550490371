import React from 'react';
import { TextArea } from '../../TextField/TextArea';
import { IContentHeader } from '../../Form/FormContentEditor/Content';
import { SpecificContentBlockEditableProps } from '../types';

export const HeaderBlockEditable: React.FC<
  SpecificContentBlockEditableProps<IContentHeader>
> = ({ className, data, onChange }) => {
  const { content, headerVariant } = data;
  // todo выбор варианта заголовка
  return (
    <TextArea
      value={content || ''}
      onChange={(content) => {
        onChange({ ...data, content });
      }}
    />
  );
};
