import React, { useCallback, useMemo } from 'react';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';
import { Formik } from 'formik';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  Breadcrumbs,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { getArticlesPageBreadcrumbs } from '../list/ArticlesPage';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { Articles } from '../../../store/articles/Articles';
import { ArticleForm } from '../ArticleForm/ArticleForm';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import {
  ArticleProps,
  ArticleUtils
} from '../../../store/articles/ArticleUtils';
import { routeLinks } from '../../routeLinks';
import s from '../edit/ArticleEditPage.module.scss';

export interface ArticleCreatePageProps extends RouteComponentProps {
  history: AppHistoryType;
}

export function getArticleCreatePageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    ...getArticlesPageBreadcrumbs(),
    {
      name: 'Создание',
      path: `${routeLinks.articles.to}/create`
    }
  ];
}

export default function ArticleCreatePage({ history }: ArticleCreatePageProps) {
  const createArticle = useGraphqlMutation(Articles.createArticle);
  const createArticleRun = createArticle.run;

  const handleSubmit = useCallback(
    (data: ArticleProps) => {
      const input = ArticleUtils.fromCreateForm(data);

      createArticleRun({ variables: { input } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Новость добавлена');
            history.push(`${routeLinks.articles.to}/${result.data.result.id}`);
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при создании новости. Попробуйте снова'
          );
        });
    },
    [createArticleRun, history]
  );

  const handleCancel = useCallback(() => {
    if (window.confirm('Вы действительно хотите отменить редактирование?')) {
      history.goBack(routeLinks.articles.to);
    }
  }, [history]);

  const breadcrumbs = useMemo(() => getArticleCreatePageBreadcrumbs(), []);

  return (
    <PageLayout
      breadcrumbs={<Breadcrumbs items={breadcrumbs} />}
      contentClassName={s.ArticleEditPage}
    >
      <LayoutContent>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={8}>
            <PageTitle
              className={s.ArticleEditPage__pageTitle}
              title={'Создание новости'}
              showHeading
            />
            <Formik
              initialValues={ArticleUtils.toForm()}
              validationSchema={ArticleUtils.validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <ArticleForm
                  onCancel={handleCancel}
                  submissionLoading={createArticle.loading}
                  {...props}
                />
              )}
            </Formik>
          </LayoutGrid.GridItem>
        </LayoutGrid>
      </LayoutContent>
    </PageLayout>
  );
}
