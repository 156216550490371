import gql from 'graphql-tag';
import {
  FRAGMENT_COURSE_FULL,
  FRAGMENT_COURSE_SNIPPET
} from '../fragments/courses';
import { FRAGMENT_PAGINATION_HEADER } from '../fragments/common';

export const QUERY_GET_COURSE = gql`
  query queryGetCourse($id: ID!) {
    course(course_id: $id) {
      ...CourseFull
    }
  }
  ${FRAGMENT_COURSE_FULL}
`;

export const QUERY_GET_COURSES_PAGE = gql`
  query queryGetCoursesPage($input: CoursesPageInput) {
    coursesPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...CourseSnippet
      }
    }
  }

  ${FRAGMENT_COURSE_SNIPPET}
  ${FRAGMENT_PAGINATION_HEADER}
`;

export const QUERY_GET_UNPUBLISHED_COURSES_PAGE = gql`
  query queryGetUnpublishedCoursesPage($input: CoursesPageInput) {
    unpublishedCoursesPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...CourseSnippet
      }
    }
  }

  ${FRAGMENT_COURSE_SNIPPET}
  ${FRAGMENT_PAGINATION_HEADER}
`;

export const QUERY_GET_COURSE_TEST_RESULT = gql`
  query queryGetCourseTestResult {
    courseTestResult
  }
`;

export const QUERY_GET_COURSE_SUBJECTS_PAGE = gql`
  query queryGetCourseSubjectsPage(
    $input: CourseSubjectsPageInput
    $coursesFilter: CoursesFilter
    $coursesLimit: Int
  ) {
    courseSubjectsPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        id
        name
        count_courses
        courses(filter: $coursesFilter, limit: $coursesLimit) {
          ...CourseSnippet
        }
      }
    }
  }

  ${FRAGMENT_COURSE_SNIPPET}
  ${FRAGMENT_PAGINATION_HEADER}
`;
