import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { routeLinks } from '../../../routeLinks';
import { useGraphqlMutation } from '../../../../common/hooks/utils/useGraphqlMutation';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import { CardCourse } from '../../../../common/components/ui/Cards/CardCourse/CardCourse';
import { useLocationQuery } from '../../../../common/hooks/utils/useLocationQuery';
import {
  URL_KEY_ID,
  URL_KEY_PAGE,
  URL_KEY_SUBJECT_IDS,
  URL_KEY_TAG_IDS
} from '../../../../store/urlKeys';
import { QueryPagination } from '../../../../common/components/ui/QueryPagination/QueryPagination';
import { NoData } from '../../../../common/components/ui/NoData/NoData';
import { CourseAnnounce } from '../../index/CourseAnnounce/CourseAnnounce';
import { Modal } from '../../../../common/components/ui/Modal/Modal';
import { CoursesFilter, CourseTypesEnum } from '../../../../graphql/types';
import { Courses } from '../../../../store/courses/Courses';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { useCoursesPage } from '../../../../graphql/hooks/courses/useCoursesPage';
import { queryLoader } from '../../../../common/components/utils/queryLoader';
import s from './CoursesList.module.scss';

export interface CoursesListProps {
  filter?: CoursesFilter;
  page?: number;
  showArchived?: boolean;
  isEditable?: boolean;
}

export const CoursesList: React.FC<CoursesListProps> = ({
  filter,
  page,
  showArchived,
  isEditable
}) => {
  const { isMobile } = useWindowSize();
  const [query, { changeQuery }] = useLocationQuery([
    URL_KEY_PAGE,
    URL_KEY_ID
  ] as const);
  const activeId = query[URL_KEY_ID];

  const coursesQuery = useCoursesPage(
    {
      filter,
      pagination: { page }
    },
    showArchived
  );
  const courses = coursesQuery.state.data?.list;
  const header = coursesQuery.state.data?.header;
  const hasCourses = courses && courses.length > 0;

  const deleteCourse = useGraphqlMutation(Courses.deleteCourse);
  const deleteCourseRun = deleteCourse.run;

  const handleDeleteClick = useCallback(
    (id) => {
      if (window.confirm('Вы действительно хотите удалить курс?')) {
        deleteCourseRun({ variables: { id } })
          .then((result) => {
            if (!result.data?.result) {
              throw new Error('UnexpectedResult');
            }
            toast.success('Курс удален');
          })
          .catch((err) => {
            if (err instanceof AsyncSingletonError) return;
            handleDefaultError(err, 'Произошла ошибка при удалении курса');
          });
      }
    },
    [deleteCourseRun]
  );

  const handleLinkClick = useCallback(
    (e, course) => {
      if (course.type === CourseTypesEnum.Announce && !isMobile) {
        e.preventDefault();
        changeQuery({ [URL_KEY_ID]: course.id });
      }
    },
    [isMobile, changeQuery]
  );

  const handleTagSelect = useCallback(
    (id) => {
      changeQuery({
        [URL_KEY_TAG_IDS]: [id],
        [URL_KEY_PAGE]: 1
      });
    },
    [changeQuery]
  );

  const handleSubjectSelect = useCallback(
    (id) => {
      changeQuery({
        [URL_KEY_SUBJECT_IDS]: [id],
        [URL_KEY_PAGE]: 1
      });
    },
    [changeQuery]
  );

  const coursesItems = useMemo(() => {
    return courses?.map((item) => (
      <div key={item.id} className={s.CoursesList__item}>
        <CardCourse
          {...item}
          to={`${routeLinks.courses.to}/${item.id}`}
          isEditable={isEditable}
          onTagClick={handleTagSelect}
          onSubjectClick={handleSubjectSelect}
          onDeleteClick={handleDeleteClick}
          onLinkClick={(e) => handleLinkClick(e, item)}
        />
      </div>
    ));
  }, [
    courses,
    isEditable,
    handleTagSelect,
    handleSubjectSelect,
    handleDeleteClick,
    handleLinkClick
  ]);

  return (
    queryLoader(coursesQuery) ||
    (!hasCourses && <NoData />) || (
      <>
        {coursesItems}

        {header && (
          <QueryPagination header={header} pathname={routeLinks.courses.to} />
        )}

        {!isMobile && (
          <Modal
            className={s.CoursesList__modal}
            title={'Анонс курса'}
            isOpen={!!activeId}
            onClose={() => changeQuery({ id: undefined })}
          >
            <CourseAnnounce id={activeId} showActions={isEditable} />
          </Modal>
        )}
      </>
    )
  );
};
