import React from 'react';
import clsx from 'clsx';
import { Button, ButtonClasses } from '../../Button';
import s from './Tab.module.scss';

export interface TabClasses extends ButtonClasses {
  enabled?: string;
}

interface TabProps {
  title: string;
  id: string;
  onClick: (id: string) => void;
  isEnabled: boolean;
  classes?: TabClasses;
}

export const Tab: React.FC<TabProps> = ({
  title,
  id,
  onClick,
  isEnabled,
  classes
}) => {
  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      classes={{
        ...classes,
        root: clsx(
          s.Tab,
          {
            [clsx(s.Tab__enables, classes?.enabled)]: isEnabled
          },
          classes?.root
        )
      }}
    >
      {title}
    </Button>
  );
};
