import gql from 'graphql-tag';
import { FRAGMENT_PROJECT_FULL } from '../fragments/projects';

export const MUTATION_CREATE_PROJECT = gql`
  mutation mutationCreateProject($input: ProjectCreateInput!) {
    result: createProject(input: $input) {
      ...ProjectFull
    }
  }

  ${FRAGMENT_PROJECT_FULL}
`;

export const MUTATION_UPDATE_PROJECT = gql`
  mutation mutationUpdateProject($input: ProjectUpdateInput!) {
    result: updateProject(input: $input) {
      ...ProjectFull
    }
  }

  ${FRAGMENT_PROJECT_FULL}
`;

export const MUTATION_DELETE_PROJECT = gql`
  mutation mutationDeleteProject($id: ID!) {
    result: deleteProject(project_id: $id)
  }
`;

export const MUTATION_QUIT_PROJECT = gql`
  mutation mutationQuitProject($id: ID!) {
    result: quitProject(project_id: $id)
  }
`;

export const MUTATION_SEND_PROJECT_APPLICATION = gql`
  mutation sendProjectApplication($id: ID!) {
    result: sendProjectApplication(project_id: $id)
  }
`;
