import { MutationOptions } from 'apollo-client';
import { useAsyncOperation } from '@proscom/prostore-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { stringifyUrl } from 'query-string';
import { MUTATION_CREATE_OTT_FOR_FEEDBACKS_EXPORT_DOWNLOAD } from '../../../graphql/mutations/oneTimeTokens';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { fileExportUrl } from '../../../config';
import { tryDownloadFileFromResponse } from '../../../utils/files';

export type ExportFeedbacksFilterType = {
  start_from: string;
  end_at: string;
};

const exportOtt: MutationOptions<{ result: string }> = {
  mutation: MUTATION_CREATE_OTT_FOR_FEEDBACKS_EXPORT_DOWNLOAD
};
const EXPORT_ERROR_TEXT = 'Произошла ошибка при экспорте';

export function useExportFeedbacks() {
  const getOttTokenMutation = useGraphqlMutation(exportOtt);
  return useAsyncOperation(
    async ({ start_from, end_at }: ExportFeedbacksFilterType) => {
      try {
        const ottTokenMutationResult = await getOttTokenMutation.run({});
        const token = ottTokenMutationResult.data?.result;
        if (!token) {
          console.error(ottTokenMutationResult);
          throw new Error(EXPORT_ERROR_TEXT);
        }
        try {
          const url = stringifyUrl({
            url: `${fileExportUrl}/feedbacks`,
            query: {
              start_from,
              end_at,
              token
            }
          });
          const response = await axios(url, {
            method: 'GET',
            responseType: 'arraybuffer',
            headers: {
              'content-type': 'application/json'
            }
          });
          tryDownloadFileFromResponse(response);
        } catch (e) {
          console.error(e);
          throw new Error(EXPORT_ERROR_TEXT);
        }
      } catch (e) {
        toast.error(e.message);
      }
    },
    {
      singleton: true,
      finishedTimeout: 10 * 1000
    }
  );
}
