import React from 'react';
import { ARROW_LEFT, ARROW_RIGHT, MAC_OPTION } from '../../../../constants';
import s from './Pagination.module.scss';

export interface PaginationInfoProps {
  isMac: boolean;
}

export const PaginationInfo: React.FC<PaginationInfoProps> = ({ isMac }) => {
  return (
    <div className={s.PaginationInfo}>
      Используйте{' '}
      {isMac ? (
        <>
          <kbd>
            {MAC_OPTION}
            {ARROW_LEFT}
          </kbd>{' '}
          и{' '}
          <kbd>
            {MAC_OPTION}
            {ARROW_RIGHT}
          </kbd>
        </>
      ) : (
        <>
          <kbd>Ctrl+{ARROW_LEFT}</kbd> и <kbd>Ctrl+{ARROW_RIGHT}</kbd>
        </>
      )}{' '}
      для навигации по страницам
    </div>
  );
};
