import gql from 'graphql-tag';
import { FRAGMENT_MANUAL } from '../fragments/manuals';

export const MUTATION_CREATE_MANUAL = gql`
  mutation mutationCreateManual($input: ManualCreateInput!) {
    result: createManual(input: $input) {
      ...ManualProps
    }
  }

  ${FRAGMENT_MANUAL}
`;

export const MUTATION_UPDATE_MANUAL = gql`
  mutation mutationUpdateManual($input: ManualUpdateInput!) {
    result: updateManual(input: $input) {
      ...ManualProps
    }
  }

  ${FRAGMENT_MANUAL}
`;

export const MUTATION_DELETE_MANUAL = gql`
  mutation mutationDeleteManual($id: ID!) {
    result: deleteManual(manual_id: $id)
  }
`;
