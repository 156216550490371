import React from 'react';
import { LayoutContent } from '../../Layout/Layout';
import { PageTitle } from '../PageTitle/PageTitle';
import { ButtonLink } from '../Button';
import s from './ErrorPageContent.module.scss';

export interface ErrorPageContentProps {
  title: string;
  description?: React.ReactNode;
  actions?: React.ReactNode;
}

export function ErrorPageContent({
  title,
  description,
  actions
}: ErrorPageContentProps) {
  return (
    <LayoutContent>
      <div className={s.ErrorPageContent}>
        <PageTitle title={title} showHeading isCentered />

        {description && (
          <div className={s.ErrorPageContent__description}>{description}</div>
        )}

        {actions || (
          <ButtonLink to={'/'} className={s.ErrorPageContent__button}>
            Вернуться на главную
          </ButtonLink>
        )}
      </div>
    </LayoutContent>
  );
}
