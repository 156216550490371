import gql from 'graphql-tag';

export const FRAGMENT_MANUAL = gql`
  fragment ManualProps on ManualType {
    id
    type
    file_id
    name
    file {
      id
      name
    }
    external_link
  }
`;
