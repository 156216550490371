import React, { useCallback } from 'react';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { STORE_LOCATION } from '../../../../store/storeKeys';
import {
  TransformedQueryParams,
  URL_KEY_PAGE,
  URL_KEY_SEARCH,
  URL_KEY_TENSES
} from '../../../../store/urlKeys';
import { useHasPermission } from '../../../../common/hooks/useHasPermission';
import { ExternalPollsFilter, TensesEnum } from '../../../../graphql/types';
import { FilterSearch } from '../../../../common/components/ui/Filter/FilterSearch';
import { FilterTense } from '../../../../common/components/utils/filters/FilterTense';
import { useCurrentUser } from '../../../../common/hooks/useCurrentUser';
import { UserPermission } from '../../../../utils/permissions';
import s from './useExternalPollsFilter.module.scss';

export const useExternalPollsFilter = () => {
  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.ExternalPollCrud);
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const query = (locationState.query || {}) as TransformedQueryParams;
  const changeQuery = locationStore.changeQuery;

  const filter: ExternalPollsFilter = {
    search: query[URL_KEY_SEARCH],
    tense: canEdit ? (query[URL_KEY_TENSES] as TensesEnum) : TensesEnum.Present
  };

  const handleFilterChange = useCallback(
    (key: string, valueConverter?: (value) => any) => {
      return (value) => {
        const val = valueConverter ? valueConverter(value) : value;

        changeQuery({
          [key]: val,
          [URL_KEY_PAGE]: 1
        });
      };
    },
    [changeQuery]
  );

  const filterComponent = (
    <div className={s.PollsFilters}>
      <FilterSearch
        value={filter.search ?? undefined}
        onChange={handleFilterChange(URL_KEY_SEARCH)}
        placeholder={'Поиск по опросам'}
        className={s.PollsFilters__searchFilter}
        classes={{
          filter: { content: s.PollsFilters__filterContent },
          searchField: { root: s.PollsFilters__search }
        }}
      />
      {canEdit && (
        <FilterTense
          value={filter.tense}
          onChange={handleFilterChange(URL_KEY_TENSES)}
          classes={{
            select: {
              root: s.PollsFilters__select
            }
          }}
        />
      )}
    </div>
  );
  return [filterComponent, filter] as const;
};
