import { IQueryTransformer } from '@proscom/prostore-react-router';

export const stringArrayTransformer: IQueryTransformer<string[] | undefined> = {
  parse(value) {
    return value && typeof value === 'string' ? value.split(',') : undefined;
  },
  stringify(value?: string[]) {
    return value && value.length ? value.join(',') : undefined;
  }
};
