import React, { useRef } from 'react';
import clsx from 'clsx';
import { Parser } from 'htmlparser2';
import s from './HTMLTextContainer.module.scss';

const lineBreakTags = ['p', 'li'];
const dashTags = ['li'];

export interface HTMLTextContainerProps {
  className?: string;
  htmlString?: string;
}

export const HTMLTextContainer = React.memo(function HTMLTextContainer({
  className,
  htmlString
}: HTMLTextContainerProps) {
  let outText = '';

  const parserRef = useRef(
    new Parser({
      ontext(text) {
        outText += text;
      },
      onopentagname(tagname) {
        if (dashTags.includes(tagname)) {
          outText += '- ';
        }
      },
      onclosetag(tagname) {
        if (lineBreakTags.includes(tagname)) {
          outText += '\n';
        }
      }
    })
  );

  parserRef.current.write(htmlString || '');
  parserRef.current.end();

  return <div className={clsx(s.HTMLTextContainer, className)}>{outText}</div>;
});
