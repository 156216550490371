import React, { useCallback } from 'react';
import { useControlledState } from '@proscom/ui-react';
import clsx from 'clsx';
import { ReactComponent as CheckboxIcon } from '../../../../assets/img/icons/checkbox.svg';
import {
  DivPropsWithoutRef,
  InputPropsWithoutRef
} from '../../../../utils/reactTypes';
import s from './Checkbox.module.scss';

export interface CheckboxProps extends Omit<BaseCheckboxProps, 'onChange'> {
  onChange: (value: boolean) => void;
  checked?: boolean;
  defaultChecked?: boolean;
}

export interface BaseCheckboxProps extends InputPropsWithoutRef {
  label?: string;
  rootProps?: DivPropsWithoutRef;
}

export const BaseCheckbox = React.memo(function BaseCheckbox({
  label,
  className,
  rootProps = {},
  ...props
}: BaseCheckboxProps) {
  return (
    <div {...rootProps}>
      <label className={clsx(className, s.Checkbox)}>
        <div
          className={clsx(s.Checkbox__box, {
            [s.Checkbox__box_withLabel]: !!label
          })}
        >
          <CheckboxIcon
            className={clsx(s.Checkbox__icon, {
              [s.Checkbox_checked]: props.checked
            })}
          />
        </div>
        {label && <span>{label}</span>}
        <input type="checkbox" className={s.Checkbox__input} {...props} />
      </label>
    </div>
  );
});

export const Checkbox = React.memo(function Checkbox({
  defaultChecked,
  checked: checkedProp,
  onChange: onChangeProp,
  ...props
}: CheckboxProps) {
  const [checked, handleChange] = useControlledState(
    checkedProp,
    defaultChecked,
    false,
    onChangeProp
  );
  const onChange = useCallback((event) => handleChange(event.target.checked), [
    handleChange
  ]);
  return <BaseCheckbox checked={checked} onChange={onChange} {...props} />;
});
