import { IQueryTransformer } from '@proscom/prostore-react-router';

export const pageTransformer: IQueryTransformer<number> = {
  parse(value) {
    return (typeof value === 'string' && +value) || 1;
  },
  stringify(value: number) {
    return value ? String(value) : undefined;
  }
};
