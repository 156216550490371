import { SelectOptionProps } from '../../common/components/ui/Select/Select';
import { ProjectStatusEnum } from '../../graphql/types';

export const statusSelectOptions: SelectOptionProps[] = [
  {
    label: 'Проект активен',
    value: ProjectStatusEnum.Active
  },
  {
    label: 'Проект завершен',
    value: ProjectStatusEnum.Completed
  }
];

export const noHeadUserMsg =
  'К сожалению, вступление в проект невозможно, так как отсутствует руководитель проекта';

export const quitProjectWarning = 'Вы действительно хотите выйти из проекта?';
