import gql from 'graphql-tag';

export const MUTATION_LEAVE_VACATION_APPLICATION = gql`
  mutation mutationLeaveVacationApplication($input: VacationApplicationInput!) {
    result: leaveVacationApplication(input: $input) {
      status
    }
  }
`;

export const MUTATION_UPDATE_VACATION_SCHEDULE = gql`
  mutation mutationUpdateVacationSchedule($input: VacationScheduleInput!) {
    result: submitVacationSchedule(input: $input) {
      position_id
    }
  }
`;
