import React from 'react';
import { queryLoader } from '../../../../common/components/utils/queryLoader';
import { NoData } from '../../../../common/components/ui/NoData/NoData';
import {
  GridItem,
  LayoutGrid
} from '../../../../common/components/Layout/Layout';
import { QueryPagination } from '../../../../common/components/ui/QueryPagination/QueryPagination';
import { useExternalPollsPage } from '../../../../graphql/hooks/polls/useExternalPollsPage';
import { CardPoll } from '../../../../common/components/ui/Cards/CardPoll/CardPoll';
import { useLocationQuery } from '../../../../common/hooks/utils/useLocationQuery';
import { URL_KEY_PAGE } from '../../../../store/urlKeys';
import {
  ExternalPollsFilter,
  ExternalPollSortingFieldsEnum,
  SortingDirectionEnum,
  SortingNullsEnum
} from '../../../../graphql/types';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { ExternalPollActions } from './ExternalPollActions/ExternalPollActions';

const fieldsBaseSettings = {
  direction: SortingDirectionEnum.Desc,
  nulls: SortingNullsEnum.Last
};

const sorting = [
  {
    field: ExternalPollSortingFieldsEnum.EndsAt,
    ...fieldsBaseSettings
  },
  {
    field: ExternalPollSortingFieldsEnum.StartsAt,
    ...fieldsBaseSettings
  },

  {
    field: ExternalPollSortingFieldsEnum.CreatedAt,
    ...fieldsBaseSettings
  }
];

export interface ExternalPollsProps {
  editable?: boolean;
  filter?: ExternalPollsFilter;
}

export const ExternalPolls: React.FC<ExternalPollsProps> = ({
  filter,
  editable = false
}) => {
  const [query] = useLocationQuery([URL_KEY_PAGE] as const);
  const externalPollsQuery = useExternalPollsPage({
    filter,
    pagination: { page: query[URL_KEY_PAGE] },
    sorting
  });
  const externalPolls = externalPollsQuery.state.data?.list;
  const header = externalPollsQuery.state.data?.header;

  const { isTablet } = useWindowSize();

  return (
    queryLoader(externalPollsQuery) ||
    (!externalPolls?.length ? (
      <NoData />
    ) : (
      <>
        <LayoutGrid>
          {externalPolls.map((externalPoll) => (
            <GridItem key={externalPoll.id} cols={isTablet ? 5 : 6}>
              <CardPoll
                data={{
                  name: externalPoll.name,
                  cover_file_id: externalPoll.cover_file_id ?? undefined,
                  passcode: externalPoll.passcode ?? undefined,
                  startsAt: externalPoll.starts_at,
                  endsAt: externalPoll.ends_at
                }}
                link={externalPoll.link}
                actions={
                  editable && <ExternalPollActions id={externalPoll.id} />
                }
                isExternal
              />
            </GridItem>
          ))}
        </LayoutGrid>
        {header && <QueryPagination header={header} pathname={'/polls'} />}
      </>
    ))
  );
};
