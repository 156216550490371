import React from 'react';
import clsx from 'clsx';
import { ReactComponent as PersonDefaultIcon } from '../../../../assets/img/icons/person.svg';
import { ImagePreview } from '../ImagePreview/ImagePreview';
import s from './Avatar.module.scss';

export enum AvatarSize {
  extraSmall = 'extraSmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge'
}

export interface AvatarProps {
  className?: string;
  src?: string | null;
  size?: AvatarSize;
}

export const Avatar: React.FC<AvatarProps> = ({
  className,
  src,
  size = AvatarSize.medium
}) => {
  return (
    <ImagePreview
      className={clsx(s.Avatar, s[`Avatar_${size}`], className)}
      classes={{
        image: s.Avatar__image
      }}
      placeholderProps={{
        icon: PersonDefaultIcon,
        iconProps: {
          boxSize: 'auto',
          className: s.Avatar__icon,
          iconClassName: s[`Avatar__iconIcon_${size}`]
        }
      }}
      loaderProps={{
        size: 14,
        borderSize: 2
      }}
      imageSrc={src}
    />
  );
};
