import { isNil } from 'lodash-es';
import { format, isSameDay, isValid } from 'date-fns';
import { Maybe } from '../../graphql/types';

export const DATE_FORMAT_FULL = 'dd MMMM yyyy';
export const DATE_FORMAT_DAY_MONTH = 'dd MMMM';
export const DATETIME_FORMAT_FULL = 'dd MMMM yyyy, HH:mm';
export const DATETIME_FORMAT_SHORT = 'dd.MM.yyyy';
export const MONTHS = [
  {
    name: 'Январь',
    shortName: 'Янв',
    unitName: 'Января'
  },
  {
    name: 'Февраль',
    shortName: 'Фев',
    unitName: 'Февраля'
  },
  {
    name: 'Март',
    shortName: 'Мар',
    unitName: 'Марта'
  },
  {
    name: 'Апрель',
    shortName: 'Апр',
    unitName: 'Апреля'
  },
  {
    name: 'Май',
    shortName: 'Май',
    unitName: 'Мая'
  },
  {
    name: 'Июнь',
    shortName: 'Июн',
    unitName: 'Июня'
  },
  {
    name: 'Июль',
    shortName: 'Июл',
    unitName: 'Июля'
  },
  {
    name: 'Август',
    shortName: 'Авг',
    unitName: 'Августа'
  },
  {
    name: 'Сентябрь',
    shortName: 'Сен',
    unitName: 'Сентября'
  },
  {
    name: 'Октябрь',
    shortName: 'Окт',
    unitName: 'Октября'
  },
  {
    name: 'Ноябрь',
    shortName: 'Ноя',
    unitName: 'Ноября'
  },
  {
    name: 'Декабрь',
    shortName: 'Дек',
    unitName: 'Декабря'
  }
];

export function addZero(value: number): number | string {
  return !isNaN(value) && !isNil(value)
    ? (value < 10 ? '0' : '') + value
    : value;
}

/**
 * Достаёт дату из ISO-строки и возвращает объект Date который в локальном
 * часовом поясе указывает на начало дня этой даты
 *
 * @param dateString - строка в формате ISO
 */
export function getDateUtc<T extends string | undefined | null>(
  dateString: T
): T | string {
  return dateString && dateString.slice(0, 4 + 2 + 2 + 2) + 'T00:00:00';
}

export function convertToUtcStr(date?: Date): string;
export function convertToUtcStr(
  date?: Date | number | string | null
): string | undefined;
export function convertToUtcStr(date?: Date | number | string | null) {
  const d = date && new Date(date);
  const dateString = d ? format(d, 'yyyy-MM-dd') + 'T00:00:00Z' : undefined;

  return dateString && isValid(new Date(dateString)) ? dateString : undefined;
}

/**
 * Смещает ISO-строку из UTC в локальный часовой пояс с сохранением
 * времени
 *
 * @param date - строка в формате ISO
 */
export function parseIsoToLocal(date?: string): string {
  if (!date) return '';
  if (date[date.length - 1] !== 'Z') return date;
  return date.slice(0, date.length - 1);
}

export function isoStringWithoutTime(isoString?: Maybe<string>) {
  if (!isoString) return undefined;
  const tIndex = isoString.indexOf('T');
  return tIndex > -1 ? isoString.slice(0, tIndex) : isoString;
}

export function setIsoStartOfDay(isoString?: Maybe<string>) {
  if (!isoString) return undefined;
  return isoStringWithoutTime(isoString) + 'T00:00:00Z';
}

export function setIsoEndOfDay(isoString?: Maybe<string>) {
  if (!isoString) return undefined;
  return isoStringWithoutTime(isoString) + 'T23:59:59Z';
}

export function checkDatesIncludeDate(dates: Date[], date: Date) {
  return !!dates.find((d) => isSameDay(d, date));
}
