import React from 'react';
import clsx from 'clsx';
import { UserType } from '../../../graphql/types';
import { useFileSrc } from '../../../common/hooks/useFileSrc';
import { UserUtils } from '../../../store/users/UserUtils';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import { Person } from '../../../common/components/ui/Person/Person';
import { BaseLink } from '../../../common/components/ui/BaseLink/BaseLink';
import { routeLinks } from '../../routeLinks';
import s from './ProjectDirector.module.scss';

export interface ProjectDirectorProps {
  className?: string;
  user: UserType;
}

export const ProjectDirector: React.FC<ProjectDirectorProps> = ({
  className,
  user
}) => {
  const avatarSrc = useFileSrc(UserUtils.getAvatar(user), FileTypeEnum.avatar);

  return (
    <BaseLink
      className={clsx(s.ProjectDirector, className)}
      to={`${routeLinks.users.to}/${user.id}`}
    >
      <Person
        classes={{
          avatar: s.ProjectDirector__avatar
        }}
        name={UserUtils.getName(user)}
        description={UserUtils.getPositionsList(user)}
        avatarSrc={avatarSrc ?? undefined}
      />
    </BaseLink>
  );
};
