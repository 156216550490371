import React, { useCallback, useState } from 'react';
import { TextField } from '../../TextField/TextField';
import { ActionButton, ActionType } from '../../ActionButton/ActionButton';
import { DocumentType } from '../../../../../graphql/types';
import { PriorityField } from '../../PriorityField/PriorityField';
import s from './DocumentEdit.module.scss';

export type DocumentEditSubmitValuesType = {
  document: DocumentType;
  name: string;
  priority: number | null;
};

export interface DocumentEditProps {
  document: DocumentType;
  onSubmit?: (values: DocumentEditSubmitValuesType) => void;
  onCancel?: () => void;
}

export const DocumentEdit: React.FC<DocumentEditProps> = ({
  document,
  onSubmit,
  onCancel
}) => {
  const [name, setName] = useState(document.name || '');
  const [priority, setPriority] = useState(document.priority || null);

  const handleSubmit = useCallback(() => {
    onSubmit?.({
      document,
      name,
      priority
    });
    onCancel?.();
  }, [onSubmit, onCancel, document, name, priority]);

  return (
    <div className={s.DocumentEdit}>
      <TextField
        value={name}
        onChange={(val) => {
          setName(val);
        }}
        className={s.DocumentEdit__textField}
      />
      <div className={s.DocumentEdit__actions}>
        <ActionButton actionType={ActionType.approve} onClick={handleSubmit} />
        <ActionButton actionType={ActionType.cancel} onClick={onCancel} />
      </div>
      <div className={s.DocumentEdit__ext}>
        <PriorityField
          classes={{
            root: s.DocumentEdit__priority,
            label: s.DocumentEdit__priorityLabel
          }}
          priority={document.priority}
          onOrderChange={setPriority}
          showActions={false}
        />
      </div>
    </div>
  );
};
