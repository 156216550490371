import * as yup from 'yup';
import { IContentBlock } from '../../common/components/ui/Form/FormContentEditor/Content';
import {
  ArticleCreateInput,
  ArticleDocumentInput,
  ArticleType,
  ArticleUpdateInput
} from '../../graphql/types';
import { parseBlocksWithDefaultValue, stringifyBlocks } from '../contentUtils';

export interface ArticleProps {
  id?: string;
  postedAt?: string;
  priority?: number;
  coverImageId?: string;
  title?: string;
  snippet?: string;
  content?: IContentBlock[];
  documents?: ArticleDocumentInput[];
  document_files?: ArticleType['document_files'];
}

export class ArticleUtils {
  static toForm(data?: ArticleType): ArticleProps {
    return {
      id: data?.id,
      postedAt: data?.posted_at || undefined,
      priority: data?.priority ?? undefined,
      coverImageId: data?.cover_file_id ?? undefined,
      content: parseBlocksWithDefaultValue(data?.content),
      title: data?.title || '',
      snippet: data?.snippet || '',
      // documents: data?.document_files?.map((f) => f.id),
      document_files: data?.document_files
    };
  }

  static fromCreateForm(formData: ArticleProps): ArticleCreateInput {
    return {
      posted_at: formData.postedAt || new Date().toISOString(),
      priority: formData.priority || null,
      cover_file_id: formData.coverImageId || null,
      content: stringifyBlocks(formData.content || []),
      title: formData.title || '',
      snippet: formData.snippet || '',
      documents: formData.documents || null
    };
  }

  static fromUpdateForm(formData: ArticleProps): ArticleUpdateInput {
    return {
      id: formData.id!,
      ...ArticleUtils.fromCreateForm(formData)
    };
  }

  static get validationSchema() {
    return yup.object().shape({
      coverImageId: yup
        .string()
        .typeError('Пожалуйста, выберите обложку')
        .required('Пожалуйста, выберите обложку'),
      priority: yup
        .number()
        .optional()
        .typeError('Приоритет должен быть числом'),
      title: yup.string().required('Пожалуйста, введите заголовок'),
      snippet: yup.string(),
      content: yup.array()
    });
  }
}
