import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { EMDASH } from '@proscom/ui-utils';
import { Card, CardProps, CardStyle } from '../../Card/Card';
import { TextLink } from '../../TextLink/TextLink';
import { Person } from '../../Person/Person';
import { Tag } from '../../Tag/Tag';
import { UserType } from '../../../../../graphql/types';
import { ContactsCardSection } from '../../../../../routes/welcome/Contacts/ContactsCard';
import { useFileSrc } from '../../../../hooks/useFileSrc';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { UserUtils } from '../../../../../store/users/UserUtils';
import s from './CardUserPreview.module.scss';

export interface CardUserPreviewProps extends Pick<CardProps, 'to'> {
  className?: string;
  projectsAmount?: number;
  staffAmount?: number;
  showContacts?: boolean;
  showLink?: boolean;
  user?: UserType;
  onSkillTagClick?: (skillId?: string) => void;
}

export const CardUserPreview: React.FC<CardUserPreviewProps> = ({
  className,
  to,
  projectsAmount,
  staffAmount,
  showContacts,
  showLink = true,
  user,
  onSkillTagClick
}) => {
  const { skills, email, phone, campus } = user || ({} as UserType);
  const avatarSrc = useFileSrc(UserUtils.getAvatar(user), FileTypeEnum.avatar);
  const contacts = useMemo(() => {
    return [
      {
        title: 'Email',
        content: email ? <TextLink noUnderline>{email}</TextLink> : EMDASH
      },
      {
        title: 'Телефон',
        content: phone || EMDASH
      },
      {
        title: 'Кампус',
        content: campus || EMDASH
      }
    ];
  }, [email, phone, campus]);

  return (
    <Card
      className={clsx(s.CardUserPreview, className)}
      classes={{
        inner: s.CardUserPreview__inner
      }}
      cardStyle={CardStyle.bordered}
    >
      <div className={s.CardUserPreview__head}>
        <Link className={s.CardUserPreview__person} to={to || ''} tabIndex={-1}>
          <Person
            name={UserUtils.getName(user)}
            description={UserUtils.getPositionsList(user)}
            avatarSrc={avatarSrc ?? undefined}
            classes={{
              avatar: s.CardUserPreview__avatar
            }}
          />
        </Link>

        <div className={s.CardUserPreview__extInfo}>
          {projectsAmount && (
            <div className={s.CardUserPreview__infoItem}>
              Проекты: {projectsAmount}
            </div>
          )}
          {staffAmount && (
            <div className={s.CardUserPreview__infoItem}>
              Подчиненные: {staffAmount}
            </div>
          )}
        </div>
      </div>

      {showContacts && (
        <ContactsCardSection
          className={s.CardUserPreview__contacts}
          classes={{
            content: s.CardUserPreview__contactsContent
          }}
          contacts={contacts}
        />
      )}

      {skills && skills.length > 0 && (
        <div className={s.CardUserPreview__tags}>
          {skills.map((skill) => (
            <Tag
              key={skill.id}
              className={s.CardUserPreview__tag}
              isClickable={true}
              onClick={() => onSkillTagClick?.(skill.id)}
            >
              {skill.name}
            </Tag>
          ))}
        </div>
      )}

      {showLink && (
        <div className={s.CardUserPreview__actions}>
          <TextLink
            className={s.CardUserPreview__link}
            to={to || ''}
            noUnderline
            showArrow
          >
            Перейти
          </TextLink>
        </div>
      )}
    </Card>
  );
};
