import React from 'react';
import clsx from 'clsx';
import { FigureRectangle } from '../../../Figures/FigureRectangle';
import { FigureCircle } from '../../../Figures/FigureCircle';
import { FigureTriangle } from '../../../Figures/FigureTriangle';
import { FigureProps, FigureType } from '../../../Figures/types';
import s from './CardPattern.module.scss';

const figuresObject = {
  [FigureType.rectangle]: FigureRectangle,
  [FigureType.circle]: FigureCircle,
  [FigureType.triangle]: FigureTriangle
} as Record<FigureType, React.ComponentType<FigureProps>>;

export interface CardPatternFiguresProps extends FigureProps {
  type: FigureType;
}

export interface CardPatternProps {
  className?: string;
  classes?: {
    root?: string;
    item?: string;
    figure?: string;
  };
  figures: CardPatternFiguresProps[];
}

export const CardPattern = React.memo(function CardPattern({
  className,
  classes,
  figures
}: CardPatternProps) {
  const figureArray = figures.slice().reverse();

  return (
    <div className={clsx(s.CardPattern, className, classes?.root)}>
      {figureArray.map(({ type, ...props }, iItem) => {
        const Figure = figuresObject[type];

        return (
          <div className={clsx(s.CardPattern__item, classes?.item)} key={iItem}>
            <Figure
              {...props}
              className={clsx(
                s.CardPattern__figure,
                props.className,
                classes?.figure
              )}
            />
          </div>
        );
      })}
    </div>
  );
});
