import React, { ReactNode } from 'react';
import { DATE_FORMAT_RUSSIAN } from '@proscom/ui-utils-date';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { useFileSrc } from '../../../../hooks/useFileSrc';
import { Title, TitleVariant } from '../../Title/Title';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { Card, CardProps, CardStyle } from '../../Card/Card';
import { BaseLink } from '../../BaseLink/BaseLink';
import { ImagePreview } from '../../ImagePreview/ImagePreview';
import { CardPollFooter } from './CardPollFooter';
import s from './CardPoll.module.scss';

export interface CardPollData {
  name: string;
  cover_file_id?: string;
  passcode?: string;
  endsAt?: string | null;
  startsAt?: string | null;
}

export interface CardPollProps extends CardProps {
  data: CardPollData;
  link?: string;
  isExternal?: boolean;
  actions?: ReactNode;
  imageType?: FileTypeEnum;
}

export const CardPoll: React.FC<CardPollProps> = ({
  data,
  link,
  isExternal,
  actions,
  imageType = FileTypeEnum.external_poll_cover
}) => {
  const linkProp = isExternal ? { href: link } : { to: link };

  const startsAt = useFormatDate(data.startsAt, DATE_FORMAT_RUSSIAN);
  const endsAt = useFormatDate(data.endsAt, DATE_FORMAT_RUSSIAN);

  const imageSrc = useFileSrc(data.cover_file_id, imageType);

  const LinkWrapper = link ? BaseLink : 'div';

  return (
    <Card
      className={s.CardPoll}
      cardStyle={CardStyle.bordered}
      classes={{
        inner: s.CardPoll__inner
      }}
    >
      <LinkWrapper {...linkProp} tabIndex={-1}>
        <ImagePreview
          className={s.CardPoll__cover}
          classes={{
            image: s.CardPoll__coverImage
          }}
          imageSrc={imageSrc}
          showBackfaceImage
        />
      </LinkWrapper>

      <div className={s.CardPoll__body}>
        <div className={s.CardPoll__content}>
          <div>
            <LinkWrapper
              className={s.CardPoll__title}
              {...linkProp}
              tabIndex={-1}
            >
              <Title title={data.name} variant={TitleVariant.h4} />
            </LinkWrapper>

            <div className={s.CardPoll__date}>
              {startsAt && 'с ' + startsAt} {endsAt && 'по ' + endsAt}
            </div>
          </div>

          {actions && (
            <div className={s.CardPoll__contextActions}>{actions}</div>
          )}
        </div>

        <CardPollFooter
          passcode={isExternal && data.passcode ? data.passcode : undefined}
          {...linkProp}
        />
      </div>
    </Card>
  );
};
