import React from 'react';
import clsx from 'clsx';
import {
  Title,
  TitleVariant
} from '../../../../common/components/ui/Title/Title';
import { Tag } from '../../../../common/components/ui/Tag/Tag';
import s from './ProjectsSection.module.scss';

export interface ProjectsSectionProps {
  className?: string;
  title?: string;
  titleContent?: React.ReactNode;
  projectsAmount?: number;
  children?: React.ReactNode;
}

export const ProjectsSection: React.FC<ProjectsSectionProps> = ({
  className,
  title,
  titleContent,
  projectsAmount,
  children
}) => {
  return (
    <div className={clsx(s.ProjectsSection, className)}>
      {(title || titleContent) && (
        <div className={s.ProjectsSection__head}>
          <div className={s.ProjectsSection__headTitle}>
            {title && <Title variant={TitleVariant.h3} title={title} />}

            {projectsAmount && (
              <Tag className={s.ProjectsSection__projectsAmount}>
                {projectsAmount}
              </Tag>
            )}
          </div>

          {titleContent && (
            <div className={s.ProjectsSection__headContent}>{titleContent}</div>
          )}
        </div>
      )}

      {children}
    </div>
  );
};
