import React from 'react';
import { useField } from 'formik';
import { Entity } from '../../AsyncSelect/entities/types';
import {
  AsyncEntitiesCreatableSelect,
  AsyncEntitiesCreatableSelectProps
} from '../../AsyncSelect/entities/AsyncEntitiesCreatableSelect';

export function FormAsyncEntitiesCreatableSelect<T extends Entity>({
  name,
  entity,
  ...props
}: Omit<AsyncEntitiesCreatableSelectProps<T>, 'onChange' | 'value'> & {
  name: string;
}) {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;
  const { error, touched } = meta;

  return (
    <AsyncEntitiesCreatableSelect
      entity={entity}
      {...field}
      value={value}
      onChange={setValue}
      errorMessage={touched && error ? error : undefined}
      {...props}
    />
  );
}
