import {
  PermissionType,
  PermissionTypeEnum,
  UserType
} from '../../../../../graphql/types';

export type PermissionMap = Record<
  PermissionType['base_permission_code'],
  PermissionType & { object_ids: Set<PermissionType['object_id']> }
>;

export class UserPermissionsUtils {
  /**
   * Преобразовывает формат прав из запроса описания в формат пользовательских прав
   * @param allPermission
   */
  static getAdminPermission(allPermission) {
    const result: Partial<PermissionType>[] = [];
    if (!allPermission) {
      return result;
    }

    for (const perm of allPermission) {
      const permission: Partial<PermissionType> = {
        base_permission_code: perm.code
      };
      if (perm.object_type === PermissionTypeEnum.Object) {
        permission.object_type = 'all';
      }
      result.push(permission);
    }
    return result;
  }

  static getAllPermissionsMap(
    user?: UserType,
    permissions?: Partial<PermissionType>[]
  ) {
    const result: PermissionMap = {};

    const setPermissionToObject = (permission) => {
      const { object_type, object_id } = permission;
      const baseCode = permission.base_permission_code;
      if (result[baseCode]) {
        if (object_type === 'all') {
          result[baseCode].object_type = 'all';
        } else if (object_id) {
          result[baseCode].object_ids.add(object_id);
        }
      } else {
        result[baseCode] = {
          ...permission,
          object_id: undefined,
          object_ids: new Set(object_id ? [object_id] : [])
        };
      }
    };

    if (user?.roles) {
      for (const role of user.roles) {
        if (!role.permissions) {
          continue;
        }
        for (const permission of role.permissions) {
          setPermissionToObject(permission);
        }
      }
    }
    if (user?.permissions) {
      for (const permission of user.permissions) {
        setPermissionToObject(permission);
      }
    }
    if (permissions) {
      for (const perm of permissions) {
        setPermissionToObject(perm);
      }
    }
    return result;
  }

  static getPermissionsFromMap(
    permissionsMap: Record<
      string,
      PermissionType & { object_ids: Set<PermissionType['object_id']> }
    >
  ) {
    const result: Partial<PermissionType>[] = [];
    for (const permission of Object.values(permissionsMap)) {
      const { base_permission_code, object_type, object_ids } = permission;
      if (object_type === 'all') {
        result.push({
          base_permission_code,
          object_type
        });
      } else if (object_ids.size > 0) {
        for (const object_id of object_ids) {
          result.push({
            base_permission_code,
            object_id
          });
        }
      } else {
        result.push({
          base_permission_code
        });
      }
    }
    return result;
  }
}
