import React, { useCallback } from 'react';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import {
  TransformedQueryParams,
  URL_KEY_PAGE,
  URL_KEY_SEARCH
} from '../../../store/urlKeys';
import { STORE_LOCATION } from '../../../store/storeKeys';
import { FilterSearch } from '../../../common/components/ui/Filter/FilterSearch';
import { ArticleFilter } from '../../../graphql/types';
import s from './ArticlesPage.module.scss';

export const useArticlesFilter = () => {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const query = (locationState.query || {}) as TransformedQueryParams;
  const changeQuery = locationStore.changeQuery;

  const filter: ArticleFilter = {
    search: query[URL_KEY_SEARCH]
  };

  const handleFilterChange = useCallback(
    (key: string, valueConverter?: (value) => any) => {
      return (value) => {
        const val = valueConverter ? valueConverter(value) : value;

        changeQuery({
          [key]: val,
          [URL_KEY_PAGE]: 1
        });
      };
    },
    [changeQuery]
  );

  const filterComponent: React.ReactNode = (
    <div className={s.ArticlesFilter}>
      <FilterSearch
        value={filter.search || ''}
        onChange={handleFilterChange(URL_KEY_SEARCH)}
      />
    </div>
  );

  return [filterComponent, filter] as const;
};
