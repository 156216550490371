import React from 'react';
import { History, Location } from 'history';
import { Redirect } from 'react-router';
import { match as Match } from 'react-router-dom';
import { useContextStore, useStore } from '@proscom/prostore-react';
import { STORE_AUTH, STORE_TARGET } from '../store/storeKeys';
import { AuthStore } from '../store/AuthStore';
import { UserType } from '../graphql/types';
import { TargetStore } from '../store/TargetStore';
import NoAccessPage from './noAccess/NoAccessPage';
import { routeLinks } from './routeLinks';

export interface RequireAuthProps {
  location: Location;
  match: Match;
  history: History;
}

export function RequireAuth<Props extends RequireAuthProps>(
  Component: React.ComponentType<Props>,
  checkAccess?: (user: UserType, props: Props) => boolean
) {
  return function RequireAuthComponent(props: Props) {
    const targetStore = useContextStore<TargetStore>(STORE_TARGET);
    const [auth, authStore] = useStore<AuthStore>(STORE_AUTH);
    const user = auth.authData?.user;
    const path = props.location.pathname;
    if (!auth.loaded) return null;

    // Если токен просрочен, редиректим на страницу входа
    if (auth.error && user) {
      authStore.logOut().catch(() => {});
      targetStore.setState(path);
      return <Redirect to={`${routeLinks.login.to}?session-expired`} />;
    }

    // Запоминаем страницу, на которую пользователь заходил до логина и редиректим на страницу входа
    if (!authStore.isLoggedIn()) {
      authStore.resetStore().catch(() => {});
      targetStore.setState(path);
      return <Redirect to={routeLinks.login.to} />;
    }

    // Редиректим на сохраненную страницу, если она есть
    const targetPath = targetStore.state;
    if (targetPath) {
      targetStore.setState(null);
      return <Redirect to={targetPath} />;
    }

    if (checkAccess) {
      if (!user || !checkAccess(user, props)) {
        return <NoAccessPage />;
      }
    }

    return <Component {...props} />;
  };
}
