import immer from 'immer';
import {
  CourseAnnounceCreateInput,
  CourseAnnounceUpdateInput,
  CourseLongreadCreateInput,
  CourseLongreadUpdateInput,
  CoursesFilter,
  CourseSource,
  CourseStatus,
  CourseType,
  CourseTypesEnum
} from '../../graphql/types';
import {
  parseBlocks,
  parseBlocksWithDefaultValue,
  stringifyBlocks
} from '../contentUtils';
import { CoursesTestFormValues } from '../../routes/courses/test/CoursesTestPage';
import { CourseTypeForm } from '../../routes/courses/types';
import { CoursePrice } from './CourseTest';

export class CourseUtils {
  static getCommonFieldsFromCreateForm(data: CourseTypeForm) {
    return {
      name: data.name,
      course_subject_id: data.course_subject_id,
      cover_file_id: data.cover_file_id || null,
      description: data.description,
      tags: data.tags?.map((t) => t.name || t.label),
      source: data.source,
      priority: data.priority || null,
      price: data.price || null,
      discount_price: data.discount_price || null,
      learning_format: data.learning_format,
      completion_proof: data.completion_proof,
      listener_category: data.listener_category,
      status: data.status,
      documents: data.documents || null
    };
  }

  static fromLongreadCreateForm(
    data: CourseTypeForm
  ): CourseLongreadCreateInput {
    return {
      content: stringifyBlocks(data.content),
      ...CourseUtils.getCommonFieldsFromCreateForm(data)
    };
  }

  static fromAnnounceCreateForm(
    data: CourseTypeForm
  ): CourseAnnounceCreateInput {
    return {
      acquired_knowledge: data.acquired_knowledge,
      course_link: data.course_link,
      partner_logo_file_id: data.partner_logo_file_id || null,
      teachers:
        data.teachers?.map((t) => ({
          name: t.name,
          position: t.position,
          speciality: t.speciality || null
        })) || [],
      ...CourseUtils.getCommonFieldsFromCreateForm(data)
    };
  }

  static fromAnnounceUpdateForm(
    data: CourseTypeForm
  ): CourseAnnounceUpdateInput {
    return {
      id: data.id,
      ...CourseUtils.fromAnnounceCreateForm(data)
    };
  }

  static fromLongreadUpdateForm(
    data: CourseTypeForm
  ): CourseLongreadUpdateInput {
    return {
      id: data.id,
      ...CourseUtils.fromLongreadCreateForm(data)
    };
  }

  static toCreateForm(data?: CourseType | null): CourseTypeForm {
    return {
      acquired_knowledge: data?.acquired_knowledge || '',
      content: parseBlocksWithDefaultValue(data?.content),
      course_link: data?.course_link || '',
      course_subject_id: data?.course_subject_id || null,
      cover_file_id: data?.cover_file_id || '',
      description: data?.description || '',
      id: data?.id || '',
      name: data?.name || '',
      partner_logo_file_id: data?.partner_logo_file_id || '',
      tags: data?.tags || [],
      teachers: data?.teachers || [],
      type: data?.type || CourseTypesEnum.Announce,
      source: data?.source || CourseSource.Partner,
      priority: data?.priority ?? undefined,
      price: data?.price || null,
      discount_price: data?.discount_price || null,
      learning_format: data?.learning_format,
      completion_proof: data?.completion_proof,
      listener_category: data?.listener_category,
      status: data?.status || CourseStatus.Published,
      // documents: data?.document_files?.map((f) => f.id),
      document_files: data?.document_files
    };
  }

  static toCourse(data?: CourseType | null) {
    if (!data) return null;
    return {
      title: data.name,
      coverImageId: data.cover_file_id || '',
      contentBlocks: data.content ? parseBlocks(data.content) : [],
      tags: data.tags?.map(({ name }) => name) || [],
      courseSubject: data.course_subject?.name || '',
      type: data.type,
      price: data.discount_price || data.price,
      has_discount: data.has_discount,
      learning_format: data.learning_format,
      completion_proof: data.completion_proof,
      listener_category: data.listener_category,
      source: data.source,
      status: data.status,
      document_files: data.document_files
    };
  }

  static toAnnounce(data?: CourseType | null) {
    if (!data) return null;
    return {
      title: data.name,
      description: data.description || '',
      partnerLogoFileId: data.partner_logo_file_id || '',
      coverFileId: data.cover_file_id,
      acquiredKnowledge: data.acquired_knowledge || '',
      courseLink: data.course_link || '',
      courseSubject: data.course_subject?.name || '',
      tags: data.tags?.map(({ name }) => name) || [],
      teachers:
        data.teachers?.map((teacher) => ({
          name: teacher.name || '',
          position: teacher.position || '',
          specialization: teacher.speciality || '',
          price: data.discount_price || data.price,
          has_discount: data.has_discount,
          learning_format: data.learning_format,
          completion_proof: data.completion_proof,
          listener_category: data.listener_category,
          source: data.source
        })) || [],
      status: data.status,
      document_files: data.document_files
    };
  }

  static toEditForm(data?: CourseType | null): CourseTypeForm {
    return {
      ...CourseUtils.toCreateForm(data),
      tags: data?.tags?.map((t) => ({ label: t.name, value: t.id })) || []
    };
  }

  static fromTestFrom(input: CoursesTestFormValues) {
    return immer(
      input,
      (data: Partial<CoursesFilter> & CoursesTestFormValues) => {
        if (data.price?.includes(CoursePrice.Free)) {
          data.price_min = 0;
        } else if (data.price?.includes(CoursePrice.Paid)) {
          data.price_min = 1;
          if (data.price?.includes(CoursePrice.PaidWithDiscount)) {
            data.has_discount = true;
          }
        }
        delete data.price;
      }
    );
  }
}
