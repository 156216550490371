import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import {
  CardArticlePreview,
  CardArticlePreviewProps
} from '../../../../common/components/ui/Cards/CardArticlePreview/CardArticlePreview';
import { routeLinks } from '../../../routeLinks';
import { useGraphqlMutation } from '../../../../common/hooks/utils/useGraphqlMutation';
import { Articles } from '../../../../store/articles/Articles';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import s from './ArticlesList.module.scss';

export interface ArticlesListProps {
  data?: CardArticlePreviewProps[] | null;
  isEditable?: boolean;
}

export const ArticlesList: React.FC<ArticlesListProps> = ({
  data,
  isEditable
}) => {
  const deleteArticle = useGraphqlMutation(Articles.deleteArticle);
  const deleteArticleRun = deleteArticle.run;

  const handleDeleteClick = useCallback(
    (id) => {
      if (window.confirm('Вы действительно хотите удалить новость?')) {
        deleteArticleRun({ variables: { id } })
          .then((result) => {
            if (result.data?.result) {
              toast.success('Новость удалена');
            }
          })
          .catch((err) => {
            if (err instanceof AsyncSingletonError) return;
            handleDefaultError(err, 'Произошла ошибка при удалении новости');
          });
      }
    },
    [deleteArticleRun]
  );

  return (
    <div className={s.ArticlesList}>
      {data?.map((item, iItem) => (
        <div key={item.id} className={s.ArticlesList__item}>
          <CardArticlePreview
            {...item}
            to={`${routeLinks.articles.to}/${item.id}`}
            isEditable={isEditable}
            onDeleteClick={handleDeleteClick}
          />
        </div>
      ))}
    </div>
  );
};
