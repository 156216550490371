import { IBlockMenuItems } from './BlockAddMenu';
import {
  contentBlockNames,
  ContentBlockVariant,
  ContentHeaderVariant,
  IContentBlock,
  IContentBlockData
} from './Content';

function createItem<V extends ContentBlockVariant>(
  variant: V,
  data: () => IContentBlockData<V>
): () => IContentBlock<V> {
  return () => ({
    variant,
    data: data()
  });
}

export const blockAddMenuItems: IBlockMenuItems = [
  {
    label: 'Заголовок h2',
    blockProps: createItem(ContentBlockVariant.header, () => ({
      headerVariant: ContentHeaderVariant.h2,
      content: ''
    }))
  },
  {
    label: 'Заголовок h3',
    blockProps: createItem(ContentBlockVariant.header, () => ({
      headerVariant: ContentHeaderVariant.h3,
      content: ''
    }))
  },
  {
    label: contentBlockNames[ContentBlockVariant.text],
    blockProps: createItem(ContentBlockVariant.text, () => ({
      content: ''
    }))
  },
  {
    label: contentBlockNames[ContentBlockVariant.blockquote],
    blockProps: createItem(ContentBlockVariant.blockquote, () => ({
      content: ''
    }))
  },
  {
    label: contentBlockNames[ContentBlockVariant.image],
    blockProps: createItem(ContentBlockVariant.image, () => ({
      fileId: undefined
    }))
  },
  {
    label: contentBlockNames[ContentBlockVariant.textImage],
    blockProps: createItem(ContentBlockVariant.textImage, () => ({
      fileId: undefined,
      content: ''
    }))
  },
  {
    label: contentBlockNames[ContentBlockVariant.video],
    blockProps: createItem(ContentBlockVariant.video, () => ({
      url: ''
    }))
  }
];
