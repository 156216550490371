import React from 'react';
import clsx from 'clsx';
import { ReactComponent as IconPlaceholder } from '../../../../assets/img/icons/ImagePlaceholder.svg';
import { Icon, IconProps } from '../Icon/Icon';
import s from './ImagePlaceholder.module.scss';

export interface ImagePlaceholderProps {
  className?: string;
  icon?: React.ComponentType;
  iconProps?: Omit<IconProps, 'icon'>;
}

export const ImagePlaceholder: React.FC<ImagePlaceholderProps> = ({
  className,
  icon,
  iconProps
}) => {
  return (
    <div className={clsx(s.ImagePlaceholder, className)}>
      <Icon icon={icon || IconPlaceholder} boxSize={48} {...iconProps} />
    </div>
  );
};
