import { AvatarFile } from '../Avatar/AvatarFile';
import { AvatarSize } from '../Avatar/Avatar';
import { UserUtils } from '../../../../store/users/UserUtils';
import { UserType } from '../../../../graphql/types';
import { routeLinks } from '../../../../routes/routeLinks';
import { TextLink, TextLinkVariant } from '../TextLink/TextLink';
import s from './PersonSmall.module.scss';

export interface PersonSmallProps {
  user: UserType;
}

export function PersonSmall({ user }: PersonSmallProps) {
  return (
    <TextLink
      className={s.PersonSmall}
      variant={TextLinkVariant.black}
      noUnderline={true}
      to={`${routeLinks.users.to}/${user.id}`}
    >
      <AvatarFile
        fileId={UserUtils.getAvatar(user)}
        size={AvatarSize.extraSmall}
      />
      <div>{UserUtils.getName(user)}</div>
    </TextLink>
  );
}
