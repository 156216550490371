export function PromiseAllSettled<PT>(promises: Promise<PT>[]) {
  return Promise.all(
    promises.map((p) =>
      Promise.resolve(p).then(
        (value) => ({
          status: 'fulfilled',
          value: value
        }),
        (error) => ({
          status: 'rejected',
          reason: error
        })
      )
    )
  );
}
