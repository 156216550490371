
function env(variable: string | undefined, defaultValue: string) {
  if (typeof variable === 'undefined') {
    return defaultValue;
  }
  return variable;
}


export function booleanEnv(variable: string | undefined) {
  return env(variable, 'false') === 'true';
}
