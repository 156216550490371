import React, { useCallback } from 'react';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { STORE_LOCATION } from '../../../../store/storeKeys';
import {
  TransformedQueryParams,
  URL_KEY_ACTIVE_STATUS,
  URL_KEY_FILTER_CATEGORIES,
  URL_KEY_FILTER_KINDS,
  URL_KEY_PAGE,
  URL_KEY_SEARCH
} from '../../../../store/urlKeys';
import { OffersFilter } from '../../../../graphql/types';
import { FilterSearch } from '../../../../common/components/ui/Filter/FilterSearch';
import { FilterCategories } from '../../../../common/components/utils/filters/FilterCategories';
import { FilterKinds } from '../../../../common/components/utils/filters/FilterKinds';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { FilterStatus } from '../../../../common/components/utils/filters/FilterStatus';
import { useHasPermission } from '../../../../common/hooks/useHasPermission';
import { UserPermission } from '../../../../utils/permissions';
import { useCurrentUser } from '../../../../common/hooks/useCurrentUser';
import s from './useOffersFilter.module.scss';

export const useOffersFilter = () => {
  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.OfferCrud);
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const query = (locationState.query || {}) as TransformedQueryParams;
  const changeQuery = locationStore.changeQuery;

  const filter: OffersFilter = {
    active: canEdit ? query[URL_KEY_ACTIVE_STATUS] : true,
    category_ids: query[URL_KEY_FILTER_CATEGORIES],
    kind_ids: query[URL_KEY_FILTER_KINDS],
    search: query[URL_KEY_SEARCH]
  };

  const handleFilterChange = useCallback(
    (key: string, valueConverter?: (value) => any) => {
      return (value) => {
        const val = valueConverter ? valueConverter(value) : value;

        changeQuery({
          [key]: val,
          [URL_KEY_PAGE]: 1
        });
      };
    },
    [changeQuery]
  );

  const { isMobile } = useWindowSize();

  const filterComponent = (
    <div className={s.OffersFilter}>
      {!isMobile && (
        <FilterSearch
          value={filter.search ?? undefined}
          onChange={handleFilterChange(URL_KEY_SEARCH)}
          placeholder={'Поиск по бонусам'}
          className={s.OffersFilter__filter}
          classes={{
            filter: { content: s.OffersPage__filterRoot },
            searchField: { root: s.OffersPage__searchField }
          }}
        />
      )}
      <FilterCategories
        label={'Категория'}
        isMulti
        value={filter.category_ids}
        onChange={handleFilterChange(URL_KEY_FILTER_CATEGORIES)}
        className={s.OffersFilter__filter}
        classes={{ select: { root: s.OffersFilter__select } }}
      />
      <FilterKinds
        label={'Тип'}
        isMulti
        value={filter.kind_ids}
        onChange={handleFilterChange(URL_KEY_FILTER_KINDS)}
        className={s.OffersFilter__filter}
        classes={{ select: { root: s.OffersFilter__select } }}
      />
      {canEdit && (
        <FilterStatus
          value={filter.active}
          onChange={handleFilterChange(URL_KEY_ACTIVE_STATUS)}
        />
      )}
    </div>
  );
  return [filterComponent, filter, handleFilterChange] as const;
};
