import gql from 'graphql-tag';
import { FRAGMENT_PAGINATION_HEADER } from '../fragments/common';

export const QUERY_GET_ROLES = gql`
  query queryGetRoles($input: RolesPageInput) {
    rolesPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        code
        created_at
        creator_id
        id
        name
        permissions {
          base_permission_code
          created_at
          id
          object_id
          object_type
          role_id
          updated_at
          user_id
        }
        updated_at
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
`;
