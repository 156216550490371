import {
  BottomSheet as SpringBottomSheet,
  BottomSheetProps as SpringBottomSheetProps
} from 'react-spring-bottom-sheet';
import clsx from 'clsx';
import React from 'react';
import { Title, TitleVariant } from '../Title/Title';
import { ReactComponent as CrossIcon } from '../../../../assets/img/icons/cross.svg';
import s from './BottomSheet.module.scss';

export interface BottomSheetProps
  extends Omit<SpringBottomSheetProps, 'title'> {
  title?: React.ReactNode;
}

export function BottomSheet({
  className,
  title,
  snapPoints = ({ minHeight }) => minHeight,
  children,
  header,
  ...props
}: BottomSheetProps) {
  return (
    <SpringBottomSheet
      className={clsx(className, s.BottomSheet)}
      snapPoints={snapPoints}
      header={
        header ?? (
          <div className={s.BottomSheet__header}>
            {title && <Title variant={TitleVariant.h4}>{title}</Title>}
            <span
              className={s.BottomSheet__closeButton}
              onClick={props.onDismiss}
            >
              <CrossIcon width={'16px'} height={'16px'} />
            </span>
          </div>
        )
      }
      {...props}
    >
      <div className={s.BottomSheet__wrapper}>
        <div className={s.BottomSheet__content}>{children}</div>
      </div>
    </SpringBottomSheet>
  );
}
