import { IUseGraphqlWatchQueryOptions } from '@proscom/prostore-apollo-react';
import { UserPageInput, UserPageType } from '../types';
import { useAuthGraphqlQuery } from '../../common/hooks/utils/useAuthGraphqlQuery';
import { QUERY_GET_USERS } from '../queries/users';
import { ONE_PAGE_ITEMS } from '../../constants';

const queryOptions: IUseGraphqlWatchQueryOptions<
  { input: UserPageInput },
  UserPageType
> = {
  query: QUERY_GET_USERS,
  mapData: (result) => result.usersPage
};

export function useUsers({ filter, pagination }: UserPageInput) {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: {
      input: {
        filter,
        pagination: {
          onePage: ONE_PAGE_ITEMS,
          ...pagination
        }
      }
    }
  });
}
