import React, { useMemo } from 'react';
import { SwitchBox, SwitchBoxProps } from '../../SwitchBox/SwitchBox';
import { SwitchBoxButtonIdType } from '../../SwitchBox/SwitchBoxButton';
import { ReactComponent as IconViewGrid } from '../../../../../assets/img/icons/view/grid.svg';
import { ReactComponent as IconViewList } from '../../../../../assets/img/icons/view/list.svg';

export enum SwitchViewType {
  GRID = 'grid',
  LIST = 'list'
}

const switchIcons = {
  [SwitchViewType.GRID]: IconViewGrid,
  [SwitchViewType.LIST]: IconViewList
};

export interface SwitchViewSwitchProps {
  id: SwitchBoxButtonIdType;
  type: SwitchViewType;
}

export interface SwitchViewProps extends Omit<SwitchBoxProps, 'switches'> {
  switches: SwitchViewSwitchProps[];
}

export const SwitchView: React.FC<SwitchViewProps> = ({
  switches,
  ...props
}) => {
  const cSwitches = useMemo(() => {
    return switches.map((sw) => ({
      id: sw.id,
      icon: switchIcons[sw.type]
    }));
  }, [switches]);

  return <SwitchBox switches={cSwitches} {...props} />;
};
