import React from 'react';
import clsx from 'clsx';
import { Select, SelectClasses, SelectProps } from '../Select/Select';
import { Filter, FilterClasses, FilterProps } from './Filter';
import s from './Filter.module.scss';

export interface FilterSelectClasses {
  filter?: FilterClasses;
  select?: SelectClasses;
}

export interface FilterSelectProps
  extends Omit<FilterProps, 'classes'>,
    Omit<SelectProps, 'classes' | 'label'> {
  classes?: FilterSelectClasses;
}

export const FilterSelect: React.FC<FilterSelectProps> = ({
  className,
  label,
  classes,
  ...props
}) => {
  return (
    <Filter
      className={clsx(s.FilterSelect, className)}
      classes={classes?.filter}
      label={label}
    >
      <Select {...props} classes={classes?.select} />
    </Filter>
  );
};
