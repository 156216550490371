import React, { useState } from 'react';
import { ActionButton, ActionType } from '../../ActionButton/ActionButton';
import { DocumentType } from '../../../../../graphql/types';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { DocumentEdit, DocumentEditProps } from './DocumentEdit';
import s from './Document.module.scss';

export interface DocumentProps extends DocumentType {
  onDelete?: (id: string) => void;
  onChangeSubmit?: DocumentEditProps['onSubmit'];
  editable?: boolean;
}

export const Document: React.FC<DocumentProps> = ({
  onDelete,
  onChangeSubmit,
  editable,
  ...document
}) => {
  const [isEdit, setEdit] = useState(false);
  const { name, file_id, id } = document;

  const { handle: handleOpenDocument } = useFileDownload(
    undefined,
    FileTypeEnum.document
  );

  const handleDeleteDocument = () => {
    onDelete?.(id);
  };

  if (isEdit) {
    return (
      <DocumentEdit
        document={document}
        onSubmit={onChangeSubmit}
        onCancel={() => {
          setEdit(false);
        }}
      />
    );
  }

  return (
    <div className={s.Document}>
      <div className={s.Document__link} onClick={handleOpenDocument(file_id)}>
        {name}
      </div>
      <div className={s.Document__actions}>
        {editable && (
          <>
            <ActionButton
              actionType={ActionType.edit}
              onClick={() => {
                setEdit(true);
              }}
            />
            <ActionButton
              actionType={ActionType.delete}
              onClick={handleDeleteDocument}
            />
          </>
        )}
      </div>
    </div>
  );
};
