import { Routes, RouteType } from '../../common/components/utils/Routes';
import { RequireAuth } from '../RequireAuth';
import NotFoundPage from '../notFound/NotFoundPage';
import ArticlesPage from './list/ArticlesPage';
import ArticlePage from './index/ArticlePage';
import ArticleEditPage from './edit/ArticleEditPage';
import ArticleCreatePage from './create/ArticleCreatePage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: RequireAuth(ArticlesPage)
  },
  {
    path: '/create',
    exact: true,
    component: RequireAuth(ArticleCreatePage)
  },
  {
    path: '/:articleId',
    exact: true,
    component: RequireAuth(ArticlePage)
  },
  {
    path: '/:articleId/edit',
    exact: true,
    component: RequireAuth(ArticleEditPage)
  },
  {
    component: NotFoundPage
  }
];

const ArticlesRoutes = Routes(routes);

export default ArticlesRoutes;
