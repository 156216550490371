import React from 'react';
import { IContentHeader } from '../../Form/FormContentEditor/Content';
import { SpecificContentBlockProps } from '../types';

export function HeaderBlock({
  data,
  className
}: SpecificContentBlockProps<IContentHeader>) {
  const { content, headerVariant } = data;
  const Component = headerVariant || 'h2';
  if (!Component) {
    return <div className={className}>Некорректный блок</div>;
  }

  return <Component className={className}>{content}</Component>;
}
