import React from 'react';
import {
  AsyncSingletonError,
  useAsyncOperation
} from '@proscom/prostore-react';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Button, ButtonClasses, ButtonProps, ButtonSize } from '../Button';
import { ReactComponent as UploadIcon } from '../../../../assets/img/icons/upload.svg';
import {
  MUTATION_CREATE_OTT_FOR_ARTICLE_COVER_UPLOAD,
  MUTATION_CREATE_OTT_FOR_ARTICLE_DOCUMENT_UPLOAD,
  MUTATION_CREATE_OTT_FOR_ARTICLE_IMAGE_UPLOAD,
  MUTATION_CREATE_OTT_FOR_AVATAR_UPLOAD,
  MUTATION_CREATE_OTT_FOR_COURSE_DOCUMENT_UPLOAD,
  MUTATION_CREATE_OTT_FOR_COURSE_IMAGE_UPLOAD,
  MUTATION_CREATE_OTT_FOR_DOCUMENT_UPLOAD,
  MUTATION_CREATE_OTT_FOR_EVENT_COVER_UPLOAD,
  MUTATION_CREATE_OTT_FOR_EXTERNAL_POLL_COVER_UPLOAD,
  MUTATION_CREATE_OTT_FOR_FEEDBACK_DOCUMENT_UPLOAD,
  MUTATION_CREATE_OTT_FOR_INTERNSHIP_COVER_UPLOAD,
  MUTATION_CREATE_OTT_FOR_INTERNSHIP_MANUAL_UPLOAD,
  MUTATION_CREATE_OTT_FOR_MANUAL_UPLOAD,
  MUTATION_CREATE_OTT_FOR_NOTICE_IMAGE_UPLOAD,
  MUTATION_CREATE_OTT_FOR_OFFER_COVER_UPLOAD,
  MUTATION_CREATE_OTT_FOR_OFFER_DOCUMENT_UPLOAD,
  MUTATION_CREATE_OTT_FOR_OFFER_PARTNER_LOGO_UPLOAD,
  MUTATION_CREATE_OTT_FOR_PERSONNEL_RESERVE_COVER_UPLOAD
} from '../../../../graphql/mutations/oneTimeTokens';
import { fileUploadUrl } from '../../../../config';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { ensureFetch, FetchError } from '../../../../utils/url/ensureFetch';
import s from './FileUploadButton.module.scss';

export interface FileUploadButtonClasses {
  root?: string;
  buttonClasses?: ButtonClasses;
  label?: string;
  input?: string;
}

export interface FileUploadButtonProps {
  onFileUploaded?: (id: string, file: File) => void;
  fileType: FileTypeEnum;
  buttonText?: string;
  buttonProps?: ButtonProps;
  multiple?: boolean;
  classes?: FileUploadButtonClasses;
  className?: string;
}

const RequestConfig = {
  [FileTypeEnum.manual]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_MANUAL_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.manual
  },
  [FileTypeEnum.document]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_DOCUMENT_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.document
  },
  [FileTypeEnum.notice_image]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_NOTICE_IMAGE_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.notice_image
  },
  [FileTypeEnum.article_cover]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_ARTICLE_COVER_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.article_cover
  },
  [FileTypeEnum.article_image]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_ARTICLE_IMAGE_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.article_image
  },
  [FileTypeEnum.event_cover]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_EVENT_COVER_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.event_cover
  },
  [FileTypeEnum.offer_cover]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_OFFER_COVER_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.offer_cover
  },
  [FileTypeEnum.offer_partner_logo]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_OFFER_PARTNER_LOGO_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.offer_partner_logo
  },
  [FileTypeEnum.personnel_reserve_cover]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_PERSONNEL_RESERVE_COVER_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.personnel_reserve_cover
  },
  [FileTypeEnum.avatar]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_AVATAR_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.avatar
  },
  [FileTypeEnum.external_poll_cover]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_EXTERNAL_POLL_COVER_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.external_poll_cover
  },
  [FileTypeEnum.internship_cover]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_INTERNSHIP_COVER_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.internship_cover
  },
  [FileTypeEnum.internship_manual]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_INTERNSHIP_MANUAL_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.internship_manual
  },
  [FileTypeEnum.course_image]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_COURSE_IMAGE_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.course_image
  },
  [FileTypeEnum.feedback_document]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_FEEDBACK_DOCUMENT_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.feedback_document
  },
  [FileTypeEnum.course_document]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_COURSE_DOCUMENT_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.course_document
  },
  [FileTypeEnum.offer_document]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_OFFER_DOCUMENT_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.offer_document
  },
  [FileTypeEnum.article_document]: {
    ottMutation: MUTATION_CREATE_OTT_FOR_ARTICLE_DOCUMENT_UPLOAD,
    endpoint: fileUploadUrl + '/' + FileTypeEnum.article_document
  }
};

export const FileUploadButton = React.memo(function FileUploadButtonProps({
  onFileUploaded,
  fileType,
  buttonText = 'Загрузить документ',
  buttonProps,
  multiple = false,
  classes,
  className
}: FileUploadButtonProps) {
  const client = useContextApolloClient();
  const { run, loading } = useAsyncOperation(
    async (file: File) => {
      const result = await client.mutate({
        mutation: RequestConfig[fileType].ottMutation,
        variables: {}
      });
      const token = result.data?.result;

      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('token', token);

      const fileUploadResponse = await ensureFetch(
        RequestConfig[fileType].endpoint,
        {
          method: 'POST',
          body: formData
        }
      );
      const response = await fileUploadResponse.json();
      const fileId = response.file_id;
      onFileUploaded?.(fileId.toString(), file);
    },
    {
      singleton: true
    }
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) =>
      run(files[0]).catch((err) => {
        if (err instanceof AsyncSingletonError) return;
        if (err instanceof FetchError) {
          if (err.response.body && typeof err.response.body === 'object') {
            const { message } = err.response.body;
            if (message === 'File is not an image') {
              toast.error('Загружаемый файл не является изображением');
              return;
            }
          }
        }
        handleDefaultError(
          err,
          'Не удалось загрузить файл на сервер. Попробуйте снова'
        );
      }),
    multiple
  });

  return (
    <div
      {...getRootProps()}
      className={clsx(s.FileLoadButton, className, classes?.root)}
    >
      <input {...getInputProps()} />
      <Button
        iconLeft={<UploadIcon />}
        classes={{
          ...classes?.buttonClasses,
          root: clsx(
            s.FileLoadButton__customButton,
            classes?.buttonClasses?.root
          )
        }}
        size={ButtonSize.large}
        loading={loading}
        {...buttonProps}
      >
        {buttonText}
      </Button>
    </div>
  );
});
