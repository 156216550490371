import { useLocationQuery as useProstoreLocationQuery } from '@proscom/prostore-react-router';
import { TransformedQueryParams } from '../../../store/urlKeys';
import { STORE_LOCATION } from '../../../store/storeKeys';

export function useLocationQuery<
  Keys extends readonly string[] = readonly string[]
>(keys: Keys) {
  return useProstoreLocationQuery<TransformedQueryParams, Keys>(
    STORE_LOCATION,
    keys
  );
}
