import React from 'react';
import { EMDASH } from '@proscom/ui-utils';
import { LayoutGrid } from '../../../Layout/Layout';
import { TextFieldPreview } from '../../TextFieldPreview/TextFieldPreview';
import { TextLink } from '../../TextLink/TextLink';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { DATE_FORMAT_DAY_MONTH } from '../../../../../utils/date/date';
import {
  CampusType,
  DegreeType,
  UserContactType
} from '../../../../../graphql/types';
import { UserDegrees } from './UserDegrees';
import s from './UserGeneral.module.scss';

export interface UserGeneralProps {
  birthday?: string | null;
  email?: string | null;
  workPhone?: string | null;
  mobilePhone?: string | null;
  campus?: CampusType | null;
  degrees?: DegreeType[] | null;
  contacts?: UserContactType[] | null;
}

const colsCount = 6;

export const UserGeneral: React.FC<UserGeneralProps> = ({
  birthday,
  email,
  workPhone,
  mobilePhone,
  campus,
  degrees,
  contacts
}) => {
  const formattedBirthday = useFormatDate(birthday, DATE_FORMAT_DAY_MONTH);

  return (
    <LayoutGrid className={s.UserGeneral}>
      {formattedBirthday && (
        <LayoutGrid.GridItem cols={colsCount}>
          <TextFieldPreview title={'День рождения'}>
            {formattedBirthday}
          </TextFieldPreview>
        </LayoutGrid.GridItem>
      )}

      {email && (
        <LayoutGrid.GridItem cols={colsCount}>
          <TextFieldPreview title={'Email'}>
            <TextLink href={`mailto:${email}`} noUnderline>
              {email}
            </TextLink>
          </TextFieldPreview>
        </LayoutGrid.GridItem>
      )}

      {campus && (
        <LayoutGrid.GridItem cols={colsCount}>
          <TextFieldPreview title={'Кампус'}>
            {campus.name || EMDASH}
          </TextFieldPreview>
        </LayoutGrid.GridItem>
      )}

      {mobilePhone && (
        <LayoutGrid.GridItem cols={colsCount}>
          <TextFieldPreview title={'Мобильный телефон'}>
            {mobilePhone}
          </TextFieldPreview>
        </LayoutGrid.GridItem>
      )}

      {workPhone && (
        <LayoutGrid.GridItem cols={colsCount}>
          <TextFieldPreview title={'Рабочий телефон'}>
            {workPhone}
          </TextFieldPreview>
        </LayoutGrid.GridItem>
      )}

      {degrees && degrees.length > 0 && (
        <LayoutGrid.GridItem cols={colsCount}>
          <UserDegrees degrees={degrees} />
        </LayoutGrid.GridItem>
      )}

      {/*{contacts && contacts.length > 0 && (*/}
      {/*  <LayoutGrid.GridItem cols={colsCount}>*/}
      {/*    <UserContacts contacts={contacts} />*/}
      {/*  </LayoutGrid.GridItem>*/}
      {/*)}*/}
    </LayoutGrid>
  );
};
