import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import ReactSlick, { CustomArrowProps, Settings } from 'react-slick';
import { Portal } from 'react-portal';
import { ReactComponent as ArrowLeft } from '../../../../assets/img/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/img/icons/ArrowRight.svg';
import { Icon } from '../Icon/Icon';
import { useWindowSize } from '../../../hooks/useWindowSize';
import s from './Slider.module.scss';

const defaultConfig: Settings = {
  dots: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
  focusOnSelect: true,
  variableWidth: false,
  initialSlide: 0
};

export enum SliderDirection {
  Prev = 'prev',
  Next = 'next'
}

export interface SliderButtonClasses {
  root?: string;
}

export interface SliderButtonProps extends CustomArrowProps {
  classes?: SliderButtonClasses;
  direction: SliderDirection;
  component?: React.ReactNode;
  containerNode?: HTMLDivElement | null;
}

export const SliderButton: React.FC<SliderButtonProps> = ({
  className,
  classes,
  direction,
  component,
  containerNode,
  onClick
}) => {
  const icon = direction === SliderDirection.Prev ? ArrowLeft : ArrowRight;

  const button = component ? (
    <button className={clsx(className, classes?.root)} onClick={onClick}>
      {component}
    </button>
  ) : (
    <button
      className={clsx(
        s.SliderButton,
        className,
        classes?.root,
        s[`SliderButton_${direction}`]
      )}
      onClick={onClick}
    >
      <Icon icon={icon} boxSize={20} />
    </button>
  );

  return containerNode ? (
    <Portal node={containerNode}>{button}</Portal>
  ) : (
    button
  );
};

export interface SliderProps {
  className?: string;
  classes?: {
    root?: string;
    offset?: string;
    slick?: string;
    button?: string;
    buttonPrev?: string;
    buttonNext?: string;
  };
  arrowPrev?: React.ReactNode;
  arrowNext?: React.ReactNode;
  isOverflowHidden?: boolean;
  onChange?: (currentSlide: number) => void;
  config?: Settings;
  children?: React.ReactNode;
}

export const Slider: React.FC<SliderProps> = ({
  className,
  classes,
  arrowPrev,
  arrowNext,
  isOverflowHidden = true,
  children,
  onChange,
  config
}) => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const slickRef = useRef<ReactSlick | null>(null);
  const [btnContainer, setBtnContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (sliderRef?.current) {
      setBtnContainer(sliderRef.current);
    }
  }, [sliderRef]);

  const cfg = useMemo(() => {
    return {
      ...defaultConfig,
      ...config
    };
  }, [config]);

  const isMultiple = cfg.slidesToShow && cfg.slidesToShow > 1;

  return (
    <div ref={sliderRef} className={clsx(s.Slider, className, classes?.root)}>
      <div
        className={clsx(s.Slider__offset, classes?.offset, {
          [s.Slider__offset_hidden]: isOverflowHidden
        })}
      >
        <ReactSlick
          className={clsx(s.Slick, classes?.slick, {
            [s.Slick_multiple]: isMultiple
          })}
          ref={slickRef}
          {...cfg}
          prevArrow={
            <SliderButton
              classes={{
                root: clsx(classes?.button, classes?.buttonPrev)
              }}
              direction={SliderDirection.Prev}
              containerNode={btnContainer}
              component={arrowPrev}
            />
          }
          nextArrow={
            <SliderButton
              classes={{
                root: clsx(classes?.button, classes?.buttonNext)
              }}
              direction={SliderDirection.Next}
              containerNode={btnContainer}
              component={arrowNext}
            />
          }
          afterChange={onChange}
        >
          {children}
        </ReactSlick>
      </div>
    </div>
  );
};

export interface SliderConvertibleProps extends SliderProps {
  convertedContent?: React.ReactNode;
}

export const SliderConvertible: React.FC<SliderConvertibleProps> = ({
  config,
  convertedContent,
  children,
  ...props
}) => {
  const { width } = useWindowSize();

  const childrenAmount = React.Children.toArray(children).length;

  const amountForSlider = useMemo(() => {
    let amount = config?.slidesToShow || 1;

    config?.responsive?.forEach((bp) => {
      if (width <= bp.breakpoint) {
        amount = (bp.settings as Settings).slidesToShow || amount;
        return;
      }
    });

    return amount;
  }, [width, config]);

  return (
    <>
      {childrenAmount > amountForSlider ? (
        <Slider config={config} {...props}>
          {children}
        </Slider>
      ) : (
        convertedContent || children
      )}
    </>
  );
};
