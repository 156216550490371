import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import { Card, CardStyle } from '../../../common/components/ui/Card/Card';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import s from './ContactsCard.module.scss';

export interface ContactsCardPersonClasses {
  root?: string;
  avatar?: string;
  content?: string;
  name?: string;
  profession?: string;
}

export interface ContactsCardPersonProps {
  className?: string;
  classes?: ContactsCardPersonClasses;
  avatarSrc?: string;
  profession?: string;
  name: string;
  inverted?: boolean;
}

export const ContactsCardPerson: React.FC<ContactsCardPersonProps> = ({
  className,
  classes,
  avatarSrc,
  profession,
  name,
  inverted
}) => {
  return (
    <div className={clsx(s.ContactsCardPerson, className, classes?.root)}>
      {avatarSrc && (
        <div
          className={clsx(s.ContactsCardPerson__ava, classes?.avatar)}
          style={{
            backgroundImage: `url(${avatarSrc})`
          }}
        />
      )}
      <div className={clsx(s.ContactsCardPerson__content, classes?.content)}>
        {inverted ? (
          <>
            <div className={clsx(s.ContactsCardPerson__name, classes?.name)}>
              {name}
            </div>
            <div
              className={clsx(s.ContactsCardPerson__prof, classes?.profession)}
            >
              {profession}
            </div>
          </>
        ) : (
          <>
            <div
              className={clsx(s.ContactsCardPerson__prof, classes?.profession)}
            >
              {profession}
            </div>
            <div className={clsx(s.ContactsCardPerson__name, classes?.name)}>
              {name}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export interface ContactsCardSectionClasses {
  root?: string;
  title?: string;
  content?: string;
  itemTitle?: string;
  itemContent?: string;
}

export interface ContactsCardSectionProps {
  className?: string;
  classes?: ContactsCardSectionClasses;
  title?: React.ReactNode;
  contacts: {
    title?: string;
    content?: React.ReactNode;
    mobileContent?: React.ReactNode;
    tabletContent?: React.ReactNode;
    titleClassName?: string;
    contentClassName?: string;
  }[];
  listContent?: boolean;
}

export const ContactsCardSection: React.FC<ContactsCardSectionProps> = ({
  className,
  classes,
  title,
  contacts,
  listContent
}) => {
  const ItemWrapper = useMemo(
    () => (props) => {
      if (listContent) {
        return <div className={s.ContactsCardSection__contact} {...props} />;
      } else {
        return <Fragment {...props} />;
      }
    },
    [listContent]
  );

  const { isMobile, isTablet } = useWindowSize();

  return (
    <div className={clsx(s.ContactsCardSection, className, classes?.root)}>
      {title && (
        <div className={clsx(s.ContactsCardSection__title, classes?.title)}>
          {title}
        </div>
      )}
      <div
        className={clsx(s.ContactsCardSection__content, classes?.content, {
          [s.ContactsCardSection__content_list]: listContent
        })}
      >
        {contacts.map((item, iItem) => (
          <ItemWrapper key={iItem}>
            <div
              className={clsx(
                s.ContactsCardSection__contactTitle,
                classes?.itemTitle,
                item.titleClassName
              )}
            >
              {item.title}
            </div>
            <div
              className={clsx(
                s.ContactsCardSection__contactContent,
                classes?.itemContent,
                item.contentClassName
              )}
            >
              {isMobile && item.mobileContent
                ? item.mobileContent
                : isTablet && item.tabletContent
                ? item.tabletContent
                : item.content}
            </div>
          </ItemWrapper>
        ))}
      </div>
    </div>
  );
};

export interface ContactsCardClasses {
  root?: string;
  sections?: string;
  section?: ContactsCardSectionClasses;
}

export interface ContactsCardProps {
  className?: string;
  classes?: ContactsCardClasses;
  sections: ContactsCardSectionProps[];
  children?: React.ReactNode;
}

export const ContactsCard: React.FC<ContactsCardProps> = ({
  className,
  classes,
  sections,
  children
}) => {
  return (
    <Card
      className={clsx(s.ContactsCard, className, classes?.root)}
      cardStyle={CardStyle.bordered}
    >
      <div className={clsx(s.ContactsCard__sections, classes?.sections)}>
        {sections.map((section, iSection) => (
          <ContactsCardSection
            key={iSection}
            {...section}
            classes={{
              ...section.classes,
              ...classes?.section
            }}
          />
        ))}
      </div>

      {children}
    </Card>
  );
};
