import gql from 'graphql-tag';
import { FRAGMENT_USER_BASE } from '../fragments/users';

export const QUERY_GET_OFFER_KINDS_PAGE_OPTIONS = gql`
  query queryGetOfferKindsPageOptions($input: OfferKindsPageInput) {
    items: offerKindsPage(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_OFFER_CATEGORIES_PAGE_OPTIONS = gql`
  query queryGetOfferCategoriesPageOptions($input: OfferCategoriesPageInput) {
    items: offerCategoriesPage(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_SKILLS_OPTIONS = gql`
  query getSkillsOptions($input: SkillsPageInput) {
    items: skillsPage(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_AREAS_OF_INTEREST_OPTIONS = gql`
  query getAreasOfInterestOptions($input: AreasOfInterestPageInput) {
    items: areasOfInterestPage(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_USERS_OPTIONS = gql`
  query getUsersOptions($input: UserPageInput) {
    items: usersPage(input: $input) {
      list {
        ...UserBase
      }
    }
  }

  ${FRAGMENT_USER_BASE}
`;

export const QUERY_GET_UNITS_OPTIONS = gql`
  query getUnitsOptions($input: UnitsPageInput) {
    items: unitsPage(input: $input) {
      header {
        hasNext
      }
      list {
        id
        name
        parent_ids
        parent_unit_id
      }
    }
  }
`;

export const QUERY_GET_COURSE_SUBJECTS_OPTIONS = gql`
  query queryGetCourseSubjectsOptions($input: CourseSubjectsPageInput) {
    items: courseSubjectsPage(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_INTERNSHIP_SUBJECTS_OPTIONS = gql`
  query getInternshipSubjectsOptions($input: InternshipsSubjectsPageInput) {
    items: internshipSubjectsPage(input: $input) {
      header {
        hasNext
      }
      list {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_TAGS_OPTIONS = gql`
  query getTagsOptions($input: TagsPageInput) {
    items: tagsPage(input: $input) {
      header {
        hasNext
      }
      list {
        id
        name
      }
    }
  }
`;
