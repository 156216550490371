import gql from 'graphql-tag';
import { FRAGMENT_COURSE_FULL } from '../fragments/courses';

export const MUTATION_CREATE_ANNOUNCE_COURSE = gql`
  mutation mutationCreateAnnounceCourse($input: CourseAnnounceCreateInput!) {
    result: createCourseAnnounce(input: $input) {
      ...CourseFull
    }
  }
  ${FRAGMENT_COURSE_FULL}
`;

export const MUTATION_CREATE_LONGREAD_COURSE = gql`
  mutation mutationCreateLongreadCourse($input: CourseLongreadCreateInput!) {
    result: createCourseLongread(input: $input) {
      ...CourseFull
    }
  }
  ${FRAGMENT_COURSE_FULL}
`;

export const MUTATION_UPDATE_ANNOUNCE_COURSE = gql`
  mutation mutationUpdateAnnounceCourse($input: CourseAnnounceUpdateInput!) {
    result: updateCourseAnnounce(input: $input) {
      ...CourseFull
    }
  }
  ${FRAGMENT_COURSE_FULL}
`;

export const MUTATION_UPDATE_LONGREAD_COURSE = gql`
  mutation mutationUpdateLongreadCourse($input: CourseLongreadUpdateInput!) {
    result: updateCourseLongread(input: $input) {
      ...CourseFull
    }
  }
  ${FRAGMENT_COURSE_FULL}
`;

export const MUTATION_DELETE_COURSE = gql`
  mutation mutationDeleteCourse($id: ID!) {
    result: deleteCourse(course_id: $id)
  }
`;

export const MUTATION_CREATE_COURSE_TEST_RESULT = gql`
  mutation mutationCreateCourseTestResult($input: String!) {
    result: createCourseTestResult(input: $input)
  }
`;
