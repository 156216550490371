import React from 'react';
import { get } from 'lodash-es';
import { TableRowData } from '../../../common/components/ui/Table/Table';
import { Button, ButtonSize } from '../../../common/components/ui/Button';
import { useFileDownload } from '../../../common/hooks/useFileDownload';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import s from '../ManualsPage.module.scss';

export interface FileLinkProps {
  row: TableRowData;
  field: string;
}

export const FileLink: React.FC<FileLinkProps> = ({ row, field }) => {
  const { handle } = useFileDownload(undefined, FileTypeEnum.manual);
  const value = get(row, field, undefined);
  return (
    <Button
      onClick={handle(row.file_id)}
      size={ButtonSize.small}
      classes={{
        root: s.FileLink,
        content: s.FileLink__content
      }}
    >
      {value}
    </Button>
  );
};
