import React from 'react';
import clsx from 'clsx';
import { renderSlot, Slot } from '@proscom/ui-react';
import { Tab, TabClasses } from '../Tab/Tab';
import s from './TabsList.module.scss';

export interface TabsListClasses {
  root?: string;
  tab?: TabClasses;
}

export interface RenderTabProps {
  title: string;
  id: string;
  onClick: (id: string) => void;
  isEnabled: boolean;
}

export interface TabsData {
  id: string;
  title: string;
  renderTab?: Slot<RenderTabProps>;
}

export interface TabsListProps {
  tabs: TabsData[];
  curTab: string;
  onChange: (id: string) => void;
  classes?: TabsListClasses;
  className?: string;
}

export const TabsList: React.FC<TabsListProps> = ({
  tabs,
  curTab,
  onChange,
  classes,
  className
}) => {
  return (
    <div className={clsx(s.TabsList, className, classes?.root)}>
      <div
        className={clsx(s.TabsList__wrapper, {
          [s.TabsList__wrapper_twoItems]: tabs.length === 2
        })}
      >
        {tabs.map((item) => {
          const props = {
            key: item.id,
            title: item.title,
            id: item.id,
            isEnabled: curTab === item.id,
            onClick: onChange
          };

          return item.renderTab ? (
            renderSlot(item.renderTab, props)
          ) : (
            <Tab {...props} classes={classes?.tab} />
          );
        })}
      </div>
    </div>
  );
};
