import { BehaviorStore } from '@proscom/prostore';
import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';
import { isValid } from 'date-fns';
import isNil from 'lodash-es/isNil';
import { prodCalendarApiUrl } from '../config';
import { PromiseAllSettled } from '../utils/promise';

export function getProdCalendarApiUrl(year: number) {
  return prodCalendarApiUrl.replace(/%year%/, year.toString());
}

export interface ProdCalendarStoreState {
  holidayDates: Date[];
  loaded: boolean;
  loading: boolean;
  error: boolean;
}

const currentYear = new Date().getFullYear();
const yearsToFetch = [currentYear - 1, currentYear, currentYear + 1];

/**
 * документация тут: http://xmlcalendar.ru/
 */

export class ProdCalendarStore extends BehaviorStore<ProdCalendarStoreState> {
  xmlParser = new XMLParser({
    ignoreAttributes: false
  });

  constructor() {
    super({
      holidayDates: [],
      loaded: false,
      loading: false,
      error: false
    });

    this.init();
  }

  _setError(error) {
    this.setState({
      loading: false,
      loaded: true,
      error: true
    });
  }

  handleCalendarLoaded(data) {
    if (!data.length) {
      this._setError('error: no data');
      return;
    }

    const holidayDates: Date[] = [];

    data.forEach((obj) => {
      const calendarYear = obj.calendar?.['@_year'];
      const dates = (obj.calendar.days?.day || [])
        .filter((d) => d['@_t'] === '1')
        .filter((d) => isNil(d['@_f']) || d['@_f']?.length !== 5)
        .map((d) => {
          const date = new Date(`${calendarYear}.${d['@_d']}`);
          return isValid(date) ? date : null;
        })
        .filter(Boolean);

      holidayDates.push(...dates);
    });

    this.setState({
      loading: false,
      loaded: true,
      holidayDates
    });
  }

  async loadCalendar(urls: string[] | string) {
    this.setState({
      loading: true,
      error: false
    });

    try {
      const urlsToFetch = Array.isArray(urls) ? urls : [urls];

      const results: any = await PromiseAllSettled(
        urlsToFetch.map((url) => axios.get(url))
      );

      const data = results
        .filter((res) => {
          return res.status === 'fulfilled' && res.value?.data;
        })
        .map((res) => {
          if (!res.value.data) return null;
          return this.xmlParser.parse(res.value.data);
        })
        .filter(Boolean);

      if (!data.length) {
        throw new Error('error: no data');
      }

      this.handleCalendarLoaded(data);
    } catch (error) {
      console.error(error);
      this._setError(error);
    }
  }

  init() {
    this.loadCalendar(yearsToFetch.map(getProdCalendarApiUrl));
  }
}
