import gql from 'graphql-tag';
import { FRAGMENT_PAGINATION_HEADER } from '../fragments/common';
import { FRAGMENT_FEEDBACK_FULL } from '../fragments/feedback';

export const QUERY_GET_FEEDBACK_PAGE = gql`
  query queryGetFeedbackPage($input: FeedbackPageInput) {
    feedbacksPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...FeedbackFull
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
  ${FRAGMENT_FEEDBACK_FULL}
`;

export const QUERY_GET_FEEDBACK = gql`
  query queryGetFeedback($id: ID!) {
    feedback(id: $id) {
      ...FeedbackFull
    }
  }

  ${FRAGMENT_FEEDBACK_FULL}
`;
