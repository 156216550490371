import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { ArticleType } from '../../../../../graphql/types';
import { useFileSrc } from '../../../../hooks/useFileSrc';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { Title, TitleVariant } from '../../Title/Title';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { ImagePreview } from '../../ImagePreview/ImagePreview';
import s from './CardArticleOther.module.scss';

export interface CardArticleOtherProps extends ArticleType {
  className?: string;
}

export const CardArticleOther: React.FC<CardArticleOtherProps> = ({
  className,
  id,
  cover_file_id,
  title,
  posted_at
}) => {
  const imageSrc = useFileSrc(cover_file_id, FileTypeEnum.article_cover);
  const formattedDate = useFormatDate(posted_at);

  return (
    <Link
      to={`/articles/${id}`}
      className={clsx(s.CardArticleOther, className)}
    >
      <ImagePreview
        className={s.CardArticleOther__cover}
        classes={{
          image: s.CardArticleOther__coverImage
        }}
        imageSrc={imageSrc}
      />
      <div className={s.CardArticleOther__date}>{formattedDate}</div>
      <Title
        className={s.CardArticleOther__title}
        title={title}
        variant={TitleVariant.h4}
      />
    </Link>
  );
};
