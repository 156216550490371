import gql from 'graphql-tag';

export const FRAGMENT_COURSE_SNIPPET = gql`
  fragment CourseSnippet on CourseType {
    id
    name
    description
    partner_logo_file_id
    course_subject {
      id
      name
    }
    cover_file_id
    type
    source
    priority
    tags {
      id
      name
    }
    status
  }
`;

export const FRAGMENT_COURSE_FULL = gql`
  fragment CourseFull on CourseType {
    acquired_knowledge
    content
    course_link
    course_subject {
      id
      name
    }
    course_subject_id
    cover_file_id
    description
    id
    name
    partner_logo_file_id
    tags {
      id
      name
    }
    teachers {
      name
      position
      speciality
    }
    type
    price
    discount_price
    has_discount
    completion_proof
    learning_format
    listener_category
    source
    priority
    status
    document_files {
      id
      name
    }
  }
`;
