import React from 'react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import {
  Breadcrumbs,
  getBreadcrumbRouteLink,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { routeLinks } from '../../routeLinks';
import { useUser } from '../../../graphql/hooks/useUser';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { UserPageContent } from '../../users/index/UserPageContent';

export function getProfilePageBreadcrumbs(): IBreadcrumbsItem[] {
  return [getBreadcrumbRouteLink(routeLinks.profile)];
}

export default function ProfilePage() {
  const { user: currentUser } = useCurrentUser();

  const userQuery = useUser(currentUser?.id);
  const user = userQuery.state.data;

  const breadcrumbs = getProfilePageBreadcrumbs();

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle title={'Страница сотрудника'} />

        {queryLoader(userQuery) ||
          (!user ? (
            <NoData />
          ) : (
            <UserPageContent
              user={user}
              canEdit={true}
              editLink={`${routeLinks.profile.to}/edit`}
            />
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
