import React from 'react';
import clsx from 'clsx';
import {
  ContentBlockVariant,
  IContentBlock
} from '../Form/FormContentEditor/Content';
import { TextBlock } from './TextBlock/TextBlock';
import { HeaderBlock } from './HeaderBlock/HeaderBlock';
import { ImageBlock } from './ImageBlock/ImageBlock';
import { VideoBlock } from './VideoBlock/VideoBlock';
import { BlockquoteBlock } from './BlockquoteBlock/BlockquoteBlock';
import { TextImageBlock } from './TextImageBlock/TextImageBlock';
import { ContentBlockMeta } from './types';
import s from './ContentBlock.module.scss';

const blocks = {
  [ContentBlockVariant.header]: HeaderBlock,
  [ContentBlockVariant.text]: TextBlock,
  [ContentBlockVariant.image]: ImageBlock,
  [ContentBlockVariant.textImage]: TextImageBlock,
  [ContentBlockVariant.video]: VideoBlock,
  [ContentBlockVariant.blockquote]: BlockquoteBlock
};

export interface ContentBlockProps<Variant extends ContentBlockVariant> {
  className?: string;
  block: IContentBlock<Variant>;
  meta?: ContentBlockMeta;
}

export function ContentBlock<Variant extends ContentBlockVariant>({
  className,
  block,
  meta
}: ContentBlockProps<Variant>) {
  const BlockComponent = blocks[block.variant];

  return BlockComponent ? (
    <BlockComponent
      data={block.data}
      meta={meta}
      className={clsx(
        s.ContentBlock,
        className,
        s[`ContentBlock_${block.variant}`]
      )}
    />
  ) : (
    <div>Неизвестный блок</div>
  );
}

export interface ContentBlocksProps {
  blocks?: IContentBlock[];
  className?: string;
  classes?: Partial<Record<ContentBlockVariant, string>>;
  meta?: ContentBlockMeta;
}

export function ContentBlocks({
  blocks,
  classes,
  className,
  meta
}: ContentBlocksProps) {
  return (
    <>
      {blocks?.map((b, k) => {
        return (
          <ContentBlock
            key={k}
            className={clsx(className, classes?.[b.variant])}
            meta={meta}
            block={b}
          />
        );
      })}
    </>
  );
}
