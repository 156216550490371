import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import {
  Button,
  ButtonBaseColor,
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import {
  Breadcrumbs,
  getBreadcrumbItemEntity,
  IBreadcrumbsEntity,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { getCoursesPageBreadcrumbs } from '../list/CoursesPage';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { routeLinks } from '../../routeLinks';
import { UserPermission } from '../../../utils/permissions';
import { useCourse } from '../../../graphql/hooks/courses/useCourse';
import { Courses } from '../../../store/courses/Courses';
import { CourseStatus, CourseTypesEnum } from '../../../graphql/types';
import { CourseUtils } from '../../../store/courses/CourseUtils';
import { CoursesPageTabs } from '../types';
import { useUpdateCourseStatus } from '../useUpdateCourseStatus';
import { Course } from './Course/Course';
import { CourseAnnounce } from './CourseAnnounce/CourseAnnounce';
import s from './CoursePage.module.scss';

export interface CoursePageProps
  extends RouteComponentProps<{ courseId: string }> {
  history: AppHistoryType;
}

export function getCoursePageBreadcrumbs({
  course,
  type = CoursesPageTabs.ALL
}: {
  course: IBreadcrumbsEntity;
  type?: CoursesPageTabs;
}): IBreadcrumbsItem[] {
  return [
    ...getCoursesPageBreadcrumbs({ type }),
    getBreadcrumbItemEntity(routeLinks.courses.to, course, 50)
  ];
}

export default function CoursePage({ match, history }: CoursePageProps) {
  const { courseId } = match.params;
  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.CourseCrud);

  const courseQuery = useCourse(courseId);
  const courseData = courseQuery.state.data;
  const course = useMemo(() => {
    return CourseUtils.toCourse(courseData);
  }, [courseData]);
  const statusArchived = course?.status !== CourseStatus.Published;
  const { updateWithValidation, updateStatusLoading } = useUpdateCourseStatus(
    courseId,
    course?.type
  );

  const deleteCourse = useGraphqlMutation(Courses.deleteCourse);
  const deleteCourseRun = deleteCourse.run;

  const handleRemoveClick = useCallback(() => {
    if (window.confirm('Вы действительно хотите удалить курс?')) {
      deleteCourseRun({ variables: { id: courseId } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Курс удален');
            history.push(routeLinks.courses.to);
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(err, 'Произошла ошибка при удалении курса');
        });
    }
  }, [courseId, deleteCourseRun, history]);

  const handleArchiveClick = useCallback(() => {
    if (courseData) {
      updateWithValidation(
        courseData,
        statusArchived ? CourseStatus.Published : CourseStatus.Archived
      );
    }
  }, [statusArchived, courseData, updateWithValidation]);

  const breadcrumbs = useMemo(
    () =>
      getCoursePageBreadcrumbs({
        course: {
          id: courseId,
          name: course?.title
        },
        type: course?.source
          ? CoursesPageTabs[course.source]
          : CoursesPageTabs.ALL
      }),
    [course?.source, course?.title, courseId]
  );

  return (
    <PageLayout
      breadcrumbs={<Breadcrumbs items={breadcrumbs} />}
      contentClassName={s.ArticlePage}
    >
      <LayoutContent>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={8}>
            {queryLoader(courseQuery) ||
              (!course ? (
                <NoData />
              ) : course.type === CourseTypesEnum.Announce ? (
                <CourseAnnounce isEmbedded id={courseId} />
              ) : (
                <Course {...course} />
              ))}
          </LayoutGrid.GridItem>

          <LayoutGrid.GridItem cols={4}>
            {canEdit && course && (
              <div className={s.ArticlePage__actions}>
                <ButtonLink
                  to={`${routeLinks.courses.to}/${courseId}/edit`}
                  className={clsx(s.ActionButton, s.ActionButton_edit)}
                  size={ButtonSize.small}
                  variant={ButtonVariant.secondary}
                >
                  Редактировать
                </ButtonLink>
                <Button
                  className={clsx(s.ActionButton, s.ActionButton_remove)}
                  size={ButtonSize.small}
                  variant={ButtonVariant.secondary}
                  baseColor={ButtonBaseColor.red}
                  onClick={handleRemoveClick}
                  loading={deleteCourse.loading}
                >
                  Удалить
                </Button>

                <Button
                  className={clsx(s.ActionButton, s.ActionButton_archive)}
                  size={ButtonSize.small}
                  variant={ButtonVariant.secondary}
                  baseColor={
                    statusArchived
                      ? ButtonBaseColor.green
                      : ButtonBaseColor.gray
                  }
                  onClick={handleArchiveClick}
                  loading={updateStatusLoading}
                >
                  {statusArchived ? 'Опубликовать' : 'Архивировать'}
                </Button>
              </div>
            )}
          </LayoutGrid.GridItem>
        </LayoutGrid>
      </LayoutContent>
    </PageLayout>
  );
}
