import { IUseGraphqlWatchQueryOptions } from '@proscom/prostore-apollo-react';
import { QUERY_GET_MANUALS } from '../queries/manuals';
import { ManualType } from '../types';
import { useAuthGraphqlQuery } from '../../common/hooks/utils/useAuthGraphqlQuery';

const manualsQueryOptions: IUseGraphqlWatchQueryOptions<{}, ManualType[]> = {
  query: QUERY_GET_MANUALS,
  mapData: (result) => result.manuals
};

export const useManuals = () => {
  return useAuthGraphqlQuery({
    queryOptions: manualsQueryOptions,
    variables: {}
  });
};
