import React from 'react';
import { Entity } from '../../AsyncSelect/entities/types';
import { FormAsyncEntitySelect } from '../FormAsyncEntitySelect/FormAsyncEntitySelect';
import { AsyncSelectSize } from '../../AsyncSelect/AsyncSelect';

export const PollSelector = (props) => {
  return (
    <FormAsyncEntitySelect
      isClearable={true}
      entity={Entity.pollsPage}
      label={'Опрос'}
      size={AsyncSelectSize.large}
      placeholder={'Выберите опрос'}
      {...props}
    />
  );
};
