import React, { useEffect, useMemo } from 'react';
import { EMDASH } from '@proscom/ui-utils';
import clsx from 'clsx';
import isFuture from 'date-fns/isFuture';
import {
  Title,
  TitleVariant
} from '../../../../common/components/ui/Title/Title';
import { useLocationQuery } from '../../../../common/hooks/utils/useLocationQuery';
import { URL_KEY_PAGE } from '../../../../store/urlKeys';
import { useVacationApplicationsPage } from '../../../../graphql/hooks/vacations/useVacationApplicationsPage';
import { queryLoader } from '../../../../common/components/utils/queryLoader';
import { NoData } from '../../../../common/components/ui/NoData/NoData';
import { QueryPagination } from '../../../../common/components/ui/QueryPagination/QueryPagination';
import { routeLinks } from '../../../routeLinks';
import {
  VacationApplicationType,
  VacationStatusesEnum
} from '../../../../graphql/types';
import { GridItem } from '../../../../common/components/Layout/Layout';
import { useFormatDate } from '../../../../common/hooks/useFormatDate';
import { DATETIME_FORMAT_SHORT } from '../../../../utils/date/date';
import { Tag } from '../../../../common/components/ui/Tag/Tag';
import { VacationsUtils } from '../../../../store/vacations/VacationsUtils';
import {
  useVacationDaysAmount
} from '../../../../common/hooks/useDayOffs';
import s from './VacationArchive.module.scss';

interface VacationApprovedInfoProps
  extends Pick<VacationApplicationType, 'reviewed_at' | 'start' | 'end'> {}

const VacationApprovedInfo: React.FC<VacationApprovedInfoProps> = ({
  reviewed_at,
  start,
  end
}) => {
  const reviewedAtDate = useFormatDate(reviewed_at, DATETIME_FORMAT_SHORT);
  const startDate = useFormatDate(start, DATETIME_FORMAT_SHORT);
  const endDate = useFormatDate(end, DATETIME_FORMAT_SHORT);

  const selectedDaysAmount = useVacationDaysAmount({
    startDate: start,
    endDate: end
  });

  return (
    <div className={s.VacationApprovedInfo}>
      {reviewedAtDate && (
        <div
          className={clsx(
            s.VacationApprovedInfo__col,
            s.VacationApprovedInfo__approveDate
          )}
        >
          <div className={s.VacationApprovedInfo__title}>
            Дата согласования заявления
          </div>
          <div className={s.VacationApprovedInfo__value}>
            {reviewedAtDate || EMDASH}
          </div>
        </div>
      )}
      <div
        className={clsx(
          s.VacationApprovedInfo__col,
          s.VacationApprovedInfo__startDate
        )}
      >
        <div className={s.VacationApprovedInfo__title}>Дата начала отпуска</div>
        <div className={s.VacationApprovedInfo__value}>
          {startDate || EMDASH}
        </div>
      </div>
      <div
        className={clsx(
          s.VacationApprovedInfo__col,
          s.VacationApprovedInfo__days
        )}
      >
        <div className={s.VacationApprovedInfo__title}>
          Количество дней отпуска
        </div>
        <div className={s.VacationApprovedInfo__value}>
          {selectedDaysAmount || EMDASH}
        </div>
      </div>
      <div
        className={clsx(
          s.VacationApprovedInfo__col,
          s.VacationApprovedInfo__endDate
        )}
      >
        <div className={s.VacationApprovedInfo__title}>
          Дата окончания отпуска
        </div>
        <div className={s.VacationApprovedInfo__value}>{endDate || EMDASH}</div>
      </div>
    </div>
  );
};

interface VacationArchiveRowProps extends VacationApplicationType {}

const VacationArchiveRow: React.FC<VacationArchiveRowProps> = ({
  created_at,
  reviewed_at,
  status,
  start,
  end,
  review_comment,
  position
}) => {
  const createdAtDate = useFormatDate(created_at, DATETIME_FORMAT_SHORT);

  const vacAppStatus = VacationsUtils.VacationAppStatus[status];
  const vacAppStatusTitle = VacationsUtils.VacationAppStatusTitle[status];

  const isSentForApproval = status === VacationStatusesEnum.SentForApproval;
  const isRejected = status === VacationStatusesEnum.Rejected;
  const isApproved = status === VacationStatusesEnum.Approved;
  const isComplete = status === VacationStatusesEnum.Complete;

  return (
    <div className={s.VacationArchiveRow}>
      <div className={s.VacationArchiveRow__grid}>
        <GridItem className={s.VacationArchiveRow__gridCol} cols={4}>
          <div className={s.VacationArchiveRow__createdAtDate}>
            Заявление на отпуск от {createdAtDate || EMDASH}
          </div>
        </GridItem>

        <GridItem className={s.VacationArchiveRow__gridCol} cols={3}>
          <div className={s.VacationArchiveRow__position}>
            {position.c_position_name || EMDASH}
          </div>
        </GridItem>

        <GridItem className={s.VacationArchiveRow__gridCol} cols={5}>
          <div className={s.VacationArchiveRow__status}>
            <Tag
              className={clsx(
                s.VacationArchiveRowStatus,
                s[`VacationArchiveRowStatus_${vacAppStatus}`]
              )}
            >
              {vacAppStatusTitle || EMDASH}
            </Tag>
          </div>
          {isRejected && review_comment && (
            <div className={s.VacationArchiveRow__comment}>
              {review_comment}
            </div>
          )}

          {(isSentForApproval || isApproved || isComplete) && (
            <div className={s.VacationArchiveRow__info}>
              <VacationApprovedInfo
                reviewed_at={reviewed_at}
                start={start}
                end={end}
              />
            </div>
          )}
        </GridItem>
      </div>
    </div>
  );
};

export interface VacationArchiveProps {
  onVacationAppsChange: (data: VacationApplicationType[]) => void;
}

export const VacationArchive: React.FC<VacationArchiveProps> = ({
  onVacationAppsChange
}) => {
  const query = useLocationQuery([URL_KEY_PAGE] as const);
  const page = query[URL_KEY_PAGE] || 0;

  const vacationAppsPageQuery = useVacationApplicationsPage({
    pagination: { page }
  });
  const vacationApps = vacationAppsPageQuery.state.data?.list;
  const header = vacationAppsPageQuery.state.data?.header;
  const hasVacationApps = !!vacationApps && vacationApps.length > 0;

  const futureVacationApps = useMemo(() => {
    if (!vacationApps || !vacationApps.length) return [];
    return vacationApps.filter((app) => {
      return isFuture(new Date(app.start)) || isFuture(new Date(app.end));
    });
  }, [vacationApps]);

  useEffect(() => {
    onVacationAppsChange(futureVacationApps ?? []);
  }, [futureVacationApps, onVacationAppsChange]);

  return (
    <div className={s.VacationArchive}>
      <Title
        className={s.VacationArchive__title}
        variant={TitleVariant.h3}
        title={'Архив заявлений'}
      />

      <div className={s.VacationArchive__list}>
        {queryLoader(vacationAppsPageQuery) ||
          (!hasVacationApps && <NoData />) ||
          vacationApps?.map((item, iItem) => (
            <div className={s.VacationArchive__item} key={iItem}>
              <VacationArchiveRow {...item} />
            </div>
          ))}
      </div>

      {hasVacationApps && header && (
        <QueryPagination header={header} pathname={routeLinks.vacation.to} />
      )}
    </div>
  );
};
