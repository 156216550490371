import React from 'react';
import { ReactComponent as IconVk } from '../../../../../assets/img/icons/socials/vk.svg';
import { UserType } from '../../../../../graphql/types';
import { Person } from '../../Person/Person';
import { AvatarSize } from '../../Avatar/Avatar';
import { BaseLink } from '../../BaseLink/BaseLink';
import { Icon } from '../../Icon/Icon';
import { useFileSrc } from '../../../../hooks/useFileSrc';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { UserUtils } from '../../../../../store/users/UserUtils';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import s from './UserPerson.module.scss';

export interface UserPersonProps {
  user: UserType | null;
}

export const UserPerson = React.memo(function UserPerson({
  user
}: UserPersonProps) {
  const avatarSrc = useFileSrc(UserUtils.getAvatar(user), FileTypeEnum.avatar);
  const userName = UserUtils.getName(user);
  const userProfession = UserUtils.getPositionsList(user);
  const { isMobile } = useWindowSize();
  return (
    <Person
      classes={{
        avatar: s.UserPerson__avatar,
        name: s.UserPerson__name,
        description: s.UserPerson__description
      }}
      name={userName}
      description={userProfession}
      avatarSrc={avatarSrc ?? undefined}
      size={isMobile ? AvatarSize.large : AvatarSize.extraLarge}
    >
      <div className={s.UserPerson__socials}>
        {user?.vk && (
          <BaseLink href={user?.vk} className={s.UserPerson__socialsLink}>
            <Icon icon={IconVk} iconSize={20} />
          </BaseLink>
        )}
        {/*{user?.instagram && (*/}
        {/*  <BaseLink*/}
        {/*    href={user?.instagram}*/}
        {/*    className={s.UserPerson__socialsLink}*/}
        {/*  >*/}
        {/*    <Icon icon={IconInst} iconSize={20} />*/}
        {/*  </BaseLink>*/}
        {/*)}*/}
        {/*{user?.facebook && (*/}
        {/*  <BaseLink href={user?.facebook} className={s.UserPerson__socialsLink}>*/}
        {/*    <Icon icon={IconFb} iconSize={20} />*/}
        {/*  </BaseLink>*/}
        {/*)}*/}
      </div>
    </Person>
  );
});
