import gql from 'graphql-tag';
import { FRAGMENT_ARTICLE_FULL } from '../fragments/articles';

export const MUTATION_CREATE_ARTICLE = gql`
  mutation mutationCreateArticle($input: ArticleCreateInput!) {
    result: createArticle(input: $input) {
      ...ArticleFull
    }
  }

  ${FRAGMENT_ARTICLE_FULL}
`;

export const MUTATION_UPDATE_ARTICLE = gql`
  mutation mutationUpdateArticle($input: ArticleUpdateInput!) {
    result: updateArticle(input: $input) {
      ...ArticleFull
    }
  }

  ${FRAGMENT_ARTICLE_FULL}
`;

export const MUTATION_DELETE_ARTICLE = gql`
  mutation mutationDeleteDocument($id: ID!) {
    result: deleteArticle(article_id: $id)
  }
`;
