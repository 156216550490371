import React from 'react';
import { Link } from 'react-router-dom';
import { GenericComponent } from '../../utils/GenericComponent';
import { BaseButtonBaseProps } from '../Button';
import { HtmlAnchor, HtmlButton } from '../../html';
import { BaseIconButton, BaseIconButtonProps } from './BaseIconButton';

const IconButtonGeneric = GenericComponent<
  BaseButtonBaseProps,
  BaseIconButtonProps
>(BaseIconButton);

export const IconButton = IconButtonGeneric(HtmlButton);
export type IconButtonProps = React.ComponentProps<typeof IconButton>;

export const IconButtonAnchor = IconButtonGeneric(HtmlAnchor);
export type IconButtonAnchorProps = React.ComponentProps<
  typeof IconButtonAnchor
>;

export const IconButtonLink = IconButtonGeneric(Link);
export type IconButtonLinkProps = React.ComponentProps<typeof IconButtonLink>;
