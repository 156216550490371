import React, { useState } from 'react';
import {
  Title,
  TitleVariant
} from '../../../../common/components/ui/Title/Title';
import { ReactComponent as IconDoc } from '../../../../assets/img/icons/Document.svg';
import { ReactComponent as IconInfo } from '../../../../assets/img/icons/Info.svg';
import {
  TextLink,
  TextLinkIconPosition
} from '../../../../common/components/ui/TextLink/TextLink';
import {
  HelpText,
  HelpTextType
} from '../../../../common/components/ui/HelpText/HelpText';
import { Button } from '../../../../common/components/ui/Button';
import { VacationSchedulesModal } from '../VacationSchedulesModal/VacationSchedulesModal';
import { NoData } from '../../../../common/components/ui/NoData/NoData';
import { VacationScheduleType } from '../../../../graphql/types';
import { daysBeforeVacationGap } from '../../constants';
import { VacationScheduleCard } from './VacationScheduleCard';
import s from './VacationSchedules.module.scss';

const manualDocLink = `${process.env.PUBLIC_URL}/manuals/Инструкция по планированию отпуска.pdf`;

export interface VacationSchedulesProps {
  vacationsSchedules: VacationScheduleType[] | null;
}

export const VacationSchedules: React.FC<VacationSchedulesProps> = ({
  vacationsSchedules
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={s.VacationSchedules}>
      <Title className={s.VacationSchedules__title} variant={TitleVariant.h3}>
        Информация об утвержденных датах отпусков в&nbsp;соответствии
        с&nbsp;согласованным графиком отпусков
      </Title>

      <HelpText
        className={s.VacationSchedules__hint}
        type={HelpTextType.hint}
        icon={IconInfo}
      >
        Вы можете подать заявление с помощью этой формы не позднее, чем за 
        {daysBeforeVacationGap} дней до даты начала отпуска. Если до начала
        планируемого отпуска осталось менее {daysBeforeVacationGap} дней,
        вам необходимо лично обратиться в отдел кадров с согласованным
        руководителем заявлением.
        <br />
        Как минимум одна часть вашего отпуска должна быть не менее
        14 календарных дней.
        <br />
        <b>
          При внесении изменений в&nbsp;график отпусков необходимо распределять
          все&nbsp;дни периода отпуска, в&nbsp;который вносятся изменения.
        </b>
      </HelpText>

      <div className={s.VacationSchedules__content}>
        {!vacationsSchedules?.length ? (
          <NoData />
        ) : (
          <>
            <div className={s.VacationSchedules__list}>
              {vacationsSchedules.map((item, iItem) => (
                <div key={iItem} className={s.VacationSchedules__item}>
                  <VacationScheduleCard {...item} />
                </div>
              ))}
            </div>

            <div className={s.VacationSchedules__actions}>
              <Button onClick={() => setModalOpen(true)}>
                Внести изменения в график отпусков
              </Button>

              <TextLink
                className={s.VacationSchedules__extLink}
                href={manualDocLink}
                icon={IconDoc}
                iconPosition={TextLinkIconPosition.left}
                iconProps={{
                  iconSize: 16
                }}
              >
                Инструкция по планированию отпуска
              </TextLink>
            </div>

            <VacationSchedulesModal
              vacationsSchedules={vacationsSchedules}
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
            />
          </>
        )}
      </div>
    </div>
  );
};
