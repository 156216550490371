import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { Formik } from 'formik';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import {
  Breadcrumbs,
  IBreadcrumbsEntity
} from '../../../common/components/ui/Breadcrumbs';
import { CourseForm } from '../CourseForm/CourseForm';
import { useCourse } from '../../../graphql/hooks/courses/useCourse';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { Courses } from '../../../store/courses/Courses';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { CourseUtils } from '../../../store/courses/CourseUtils';
import { routeLinks } from '../../routeLinks';
import { getCoursePageBreadcrumbs } from '../index/CoursePage';
import {
  CourseAnnounceUpdateInput,
  CourseLongreadUpdateInput,
  CourseStatus,
  CourseTypesEnum
} from '../../../graphql/types';
import { CourseTypeForm } from '../types';
import { queryLoaderWithExistenceCheck } from '../../../common/components/utils/queryLoader';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import {
  CoursesDraftValidationSchema,
  CoursesPublicValidationSchema,
  lazyValidation
} from '../../../store/validationSchema';
import s from './CourseEditPage.module.scss';

export interface CourseEditPageProps
  extends RouteComponentProps<{ courseId: string }> {
  history: AppHistoryType;
}

export function getCourseEditPageBreadcrumbs({
  course
}: {
  course: IBreadcrumbsEntity;
}) {
  return [
    ...getCoursePageBreadcrumbs({ course }),
    {
      name: 'Редактирование',
      path: `${routeLinks.courses.to}/${course.id}/edit`
    }
  ];
}

export default function CourseEditPage({
  match,
  history
}: CourseEditPageProps) {
  const { courseId } = match.params;
  const courseQuery = useCourse(courseId);
  const courseData = courseQuery.state.data;
  const updateCourse = useGraphqlMutation(
    courseData?.type === CourseTypesEnum.Longread
      ? Courses.updateLongreadCourse
      : Courses.updateAnnounceCourse
  );

  const updateCourseRun = updateCourse.run;

  const course = useMemo(() => {
    return CourseUtils.toEditForm(courseData);
  }, [courseData]);

  const breadcrumbs = useMemo(
    () =>
      getCourseEditPageBreadcrumbs({
        course: { id: courseId, name: course?.name }
      }),
    [courseId, course]
  );

  const handleSubmit = useCallback(
    (data: CourseTypeForm) => {
      let input: CourseAnnounceUpdateInput | CourseLongreadUpdateInput;
      if (data.type === CourseTypesEnum.Announce) {
        input = CourseUtils.fromAnnounceUpdateForm(data);
      } else {
        input = CourseUtils.fromLongreadUpdateForm(data);
      }

      updateCourseRun({ variables: { input } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Курс обновлен');
            history.push(`${routeLinks.courses.to}/${result.data.result.id}`);
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при изменении курса. Попробуйте снова'
          );
        });
    },
    [updateCourseRun, history]
  );

  const handleCancel = useCallback(() => {
    if (window.confirm('Вы действительно хотите отменить редактирование?')) {
      history.goBack(`${routeLinks.courses.to}/${courseId}`);
    }
  }, [courseId, history]);

  return (
    <PageLayout
      breadcrumbs={<Breadcrumbs items={breadcrumbs} />}
      contentClassName={s.CourseEditPage}
    >
      <LayoutContent>
        <div className={s.CourseEditPage__content}>
          <PageTitle
            className={s.CourseEditPage__pageTitle}
            title={'Редактирование курса'}
            showHeading
          />
          {queryLoaderWithExistenceCheck(courseQuery, () => <NoData />) || (
            <Formik
              initialValues={course}
              validationSchema={lazyValidation((values) => {
                const isDraft = values?.status === CourseStatus.Draft;
                return isDraft
                  ? CoursesDraftValidationSchema
                  : CoursesPublicValidationSchema;
              })}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <CourseForm
                  submissionLoading={updateCourse.loading}
                  isEdit={true}
                  onCancel={handleCancel}
                  {...props}
                />
              )}
            </Formik>
          )}
        </div>
      </LayoutContent>
    </PageLayout>
  );
}
