import React from 'react';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { useFileSrc } from '../../../hooks/useFileSrc';

export interface ImageProps {
  type: FileTypeEnum;
  id?: string;
  className?: string;
}

export const Image: React.FC<ImageProps> = ({ id, type, className }) => {
  const imgSrc = useFileSrc(id, type);
  return <img src={imgSrc ?? undefined} className={className} alt="" />;
};
