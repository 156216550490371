import { isTruthy, joinNonEmpty } from '@proscom/ui-utils';
import { pick, upperFirst } from 'lodash-es';
import { OptionTypeBase } from 'react-select/src/types';
import {
  AreaOfInterestCreateInput,
  AreaOfInterestType,
  PermissionInput,
  PermissionType,
  PositionType,
  PublicationInput,
  PublicationType,
  SkillInput,
  RoleType,
  SkillType,
  UserType,
  UserUpdateInput,
  WorkExperienceInput,
  WorkExperienceType
} from '../../graphql/types';
import {
  dateToYear,
  yearToDate
} from '../../common/components/ui/DatePicker/datePickerUtils';
import { UnitUtils } from '../units/UnitUtils';
import { nullifyFalsy } from '../../utils/ifNotUndefined';

export interface UserProps {
  id: string;
  avatar_file_id?: string | null;

  vk?: string;
  instagram?: string;
  facebook?: string;

  campus_id?: string | null;
  mobile_phone?: string | null;
  work_phone?: string | null;

  publications?: (PublicationType | PublicationInput)[];
  work_experiences?: (WorkExperienceType | WorkExperienceInput)[];
  skills?: (OptionTypeBase & (SkillType | SkillInput))[];
  areas_of_interest?: (OptionTypeBase &
    (AreaOfInterestType | AreaOfInterestCreateInput))[];
  about?: string;
  roles?: (OptionTypeBase & RoleType)[];
  permissions?: PermissionType[];
}

const workExperienceFields = [
  'duties',
  'place',
  'position',
  'year_end',
  'year_start'
] as const;

const publicationFields = ['date', 'link', 'name'] as const;

const isValidWorkExperience = (work_exp: Partial<WorkExperienceType>) => {
  return !!(
    work_exp.duties ||
    work_exp.place ||
    work_exp.position ||
    work_exp.year_start ||
    work_exp.year_end
  );
};

const isValidPublication = (pub: Partial<PublicationType>) => {
  return !!(pub.name || pub.link || pub.date);
};

export class UserUtils {
  static getName(user?: UserType | null): string {
    if (!user) return '';
    return (
      joinNonEmpty([user.surname, user.name, user.patronymic], ' ') ||
      user.email ||
      ''
    );
  }

  static getPositionsList(user?: UserType | null): string {
    if (!user) return '';
    if (!user.positions) return '';
    return joinNonEmpty(
      user.positions.map((p) => {
        const unitName = UserUtils.getPositionUnit(p);
        const wUnitName = unitName && `(${unitName})`;
        return joinNonEmpty([UserUtils.getPositionName(p), wUnitName], ' ');
      }),
      ', '
    );
  }

  static getPositionName(position?: PositionType | null): string {
    return position && position.c_position_name
      ? upperFirst(position.c_position_name)
      : '';
  }

  static getPositionUnit(position?: PositionType | null): string {
    return (position && UnitUtils.getName(position.unit)) || '';
  }

  static getAvatar(user?: UserType | null): string | null {
    return user?.avatar_file_id || user?.c_avatar_file_id || null;
  }

  static toForm(data?: UserType): UserProps {
    const publications = data?.publications?.map(
      ({ __typename, ...props }) => props
    );

    const work_experiences = data?.work_experiences?.map(
      ({ __typename, ...props }) => ({
        ...props,
        year_start: yearToDate(props.year_start ?? undefined),
        year_end: yearToDate(props.year_end ?? undefined)
      })
    );

    const areas_of_interest = data?.areas_of_interest?.map((s) => ({
      ...s,
      value: s.id,
      label: s.name
    }));

    const skills = data?.skills?.map((s) => ({
      ...s,
      value: s.id,
      label: s.name
    }));
    const roles =
      data?.roles?.map((r) => ({
        ...r,
        value: r.id,
        label: r.name
      })) || [];

    return {
      id: data?.id || '',
      // Общая информация
      avatar_file_id: data?.avatar_file_id || null,

      campus_id: data?.campus?.id,

      mobile_phone: data?.mobile_phone,
      work_phone: data?.work_phone,

      vk: data?.vk || '',
      instagram: data?.instagram || '',
      facebook: data?.facebook || '',

      // Публикации и исследования
      publications: publications || [],

      // Профессиональные достижения
      work_experiences: work_experiences || [],
      skills: skills || [],
      areas_of_interest: areas_of_interest || [],
      about: data?.about || '',
      roles,
      permissions: data?.permissions || []
    };
  }

  static fromUpdateForm(
    formData: UserProps,
    canEditRoles?: boolean
  ): UserUpdateInput {
    const work_experiences = formData.work_experiences
      ?.filter(isValidWorkExperience)
      .map((we) => {
        const obj = {
          ...we,
          year_start: dateToYear(we.year_start),
          year_end: dateToYear(we.year_end)
        };
        return pick(obj, ...workExperienceFields);
      });

    const publications = formData.publications
      ?.filter(isValidPublication)
      .map((p) => pick(p, ...publicationFields));

    const skills = formData.skills
      ?.map((s) => {
        if (s.value || s.id) {
          return { id: s.value || s.id, name: s.label || s.name };
        } else if (s.label) {
          return { name: s.label };
        }
        return null;
      })
      .filter(isTruthy);

    const areasOfInterest = formData.areas_of_interest
      ?.map((s) => {
        if (s.value || s.id) {
          return { id: s.value || s.id };
        } else if (s.label) {
          return { name: s.label };
        }
        return null;
      })
      .filter(isTruthy);

    const role_ids = formData.roles?.map((x) => x.value).filter(Boolean) || [];

    const permissions =
      formData.permissions?.map<PermissionInput>(
        ({ base_permission_code, object_id, object_type }) => ({
          base_permission_code,
          object_id: object_id ? +object_id : null,
          object_type: object_id ? 'OBJECT' : object_type || 'GLOBAL'
        })
      ) || [];

    return {
      id: formData.id,

      avatar_file_id: formData.avatar_file_id,

      campus_id: formData.campus_id,
      mobile_phone: formData.mobile_phone,
      work_phone: formData.work_phone,

      vk: nullifyFalsy(formData.vk),
      instagram: nullifyFalsy(formData.instagram),
      facebook: nullifyFalsy(formData.facebook),

      work_experiences,
      publications,
      skills,
      areas_of_interest: areasOfInterest,
      about: formData.about,
      role_ids: canEditRoles ? role_ids : undefined,
      permissions: canEditRoles ? permissions : undefined
    };
  }
}
