import React, { useCallback } from 'react';
import { SpecificContentBlockEditableProps } from '../types';
import { IContentImage } from '../../Form/FormContentEditor/Content';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { FileUploadButton } from '../../UploadField/FileUploadButton';
import { TextArea } from '../../TextField/TextArea';
import { ImageBlockPreview } from './ImageBlockPreview';
import s from '../ContentBlock.module.scss';

export const ImageBlockEditable: React.FC<
  SpecificContentBlockEditableProps<IContentImage>
> = ({ data, onChange, className, meta }) => {
  const { fileId, imageCaption } = data;

  const handleUpload = useCallback(
    (fileId?: string) => {
      onChange({
        ...data,
        fileId
      });
    },
    [onChange, data]
  );

  const handleCaptionChange = useCallback(
    (text?: string) => {
      onChange({
        ...data,
        imageCaption: text || undefined
      });
    },
    [onChange, data]
  );

  return (
    <div className={s.ImageBlock}>
      <ImageBlockPreview
        fileId={fileId}
        isEditable={!!fileId}
        fileType={meta?.fileType || FileTypeEnum.article_image}
        onRemoveClick={handleUpload}
        placeholderProps={{
          iconProps: {
            boxSize: 64
          }
        }}
      />
      {!fileId && (
        <FileUploadButton
          className={s.ImageBlock__uploadButton}
          buttonText={'Загрузите изображение'}
          fileType={meta?.fileType || FileTypeEnum.article_image}
          onFileUploaded={handleUpload}
        />
      )}
      <TextArea
        className={s.ImageBlock__imageCaptionEditor}
        placeholder={'Описание изображения'}
        value={imageCaption || ''}
        rows={2}
        onChange={handleCaptionChange}
      />
    </div>
  );
};
