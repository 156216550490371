import { IUseGraphqlQueryOptions } from '@proscom/prostore-apollo-react';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import { ONE_PAGE_ITEMS } from '../../../constants';
import {
  OfferKindsFilter,
  OfferKindsPageInput,
  OfferKindsPageType,
  PaginationInput
} from '../../types';
import { QUERY_GET_OFFER_KINDS_PAGE } from '../../queries/offers';

export interface UseOfferKindsPageVars {
  input: OfferKindsPageInput;
}

export interface UseOfferKindsPageArgs {
  filter?: OfferKindsFilter;
  pagination?: PaginationInput;
}

const queryOptions: IUseGraphqlQueryOptions<
  UseOfferKindsPageVars,
  OfferKindsPageType
> = {
  query: QUERY_GET_OFFER_KINDS_PAGE,
  mapData: (result) => result.offerKindsPage
};

export const useOfferKindsPage = ({
  filter,
  pagination
}: UseOfferKindsPageArgs) => {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: {
      input: {
        filter,
        pagination: {
          onePage: ONE_PAGE_ITEMS,
          ...pagination
        }
      }
    }
  });
};
