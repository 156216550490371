import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import ApolloClient, { MutationOptions } from 'apollo-client';
import {
  useAsyncOperation,
  UseAsyncOperationOptions
} from '@proscom/prostore-react';

export function useGraphqlMutation<Result = any, Vars = any>(
  options?: MutationOptions<Result, Vars>,
  clientOrName?: string | ApolloClient<any>,
  operationOptions: UseAsyncOperationOptions = {}
) {
  const client = useContextApolloClient(clientOrName || null);
  return useAsyncOperation(
    (mutationObject: Partial<MutationOptions<Result, Vars>>) => {
      return client.mutate<Result, Vars>({
        ...options,
        ...mutationObject
      } as any);
    },
    {
      singleton: true,
      ...operationOptions
    }
  );
}
