import React, { useMemo } from 'react';
import { EMDASH, joinNonEmpty } from '@proscom/ui-utils';
import { groupBy, orderBy } from 'lodash-es';
import { Card, CardStyle } from '../../Card/Card';
import { LayoutGrid } from '../../../Layout/Layout';
import { EducationType } from '../../../../../graphql/types';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import {
  UserPageBlock,
  UserPageBlockVariant
} from '../UserPageBlock/UserPageBlock';
import s from './UserEducations.module.scss';

const EduCard: React.FC<EducationType> = ({
  c_educational_institution,
  c_specialization,
  c_name_training,
  c_date_end
}) => {
  const year_end = useFormatDate(c_date_end, 'yyyy');

  const titleStr = useMemo(() => {
    return joinNonEmpty(
      [c_educational_institution, c_name_training, c_specialization],
      ', '
    );
  }, [c_educational_institution, c_name_training, c_specialization]);

  return (
    <Card
      className={s.EduCard}
      classes={{
        inner: s.EduCard__inner
      }}
      cardStyle={CardStyle.bordered}
    >
      <div className={s.EduCard__title}>{titleStr}</div>
      <div className={s.EduCard__info}>Год окончания: {year_end || EMDASH}</div>
    </Card>
  );
};

export interface UserEducationsProps {
  educations?: EducationType[] | null;
}

type EduGroupType = {
  title?: string;
  items: EducationType[];
};

export const UserEducations: React.FC<UserEducationsProps> = ({
  educations
}) => {
  const eduGroups: EduGroupType[] = useMemo(() => {
    if (!educations || !educations.length) return [];
    const existing = educations.filter((item) => !!item.c_kind_of_education);
    const nonExisting = educations.filter((item) => !item.c_kind_of_education);
    const existingSorted = orderBy(
      existing,
      ['c_kind_of_education', 'c_date_end'],
      ['asc', 'desc']
    );
    const nonExistingSorted = orderBy(nonExisting, ['c_date_end'], ['desc']);
    const grouped = groupBy(existingSorted, (edu) => edu.c_kind_of_education);
    const groups: EduGroupType[] = Object.entries(grouped).map(
      ([key, val]) => ({
        title: key,
        items: val
      })
    );

    if (nonExistingSorted.length) {
      groups.unshift({
        items: nonExistingSorted
      });
    }

    return groups;
  }, [educations]);

  return (
    <div className={s.UserEducations}>
      {eduGroups.map((group, iGroup) => (
        <UserPageBlock
          key={iGroup}
          title={group.title}
          variant={UserPageBlockVariant.small}
        >
          <LayoutGrid>
            {group.items.map((item, iItem) => (
              <LayoutGrid.GridItem key={iItem} cols={6}>
                <EduCard {...item} />
              </LayoutGrid.GridItem>
            ))}
          </LayoutGrid>
        </UserPageBlock>
      ))}
    </div>
  );
};
