import { addDays, addYears, endOfYear, isAfter, startOfToday } from 'date-fns';

export const daysBeforeVacationGap = 9;

/**
 * 15 декабря текущего года
 */
export const dateNextYearAvailableAfter = new Date(
  `${new Date().getFullYear()}-12-15`
);

const today = startOfToday();
export const endOfYearDate = endOfYear(today);
export const endOfNextYearDate = endOfYear(addYears(today, 1));
export const nextYearAvailable = isAfter(
  new Date(),
  dateNextYearAvailableAfter
);
export const vacationMinStartDate = addDays(today, daysBeforeVacationGap);
export const vacationMaxStartDate = nextYearAvailable
  ? endOfNextYearDate
  : endOfYearDate;
