import React, { useMemo, useState } from 'react';
import { FieldArray, FormikErrors, useField, useFormikContext } from 'formik';
import { EMDASH, joinNonEmpty } from '@proscom/ui-utils';
import { differenceInCalendarDays, isValid } from 'date-fns';
import {
  HelpText,
  HelpTextType
} from '../../../../../common/components/ui/HelpText/HelpText';
import {
  Title,
  TitleVariant
} from '../../../../../common/components/ui/Title/Title';
import { useFormatDate } from '../../../../../common/hooks/useFormatDate';
import { DATETIME_FORMAT_SHORT } from '../../../../../utils/date/date';
import { ReactComponent as IconInfo } from '../../../../../assets/img/icons/Info.svg';
import {
  Button,
  ButtonBaseColor,
  ButtonSize,
  ButtonVariant
} from '../../../../../common/components/ui/Button';
import { VacationPeriod } from '../VacationPeriod/VacationPeriod';
import { TextFieldPreview } from '../../../../../common/components/ui/TextFieldPreview/TextFieldPreview';
import { PositionType } from '../../../../../graphql/types';
import { VacationSchedulesFormValuesProps } from '../../../../../store/vacations/VacationsUtils';
import s from './VacationBlock.module.scss';

const today = new Date();

export interface VacationBlockProps {
  index: number;
  position?: PositionType;
  daysAvailable: number;
  isNewBlock: boolean;
  isRemovable: boolean;
  errors: FormikErrors<VacationSchedulesFormValuesProps>;
  onRemoveBlock: (index: number) => void;
}

export const VacationBlock = ({
  index,
  position,
  daysAvailable,
  isNewBlock,
  isRemovable,
  errors,
  onRemoveBlock
}: VacationBlockProps) => {
  const { initialValues } =
    useFormikContext<VacationSchedulesFormValuesProps>();

  const fieldName = `vacations[${index}]`;
  const [{ value: vacationValues }] = useField(fieldName);
  const canAddPeriod = !!(daysAvailable && !errors.vacations?.[index]);

  const initPeriodsIds = (
    initialValues.vacations.find((v) => v.id === vacationValues.id)?.periods ||
    []
  ).map((p) => p.id);
  const periods = vacationValues.periods;
  const lastPeriodId = periods[periods.length - 1]?.id || 0;
  const hasFilledPeriods = periods.some((p) => !!p.days);

  const [onEdit, setOnEdit] = useState(isNewBlock || !hasFilledPeriods);

  const firstPeriod = vacationValues.periods[0];
  const lastPeriod = vacationValues.periods[vacationValues.periods.length - 1];
  const startDate = useFormatDate(firstPeriod.start, DATETIME_FORMAT_SHORT);
  const endDate = useFormatDate(lastPeriod.end, DATETIME_FORMAT_SHORT);

  const selectedDaysAmount = useMemo(() => {
    return periods.reduce((summ, curr) => summ + (curr.days || 0), 0);
  }, [periods]);

  /**
   * Нельзя редактировать график отпуска, если до его начала менее 7 дней
   */
  const canEdit = useMemo(() => {
    if (!firstPeriod.start || !isValid(new Date(firstPeriod.start))) {
      return true;
    }
    return differenceInCalendarDays(new Date(firstPeriod.start), today) >= 7;
  }, [firstPeriod.start]);

  return (
    <div className={s.VacationBlock}>
      <HelpText
        className={s.VacationBlock__hint}
        type={HelpTextType.hint}
        icon={IconInfo}
      >
        Вы можете спланировать новые даты отпуска только в пределах текущего
        календарного года
      </HelpText>

      <div className={s.VacationBlock__title}>
        <Title variant={TitleVariant.h3}>
          {isNewBlock || !hasFilledPeriods ? 'Планируемый' : 'Утвержденный'}{' '}
          отпуск № {index + 1}
        </Title>

        {onEdit && isRemovable ? (
          <Button
            variant={ButtonVariant.secondary}
            baseColor={ButtonBaseColor.red}
            size={ButtonSize.small}
            onClick={() => onRemoveBlock(index)}
          >
            Удалить
          </Button>
        ) : (
          <div className={s.VacationBlock__dates}>
            {startDate && endDate
              ? joinNonEmpty([startDate, endDate], ' - ')
              : EMDASH}
          </div>
        )}
      </div>

      {!onEdit && (
        <TextFieldPreview
          className={s.VacationBlock__daysAmount}
          title={'Количество календарных дней отпуска:'}
          simpleView
        >
          {selectedDaysAmount}
        </TextFieldPreview>
      )}

      {onEdit ? (
        <div className={s.VacationBlock__periods}>
          <FieldArray name={`${fieldName}.periods`}>
            {({ push, remove }) => (
              <>
                {periods.map((item, iItem) => (
                  <div key={item.id} className={s.VacationBlock__period}>
                    <VacationPeriod
                      index={iItem}
                      position={position}
                      blockName={fieldName}
                      isNewPeriod={!initPeriodsIds.includes(item.id)}
                      isRemovable={periods.length > 1}
                      onRemovePeriod={remove}
                      {...item}
                    />
                  </div>
                ))}

                {!isNewBlock && (
                  <Button
                    className={s.VacationBlock__addPeriodButton}
                    variant={ButtonVariant.tertiary}
                    size={ButtonSize.small}
                    disabled={!canAddPeriod}
                    onClick={() =>
                      push({ id: lastPeriodId + 1, start: '', end: '' })
                    }
                  >
                    Добавить новый период отпуска
                  </Button>
                )}
              </>
            )}
          </FieldArray>
        </div>
      ) : (
        <div className={s.VacationBlock__actions}>
          <Button
            disabled={!canEdit || !position || position.vacations_disabled}
            onClick={() => setOnEdit(true)}
          >
            Изменить
          </Button>
          {isRemovable && (
            <Button
              variant={ButtonVariant.secondary}
              baseColor={ButtonBaseColor.red}
              onClick={() => onRemoveBlock(index)}
            >
              Удалить
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
