import { RolesEnum, UserType } from '../graphql/types';

export enum UserPermission {
  UserCrud = 'user.crud',
  UserEditAbout = 'user.edit.about',
  UserEditPhone = 'user.edit.phone',
  UserEditMobilePhone = 'user.edit.mobile_phone',
  UserEditWorkPhone = 'user.edit.work_phone',
  UserEditCampus = 'user.edit.campus',
  UserEditPublications = 'user.edit.publications',
  UserEditPermissions = 'user.edit.permissions',
  UserEditRoles = 'user.edit.roles',
  UserEditWorkExperiences = 'user.edit.work_experiences',
  UserEditSkillIds = 'user.edit.skills',
  UserEditAreasOfInterestIds = 'user.edit.areas_of_interest',
  ManualCrud = 'manual.crud',
  DocumentCrud = 'document.crud',
  ArticleCrud = 'article.crud',
  NoticeCrudOwn = 'notice.crud_own',
  NoticeCrud = 'notice.crud',
  EventCrud = 'event.crud',
  SystemLinkCrud = 'system_link.crud',
  ProjectsCrud = 'projects.crud',
  ProjectCrud = 'project.crud',
  ProjectCrudHead = 'project.crud_head',
  OfferCrud = 'offer.crud',
  ExternalPollCrud = 'external_poll.crud',
  PollAccess = 'poll.access',
  PollViewAttempts = 'poll.view_attempts',
  CampusCrud = 'campus.crud',
  InternshipCrud = 'internship.crud',
  CourseCrud = 'course.crud',
  PersonnelReserveCrud = 'personnel_reserve.crud',
  AssessmentProactionCrud = 'assessment.administer',
  FeedbackViewCrud = 'feedback.view'
}

export const isAdmin = (user: UserType): boolean => {
  if (!user.roles) {
    return false;
  }
  return user.roles?.some((role) => role.code === RolesEnum.Admin);
};

export const getUserPermissions = (user: UserType) => {
  if (!user) {
    return [];
  }

  const permissions: Set<UserPermission> = new Set();

  if (user.permissions) {
    for (const userPermission of user.permissions) {
      if (userPermission.base_permission_code) {
        permissions.add(userPermission.base_permission_code as UserPermission);
      }
    }
  }

  if (user.roles) {
    for (const role of user.roles) {
      if (!role.permissions) {
        continue;
      }
      for (const rolePermission of role.permissions) {
        if (rolePermission.base_permission_code) {
          permissions.add(
            rolePermission.base_permission_code as UserPermission
          );
        }
      }
    }
  }

  return Array.from(permissions);
};

export const hasPermission = (
  user: UserType | null | undefined,
  permission: UserPermission
) => {
  if (!user) {
    return false;
  }
  if (isAdmin(user)) {
    return true;
  }
  const userPermissions = getUserPermissions(user);
  return userPermissions.includes(permission);
};

export const hasPermissions = (
  user: UserType,
  permissions: UserPermission[]
) => {
  if (isAdmin(user)) {
    return true;
  }
  const userPermissions = getUserPermissions(user);
  return permissions.every((permission) =>
    userPermissions.includes(permission)
  );
};
