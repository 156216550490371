import React from 'react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import {
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { ReactComponent as PlusIcon } from '../../../assets/img/icons/plus.svg';
import { Tabs } from '../../../common/components/ui/Tabs/Tabs';
import { useLocationQuery } from '../../../common/hooks/utils/useLocationQuery';
import { URL_KEY_TAB } from '../../../store/urlKeys';
import {
  getBreadcrumbRouteLink,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { BasePageInterface } from '../../../common/components/utils/BasePageInterface';
import { removeTrailingSlash } from '../../../utils/url/url';
import { routeLinks } from '../../routeLinks';
import { UserPermission } from '../../../utils/permissions';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { IconButtonCreate } from '../../../common/components/ui/IconButtonCreate/IconButtonCreate';
import { useExternalPollsFilter } from './PollsFilter/useExternalPollsFilter';
import { Polls } from './Polls/Polls';
import { ExternalPolls } from './ExternalPolls/ExternalPolls';
import s from './PollsPage.module.scss';

export enum PollsPageTabs {
  periodic = 'periodic',
  external = 'external'
}

const tabs = [
  {
    id: PollsPageTabs.periodic,
    title: 'Постоянные'
  },
  {
    id: PollsPageTabs.external,
    title: 'Временные'
  }
];

export function getPollsPageBreadcrumbs(): IBreadcrumbsItem[] {
  return [getBreadcrumbRouteLink(routeLinks.polls)];
}

export default function PollsPage({ match }: BasePageInterface<{}>) {
  const { user } = useCurrentUser();
  const canCrudExternalPoll = useHasPermission(
    user,
    UserPermission.ExternalPollCrud
  );

  const [query] = useLocationQuery([URL_KEY_TAB] as const);
  const tabId = query[URL_KEY_TAB] || tabs[0].id;

  const [
    externalPollsFilterComponent,
    externalPollsFilter
  ] = useExternalPollsFilter();

  const tabRenderFns = {
    [PollsPageTabs.periodic]: () => <Polls />,
    [PollsPageTabs.external]: () => (
      <ExternalPolls
        editable={canCrudExternalPoll}
        filter={externalPollsFilter}
      />
    )
  };

  function renderTab({ id }) {
    return tabRenderFns[id]?.() || null;
  }

  const { isMobile } = useWindowSize();

  return (
    <PageLayout>
      <LayoutContent>
        <PageTitle
          title={routeLinks.polls.text}
          showHeading
          className={s.PollsPage__title}
          addButton={
            canCrudExternalPoll &&
            tabId === PollsPageTabs.external &&
            isMobile ? (
              <IconButtonCreate
                to={removeTrailingSlash(match.path) + '/external/create'}
              />
            ) : null
          }
        >
          {(Boolean(!isMobile && tabId === PollsPageTabs.external) ||
            Boolean(
              !isMobile &&
                canCrudExternalPoll &&
                tabId === PollsPageTabs.external
            )) && (
            <div className={s.PollsPage__TitleInner}>
              {tabId === PollsPageTabs.external && externalPollsFilterComponent}
              {canCrudExternalPoll && tabId === PollsPageTabs.external && (
                <ButtonLink
                  to={removeTrailingSlash(match.path) + '/external/create'}
                  iconLeft={<PlusIcon />}
                  variant={ButtonVariant.secondary}
                  size={ButtonSize.medium}
                >
                  Добавить опрос
                </ButtonLink>
              )}
            </div>
          )}
        </PageTitle>
        <div className={s.PollsPage__search}>
          {isMobile &&
            tabId === PollsPageTabs.external &&
            externalPollsFilterComponent}
        </div>
        <Tabs tabs={tabs} children={renderTab} />
      </LayoutContent>
    </PageLayout>
  );
}
