import gql from 'graphql-tag';
import { FRAGMENT_USER_FULL } from '../fragments/users';

export const MUTATION_UPDATE_USER = gql`
  mutation mutationUpdateUser($input: UserUpdateInput!) {
    result: updateUser(input: $input) {
      ...UserFull
    }
  }

  ${FRAGMENT_USER_FULL}
`;
