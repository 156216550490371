import { lFormat, tryParseIso } from '@proscom/ui-utils-date';
import { useMemo } from 'react';
import { DATETIME_FORMAT_FULL } from '../../utils/date/date';

export function formatDate(
  dateIso: Date | string,
  formatString: string
): string;
export function formatDate(
  dateIso: Date | string | undefined | null,
  formatString: string
): string | null;
export function formatDate(
  dateIso: Date | string | undefined | null,
  formatString: string = DATETIME_FORMAT_FULL
) {
  const date =
    typeof dateIso === 'string' ? tryParseIso(dateIso) : dateIso || null;
  return (date && lFormat(date, formatString)) || null;
}

export const useFormatDate = (
  dateIso: Date | string | undefined | null,
  formatString: string = DATETIME_FORMAT_FULL
) => {
  return useMemo(() => {
    return formatDate(dateIso, formatString);
  }, [dateIso, formatString]);
};
