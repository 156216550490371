import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { BaseLink } from '../BaseLink/BaseLink';
import s from './ContextMenu.module.scss';

export interface ContextMenuItem {
  label: ReactNode;
  className?: string;
  onClick?: () => void;
  to?: string;
}

export interface ContextMenuProps {
  items: ContextMenuItem[];
}

export const ContextMenu: React.FC<ContextMenuProps> = ({ items }) => {
  return (
    <ul className={s.ContextMenu}>
      {items.map((item, iItem) => {
        return (
          <BaseLink
            key={iItem}
            className={clsx(s.ContextMenu__item, item.className)}
            onClick={item.onClick}
            to={item.to}
          >
            {item.label}
          </BaseLink>
        );
      })}
    </ul>
  );
};
