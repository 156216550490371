import React from 'react';
import { RouteComponentProps } from 'react-router';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { IBreadcrumbsItem } from '../../../common/components/ui/Breadcrumbs';
import { routeLinks } from '../../routeLinks';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { AppHistoryType } from '../../../utils/createAppHistory';
import {
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { ReactComponent as IconPlus } from '../../../assets/img/icons/plus.svg';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { UserPermission } from '../../../utils/permissions';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { IconButtonCreate } from '../../../common/components/ui/IconButtonCreate/IconButtonCreate';
import { ProjectsListUser } from './ProjectsList/ProjectsListUser';
import { ProjectsList } from './ProjectsList/ProjectsList';
import { useProjectsFilter } from './useProjectsFilter';
import s from './ProjectsPage.module.scss';

export interface ProjectsPageProps extends RouteComponentProps {
  history: AppHistoryType;
}

export function getProjectsPageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    {
      name: 'Проекты',
      path: routeLinks.projects.to
    }
  ];
}

export default function ProjectsPage({ history }: ProjectsPageProps) {
  const { user } = useCurrentUser();
  const canEditAll = useHasPermission(user, UserPermission.ProjectsCrud);

  const pageTitle = canEditAll ? 'Все проекты сотрудников' : 'Проекты';

  const [filterComponent, filter] = useProjectsFilter();

  const { isMobile } = useWindowSize();

  return (
    <PageLayout>
      <LayoutContent>
        <PageTitle
          className={s.ProjectsPage__pageTitle}
          title={pageTitle}
          showHeading={true}
          addButton={
            isMobile ? (
              <IconButtonCreate to={`${routeLinks.projects.to}/create`} />
            ) : null
          }
        >
          <div className={s.ProjectsPage__pageTitleExt}>
            {!isMobile && (
              <ButtonLink
                to={`${routeLinks.projects.to}/create`}
                variant={ButtonVariant.secondary}
                size={ButtonSize.small}
                iconLeft={<IconPlus />}
              >
                Добавить проект
              </ButtonLink>
            )}

            <div className={s.ProjectsPage__filter}>{filterComponent}</div>
          </div>
        </PageTitle>

        {canEditAll ? (
          <ProjectsList filter={filter} isEditable />
        ) : (
          <ProjectsListUser filter={filter} />
        )}
      </LayoutContent>
    </PageLayout>
  );
}
