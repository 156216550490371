import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import {
  Breadcrumbs,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { OffersValidationSchema } from '../../../store/validationSchema';
import { OfferForm } from '../OfferForm/OfferForm';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { BasePageInterface } from '../../../common/components/utils/BasePageInterface';
import { getOffersPageBreadcrumbs } from '../list/OffersPage';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { tryAppendErrorId } from '../../../graphql/graphqlErrors';
import { Offers } from '../../../store/offers/Offers';
import {
  OffersFormValues,
  OffersUtils
} from '../../../store/offers/OffersUtils';

export function getOfferCreatePageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    ...getOffersPageBreadcrumbs({}),
    {
      name: 'Создание',
      path: '/offers/create'
    }
  ];
}

export default function OfferCreatePage({ history }: BasePageInterface<{}>) {
  const createOp = useGraphqlMutation(Offers.CreateOffer);
  const createOpRun = createOp.run;
  const handleSubmitForm = useCallback(
    (values: OffersFormValues) => {
      const input = OffersUtils.fromCreateForm(values);
      createOpRun({ variables: { input } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          history.push(`/offers/${result.data.result.id}`);
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            tryAppendErrorId(
              'Произошла ошибка при создании объявления. Попробуйте снова',
              err
            )
          );
        });
    },
    [history, createOpRun]
  );

  const breadcrumbs = useMemo(() => getOfferCreatePageBreadcrumbs(), []);

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle title={['Создание', 'Бонусы']} showHeading />
        <Formik
          validationSchema={OffersValidationSchema}
          initialValues={OffersUtils.toForm()}
          onSubmit={handleSubmitForm}
        >
          {(props) => (
            <OfferForm
              {...props}
              history={history}
              submissionLoading={createOp.loading}
            />
          )}
        </Formik>
      </LayoutContent>
    </PageLayout>
  );
}
