import clsx from 'clsx';
import { Card, CardProps, CardStyle } from '../Card/Card';
import s from './QuestionCard.module.scss';

interface QuestionCardProps extends CardProps {
  name?: string;
  title?: string;
  required?: boolean;
}

export function QuestionCard({
  required,
  title,
  children,
  ...props
}: QuestionCardProps) {
  return (
    <Card
      className={clsx(s.QuestionCard, {
        [s.QuestionCard_required]: required
      })}
      cardStyle={CardStyle.bordered}
      {...props}
    >
      <div className={s.QuestionCard__title}>{title}</div>
      {children}
    </Card>
  );
}
