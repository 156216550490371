import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { cleanObject } from '@proscom/ui-utils';
import { TableCell } from '../TableCell/TableCell';
import { TableRow } from '../TableRow/TableRow';
import { ActionButton, ActionType } from '../../ActionButton/ActionButton';
import { TableRowData, TableRowProps } from '../Table';
import s from './ActionRow.module.scss';

export interface ActionRowCbProps {
  row: TableRowData;
  state: TableRowData;
  iRow: number;
  data: TableRowData[];
  cancelEdit: () => void;
}

export type ActionRowCb = (props: ActionRowCbProps) => void;

export interface ActionRowProps extends TableRowProps {
  onDeleteRow: ActionRowCb;
  onSubmitRow: ActionRowCb;
  showTitle?: boolean;
}

export const ActionRow: React.FC<ActionRowProps> = ({
  columns,
  data,
  iRow,
  row,
  onDeleteRow,
  onSubmitRow,
  showTitle
}) => {
  const [editMode, setEditMode] = useState(false);
  const [state, setState] = useState<TableRowData>(row);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setState(row);
  }, [row]);

  const cancelEdit = useCallback(() => {
    setState(row);
    setEditMode(false);
  }, [row]);

  const activateEditMode = () => {
    setEditMode(true);
  };

  const handleDeleteRow = () => {
    onDeleteRow({ row, state, iRow, data, cancelEdit });
  };

  const handleSubmitRow = () => {
    if (Object.keys(cleanObject(errors)).length) {
      return;
    }
    onSubmitRow({ row, state, iRow, data, cancelEdit });
  };

  return (
    <TableRow>
      {columns.map((col, iCol) => {
        const EditComponent = col.editComponent;
        const FieldComponent = col.fieldComponent;

        return (
          <TableCell
            key={iCol}
            className={clsx(col.className, {
              [s.ActionRow_editMode]: editMode
            })}
          >
            {showTitle && <div className={s.ActionRow__title}>{col.title}</div>}
            {EditComponent && editMode ? (
              <EditComponent
                state={state}
                onChange={(value) => {
                  setState({ ...state, [col.field]: value });
                }}
                setError={(message) => {
                  setErrors({
                    ...errors,
                    [col.field]: message
                  });
                }}
                error={errors[col.field]}
              />
            ) : FieldComponent ? (
              <FieldComponent row={row} field={col.field} />
            ) : (
              row[col.field]
            )}
          </TableCell>
        );
      })}
      <TableCell className={s.ActionRow__action}>
        <div className={s.ActionRow__actionsInner}>
          {editMode ? (
            <>
              <ActionButton
                actionType={ActionType.approve}
                onClick={handleSubmitRow}
              />
              <ActionButton
                actionType={ActionType.cancel}
                onClick={cancelEdit}
              />
            </>
          ) : (
            <>
              <ActionButton
                actionType={ActionType.edit}
                onClick={activateEditMode}
              />
              <ActionButton
                actionType={ActionType.delete}
                onClick={handleDeleteRow}
              />
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};
