import React, { useRef } from 'react';
import clsx from 'clsx';
import {
  renderSlot,
  Slot,
  useClickOutside,
  useControlledState
} from '@proscom/ui-react';
import s from './Dropdown.module.scss';

export interface DropdownClasses {
  root?: string;
  content?: string;
  trigger?: string;
}

export interface DropdownSlotProps {
  onToggle?: (value: boolean) => void;
  isOpen?: boolean;
}

export interface DropdownProps {
  isOpen?: boolean;
  defaultOpen?: boolean;
  onToggle?: (value: boolean) => void;
  content: Slot<DropdownSlotProps>;
  children: Slot<DropdownSlotProps>;
  classes?: DropdownClasses;
  className?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  classes,
  isOpen: isOpenProp,
  defaultOpen,
  onToggle,
  content,
  className,
  children
}) => {
  const [isOpen, handleToggle] = useControlledState(
    isOpenProp,
    defaultOpen,
    false,
    onToggle
  );

  const dropDownRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(dropDownRef, () => {
    handleToggle(false);
  });

  return (
    <div
      ref={dropDownRef}
      className={clsx(s.Dropdown, className, classes?.root)}
    >
      <div className={clsx(s.Dropdown__trigger, classes?.trigger)}>
        {renderSlot(children, { onToggle: handleToggle, isOpen })}
      </div>
      <div
        className={clsx(
          s.Dropdown__content,
          {
            [s.Dropdown__content_open]: isOpen
          },
          classes?.content
        )}
      >
        {isOpen && renderSlot(content, { onToggle: handleToggle, isOpen })}
      </div>
    </div>
  );
};
