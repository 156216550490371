import React from 'react';
import { FigureType, FigureVariant } from '../../../Figures/types';
import { CardPattern } from '../CardPattern/CardPattern';
import s from './CirclesPattern.module.scss';

export const CirclesPattern = React.memo(function CirclesPattern() {
  return (
    <CardPattern
      className={s.CirclesPattern}
      figures={[
        {
          className: s.CirclesPattern_front,
          type: FigureType.circle,
          variant: FigureVariant.dark,
          size: 132
        },
        {
          className: s.CirclesPattern_back,
          type: FigureType.circle,
          variant: FigureVariant.light,
          size: 190
        }
      ]}
    />
  );
});
