import { IUseGraphqlQueryOptions } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { CourseType } from '../../types';
import { QUERY_GET_COURSE } from '../../queries/courses';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';

export interface UseCourseVars {
  id: string;
}

const queryOptions: IUseGraphqlQueryOptions<
  UseCourseVars | null,
  CourseType | null
> = {
  query: QUERY_GET_COURSE,
  mapData: (result) => result.course,
  skipQuery: skipIfNull(null)
};

export function useCourse(id?: string) {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: id ? { id } : null
  });
}
