import React from 'react';
import { DATE_FORMAT_RUSSIAN } from '@proscom/ui-utils-date';
import { PageTitle } from '../../../../common/components/ui/PageTitle/PageTitle';
import { ContentBlock } from '../ContentBlock/ContentBlock';
import { OfferType } from '../../../../graphql/types';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { useFormatDate } from '../../../../common/hooks/useFormatDate';
import { ButtonAnchor } from '../../../../common/components/ui/Button';
import { Image } from '../../../../common/components/ui/Image/Image';
import { useFileSrc } from '../../../../common/hooks/useFileSrc';
import { ImagePreview } from '../../../../common/components/ui/ImagePreview/ImagePreview';
import { getDateUtc } from '../../../../utils/date/date';
import { FileLoaderList } from '../../../../common/components/ui/FileLoader/FileLoaderList';
import { TitleVariant } from '../../../../common/components/ui/Title/Title';
import s from './Offer.module.scss';

export interface OfferProps extends OfferType {}

export const Offer: React.FC<OfferProps> = ({
  name,
  description,
  conditions,
  expires_at,
  partner_logo_file_id,
  cover_file_id,
  link,
  document_files
}) => {
  const expiresAt = useFormatDate(getDateUtc(expires_at), DATE_FORMAT_RUSSIAN);
  const coverImgSrc = useFileSrc(cover_file_id, FileTypeEnum.offer_cover);

  return (
    <div className={s.Offer}>
      <PageTitle title={name} showHeading />
      <ContentBlock
        content={
          <ImagePreview
            className={s.Offer__cover}
            classes={{
              image: s.Offer__coverImage
            }}
            imageSrc={coverImgSrc}
            showBackfaceImage
          />
        }
      />
      {expiresAt && (
        <ContentBlock label={'Срок действия:'} content={`до ${expiresAt}`} />
      )}
      <ContentBlock
        label={'Описание:'}
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: description || ''
            }}
          />
        }
      />
      <ContentBlock
        label={'Условия получения:'}
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: conditions || ''
            }}
          />
        }
      />

      {document_files && document_files.length > 0 && (
        <FileLoaderList
          className={s.Offer__files}
          title={'Файлы'}
          titleVariant={TitleVariant.h4}
          fileType={FileTypeEnum.offer_document}
          files={document_files}
        />
      )}

      <div className={s.Offer__footer}>
        {partner_logo_file_id && (
          <div>
            <Image
              type={FileTypeEnum.offer_partner_logo}
              id={partner_logo_file_id}
              className={s.Offer__partnerLogo}
            />
          </div>
        )}
        <div className={s.Offer__actions}>
          {link && (
            <ButtonAnchor
              href={link}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              Перейти
            </ButtonAnchor>
          )}
        </div>
      </div>
    </div>
  );
};
