import {
  attachValueToObservable,
  ObservableWithValue
} from '@proscom/prostore';
import { distinctUntilChanged, map } from 'rxjs/operators';

/**
 * Преобразовывает обзервабл со значением в новый обзервабл со значением,
 * меняя значение
 *
 * @param obs$ - исходный обзервабл
 * @param project - функция для смены значения
 */
export function mapObservableWithValue<T, R>(
  obs$: ObservableWithValue<T>,
  project: (value: T) => R
) {
  return attachValueToObservable(
    obs$.pipe(map(project), distinctUntilChanged()),
    () => project(obs$.value)
  );
}
