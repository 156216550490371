import React from 'react';
import { useFileSrc } from '../../../../hooks/useFileSrc';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { ActionButton, ActionType } from '../../ActionButton/ActionButton';
import {
  ImagePreview,
  ImagePreviewProps
} from '../../ImagePreview/ImagePreview';
import s from '../ContentBlock.module.scss';

export interface ImageBlockPreviewProps
  extends Pick<ImagePreviewProps, 'placeholderProps'> {
  fileId?: string;
  isEditable?: boolean;
  fileType?: FileTypeEnum;
  onRemoveClick?: () => void;
}

export const ImageBlockPreview: React.FC<ImageBlockPreviewProps> = ({
  fileId,
  isEditable,
  onRemoveClick,
  fileType = FileTypeEnum.article_image,
  ...props
}) => {
  const imageSrc = useFileSrc(fileId, fileType);

  return (
    <div className={s.ImageBlockPreview}>
      <ImagePreview
        className={s.ImageBlockPreview__content}
        classes={{
          image: s.ImageBlockPreview__image
        }}
        imageSrc={imageSrc}
        {...props}
      />
      {isEditable && imageSrc && (
        <ActionButton
          className={s.ImageBlockPreview__removeButton}
          actionType={ActionType.delete}
          onClick={() => onRemoveClick?.()}
        />
      )}
    </div>
  );
};
