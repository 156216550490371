import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { FetchResult } from 'apollo-link';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { Offers } from '../../store/offers/Offers';
import { handleDefaultError } from '../../utils/handleDefaultError';
import { useGraphqlMutation } from './utils/useGraphqlMutation';

export interface useOfferDeleteReturn {
  handleDelete: () => void;
  loading: boolean;
}

export const useOfferDelete = (
  id: string,
  onSuccess?: (result: FetchResult<{ result: boolean }>) => void
): useOfferDeleteReturn => {
  const deleteOffer = useGraphqlMutation(Offers.DeleteOffer);
  const deleteOfferRun = deleteOffer.run;
  const handleRemoveClick = useCallback(() => {
    if (window.confirm('Вы действительно хотите удалить бонус?')) {
      deleteOfferRun({ variables: { id } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          toast.success('Бонус удален');
          onSuccess?.(result);
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(err, 'Произошла ошибка при удалении бонуса');
        });
    }
  }, [deleteOfferRun, id, onSuccess]);

  return {
    handleDelete: handleRemoveClick,
    loading: deleteOffer.loading
  };
};
