import React from 'react';
import clsx from 'clsx';
import { Card, CardProps, CardVariant } from '../../Card/Card';
import { Pattern, PatternsComponent } from '../../Card/patterns';
import { TextLink } from '../../TextLink/TextLink';
import s from './CardDocSection.module.scss';

export interface CardDocSectionProps extends Omit<CardProps, 'actions'> {
  pattern: Pattern;
}

export const CardDocSection: React.FC<CardDocSectionProps> = ({
  classes,
  pattern,
  ...cardProps
}) => {
  return (
    <Card
      {...cardProps}
      classes={{
        ...classes,
        root: clsx(s.CardDocSection, classes?.root),
        actions: s.CardDocSection__actions
      }}
      variant={CardVariant.secondary}
      actions={
        <TextLink isClickable={false} noHover noUnderline>
          Подробнее
        </TextLink>
      }
      extContent={PatternsComponent[pattern]}
    />
  );
};
