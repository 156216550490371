import React, { useEffect, useRef } from 'react';
import { TextField } from '../../../common/components/ui/TextField/TextField';
import { REQUIRED_MESSAGE } from '../../../store/validationSchema';

export const NameEdit = ({ state, onChange, error, setError }) => {
  const ref = useRef(setError);
  useEffect(() => {
    ref.current();
  }, []);

  return (
    <TextField
      value={state.name}
      onChange={onChange}
      onBlur={(event) => {
        if (!event.target.value) {
          setError(REQUIRED_MESSAGE);
        } else {
          setError();
        }
      }}
      errorMessage={error}
    />
  );
};
