import { useMemo } from 'react';
import { UserType } from '../../graphql/types';
import { hasPermission, UserPermission } from '../../utils/permissions';

export function useHasPermission(
  user: UserType | null | undefined,
  permission: UserPermission
) {
  return useMemo(() => hasPermission(user, permission), [user, permission]);
}
