import React, { useCallback } from 'react';
import { useField } from 'formik';
import {
  AsyncEntitySelect,
  AsyncEntitySelectProps
} from '../../AsyncSelect/entities/AsyncEntitySelect';
import { Entity } from '../../AsyncSelect/entities/types';

export function FormAsyncEntitySelect<T extends Entity>({
  name,
  entity,
  ...props
}: Omit<AsyncEntitySelectProps<T>, 'onChange' | 'value'> & { name: string }) {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;
  const { error, touched } = meta;

  const handleChange = useCallback(
    (item) => {
      setValue(item?.value);
    },
    [setValue]
  );

  return (
    <AsyncEntitySelect
      entity={entity}
      {...field}
      value={value ? String(value) : null}
      onChange={handleChange}
      errorMessage={touched && error ? error : undefined}
      {...props}
    />
  );
}
