import React, { useMemo, useState } from 'react';
import { EMDASH } from '@proscom/ui-utils';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import {
  getSystemLinksPageBreadcrumbs,
  SystemLinksPageProps
} from '../list/SystemLinksPage';
import {
  Breadcrumbs,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { Table } from '../../../common/components/ui/Table/Table';
import { useSystemLinks } from '../../../graphql/hooks/useSystemLinks';
import { TextField } from '../../../common/components/ui/TextField/TextField';
import { SystemLinkAddField } from './SystemLinkAddField';
import { SystemLink } from './SystemLink/SystemLink';
import { SystemLinksRow } from './SystemLinksRow';
import { NameEdit } from './NameEdit';
import { LinkEdit } from './LinkEdit';
import s from './SystemLinksEditPage.module.scss';

function getSystemLinksEditPageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    ...getSystemLinksPageBreadcrumbs(),
    {
      name: 'Управление информационными системами университета',
      path: '/systemLinks/edit'
    }
  ];
}

const SystemLinksColumns = [
  {
    title: 'Приоритет',
    field: 'priority',
    editComponent: ({ state, onChange }) => (
      <TextField value={state.priority} type={'number'} onChange={onChange} />
    ),
    fieldComponent: ({ row }) => row.priority || EMDASH
  },
  {
    title: 'Название',
    field: 'name',
    editComponent: NameEdit
  },
  {
    title: 'Адрес',
    field: 'link',
    editComponent: LinkEdit,
    fieldComponent: SystemLink
  }
];

export default function SystemLinksEditPage({ match }: SystemLinksPageProps) {
  const [showAddForm, setShowAddForm] = useState(false);

  const systemLinksQuery = useSystemLinks();
  const systemLinks = systemLinksQuery.state.data;

  const breadcrumbs = useMemo(() => getSystemLinksEditPageBreadcrumbs(), []);

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle
          className={s.SystemLinksEditPage__title}
          title={'Сервисы университета'}
          showHeading
        >
          {!showAddForm && (
            <div>
              <Button
                size={ButtonSize.small}
                variant={ButtonVariant.secondary}
                onClick={() => setShowAddForm(true)}
              >
                Добавить систему
              </Button>
            </div>
          )}
        </PageTitle>
        {showAddForm && (
          <SystemLinkAddField onCancel={() => setShowAddForm(false)} />
        )}
        {queryLoader(systemLinksQuery) ||
          (!systemLinks || systemLinks.length === 0 ? (
            <NoData />
          ) : (
            <Table
              columns={SystemLinksColumns}
              data={systemLinks}
              rowComponent={SystemLinksRow}
              hasActions
            />
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
