import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Title, TitleVariant } from '../Title/Title';
import s from './Card.module.scss';

export enum CardActionsStart {
  left = 'left',
  right = 'right'
}

export enum CardVariant {
  primary = 'primary',
  secondary = 'secondary'
}

export enum CardStyle {
  bordered = 'bordered',
  gray = 'gray',
  gradient = 'gradient'
}

export interface CardClasses {
  root?: string;
  inner?: string;
  title?: string;
  actions?: string;
}

export interface CardProps {
  className?: string;
  title?: string;
  actions?: ReactNode;
  actionsStart?: CardActionsStart;
  cardStyle?: CardStyle;
  variant?: CardVariant;
  classes?: CardClasses;
  children?: React.ReactNode;
  extContent?: React.ReactNode;
  to?: string;
  style?: React.CSSProperties;
  onClick?: (e?: React.MouseEvent) => void;
}

export const Card: React.FC<CardProps> = ({
  className,
  classes,
  title,
  actions,
  actionsStart = CardActionsStart.left,
  cardStyle = CardStyle.gray,
  variant = CardVariant.primary,
  children,
  extContent,
  to,
  style,
  onClick
}) => {
  const Component = (props) => {
    return to ? <Link to={to} {...props} /> : <div {...props} />;
  };

  return (
    <Component
      className={clsx(
        s.Card,
        className,
        classes?.root,
        s[`Card_${cardStyle}`],
        s[`Card_${variant}`]
      )}
      style={style}
      onClick={onClick}
    >
      <div className={clsx(s.Card__inner, classes?.inner)}>
        {title && (
          <Title
            title={title}
            variant={TitleVariant.h4}
            className={classes?.title}
          />
        )}
        {children}
      </div>

      {actions && (
        <div
          className={clsx(
            s.Card__actions,
            s[`Card__actions_${actionsStart}`],
            classes?.actions
          )}
        >
          {actions}
        </div>
      )}

      {extContent}
    </Component>
  );
};
