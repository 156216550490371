import { Routes, RouteType } from '../../common/components/utils/Routes';
import { RequireAuth } from '../RequireAuth';
import { hasPermission, UserPermission } from '../../utils/permissions';
import NotFoundPage from '../notFound/NotFoundPage';
import ReservesPage from './list/ReservesPage';
import ReservePage from './index/ReservePage';
import ReserveCreatePage from './create/ReserveCreatePage';
import ReserveEditPage from './edit/ReserveEditPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: RequireAuth(ReservesPage)
  },
  {
    path: '/create',
    exact: true,
    component: RequireAuth(ReserveCreatePage, (user) =>
      hasPermission(user, UserPermission.PersonnelReserveCrud)
    )
  },
  {
    path: '/:reserveId',
    exact: true,
    component: RequireAuth(ReservePage)
  },
  {
    path: '/:reserveId/edit',
    exact: true,
    component: RequireAuth(ReserveEditPage, (user) =>
      hasPermission(user, UserPermission.PersonnelReserveCrud)
    )
  },
  {
    component: NotFoundPage
  }
];

const ReserveRoutes = Routes(routes);

export default ReserveRoutes;
