import React, { useMemo } from 'react';
import clsx from 'clsx';
import {
  addStaticProperties,
  propsSplitter
} from '../../../../utils/reactTypes';
import { Loader } from '../Loader/Loader';
import {
  BaseButtonProps,
  ButtonBaseColor,
  ButtonLoaderSize,
  ButtonSize,
  ButtonVariant
} from './types';
import s from './Button.module.scss';

const baseKeys = [
  'renderBase',
  'className',
  'children',
  'classes',
  'iconLeft',
  'variant',
  'size',
  'iconRight',
  'loading',
  'baseColor'
] as const;

const splitProps = propsSplitter(baseKeys);

export const BaseButton = addStaticProperties(
  function BaseButton({
    variant = ButtonVariant.primary,
    size = ButtonSize.medium,
    baseColor = ButtonBaseColor.blue,
    iconLeft,
    iconRight,
    classes,
    children,
    className,
    loading,
    renderBase
  }: BaseButtonProps) {
    const singleColor = useMemo(() => {
      if (variant === ButtonVariant.primary) {
        return '#fff';
      }
      if (variant === ButtonVariant.secondary) {
        switch (baseColor) {
          case ButtonBaseColor.red:
            return '#d51a1a';
          case ButtonBaseColor.green:
            return '#03bd4e';
          case ButtonBaseColor.gray:
            return '#38414e';
          case ButtonBaseColor.blue:
            return '#0075ff';
          default:
            return '#0075ff';
        }
      }
    }, [baseColor, variant]);

    return renderBase({
      className: clsx(
        s.Button,
        s[`Button_${variant}`],
        s[`Button_${size}`],
        {
          [s[`Button_${baseColor}`]]: variant === ButtonVariant.secondary
        },
        className,
        classes?.root
      ),
      children: loading ? (
        <Loader size={ButtonLoaderSize[size]} singleColor={singleColor} />
      ) : (
        <>
          {iconLeft && (
            <div
              className={clsx(
                s.Button__icon,
                s.Button__iconLeft,
                classes?.iconLeft
              )}
            >
              {iconLeft}
            </div>
          )}
          <div className={clsx(classes?.content)}>{children}</div>
          {iconRight && (
            <div
              className={clsx(
                s.Button__icon,
                s.Button__iconRight,
                classes?.iconRight
              )}
            >
              {iconRight}
            </div>
          )}
        </>
      )
    });
  },
  {
    splitProps,
    baseKeys
  }
);
