import React from 'react';
import { LayoutContent } from '../../common/components/Layout/Layout';
import { PageLayout } from '../../common/components/Layout/PageLayout';
import { routeLinks } from '../routeLinks';
import { MainPageSection } from './MainPageSection/MainPageSection';
import { ArticlesSlider } from './ArticlesSlider/ArticlesSlider';
import { ExternalPollsSlider } from './ExternalPollsSlider/ExternalPollsSlider';
import { OffersSlider } from './OffersSlider/OffersSlider';

export default function MainPage() {
  return (
    <PageLayout>
      <LayoutContent>
        <MainPageSection
          title={routeLinks.articles.text}
          link={routeLinks.articles.to}
        >
          <ArticlesSlider />
        </MainPageSection>

        <MainPageSection
          title={routeLinks.polls.text}
          link={routeLinks.polls.to}
        >
          <ExternalPollsSlider />
        </MainPageSection>

        {/*<MainPageSection*/}
        {/*  title={routeLinks.notices.text}*/}
        {/*  link={routeLinks.notices.to}*/}
        {/*>*/}
        {/*  <NoticesSlider />*/}
        {/*</MainPageSection>*/}

        <MainPageSection
          title={routeLinks.offers.text}
          link={routeLinks.offers.to}
        >
          <OffersSlider />
        </MainPageSection>

        {/*<MainPageSection*/}
        {/*  title={routeLinks.events.text}*/}
        {/*  link={routeLinks.events.to}*/}
        {/*>*/}
        {/*  <EventsSlider />*/}
        {/*</MainPageSection>*/}
      </LayoutContent>
    </PageLayout>
  );
}
