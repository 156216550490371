import React from 'react';
import { useField } from 'formik';
import { TextField, TextFieldProps } from '../../TextField/TextField';

export interface FormTextFieldProps extends TextFieldProps {
  name: string;
}

export const FormTextField: React.FC<FormTextFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { onChange, ...fieldProps } = field;

  const { error, touched } = meta;

  return (
    <TextField
      onChange={(value, e) => {
        onChange(e);
      }}
      {...props}
      {...fieldProps}
      errorMessage={touched && error ? error : undefined}
    />
  );
};
