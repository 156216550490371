import gql from 'graphql-tag';
import { FRAGMENT_PERSONNEL_RESERVE_FULL } from '../fragments/personnelReserve';

export const MUTATION_CREATE_PERSONNEL_RESERVE = gql`
  mutation mutationCreatePersonnelReserve(
    $input: PersonnelReserveCreateInput!
  ) {
    result: createPersonnelReserve(input: $input) {
      ...PersonnelReserveFull
    }
  }

  ${FRAGMENT_PERSONNEL_RESERVE_FULL}
`;

export const MUTATION_UPDATE_PERSONNEL_RESERVE = gql`
  mutation mutationUpdatePersonnelReserve(
    $input: PersonnelReserveUpdateInput!
  ) {
    result: updatePersonnelReserve(input: $input) {
      ...PersonnelReserveFull
    }
  }

  ${FRAGMENT_PERSONNEL_RESERVE_FULL}
`;

export const MUTATION_DELETE_PERSONNEL_RESERVE = gql`
  mutation mutationDeletePersonnelReserve($id: ID!) {
    result: deletePersonnelReserve(id: $id)
  }
`;
