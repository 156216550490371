import { useAsyncOperation } from '@proscom/prostore-react';
import axios from 'axios';
import { MutationOptions } from 'apollo-client';
import { toast } from 'react-toastify';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { fileExportUrl } from '../../../config';
import { tryDownloadFileFromResponse } from '../../../utils/files';
import { MUTATION_CREATE_OTT_FOR_USERS_EXPORT_DOWNLOAD } from '../../../graphql/mutations/oneTimeTokens';
import { UserFilter } from '../../../graphql/types';

const exportOtt: MutationOptions<{ result: string }> = {
  mutation: MUTATION_CREATE_OTT_FOR_USERS_EXPORT_DOWNLOAD
};
const EXPORT_ERROR_TEXT = 'Произошла ошибка при экспорте';

export function useExportUsersToXlsx({ filter }: { filter: UserFilter }) {
  const getOttTokenMutation = useGraphqlMutation(exportOtt);
  return useAsyncOperation(
    async () => {
      try {
        const ottTokenMutationResult = await getOttTokenMutation.run({});
        const token = ottTokenMutationResult.data?.result;
        if (!token) {
          console.error(ottTokenMutationResult);
          throw new Error(EXPORT_ERROR_TEXT);
        }
        try {
          const response = await axios(`${fileExportUrl}/users`, {
            method: 'POST',
            responseType: 'arraybuffer',
            headers: {
              'content-type': 'application/json'
            },
            data: JSON.stringify({
              filter,
              token
            })
          });
          tryDownloadFileFromResponse(response);
        } catch (e) {
          console.error(e);
          throw new Error(EXPORT_ERROR_TEXT);
        }
      } catch (e) {
        toast.error(e.message);
      }
    },
    {
      singleton: true,
      finishedTimeout: 10 * 1000
    }
  );
}
