import React from 'react';
import clsx from 'clsx';
import {
  ContentBlockVariant,
  IContentBlockData
} from '../Form/FormContentEditor/Content';
import { ContentBlockProps } from './ContentBlock';
import { TextBlockEditable } from './TextBlock/TextBlockEditable';
import { VideoBlockEditable } from './VideoBlock/VideoBlockEditable';
import { HeaderBlockEditable } from './HeaderBlock/HeaderBlockEditable';
import { ImageBlockEditable } from './ImageBlock/ImageBlockEditable';
import { BlockquoteBlockEditable } from './BlockquoteBlock/BlockquoteBlockEditable';
import { TextImageBlockEditable } from './TextImageBlock/TextImageBlockEditable';
import s from './ContentBlock.module.scss';

type BlockTypeMap = Record<ContentBlockVariant, React.ElementType>;

const blocks: BlockTypeMap = {
  [ContentBlockVariant.header]: HeaderBlockEditable,
  [ContentBlockVariant.text]: TextBlockEditable,
  [ContentBlockVariant.image]: ImageBlockEditable,
  [ContentBlockVariant.textImage]: TextImageBlockEditable,
  [ContentBlockVariant.video]: VideoBlockEditable,
  [ContentBlockVariant.blockquote]: BlockquoteBlockEditable
};

export interface ContentBlockEditableProps<Variant extends ContentBlockVariant>
  extends ContentBlockProps<Variant> {
  onChange: (value: IContentBlockData<Variant>) => void;
  onError?: (error: any) => void;
}

export function ContentBlockEditable<Variant extends ContentBlockVariant>({
  className,
  block,
  meta,
  onChange,
  onError
}: ContentBlockEditableProps<Variant>) {
  const BlockComponent = blocks[block.variant];

  return (
    <div
      className={clsx(
        s.ContentBlock,
        s.ContentBlock_editable,
        className,
        s[`ContentBlock_${block.variant}`]
      )}
    >
      {BlockComponent ? (
        <BlockComponent
          meta={meta}
          data={block.data}
          onChange={onChange}
          onError={onError}
        />
      ) : (
        'Неизвестный блок'
      )}
    </div>
  );
}
