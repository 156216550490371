import { ObservableWithValue } from '@proscom/prostore';
import { useEffect, useState } from 'react';
import { createReactDOMBatcher } from '../../utils/helpers/createReactDOMBatcher';

const queueSubjectUpdate = createReactDOMBatcher();

/**
 * Подписывает компонент на указанный ObservableWithValue с группировкой обновлений.
 * React перерендерит компоненты не последовательно, а все разом.
 * Это позволяет оптимизировать ситуации, когда на один и тот же обзервабл
 * подписано много компонентов
 *
 * @param obs$ - обзервабл для подписки
 */
export function useBatchedSubject<T>(obs$: ObservableWithValue<T>) {
  const [state, setState] = useState(obs$.value);
  useEffect(() => {
    let active = true;
    const sub = obs$.subscribe((value) => {
      queueSubjectUpdate(() => {
        if (active) {
          setState(value);
        }
      });
    });
    return () => {
      active = false;
      sub.unsubscribe();
    };
  }, [obs$]);
  return state;
}
