import React, { useState } from 'react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { VacationApplicationType } from '../../../graphql/types';
import { useVacationSchedules } from '../../../graphql/hooks/vacations/useVacationSchedules';
import { VacationSchedules } from './VacationSchedules/VacationSchedules';
import { VacationAppForm } from './VacationAppForm/VacationAppForm';
import { VacationArchive } from './VacationArchive/VacationArchive';
import s from './VacationAppPage.module.scss';

const title = 'Заявление на отпуск (кроме категории ППС)';
const caption = (
  <>
    В&nbsp;этом разделе вы&nbsp;сможете сформировать заявление на&nbsp;отпуск
    и&nbsp;направить его в&nbsp;электронном виде на&nbsp;согласование вашему
    руководителю и&nbsp;в&nbsp;отдел кадров.
    <br />
    После согласования заявления вы получите уведомление на&nbsp;корпоративную
    почту.
  </>
);

export default function VacationAppPage() {
  const [vacationApps, setVacationApps] = useState<VacationApplicationType[]>(
    []
  );

  const vacationSchedulesQuery = useVacationSchedules();
  const vacationSchedules = vacationSchedulesQuery.state.data;

  return (
    <PageLayout>
      <LayoutContent>
        <PageTitle
          className={s.VacationAppPage__pageTitle}
          captionClassName={s.VacationAppPage__pageCaption}
          title={title}
          caption={caption}
          showHeading={true}
        />

        {queryLoader(vacationSchedulesQuery) || (
          <>
            <div className={s.VacationAppPage__confirmedList}>
              <VacationSchedules vacationsSchedules={vacationSchedules} />
            </div>

            <div className={s.VacationAppPage__form}>
              <VacationAppForm
                vacationsSchedules={vacationSchedules}
                vacationApps={vacationApps}
              />
            </div>
          </>
        )}

        <div className={s.VacationAppPage__archive}>
          <VacationArchive onVacationAppsChange={setVacationApps} />
        </div>
      </LayoutContent>
    </PageLayout>
  );
}
