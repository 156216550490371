import gql from 'graphql-tag';

export const FRAGMENT_INTERNSHIP_SNIPPET = gql`
  fragment InternshipSnippet on InternshipType {
    id
    cover_file_id
    name
    description
    internship_subject {
      id
      name
    }
    tags {
      id
      name
    }
  }
`;

export const FRAGMENT_INTERNSHIP_FULL = gql`
  fragment InternshipFull on InternshipType {
    ...InternshipSnippet
    conditions
    internship_subject_id
    link
    manual_file_id
    manual_file {
      id
      name
    }
    contest
    duration
    financial_conditions
    location
    purpose
    trainee_category
  }

  ${FRAGMENT_INTERNSHIP_SNIPPET}
`;
