import { IUseGraphqlWatchQueryOptions } from '@proscom/prostore-apollo-react';
import {
  PersonnelReservePageInput,
  PersonnelReservePageType
} from '../../types';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import { ONE_PAGE_ITEMS } from '../../../constants';
import { QUERY_GET_PERSONNEL_RESERVES } from '../../queries/personnelReserve';

const queryOptions: IUseGraphqlWatchQueryOptions<
  { input: PersonnelReservePageInput },
  PersonnelReservePageType
> = {
  query: QUERY_GET_PERSONNEL_RESERVES,
  mapData: (result) => result.personnelReservePage
};

export function usePersonnelReserves({
  filter,
  sorting,
  pagination
}: PersonnelReservePageInput) {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: {
      input: {
        filter,
        sorting,
        pagination: {
          onePage: ONE_PAGE_ITEMS,
          ...pagination
        }
      }
    }
  });
}
