import { IUseGraphqlWatchQueryOptions } from '@proscom/prostore-apollo-react';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import { QUERY_GET_VACATION_SCHEDULES } from '../../queries/vacations';
import { VacationScheduleType } from '../../types';

const queryOptions: IUseGraphqlWatchQueryOptions<
  null,
  VacationScheduleType[]
> = {
  query: QUERY_GET_VACATION_SCHEDULES,
  mapData: (result) => result.vacationSchedules
};

export function useVacationSchedules() {
  return useAuthGraphqlQuery({
    queryOptions
  });
}
