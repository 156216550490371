import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import {
  Breadcrumbs,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { BasePageInterface } from '../../../common/components/utils/BasePageInterface';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { tryAppendErrorId } from '../../../graphql/graphqlErrors';
import { routeLinks } from '../../routeLinks';
import { PersonnelReserve } from '../../../store/personnelReserve/PersonnelReserve';
import {
  PersonnelReserveFormValues,
  PersonnelReserveUtils
} from '../../../store/personnelReserve/PersonnelReserveUtils';
import { ReserveForm } from '../ReserveForm/ReserveForm';
import { PersonnelReserveValidationSchema } from '../../../store/validationSchema';
import {
  getReservePageBreadcrumbs,
  GetReservePageBreadcrumbsArgs
} from '../index/ReservePage';
import { usePersonnelReserve } from '../../../graphql/hooks/personnelReserve/usePersonnelReserve';
import { queryLoader } from '../../../common/components/utils/queryLoader';

export function getReserveEditPageBreadcrumbs({
  reserve
}: GetReservePageBreadcrumbsArgs): IBreadcrumbsItem[] {
  return [
    ...getReservePageBreadcrumbs({ reserve }),
    {
      name: 'Редактирование',
      path: `${routeLinks.reserve.to}/${reserve.id}/edit`
    }
  ];
}

export default function ReserveEditPage({
  match,
  history
}: BasePageInterface<{ reserveId: string }>) {
  const { reserveId } = match.params;
  const reserveQuery = usePersonnelReserve(reserveId);
  const reserve = reserveQuery.state.data;

  const updateOp = useGraphqlMutation(PersonnelReserve.updateReserve);
  const updateOpRun = updateOp.run;
  const handleSubmitForm = useCallback(
    (values: PersonnelReserveFormValues) => {
      const input = PersonnelReserveUtils.fromUpdateForm(values);

      updateOpRun({ variables: { input } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          history.push(`${routeLinks.reserve.to}/${result.data.result.id}`);
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            tryAppendErrorId(
              'Произошла ошибка при создании кадрового резерва. Попробуйте снова',
              err
            )
          );
        });
    },
    [history, updateOpRun]
  );

  const breadcrumbs = useMemo(
    () =>
      getReserveEditPageBreadcrumbs({
        reserve: { id: reserveId, name: reserve?.name }
      }),
    [reserve, reserveId]
  );

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle title={['Редактирование', 'Кадровый резерв']} showHeading />
        {queryLoader(reserveQuery) ||
          (reserve && (
            <Formik
              validationSchema={PersonnelReserveValidationSchema}
              initialValues={PersonnelReserveUtils.toForm(reserve)}
              onSubmit={handleSubmitForm}
            >
              {(props) => (
                <ReserveForm
                  {...props}
                  reserveId={reserveId}
                  history={history}
                  submissionLoading={updateOp.loading}
                />
              )}
            </Formik>
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
