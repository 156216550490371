import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { ProstoreContext } from '@proscom/prostore-react';
import { LocationProvider } from '@proscom/prostore-react-router';
import { ApolloClientsContext } from '@proscom/prostore-apollo-react';
import { STORE_LOCATION } from './store/storeKeys';
import { App } from './App';
import { appHistory } from './appHistory';
import { apolloContext, appStores } from './appStores';

ReactDOM.render(
  <Router history={appHistory}>
    <ProstoreContext.Provider value={appStores}>
      <LocationProvider storeOrName={STORE_LOCATION}>
        <ApolloClientsContext.Provider value={apolloContext}>
          <App />
        </ApolloClientsContext.Provider>
      </LocationProvider>
    </ProstoreContext.Provider>
  </Router>,
  document.getElementById('root')
);
