import React from 'react';
import { Button, ButtonProps, ButtonSize, ButtonVariant } from '../../Button';

export function BlockActionRemove(props: ButtonProps) {
  return (
    <Button
      size={ButtonSize.small}
      variant={ButtonVariant.secondary}
      {...props}
    >
      Удалить
    </Button>
  );
}
