import {
  InternshipContest,
  InternshipDuration,
  InternshipFinancialConditions,
  InternshipLocation,
  InternshipPurpose,
  InternshipTraineeCategory
} from '../../graphql/types';
import { createOptionsFromEnum } from '../../utils/createOptionsFromEnum';

export const internshipTraineeCategoryLabelMap = {
  [InternshipTraineeCategory.AdministrativeManagementStaff]:
    'Научно-педагогические работники',
  [InternshipTraineeCategory.ScientificPedagogicalStaff]:
    'Административно-управленческий персонал',
  [InternshipTraineeCategory.TalentPoolProgramMembers]:
    'Участники программы кадрового резерва'
};

export const internshipPurposeLabelMap = {
  [InternshipPurpose.FormationOfProfessionalSkills]:
    'Формирование профессиональных навыков',
  [InternshipPurpose.ParticipationInJointResearch]:
    'Участие в совместном научном проекте/ исследовании',
  [InternshipPurpose.ExchangeOfExperience]: 'Обмен опытом и лучшими практиками',
  [InternshipPurpose.FormationOfManagementSkills]:
    'Формирование управленческих навыков'
};

export const internshipDurationLabelMap = {
  [InternshipDuration.ShortTerm]: 'Краткосрочная (до 1 месяца)',
  [InternshipDuration.MidTerm]: 'Среднесрочная (1-6 месяцев)',
  [InternshipDuration.LongTerm]: 'Длительная (свыше 6 месяцев)'
};

export const internshipLocationLabelMap = {
  [InternshipLocation.MoscowAndMoscowRegion]:
    'Организации Москвы и Московской области',
  [InternshipLocation.RussiaRegions]:
    'Организации за пределами Москвы и Московской области',
  [InternshipLocation.ForeignInCis]: 'Зарубежные (в странах СНГ)',
  [InternshipLocation.ForeignOutsideCis]: 'Зарубежные (за пределами стран СНГ)'
};

export const internshipLocationViewLabelMap = {
  [InternshipLocation.MoscowAndMoscowRegion]: 'Москва и Московская область',
  [InternshipLocation.RussiaRegions]:
    'За пределами Москвы и Московской области',
  [InternshipLocation.ForeignInCis]: 'Зарубежная (в странах СНГ)',
  [InternshipLocation.ForeignOutsideCis]: 'Зарубежная (за пределами стран СНГ)'
};

export const internshipContestLabelMap = {
  [InternshipContest.Yes]: 'Зачисление по конкурсу',
  [InternshipContest.No]: 'Зачисление без конкурса'
};

export const internshipFinancialConditionsLabelMap = {
  [InternshipFinancialConditions.WithSalary]:
    'Стажировка без оплаты с сохранением заработной платы по основному месту работы',
  [InternshipFinancialConditions.WithSalaryAtMainJob]:
    'Стажировка с заработной платой'
};

export const internshipTraineeCategoryOptions = createOptionsFromEnum(
  InternshipTraineeCategory,
  internshipTraineeCategoryLabelMap
);

export const internshipPurposeOptions = createOptionsFromEnum(
  InternshipPurpose,
  internshipPurposeLabelMap
);

export const internshipDurationOptions = createOptionsFromEnum(
  InternshipDuration,
  internshipDurationLabelMap
);

export const internshipLocationOptions = createOptionsFromEnum(
  InternshipLocation,
  internshipLocationLabelMap
);

export const internshipContestOptions = createOptionsFromEnum(
  InternshipContest,
  internshipContestLabelMap
);

export const internshipFinancialConditionsOptions = createOptionsFromEnum(
  InternshipFinancialConditions,
  internshipFinancialConditionsLabelMap
);
