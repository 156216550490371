import React from 'react';
import clsx from 'clsx';
import {
  SwitchBoxButton,
  SwitchBoxButtonIdType,
  SwitchBoxButtonProps
} from './SwitchBoxButton';
import s from './SwitchBox.module.scss';

export interface SwitchBoxProps {
  className?: string;
  switches: Omit<SwitchBoxButtonProps, 'onClick' | 'selected'>[];
  selectedId: SwitchBoxButtonIdType;
  onChange: (id: SwitchBoxButtonIdType) => void;
}

export const SwitchBox: React.FC<SwitchBoxProps> = ({
  className,
  switches,
  selectedId,
  onChange
}) => {
  return (
    <div className={clsx(s.SwitchBox, className)}>
      {switches.map((sw) => (
        <SwitchBoxButton
          key={sw.id}
          {...sw}
          selected={sw.id === selectedId}
          onClick={onChange}
        />
      ))}
    </div>
  );
};
