import React from 'react';
import { define } from '@proscom/ui-utils';
import { Tabs } from '../../../../common/components/ui/Tabs/Tabs';
import { ProjectFilter } from '../../../../graphql/types';
import { ProjectsList } from './ProjectsList';

export enum ProjectsTab {
  own = 'own',
  participant = 'participant',
  others = 'others'
}

const tabs: { id: ProjectsTab; title: string }[] = [
  {
    id: ProjectsTab.own,
    title: 'Мои проекты'
  },
  {
    id: ProjectsTab.participant,
    title: 'Участник проектов'
  },
  {
    id: ProjectsTab.others,
    title: 'Все проекты'
  }
];

const filters = define<{ [key: string]: ProjectFilter }>()({
  [ProjectsTab.own]: { user_is_creator: true },
  [ProjectsTab.participant]: {
    user_is_creator: false,
    user_is_participating: true
  },
  [ProjectsTab.others]: { user_is_creator: false, user_is_participating: false }
});

export interface ProjectsListUserProps {
  filter?: ProjectFilter;
}

const tabRenderFns = {
  [ProjectsTab.own]: (id, filter) => (
    <ProjectsList filter={{ ...filter, ...filters[id] }} isEditable />
  ),
  [ProjectsTab.participant]: (id, filter) => (
    <ProjectsList filter={{ ...filter, ...filters[id] }} />
  ),
  [ProjectsTab.others]: (id, filter) => (
    <ProjectsList filter={{ ...filter, ...filters[id] }} />
  )
};

export const ProjectsListUser: React.FC<ProjectsListUserProps> = ({
  filter
}) => {
  return (
    <Tabs
      tabs={tabs}
      children={({ id }) => tabRenderFns[id]?.(id, filter) || null}
    />
  );
};
