import React from 'react';
import { ReactComponent as NoDataIcon } from '../../../../assets/img/noData.svg';
import s from './NoData.module.scss';

export interface NoDataProps {
  showIcon?: boolean;
  children?: React.ReactNode;
}

export const NoData: React.FC<NoDataProps> = ({
  showIcon = true,
  children
}) => {
  return (
    <div className={s.NoData}>
      {showIcon && <NoDataIcon />}
      <div className={s.NoData__content}>
        {children || 'Кажется, тут ничего нет'}
      </div>
    </div>
  );
};
