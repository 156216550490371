import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import {
  Breadcrumbs,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { ExternalPollsValidationSchema } from '../../../store/validationSchema';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  ExternalPollFormValues,
  ExternalPollsUtils
} from '../../../store/polls/ExternalPollsUtils';
import { BasePageInterface } from '../../../common/components/utils/BasePageInterface';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { tryAppendErrorId } from '../../../graphql/graphqlErrors';
import { getPollsPageBreadcrumbs } from '../list/PollsPage';
import { ExternalPolls } from '../../../store/polls/ExternalPolls';
import { ExternalPollForm } from '../forms/ExternalPollForm';
import { URL_KEY_TAB } from '../../../store/urlKeys';
import { routeLinks } from '../../routeLinks';

export function getPollCreatePageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    ...getPollsPageBreadcrumbs(),
    {
      name: 'Создание',
      path: '/polls/external/create'
    }
  ];
}

export default function PollCreatePage({ history }: BasePageInterface<{}>) {
  const createOp = useGraphqlMutation(ExternalPolls.CreateExternalPoll);
  const createOpRun = createOp.run;
  const handleSubmitForm = useCallback(
    (values: ExternalPollFormValues) => {
      const input = ExternalPollsUtils.fromCreateForm(values);
      createOpRun({ variables: { input } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          history.push(`/polls?${URL_KEY_TAB}=external`);
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            tryAppendErrorId(
              'Произошла ошибка при создании опроса. Попробуйте снова',
              err
            )
          );
        });
    },
    [history, createOpRun]
  );

  const breadcrumbs = useMemo(() => getPollCreatePageBreadcrumbs(), []);
  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle title={['Создание', routeLinks.polls.text]} showHeading />
        <Formik
          validationSchema={ExternalPollsValidationSchema}
          initialValues={ExternalPollsUtils.toForm()}
          onSubmit={handleSubmitForm}
        >
          {(props) => (
            <ExternalPollForm
              {...props}
              history={history}
              submissionLoading={createOp.loading}
            />
          )}
        </Formik>
      </LayoutContent>
    </PageLayout>
  );
}
