import React, { useEffect, useMemo, useState } from 'react';
import { FormikValues } from 'formik';
import { Entity } from '../../../../common/components/ui/AsyncSelect/entities/types';
import { FormAsyncEntitySelect } from '../../../../common/components/ui/Form/FormAsyncEntitySelect/FormAsyncEntitySelect';
import s from '../ProjectForm.module.scss';

export interface ProjectFormDirectorProps {
  name: string;
  values?: FormikValues;
}

export const ProjectFormDirector: React.FC<ProjectFormDirectorProps> = ({
  name,
  values
}) => {
  const [selectUpdateKey, setSelectUpdateKey] = useState(1);

  const participants = values?.participants;

  /**
   * фильтруем из возможных руководителей уже выбранных участников
   */
  const directorIdsFilter = useMemo(() => {
    return participants && participants.length > 0
      ? {
          exclude_ids: participants.map((p) => p.user_id)
        }
      : undefined;
  }, [participants]);

  useEffect(() => {
    setSelectUpdateKey((prev) => prev + 1);
  }, [directorIdsFilter]);

  return (
    <FormAsyncEntitySelect
      key={selectUpdateKey}
      className={s.ProjectForm__directorSelect}
      entity={Entity.usersPage}
      filter={directorIdsFilter}
      name={name}
      placeholder={'Выберите руководителя проекта'}
    />
  );
};
