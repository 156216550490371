import gql from 'graphql-tag';
import { FRAGMENT_PAGINATION_HEADER } from '../fragments/common';
import {
  FRAGMENT_INTERNSHIP_FULL,
  FRAGMENT_INTERNSHIP_SNIPPET
} from '../fragments/internships';

export const QUERY_GET_INTERNSHIPS = gql`
  query queryGetInternships($input: InternshipsPageInput) {
    internshipsPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...InternshipSnippet
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
  ${FRAGMENT_INTERNSHIP_SNIPPET}
`;

export const QUERY_GET_INTERNSHIP = gql`
  query queryGetInternship($id: ID!) {
    internship(internship_id: $id) {
      ...InternshipFull
    }
  }

  ${FRAGMENT_INTERNSHIP_FULL}
`;

export const QUERY_GET_INTERNSHIP_TEST_RESULT = gql`
  query queryGetInternshipTestResult {
    internshipTestResult
  }
`;
