import { Form, FormikProps } from 'formik';
import React from 'react';
import { IUseGraphqlWatchQueryOptions } from '@proscom/prostore-apollo-react';
import { FormCheckboxGroup } from '../../../common/components/ui/CheckboxGroup/CheckboxGroup';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import { QUERY_GET_COURSE_SUBJECTS_OPTIONS } from '../../../graphql/queries/entitiesOptionsQueries';
import { Loader } from '../../../common/components/ui/Loader/Loader';
import { Button } from '../../../common/components/ui/Button';
import { ReactComponent as IconArrowRight } from '../../../assets/img/icons/ArrowRight.svg';
import {
  CourseSubjectsPageInput,
  CourseSubjectType
} from '../../../graphql/types';
import {
  courseCompletionProofOptions,
  courseLearningFormatOptions,
  courseListenerCategoryOptions,
  coursePriceOptions
} from '../../../store/courses/CourseTest';
import { QuestionCard } from '../../../common/components/ui/QuestionCard/QuestionCard';
import { CoursesTestFormValues } from './CoursesTestPage';
import s from './CoursesTestPage.module.scss';

const courseSubjectsQuery: IUseGraphqlWatchQueryOptions<
  { input: CourseSubjectsPageInput },
  CourseSubjectType[]
> = {
  query: QUERY_GET_COURSE_SUBJECTS_OPTIONS,
  mapData: (data) => data.items?.list
};

export const CourseTestForm = React.memo(function CourseTestForm(
  props: FormikProps<CoursesTestFormValues>
) {
  const {
    state: { data: subjectsQueryData, loading: subjectsQueryLoading }
  } = useAuthGraphqlQuery({ queryOptions: courseSubjectsQuery });
  const subjectsOptions =
    subjectsQueryData?.map((s) => ({
      label: s.name,
      value: s.id
    })) || [];
  const canSubmit = props.isValid && props.dirty;
  return (
    <Form>
      {subjectsQueryLoading ? (
        <Loader />
      ) : (
        <>
          <div className={s.CourseTestForm__content}>
            <QuestionCard
              title={'Какой формат обучения вас интересует?'}
              required
            >
              <FormCheckboxGroup
                name={'learning_format'}
                options={courseLearningFormatOptions}
              />
            </QuestionCard>
            <QuestionCard
              title={'Курсы какой тематики вас интересуют?'}
              required
            >
              <FormCheckboxGroup
                name={'course_subject_ids'}
                options={subjectsOptions}
              />
            </QuestionCard>
            <QuestionCard
              title={
                'Укажите, какие курсы вас интересуют с точки зрения их стоимости?'
              }
            >
              <FormCheckboxGroup name={'price'} options={coursePriceOptions} />
            </QuestionCard>
            <QuestionCard
              title={
                'Вас интересуют курсы, после которых возможно получить документ, подтверждающий прохождение? Если да, то какой?'
              }
            >
              <FormCheckboxGroup
                name={'completion_proof'}
                options={courseCompletionProofOptions}
              />
            </QuestionCard>
            <QuestionCard
              title={'Курсы для какой категории слушателей вас интересуют?'}
            >
              <FormCheckboxGroup
                name={'listener_category'}
                options={courseListenerCategoryOptions}
              />
            </QuestionCard>
          </div>
          <div className={s.CourseTestForm__footer}>
            <Button
              className={s.CoursesTestPage__submitButton}
              iconRight={<IconArrowRight />}
              disabled={!canSubmit}
              type={'submit'}
            >
              Подобрать курс
            </Button>
            <div className={s.CourseTestForm__submitText}>
              {!canSubmit && 'Пожалуйста, ответьте на все вопросы'}
            </div>
          </div>
        </>
      )}
    </Form>
  );
});
