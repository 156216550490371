import { LocationStore } from '@proscom/prostore-react-router';
import { ApolloClientsManager } from '@proscom/prostore-apollo-react';
import { appHistory } from './appHistory';
import { createSimpleBrowserClient } from './graphql/client';
import { apiGraphqlUrl } from './config';
import {
  STORE_AUTH,
  STORE_LOCATION,
  STORE_PROD_CALENDAR,
  STORE_TARGET
} from './store/storeKeys';
import { urlTransformers } from './store/urlKeys';
import { AuthStore } from './store/AuthStore';
import { CLIENT_DEFAULT, CLIENT_SIMPLE } from './store/clientNames';
import { TargetStore } from './store/TargetStore';
import { ProdCalendarStore } from './store/ProdCalendarStore';

export const locationStore = new LocationStore({
  history: appHistory,
  transformers: urlTransformers
});

export const apolloClient = createSimpleBrowserClient({ uri: apiGraphqlUrl });

export const authStore = new AuthStore({
  localStorage,
  client: apolloClient
});
authStore.registerListener();

export const clientWithAuth = authStore.clientWithAuth;

export const apolloContext = new ApolloClientsManager({
  [CLIENT_DEFAULT]: clientWithAuth,
  [CLIENT_SIMPLE]: apolloClient
});

export const targetStore = new TargetStore();

export const prodCalendarStore = new ProdCalendarStore();

export const appStores = {
  [STORE_AUTH]: authStore,
  [STORE_LOCATION]: locationStore,
  [STORE_TARGET]: targetStore,
  [STORE_PROD_CALENDAR]: prodCalendarStore
};
