import React from 'react';
import {
  UserPageBlock,
  UserPageBlockVariant
} from '../../UserBlocks/UserPageBlock/UserPageBlock';
import { userFormNames } from '../../../../../store/users/UserConstants';
import { FormSection } from '../../Form/FormSection/FormSection';
import { FormTextEditor } from '../../Form/FormTextEditor/FormTextEditor';

export const EditAbout = () => {
  return (
    <UserPageBlock
      variant={UserPageBlockVariant.small}
      title={'Личные интересы и хобби'}
    >
      <FormSection>
        <FormTextEditor name={userFormNames.about} />
      </FormSection>
    </UserPageBlock>
  );
};
