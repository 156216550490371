import { Routes, RouteType } from '../../common/components/utils/Routes';
import { RequireAuth } from '../RequireAuth';
import FeedbackPage from './index/FeedbackPage';
import FeedbackSuccessPage from './success/FeedbackSuccessPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: RequireAuth(FeedbackPage)
  },
  {
    path: '/success',
    exact: true,
    component: RequireAuth(FeedbackSuccessPage)
  }
];

const FeedbackRoutes = Routes(routes);

export default FeedbackRoutes;
