import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import {
  TransformedQueryParams,
  URL_KEY_COMPLETION_PROOF,
  URL_KEY_HAS_DISCOUNT,
  URL_KEY_LEARNING_FORMAT,
  URL_KEY_LISTENER_CATEGORY,
  URL_KEY_PAGE,
  URL_KEY_PRICE_MAX,
  URL_KEY_PRICE_MIN,
  URL_KEY_SEARCH,
  URL_KEY_SHEET_VIEW,
  URL_KEY_SUBJECT_IDS,
  URL_KEY_TAG_IDS
} from '../../../store/urlKeys';
import { STORE_LOCATION } from '../../../store/storeKeys';
import { FilterSearch } from '../../../common/components/ui/Filter/FilterSearch';
import {
  CourseCompletionProof,
  CourseLearningFormat,
  CourseListenerCategory,
  CoursesFilter,
  Maybe
} from '../../../graphql/types';
import { Entity } from '../../../common/components/ui/AsyncSelect/entities/types';
import { FilterSelectAsyncMulti } from '../../../common/components/ui/Filter/FilterSelectAsyncMulti';
import { Select } from '../../../common/components/ui/Select/Select';
import {
  courseCompletionProofOptions,
  courseLearningFormatOptions,
  courseListenerCategoryOptions
} from '../../../store/courses/CourseTest';
import { TextField } from '../../../common/components/ui/TextField/TextField';
import { Checkbox } from '../../../common/components/ui/Checkbox/Checkbox';
import { SheetView } from '../../../common/types/sheet';
import s from './CoursesPage.module.scss';

export const useCoursesFilter = (onFilterChange?: () => void) => {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const query = (locationState.query || {}) as TransformedQueryParams;
  const changeQuery = locationStore.changeQuery;

  const view = query[URL_KEY_SHEET_VIEW];
  const isTileView = view === SheetView.GRID;

  const filter: CoursesFilter = {
    search: query[URL_KEY_SEARCH],
    course_subject_ids: query[URL_KEY_SUBJECT_IDS],
    tag_ids: query[URL_KEY_TAG_IDS],
    learning_format: query[URL_KEY_LEARNING_FORMAT] as Maybe<
      Array<CourseLearningFormat>
    >,
    completion_proof: query[URL_KEY_COMPLETION_PROOF] as Maybe<
      Array<CourseCompletionProof>
    >,
    listener_category: query[URL_KEY_LISTENER_CATEGORY] as Maybe<
      Array<CourseListenerCategory>
    >,
    price_min: query[URL_KEY_PRICE_MIN],
    price_max: query[URL_KEY_PRICE_MAX],
    has_discount: query[URL_KEY_HAS_DISCOUNT]
  };

  const handleFilterChange = useCallback(
    (key: string, valueConverter?: (value) => any) => {
      return (value) => {
        const val = valueConverter ? valueConverter(value) : value;

        changeQuery({
          [key]: val,
          [URL_KEY_PAGE]: 1
        });

        if (onFilterChange) {
          setTimeout(() => {
            onFilterChange();
          }, 1);
        }
      };
    },
    [changeQuery, onFilterChange]
  );

  useEffect(() => {
    if (isTileView) {
      handleFilterChange(URL_KEY_SUBJECT_IDS)(undefined);
    }
  }, [isTileView, handleFilterChange]);

  const filterComponent: React.ReactNode = (
    <div className={s.CoursesFilter}>
      <FilterSearch
        className={clsx(
          s.CoursesFilter__filter,
          s.CoursesFilter__filter_search
        )}
        classes={{
          filter: {
            content: s.CoursesFilter__filterSearchContent,
            label: s.CoursesFilter__filterSearchLabel
          }
        }}
        label={'Поиск'}
        value={filter.search || ''}
        onChange={handleFilterChange(URL_KEY_SEARCH)}
      />

      {!isTileView && (
        <FilterSelectAsyncMulti
          className={s.CoursesFilter__filter}
          entity={Entity.courseSubjectsPage}
          label={'Тематика'}
          value={filter.course_subject_ids ?? null}
          onChange={handleFilterChange(URL_KEY_SUBJECT_IDS)}
        />
      )}
      <FilterSelectAsyncMulti
        className={s.CoursesFilter__filter}
        entity={Entity.tagsPage}
        label={'Теги'}
        value={filter.tag_ids ?? null}
        onChange={handleFilterChange(URL_KEY_TAG_IDS)}
      />
      <Select
        className={s.CoursesFilter__filter}
        label={'Формат обучения'}
        value={filter.learning_format as string | string[]}
        options={courseLearningFormatOptions}
        isMulti
        onChange={handleFilterChange(URL_KEY_LEARNING_FORMAT)}
      />
      <Select
        className={s.CoursesFilter__filter}
        label={'Доказательство прохождения'}
        value={filter.completion_proof as string | string[]}
        options={courseCompletionProofOptions}
        isMulti
        onChange={handleFilterChange(URL_KEY_COMPLETION_PROOF)}
      />
      <Select
        className={s.CoursesFilter__filter}
        label={'Категория слушателя'}
        value={filter.listener_category as string | string[]}
        options={courseListenerCategoryOptions}
        isMulti
        onChange={handleFilterChange(URL_KEY_LISTENER_CATEGORY)}
      />

      <div className={s.CoursesFilter__priceRange}>
        <TextField
          className={s.CoursesFilter__priceField}
          type={'number'}
          label={'Минимальная цена'}
          value={filter.price_min || ''}
          placeholder={'Минимальная цена'}
          onChange={handleFilterChange(URL_KEY_PRICE_MIN)}
        />
        <TextField
          className={s.CoursesFilter__priceField}
          type={'number'}
          label={'Максимальная цена'}
          value={filter.price_max || ''}
          placeholder={'Максимальная цена'}
          onChange={handleFilterChange(URL_KEY_PRICE_MAX)}
        />
        <div className={s.CoursesFilter__hasDiscount}>
          <Checkbox
            label={'Есть скидка для сотрудников'}
            checked={Boolean(filter.has_discount)}
            onChange={handleFilterChange(URL_KEY_HAS_DISCOUNT)}
          />
        </div>
      </div>
    </div>
  );

  return [filterComponent, filter] as const;
};
