import React from 'react';
import clsx from 'clsx';
import { UserType } from '../../../../graphql/types';
import { useFileSrc } from '../../../hooks/useFileSrc';
import { UserUtils } from '../../../../store/users/UserUtils';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { Avatar, AvatarSize } from '../Avatar/Avatar';
import s from './UserStack.module.scss';

export interface UserStackParticipantProps
  extends Pick<UserType, 'id' | 'avatar_file_id' | 'c_avatar_file_id'> {}

export interface UserStackItemClasses {
  root?: string;
  avatar?: string;
  amount?: string;
}

export interface UserStackItemProps {
  className?: string;
  classes?: UserStackItemClasses;
  user?: UserStackParticipantProps;
  usersAmount?: number;
  size?: AvatarSize;
}

export const UserStackItem: React.FC<UserStackItemProps> = ({
  className,
  classes,
  user,
  usersAmount,
  size = AvatarSize.extraSmall
}) => {
  const avaSrc = useFileSrc(UserUtils.getAvatar(user), FileTypeEnum.avatar);

  return (
    <div
      className={clsx(
        s.UserStackItem,
        s[`UserStackItem_${size}`],
        className,
        classes?.root
      )}
    >
      {!usersAmount && (
        <Avatar
          className={clsx(s.UserStackItem__avatar, classes?.avatar)}
          src={avaSrc}
          size={size}
        />
      )}
      {usersAmount && (
        <div
          className={clsx(
            s.UserStackItem__amount,
            s[`UserStackItem_amount_${size}`],
            classes?.amount
          )}
        >
          {usersAmount < 100 ? `+${usersAmount}` : '99+'}
        </div>
      )}
    </div>
  );
};

export interface UserStackClasses {
  root?: string;
  item?: UserStackItemClasses;
}

export interface UserStackProps {
  className?: string;
  classes?: UserStackClasses;
  topUsers: UserStackParticipantProps[];
  restUsersAmount?: number;
}

export const UserStack: React.FC<UserStackProps> = ({
  className,
  classes,
  topUsers,
  restUsersAmount = 0
}) => {
  return (
    <div className={clsx(s.UserStack, className, classes?.root)}>
      {topUsers.map((user, iUser) => (
        <UserStackItem
          key={iUser}
          className={s.UserStack__item}
          classes={classes?.item}
          user={user}
        />
      ))}
      {restUsersAmount > 0 && (
        <UserStackItem
          className={s.UserStack__item}
          classes={classes?.item}
          usersAmount={restUsersAmount}
        />
      )}
    </div>
  );
};
