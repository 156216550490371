import React, { useCallback, useMemo } from 'react';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';
import { Formik } from 'formik';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  Breadcrumbs,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { getCoursesPageBreadcrumbs } from '../list/CoursesPage';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { Courses } from '../../../store/courses/Courses';
import { CourseForm } from '../CourseForm/CourseForm';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { CourseUtils } from '../../../store/courses/CourseUtils';
import { routeLinks } from '../../routeLinks';
import { CourseStatus, CourseTypesEnum } from '../../../graphql/types';
import { CourseTypeForm } from '../types';
import {
  CoursesDraftValidationSchema,
  CoursesPublicValidationSchema,
  lazyValidation
} from '../../../store/validationSchema';
import s from '../edit/CourseEditPage.module.scss';

export interface CourseCreatePageProps extends RouteComponentProps {
  history: AppHistoryType;
}

export function getCourseCreatePageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    ...getCoursesPageBreadcrumbs(),
    {
      name: 'Создание',
      path: `${routeLinks.courses.to}/create`
    }
  ];
}

export default function CourseCreatePage({ history }: CourseCreatePageProps) {
  const createAnnounceCourseMutation = useGraphqlMutation(
    Courses.createAnnounceCourse
  );
  const createLongreadCourseMutation = useGraphqlMutation(
    Courses.createLongreadCourse
  );
  const loading =
    createAnnounceCourseMutation.loading ||
    createLongreadCourseMutation.loading;

  const handleSubmit = useCallback(
    (data: CourseTypeForm) => {
      let op: Promise<any>;
      if (data.type === CourseTypesEnum.Longread) {
        op = createLongreadCourseMutation.run({
          variables: { input: CourseUtils.fromLongreadCreateForm(data) }
        });
      } else {
        op = createAnnounceCourseMutation.run({
          variables: { input: CourseUtils.fromAnnounceCreateForm(data) }
        });
      }
      op.then((result) => {
        if (!result.data?.result) {
          throw new Error('UnexpectedResult');
        }
        toast.success('Курс добавлен');
        history.push(`${routeLinks.courses.to}/${result.data.result.id}`);
      }).catch((err) => {
        if (err instanceof AsyncSingletonError) return;
        handleDefaultError(
          err,
          'Произошла ошибка при создании курса. Попробуйте снова'
        );
      });
    },
    [createAnnounceCourseMutation, createLongreadCourseMutation, history]
  );

  const handleCancel = useCallback(() => {
    if (window.confirm('Вы действительно хотите отменить редактирование?')) {
      history.goBack(routeLinks.courses.to);
    }
  }, [history]);

  const breadcrumbs = useMemo(() => getCourseCreatePageBreadcrumbs(), []);

  return (
    <PageLayout
      breadcrumbs={<Breadcrumbs items={breadcrumbs} />}
      contentClassName={s.CourseEditPage}
    >
      <LayoutContent>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={8}>
            <PageTitle
              className={s.CourseEditPage__pageTitle}
              title={'Создание курса'}
              showHeading
            />
            <Formik
              initialValues={CourseUtils.toCreateForm()}
              onSubmit={handleSubmit}
              validationSchema={lazyValidation((values) => {
                const isDraft = values?.status === CourseStatus.Draft;
                return isDraft
                  ? CoursesDraftValidationSchema
                  : CoursesPublicValidationSchema;
              })}
            >
              {(props) => (
                <CourseForm
                  submissionLoading={loading}
                  onCancel={handleCancel}
                  {...props}
                />
              )}
            </Formik>
          </LayoutGrid.GridItem>
        </LayoutGrid>
      </LayoutContent>
    </PageLayout>
  );
}
