import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { useLocationQuery } from '../../../../common/hooks/utils/useLocationQuery';
import { URL_KEY_PAGE } from '../../../../store/urlKeys';
import { useProjects } from '../../../../graphql/hooks/projects/useProjects';
import { QueryPagination } from '../../../../common/components/ui/QueryPagination/QueryPagination';
import { routeLinks } from '../../../routeLinks';
import { queryLoader } from '../../../../common/components/utils/queryLoader';
import { NoData } from '../../../../common/components/ui/NoData/NoData';
import { LayoutGrid } from '../../../../common/components/Layout/Layout';
import { CardProjectPreview } from '../../../../common/components/ui/Cards/CardProjectPreview/CardProjectPreview';
import { ProjectsSection } from '../ProjectsSection/ProjectsSection';
import { ProjectFilter } from '../../../../graphql/types';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import { useGraphqlMutation } from '../../../../common/hooks/utils/useGraphqlMutation';
import { Projects } from '../../../../store/projects/Projects';
import s from './ProjectsList.module.scss';

export interface ProjectsListProps {
  filter?: ProjectFilter;
  isEditable?: boolean;
}

export const ProjectsList: React.FC<ProjectsListProps> = ({
  filter,
  isEditable
}) => {
  const query = useLocationQuery([URL_KEY_PAGE] as const);
  const deleteProject = useGraphqlMutation(Projects.deleteProject);
  const deleteProjectRun = deleteProject.run;

  const page = query[URL_KEY_PAGE] || 0;
  const projectsQuery = useProjects({
    filter,
    pagination: { page }
  });
  const projects = projectsQuery.state.data?.list;
  const header = projectsQuery.state.data?.header;
  const hasProjects = projects && projects.length > 0;

  const handleDeleteClick = useCallback(
    (id) => {
      if (window.confirm('Вы действительно хотите удалить проект?')) {
        deleteProjectRun({ variables: { id } })
          .then((result) => {
            if (result.data?.result) {
              toast.success('Проект удален');
            }
          })
          .catch((err) => {
            if (err instanceof AsyncSingletonError) return;
            handleDefaultError(err, 'Произошла ошибка при удалении проекта');
          });
      }
    },
    [deleteProjectRun]
  );

  return (
    <div className={s.ProjectsList}>
      <ProjectsSection>
        {queryLoader(projectsQuery) || (!hasProjects && <NoData />) || (
          <LayoutGrid className={s.ProjectsList__grid}>
            {projects?.map((item) => (
              <div key={item.id} className={s.ProjectsList__gridItem}>
                <CardProjectPreview
                  {...item}
                  to={`${routeLinks.projects.to}/${item.id}`}
                  isEditable={isEditable}
                  onDeleteClick={handleDeleteClick}
                />
              </div>
            ))}
          </LayoutGrid>
        )}
      </ProjectsSection>

      {hasProjects && header && (
        <QueryPagination header={header} pathname={routeLinks.projects.to} />
      )}
    </div>
  );
};
