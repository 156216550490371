import React from 'react';
import {
  Button,
  ButtonBaseColor,
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { routeLinks } from '../../routeLinks';
import { ProjectType } from '../../../graphql/types';
import s from './ProjectActions.module.scss';

export interface ProjectActionsProps {
  project?: ProjectType;
  canEdit?: boolean;
  canParticipate?: boolean;
  isParticipant?: boolean;
  deleteLoading?: boolean;
  sendApplicationLoading?: boolean;
  quitLoading?: boolean;
  onDeleteClick?: () => void;
  onSendApplicationClick?: () => void;
  onQuitProjectClick?: () => void;
}

export const ProjectActions: React.FC<ProjectActionsProps> = ({
  project,
  canEdit,
  canParticipate,
  isParticipant,
  deleteLoading,
  sendApplicationLoading,
  quitLoading,
  onDeleteClick,
  onSendApplicationClick,
  onQuitProjectClick
}) => {
  const projectId = project?.id;

  return (
    <div className={s.ProjectActions}>
      {canEdit && (
        <div className={s.ProjectActions__edit}>
          <ButtonLink
            to={`${routeLinks.projects.to}/${projectId}/edit`}
            className={s.ActionButton}
            size={ButtonSize.small}
            variant={ButtonVariant.secondary}
          >
            Редактировать
          </ButtonLink>
          <Button
            className={s.ActionButton}
            size={ButtonSize.small}
            variant={ButtonVariant.secondary}
            baseColor={ButtonBaseColor.red}
            onClick={onDeleteClick}
            loading={deleteLoading}
          >
            Удалить
          </Button>
        </div>
      )}
      <div className={s.ProjectActions__participate}>
        {!isParticipant && canParticipate && (
          <Button
            className={s.ActionButton}
            size={ButtonSize.small}
            variant={ButtonVariant.secondary}
            onClick={onSendApplicationClick}
            loading={sendApplicationLoading || quitLoading}
          >
            Стать участником
          </Button>
        )}
        {isParticipant && (
          <Button
            className={s.ActionButton}
            size={ButtonSize.small}
            variant={ButtonVariant.secondary}
            baseColor={ButtonBaseColor.red}
            onClick={onQuitProjectClick}
            loading={quitLoading || sendApplicationLoading}
          >
            Выйти из проекта
          </Button>
        )}
      </div>
    </div>
  );
};
