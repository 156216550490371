import {
  defineQueryTransformers,
  ExtractTransformedQueryParams
} from '@proscom/prostore-react-router';
import { stringArrayTransformer } from '../utils/url/stringArrayTransformer';
import { pageTransformer } from '../utils/url/pageTransformer';
import { stringTransformer } from '../utils/url/stringTransformer';
import { startDateTransformer } from '../utils/url/startDateTransformer';
import { endDateTransformer } from '../utils/url/endDateTransformer';
import { booleanTransformer, numberTransformer } from '../utils/url';

// Рекомендуется перечислять все query-параметры, используемые в приложении,
// в виде констант и использовать их только через эти константы.
// Так легче находить места в коде, где они используются.

export const URL_KEY_PAGE = 'page' as const;
export const URL_KEY_TAB = 'tab' as const;
export const URL_KEY_DATE_START = 'dateStart' as const;
export const URL_KEY_DATE_END = 'dateEnd' as const;

// FILTERS
export const URL_KEY_SEARCH = 'search' as const;
export const URL_KEY_FILTER_SKILL_IDS = 'skill_ids' as const;
export const URL_KEY_CATEGORY = 'category' as const;
export const URL_KEY_SOURCE = 'source' as const;
export const URL_KEY_SHEET_VIEW = 'sheet_view' as const;
export const URL_KEY_FILTER_CATEGORIES = 'categories' as const;
export const URL_KEY_FILTER_KINDS = 'kinds' as const;
export const URL_KEY_SUBJECT_IDS = 'subject_ids' as const;
export const URL_KEY_TAG_IDS = 'tag_ids' as const;
export const URL_KEY_ACTIVE_STATUS = 'status' as const;
export const URL_KEY_DIRECTOR_ID = 'director_id' as const;
export const URL_KEY_UNIT_ID = 'unit_id' as const;
export const URL_KEY_TENSES = 'tenses' as const;
export const URL_KEY_PROJECT_STATUS = 'project_status' as const;
export const URL_KEY_PARTICIPANT = 'participant' as const;
export const URL_KEY_LEARNING_FORMAT = 'learning_format' as const;
export const URL_KEY_COMPLETION_PROOF = 'completion_proof' as const;
export const URL_KEY_LISTENER_CATEGORY = 'listener_category' as const;
export const URL_KEY_PRICE_MIN = 'price_min' as const;
export const URL_KEY_PRICE_MAX = 'price_max' as const;
export const URL_KEY_HAS_DISCOUNT = 'has_discount' as const;
export const URL_KEY_TRAINEE_CATEGORY = 'trainee_category' as const;
export const URL_KEY_PURPOSE = 'purpose' as const;
export const URL_KEY_DURATION = 'duration' as const;
export const URL_KEY_LOCATION = 'location' as const;
export const URL_KEY_CONTEST = 'contest' as const;
export const URL_KEY_FINANCIAL_CONDITIONS = 'financial_conditions' as const;
export const URL_KEY_ID = 'id' as const;
export const URL_KEY_FEEDBACK_ID = 'feedback_id' as const;

// Трансформеры определяют, как парсить и сериализовать query-параметры из адресной строки
export const urlTransformers = defineQueryTransformers({
  [URL_KEY_PAGE]: pageTransformer,
  [URL_KEY_TAB]: stringTransformer,
  [URL_KEY_SEARCH]: stringTransformer,
  [URL_KEY_CATEGORY]: stringTransformer,
  [URL_KEY_SOURCE]: stringTransformer,
  [URL_KEY_SHEET_VIEW]: stringTransformer,
  [URL_KEY_FILTER_SKILL_IDS]: stringArrayTransformer,
  [URL_KEY_FILTER_CATEGORIES]: stringArrayTransformer,
  [URL_KEY_FILTER_KINDS]: stringArrayTransformer,
  [URL_KEY_SUBJECT_IDS]: stringArrayTransformer,
  [URL_KEY_TAG_IDS]: stringArrayTransformer,
  [URL_KEY_ACTIVE_STATUS]: booleanTransformer,
  [URL_KEY_DIRECTOR_ID]: stringArrayTransformer,
  [URL_KEY_UNIT_ID]: stringArrayTransformer,
  [URL_KEY_DATE_START]: startDateTransformer,
  [URL_KEY_DATE_END]: endDateTransformer,
  [URL_KEY_TENSES]: stringTransformer,
  [URL_KEY_PROJECT_STATUS]: stringTransformer,
  [URL_KEY_PARTICIPANT]: stringTransformer,
  [URL_KEY_TAG_IDS]: stringArrayTransformer,
  [URL_KEY_LEARNING_FORMAT]: stringArrayTransformer,
  [URL_KEY_COMPLETION_PROOF]: stringArrayTransformer,
  [URL_KEY_LISTENER_CATEGORY]: stringArrayTransformer,
  [URL_KEY_TRAINEE_CATEGORY]: stringArrayTransformer,
  [URL_KEY_PURPOSE]: stringArrayTransformer,
  [URL_KEY_DURATION]: stringArrayTransformer,
  [URL_KEY_LOCATION]: stringArrayTransformer,
  [URL_KEY_CONTEST]: stringArrayTransformer,
  [URL_KEY_FINANCIAL_CONDITIONS]: stringArrayTransformer,
  [URL_KEY_PRICE_MIN]: numberTransformer,
  [URL_KEY_PRICE_MAX]: numberTransformer,
  [URL_KEY_HAS_DISCOUNT]: booleanTransformer,
  [URL_KEY_FEEDBACK_ID]: stringTransformer
});

type UrlTransformersType = typeof urlTransformers;

export type TransformedQueryParams = ExtractTransformedQueryParams<UrlTransformersType>;
