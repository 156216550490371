import gql from 'graphql-tag';
import {
  FRAGMENT_ARTICLE_FULL,
  FRAGMENT_ARTICLE_SNIPPET
} from '../fragments/articles';
import { FRAGMENT_PAGINATION_HEADER } from '../fragments/common';

export const QUERY_GET_ARTICLES_PAGE = gql`
  query queryGetArticlesPage($input: ArticlePageInput) {
    articlesPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...ArticleSnippet
      }
    }
  }

  ${FRAGMENT_ARTICLE_SNIPPET}
  ${FRAGMENT_PAGINATION_HEADER}
`;

export const QUERY_GET_ARTICLE = gql`
  query queryGetArticle($id: ID!) {
    article(id: $id) {
      ...ArticleFull
    }
  }

  ${FRAGMENT_ARTICLE_FULL}
`;
