import { onError } from 'apollo-link-error';
import { switchMap } from 'rxjs/operators';
import { from, of, throwError } from 'rxjs';
import { AuthStore } from '../../store/AuthStore';
import { wrapForwardOperation } from './wrapForwardOperation';

export const createCheckAuthenticationErrorLink = (authStore: AuthStore) =>
  onError(
    ({ graphQLErrors, networkError, response, operation, forward }): any => {
      if (graphQLErrors && graphQLErrors.length > 0) {
        const errorOriginalError =
          graphQLErrors[0]?.extensions?.originalError?.error;
        const errorOriginalMessage =
          graphQLErrors[0]?.extensions?.originalError?.message;
        const errorMessage = graphQLErrors[0]?.message;
        const errorMsg = errorMessage || errorOriginalMessage;

        if (
          errorOriginalError === 'Unauthorized' ||
          errorMsg === 'Invalid Token'
        ) {
          if (!authStore.canRefreshToken()) {
            authStore._setError();
            return;
          }

          return from(authStore.refreshToken()).pipe(
            switchMap((state) => {
              if (state && state.accessToken) {
                return wrapForwardOperation(forward, operation);
              } else if (networkError) {
                return throwError(networkError);
              } else {
                return of(response);
              }
            })
          );
        }
      }
    }
  );
