import React from 'react';
import { ResponsiveObject } from 'react-slick';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { Slider } from '../../../common/components/ui/Slider/Slider';
import sliderPic_1 from '../../../assets/img/enrollee/about/1.jpg';
import sliderPic_2 from '../../../assets/img/enrollee/about/2.jpg';
import sliderPic_3 from '../../../assets/img/enrollee/about/3.jpg';
import sliderPic_4 from '../../../assets/img/enrollee/about/4.jpg';
import sliderPic_5 from '../../../assets/img/enrollee/about/5.jpg';
import sliderPic_6 from '../../../assets/img/enrollee/about/6.jpg';
import sliderPic_7 from '../../../assets/img/enrollee/about/7.jpg';
import { BREAKPOINT_VALUES, Breakpoints } from '../../../constants';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { ImagePreview } from '../../../common/components/ui/ImagePreview/ImagePreview';
import s from './AboutHR.module.scss';

const pics = [
  sliderPic_1,
  sliderPic_2,
  sliderPic_3,
  sliderPic_4,
  sliderPic_5,
  sliderPic_6,
  sliderPic_7
];

const sliderResponsive: ResponsiveObject[] = [
  {
    breakpoint: BREAKPOINT_VALUES[Breakpoints.tablet] - 1,
    settings: {
      slidesToShow: 1,
      arrows: false,
      dots: true
    }
  }
];

const AboutSlider = () => {
  return (
    <Slider
      className={s.AboutSlider}
      classes={{
        slick: s.AboutSlider__slick,
        buttonPrev: s.AboutSlider__prevButton,
        buttonNext: s.AboutSlider__nextButton
      }}
      config={{
        slidesToShow: 1,
        arrows: true,
        responsive: sliderResponsive
      }}
    >
      {pics.map((pic, iPic) => (
        <ImagePreview
          key={iPic}
          className={s.AboutSlider__pic}
          classes={{
            image: s.AboutSlider__picImage
          }}
          imageSrc={pic}
        />
      ))}
    </Slider>
  );
};

export const AboutHR = () => {
  return (
    <LayoutContent id={'about'} className={s.AboutHR}>
      <div className={s.AboutHR__header}>
        <LayoutGrid className={s.AboutHR__grid}>
          <LayoutGrid.GridItem cols={6}>
            <div className={s.Enrollee__column}>
              <Title variant={TitleVariant.h2} className={s.Enrollee__title}>
                Видение
              </Title>
              <div className={s.Enrollee__text}>
                Исследовательский университет мирового класса, ключевой центр
                интеграции России с мировым научно-техническим сообществом и
                высокотехнологичной индустрией, а также точка роста
                отечественной науки, технологий и источник человеческого
                капитала.
              </div>
            </div>
          </LayoutGrid.GridItem>
          <LayoutGrid.GridItem cols={6}>
            <div className={s.Enrollee__column}>
              <Title variant={TitleVariant.h2} className={s.Enrollee__title}>
                Миссия МФТИ
              </Title>
              <div className={s.Enrollee__text}>
                Быть мировым центром научной и технологической мысли; служить
                источником новых знаний и передовых технологий, передавая их в
                экономику России; готовить лидеров, способных отвечать на
                ключевые научно-технические вызовы и определять успех страны и
                человечества в ХХI веке.
              </div>
            </div>
          </LayoutGrid.GridItem>
        </LayoutGrid>
      </div>

      <div className={s.AboutHR__slider}>
        <AboutSlider />
      </div>
    </LayoutContent>
  );
};
