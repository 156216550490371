import { attachValueToObservable } from '@proscom/prostore';
import { auditTime, map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { BREAKPOINT_VALUES, Breakpoints } from '../constants';
import { mapObservableWithValue } from '../utils/helpers/mapObservableWithValue';

export function getBreakpoint(windowWidth) {
  const bps = Object.keys(Breakpoints);
  for (const bp of bps.reverse()) {
    if (windowWidth >= BREAKPOINT_VALUES[bp]) {
      return bp;
    }
  }
  return BREAKPOINT_VALUES[bps[0]];
}

function createWindowSizeStore(getWindowSize) {
  const obs$ = fromEvent(window, 'resize').pipe(
    auditTime(100),
    map(() => getWindowSize({ window }))
  );

  return attachValueToObservable(obs$, () => getWindowSize({ window }));
}

function getWindowSize(ws: Window) {
  const window = ws.window;
  const breakpoint = getBreakpoint(window.innerWidth);

  return {
    window,
    width: window.innerWidth,
    height: window.innerHeight,
    breakpoint,
    isMobile: breakpoint === Breakpoints.mobile,
    isTablet: breakpoint === Breakpoints.tablet,
    isDesktop: breakpoint === Breakpoints.desktop
  };
}

export const windowSize$ = createWindowSizeStore(getWindowSize);
export const isMobile$ = mapObservableWithValue(
  windowSize$,
  (ws) => ws.isMobile
);
export const isTablet$ = mapObservableWithValue(
  windowSize$,
  (ws) => ws.isTablet
);
export const isDesktop$ = mapObservableWithValue(
  windowSize$,
  (ws) => ws.isDesktop
);
