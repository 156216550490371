import gql from 'graphql-tag';
import {
  FRAGMENT_DOCUMENT,
  FRAGMENT_DOCUMENT_SECTION
} from '../fragments/documents';

export const MUTATION_CREATE_DOCUMENT_SECTION = gql`
  mutation mutationCreateDocumentSection($input: DocumentSectionCreateInput!) {
    result: createDocumentSection(input: $input) {
      ...DocumentSectionProps
      documents {
        ...DocumentProps
      }
    }
  }

  ${FRAGMENT_DOCUMENT_SECTION}
  ${FRAGMENT_DOCUMENT}
`;

export const MUTATION_UPDATE_DOCUMENT_SECTION = gql`
  mutation mutationUpdateDocumentSection($input: DocumentSectionUpdateInput!) {
    result: updateDocumentSection(input: $input) {
      ...DocumentSectionProps
      documents {
        ...DocumentProps
      }
    }
  }

  ${FRAGMENT_DOCUMENT_SECTION}
  ${FRAGMENT_DOCUMENT}
`;

export const MUTATION_DELETE_DOCUMENT_SECTION = gql`
  mutation mutationDeleteDocumentSection($id: ID!) {
    result: deleteDocumentSection(document_section_id: $id)
  }
`;

export const MUTATION_CREATE_DOCUMENT = gql`
  mutation mutationCreateDocument($input: DocumentCreateInput!) {
    result: createDocument(input: $input) {
      ...DocumentProps
    }
  }

  ${FRAGMENT_DOCUMENT}
`;

export const MUTATION_UPDATE_DOCUMENT = gql`
  mutation mutationUpdateDocument($input: DocumentUpdateInput!) {
    result: updateDocument(input: $input) {
      ...DocumentProps
    }
  }

  ${FRAGMENT_DOCUMENT}
`;

export const MUTATION_DELETE_DOCUMENT = gql`
  mutation mutationDeleteDocument($id: ID!) {
    result: deleteDocument(document_id: $id)
  }
`;
