import React, { useEffect } from 'react';
import { useStore } from '@proscom/prostore-react';
import { Profile } from '../Profile/Profile';
import { ButtonLink } from '../Button';
import { AuthStore } from '../../../../store/AuthStore';
import { STORE_AUTH } from '../../../../store/storeKeys';
import { Loader } from '../Loader/Loader';
import { useLoginOps } from '../../../hooks/useLoginOps';
import { routeLinks } from '../../../../routes/routeLinks';
import { useUser } from '../../../../graphql/hooks/useUser';
import { useFileSrc } from '../../../hooks/useFileSrc';
import { UserUtils } from '../../../../store/users/UserUtils';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { UserType } from '../../../../graphql/types';
import s from './Login.module.scss';

export interface LoginProps {
  onUserChange: (user: UserType | null) => void;
}

export const Login: React.FC<LoginProps> = ({ onUserChange }) => {
  const [auth, authStore] = useStore<AuthStore>(STORE_AUTH);
  const ops = useLoginOps(auth, authStore);
  const currentUser = auth?.authData?.user;

  const userQuery = useUser(currentUser?.id);
  const user = userQuery.state.data;
  const avaSrc = useFileSrc(UserUtils.getAvatar(user), FileTypeEnum.avatar);

  useEffect(() => {
    onUserChange(currentUser || null);
  }, [currentUser, onUserChange]);

  return ops.loginOp.loading || ops.logoutOp.loading ? (
    <Loader />
  ) : currentUser ? (
    <Profile
      actions={[
        {
          label: 'Профиль',
          to: routeLinks.profile.to
        },
        {
          label: routeLinks.settings.text,
          to: routeLinks.settings.to
        },
        {
          label: 'Выход',
          onClick: ops.logoutOp.run
        }
      ]}
      src={avaSrc}
    />
  ) : (
    <ButtonLink
      // onClick={ops.handler?.handleSocialAuth(getDefaultAuthProvider())}
      className={s.Login__button}
      to={routeLinks.login.to}
    >
      Войти в сервис
    </ButtonLink>
  );
};
