import gql from 'graphql-tag';
import { FRAGMENT_SYSTEM_LINK } from '../fragments/systemLinks';

export const MUTATION_CREATE_SYSTEM_LINK = gql`
  mutation mutationCreateSystemLink($input: SystemLinkCreateInput!) {
    result: createSystemLink(input: $input) {
      ...SystemLinkProps
    }
  }

  ${FRAGMENT_SYSTEM_LINK}
`;

export const MUTATION_UPDATE_SYSTEM_LINK = gql`
  mutation mutationUpdateSystemLink($input: SystemLinkUpdateInput!) {
    result: updateSystemLink(input: $input) {
      ...SystemLinkProps
    }
  }

  ${FRAGMENT_SYSTEM_LINK}
`;

export const MUTATION_DELETE_SYSTEM_LINK = gql`
  mutation mutationDeleteSystemLink($id: ID!) {
    result: deleteSystemLink(system_link_id: $id)
  }
`;
