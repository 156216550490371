import React from 'react';
import clsx from 'clsx';
import { Title, TitleVariant } from '../Title/Title';
import { FileUploadButton } from '../UploadField/FileUploadButton';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { useFileSrc } from '../../../hooks/useFileSrc';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import {
  CoverLoaderPreview,
  CoverLoaderPreviewImageProps
} from './CoverLoaderPreview';
import s from './CoverLoader.module.scss';

export interface CoverLoaderClasses {
  root?: string;
  title?: string;
  content?: string;
  uploadButton?: string;
  helperText?: string;
}

export interface CoverLoaderProps {
  className?: string;
  classes?: CoverLoaderClasses;
  coverImageId?: string;
  fileType: FileTypeEnum;
  label?: string;
  buttonText?: string;
  errorMessage?: string;
  hintMessage?: string;
  imagePreviewProps?: CoverLoaderPreviewImageProps;
  onImageUploaded: (id: string, file: File) => void;
  onImageRemoved: () => void;
}

export const CoverLoader: React.FC<CoverLoaderProps> = ({
  className,
  classes,
  coverImageId,
  fileType,
  label = 'Обложка',
  buttonText = 'Загрузить обложку',
  errorMessage,
  hintMessage,
  imagePreviewProps,
  onImageUploaded,
  onImageRemoved
}) => {
  const imageSrc = useFileSrc(coverImageId, fileType);

  return (
    <div className={clsx(s.CoverLoader, classes?.root, className)}>
      {label && (
        <div className={s.CoverLoader__head}>
          <Title
            className={clsx(s.CoverLoader__title, classes?.title)}
            variant={TitleVariant.h3}
            title={label}
          />
        </div>
      )}
      <div className={clsx(s.CoverLoader__content, classes?.content)}>
        <CoverLoaderPreview
          imageSrc={imageSrc}
          onRemoveClick={onImageRemoved}
          previewProps={imagePreviewProps}
        />
        {!imageSrc && (
          <FileUploadButton
            className={clsx(s.CoverLoader__uploadButton, classes?.uploadButton)}
            fileType={fileType}
            onFileUploaded={onImageUploaded}
            buttonText={buttonText}
          />
        )}
      </div>
      <HelpText
        className={classes?.helperText}
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </div>
  );
};
