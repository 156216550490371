import React from 'react';
import { PageTitle } from '../../../../common/components/ui/PageTitle/PageTitle';
import { ContentBlock } from '../ContentBlock/ContentBlock';
import { PersonnelReserveType } from '../../../../graphql/types';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { useFileSrc } from '../../../../common/hooks/useFileSrc';
import { ImagePreview } from '../../../../common/components/ui/ImagePreview/ImagePreview';
import s from './Reserve.module.scss';

export interface ReserveProps extends PersonnelReserveType {}

export const Reserve: React.FC<ReserveProps> = ({
  name,
  description,
  cover_file_id
}) => {
  const coverImgSrc = useFileSrc(
    cover_file_id,
    FileTypeEnum.personnel_reserve_cover
  );

  return (
    <div className={s.Reserve}>
      <PageTitle title={name} showHeading />
      <ContentBlock
        content={
          <ImagePreview
            className={s.Reserve__cover}
            classes={{
              image: s.Reserve__coverImage
            }}
            imageSrc={coverImgSrc}
            showBackfaceImage
          />
        }
      />
      <ContentBlock
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: description || ''
            }}
          />
        }
      />
    </div>
  );
};
