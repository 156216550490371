import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import {
  Breadcrumbs,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { BasePageInterface } from '../../../common/components/utils/BasePageInterface';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { tryAppendErrorId } from '../../../graphql/graphqlErrors';
import { getReservesPageBreadcrumbs } from '../list/ReservesPage';
import { routeLinks } from '../../routeLinks';
import { PersonnelReserve } from '../../../store/personnelReserve/PersonnelReserve';
import {
  PersonnelReserveFormValues,
  PersonnelReserveUtils
} from '../../../store/personnelReserve/PersonnelReserveUtils';
import { ReserveForm } from '../ReserveForm/ReserveForm';
import { PersonnelReserveValidationSchema } from '../../../store/validationSchema';

export function getReserveCreatePageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    ...getReservesPageBreadcrumbs(),
    {
      name: 'Создание',
      path: `${routeLinks.reserve.to}/create`
    }
  ];
}

export default function ReserveCreatePage({ history }: BasePageInterface<{}>) {
  const createOp = useGraphqlMutation(PersonnelReserve.createReserve);
  const createOpRun = createOp.run;
  const handleSubmitForm = useCallback(
    (values: PersonnelReserveFormValues) => {
      const input = PersonnelReserveUtils.fromCreateForm(values);

      createOpRun({ variables: { input } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          history.push(`${routeLinks.reserve.to}/${result.data.result.id}`);
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            tryAppendErrorId(
              'Произошла ошибка при создании кадрового резерва. Попробуйте снова',
              err
            )
          );
        });
    },
    [history, createOpRun]
  );

  const breadcrumbs = useMemo(() => getReserveCreatePageBreadcrumbs(), []);

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle title={['Создание', 'Кадровый резерв']} showHeading />
        <Formik
          validationSchema={PersonnelReserveValidationSchema}
          initialValues={PersonnelReserveUtils.toForm()}
          onSubmit={handleSubmitForm}
        >
          {(props) => (
            <ReserveForm
              {...props}
              history={history}
              submissionLoading={createOp.loading}
            />
          )}
        </Formik>
      </LayoutContent>
    </PageLayout>
  );
}
