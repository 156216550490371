import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { Portal } from 'react-portal';
import { useClickOutside, useTimeoutRef } from '@proscom/ui-react';
import { ReactComponent as IconClose } from '../../../../assets/img/icons/Close.svg';
import { Icon } from '../Icon/Icon';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { usePreventWindowScroll } from '../../../hooks/usePreventWindowScroll';
import { Title, TitleVariant } from '../Title/Title';
import s from './Modal.module.scss';

const modalTransitionDelay = s.modalTransitionDelay;

export interface ModalClasses {
  overlay?: string;
  offset?: string;
  offsetContent?: string;
  head?: string;
  title?: string;
  content?: string;
}

export interface ModalProps {
  className?: string;
  classes?: ModalClasses;
  title?: React.ReactNode;
  headContent?: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  onClosed?: () => void;
  size?: {
    width?: number | string;
    maxWidth?: number | string;
    minHeight?: number | string;
    height?: number | string;
    maxHeight?: number | string;
  };
}

interface ModalCompProps extends ModalProps {
  isClosed: boolean;
  modalRef: MutableRefObject<any>;
}

const ModalComponent: React.FC<ModalCompProps> = ({
  className,
  classes,
  title,
  headContent,
  isOpen,
  isClosed,
  onClose,
  modalRef,
  size,
  children
}) => {
  return (
    <div
      ref={modalRef}
      className={clsx(s.Modal, className, {
        [s.Modal_open]: isOpen
      })}
      style={size || {}}
    >
      <div
        className={clsx(s.Modal__head, classes?.head, {
          [s.Modal__head_hasContent]: !!headContent
        })}
      >
        <Title
          className={clsx(s.Modal__title, classes?.title)}
          variant={TitleVariant.h3}
        >
          {title}
        </Title>

        <Icon
          className={s.Modal__closeButton}
          icon={IconClose}
          boxSize={28}
          iconSize={24}
          onClick={onClose}
        />

        {headContent && (
          <div className={s.Modal__headContent}>{headContent}</div>
        )}
      </div>
      {!isClosed && (
        <div className={clsx(s.Modal__content, classes?.content)}>
          {children}
        </div>
      )}
    </div>
  );
};

export const Modal: React.FC<ModalProps> = ({
  className,
  classes,
  title,
  headContent,
  isOpen,
  onClose,
  onClosed,
  size,
  children
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const offsetRef = useRef<HTMLDivElement | null>(null);
  const animationTO = useTimeoutRef();
  const [isClosed, setClosed] = useState(!isOpen);

  /**
   * todo: как-то суметь обойтись без стейта и эффекта
   * Видимость контента модалки зависит от isClosed.
   * isClosed становится true, когда отработает анимация закрытия модалки,
   * тогда контент модалки отмонтируется из ДОМа.
   *
   * Проблема в том, что при активации модалки сначала идет рендер модалки,
   * потом эффект с setClosed(false), а потом уже монтируется контент модалки.
   *
   * Из-за двойного рендера (модалка -> useEffect -> контент модалки)
   * иногда сначала рендерится пустая модалка и с задержкой появляется контент
   * (Визуально замечено в сафари, в хроме проблема не замечается)
   */
  useEffect(() => {
    if (isOpen) {
      setClosed(false);
    }
  }, [isOpen]);

  const resetScroll = useCallback(() => {
    const offset = offsetRef.current;
    if (offset) {
      offset.scrollTo({
        top: 0
      });
    }
  }, []);

  const handleOnClosed = useCallback(() => {
    resetScroll();
    onClosed?.();
    setClosed(true);
  }, [resetScroll, onClosed]);

  /**
   * triggers closed-handler after closing-animation ends
   */
  useEffect(() => {
    if (!isOpen && !isClosed) {
      animationTO.set(() => {
        handleOnClosed();
      }, +modalTransitionDelay || 0);
    }
  }, [isOpen, isClosed, animationTO, handleOnClosed]);

  const handleClickOutside = useCallback(() => {
    if (isOpen && onClose) {
      onClose();
    }
  }, [isOpen, onClose]);

  usePreventWindowScroll(isOpen);

  useClickOutside(modalRef, handleClickOutside);

  const { isMobile } = useWindowSize();

  return (
    <Portal>
      <div
        className={clsx(s.ModalOverlay, classes?.overlay, {
          [s.ModalOverlay_open]: isOpen
        })}
      />

      <div
        className={clsx(s.ModalOffset, classes?.offset, {
          [s.ModalOffset_open]: isOpen
        })}
        ref={offsetRef}
      >
        <div className={clsx(s.ModalOffset__content, classes?.offsetContent)}>
          <ModalComponent
            className={className}
            classes={classes}
            title={title}
            headContent={headContent}
            isOpen={isOpen}
            isClosed={isClosed}
            onClose={onClose}
            modalRef={modalRef}
            size={isMobile ? undefined : size}
          >
            {children}
          </ModalComponent>
        </div>
      </div>
    </Portal>
  );
};
