import { Routes, RouteType } from '../../common/components/utils/Routes';
import { RequireAuth } from '../RequireAuth';
import NotFoundPage from '../notFound/NotFoundPage';
import { hasPermission, UserPermission } from '../../utils/permissions';
import SystemLinksPage from './list/SystemLinksPage';
import SystemLinksEditPage from './edit/SystemLinksEditPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: RequireAuth(SystemLinksPage)
  },
  {
    path: '/edit',
    exact: true,
    component: RequireAuth(SystemLinksEditPage, (user) =>
      hasPermission(user, UserPermission.SystemLinkCrud)
    )
  },
  {
    component: NotFoundPage
  }
];

const SystemLinksRoutes = Routes(routes);

export default SystemLinksRoutes;
