import React from 'react';
import { SpecificContentBlockProps } from '../types';
import { Blockquote } from '../../Blockquote/Blockquote';
import { IContentBlockquote } from '../../Form/FormContentEditor/Content';

export const BlockquoteBlock: React.FC<
  SpecificContentBlockProps<IContentBlockquote>
> = ({ data, className }) => {
  return <Blockquote className={className}>{data.content}</Blockquote>;
};
