import React from 'react';
import { get } from 'lodash-es';
import { TableRowData } from '../../../../common/components/ui/Table/Table';

export interface FileLinkProps {
  row: TableRowData;
  field: string;
}

export const PollAnswer: React.FC<FileLinkProps> = ({ row, field }) => {
  const answer = get(row, field, undefined);
  return <>{answer.value_scale || answer.value_text}</>;
};
