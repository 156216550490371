import React, { useMemo } from 'react';
import clsx from 'clsx';
import { HashLink, HashLinkProps } from 'react-router-hash-link';
import { Loader } from '../Loader/Loader';
import { useHashScroll } from '../../../hooks/useHashScroll';
import {
  BaseButtonProps,
  ButtonBaseColor,
  ButtonLoaderSize,
  ButtonSize,
  ButtonVariant
} from './types';
import s from './Button.module.scss';

export interface ButtonHashProps
  extends HashLinkProps,
    Omit<BaseButtonProps, 'renderBase'> {
  scrollOffset?: number;
  scrollOffsettingHeader?: boolean;
}

export const ButtonHash: React.FC<ButtonHashProps> = ({
  variant = ButtonVariant.primary,
  size = ButtonSize.medium,
  baseColor = ButtonBaseColor.blue,
  iconLeft,
  iconRight,
  classes,
  children,
  className,
  loading,
  scrollOffset,
  scrollOffsettingHeader = true,
  smooth = true,
  scroll,
  ...props
}) => {
  const singleColor = useMemo(() => {
    if (variant === ButtonVariant.primary) {
      return '#fff';
    }
    if (variant === ButtonVariant.secondary) {
      switch (baseColor) {
        case ButtonBaseColor.red:
          return '#d51a1a';
        case ButtonBaseColor.green:
          return '#03bd4e';
        case ButtonBaseColor.gray:
          return '#38414e';
        case ButtonBaseColor.blue:
          return '#0075ff';
        default:
          return '#0075ff';
      }
    }
  }, [baseColor, variant]);

  const { scrollToHash } = useHashScroll(
    scrollOffset,
    scrollOffsettingHeader,
    smooth
  );

  return (
    <HashLink
      className={clsx(
        s.Button,
        s[`Button_${variant}`],
        s[`Button_${size}`],
        {
          [s[`Button_${baseColor}`]]: variant === ButtonVariant.secondary
        },
        className,
        classes?.root
      )}
      scroll={scroll || scrollToHash}
      {...props}
    >
      {loading ? (
        <Loader size={ButtonLoaderSize[size]} singleColor={singleColor} />
      ) : (
        <>
          {iconLeft && (
            <div
              className={clsx(
                s.Button__icon,
                s.Button__iconLeft,
                classes?.iconLeft
              )}
            >
              {iconLeft}
            </div>
          )}
          <div className={clsx(classes?.content)}>{children}</div>
          {iconRight && (
            <div
              className={clsx(
                s.Button__icon,
                s.Button__iconRight,
                classes?.iconRight
              )}
            >
              {iconRight}
            </div>
          )}
        </>
      )}
    </HashLink>
  );
};
