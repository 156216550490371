import React from 'react';
import { ErrorPage } from '../../common/components/ui/ErrorPage/ErrorPage';

export default function NoAccessPage() {
  return (
    <ErrorPage
      title="Нет доступа"
      description="У вас нет доступа к этой странице"
    />
  );
}
