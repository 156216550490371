import gql from 'graphql-tag';
import { FRAGMENT_PAGINATION_HEADER } from '../fragments/common';
import {
  FRAGMENT_OFFER_FULL,
  FRAGMENT_OFFER_SNIPPET
} from '../fragments/offers';

export const QUERY_GET_OFFERS_PAGE = gql`
  query queryGetOffersPage($input: OffersPageInput) {
    offersPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...OfferSnippet
      }
    }
  }

  ${FRAGMENT_OFFER_SNIPPET}
  ${FRAGMENT_PAGINATION_HEADER}
`;

export const QUERY_GET_OFFER = gql`
  query queryGetOffer($id: ID!) {
    offer(id: $id) {
      ...OfferFull
    }
  }

  ${FRAGMENT_OFFER_FULL}
`;

export const QUERY_GET_OFFER_KINDS_PAGE = gql`
  query queryGetOfferKindsPage($input: OfferKindsPageInput) {
    offerKindsPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        id
        name
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
`;

export const QUERY_GET_OFFER_CATEGORIES_PAGE = gql`
  query queryGetOfferCategoriesPage($input: OfferCategoriesPageInput) {
    offerCategoriesPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        id
        name
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
`;
