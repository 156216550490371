import { isTruthy } from '@proscom/ui-utils';
import { OptionTypeBase } from 'react-select/src/types';
import {
  FileType,
  InternshipContest,
  InternshipCreateInput,
  InternshipDuration,
  InternshipFinancialConditions,
  InternshipLocation,
  InternshipPurpose,
  InternshipSubjectType,
  InternshipTraineeCategory,
  InternshipType,
  InternshipUpdateInput,
  Maybe,
  TagType
} from '../../graphql/types';

export interface InternshipProps {
  id: string;
  cover_file_id?: string;
  name?: string;
  description?: string;
  conditions?: string;
  internship_subject?: InternshipSubjectType | null;
  internship_subject_id?: string;
  tags?: (OptionTypeBase & TagType)[] | null;
  link?: string;
  manual_file_id?: string;
  manual_file?: FileType;
  trainee_category?: Maybe<InternshipTraineeCategory>;
  purpose?: Maybe<InternshipPurpose>;
  duration?: Maybe<InternshipDuration>;
  location?: Maybe<InternshipLocation>;
  contest?: Maybe<InternshipContest>;
  financial_conditions?: Maybe<InternshipFinancialConditions>;
}

export class InternshipUtils {
  static toForm(data?: InternshipType): InternshipProps {
    const tags = data?.tags?.map((tag) => ({
      ...tag,
      value: tag.id,
      label: tag.name
    }));

    return {
      id: data?.id || '',
      cover_file_id: data?.cover_file_id || '',
      name: data?.name || '',
      description: data?.description || '',
      conditions: data?.conditions || '',
      internship_subject_id: data?.internship_subject_id ?? undefined,
      tags: tags,
      link: data?.link || '',
      manual_file_id: data?.manual_file_id ?? undefined,
      manual_file: data?.manual_file ?? undefined,
      trainee_category: data?.trainee_category,
      purpose: data?.purpose,
      duration: data?.duration,
      location: data?.location,
      contest: data?.contest,
      financial_conditions: data?.financial_conditions
    };
  }

  static fromCreateForm(formData: InternshipProps): InternshipCreateInput {
    const tags = formData.tags?.map((s) => s.label || null).filter(isTruthy);

    return {
      cover_file_id: formData.cover_file_id || null,
      name: formData.name || '',
      description: formData.description || '',
      conditions: formData.conditions || '',
      internship_subject_id: formData.internship_subject_id || null,
      tags: tags,
      link: formData?.link || null,
      manual_file_id: formData?.manual_file_id || null,
      trainee_category: formData?.trainee_category,
      purpose: formData?.purpose,
      duration: formData?.duration,
      location: formData?.location,
      contest: formData?.contest,
      financial_conditions: formData?.financial_conditions
    };
  }

  static fromUpdateForm(formData: InternshipProps): InternshipUpdateInput {
    return {
      id: formData.id,
      ...InternshipUtils.fromCreateForm(formData)
    };
  }
}
