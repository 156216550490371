import React from 'react';
import {
  Title,
  TitleVariant
} from '../../../../common/components/ui/Title/Title';
import { ContentBlocks } from '../../../../common/components/ui/ContentBlock/ContentBlock';
import { useFormatDate } from '../../../../common/hooks/useFormatDate';
import { ArticleProps } from '../../../../store/articles/ArticleUtils';
import { useFileSrc } from '../../../../common/hooks/useFileSrc';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { ImagePreview } from '../../../../common/components/ui/ImagePreview/ImagePreview';
import { FileLoaderList } from '../../../../common/components/ui/FileLoader/FileLoaderList';
import s from './Article.module.scss';

export const Article: React.FC<ArticleProps> = ({
  title,
  postedAt,
  coverImageId,
  content,
  document_files
}) => {
  const formattedDate = useFormatDate(postedAt);
  const imageSrc = useFileSrc(coverImageId, FileTypeEnum.article_cover);

  return (
    <div className={s.Article}>
      <div className={s.Article__head}>
        <Title
          className={s.Article__title}
          title={title || ''}
          variant={TitleVariant.h2}
        />
        {formattedDate && (
          <div className={s.Article__date}>{formattedDate}</div>
        )}
        {imageSrc && (
          <ImagePreview
            className={s.Article__cover}
            classes={{
              image: s.Article__coverImage
            }}
            imageSrc={imageSrc}
            showBackfaceImage
          />
        )}
      </div>
      <div className={s.Article__content}>
        <ContentBlocks
          className={s.Article__contentBlock}
          meta={{ fileType: FileTypeEnum.article_image }}
          blocks={content}
        />
      </div>
      {document_files && document_files.length > 0 && (
        <FileLoaderList
          className={s.Article__files}
          title={'Файлы'}
          fileType={FileTypeEnum.article_document}
          files={document_files}
        />
      )}
    </div>
  );
};
