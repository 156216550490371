import React from 'react';
import { useField } from 'formik';
import { OptionTypeBase } from 'react-select/src/types';
import { Select, SelectProps } from '../../Select/Select';

export interface FormSelectProps
  extends Omit<SelectProps, 'value' | 'onChange'> {
  name: string;
  options: OptionTypeBase[];
}

export const FormSelect: React.FC<FormSelectProps> = ({
  className,
  name,
  options,
  isMulti,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;
  const { error, touched } = meta;

  return (
    <Select
      {...props}
      className={className}
      options={options}
      value={value}
      isMulti={isMulti}
      errorMessage={touched && error ? error : undefined}
      onChange={setValue}
      onBlur={onBlur}
    />
  );
};
