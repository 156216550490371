import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ReactComponent as SlashSvg } from '../../../../assets/img/icons/slash.svg';
import s from './Breadcrumbs.module.scss';

export interface IBreadcrumbsItem {
  path: string;
  name: string;
}

interface BreadcrumbsItemProps {
  last?: boolean;
  item: IBreadcrumbsItem;
}

export function BreadcrumbsItem({ last, item }: BreadcrumbsItemProps) {
  return (
    <>
      <Link
        className={clsx(s.BreadcrumbsItem__link, {
          [s.BreadcrumbsItem__link_last]: last
        })}
        to={item.path}
      >
        {item.name}
      </Link>
      {!last && <SlashSvg className={s.BreadcrumbsItem__slash} />}
    </>
  );
}
