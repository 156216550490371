import React, { useMemo } from 'react';
import { FormSelect } from '../../../common/components/ui/Form/FormSelect/FormSelect';
import { useOfferCategoriesPage } from '../../../graphql/hooks/offers/useOfferCategoriesPage';
import { SelectClasses } from '../../../common/components/ui/Select/Select';

export interface OfferCategoriesSelectProps {
  classes?: SelectClasses;
}

export const OfferCategoriesSelect: React.FC<OfferCategoriesSelectProps> = ({
  classes
}) => {
  const categoriesQuery = useOfferCategoriesPage({});
  const categoriesOptions = useMemo(() => {
    if (!categoriesQuery.state.data) {
      return [];
    }
    return categoriesQuery.state.data.list.map((kind) => ({
      label: kind.name,
      value: kind.id
    }));
  }, [categoriesQuery.state.data]);

  return (
    <FormSelect
      label={'Категория'}
      name={'category_id'}
      options={categoriesOptions}
      isLoading={categoriesQuery.check.spinner}
      classes={classes}
      placeholder={'Выберите категорию'}
    />
  );
};
