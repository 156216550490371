import React, { useState } from 'react';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import {
  Button,
  ButtonAnchor,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { Card, CardStyle } from '../../../common/components/ui/Card/Card';
import { CardPattern } from '../../../common/components/ui/Card/patterns/CardPattern/CardPattern';
import {
  FigureType,
  FigureVariant
} from '../../../common/components/ui/Figures/types';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { Modal } from '../../../common/components/ui/Modal/Modal';
import { TextLink } from '../../../common/components/ui/TextLink/TextLink';
import s from './Health.module.scss';

enum modalIds {
  Sport = 'Sport',
  Food = 'Food'
}

const ModalSportContent = () => {
  return (
    <div className={s.ModalSportContent}>
      <div className={s.ModalSportContent__item}>
        <Title className={s.ModalSportContent__title} variant={TitleVariant.h3}>
          Спортивные занятия
        </Title>
        <div className={s.ModalSportContent__text}>
          На территории МФТИ, для сотрудников работает{' '}
          <TextLink
            href={
              'https://mipt.ru/education/chair/sport/studies/health-preps.php?sphrase_id=287016'
            }
            noUnderline
          >
            бассейн
          </TextLink>
          , есть{' '}
          <TextLink
            href={'https://mipt.ru/education/chair/sport/services/gym'}
            noUnderline
          >
            тренажерный
          </TextLink>{' '}
          и{' '}
          <TextLink
            href={'https://mipt.ru/education/chair/sport/services/sport-hall'}
            noUnderline
          >
            спортивный
          </TextLink>{' '}
          залы, стадион, в зимнее время прокат коньков и беговых{' '}
          <TextLink
            href={'https://mipt.ru/education/chair/sport/studies/skiing-base'}
            noUnderline
          >
            лыж
          </TextLink>
          .
        </div>
      </div>

      <div className={s.ModalSportContent__item}>
        <Title className={s.ModalSportContent__title} variant={TitleVariant.h3}>
          Бассейн
        </Title>
        <div className={s.ModalSportContent__text}>
          Адрес: Московское шоссе, д. 21, к. 1
        </div>
      </div>
    </div>
  );
};

const ModalFoodContent = () => {
  return (
    <div className={s.ModalFoodContent}>
      <div className={s.ModalFoodContent__item}>
        <div className={s.ModalFoodContent__itemContent}>
          <Title
            className={s.ModalSportContent__title}
            variant={TitleVariant.h3}
          >
            <TextLink
              href={
                'https://www.google.com/maps/place/%D0%9A%D0%A1%D0%9F+%D0%9C%D0%A4%D0%A2%D0%98/@55.9307458,37.5206457,15z/data=!4m5!3m4!1s0x0:0x14e93025406d6ec5!8m2!3d55.9307634!4d37.5207518'
              }
              noUnderline
            >
              Комбинат студенческого питания МФТИ
            </TextLink>
          </Title>
          <div className={s.ModalFoodContent__text}>
            Время работы: 7:30 до 18:00. <br />
            ул. Первомайская д.7А, 3 эт. <br />
            Тел.: +74954087545.
          </div>
        </div>
      </div>

      <div className={s.ModalFoodContent__item}>
        <div className={s.ModalFoodContent__itemContent}>
          <Title
            className={s.ModalSportContent__title}
            variant={TitleVariant.h3}
          >
            Столовая
          </Title>
          <div className={s.ModalFoodContent__text}>
            Время работы: 8:00 до 19:00. <br />
            ул. Первомайская д.7А, 1 этаж.
          </div>
        </div>
      </div>

      <div className={s.ModalFoodContent__item}>
        <div className={s.ModalFoodContent__itemContent}>
          <Title
            className={s.ModalSportContent__title}
            variant={TitleVariant.h3}
          >
            Столовая
          </Title>
          <div className={s.ModalFoodContent__text}>
            Время работы: 10:00 до 21:40. <br />
            КПМ, ул. Первомайская 5, 2 этаж. <br />
            Тел.: 8 800 775-78-86, доб 51.
          </div>
        </div>
      </div>

      <div className={s.ModalFoodContent__item}>
        <div className={s.ModalFoodContent__itemContent}>
          <Title
            className={s.ModalSportContent__title}
            variant={TitleVariant.h3}
          >
            Столовая
          </Title>
          <div className={s.ModalFoodContent__text}>
            НК, 2 этаж. <br />
            Время работы: 8:00 до 16:00, выходной – воскресенье.
          </div>
        </div>
      </div>

      <div className={s.ModalFoodContent__item}>
        <div className={s.ModalFoodContent__itemContent}>
          <Title
            className={s.ModalSportContent__title}
            variant={TitleVariant.h3}
          >
            Кафе
          </Title>
          <div className={s.ModalFoodContent__text}>
            Физтех.Био, 1 этаж. <br />
            Время работы: 10:00 до 16:00, выходной – воскресенье.
          </div>
        </div>
      </div>

      <div className={s.ModalFoodContent__item}>
        <div className={s.ModalFoodContent__itemContent}>
          <Title
            className={s.ModalSportContent__title}
            variant={TitleVariant.h3}
          >
            Кафе
          </Title>
          <div className={s.ModalFoodContent__text}>
            Физтех Арктика, 1 этаж. <br />
            Время работы: 10:00 до 17:00, выходной – воскресенье.
          </div>
        </div>
      </div>

      <div className={s.ModalFoodContent__item}>
        <div className={s.ModalFoodContent__itemContent}>
          <Title
            className={s.ModalSportContent__title}
            variant={TitleVariant.h3}
          >
            Кафе
          </Title>
          <div className={s.ModalFoodContent__text}>
            ГК, 2 этаж. <br />
            Время работы: 9:00 до 17:30, выходной - воскресенье.
          </div>
        </div>
      </div>

      <div className={s.ModalFoodContent__item}>
        <div className={s.ModalFoodContent__itemContent}>
          <Title
            className={s.ModalSportContent__title}
            variant={TitleVariant.h3}
          >
            Кафе
          </Title>
          <div className={s.ModalFoodContent__text}>
            НК, 2 этаж. <br />
            Время работы: 9:00 до 17:30, выходной – воскресенье.
          </div>
        </div>
      </div>

      <div className={s.ModalFoodContent__item}>
        <div className={s.ModalFoodContent__itemContent}>
          <Title
            className={s.ModalSportContent__title}
            variant={TitleVariant.h3}
          >
            Кофейня
          </Title>
          <div className={s.ModalFoodContent__text}>
            Переход между НК и ГК, 2 этаж. <br />
            Время работы: 9-00-19:00, суб: 9-00-17:00, выходной – воскресенье.
          </div>
        </div>
      </div>

      <div className={s.ModalFoodContent__item}>
        <div className={s.ModalFoodContent__itemContent}>
          <Title
            className={s.ModalSportContent__title}
            variant={TitleVariant.h3}
          >
            Кофейня
          </Title>
          <div className={s.ModalFoodContent__text}>
            Переход между ГК и корпусами «Арктика»/ «Цифра». <br />
            Время работы: 9-00-19:00, суб: 9-00-17:00, выходной – воскресенье.
          </div>
        </div>
      </div>

      <div className={s.ModalFoodContent__item}>
        <div className={s.ModalFoodContent__itemContent}>
          <Title
            className={s.ModalSportContent__title}
            variant={TitleVariant.h3}
          >
            Теория Кофе
          </Title>
          <div className={s.ModalFoodContent__text}>
            ГК, 2 этаж, рядом с боталкой. <br />
            Время работы: 9-00-21:00.
          </div>
        </div>
      </div>
    </div>
  );
};

const modals = {
  [modalIds.Sport]: {
    title: 'Спорт в МФТИ',
    component: <ModalSportContent />
  },
  [modalIds.Food]: {
    title: 'Питание',
    component: <ModalFoodContent />
  }
};

export const Health = () => {
  const [modalId, setModalId] = useState<modalIds | null>(null);

  const modalTitle = modalId ? modals[modalId]?.title : '';
  const modalContent = modalId ? modals[modalId]?.component : null;

  return (
    <LayoutContent id={'health'} className={s.Health}>
      <Title variant={TitleVariant.h2} className={s.Health__title}>
        Здоровье и питание
      </Title>

      <div className={s.HealthCards__grid}>
        <Card
          className={s.HealthCard}
          cardStyle={CardStyle.gray}
          extContent={
            <CardPattern
              className={s.HealthCard__pattern}
              figures={[
                {
                  className: s.HealthCardTriangle_front,
                  type: FigureType.triangle,
                  variant: FigureVariant.light,
                  color: '#CCECFF',
                  size: 280
                },
                {
                  className: s.HealthCardTriangle_back,
                  type: FigureType.triangle,
                  size: 280
                }
              ]}
            />
          }
          actions={
            <Button
              className={s.HealthCard__button_noBg}
              variant={ButtonVariant.secondary}
              onClick={() => setModalId(modalIds.Sport)}
            >
              Подробнее
            </Button>
          }
        >
          <div className={s.HealthCard__title}>Спорт в МФТИ</div>
          <div className={s.HealthCard__text}>
            На территории МФТИ, для сотрудников работает бассейн, есть
            тренажерный и спортивный залы, стадион, в зимнее время прокат
            коньков и беговых лыж.
          </div>
        </Card>

        <Card
          className={s.HealthCard}
          cardStyle={CardStyle.gray}
          extContent={
            <CardPattern
              className={s.HealthCard__pattern}
              figures={[
                {
                  className: s.HealthCardRectangle_back_2,
                  type: FigureType.rectangle,
                  variant: FigureVariant.light,
                  color: '#CCECFF',
                  size: 204
                },
                {
                  className: s.HealthCardRectangle_front_2,
                  type: FigureType.rectangle,
                  size: 145
                }
              ]}
            />
          }
          actions={
            <Button
              className={s.HealthCard__button_noBg}
              variant={ButtonVariant.secondary}
              onClick={() => setModalId(modalIds.Food)}
            >
              Подробнее
            </Button>
          }
        >
          <div className={s.HealthCard__title}>Питание</div>
          <div className={s.HealthCard__text}>
            На территории кампуса вы можете питаться в столовых, а так же
            заказывать доставку еды
          </div>
        </Card>

        <Card
          className={s.HealthCard}
          cardStyle={CardStyle.gray}
          extContent={
            <CardPattern
              className={s.HealthCard__pattern}
              figures={[
                {
                  className: s.HealthCardRectangle_back,
                  type: FigureType.rectangle,
                  variant: FigureVariant.light,
                  color: '#CCECFF',
                  size: 204
                },
                {
                  className: s.HealthCardRectangle_front,
                  type: FigureType.rectangle,

                  size: 145
                }
              ]}
            />
          }
          actions={
            <ButtonAnchor
              className={s.HealthCard__button_noBg}
              variant={ButtonVariant.secondary}
              href={'https://profile.mipt.ru/psy/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Перейти
            </ButtonAnchor>
          }
        >
          <div className={s.HealthCard__title}>Психолог в МФТИ</div>
          <div className={s.HealthCard__text}>
            На территории МФТИ для сотрудников работает психолог. Вы в любой
            момент можете обратиться за помощью к квалифицированному специалисту
          </div>
        </Card>
      </div>

      <Modal
        className={s.HealthModal}
        title={modalTitle}
        isOpen={!!modalContent}
        onClose={() => setModalId(null)}
      >
        {modalContent}
      </Modal>
    </LayoutContent>
  );
};
