import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { AnyProps } from '../../../../utils/reactTypes';
import { TableHeading } from './TableHeading/TableHeading';
import s from './Table.module.scss';

export type TableRowData = Record<string, any>;

export interface TableColumn {
  className?: string;
  title: string;
  field: string;
  editComponent?: React.ComponentType<{
    state: TableRowData;
    onChange: (value: any) => void;
    setError: (message?: string) => void;
    error: string;
  }>;
  fieldComponent?: React.ComponentType<{
    row: TableRowData;
    field: string;
  }>;
  titleComponent?: React.ComponentType<{
    data: TableRowData[];
    field: string;
    title: string;
  }>;
}

export interface TableRowProps {
  className?: string;
  row: TableRowData;
  iRow: number;
  columns: TableColumn[];
  data: TableRowData[];
  onClick?: () => void;
}

export interface ActionTableRowProps {
  row: TableRowData;
  columns: TableColumn[];
  actions?: ReactNode;
  editMode?: boolean;
}

export interface TableProps {
  columns: TableColumn[];
  data: TableRowData[];
  rowComponent: React.ComponentType<TableRowProps>;
  onRowClick?: (data: TableRowData, index: number) => void;
  hasActions?: boolean;
  hideHeader?: boolean;
}

export const Table: React.FC<TableProps> = ({
  columns,
  data,
  rowComponent: RowComponent,
  onRowClick,
  hasActions,
  hideHeader
}) => {
  const { isMobile } = useWindowSize();

  return (
    <BaseTable
      component={isMobile ? 'div' : 'table'}
      header={
        hideHeader || isMobile ? null : (
          <TableHeading
            data={data}
            hasActions={hasActions}
            columns={columns}
            classes={{
              actions: s.Table__action
            }}
          />
        )
      }
    >
      {data.map((row, iRow) => (
        <RowComponent
          key={iRow}
          columns={columns}
          row={row}
          iRow={iRow}
          data={data}
          onClick={() => onRowClick?.(row, iRow)}
        />
      ))}
    </BaseTable>
  );
};

interface BaseTableProps extends AnyProps {
  className?: string;
  component?: React.ElementType;
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

export const BaseTable = React.memo(function BaseTable({
  className,
  component = 'table',
  header,
  children,
  footer,
  ...props
}: BaseTableProps) {
  const Component = component;
  const HeadComponent = component === 'table' ? 'thead' : 'div';
  const BodyComponent = component === 'table' ? 'tbody' : 'div';
  const FooterComponent = component === 'table' ? 'tfoot' : 'div';
  return (
    <Component className={clsx(className, s.Table)} {...props}>
      {header && (
        <HeadComponent className={s.Table__header}>{header}</HeadComponent>
      )}
      {children && (
        <BodyComponent className={s.Table__body}>{children}</BodyComponent>
      )}
      {footer && (
        <FooterComponent className={s.Table__footer}>{footer}</FooterComponent>
      )}
    </Component>
  );
});
