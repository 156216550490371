import React from 'react';
import clsx from 'clsx';
import s from './ContentFormBlock.module.scss';

export interface ContentFormBlockProps {
  className?: string;
  label?: string;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}

export const ContentFormBlock: React.FC<ContentFormBlockProps> = ({
  className,
  label,
  actions,
  children
}) => {
  return (
    <div className={clsx(s.ContentFormBlock, className)}>
      {label && <div className={s.ContentFormBlock__label}>{label}</div>}

      <div>{children}</div>

      {actions && <div className={s.ContentFormBlock__actions}>{actions}</div>}
    </div>
  );
};
