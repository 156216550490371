import React from 'react';
import { Location } from 'history';
import { Helmet } from 'react-helmet';
import { PageLayout } from '../../common/components/Layout/PageLayout';
import {
  AnchorSwitch,
  SwitchAnchorType
} from '../../common/components/ui/AnchorSwitch/AnchorSwitch';
import { useWindowSize } from '../../common/hooks/useWindowSize';
import { Main } from './Main/Main';
import { AboutHR } from './AboutHR/AboutHR';
import { Welcome } from './Welcome/Welcome';
import { Structure } from './Structure/Structure';
import { Numbers } from './Numbers/Numbers';
import { Nobel } from './Nobel/Nobel';
import { PhysTech } from './PhysTech/PhysTech';
import { Health } from './Health/Health';
import { FeedBack } from './FeedBack/FeedBack';
import { Target } from './Target/Target';
import { Links } from './Links/Links';
import s from './NewComersPage.module.scss';

const anchors: SwitchAnchorType[] = [
  {
    id: 'about',
    label: 'Об МФТИ'
  },
  {
    id: 'phystech',
    label: 'Физтех-школы'
  },
  {
    id: 'links',
    label: 'Полезные ссылки'
  },
  {
    id: 'structure',
    label: 'Объединения МФТИ'
  },
  {
    id: 'health',
    label: 'Здоровье и питание'
  },
  {
    id: 'feedback',
    label: 'Обратная связь'
  }
];

const Switch = () => {
  const { isMobile } = useWindowSize();

  return !isMobile ? (
    <AnchorSwitch
      className={s.NewComersPage__anchorSwitch}
      anchors={anchors}
      // scrollOffset={100}
    />
  ) : null;
};

export interface NewComersPageProps {
  location: Location;
}

export default function NewComersPage({ location }: NewComersPageProps) {
  return (
    <PageLayout contentClassName={s.NewComersPage__content} isLanding={true}>
      <Helmet title={'Работа в МФТИ'} />

      <Main />
      <AboutHR />
      <Welcome />
      <Target />
      <Numbers />
      <Nobel />
      <PhysTech />
      <Links />
      <Structure />
      <Health />
      <FeedBack />

      <Switch />
    </PageLayout>
  );
}
