import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { SystemLinkForm } from '../SystemLinkForm/SystemLinkForm';
import {
  SystemLinkFormValues,
  SystemLinksUtils
} from '../../../store/systemLinks/SystemLinksUtils';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { SystemLinks } from '../../../store/systemLinks/SystemLinks';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { SystemLinksValidationSchema } from '../../../store/validationSchema';

export interface SystemLinkAddFieldProps {
  onCancel: () => void;
}

export const SystemLinkAddField: React.FC<SystemLinkAddFieldProps> = ({
  onCancel
}) => {
  const createSystemLink = useGraphqlMutation(SystemLinks.createSystemLink);
  const createSystemLinkRun = createSystemLink.run;
  const handleSubmit = useCallback(
    (values: SystemLinkFormValues) => {
      const input = SystemLinksUtils.fromCreateForm(values);
      createSystemLinkRun({ variables: { input } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Ссылка добавлена');
            onCancel();
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при создании ссылки. Попробуйте снова'
          );
        });
    },
    [createSystemLinkRun, onCancel]
  );

  return (
    <Formik
      validationSchema={SystemLinksValidationSchema}
      initialValues={SystemLinksUtils.toForm()}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <SystemLinkForm
          {...props}
          onCancel={onCancel}
          submissionLoading={createSystemLink.loading}
        />
      )}
    </Formik>
  );
};
