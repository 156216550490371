import { useCallback, useEffect, useState } from 'react';

export function useHashScroll(
  scrollOffset: number = 0,
  scrollOffsettingHeader: boolean = true,
  smooth: boolean = true
) {
  const [headerHeight, setHeaderHeight] = useState(0);

  const checkHeaderSize = useCallback(() => {
    const header = document.getElementById('page-header');
    setHeaderHeight(header ? header.clientHeight : 0);
  }, []);

  useEffect(() => {
    window.addEventListener('load', checkHeaderSize);
    window.addEventListener('resize', checkHeaderSize);
    return () => {
      window.removeEventListener('load', checkHeaderSize);
      window.removeEventListener('resize', checkHeaderSize);
    };
  }, [checkHeaderSize]);

  const scrollToHash = useCallback(
    (el) => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;

      let offset = scrollOffset || 0;

      if (scrollOffsettingHeader) {
        offset = offset - headerHeight;
      }

      window.scrollTo({
        top: yCoordinate + offset,
        behavior: smooth ? 'smooth' : 'auto'
      });
    },
    [scrollOffset, scrollOffsettingHeader, smooth, headerHeight]
  );

  return {
    scrollToHash,
    headerHeight
  };
}
