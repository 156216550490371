import {
  CourseCompletionProof,
  CourseLearningFormat,
  CourseListenerCategory
} from '../../graphql/types';
import { createOptionsFromEnum } from '../../utils/createOptionsFromEnum';

export const learningFormatLabelMap = {
  [CourseLearningFormat.FullTime]: 'Очно',
  [CourseLearningFormat.Remote]: 'Дистанционно',
  [CourseLearningFormat.FullTimeWithRemote]:
    'Очно с применением дистанционных технологий'
};

export enum CoursePrice {
  Free = 'Free',
  Paid = 'Paid',
  PaidWithDiscount = 'PaidWithDiscount'
}

export const coursePriceLabelMap = {
  [CoursePrice.Paid]: 'Платные',
  [CoursePrice.Free]: 'Бесплатные',
  [CoursePrice.PaidWithDiscount]: 'Платные, есть скидка для сотрудников'
};

export const courseCompletionProofLabelMap = {
  [CourseCompletionProof.None]: 'Нет',
  [CourseCompletionProof.Certificate]: 'Сертификат',
  [CourseCompletionProof.AdvancedTrainingCertificate]:
    'Свидетельство о повышении квалификации',
  [CourseCompletionProof.ProfessionalRetrainingCertificate]:
    'Удостоверение о профессиональной переподготовке',
  [CourseCompletionProof.ProfessionalRetrainingDiploma]:
    'Диплом о профессиональной переподготовке'
};

export const courseListenerCategoryLabelMap = {
  [CourseListenerCategory.ProfessorsTeachersStaff]:
    'Профессорско-преподавательский состав',
  [CourseListenerCategory.ScientificPedagogicalStaff]:
    'Научно-педагогические работники',
  [CourseListenerCategory.ResearchStaff]: 'Научно-исследовательская часть',
  [CourseListenerCategory.EngineeringStaff]:
    'Административно-управленческий персонал',
  [CourseListenerCategory.AdministrativeManagementStaff]:
    'Инженерно-технические работники',
  [CourseListenerCategory.All]: 'Все категории'
};

export const courseLearningFormatOptions = createOptionsFromEnum(
  CourseLearningFormat,
  learningFormatLabelMap
);
export const coursePriceOptions = createOptionsFromEnum(
  CoursePrice,
  coursePriceLabelMap
);
export const courseCompletionProofOptions = createOptionsFromEnum(
  CourseCompletionProof,
  courseCompletionProofLabelMap
);
export const courseListenerCategoryOptions = createOptionsFromEnum(
  CourseListenerCategory,
  courseListenerCategoryLabelMap
);
