import { BreadcrumbsItem, IBreadcrumbsItem } from './BreadcrumbsItem';
import s from './Breadcrumbs.module.scss';

export interface BreadcrumbsProps {
  items: IBreadcrumbsItem[];
}

export function Breadcrumbs({ items }: BreadcrumbsProps) {
  return (
    <div className={s.Breadcrumbs}>
      {items.map((item, iItem) => {
        return (
          <BreadcrumbsItem
            key={iItem}
            last={iItem === items.length - 1}
            item={item}
          />
        );
      })}
    </div>
  );
}
