import { Form, FormikProps } from 'formik';
import React from 'react';
import { FormCheckboxGroup } from '../../../common/components/ui/CheckboxGroup/CheckboxGroup';
import { Button } from '../../../common/components/ui/Button';
import { ReactComponent as IconArrowRight } from '../../../assets/img/icons/ArrowRight.svg';
import { QuestionCard } from '../../../common/components/ui/QuestionCard/QuestionCard';
import {
  internshipContestOptions,
  internshipDurationOptions,
  internshipFinancialConditionsOptions,
  internshipLocationOptions,
  internshipPurposeOptions,
  internshipTraineeCategoryOptions
} from '../../../store/internships/InternshipTest';
import { InternshipTestFormValues } from './InternshipTestPage';
import s from './InternshipTestPage.module.scss';

export const InternshipTestForm = React.memo(function InternshipTestForm(
  props: FormikProps<InternshipTestFormValues>
) {
  const canSubmit = props.isValid && props.dirty;
  return (
    <Form>
      <div className={s.InternshipTestForm__content}>
        <QuestionCard
          required
          title={'К какой категории персонала МФТИ вы относитесь?'}
        >
          <FormCheckboxGroup
            name={'trainee_category'}
            options={internshipTraineeCategoryOptions}
          />
        </QuestionCard>
        <QuestionCard
          title={'С какой целью вы хотите поучаствовать в стажировке?'}
        >
          <FormCheckboxGroup
            name={'purpose'}
            options={internshipPurposeOptions}
          />
        </QuestionCard>
        <QuestionCard title={'Стажировка какой длительности вас интересует?'}>
          <FormCheckboxGroup
            name={'duration'}
            options={internshipDurationOptions}
          />
        </QuestionCard>
        <QuestionCard
          title={
            'Стажировки с какой территориальной отдаленностью вы рассматриваете?'
          }
        >
          <FormCheckboxGroup
            name={'location'}
            options={internshipLocationOptions}
          />
        </QuestionCard>
        <QuestionCard
          title={
            'Вас интересуют стажировки с конкурсом или со свободным зачислением?'
          }
        >
          <FormCheckboxGroup
            name={'contest'}
            options={internshipContestOptions}
          />
        </QuestionCard>
        <QuestionCard
          title={'Стажировки с какими финансовыми условиями вас интересуют?'}
        >
          <FormCheckboxGroup
            name={'financial_conditions'}
            options={internshipFinancialConditionsOptions}
          />
        </QuestionCard>
      </div>
      <div className={s.InternshipTestForm__footer}>
        <Button
          className={s.InternshipTestPage__submitButton}
          iconRight={<IconArrowRight />}
          disabled={!canSubmit}
          type={'submit'}
        >
          Подобрать стажировку
        </Button>
        <div className={s.InternshipTestForm__submitText}>
          {!canSubmit && 'Пожалуйста, ответьте на все вопросы'}
        </div>
      </div>
    </Form>
  );
});
