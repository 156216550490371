import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Checkbox } from '../../ui/Checkbox/Checkbox';
import { Maybe } from '../../../../graphql/types';
import s from '../../ui/Filter/Filter.module.scss';

export interface FilterKindsProps {
  value?: Maybe<boolean>;
  onChange?: (state?: boolean) => void;
}

const statuses = [
  {
    label: 'Активные',
    value: 'active'
  },
  {
    label: 'Завершенные',
    value: 'finished'
  }
];

export function FilterStatus({ value, onChange, ...props }: FilterKindsProps) {
  const [state, setState] = useState<string[]>([]);

  useEffect(() => {
    let newValue: boolean | undefined = undefined;
    const isActive = state.includes('active');
    const isFinished = state.includes('finished');
    if (isActive && isFinished) {
      newValue = undefined;
    } else if (isActive) {
      newValue = true;
    } else if (isFinished) {
      newValue = false;
    }
    if (newValue !== value) onChange?.(newValue);
  }, [value, state, onChange]);

  useEffect(() => {
    if (value === true) {
      setState(() => ['active']);
    } else if (value === false) {
      setState(() => ['finished']);
    } else {
      setState(() => ['active', 'finished']);
    }
  }, [value]);

  return (
    <div className={clsx(s.Filter, s.FilterStatus)}>
      <div className={s.Filter__label}>Статус</div>
      {statuses.map(({ label, value: x }) => (
        <Checkbox
          label={label}
          checked={state === undefined || !!state.find((v) => v === x)}
          key={x}
          onChange={(e) => {
            if (state.includes(x)) {
              setState((v) => v.filter((o) => o !== x));
            } else {
              setState((v) => [...v, x]);
            }
          }}
        />
      ))}
    </div>
  );
}
