import { MutationOptions } from 'apollo-client';
import { clearCache } from '../../graphql/queriesUtils';
import {
  PersonnelReserveCreateInput,
  PersonnelReserveType,
  PersonnelReserveUpdateInput
} from '../../graphql/types';
import {
  MUTATION_CREATE_PERSONNEL_RESERVE,
  MUTATION_DELETE_PERSONNEL_RESERVE,
  MUTATION_UPDATE_PERSONNEL_RESERVE
} from '../../graphql/mutations/personnelReserve';

export class PersonnelReserve {
  private static refetchQueries = [
    'queryGetPersonnelReservePage',
    'queryGetPersonnelReserve'
  ];
  private static updateQueries = ['personnelReservePage', 'personnelReserve'];
  private static updateFragments = [
    'PersonnelReserveType',
    'PersonnelReservePageType'
  ];

  public static createReserve: MutationOptions<
    {
      result: PersonnelReserveType;
    },
    {
      input: PersonnelReserveCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_PERSONNEL_RESERVE,
    refetchQueries: PersonnelReserve.refetchQueries,
    update: clearCache(
      PersonnelReserve.updateQueries,
      PersonnelReserve.updateFragments
    )
  };

  public static updateReserve: MutationOptions<
    {
      result: PersonnelReserveType;
    },
    {
      input: PersonnelReserveUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_PERSONNEL_RESERVE,
    refetchQueries: PersonnelReserve.refetchQueries,
    update: clearCache(
      PersonnelReserve.updateQueries,
      PersonnelReserve.updateFragments
    )
  };

  public static deleteReserve: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_DELETE_PERSONNEL_RESERVE,
    refetchQueries: PersonnelReserve.refetchQueries,
    update: clearCache(
      PersonnelReserve.updateQueries,
      PersonnelReserve.updateFragments
    )
  };
}
