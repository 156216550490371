import gql from 'graphql-tag';
import {
  FRAGMENT_DOCUMENT,
  FRAGMENT_DOCUMENT_SECTION
} from '../fragments/documents';

export const QUERY_GET_DOCUMENT_SECTIONS = gql`
  query queryGetDocumentSections {
    documentSections {
      ...DocumentSectionProps
      documents {
        ...DocumentProps
      }
    }
  }

  ${FRAGMENT_DOCUMENT_SECTION}
  ${FRAGMENT_DOCUMENT}
`;
