import { joinNonEmpty, shortenText } from '@proscom/ui-utils';

export const STATUS_CODE_GRAPHQL = 400;
export const STATUS_CODE_AUTH = 401;
export const STATUS_CODE_ACCESS = 403;
export const STATUS_CODE_NOT_FOUND = 404;
export const STATUS_CODE_NETWORK = 1000;
export const STATUS_CODE_UNKNOWN = 1001;

export interface ParsedError {
  error: string;
  errorId?: string;
  statusCode: number;
  message?: string;
}

/**
 * Парсит ошибку GraphQL, пытаясь достать ошибку, возвращенную бекендом
 * Бекенд возвращает ошибку в виде json-строки, содержащей statusCode и message
 * Если ошибка не с бекенда, то возвращается объект у которого есть хотя бы message
 * и statusCode в худшем случае STATUS_CODE_UNKNOWN
 * Если какие-то проблемы с сетью, то возвращает STATUS_CODE_NETWORK
 *
 * Если переданная ошибка не определяется, как ошибка graphql, то просто возвращает ее
 */
export function getGraphQLErrorInfo(error: any): ParsedError | any {
  if (typeof error !== 'object') return error;

  let graphQLErrors;
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    graphQLErrors = error.graphQLErrors;
  } else if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors
  ) {
    graphQLErrors = error.networkError.result.errors;
  }

  if (graphQLErrors && graphQLErrors.length > 0) {
    try {
      return {
        ...error.networkError,
        ...JSON.parse(graphQLErrors[0].message)
      };
    } catch (e) {
      return {
        statusCode: STATUS_CODE_UNKNOWN,
        ...error.networkError,
        ...graphQLErrors[0],
        // Что-то где-то обновилось и теперь данные распихиваются по разным местам
        // Собираем их обратно в один объект
        ...graphQLErrors[0].extensions,
        ...graphQLErrors[0].extensions?.exception,
        ...graphQLErrors[0].extensions?.response
      };
    }
  }

  if (error.networkError) {
    if (error.networkError.statusCode) {
      return {
        ...error.networkError,
        message:
          error.networkError.bodyText || error.networkError.result.message
      };
    }

    return {
      error: error.networkError,
      statusCode: STATUS_CODE_NETWORK
    };
  }

  return error;
}

/**
 * Возвращает текст ошибки для отображения пользователю
 */
export function getParsedErrorMessage(parsedError: ParsedError | any) {
  if (!parsedError) return null;
  if (typeof parsedError !== 'object') return parsedError + '';

  const errorId = parsedError.errorId ? '#' + parsedError.errorId : null;
  const codeMessage = getErrorCodeMessage(parsedError.statusCode);
  let errorMessage = parsedError.message;
  if (errorMessage) {
    if (typeof errorMessage === 'object') {
      errorMessage = `Ошибка: ${JSON.stringify(errorMessage)}`;
    }
  } else {
    errorMessage = `Ошибка: ${JSON.stringify(parsedError.error)}`;
  }

  errorMessage = shortenText(errorMessage, 400);

  return joinNonEmpty([codeMessage, errorId, errorMessage], '. ');
}

/**
 * Возвращает общеупотребимые сообщения ошибок
 */
export function getErrorCodeMessage(statusCode: number): string | null {
  switch (statusCode) {
    case STATUS_CODE_AUTH:
      return 'Требуется авторизация';
    case STATUS_CODE_ACCESS:
      return 'У вас недостаточно прав для выполнения данного действия';
    case STATUS_CODE_NOT_FOUND:
      return 'Не найдено';
    case STATUS_CODE_NETWORK:
      return 'Ошибка сети. Проверьте свой доступ к интернету';
    default:
      return null;
  }
}

/**
 * Возвращает стандартное сообщение об ошибке
 * @param error - ошибка graphql
 */
export function getGraphqlErrorMessage(error: any) {
  const parsedError = getGraphQLErrorInfo(error);
  return getParsedErrorMessage(parsedError);
}

/**
 * Добавляет в конец сообщения об ошибке её идентификатор с бекенда
 * @param message - сообщение об ошибке
 * @param error - объект ошибки
 */
export function tryAppendErrorId(message: string, error: any) {
  const parsedError = getGraphQLErrorInfo(error);
  const errorId = parsedError.errorId ? '#' + parsedError.errorId : null;
  if (errorId) {
    return message + ` (${errorId})`;
  }
  return message;
}
