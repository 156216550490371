import React, { useCallback, useMemo, useState } from 'react';
import { Form, FormikProps, useField } from 'formik';
import { EMDASH } from '@proscom/ui-utils';
import {
  CourseSource,
  CourseStatus,
  CourseTypesEnum
} from '../../../graphql/types';
import { FormTextArea } from '../../../common/components/ui/Form/FormTextArea/FormTextArea';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import {
  Button,
  ButtonBaseColor,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { FormTextField } from '../../../common/components/ui/Form/FormTextField/FormTextField';
import { FormSelect } from '../../../common/components/ui/Form/FormSelect/FormSelect';
import { FormAsyncEntitiesCreatableSelect } from '../../../common/components/ui/Form/FormAsyncEntitiesCreatableSelect/FormAsyncEntitiesCreatableSelect';
import { Entity } from '../../../common/components/ui/AsyncSelect/entities/types';
import { FormAsyncEntitySelect } from '../../../common/components/ui/Form/FormAsyncEntitySelect/FormAsyncEntitySelect';
import { FormTextEditor } from '../../../common/components/ui/Form/FormTextEditor/FormTextEditor';
import { FormContentEditor } from '../../../common/components/ui/Form/FormContentEditor/FormContentEditor';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import { FormCoverLoader } from '../../../common/components/ui/Form/FormCoverLoader/FormCoverLoader';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { Checkbox } from '../../../common/components/ui/Checkbox/Checkbox';
import {
  courseCompletionProofOptions,
  courseLearningFormatOptions,
  courseListenerCategoryOptions
} from '../../../store/courses/CourseTest';
import { CourseTypeForm } from '../types';
import { FormFileLoader } from '../../../common/components/ui/Form/FormFileLoader/FormFileLoader';
import { CourseFormBlock } from './CourseFormBlock';
import { FormCourseTeachers } from './FormCourseTeachers/FormCourseTeachers';
import s from './CourseForm.module.scss';

export interface CourseFormProps extends FormikProps<CourseTypeForm> {
  isEdit?: boolean;
  submissionLoading?: boolean;
  onCancel?: () => void;
}

const typeOptions = [
  {
    label: 'Анонс',
    value: CourseTypesEnum.Announce
  },
  {
    label: 'Лонгрид',
    value: CourseTypesEnum.Longread
  }
];

const sourceOptions = [
  {
    label: 'От МФТИ',
    value: CourseSource.Mipt
  },
  {
    label: 'От партнеров',
    value: CourseSource.Partner
  }
];

const courseFormBlocksMeta = {
  fileType: FileTypeEnum.course_image
};

export const CourseForm: React.FC<CourseFormProps> = ({
  values,
  isEdit,
  submissionLoading,
  onCancel
}) => {
  const { isMobile } = useWindowSize();
  const [, , { setValue: setCourseStatus }] = useField('status');
  const [{ value: courseType }] = useField('type');
  const [{ value: discountPrice }, , { setValue: setDiscountPrice }] = useField(
    'discount_price'
  );

  const courseTypeName = useMemo(() => {
    return typeOptions.find((o) => o.value === courseType)?.label;
  }, [courseType]);
  const [hasDiscount, setHasDiscountState] = useState(!!discountPrice);
  const setHasDiscount = useCallback(
    (v) => {
      setHasDiscountState(v);
      if (!v) setDiscountPrice(null);
    },
    [setDiscountPrice]
  );

  const handleSubmit = useCallback(
    (status) => {
      setCourseStatus(status);
    },
    [setCourseStatus]
  );

  return (
    <Form className={s.CourseForm}>
      <div className={s.CourseForm__content}>
        <CourseFormBlock className={s.CourseFormBlock_cover}>
          <FormCoverLoader
            name={'cover_file_id'}
            fileType={FileTypeEnum.course_image}
          />
        </CourseFormBlock>

        {isEdit && (
          <CourseFormBlock>
            <Title variant={TitleVariant.h3}>
              Тип курса: {courseTypeName || EMDASH}
            </Title>
          </CourseFormBlock>
        )}

        <CourseFormBlock label={'Приоритет выдачи'}>
          <FormTextField
            name={'priority'}
            type={'number'}
            hintMessage={
              'Если задана приоритетность, то курс закрепляется и отображается выше других.' +
              'Чем больше значение приоритетности, тем выше отображается курс'
            }
          />
        </CourseFormBlock>

        <CourseFormBlock label={'Источник'}>
          <FormSelect name={'source'} options={sourceOptions} />
        </CourseFormBlock>

        {!isEdit && (
          <CourseFormBlock label={'Тип курса'}>
            <FormSelect name={'type'} options={typeOptions} />
          </CourseFormBlock>
        )}

        <CourseFormBlock label={'Название курса'}>
          <FormTextArea name={'name'} rows={2} />
        </CourseFormBlock>

        <CourseFormBlock label={'Описание курса'}>
          <FormTextArea
            name={'description'}
            rows={3}
            hintMessage={'Отображается в карточке курса под заголовком'}
          />
        </CourseFormBlock>

        <CourseFormBlock label={'Тема курса'}>
          <FormAsyncEntitySelect
            name={'course_subject_id'}
            entity={Entity.courseSubjectsPage}
            isCreatable={false}
          />
        </CourseFormBlock>
        <CourseFormBlock label={'Цена курса'}>
          <FormTextField type={'number'} name={'price'} placeholder={'Цена'} />
          <div className={s.CourseForm__hasDiscount}>
            <Checkbox
              checked={hasDiscount}
              onChange={setHasDiscount}
              label={'Есть скидка для сотрудников'}
            />
          </div>
        </CourseFormBlock>
        {hasDiscount && (
          <CourseFormBlock label={'Цена курса со скидкой'}>
            <FormTextField
              type={'number'}
              name={'discount_price'}
              placeholder={'Цена со скидкой'}
            />
          </CourseFormBlock>
        )}
        <CourseFormBlock label={'Формат обучения'}>
          <FormSelect
            name={'learning_format'}
            options={courseLearningFormatOptions}
          />
        </CourseFormBlock>
        <CourseFormBlock label={'Доказательство прохождения'}>
          <FormSelect
            name={'completion_proof'}
            options={courseCompletionProofOptions}
          />
        </CourseFormBlock>
        <CourseFormBlock label={'Категория слушателя'}>
          <FormSelect
            name={'listener_category'}
            options={courseListenerCategoryOptions}
          />
        </CourseFormBlock>

        {courseType === CourseTypesEnum.Longread && (
          <CourseFormBlock label={'Содержимое'}>
            <FormContentEditor meta={courseFormBlocksMeta} name={'content'} />
          </CourseFormBlock>
        )}

        <CourseFormBlock label={'Теги'}>
          <FormAsyncEntitiesCreatableSelect
            name={'tags'}
            entity={Entity.tagsPage}
            placeholder={'Выберите теги'}
          />
        </CourseFormBlock>

        {courseType === CourseTypesEnum.Announce && (
          <>
            <CourseFormBlock label={'Чему вы научитесь'}>
              <FormTextEditor name={'acquired_knowledge'} />
            </CourseFormBlock>
            <CourseFormBlock label={'Ссылка на курс'}>
              <FormTextField name={'course_link'} />
            </CourseFormBlock>
            <CourseFormBlock label={'Логотип партнёра'}>
              <FormCoverLoader
                label={''}
                name={'partner_logo_file_id'}
                fileType={FileTypeEnum.course_image}
              />
            </CourseFormBlock>
            <CourseFormBlock label={'Учителя'}>
              <FormCourseTeachers name={'teachers'} />
            </CourseFormBlock>
          </>
        )}

        <CourseFormBlock>
          <FormFileLoader
            name={'documents'}
            label={'Файлы'}
            buttonText={'Прикрепить файлы'}
            fileType={FileTypeEnum.course_document}
            value={values?.document_files ?? undefined}
            multiple={true}
          />
        </CourseFormBlock>
      </div>
      <div className={s.CourseForm__actions}>
        <Button
          className={s.CourseForm__actionButton}
          size={isMobile ? ButtonSize.medium : ButtonSize.large}
          variant={ButtonVariant.secondary}
          onClick={onCancel}
        >
          Отменить всё
        </Button>

        <Button
          className={s.CourseForm__actionButton}
          size={isMobile ? ButtonSize.medium : ButtonSize.large}
          variant={ButtonVariant.secondary}
          color={ButtonBaseColor.gray}
          loading={submissionLoading}
          type={'submit'}
          onClick={() => handleSubmit(CourseStatus.Draft)}
        >
          Сохранить черновик
        </Button>
        <Button
          className={s.CourseForm__actionButton}
          size={isMobile ? ButtonSize.medium : ButtonSize.large}
          loading={submissionLoading}
          type={'submit'}
          onClick={() => handleSubmit(CourseStatus.Published)}
        >
          Опубликовать
        </Button>
      </div>
    </Form>
  );
};
