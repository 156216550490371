import React from 'react';
import clsx from 'clsx';
import { Header } from '../ui/Header/Header';
import { Footer } from '../ui/Footer/Footer';
import { ErrorBoundary } from '../utils/ErrorBoundary';
import { ErrorPage } from '../ui/ErrorPage/ErrorPage';
import { ErrorPageContent } from '../ui/ErrorPage/ErrorPageContent';
import { isReactNativeApp } from '../../../utils/native';
import { LayoutContent } from './Layout';
import s from './Layout.module.scss';

export interface PageLayoutProps {
  className?: string;
  contentClassName?: string;
  showHeader?: boolean;
  showFooter?: boolean;
  isLanding?: boolean;
  isVerticalCentered?: boolean;
  children?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  className,
  contentClassName,
  showHeader = true,
  showFooter = true,
  isVerticalCentered,
  isLanding,
  children,
  breadcrumbs
}) => {
  const isMobileApp = isReactNativeApp();
  const footerVisible = showFooter && !isMobileApp;

  return (
    <ErrorBoundary component={ErrorPage}>
      <div
        className={clsx(s.PageLayout, className, {
          [s.PageLayout_noHeader]: !showHeader
        })}
      >
        {showHeader && (
          <div className={s.PageLayout__header}>
            <Header isLanding={isLanding} />
          </div>
        )}

        {breadcrumbs && (
          <div className={clsx(s.PageLayout__breadcrumbs)}>
            <LayoutContent>{breadcrumbs}</LayoutContent>
          </div>
        )}
        <div
          className={clsx(s.PageLayout__content, contentClassName, {
            [s.PageLayout__content_verticalCentered]: isVerticalCentered,
            [s.PageLayout__content_hasBreadcrumbs]: breadcrumbs,
            [s.PageLayout__content_mobileApp]: isMobileApp
          })}
        >
          <ErrorBoundary component={ErrorPageContent}>{children}</ErrorBoundary>
        </div>

        {footerVisible && (
          <div className={s.PageLayout__footer}>
            <Footer isLanding={isLanding} />
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};
