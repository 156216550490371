import gql from 'graphql-tag';

export const FRAGMENT_ARTICLE_SNIPPET = gql`
  fragment ArticleSnippet on ArticleType {
    id
    title
    posted_at
    snippet
    cover_file_id
    priority
  }
`;

export const FRAGMENT_ARTICLE_FULL = gql`
  fragment ArticleFull on ArticleType {
    id
    title
    posted_at
    snippet
    cover_file_id
    content
    priority
    document_files {
      id
      name
    }
  }
`;
