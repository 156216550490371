import gql from 'graphql-tag';

export const FRAGMENT_PERSONNEL_RESERVE_FULL = gql`
  fragment PersonnelReserveFull on PersonnelReserveType {
    id
    name
    snippet
    description
    cover_file_id
  }
`;
