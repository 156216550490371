import { IUseGraphqlQueryOptions } from '@proscom/prostore-apollo-react';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import {
  ExternalPollsFilter,
  ExternalPollsPageInput,
  ExternalPollsPageSorting,
  ExternalPollsPageType,
  PaginationInput
} from '../../types';
import { QUERY_GET_EXTERNAL_POLLS_PAGE } from '../../queries/polls';

export interface UseExternalPollsVars {
  input: ExternalPollsPageInput;
}

export interface UseExternalPollsArgs {
  filter?: ExternalPollsFilter;
  pagination?: PaginationInput;
  sorting?: ExternalPollsPageSorting[];
}

const queryOptions: IUseGraphqlQueryOptions<
  UseExternalPollsVars,
  ExternalPollsPageType
> = {
  query: QUERY_GET_EXTERNAL_POLLS_PAGE,
  mapData: (result) => result.externalPollsPage
};

export const useExternalPollsPage = ({
  filter,
  pagination,
  sorting
}: UseExternalPollsArgs) => {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: {
      input: {
        filter,
        pagination: {
          ...pagination
        },
        sorting
      }
    }
  });
};
