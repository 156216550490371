import React, { useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { IBreadcrumbsItem } from '../../../common/components/ui/Breadcrumbs';
import { routeLinks } from '../../routeLinks';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { AppHistoryType } from '../../../utils/createAppHistory';
import {
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { ReactComponent as IconPlus } from '../../../assets/img/icons/plus.svg';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { UserPermission } from '../../../utils/permissions';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { TransformedQueryParams, URL_KEY_PAGE } from '../../../store/urlKeys';
import { STORE_LOCATION } from '../../../store/storeKeys';
import { useInternships } from '../../../graphql/hooks/internships/useInternships';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { IconButton } from '../../../common/components/ui/IconButton/IconButton';
import { ReactComponent as FilterIcon } from '../../../assets/img/icons/Filter.svg';
import { BottomSheet } from '../../../common/components/ui/BottomSheet/BottomSheet';
import { useInternshipsFilter } from './useInternshipsFilter';
import { InternshipsList } from './InternshipsList/InternshipsList';
import s from './InternshipsPage.module.scss';

export interface InternshipsPageProps extends RouteComponentProps {
  history: AppHistoryType;
}

export enum InternshipPageTabs {
  ALL = 'ALL',
  RECOMMENDED = 'RECOMMENDED'
}

export function getInternshipsPageBreadcrumbs(
  { category = InternshipPageTabs.ALL }: { category: InternshipPageTabs } = {
    category: InternshipPageTabs.ALL
  }
): IBreadcrumbsItem[] {
  return [
    {
      name: 'Стажировки',
      path: `${routeLinks.internships.to}?category=${category}`
    }
  ];
}

export default function InternshipsPage({ history }: InternshipsPageProps) {
  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.InternshipCrud);
  const { isMobile, isDesktop } = useWindowSize();
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const query = useMemo<Partial<TransformedQueryParams>>(
    () => locationState.query || {},
    [locationState]
  );

  const page = query[URL_KEY_PAGE] || 1;

  const [filterComponent, filter] = useInternshipsFilter();

  const internshipsQuery = useInternships({
    filter,
    pagination: { page }
  });
  const internships = internshipsQuery.state.data?.list;
  const header = internshipsQuery.state.data?.header;
  const hasInternships = internships && internships.length > 0;

  const [showFilter, setShowFilter] = useState(false);

  return (
    <PageLayout>
      <LayoutContent>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={isDesktop ? 8 : 12}>
            <PageTitle
              className={s.InternshipsPage__pageTitle}
              title={'Стажировки'}
              showHeading={true}
              addButton={
                isMobile ? (
                  <IconButton
                    variant={
                      showFilter
                        ? ButtonVariant.primary
                        : ButtonVariant.secondary
                    }
                    icon={<FilterIcon />}
                    onClick={() => setShowFilter(true)}
                  />
                ) : null
              }
            >
              {!isDesktop && !isMobile && (
                <IconButton
                  variant={
                    showFilter ? ButtonVariant.primary : ButtonVariant.secondary
                  }
                  icon={<FilterIcon />}
                  onClick={() => setShowFilter(true)}
                />
              )}
              <BottomSheet
                title={'Фильтры'}
                open={!isDesktop && showFilter}
                onDismiss={() => setShowFilter(false)}
                snapPoints={({ minHeight }) => minHeight}
              >
                {filterComponent}
              </BottomSheet>
              {canEdit && (
                <div>
                  <ButtonLink
                    to={`${routeLinks.internships.to}/create`}
                    variant={ButtonVariant.secondary}
                    size={ButtonSize.small}
                    iconLeft={<IconPlus />}
                  >
                    Добавить стажировку
                  </ButtonLink>
                </div>
              )}
            </PageTitle>
            <div>
              {queryLoader(internshipsQuery) || (
                <>
                  {hasInternships ? (
                    <InternshipsList
                      internships={internships}
                      header={header}
                      isEditable={canEdit}
                    />
                  ) : (
                    <NoData />
                  )}
                </>
              )}
            </div>
          </LayoutGrid.GridItem>

          {isDesktop && (
            <LayoutGrid.GridItem cols={4}>
              {filterComponent}
            </LayoutGrid.GridItem>
          )}
        </LayoutGrid>
      </LayoutContent>
    </PageLayout>
  );
}
