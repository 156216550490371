import React from 'react';
import { EMDASH } from '@proscom/ui-utils';
import { SkillType } from '../../../../../graphql/types';
import { Tag } from '../../Tag/Tag';
import s from './UserSkills.module.scss';

export interface UserSkillsProps {
  skills?: SkillType[] | null;
}

export const UserSkills: React.FC<UserSkillsProps> = ({ skills }) => {
  return (
    <div className={s.UserSkills}>
      {skills && skills.length > 0 ? (
        <div className={s.UserSkills__list}>
          {skills.map(({ id, name }) => (
            <Tag key={id} className={s.UserSkills__tag}>
              {name}
            </Tag>
          ))}
        </div>
      ) : (
        EMDASH
      )}
    </div>
  );
};
