import React, { useMemo } from 'react';
import {
  UserPageBlock,
  UserPageBlockVariant
} from '../../UserBlocks/UserPageBlock/UserPageBlock';
import { LayoutGrid } from '../../../Layout/Layout';
import { FormSection } from '../../Form/FormSection/FormSection';
import { userFormNames } from '../../../../../store/users/UserConstants';
import { TextLink } from '../../TextLink/TextLink';
import { TextFieldPreview } from '../../TextFieldPreview/TextFieldPreview';
import { FormSelect } from '../../Form/FormSelect/FormSelect';
import { SelectSize } from '../../Select/Select';
import { convertDataListToSelectOptions } from '../../../../../utils/form/formConverters';
import { useCampuses } from '../../../../../graphql/hooks/campus/useCampuses';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { DATE_FORMAT_DAY_MONTH } from '../../../../../utils/date/date';
import {
  DegreeType,
  Maybe,
  UserContactType
} from '../../../../../graphql/types';
import { UserDegrees } from '../../UserBlocks/UserGeneral/UserDegrees';
import { TextFieldSize } from '../../TextField/TextField';
import { FormTextField } from '../../Form/FormTextField/FormTextField';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import { useHasPermission } from '../../../../hooks/useHasPermission';
import { UserPermission } from '../../../../../utils/permissions';

const useCampusOptions = () => {
  const campusesQuery = useCampuses();
  const queryCampuses = campusesQuery.state.data;
  const campusesLoading = campusesQuery.check.spinner;

  const campusOptions = useMemo(() => {
    return convertDataListToSelectOptions(queryCampuses || [], {
      valueKey: 'id',
      labelKey: 'name'
    });
  }, [queryCampuses]);

  return {
    options: campusOptions,
    loading: campusesLoading
  };
};

export interface EditGeneralProps {
  birthday?: string | null;
  email?: string | null;
  degrees?: DegreeType[] | null;
  contacts?: UserContactType[] | null;
  workPhone?: Maybe<string>;
  mobilePhone?: Maybe<string>;
}

const colsCount = 6;

export const EditGeneral: React.FC<EditGeneralProps> = ({
  birthday,
  email,
  workPhone,
  mobilePhone,
  degrees,
  contacts
}) => {
  const { user: currentUser } = useCurrentUser();

  const formattedBirthday = useFormatDate(birthday, DATE_FORMAT_DAY_MONTH);

  const { options, loading } = useCampusOptions();

  const canEditWorkPhone = useHasPermission(
    currentUser,
    UserPermission.UserEditWorkPhone
  );

  const canEditMobilePhone = useHasPermission(
    currentUser,
    UserPermission.UserEditMobilePhone
  );

  return (
    <UserPageBlock variant={UserPageBlockVariant.small}>
      {(formattedBirthday || email || degrees) && (
        <FormSection>
          <LayoutGrid>
            {formattedBirthday && (
              <LayoutGrid.GridItem cols={colsCount}>
                <FormSection>
                  <TextFieldPreview title={'День рождения'}>
                    {formattedBirthday}
                  </TextFieldPreview>
                </FormSection>
              </LayoutGrid.GridItem>
            )}

            {email && (
              <LayoutGrid.GridItem cols={colsCount}>
                <FormSection>
                  <TextFieldPreview title={'Email'}>
                    <TextLink href={`mailto:${email}`} noUnderline>
                      {email}
                    </TextLink>
                  </TextFieldPreview>
                </FormSection>
              </LayoutGrid.GridItem>
            )}

            {degrees && degrees.length > 0 && (
              <LayoutGrid.GridItem cols={colsCount}>
                <UserDegrees degrees={degrees} />
              </LayoutGrid.GridItem>
            )}

            {/*{contacts && contacts.length > 0 && (*/}
            {/*  <LayoutGrid.GridItem cols={colsCount}>*/}
            {/*    <UserContacts contacts={contacts} />*/}
            {/*  </LayoutGrid.GridItem>*/}
            {/*)}*/}
          </LayoutGrid>
        </FormSection>
      )}
      <FormSection>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={colsCount}>
            <FormSection>
              <FormSelect
                name={userFormNames.campus_id}
                options={options}
                label={'Кампус'}
                placeholder={'Выберите кампус'}
                size={SelectSize.large}
                isLoading={loading}
              />
            </FormSection>
          </LayoutGrid.GridItem>

          {canEditMobilePhone && (
            <LayoutGrid.GridItem cols={colsCount}>
              <FormTextField
                size={TextFieldSize.large}
                name={userFormNames.mobile_phone}
                label={'Мобильный телефон'}
              />
            </LayoutGrid.GridItem>
          )}

          {canEditWorkPhone && (
            <LayoutGrid.GridItem cols={colsCount}>
              <FormTextField
                size={TextFieldSize.large}
                name={userFormNames.work_phone}
                label={'Рабочий телефон'}
              />
            </LayoutGrid.GridItem>
          )}
        </LayoutGrid>
      </FormSection>
    </UserPageBlock>
  );
};
