import {
  PollAnswerInput,
  PollAttemptCreateInput,
  PollQuestionTypesEnum,
  PollType
} from '../../graphql/types';

export interface PollAnswer extends PollAnswerInput {
  questionType: PollQuestionTypesEnum;
}

export interface PollFormValues {
  answers: PollAnswer[];
  poll_id: string;
}

export class PollsUtils {
  static toForm(data: PollType): PollFormValues {
    return {
      poll_id: data.id,
      answers:
        data.questions?.map((question) => ({
          question_id: question.id,
          value_scale: null,
          value_text: '',
          questionType: question.type
        })) || []
    };
  }

  static fromCreateForm({
    answers,
    ...formData
  }: PollFormValues): PollAttemptCreateInput {
    return {
      answers: answers.map(({ questionType, ...answer }) => ({
        ...answer,
        value_text: answer.value_text || null
      })),
      ...formData
    };
  }
}
