import gql from 'graphql-tag';

export const FRAGMENT_SYSTEM_LINK = gql`
  fragment SystemLinkProps on SystemLinkType {
    id
    link
    name
    priority
  }
`;
