import { MutationOptions } from 'apollo-client';
import {
  OfferCreateInput,
  OfferType,
  OfferUpdateInput
} from '../../graphql/types';
import { clearCache } from '../../graphql/queriesUtils';
import {
  MUTATION_CREATE_OFFER,
  MUTATION_DELETE_OFFER,
  MUTATION_UPDATE_OFFER
} from '../../graphql/mutations/offers';

export class Offers {
  private static refetchQueries = ['queryGetOffersPage', 'queryGetOffer'];
  private static updateQueries = ['offersPage', 'offer'];
  private static updateFragments = ['OfferType'];

  public static CreateOffer: MutationOptions<
    {
      result: OfferType;
    },
    {
      input: OfferCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_OFFER,
    refetchQueries: Offers.refetchQueries,
    update: clearCache(Offers.updateQueries, Offers.updateFragments)
  };

  public static UpdateOffer: MutationOptions<
    {
      result: OfferType;
    },
    {
      input: OfferUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_OFFER,
    refetchQueries: Offers.refetchQueries,
    update: clearCache(Offers.updateQueries, Offers.updateFragments)
  };

  public static DeleteOffer: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_DELETE_OFFER,
    refetchQueries: Offers.refetchQueries,
    update: clearCache(Offers.updateQueries, Offers.updateFragments)
  };
}
