import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { FeedbackValidationSchema } from '../../../../store/validationSchema';
import { FormSection } from '../../../../common/components/ui/Form/FormSection/FormSection';
import { FormTextField } from '../../../../common/components/ui/Form/FormTextField/FormTextField';
import { FormTextArea } from '../../../../common/components/ui/Form/FormTextArea/FormTextArea';
import { Button, ButtonSize } from '../../../../common/components/ui/Button';
import { FeedbackUtils } from '../../../../store/feedback/feedbackUtils';
import { useGraphqlMutation } from '../../../../common/hooks/utils/useGraphqlMutation';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import { Feedback } from '../../../../store/feedback/feedback';
import { AppHistoryType } from '../../../../utils/createAppHistory';
import { routeLinks } from '../../../routeLinks';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { FormFileLoader } from '../../../../common/components/ui/Form/FormFileLoader/FormFileLoader';
import s from '../FeedbackPage.module.scss';

export interface FeedbackFormProps {
  history: AppHistoryType;
}

export const FeedbackForm: React.FC<FeedbackFormProps> = ({ history }) => {
  const leaveFeedback = useGraphqlMutation(Feedback.leaveFeedback);
  const leaveFeedbackRun = leaveFeedback.run;
  const leaveFeedbackLoading = leaveFeedback.loading;

  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
      setSubmitting(true);

      const input = FeedbackUtils.fromForm(data);

      leaveFeedbackRun({ variables: { input } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }

          toast.success('Обращение успешно отправлено');
          history.push(`${routeLinks.feedback.to}/success`);
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при отправке обращения. Попробуйте снова'
          );
        })
        .then(() => {
          setSubmitting(false);
        });
    },
    [leaveFeedbackRun, history]
  );

  return (
    <Formik
      initialValues={FeedbackUtils.formInitialValues}
      validationSchema={FeedbackValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form className={s.FeedbackForm}>
          <FormSection>
            <FormTextField
              name={'phone'}
              label={'Телефон'}
              maskProps={{ mask: '+7 (999) 999-99-99' }}
            />
          </FormSection>
          <FormSection>
            <FormTextField name={'topic'} label={'Тема обращения *'} />
          </FormSection>
          <FormSection>
            <FormTextArea name={'message'} label={'Сообщение *'} rows={5} />
          </FormSection>
          <FormSection>
            <FormFileLoader
              name={'documents'}
              label={'Файлы'}
              buttonText={'Прикрепить файлы'}
              fileType={FileTypeEnum.feedback_document}
              value={values?.document_files ?? undefined}
              multiple={true}
            />
          </FormSection>

          <div className={s.FeedbackForm__actions}>
            <Button
              className={s.FeedbackForm__actionButton}
              size={ButtonSize.large}
              type={'submit'}
              loading={leaveFeedbackLoading}
            >
              Отправить обращение
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
