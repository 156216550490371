import React, { useCallback, useEffect, useMemo } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { addDays } from 'date-fns';
import { isNil, min } from 'lodash-es';
import { useTimeoutRef } from '@proscom/ui-react';
import { VacationFormValidationSchema } from '../../../../store/validationSchema';
import {
  VacationAppProps,
  VacationsUtils
} from '../../../../store/vacations/VacationsUtils';
import {
  Title,
  TitleVariant
} from '../../../../common/components/ui/Title/Title';
import {
  VacationApplicationType,
  VacationScheduleType
} from '../../../../graphql/types';
import { Button } from '../../../../common/components/ui/Button';
import { useGraphqlMutation } from '../../../../common/hooks/utils/useGraphqlMutation';
import { Vacations } from '../../../../store/vacations/Vacations';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import { getDateUtc } from '../../../../utils/date/date';
import { tryNumberLike } from '../../../../utils/number';
import {
  useDayOffs,
  useVacationDaysAmount
} from '../../../../common/hooks/useDayOffs';
import { Loader } from '../../../../common/components/ui/Loader/Loader';
import { ReactComponent as IconCheck } from '../../../../assets/img/icons/check.svg';
import { vacationMaxStartDate, vacationMinStartDate } from '../../constants';
import { VacationAppFormCalendar } from './VacationAppFormCalendar';
import { VacationAppFormMain } from './VacationAppFormMain';
import s from './VacationAppForm.module.scss';

const formStatus = {
  submitted: 'FORM_SUBMITTED'
};

interface VacationAppFormCompProps extends FormikProps<VacationAppProps> {
  vacationsSchedules?: VacationScheduleType[] | null;
  vacationApps?: VacationApplicationType[];
  submitLoading?: boolean;
}

const VacationAppFormComp: React.FC<VacationAppFormCompProps> = ({
  vacationsSchedules,
  vacationApps,
  submitLoading,
  ...props
}) => {
  const { status, setStatus, submitForm, resetForm, setFieldValue } = props;
  const submitTO = useTimeoutRef();

  const values = props.values;
  const errors = props.errors;

  const positions = useMemo(() => {
    return vacationsSchedules?.map((v) => v.position) ?? [];
  }, [vacationsSchedules]);

  const selectedPositionId = tryNumberLike(values.position_id, null, true);

  const selectedPosition = positions.find((p) => p.id === selectedPositionId);

  const isHeadPosition = !!selectedPosition?.is_head;
  const isVacationsDisabled = !!selectedPosition?.vacations_disabled;
  const canSubmit =
    selectedPosition && !isVacationsDisabled && !Object.keys(errors).length;

  const daysAvailable = useMemo(() => {
    if (!vacationsSchedules?.length || isNil(selectedPositionId)) return null;
    return (
      vacationsSchedules.find((v) => v.position.id === selectedPositionId)
        ?.unassigned_days ?? null
    );
  }, [vacationsSchedules, selectedPositionId]);

  const startDate = useMemo(() => {
    return values.start ? new Date(getDateUtc(values.start)) : undefined;
  }, [values.start]);

  const holidayIntervalEndDate = useMemo(() => {
    return startDate ? addDays(startDate, daysAvailable || 0) : undefined;
  }, [startDate, daysAvailable]);

  const { daysOff, holidayDates, initialLoading } = useDayOffs({
    startDate: startDate ?? undefined,
    endDate: holidayIntervalEndDate
  });

  const maxStartDate = useMemo(() => {
    const positionEndDate = selectedPosition?.c_end_date
      ? new Date(getDateUtc(selectedPosition.c_end_date))
      : undefined;

    return min([positionEndDate, vacationMaxStartDate]);
  }, [selectedPosition]);

  const minEndDate = useMemo(() => {
    return startDate || vacationMinStartDate;
  }, [startDate]);

  const maxEndDate = useMemo(() => {
    const availEndDate = startDate
      ? addDays(startDate, (daysAvailable || 0) + daysOff.length - 1)
      : undefined;

    return min([availEndDate, maxStartDate]);
  }, [daysAvailable, daysOff, startDate, maxStartDate]);

  const selectedDaysAmount = useVacationDaysAmount({
    startDate: values.start,
    endDate: values.end
  });

  const formSubmitted = status === formStatus.submitted;

  useEffect(() => {
    if (formSubmitted) {
      resetForm({
        status: formStatus.submitted
      });
      submitTO.set(() => {
        setStatus(undefined);
      }, 3000);
    }
  }, [formSubmitted, setStatus, resetForm, submitTO]);

  useEffect(() => {
    setFieldValue('availableDaysAmount', daysAvailable);
  }, [daysAvailable, setFieldValue]);

  useEffect(() => {
    setFieldValue('selectedDaysAmount', selectedDaysAmount);
  }, [selectedDaysAmount, setFieldValue]);

  useEffect(() => {
    setFieldValue('isHeadPosition', isHeadPosition);
  }, [isHeadPosition, setFieldValue]);

  return (
    <Form className={s.VacationAppForm}>
      {(initialLoading && <Loader />) || (
        <>
          <Title
            className={s.VacationAppForm__title_mobile}
            variant={TitleVariant.h3}
            title={'Заявление на отпуск'}
          />

          <div className={s.VacationAppForm__grid}>
            <div className={s.VacationAppForm__gridMain}>
              <div className={s.VacationAppForm__main}>
                <Title
                  className={s.VacationAppForm__title}
                  variant={TitleVariant.h3}
                  title={'Заявление на отпуск'}
                />
                <VacationAppFormMain
                  minStartDate={vacationMinStartDate}
                  maxStartDate={maxStartDate}
                  minEndDate={minEndDate}
                  maxEndDate={maxEndDate}
                  positions={positions}
                  selectedPositionId={selectedPositionId}
                  daysAvailable={daysAvailable}
                  selectedDaysAmount={selectedDaysAmount}
                  isVacationsDisabled={isVacationsDisabled}
                  {...props}
                />
              </div>
            </div>

            <div className={s.VacationAppForm__gridCalendar}>
              <div className={s.VacationAppForm__calendar}>
                <VacationAppFormCalendar
                  minDate={vacationMinStartDate}
                  maxDate={maxEndDate}
                  daysAvailable={daysAvailable}
                  holidayDates={holidayDates}
                  vacationApps={vacationApps}
                  isVacationsDisabled={isVacationsDisabled}
                  {...props}
                />
              </div>
            </div>
          </div>

          <div className={s.VacationAppForm__actions}>
            <Button
              className={s.VacationAppForm__actionButton}
              type={'button'}
              iconLeft={formSubmitted ? <IconCheck /> : undefined}
              loading={submitLoading}
              disabled={!canSubmit}
              onClick={canSubmit ? submitForm : undefined}
            >
              {formSubmitted ? 'Заявление отправлено' : 'Отправить заявление'}
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export interface VacationAppFormProps
  extends Pick<
    VacationAppFormCompProps,
    'vacationsSchedules' | 'vacationApps'
  > {}

export const VacationAppForm: React.FC<VacationAppFormProps> = ({
  vacationsSchedules,
  vacationApps
}) => {
  const leaveVacApp = useGraphqlMutation(Vacations.leaveVacationApplication);
  const leaveVacAppRun = leaveVacApp.run;
  const leaveVacAppLoading = leaveVacApp.loading;

  const handleSubmit = useCallback(
    (data, { setSubmitting, setStatus }: FormikHelpers<VacationAppProps>) => {
      setSubmitting(true);

      const input = VacationsUtils.fromForm(data);

      leaveVacAppRun({ variables: { input } })
        .then((result) => {
          if (result.data?.result) {
            setStatus(formStatus.submitted);
            toast.success('Заявление на отпуск отправлено на согласование');
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при отправке заявления на отпуск. Попробуйте снова'
          );
        })
        .then(() => {
          setSubmitting(false);
        });
    },
    [leaveVacAppRun]
  );

  const initialValues = useMemo(() => {
    return VacationsUtils.toForm();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VacationFormValidationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <VacationAppFormComp
          vacationsSchedules={vacationsSchedules}
          vacationApps={vacationApps}
          submitLoading={leaveVacAppLoading}
          {...props}
        />
      )}
    </Formik>
  );
};
