import React from 'react';
import { FieldArray, FormikValues } from 'formik';
import {
  UserPageBlock,
  UserPageBlockVariant
} from '../../UserBlocks/UserPageBlock/UserPageBlock';
import { ReactComponent as IconPlus } from '../../../../../assets/img/icons/plus.svg';
import { LayoutGrid } from '../../../Layout/Layout';
import { FormSection } from '../../Form/FormSection/FormSection';
import { FormTextField } from '../../Form/FormTextField/FormTextField';
import { TextFieldSize } from '../../TextField/TextField';
import { userFormNames } from '../../../../../store/users/UserConstants';
import { Button, ButtonSize, ButtonVariant } from '../../Button';
import { ActionButton, ActionType } from '../../ActionButton/ActionButton';
import { FormDatePicker } from '../../Form/FormDatePicker/FormDatePicker';

const formName = userFormNames.publications;

export interface EditPublishProps {
  values?: FormikValues;
}

export const EditPublish: React.FC<EditPublishProps> = ({ values }) => {
  const pubs = values?.[formName];

  return (
    <FieldArray name={formName}>
      {({ push, remove }) => (
        <UserPageBlock
          actions={
            <Button
              size={ButtonSize.small}
              variant={ButtonVariant.secondary}
              iconLeft={<IconPlus />}
              onClick={() =>
                push({
                  name: undefined,
                  link: undefined,
                  date: undefined
                })
              }
            >
              Добавить публикацию
            </Button>
          }
        >
          {pubs?.map((pub, iPub) => (
            <UserPageBlock
              key={iPub}
              variant={UserPageBlockVariant.small}
              title={`Публикации и исследования ${iPub + 1}`}
              headContent={
                <ActionButton
                  actionType={ActionType.delete}
                  onClick={() => remove(iPub)}
                />
              }
            >
              <LayoutGrid>
                <LayoutGrid.GridItem cols={6}>
                  <FormSection>
                    <FormTextField
                      size={TextFieldSize.large}
                      name={`${formName}.${iPub}.name`}
                      label={'Название публикации'}
                    />
                  </FormSection>
                </LayoutGrid.GridItem>

                <LayoutGrid.GridItem cols={6}>
                  <FormSection>
                    <FormDatePicker
                      name={`${formName}.${iPub}.date`}
                      label={'Дата публикации'}
                    />
                  </FormSection>
                </LayoutGrid.GridItem>

                <LayoutGrid.GridItem cols={6}>
                  <FormSection>
                    <FormTextField
                      size={TextFieldSize.large}
                      name={`${formName}.${iPub}.link`}
                      label={'Ссылка на исследование или публикацию'}
                    />
                  </FormSection>
                </LayoutGrid.GridItem>
              </LayoutGrid>
            </UserPageBlock>
          ))}
        </UserPageBlock>
      )}
    </FieldArray>
  );
};
