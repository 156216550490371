import React, { useCallback, useMemo, useState } from 'react';
import {
  getCardPattern,
  Pattern
} from '../../../common/components/ui/Card/patterns';
import { DocumentSection } from '../../../common/components/ui/DocumentSection/DocumentSection';
import { LayoutGrid } from '../../../common/components/Layout/Layout';
import { CardDocSection } from '../../../common/components/ui/Cards/CardDocSection/CardDocSection';
import { DocumentSectionType } from '../../../graphql/types';
import { Modal } from '../../../common/components/ui/Modal/Modal';
import { Document } from '../../../common/components/ui/DocumentSection/Document/Document';
import s from '../DocumentsPage.module.scss';

const patterns: Pattern[] = [
  Pattern.rectangles,
  Pattern.circles,
  Pattern.triangles
];

export interface SectionsListProps {
  documentSections: DocumentSectionType[];
  editable?: boolean;
  ws?: any;
}

export const SectionsList: React.FC<SectionsListProps> = ({
  documentSections,
  editable,
  ws
}) => {
  const [modalActive, setModalActive] = useState(false);
  const [selSectionId, setSelSectionId] = useState<string | null>(null);
  const { isTablet } = ws;

  const selectedSection = useMemo(() => {
    return documentSections.find((s) => s.id === selSectionId);
  }, [documentSections, selSectionId]);

  const handleCardClick = useCallback((sectionId) => {
    setSelSectionId(sectionId);
    setModalActive(true);
  }, []);

  return (
    <>
      {editable ? (
        documentSections.map((section) => (
          <DocumentSection
            key={section.id}
            data={section}
            editable={editable}
          />
        ))
      ) : (
        <LayoutGrid>
          {documentSections.map((section, iSection) => (
            <LayoutGrid.GridItem key={iSection} cols={isTablet ? 8 : 6}>
              <CardDocSection
                pattern={getCardPattern(section.id, patterns)}
                title={section.name}
                onClick={() => handleCardClick(section.id)}
              />
            </LayoutGrid.GridItem>
          ))}
        </LayoutGrid>
      )}

      {!editable && (
        <Modal
          className={s.SectionsList__docsModal}
          title={selectedSection?.name}
          isOpen={modalActive}
          onClose={() => setModalActive(false)}
          onClosed={() => setSelSectionId(null)}
        >
          {selectedSection?.documents?.map((doc) => (
            <Document key={doc.id} {...doc} />
          ))}
        </Modal>
      )}
    </>
  );
};
