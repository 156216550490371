import React, { useCallback } from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Card, CardStyle } from '../../../common/components/ui/Card/Card';
import {
  getInternshipsPageBreadcrumbs,
  InternshipPageTabs
} from '../../internships/list/InternshipsPage';
import { routeLinks } from '../../routeLinks';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { Breadcrumbs } from '../../../common/components/ui/Breadcrumbs';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { tryAppendErrorId } from '../../../graphql/graphqlErrors';
import { URL_KEY_CATEGORY } from '../../../store/urlKeys';
import {
  InternshipContest,
  InternshipDuration,
  InternshipFinancialConditions,
  InternshipLocation,
  InternshipPurpose,
  InternshipTraineeCategory
} from '../../../graphql/types';
import { Internships } from '../../../store/internships/Internships';
import { InternshipTestForm } from './InternshipTestForm';
import s from './InternshipTestPage.module.scss';

const pageTitle = 'Тест на подбор стажировки';
const pageDescription =
  'После прохождения теста вам будут предложены наиболее подходящие для вас стажировки';

export interface InternshipTestFormValues {
  trainee_category?: InternshipTraineeCategory[];
  purpose?: InternshipPurpose[];
  duration?: InternshipDuration[];
  location?: InternshipLocation[];
  contest?: InternshipContest[];
  financial_conditions?: InternshipFinancialConditions[];
}

const initialValues: InternshipTestFormValues = {
  trainee_category: [],
  purpose: [],
  duration: [],
  location: [],
  contest: [],
  financial_conditions: []
};

const validationSchema = yup.object().shape({
  trainee_category: yup.array().min(1, 'Пожалуйста, ответьте на вопрос'),
  purpose: yup.array(),
  duration: yup.array(),
  location: yup.array(),
  contest: yup.array(),
  financial_conditions: yup.array()
});

const breadcrumbs = [
  ...getInternshipsPageBreadcrumbs({
    category: InternshipPageTabs.RECOMMENDED
  }),
  {
    path: `${routeLinks.internships.to}/test`,
    name: pageTitle
  }
];

export function InternshipTestPage({ history }) {
  const createMutation = useGraphqlMutation(
    Internships.createInternshipTestResult
  );
  const onSubmit = useCallback(
    (input) => {
      createMutation
        .run({ variables: { input: JSON.stringify(input) } })
        .then(() => {
          // history.push(
          // todo: почему-то запрос на получение результата не рефетчится, поэтому пока так:
          window.location = (`${routeLinks.internships.to}/?${URL_KEY_CATEGORY}=${InternshipPageTabs.RECOMMENDED}` as any) as Location;
          // );
        })
        .catch((err) => {
          toast.error(
            tryAppendErrorId('Произошла ошибка. Попробуйте снова', err)
          );
        });
    },
    [createMutation]
  );
  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={2} />
          <LayoutGrid.GridItem
            className={s.InternshipTestPage__content}
            cols={8}
          >
            <Card
              className={s.InternshipTestPage__titleCard}
              cardStyle={CardStyle.bordered}
            >
              <Title
                className={s.InternshipTestPage__title}
                variant={TitleVariant.h2}
              >
                {pageTitle}
              </Title>
              <div className={s.InternshipTestPage__description}>
                {pageDescription}
              </div>
            </Card>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {(props) => <InternshipTestForm {...props} />}
            </Formik>
          </LayoutGrid.GridItem>
        </LayoutGrid>
      </LayoutContent>
    </PageLayout>
  );
}
