import React from 'react';
import clsx from 'clsx';
import { BaseLink, BaseLinkProps } from '../BaseLink/BaseLink';
import { Icon, IconProps } from '../Icon/Icon';
import { ReactComponent as IconArrowLink } from '../../../../assets/img/icons/ArrowLink.svg';
import s from './TextLink.module.scss';

export enum TextLinkVariant {
  blue = 'blue',
  black = 'black'
}

export enum TextLinkIconPosition {
  left = 'left',
  right = 'right'
}

export interface TextLinkProps extends BaseLinkProps {
  icon?: IconProps['icon'];
  iconProps?: Omit<IconProps, 'icon' | 'onClick'>;
  iconPosition?: TextLinkIconPosition;
  variant?: TextLinkVariant;
  noUnderline?: boolean;
  noHover?: boolean;
  showArrow?: boolean;
  isExternal?: boolean;
}

export interface TextLinkIconProps extends Omit<IconProps, 'icon'> {
  icon?: IconProps['icon'];
}

export const TextLinkIcon: React.FC<TextLinkIconProps> = ({
  className,
  icon,
  ...props
}) => {
  const linkIcon = icon || IconArrowLink;

  return (
    <Icon
      className={clsx(s.TextLink__icon, className)}
      icon={linkIcon}
      boxSize={16}
      iconSize={12}
      {...props}
    />
  );
};

export const TextLink: React.FC<TextLinkProps> = ({
  className,
  icon,
  iconProps,
  iconPosition = TextLinkIconPosition.right,
  variant = TextLinkVariant.blue,
  noUnderline,
  noHover,
  showArrow,
  isExternal,
  children,
  ...props
}) => {
  const linkIcon = icon || (showArrow && IconArrowLink);

  const iconComp = linkIcon ? (
    <TextLinkIcon
      className={clsx(
        iconProps?.className,
        s[`TextLink__icon_${iconPosition}`],
        {
          [s.TextLink__iconArrowExternal]: isExternal && showArrow
        }
      )}
      icon={linkIcon}
      {...iconProps}
    />
  ) : null;

  return (
    <BaseLink
      className={clsx(s.TextLink, className, {
        [s.TextLink_noUnderline]: noUnderline,
        [s.TextLink_noHover]: noHover,
        [s[`TextLink_${variant}`]]: variant
      })}
      {...props}
    >
      {iconPosition === TextLinkIconPosition.left && iconComp}
      {children}
      {iconPosition === TextLinkIconPosition.right && iconComp}
    </BaseLink>
  );
};
