import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { useGraphqlMutation } from '../../common/hooks/utils/useGraphqlMutation';
import { CourseStatus, CourseType, CourseTypesEnum } from '../../graphql/types';
import { Courses } from '../../store/courses/Courses';
import { handleDefaultError } from '../../utils/handleDefaultError';
import {
  CoursesDraftValidationSchema,
  CoursesPublicValidationSchema
} from '../../store/validationSchema';

export function useUpdateCourseStatus(
  courseId: string,
  courseType?: CourseTypesEnum
) {
  const [validationLoading, setValidationLoading] = useState(false);

  const updateCourse = useGraphqlMutation(
    courseType === CourseTypesEnum.Longread
      ? Courses.updateLongreadCourse
      : Courses.updateAnnounceCourse
  );
  const updateCourseRun = updateCourse.run;
  const updateCourseLoading = updateCourse.loading || validationLoading;

  const validateCourse = useCallback(
    (course: CourseType, validateForStatus: CourseStatus) => {
      const schema =
        validateForStatus === CourseStatus.Published
          ? CoursesPublicValidationSchema
          : CoursesDraftValidationSchema;
      return new Promise((resolve, reject) => {
        setValidationLoading(true);
        schema
          .validate(course)
          .then(resolve)
          .catch(reject)
          .finally(() => {
            setValidationLoading(false);
          });
      });
    },
    []
  );

  const updateStatus = useCallback(
    (status: CourseStatus) => {
      const input = {
        id: courseId,
        status
      };

      const msg =
        status === CourseStatus.Published
          ? 'Курс опубликован'
          : 'Курс архивирован';

      updateCourseRun({ variables: { input } })
        .then((result) => {
          if (result.data?.result) {
            toast.success(msg);
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(err, 'Произошла ошибка при обновлении курса');
        });
    },
    [courseId, updateCourseRun]
  );

  const updateWithValidation = useCallback(
    (course: CourseType, status: CourseStatus) => {
      validateCourse(course, status)
        .then(() => {
          updateStatus(status);
        })
        .catch(() => {
          toast.error(
            'Для публикации курса необходимо заполнить все обязательные поля на станице редактирования'
          );
        });
    },
    [validateCourse, updateStatus]
  );

  return {
    updateStatus,
    updateStatusLoading: updateCourseLoading,
    validateCourse,
    updateWithValidation
  };
}
