import React, { useCallback } from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Card, CardStyle } from '../../../common/components/ui/Card/Card';
import { getCoursesPageBreadcrumbs } from '../list/CoursesPage';
import { routeLinks } from '../../routeLinks';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { Breadcrumbs } from '../../../common/components/ui/Breadcrumbs';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { tryAppendErrorId } from '../../../graphql/graphqlErrors';
import { URL_KEY_SOURCE } from '../../../store/urlKeys';
import {
  CourseCompletionProof,
  CourseLearningFormat,
  CourseListenerCategory,
  CourseSubjectsFilter
} from '../../../graphql/types';
import { CoursePrice } from '../../../store/courses/CourseTest';
import { CourseUtils } from '../../../store/courses/CourseUtils';
import { Courses } from '../../../store/courses/Courses';
import { CoursesPageTabs } from '../types';
import { CourseTestForm } from './CourseTestForm';
import s from './CoursesTestPage.module.scss';

const pageTitle = 'Тест на подбор образовательного курса';
const pageDescription =
  'После прохождения теста вам будут предложены наиболее подходящие для вас курсы';

export interface CoursesTestFormValues {
  learning_format?: CourseLearningFormat[];
  course_subject_ids?: CourseSubjectsFilter['ids'];
  price?: CoursePrice[];
  completion_proof?: CourseCompletionProof[];
  listener_category?: CourseListenerCategory[];
}

const initialValues: CoursesTestFormValues = {
  learning_format: [],
  course_subject_ids: [],
  price: [],
  completion_proof: [],
  listener_category: []
};

const validationSchema = yup.object().shape({
  learning_format: yup.array().min(1, 'Выберите формат обучения'),
  course_subject_ids: yup.array().min(1, 'Выберите тему обучения'),
  price: yup.array(),
  completion_proof: yup.array(),
  listener_category: yup.array()
});

const breadcrumbs = [
  ...getCoursesPageBreadcrumbs({ type: CoursesPageTabs.RECOMMENDED }),
  {
    path: `${routeLinks.courses.to}/test`,
    name: pageTitle
  }
];

export function CoursesTestPage({ history }) {
  const createMutation = useGraphqlMutation(Courses.createCourseTestResult);
  const onSubmit = useCallback(
    (input) => {
      const data = CourseUtils.fromTestFrom(input);
      createMutation
        .run({ variables: { input: JSON.stringify(data) } })
        .then((result) => {
          // history.push(
          // todo: почему-то запрос на получение результата не рефетчится, поэтому пока так:
          window.location = (`${routeLinks.courses.to}/?${URL_KEY_SOURCE}=${CoursesPageTabs.RECOMMENDED}` as any) as Location;
          //);
        })
        .catch((err) => {
          toast.error(
            tryAppendErrorId('Произошла ошибка. Попробуйте снова', err)
          );
        });
    },
    [createMutation]
  );
  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={2} />
          <LayoutGrid.GridItem className={s.CoursesTestPage__content} cols={8}>
            <Card
              className={s.CoursesTestPage__titleCard}
              cardStyle={CardStyle.bordered}
            >
              <Title
                className={s.CoursesTestPage__title}
                variant={TitleVariant.h2}
              >
                {pageTitle}
              </Title>
              <div className={s.CoursesTestPage__description}>
                {pageDescription}
              </div>
            </Card>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {(props) => <CourseTestForm {...props} />}
            </Formik>
          </LayoutGrid.GridItem>
        </LayoutGrid>
      </LayoutContent>
    </PageLayout>
  );
}
