import React from 'react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { UserPermission } from '../../../utils/permissions';
import { FeedbackForm } from './FeedbackForm/FeedbackForm';
import { FeedbackList } from './FeedbackList/FeedbackList';
import s from './FeedbackPage.module.scss';

export default function FeedbackPage({ history }) {
  const { user } = useCurrentUser();
  const canViewFeedback = useHasPermission(
    user,
    UserPermission.FeedbackViewCrud
  );

  return (
    <PageLayout>
      <LayoutContent>
        <PageTitle
          className={s.FeedbackPage__pageTitle}
          captionClassName={s.FeedbackPage__pageCaption}
          title={'Обратная связь'}
          caption={
            <>
              В этом разделе вы можете направить любую информацию для Управления
              по работе с персоналом. <br />
              Пожалуйста, воспользуйтесь формой обратной связи для обращений и
              предложений по улучшению в работе HR-сервиса. В случае, если вы
              столкнулись с какими-то проблемами при работе с HR-сервисами, вы
              также можете направить информацию о них, используя форму обратной
              связи.
            </>
          }
          showHeading={true}
        />

        <div className={s.FeedbackPage__content}>
          {user &&
            (canViewFeedback ? (
              <FeedbackList />
            ) : (
              <FeedbackForm history={history} />
            ))}
        </div>
      </LayoutContent>
    </PageLayout>
  );
}
