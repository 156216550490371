import { Form, Formik, useField } from 'formik';
import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { CourseTeacherInput } from '../../../../graphql/types';
import { Card, CardStyle } from '../../../../common/components/ui/Card/Card';
import {
  ActionButton,
  ActionType
} from '../../../../common/components/ui/ActionButton/ActionButton';


import { FormTextField } from '../../../../common/components/ui/Form/FormTextField/FormTextField';
import { Button, ButtonVariant } from '../../../../common/components/ui/Button';
import { Modal } from '../../../../common/components/ui/Modal/Modal';
import s from './FormCourseTeachers.module.scss';

export function FormCourseTeachers({ name }) {
  const [{ value }, { error }, { setValue }] = useField(name);
  return <CourseTeachers value={value} error={error} onChange={setValue} />;
}

const initialValues: CourseTeacherInput = {
  name: '',
  position: '',
  speciality: ''
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Введите имя'),
  position: yup.string().required('Введите должность')
});

interface CourseTeachersProps {
  value?: CourseTeacherInput[];
  error?: string;
  onChange: (teachers: CourseTeacherInput[]) => void;
}

function CourseTeachers({ value, error, onChange }: CourseTeachersProps) {
  const onAdd = useCallback(
    (newValue) => {
      if (Array.isArray(value)) {
        onChange([...value, newValue]);
      } else {
        onChange([newValue]);
      }
      setAddTeacherModalOpen(false);
    },
    [value, onChange]
  );
  const onTeacherDelete = useCallback(
    (tI) => {
      onChange(value?.filter((_, vI) => vI !== tI) || value || []);
    },
    [onChange, value]
  );
  const [addTeacherModalOpen, setAddTeacherModalOpen] = useState(false);
  return (
    <div className={s.CourseTeachers}>
      {value && value.length > 0 ? (
        <div className={s.CourseTeachers__list}>
          {value.map((t, tI) => (
            <CourseTeacher
              key={`${t.name}.${t.position}.${t.speciality}`}
              {...t}
              onDelete={() => onTeacherDelete(tI)}
            />
          ))}
        </div>
      ) : (
        <div className={s.CourseTeachers__emptyText}>
          У курса еще нет учителей
        </div>
      )}
      {error && <div className={s.CourseTeachers__error}>{error}</div>}
      <Button
        variant={ButtonVariant.tertiary}
        onClick={() => {
          setAddTeacherModalOpen(true);
        }}
      >
        Добавить учителя
      </Button>
      <Modal
        className={s.CourseTeachers__modal}
        title={'Добавить учителя'}
        isOpen={addTeacherModalOpen}
        onClose={() => {
          setAddTeacherModalOpen(false);
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onAdd}
        >
          {() => <CourseTeacherCreateForm />}
        </Formik>
      </Modal>
    </div>
  );
}

interface CourseTeacherProps extends CourseTeacherInput {
  onDelete?: React.MouseEventHandler;
}

const CourseTeacher = React.memo(function CourseTeacher({
  name,
  position,
  speciality,
  onDelete
}: CourseTeacherProps) {
  return (
    <Card
      className={s.CourseTeacher}
      classes={{
        actions: s.CourseTeacher__actions
      }}
      actions={
        <ActionButton actionType={ActionType.delete} onClick={onDelete} />
      }
      title={name}
      cardStyle={CardStyle.bordered}
    >
      {position && (
        <div className={s.CourseTeacher__position}>Должность: {position}</div>
      )}
      {speciality && (
        <div className={s.CourseTeacher__speciality}>
          Специализация: {speciality}
        </div>
      )}
    </Card>
  );
});

const CourseTeacherCreateForm = React.memo(function CourseTeacherCreateForm() {
  return (
    <Form className={s.CourseTeacherCreateForm}>
      <FormTextField
        className={s.CourseTeacherCreateForm__field}
        name={'name'}
        label={'Имя учителя'}
        placeholder={'Имя учителя'}
      />
      <FormTextField
        className={s.CourseTeacherCreateForm__field}
        name={'position'}
        label={'Должность'}
        placeholder={'Должность'}
      />
      <FormTextField
        className={s.CourseTeacherCreateForm__field}
        name={'speciality'}
        label={'Специализация'}
        placeholder={'Специализация'}
      />
      <Button
        className={s.CourseTeacherCreateForm__button}
        variant={ButtonVariant.secondary}
        type={'submit'}
      >
        Добавить
      </Button>
    </Form>
  );
});
