import colors from '../_colors.module.scss';

export const COLORS = {
  colorBackgroundWhite: colors.colorBackgroundWhite,
  colorBackgroundLightGray: colors.colorBackgroundLightGray,
  colorBackgroundDark: colors.colorBackgroundDark,

  colorSurfacePrimary: colors.colorSurfacePrimary,
  colorSurfacePopup: colors.colorSurfacePopup,
  colorSurfaceAccent: colors.colorSurfaceAccent,
  colorSurfaceAttention: colors.colorSurfaceAttention,
  colorSurfaceGradient: colors.colorSurfaceGradient,
  colorSurfaceElements100: colors.colorSurfaceElements100,
  colorSurfaceElements200: colors.colorSurfaceElements200,
  colorSurfaceElements300: colors.colorSurfaceElements300,

  colorOnSurfaceContrast: colors.colorOnSurfaceContrast,
  colorOnSurfacePrimary: colors.colorOnSurfacePrimary,
  colorOnSurfaceSecondary: colors.colorOnSurfaceSecondary,
  colorOnSurfaceTertiary: colors.colorOnSurfaceTertiary,
  colorOnSurfaceAccent: colors.colorOnSurfaceAccent,
  colorOnSurfaceAttention: colors.colorOnSurfaceAttention,
  colorOnSurfaceElements100: colors.colorOnSurfaceElements100,
  colorOnSurfaceElements200: colors.colorOnSurfaceElements200,
  colorOnSurfaceElements300: colors.colorOnSurfaceElements300,

  colorAccent500: colors.colorAccent500,
  colorAccent400: colors.colorAccent400,
  colorAccent300: colors.colorAccent300,
  colorAccent300_1: colors.colorAccent300_1,
  colorAccent200: colors.colorAccent200,
  colorAccent100: colors.colorAccent100,

  colorDividers300: colors.colorDividers300,
  colorDividers200: colors.colorDividers200,
  colorDividers100: colors.colorDividers100,

  colorTextContrast: colors.colorTextContrast,
  colorTextPrimary: colors.colorTextPrimary,
  colorTextSecondary: colors.colorTextSecondary,
  colorTextTertiary: colors.colorTextTertiary,
  colorTextQuaternary: colors.colorTextQuaternary,
  colorTextAccent: colors.colorTextAccent,

  colorSystemError: colors.colorSystemError,
  colorSystemWarning: colors.colorSystemWarning,
  colorSystemAccess: colors.colorSystemAccess,
  colorSystemLinkOpened: colors.colorSystemLinkOpened
};
