import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/img/icons/arrowDown.svg';
import { Avatar, AvatarProps, AvatarSize } from '../Avatar/Avatar';
import { Dropdown } from '../Dropdown/Dropdown';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { Button } from '../Button';
import s from './Profile.module.scss';

export interface ProfileClasses {
  root?: string;
  avatar?: string;
  menuButton?: string;
}

export interface ProfileAction {
  label: ReactNode;
  to?: string;
  onClick?: () => void;
  className?: string;
}

export interface ProfileProps extends AvatarProps {
  actions: ProfileAction[];
  classes?: ProfileClasses;
}

export const Profile: React.FC<ProfileProps> = ({
  classes,
  actions,
  size = AvatarSize.medium,
  src,
  className
}) => {
  return (
    <Dropdown
      content={() => <ContextMenu items={actions} />}
      classes={{
        trigger: s[`Profile_${size}`],
        content: s.Profile__content
      }}
    >
      {(props) => (
        <Button
          className={clsx(
            s.Profile,
            s[`Profile_${size}`],
            className,
            classes?.root
          )}
          onClick={() => {
            props?.onToggle?.(!props.isOpen);
          }}
        >
          <Avatar
            className={clsx(s.Profile__avatar, classes?.avatar)}
            size={size}
            src={src}
          />
          <div
            className={clsx(
              s.Profile__actionsButton,
              s[`Profile__actionsButton_${size}`],
              classes?.menuButton
            )}
          >
            <ArrowDownIcon
              className={clsx(
                s.Profile__actionsButtonIcon,
                s[`Profile__actionsButtonIcon_${size}`],
                {
                  [s.Profile__actionsButtonIcon_open]: props?.isOpen
                }
              )}
            />
          </div>
        </Button>
      )}
    </Dropdown>
  );
};
