import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as ClipIcon } from '../../../../assets/img/icons/clip.svg';
import { ActionButton, ActionType } from '../ActionButton/ActionButton';
import { TextLink } from '../TextLink/TextLink';
import { Icon } from '../Icon/Icon';
import { TextField, TextFieldSize } from '../TextField/TextField';
import s from './FileBox.module.scss';

export interface FileBoxClasses {
  root?: string;
  name?: string;
}

export interface LoadedFile {
  file: File;
  fileId: string;
}

export interface FileBoxProps {
  className?: string;
  classes?: FileBoxClasses;
  file?: LoadedFile;
  fileName?: string;
  fileSrc?: string;
  editable?: boolean;
  onClick?: (e: React.MouseEvent<any>) => void;
  onChange?: (name: string) => void;
  onDelete?: () => void;
}

export const FileBox: React.FC<FileBoxProps> = ({
  className,
  classes,
  file,
  fileName,
  fileSrc,
  editable,
  onClick,
  onChange,
  onDelete
}) => {
  const name = fileName || file?.file.name || fileSrc || 'Файл';
  const [nameValue, setNameValue] = useState(name);
  const [changing, setChanging] = useState(false);

  useEffect(() => {
    setNameValue(name);
  }, [name]);

  const handleChangeClick = useCallback(() => {
    setChanging(true);
  }, []);

  const handleApproveChange = useCallback(() => {
    setChanging(false);
    onChange?.(nameValue || '');
  }, [onChange, nameValue]);

  const handleCancelChange = useCallback(() => {
    setChanging(false);
  }, []);

  return (
    <div className={clsx(s.FileBox, className, classes?.root)}>
      {changing ? (
        <TextField
          className={s.FileBox__textField}
          size={TextFieldSize.small}
          value={nameValue}
          onChange={setNameValue}
        />
      ) : (
        <>
          <Icon className={s.FileBox__icon} icon={ClipIcon} boxSize={16} />
          {fileSrc || !!onClick ? (
            <TextLink
              title={name}
              className={clsx(s.FileBox__name, classes?.name)}
              href={fileSrc || ''}
              noUnderline
              onClick={onClick}
            >
              {name}
            </TextLink>
          ) : (
            <div className={clsx(s.FileBox__name, classes?.name)}>{name}</div>
          )}
        </>
      )}

      {editable && (
        <div className={s.FileBox__actions}>
          <>
            {changing ? (
              <>
                <ActionButton
                  className={s.FileBox__actionButton}
                  actionType={ActionType.approve}
                  onClick={handleApproveChange}
                />
                <ActionButton
                  className={s.FileBox__actionButton}
                  actionType={ActionType.cancel}
                  onClick={handleCancelChange}
                />
              </>
            ) : (
              onChange && (
                <ActionButton
                  className={s.FileBox__actionButton}
                  actionType={ActionType.edit}
                  onClick={handleChangeClick}
                />
              )
            )}
          </>
          {onDelete && (
            <ActionButton
              className={s.FileBox__actionButton}
              actionType={ActionType.delete}
              onClick={onDelete}
            />
          )}
        </div>
      )}
    </div>
  );
};
