import React from 'react';
import clsx from 'clsx';
import { Icon, IconProps } from '../Icon/Icon';
import s from './SwitchBox.module.scss';

export type SwitchBoxButtonIdType = string | number;

export interface SwitchBoxButtonProps {
  id: SwitchBoxButtonIdType;
  icon: IconProps['icon'];
  selected?: boolean;
  onClick: (id: SwitchBoxButtonIdType) => void;
}

export const SwitchBoxButton: React.FC<SwitchBoxButtonProps> = ({
  id,
  icon,
  selected,
  onClick
}) => {
  return (
    <Icon
      className={clsx(s.SwitchBoxButton, {
        [s.SwitchBoxButton_selected]: selected
      })}
      iconClassName={s.SwitchBoxButton__icon}
      icon={icon}
      boxSize={'auto'}
      onClick={() => onClick(id)}
    />
  );
};
