import React, { useCallback } from 'react';
import { useField } from 'formik';
import { pick } from 'lodash-es';
import { FileLoader, FileLoaderProps } from '../../FileLoader/FileLoader';

export interface FormFileLoaderProps
  extends Pick<
    FileLoaderProps,
    'label' | 'value' | 'fileType' | 'multiple' | 'buttonText' | 'editable'
  > {
  name: string;
}

export const FormFileLoader: React.FC<FormFileLoaderProps> = ({
  name,
  multiple,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(name);

  const handleChange = useCallback(
    (files) => {
      if (multiple) {
        const hasFiles = files && files.length > 0;
        setValue(
          hasFiles ? files.map((f) => pick(f, ['id', 'name'])) : undefined
        );
      } else {
        setValue(files[0] ? pick(files[0], ['id', 'name']) : undefined);
      }
    },
    [setValue, multiple]
  );

  return <FileLoader onChange={handleChange} multiple={multiple} {...props} />;
};
