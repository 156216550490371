import React from 'react';
import { useField } from 'formik';
import { HelpText, HelpTextType } from '../../HelpText/HelpText';
import { TextEditor } from '../../TextEditor/TextEditor';
import s from './FormTextEditor.module.scss';

export interface FormTextEditorProps {
  className?: string;
  name: string;
  label?: string;
}

export const FormTextEditor: React.FC<FormTextEditorProps> = ({
  className,
  name,
  label
}) => {
  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;

  const { error, touched } = meta;

  const errorMessage = touched && error ? error : undefined;

  return (
    <label>
      {label && <div className={s.FormTextEditor__label}>{label}</div>}
      <TextEditor className={className} onChange={setValue} value={value} />
      {errorMessage && (
        <HelpText text={errorMessage} type={HelpTextType.error} />
      )}
    </label>
  );
};
