import { Routes, RouteType } from '../common/components/utils/Routes';
import { RequireAuth } from './RequireAuth';
import { routeLinks } from './routeLinks';
import MainPage from './main/MainPage';
import NotFoundPage from './notFound/NotFoundPage';
import WelcomePage from './welcome/WelcomePage';
import DocumentsPage from './documents/DocumentsPage';
import ManualsRoutes from './manuals';
import ArticlesRoutes from './articles';
import SystemLinksRoutes from './systemLinks';
import OffersRoutes from './offers';
import UsersRoutes from './users';
import ProfileRoutes from './profile';
import PollsRoutes from './polls';
import ProjectsRoutes from './projects';
import CoursesRoutes from './courses';
import LoginPage from './login/LoginPage';
import RedirectIfLoggedIn from './RedirectIfLoggedIn';
import InternshipsRoutes from './internships';
import SettingsRoutes from './settings';
import InDevPage from './inDev/InDevPage';
import ReserveRoutes from './personnelReserve';
import FeedbackRoutes from './feedback';
import NewComersPage from './newComers/NewComersPage';
import VacationsRoutes from './vacations';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: WelcomePage
  },
  {
    path: routeLinks.login.to,
    exact: true,
    component: RedirectIfLoggedIn(LoginPage)
  },
  {
    path: routeLinks.main.to,
    exact: true,
    component: RequireAuth(MainPage)
  },
  {
    path: routeLinks.manuals.to,
    component: ManualsRoutes
  },
  {
    path: routeLinks.documents.to,
    exact: true,
    component: RequireAuth(DocumentsPage)
  },
  // {
  //   path: routeLinks.notices.to,
  //   component: NoticesRoutes
  // },
  {
    path: routeLinks.articles.to,
    component: ArticlesRoutes
  },
  // {
  //   path: routeLinks.events.to,
  //   component: EventsRoutes
  // },
  {
    path: routeLinks.systemLinks.to,
    component: SystemLinksRoutes
  },
  {
    path: routeLinks.offers.to,
    component: OffersRoutes
  },
  {
    path: routeLinks.users.to,
    component: UsersRoutes
  },
  {
    path: routeLinks.profile.to,
    component: ProfileRoutes
  },
  {
    path: routeLinks.polls.to,
    component: PollsRoutes
  },
  {
    path: routeLinks.settings.to,
    component: SettingsRoutes
  },
  {
    path: routeLinks.projects.to,
    component: ProjectsRoutes
  },
  {
    path: routeLinks.courses.to,
    component: CoursesRoutes
  },
  {
    path: routeLinks.internships.to,
    component: InternshipsRoutes
  },
  {
    path: routeLinks.reserve.to,
    component: ReserveRoutes
  },
  {
    path: routeLinks.feedback.to,
    component: FeedbackRoutes
  },
  {
    path: routeLinks.newcomers.to,
    exact: true,
    component: RequireAuth(NewComersPage)
  },
  {
    path: routeLinks.vacation.to,
    component: VacationsRoutes
  },

  // IN DEV
  // {
  //   path: routeLinks.rate360.to,
  //   component: Rate360Routes
  // },
  {
    path: routeLinks.about.to,
    exact: true,
    component: InDevPage
  },
  {
    path: routeLinks.newcomers.to,
    exact: true,
    component: InDevPage
  },
  {
    path: routeLinks.faq.to,
    exact: true,
    component: InDevPage
  },
  {
    path: routeLinks.community.to,
    exact: true,
    component: InDevPage
  },
  {
    path: routeLinks.calendar.to,
    exact: true,
    component: InDevPage
  },
  {
    path: routeLinks.contests.to,
    exact: true,
    component: InDevPage
  },
  {
    path: routeLinks.competences.to,
    exact: true,
    component: InDevPage
  },

  // 404
  {
    component: NotFoundPage
  }
];

const AppRoutes = Routes(routes);

export default AppRoutes;
