import { useCallback, useEffect } from 'react';

export function usePreventWindowScroll(isPrevented?: boolean) {
  const preventScroll = useCallback((isPrevented) => {
    document.documentElement.classList.toggle('scroll-prevented', isPrevented);
  }, []);

  useEffect(() => {
    preventScroll(isPrevented);
  }, [isPrevented, preventScroll]);

  return preventScroll;
}
