import { OptionTypeBase } from 'react-select/src/types';
import { CourseDocumentInput, CourseType, TagType } from '../../graphql/types';
import { IContentBlock } from '../../common/components/ui/Form/FormContentEditor/Content';

export interface CourseTypeForm extends Omit<CourseType, 'tags' | 'content'> {
  content: IContentBlock[];
  tags: TagType[] | OptionTypeBase[];
  documents?: CourseDocumentInput[];
}

export enum CoursesPageTabs {
  ALL = 'ALL',
  MIPT = 'MIPT',
  PARTNER = 'PARTNER',
  RECOMMENDED = 'RECOMMENDED',
  ARCHIVED = 'ARCHIVED'
}

export enum CoursesStatusName {
  ARCHIVED = 'Архив',
  DRAFT = 'Черновик',
  PUBLISHED = 'Опубликован'
}
