import React from 'react';
import { useField } from 'formik';
import { PointScale, PointScaleProps } from '../../PointScale/PointScale';

export interface FormPointScaleProps extends Omit<PointScaleProps, 'title'> {
  label?: string;
}

export const FormPointScale: React.FC<FormPointScaleProps> = ({
  label,
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  return (
    <PointScale
      {...props}
      title={label}
      value={value}
      onChange={(value) => {
        setValue(value);
      }}
      name={name}
      errorMessage={touched && error ? error : undefined}
    />
  );
};
