import gql from 'graphql-tag';

export const FRAGMENT_DOCUMENT = gql`
  fragment DocumentProps on DocumentType {
    id
    file_id
    document_section_id
    name
    priority
  }
`;

export const FRAGMENT_DOCUMENT_SECTION = gql`
  fragment DocumentSectionProps on DocumentSectionType {
    id
    name
  }
`;
