import React, { useMemo } from 'react';
import clsx from 'clsx';
import { HashLink, HashLinkProps } from 'react-router-hash-link';
import { useHashScroll } from '../../../hooks/useHashScroll';
import { useHashInView } from '../../../hooks/useHashInView';
import s from './AnchorSwitch.module.scss';

export type SwitchAnchorType = { id: string; label?: string };

export interface AnchorSwitchItemProps extends Pick<HashLinkProps, 'scroll'> {
  className?: string;
  anchor: SwitchAnchorType;
  active?: boolean;
}

export const AnchorSwitchItem: React.FC<AnchorSwitchItemProps> = ({
  className,
  anchor,
  scroll,
  active
}) => {
  return (
    <HashLink
      className={clsx(s.AnchorSwitchItem, className, {
        [s.AnchorSwitchItem_active]: active
      })}
      to={`#${anchor.id}`}
      scroll={scroll}
    >
      <div className={s.AnchorSwitchItem__dot} />
      {anchor.label && (
        <div className={s.AnchorSwitchItem__label}>{anchor.label}</div>
      )}
    </HashLink>
  );
};

export interface AnchorSwitchClasses {
  root?: string;
  item?: string;
}

export interface AnchorSwitchProps {
  className?: string;
  classes?: AnchorSwitchClasses;
  anchors: SwitchAnchorType[];
  activeAnchorId?: SwitchAnchorType['id'];
  scrollOffset?: number;
  scrollOffsettingHeader?: boolean;
  smooth?: boolean;
}

export const AnchorSwitch: React.FC<AnchorSwitchProps> = ({
  className,
  classes,
  anchors,
  activeAnchorId,
  scrollOffset,
  scrollOffsettingHeader,
  smooth
}) => {
  const { scrollToHash } = useHashScroll(
    scrollOffset,
    scrollOffsettingHeader,
    smooth
  );

  const hashes = useMemo(() => {
    return anchors.map((a) => a.id);
  }, [anchors]);

  const hashesInView = useHashInView(hashes);

  const activeId = activeAnchorId || hashesInView[0];

  return (
    <div className={clsx(s.AnchorSwitch, className, classes?.root)}>
      {anchors.map((item, iItem) => (
        <AnchorSwitchItem
          key={iItem}
          className={clsx(s.AnchorSwitch__item, classes?.item)}
          anchor={item}
          scroll={scrollToHash}
          active={activeId === item.id}
        />
      ))}
    </div>
  );
};
