import React from 'react';
import {
  LandingGrid,
  LandingLayoutContent
} from '../../../common/components/Layout/Layout';
import welcomeSrc from '../../../assets/img/welcome/welcome.png';
import { Tag } from '../../../common/components/ui/Tag/Tag';
import s from './Welcome.module.scss';

export const Welcome = () => {
  return (
    <LandingLayoutContent className={s.Welcome}>
      <div className={s.Welcome__box}>
        <LandingGrid className={s.Welcome__grid}>
          <div className={s.Welcome__content}>
            <div className={s.Welcome__tags}>
              <Tag className={s.Welcome__tag}>hr адаптация</Tag>
            </div>
            <div className={s.Welcome__title}>
              Добро пожаловать на&nbsp;Физтех
            </div>
            <div className={s.Welcome__text}>
              Московский физико-технический институт «Физтех» — ведущий
              технический вуз страны, который входит в престижные рейтинги
              лучших университетов мира. Мы&nbsp;рады приветствовать вас на
              нашем портале.
            </div>
          </div>
          <div className={s.Welcome__image}>
            <img src={welcomeSrc} alt="" />
          </div>
        </LandingGrid>
      </div>
    </LandingLayoutContent>
  );
};
