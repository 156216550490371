import React, { useCallback } from 'react';
import { useField } from 'formik';
import { ReactComponent as SearchIcon } from '../../../../assets/img/icons/search.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/img/icons/cross.svg';
import { Entity } from '../../../../common/components/ui/AsyncSelect/entities/types';
import {
  AsyncSelect,
  AsyncSelectSize
} from '../../../../common/components/ui/AsyncSelect/AsyncSelect';
import { Icon } from '../../../../common/components/ui/Icon/Icon';
import { Button, ButtonSize } from '../../../../common/components/ui/Button';
import {
  ActionButton,
  ActionType
} from '../../../../common/components/ui/ActionButton/ActionButton';
import { useAsyncEntityOptions } from '../../../../common/components/ui/AsyncSelect/entities/useAsyncEntityOptions';
import s from './Participants.module.scss';

export interface ParticipantsProps {
  name: string;
}

export const Participants: React.FC<ParticipantsProps> = ({ name }) => {
  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;
  const { error, touched } = meta;

  const [loadOptionsFn] = useAsyncEntityOptions(Entity.usersPage);

  const handleClearUserList = useCallback(() => {
    setValue([]);
  }, [setValue]);
  const [participantsAll] = useField<boolean>('participants_all');

  return (
    <div>
      <div className={s.Participants__selectWrapper}>
        <Icon
          icon={SearchIcon}
          boxSize={18}
          className={s.Participants__selectIcon}
        />
        <AsyncSelect
          className={s.Participants__asyncSelect}
          label={'Поиск сотрудников'}
          loadOptions={loadOptionsFn}
          onChange={(options) => {
            setValue(options);
          }}
          value={value}
          defaultOptions
          cacheOptions
          isMulti
          isClearable={false}
          errorMessage={touched && error ? error : undefined}
          size={AsyncSelectSize.large}
          onBlur={onBlur}
          hideValues={true}
          disabled={participantsAll.value}
          classes={{
            input: s.Participants__select
          }}
        />
      </div>
      {!participantsAll.value && value && value.length > 0 && (
        <div className={s.Participants__users}>
          <div className={s.Participants__usersTitle}>
            Назначено{' '}
            <Button
              classes={{
                root: s.Participants__clearButton,
                iconRight: s.Participants__clearButtonIcon
              }}
              iconRight={<CrossIcon />}
              onClick={handleClearUserList}
            >
              Очистить
            </Button>
          </div>
          <div className={s.Participants__usersList}>
            {value?.map((user) => (
              <div key={user.value} className={s.Participants__usersItem}>
                <div className={s.Participants__usersLabel} title={user.label}>
                  {user.label}
                </div>
                <ActionButton
                  actionType={ActionType.delete}
                  size={ButtonSize.small}
                  onClick={() => {
                    setValue(value.filter((item) => item.value !== user.value));
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
