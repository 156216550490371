import type { AxiosResponse } from 'axios';

export function tryGetFileNameFromContentDisposition(
  responseHeaders,
  defaultName = 'Файл экспорта'
) {
  try {
    return decodeURIComponent(
      responseHeaders['content-disposition'].split('filename=')[1].split(';')[0]
    );
  } catch {}
  return defaultName;
}

export function tryDownloadFileFromResponse(response: AxiosResponse) {
  const blob = new Blob([response.data], {
    type: response.headers['content-type']
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = tryGetFileNameFromContentDisposition(response.headers);
  link.click();
}
