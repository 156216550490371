import gql from 'graphql-tag';
import { FRAGMENT_PAGINATION_HEADER } from '../fragments/common';
import { FRAGMENT_PERSONNEL_RESERVE_FULL } from '../fragments/personnelReserve';

export const QUERY_GET_PERSONNEL_RESERVES = gql`
  query queryGetPersonnelReservePage($input: PersonnelReservePageInput) {
    personnelReservePage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...PersonnelReserveFull
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
  ${FRAGMENT_PERSONNEL_RESERVE_FULL}
`;

export const QUERY_GET_PERSONNEL_RESERVE = gql`
  query queryGetPersonnelReserve($id: ID!) {
    personnelReserve(id: $id) {
      ...PersonnelReserveFull
    }
  }

  ${FRAGMENT_PERSONNEL_RESERVE_FULL}
`;
