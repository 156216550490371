import React from 'react';
import clsx from 'clsx';
import s from './Icon.module.scss';

export enum IconBoxSize {
  default = 44,
  small = 24 // можно добавлять какие-то размеры
}

export interface IconProps {
  className?: string;
  iconClassName?: string;
  icon: React.ComponentType;
  /**
   * размер контейнера иконки
   */
  boxSize?: IconBoxSize | number | 'auto';
  /**
   * размер самой иконки в контейнере
   */
  iconSize?: number;
  onClick?: () => void;
}

export const Icon = React.memo(function Icon({
  className,
  iconClassName,
  icon,
  boxSize = IconBoxSize.default,
  iconSize,
  onClick
}: IconProps) {
  const IconComp = icon;

  const boxStyle =
    boxSize !== 'auto'
      ? {
          minWidth: `${boxSize}px`,
          width: `${boxSize}px`,
          height: `${boxSize}px`
        }
      : {};

  const iconStyle = iconSize
    ? {
        width: `${iconSize}px`,
        height: `${iconSize}px`
      }
    : {};

  const isButton = !!onClick;
  const Component = isButton ? 'button' : 'div';

  return (
    <Component
      className={clsx(s.Icon, className, {
        [s.Icon_button]: isButton
      })}
      style={boxStyle}
      onClick={onClick}
    >
      <div className={clsx(s.Icon__icon, iconClassName)} style={iconStyle}>
        <IconComp />
      </div>
    </Component>
  );
});
