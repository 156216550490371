import React, { useMemo } from 'react';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { MainSlider } from '../MainSlider/MainSlider';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import { Pagination } from '../constants';
import { BlueLink } from '../../../common/components/ui/BlueLink/BlueLink';
import { useOffersPage } from '../../../graphql/hooks/offers/useOffersPage';
import { routeLinks } from '../../routeLinks';
import { OfferSourcesEnum } from '../../../graphql/types';
import s from './OffersSlider.module.scss';

const useOffersArgs = {
  filter: {
    active: true
  },
  pagination: Pagination
};

export const OffersSlider: React.FC = () => {
  const offersQuery = useOffersPage(useOffersArgs);
  const offers = offersQuery.state.data?.list;

  const offersSlides = useMemo(() => {
    if (!offers) return [];
    return offers.map((offer) => {
      const isPartner = offer.source === OfferSourcesEnum.Partner;

      return {
        title: offer.name,
        imageType: FileTypeEnum.offer_cover,
        coverFileId: offer.cover_file_id ?? undefined,
        link: `${routeLinks.offers.to}/${offer.id}`,
        snippet: offer.snippet,
        additionalContent: (
          <div className={s.OffersSlider__addContent}>
            <div className={s.OffersSlider__partner}>
              {isPartner && 'От партнера'}
            </div>
            <BlueLink to={`${routeLinks.offers.to}/${offer.id}`} hasArrow />
          </div>
        )
      };
    });
  }, [offers]);

  return queryLoader(offersQuery) || <MainSlider slides={offersSlides} />;
};
