import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { LandingLayoutContent, LayoutContent } from '../../Layout/Layout';
import { ReactComponent as LogoSvg } from '../../../../assets/img/logo.svg';
import { NavLink, NavLinkProps } from '../NavLink/NavLink';
import { Login } from '../Login/Login';
import { routeLinks } from '../../../../routes/routeLinks';
import { MobileMenu } from '../MobileMenu/MobileMenu';
import { ReactComponent as IconBurger } from '../../../../assets/img/icons/burger.svg';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { UserType } from '../../../../graphql/types';
import { hideOnEnv } from '../../../../utils/hideOnEnv';
import s from './Header.module.scss';

const showPersonnelReserve = !hideOnEnv().personnelReserve;

export const vacanciesLink: NavLinkProps = {
  text: 'Вакансии МФТИ (конкурс ППС)',
  href: 'https://hr.mipt.ru/',
  isExternal: true
};

export const servicesRouteLink = {
  ...routeLinks.systemLinks,
  text: 'Сервисы'
};

const navLinks: NavLinkProps[] = [
  {
    text: 'Коммуникации',
    subLinks: [
      routeLinks.articles,
      routeLinks.polls,
      // routeLinks.notices,
      // routeLinks.events,
      routeLinks.projects
    ]
  },
  {
    text: 'Компетенции',
    subLinks: [
      routeLinks.courses,
      routeLinks.internships,
      // routeLinks.rate360,
      showPersonnelReserve ? routeLinks.reserve : null
    ]
  },
  routeLinks.documents,
  routeLinks.users,
  {
    text: 'Адаптация',
    subLinks: [routeLinks.manuals, routeLinks.offers, routeLinks.newcomers]
  },
  vacanciesLink,
  {
    text: 'Сервисы МФТИ',
    subLinks: [routeLinks.vacation, servicesRouteLink]
  }
];

const mobileLinksAuthorized: NavLinkProps[] = [
  {
    text: 'Главная',
    to: '/main'
  },
  ...navLinks
];

const mobileLinksUnauthorized: NavLinkProps[] = [vacanciesLink];

export interface HeaderProps {
  isLanding?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ isLanding }) => {
  const LayoutComp = isLanding ? LandingLayoutContent : LayoutContent;
  const [user, setUser] = useState<UserType | null>(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isMobile, isTablet } = useWindowSize();

  const showLoginActions = !window.location.href.includes(routeLinks.login.to);

  return (
    <div id={'page-header'} className={s.Header}>
      <LayoutComp
        className={s.Header__inner}
        innerClassName={clsx(s.Header__content, {
          [s.Header__content_wide]: !isLanding
        })}
      >
        <div className={s.Header__left}>
          {(isMobile || isTablet) && (
            <div className={s.Header__mobileMenuControl}>
              <IconBurger onClick={() => setMobileMenuOpen(true)} />
            </div>
          )}
          {!isMobile && (
            <Link
              className={clsx(s.Header__logo, {
                [s.Header__logo_landing]: isLanding
              })}
              to={user ? '/main' : '/'}
            >
              <LogoSvg />
            </Link>
          )}
        </div>

        <div className={s.Header__center}>
          {user && !isMobile && !isTablet && (
            <div className={s.Header__nav}>
              {navLinks.map((link, iLink) => (
                <NavLink
                  key={iLink}
                  linkClassName={s.Header__navLink}
                  linkSubsOpenClassName={s.Header__navLink_open}
                  subLinkClassName={s.Header__navSubLink}
                  subLinksBoxClassName={s.Header__navSubLinks}
                  {...link}
                />
              ))}
            </div>
          )}
        </div>

        <MobileMenu
          isOpen={isMobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          links={user ? mobileLinksAuthorized : mobileLinksUnauthorized}
        />
        <div className={s.Header__ext}>
          {!user && (
            <div className={s.Header__extLinks}>
              <NavLink
                linkClassName={clsx(s.Header__navLink, s.Header__extLink)}
                {...vacanciesLink}
              />
            </div>
          )}
          {showLoginActions && <Login onUserChange={setUser} />}
        </div>
      </LayoutComp>
    </div>
  );
};
