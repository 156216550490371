import { EMDASH } from '@proscom/ui-utils';
import { UserUtils } from '../../../../../store/users/UserUtils';
import { Card, CardStyle } from '../../Card/Card';
import { PositionType } from '../../../../../graphql/types';
import { PersonSmall } from '../../PersonSmall/PersonSmall';
import { routeLinks } from '../../../../../routes/routeLinks';
import { URL_KEY_UNIT_ID } from '../../../../../store/urlKeys';
import { TextLink, TextLinkVariant } from '../../TextLink/TextLink';
import s from './CardUserPosition.module.scss';

export interface CardUserPositionProps {
  userId: string;
  position: PositionType;
}

export function CardUserPosition({ userId, position }: CardUserPositionProps) {
  return (
    <Card
      className={s.CardUserPosition}
      classes={{
        inner: s.CardUserPosition__inner
      }}
      title={UserUtils.getPositionName(position) || EMDASH}
      cardStyle={CardStyle.bordered}
    >
      <div className={s.CardUserPosition__content}>
        <div className={s.CardUserPosition__blocks}>
          <div className={s.CardUserPosition__block}>
            <div className={s.CardUserPosition__block__title}>
              Тип занятости
            </div>
            <div className={s.CardUserPosition__block__content}>
              {position.c_combine_position_work || EMDASH}
            </div>
          </div>
          <div className={s.CardUserPosition__block}>
            <div className={s.CardUserPosition__block__title}>
              Подразделение
            </div>
            {position ? (
              <TextLink
                variant={TextLinkVariant.black}
                noUnderline={true}
                className={s.CardUserPosition__block__content}
                to={`${routeLinks.users.to}?${URL_KEY_UNIT_ID}=${position.unit_id}`}
              >
                {UserUtils.getPositionUnit(position) || EMDASH}
              </TextLink>
            ) : (
              <div className={s.CardUserPosition__block__content}>{EMDASH}</div>
            )}
          </div>
        </div>

        {position.director && position.director.id !== userId && (
          <div className={s.CardUserPosition__block}>
            <div className={s.CardUserPosition__block__title}>Руководитель</div>
            <div className={s.CardUserPosition__block__content}>
              <PersonSmall user={position.director} />
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
