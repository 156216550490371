import React, { useCallback } from 'react';
import { useField } from 'formik';
import { ContentBlockEditable } from '../../ContentBlock/ContentBlockEditable';
import { ContentBlockMeta } from '../../ContentBlock/types';
import { blockAddMenuItems } from './blockAddMenuItems';
import {
  getContentBlockName,
  IContentBlock,
  IContentBlockData
} from './Content';
import { ContentFormBlock } from './ContentFormBlock/ContentFormBlock';
import { BlockActionAdd } from './BlockActionAdd';
import { BlockActionRemove } from './BlockActionRemove';
import s from './FormContentEditor.module.scss';

export interface ContentEditorProps {
  name: string;
  meta?: ContentBlockMeta;
}

export const FormContentEditor: React.FC<ContentEditorProps> = ({
  name,
  meta
}) => {
  const [field, { error }, helpers] = useField(name);
  const { value } = field;
  const { setValue, setError } = helpers;

  const setContent = useCallback(
    (
      content: IContentBlock[] | ((data: IContentBlock[]) => IContentBlock[])
    ) => {
      if (typeof content === 'function') {
        setValue(content(value || []));
      } else {
        setValue(content);
      }
    },
    [setValue, value]
  );

  const handleAddBlock = useCallback(
    (addAfterIndex: number, blockProps: IContentBlock) => {
      setContent((prevState) => {
        const blocks = prevState.slice();
        blocks.splice(addAfterIndex + 1, 0, blockProps);
        return blocks;
      });
    },
    [setContent]
  );

  const handleRemoveBlock = useCallback(
    (removeIndex: number) => {
      setContent((prevState) => {
        const blocks = prevState.slice();
        blocks.splice(removeIndex, 1);
        return blocks;
      });
    },
    [setContent]
  );

  const handleBlockChange = useCallback(
    (blockIndex: number, value: IContentBlockData) => {
      setContent((prevState) => {
        const blocks = prevState.slice();

        if (blocks[blockIndex]) {
          blocks[blockIndex] = {
            ...blocks[blockIndex],
            data: value
          };
        }

        return blocks;
      });
    },
    [setContent]
  );

  const handleError = useCallback(
    (error) => {
      setError(!!error);
    },
    [setError]
  );

  return (
    <>
      <div className={s.FormContentEditor__addButton}>
        <BlockActionAdd
          onBlockSelect={(blockProps) => handleAddBlock(-1, blockProps)}
          items={blockAddMenuItems}
        />
      </div>
      {value.map((block, iBlock) => {
        const label = getContentBlockName(block);
        return (
          <ContentFormBlock
            key={iBlock}
            label={label}
            actions={
              <>
                <BlockActionAdd
                  onBlockSelect={(blockProps) =>
                    handleAddBlock(iBlock, blockProps)
                  }
                  items={blockAddMenuItems}
                />
                <BlockActionRemove onClick={() => handleRemoveBlock(iBlock)} />
              </>
            }
          >
            <ContentBlockEditable
              block={block}
              meta={meta}
              onChange={(value) => handleBlockChange(iBlock, value)}
              onError={handleError}
            />
          </ContentFormBlock>
        );
      })}
      {error && (
        <div className={s.FormContentEditor__errorMessage}>{error}</div>
      )}
    </>
  );
};
