import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ImagePlaceholder } from '../../ImagePlaceholder/ImagePlaceholder';
import { VideoHostingParser } from '../../../../../utils/url';
import s from '../ContentBlock.module.scss';

interface VideoProps {
  url: string;
  onError?: (error: any) => void;
}

const HostingVideo: React.FC<VideoProps> = ({ url, onError }) => {
  return (
    <iframe
      className={s.VideoBlockPreview__video}
      src={url}
      title="Video player"
      frameBorder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      onError={onError}
    />
  );
};

const HTML5Video: React.FC<VideoProps> = ({ url, onError }) => {
  return (
    <video
      className={s.VideoBlockPreview__video}
      src={url}
      autoPlay={false}
      controls={true}
      onError={onError}
    />
  );
};

export interface VideoBlockPreviewProps {
  url?: string;
  onError?: (error: any) => void;
}

export const VideoBlockPreview: React.FC<VideoBlockPreviewProps> = ({
  url: urlProp,
  onError
}) => {
  const [error, setError] = useState(false);

  const handleError = useCallback(
    (err) => {
      setError(!!err);
      onError?.(err);
    },
    [onError]
  );

  const { url, isHosting } = useMemo(() => {
    if (!urlProp) return {};

    const hostingVideoUrl = VideoHostingParser.getEmbedUrl(urlProp);

    return {
      url: hostingVideoUrl || urlProp,
      isHosting: !!hostingVideoUrl
    };
  }, [urlProp]);

  useEffect(() => {
    setError(false);
  }, [url]);

  return (
    <div className={s.VideoBlockPreview}>
      <ImagePlaceholder
        iconProps={{
          boxSize: 64
        }}
      />

      {url &&
        !error &&
        (isHosting ? (
          <HostingVideo url={url} onError={handleError} />
        ) : (
          <HTML5Video url={url} onError={handleError} />
        ))}
    </div>
  );
};
