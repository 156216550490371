import { createFilterSearch } from '@proscom/ui-utils';
import { useMemo } from 'react';
import { UseRequestStoreResult } from '@proscom/prostore-react';
import { GraphqlWatchQueryStore } from '@proscom/prostore-apollo';
import { useDocumentSections } from '../../graphql/hooks/useDocumentSections';
import { DocumentSectionType } from '../../graphql/types';

export function useDocuments(
  search
): UseRequestStoreResult<GraphqlWatchQueryStore<{}, DocumentSectionType[]>> {
  const documentSectionsQuery = useDocumentSections();

  const filteredSections = useMemo(() => {
    if (!documentSectionsQuery.state.data) return [];
    const sections = documentSectionsQuery.state.data;
    if (!search) return sections;
    const filter = createFilterSearch(search);
    return sections
      .map((section) => {
        const isTitle = filter(section.name);
        const documents = section.documents?.filter((d) => filter(d.name));
        if (isTitle || (documents && documents.length > 0)) {
          return {
            ...section,
            documents
          };
        }
        return {
          ...section,
          documents: null
        };
      })
      .filter((section) => Boolean(section.documents));
  }, [documentSectionsQuery.state.data, search]);

  return {
    ...documentSectionsQuery,
    state: {
      ...documentSectionsQuery.state,
      data: filteredSections
    }
  };
}
