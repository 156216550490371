import React from 'react';
import { UseRequestStoreResult } from '@proscom/prostore-react';
import { Loader } from '../ui/Loader/Loader';
import { QueryErrorMessage } from '../ui/QueryErrorMessage/QueryErrorMessage';

export type UseRequestStoreResultAny = UseRequestStoreResult<any>;

/**
 * Рендерит лоадер, если запрос идет, ошибку, если он ошибочен
 * и возвращает null, если все ок
 *
 * @param query - запрос
 *
 * @usage В jsx-коде:
 *  queryLoader(query) || <div>Data</div>
 */
export function queryLoader(query: UseRequestStoreResultAny) {
  return query.check.spinner ? (
    <Loader />
  ) : query.check.error ? (
    <QueryErrorMessage error={query.state.error} onReload={query.load} />
  ) : null;
}

/**
 * Рендерит мини-лоадер, если запрос идет, ошибку, если он ошибочен
 * и возвращает null, если все ок
 *
 * @param query - запрос
 *
 * @usage В jsx-коде:
 *  queryLoaderInline(query) || <div>Data</div>
 */
export function queryLoaderInline(query: UseRequestStoreResultAny) {
  return query.check.spinner ? (
    '...'
  ) : query.check.error ? (
    <QueryErrorMessage error={query.state.error} onReload={query.load} />
  ) : null;
}

/**
 * Рендерит лоадер, если запрос идет, ошибку, если он ошибочен,
 * специальный контент, если данных нет,
 * и возвращает null, если все ок.
 *
 * Если требуется более сложное условие на существование данных, то
 * лучше его реализовать в коде компонента, или отдельной функцией.
 *
 * @param query - запрос
 * @param renderNotFound - рендер-функция, возвращающая контент в случае,
 *  если данных нет
 *
 * @usage В jsx-коде:
 *  queryLoaderWithExistenceCheck(query) || <div>Data</div>
 */
export function queryLoaderWithExistenceCheck(
  query: UseRequestStoreResultAny,
  renderNotFound: () => React.ReactNode = () => 'Не найдено'
) {
  return (
    queryLoader(query) || (!query.state.data ? renderNotFound() : undefined)
  );
}

/**
 * Рендерит лоадер, если хотя бы один из переданных запросов грузится,
 * рендерит ошибку, если хотя бы один из переданных запросов ошибочек,
 * возвращает null, если все ок
 *
 * @param queries - запросы
 * @usage В jsx-коде:
 *  multiQueryLoader([query1, query2]) || <div>Data</div>
 */
export function multiQueryLoader(queries: UseRequestStoreResultAny[]) {
  const spinner = queries.some((q) => q.check.spinner);
  const errorQuery = queries.find((q) => q.check.error);
  return spinner ? (
    <Loader />
  ) : errorQuery ? (
    <QueryErrorMessage
      error={errorQuery.state.error}
      onReload={errorQuery.load}
    />
  ) : null;
}
