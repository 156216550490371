import { DocumentNode } from 'graphql';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { SelectOptionProps } from '../../Select/Select';
import {
  AreaOfInterestFilter,
  InternshipSubjectsFilter,
  CourseSubjectsFilter,
  OfferCategoriesFilter,
  OfferKindsFilter,
  PaginationInput,
  PollsFilter,
  RolesFilter,
  SkillFilter,
  TagsFilter,
  UnitsFilter,
  UserFilter
} from '../../../../../graphql/types';

export enum Entity {
  offerCategoriesPage = 'offerCategoriesPage',
  offerKindsPage = 'offerKindsPage',
  skillsPage = 'skillsPage',
  areasOfInterestPage = 'areasOfInterestPage',
  usersPage = 'usersPage',
  unitsPage = 'unitsPage',
  tagsPage = 'tagsPage',
  courseSubjectsPage = 'courseSubjectsPage',
  internshipSubjectsPage = 'internshipSubjectsPage',
  pollsPage = 'pollsPage',
  rolesPage = 'rolesPage'
}

export type EntityFilters = {
  [Entity.offerCategoriesPage]: OfferCategoriesFilter;
  [Entity.offerKindsPage]: OfferKindsFilter;
  [Entity.skillsPage]: SkillFilter;
  [Entity.areasOfInterestPage]: AreaOfInterestFilter;
  [Entity.usersPage]: UserFilter;
  [Entity.unitsPage]: UnitsFilter;
  [Entity.tagsPage]: TagsFilter;
  [Entity.courseSubjectsPage]: CourseSubjectsFilter;
  [Entity.internshipSubjectsPage]: InternshipSubjectsFilter;
  [Entity.pollsPage]: PollsFilter;
  [Entity.rolesPage]: RolesFilter;
};

export type MapData = (result: any) => SelectOptionProps[];

export type LoadQueryBase = <F>(
  query: DocumentNode,
  mapData: MapData,
  client: ApolloClient<NormalizedCacheObject>,
  filter?: F,
  pagination?: PaginationInput
) => Promise<void | SelectOptionProps[]>;

export interface LoadQueryOptions<F> {
  query: DocumentNode;
  mapData: MapData;
  client: ApolloClient<NormalizedCacheObject>;
  filter?: F;
  pagination?: PaginationInput;
}

export type EntityPageInput<T extends Entity> = {
  entity: T;
  filter?: EntityFilters[T];
  pagination?: PaginationInput;
};

export interface GetAsyncEntityLoaderArgs<T extends Entity> {
  client: ApolloClient<NormalizedCacheObject>;
  pagination?: PaginationInput;
  entity: T;
  filter?: EntityFilters[T];
  mapDataFn?: (result: any) => SelectOptionProps[];
}
