import { ContentBlockMeta } from '../../ContentBlock/types';

export enum ContentHeaderVariant {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4'
}

export enum ContentBlockVariant {
  header = 'header',
  text = 'text',
  image = 'image',
  textImage = 'textImage',
  video = 'video',
  blockquote = 'blockquote'
}

export interface IContentHeader {
  headerVariant?: ContentHeaderVariant;
  content?: string;
}

export interface IContentText {
  content?: string;
}

export interface IContentImage {
  fileId?: string;
  imageCaption?: string;
}

export interface IContentTextImage {
  fileId?: string;
  imageCaption?: string;
  content?: string;
}

export interface IContentVideo {
  url?: string;
}

export interface IContentBlockquote {
  content?: string;
}

export type ContentBlockMap = {
  [ContentBlockVariant.header]: IContentHeader;
  [ContentBlockVariant.video]: IContentVideo;
  [ContentBlockVariant.text]: IContentText;
  [ContentBlockVariant.image]: IContentImage;
  [ContentBlockVariant.textImage]: IContentTextImage;
  [ContentBlockVariant.blockquote]: IContentBlockquote;
};

export type IContentBlockData<
  Variant extends ContentBlockVariant = ContentBlockVariant
> = ContentBlockMap[Variant];

export interface IContentBlock<
  Variant extends ContentBlockVariant = ContentBlockVariant
> {
  variant: ContentBlockVariant;
  meta?: ContentBlockMeta;
  data: IContentBlockData<Variant>;
}

export const contentBlockNames: Record<ContentBlockVariant, string> = {
  [ContentBlockVariant.header]: 'Заголовок',
  [ContentBlockVariant.text]: 'Текстовый блок',
  [ContentBlockVariant.image]: 'Изображение',
  [ContentBlockVariant.textImage]: 'Текст с изображением',
  [ContentBlockVariant.video]: 'Ссылка на видео',
  [ContentBlockVariant.blockquote]: 'Цитата'
};

export function getContentBlockName(block: IContentBlock) {
  let label = contentBlockNames[block.variant];

  if (block.variant === ContentBlockVariant.header && block.data) {
    const data = block.data as IContentBlockData<ContentBlockVariant.header>;
    label = `${label} ${data.headerVariant}`;
  }

  return label;
}
