import React from 'react';
import { FieldArray, FormikValues } from 'formik';
import {
  UserPageBlock,
  UserPageBlockVariant
} from '../../UserBlocks/UserPageBlock/UserPageBlock';
import { ReactComponent as IconPlus } from '../../../../../assets/img/icons/plus.svg';
import { LayoutGrid } from '../../../Layout/Layout';
import { FormSection } from '../../Form/FormSection/FormSection';
import { FormTextField } from '../../Form/FormTextField/FormTextField';
import { TextFieldSize } from '../../TextField/TextField';
import { userFormNames } from '../../../../../store/users/UserConstants';
import { Button, ButtonSize, ButtonVariant } from '../../Button';
import { ActionButton, ActionType } from '../../ActionButton/ActionButton';
import { FormTextArea } from '../../Form/FormTextArea/FormTextArea';
import { FormDateRangePicker } from '../../Form/FormDateRangePicker/FormDateRangePicker';

const formName = userFormNames.work_experiences;

export interface EditJobsProps {
  values?: FormikValues;
}

export const EditJobs: React.FC<EditJobsProps> = ({ values }) => {
  const jobs = values?.[formName];

  return (
    <FieldArray name={formName}>
      {({ push, remove }) => (
        <UserPageBlock
          actions={
            <Button
              size={ButtonSize.small}
              variant={ButtonVariant.secondary}
              iconLeft={<IconPlus />}
              onClick={() =>
                push({
                  place: undefined,
                  duties: undefined,
                  position: undefined,
                  year_start: undefined,
                  year_end: undefined
                })
              }
            >
              Добавить место работы
            </Button>
          }
        >
          {jobs?.map((job, iJob) => (
            <UserPageBlock
              key={iJob}
              variant={UserPageBlockVariant.small}
              title={`Опыт работы ${iJob + 1}`}
              headContent={
                <ActionButton
                  actionType={ActionType.delete}
                  onClick={() => remove(iJob)}
                />
              }
            >
              <LayoutGrid>
                <LayoutGrid.GridItem cols={6}>
                  <FormSection>
                    <FormTextField
                      size={TextFieldSize.large}
                      name={`${formName}.${iJob}.place`}
                      label={'Название организации *'}
                    />
                  </FormSection>
                </LayoutGrid.GridItem>

                <LayoutGrid.GridItem cols={6}>
                  <FormSection>
                    <FormTextField
                      size={TextFieldSize.large}
                      name={`${formName}.${iJob}.position`}
                      label={'Должность *'}
                    />
                  </FormSection>
                </LayoutGrid.GridItem>

                <LayoutGrid.GridItem cols={6}>
                  <FormSection>
                    <FormTextArea
                      name={`${formName}.${iJob}.duties`}
                      label={'Должностные обязанности *'}
                      autoResize={true}
                      rows={8}
                    />
                  </FormSection>
                </LayoutGrid.GridItem>

                <LayoutGrid.GridItem cols={6}>
                  <FormSection>
                    <FormDateRangePicker
                      startField={`${formName}.${iJob}.year_start`}
                      endField={`${formName}.${iJob}.year_end`}
                      label={'Период работы *'}
                      isYearPicker={true}
                    />
                  </FormSection>
                </LayoutGrid.GridItem>
              </LayoutGrid>
            </UserPageBlock>
          ))}
        </UserPageBlock>
      )}
    </FieldArray>
  );
};
