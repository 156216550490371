function env<T = string | undefined>(
  variable: string | undefined,
  defaultValue?: T
) {
  if (typeof variable === 'undefined') {
    return defaultValue;
  }
  return variable;
}

function requireEnv(variable: string | undefined, name: string) {
  if (typeof variable === 'undefined') {
    throw new Error(`Variable ${name} is required`);
  }
  return variable;
}

export const isProductionBuild = process.env.NODE_ENV === 'production';

export const apiUrl = requireEnv(
  process.env.REACT_APP_API_URL,
  'REACT_APP_API_URL'
);
export const apiGraphqlUrl = apiUrl + '/graphql';
export const fileDownloadUrl = apiUrl + process.env.REACT_APP_FILE_DOWNLOAD_URL;
export const fileUploadUrl = apiUrl + process.env.REACT_APP_FILE_UPLOAD_URL;
export const fileExportUrl = apiUrl + process.env.REACT_APP_FILE_EXPORT_URL;

export const pollAttemptsExportUrl =
  apiUrl + process.env.REACT_APP_POLL_ATTEMPTS_EXPORT_URL;

export const assessmentReportsExportUrl =
  fileExportUrl + process.env.REACT_APP_ASSESSMENT_REPORTS_EXPORT_URL;

/** @deprecated use fileUploadUrl + `/type` */
export const manualUploadUrl = apiUrl + process.env.REACT_APP_MANUAL_UPLOAD_URL;
/** @deprecated use fileUploadUrl + `/type` */
export const documentUploadUrl =
  apiUrl + process.env.REACT_APP_DOCUMENT_UPLOAD_URL;
/** @deprecated use fileUploadUrl + `/type` */
export const noticeImageUploadUrl =
  apiUrl + process.env.REACT_APP_NOTICE_IMAGE_UPLOAD_URL;
/** @deprecated use fileUploadUrl + `/type` */
export const articleCoverUploadUrl =
  apiUrl + process.env.REACT_APP_ARTICLE_COVER_UPLOAD_URL;

export const socialAuthOrigin = process.env.REACT_APP_SOCIAL_AUTH_ORIGIN;
export const socialAuthUrl = process.env.REACT_APP_SOCIAL_AUTH_URL;
export const socialProvider = process.env.REACT_APP_SOCIAL_PROVIDER;

export const prodCalendarApiUrl = process.env.REACT_APP_PROD_CALENDAR_URL || '';
