import React from 'react';
import { CardUserPreview } from '../../../../common/components/ui/Cards/CardUserPreview/CardUserPreview';
import { routeLinks } from '../../../routeLinks';
import { UserType } from '../../../../graphql/types';
import s from './UsersList.module.scss';

export interface UsersListProps {
  data?: UserType[] | null;
  onSkillSelect?: (skillId?: string) => void;
}

export const UsersList: React.FC<UsersListProps> = ({
  data,
  onSkillSelect
}) => {
  return (
    <div className={s.UsersList}>
      {data?.map((item, iItem) => (
        <div key={item.id} className={s.UsersList__item}>
          <CardUserPreview
            user={item}
            to={`${routeLinks.users.to}/${item.id}`}
            onSkillTagClick={onSkillSelect}
          />
        </div>
      ))}
    </div>
  );
};
