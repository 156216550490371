import React, { useMemo } from 'react';
import { useArticlesPage } from '../../../graphql/hooks/useArticlesPage';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { MainSlider } from '../MainSlider/MainSlider';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import { Pagination } from '../constants';
import { ArticleDate } from './ArticleDate';

const useArticlesArgs = {
  pagination: Pagination
};

export const ArticlesSlider: React.FC = () => {
  const articlesQuery = useArticlesPage(useArticlesArgs);
  const articleSlides = useMemo(() => {
    if (!articlesQuery.state.data) return [];
    return articlesQuery.state.data.list.map((article) => ({
      title: article.title,
      imageType: FileTypeEnum.article_cover,
      coverFileId: article.cover_file_id ?? undefined,
      link: `/articles/${article.id}`,
      snippet: article.snippet,
      additionalContent: <ArticleDate data={article.posted_at} />
    }));
  }, [articlesQuery.state.data]);

  return queryLoader(articlesQuery) || <MainSlider slides={articleSlides} />;
};
