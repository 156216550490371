import React, { useMemo } from 'react';
import { FilterSelect, FilterSelectProps } from '../../ui/Filter/FilterSelect';
import { convertDataListToSelectOptions } from '../../../../utils/form/formConverters';
import { useOfferCategoriesPage } from '../../../../graphql/hooks/offers/useOfferCategoriesPage';

export interface FilterCategoriesProps extends FilterSelectProps {}

export const FilterCategories: React.FC<FilterCategoriesProps> = ({
  value,
  ...props
}) => {
  const categoriesQuery = useOfferCategoriesPage({});
  const queryCategories = categoriesQuery.state.data?.list;
  const categoriesLoading = categoriesQuery.check.spinner;

  const options = useMemo(() => {
    return convertDataListToSelectOptions(queryCategories || [], {
      valueKey: 'id',
      labelKey: 'name'
    });
  }, [queryCategories]);

  return (
    <FilterSelect
      value={value}
      options={options}
      placeholder={props.placeholder || 'Выберите категорию'}
      isLoading={categoriesLoading}
      isMulti
      {...props}
    />
  );
};
