import { skipIfNull } from '@proscom/prostore';
import { IUseGraphqlQueryOptions } from '@proscom/prostore-apollo-react';
import { FeedbackType } from '../../types';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import { QUERY_GET_FEEDBACK } from '../../queries/feedback';

export interface UseFeedbackVars {
  id: string;
}

const queryOptions: IUseGraphqlQueryOptions<
  UseFeedbackVars | null,
  FeedbackType | null
> = {
  query: QUERY_GET_FEEDBACK,
  mapData: (result) => result.feedback,
  skipQuery: skipIfNull(null)
};

export function useFeedback(id?: string) {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: id ? { id } : null
  });
}
