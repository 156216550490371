import React from 'react';
import clsx from 'clsx';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import s from './TableRow.module.scss';

export interface TableRowProps {
  className?: string;
  onClick?: () => void;
}

export const TableRow: React.FC<TableRowProps> = ({
  className,
  onClick,
  children
}) => {
  const { isMobile } = useWindowSize();
  const Component = isMobile ? 'div' : 'tr';
  return (
    <Component className={clsx(s.TableRow, className)} onClick={onClick}>
      {children}
    </Component>
  );
};
