import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { Link } from 'react-router-dom';
import { Card, CardProps, CardStyle } from '../../Card/Card';
import { TextLink } from '../../TextLink/TextLink';
import { Title, TitleVariant } from '../../Title/Title';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { useFileSrc } from '../../../../hooks/useFileSrc';
import { useGraphqlMutation } from '../../../../hooks/utils/useGraphqlMutation';
import { Articles } from '../../../../../store/articles/Articles';
import { ArticleUpdateInput } from '../../../../../graphql/types';
import { handleDefaultError } from '../../../../../utils/handleDefaultError';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { ImagePreview } from '../../ImagePreview/ImagePreview';
import {
  ContextMenuButton,
  ContextMenuButtonPosition
} from '../../ContextMenu/ContextMenuButton';
import { routeLinks } from '../../../../../routes/routeLinks';
import { Loader } from '../../Loader/Loader';
import { PriorityField } from '../../PriorityField/PriorityField';
import s from './CardArticlePreview.module.scss';

export interface CardArticlePreviewProps extends Pick<CardProps, 'to'> {
  className?: string;
  id: string;
  priority?: number | null;
  cover_file_id?: string | null;
  title: string;
  snippet?: string;
  posted_at?: string | null;
  isEditable?: boolean;
  onDeleteClick?: (id: string) => void;
}

export const CardArticlePreview: React.FC<CardArticlePreviewProps> = ({
  className,
  id,
  priority,
  cover_file_id,
  title,
  snippet = '',
  posted_at,
  isEditable,
  to,
  onDeleteClick
}) => {
  const imageSrc = useFileSrc(cover_file_id, FileTypeEnum.article_cover);
  const formattedDate = useFormatDate(posted_at);
  const updateArticle = useGraphqlMutation(Articles.updateArticle);
  const updateArticleRun = updateArticle.run;

  const editActions = useMemo(() => {
    return [
      {
        label: 'Редактировать',
        to: `${routeLinks.articles.to}/${id}/edit`
      },
      {
        label: 'Удалить',
        onClick: () => onDeleteClick?.(id)
      }
    ];
  }, [id, onDeleteClick]);

  const handleOrderChangeSubmit = useCallback(
    (priority: number | null) => {
      const input: ArticleUpdateInput = {
        id,
        priority
      };

      updateArticleRun({ variables: { input } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Приоритет новости обновлен');
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при обновлении приоритета новости. Попробуйте снова'
          );
        });
    },
    [updateArticleRun, id]
  );

  return (
    <Card
      className={clsx(s.CardArticlePreview, className)}
      classes={{
        inner: s.CardArticlePreview__inner
      }}
      cardStyle={CardStyle.bordered}
    >
      <Link to={to || ''} tabIndex={-1}>
        <ImagePreview
          className={s.CardArticlePreviewCover}
          classes={{
            image: s.CardArticlePreviewCover__image
          }}
          imageSrc={imageSrc}
          showBackfaceImage
        />
      </Link>

      <div className={s.CardArticlePreview__body}>
        <div className={s.CardArticlePreview__content}>
          <div className={s.CardArticlePreview__head}>
            <div>
              <Link
                className={s.CardArticlePreview__title}
                to={to || ''}
                tabIndex={-1}
              >
                <Title title={title} variant={TitleVariant.h4} />
              </Link>

              {formattedDate && (
                <div className={s.CardArticlePreview__date}>
                  {formattedDate}
                </div>
              )}
            </div>

            {isEditable && (
              <ContextMenuButton
                className={s.CardArticlePreview__contextMenu}
                items={editActions}
                position={ContextMenuButtonPosition.left}
              />
            )}
          </div>
          {snippet && (
            <div className={s.CardArticlePreview__description}>{snippet}</div>
          )}
        </div>

        <div className={s.CardArticlePreview__actions}>
          <div className={s.CardArticlePreview__actionsLeft}>
            {isEditable &&
              (updateArticle.loading ? (
                <Loader />
              ) : (
                <PriorityField
                  priority={priority}
                  onOrderChangeSubmit={handleOrderChangeSubmit}
                />
              ))}
          </div>
          <div className={s.CardArticlePreview__actionsRight}>
            <TextLink
              className={s.CardArticlePreview__link}
              to={to || ''}
              noUnderline
              showArrow
            >
              Перейти
            </TextLink>
          </div>
        </div>
      </div>
    </Card>
  );
};
