import React from 'react';
import clsx from 'clsx';
import { IContentTextImage } from '../../Form/FormContentEditor/Content';
import { SpecificContentBlockProps } from '../types';
import { ImageBlockPreview } from '../ImageBlock/ImageBlockPreview';
import s from '../ContentBlock.module.scss';

export const TextImageBlock: React.FC<
  SpecificContentBlockProps<IContentTextImage>
> = ({ data, className, meta }) => {
  const { fileId, imageCaption, content } = data;

  return (
    <div className={clsx(s.TextImageBlock, className)}>
      <div
        className={s.TextImageBlock__content}
        dangerouslySetInnerHTML={{
          __html: content || ''
        }}
      />
      <div className={s.TextImageBlock__image}>
        <ImageBlockPreview fileType={meta?.fileType} fileId={fileId} />

        {imageCaption && (
          <div className={s.TextImageBlock__imageCaption}>{imageCaption}</div>
        )}
      </div>
    </div>
  );
};
