import React, { useCallback } from 'react';
import { useField } from 'formik';
import { userFormNames } from '../../../../../store/users/UserConstants';
import { useFileSrc } from '../../../../hooks/useFileSrc';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { Avatar, AvatarSize } from '../../Avatar/Avatar';
import { FileUploadButton } from '../../UploadField/FileUploadButton';
import { ButtonSize } from '../../Button';
import s from './EditAvatar.module.scss';

export interface EditAvatarProps {
  fallbackId?: string | null;
}

export const EditAvatar: React.FC<EditAvatarProps> = ({ fallbackId }) => {
  const [{ value }, meta, { setValue }] = useField(
    userFormNames.avatar_file_id
  );

  const avatarSrc = useFileSrc(value || fallbackId, FileTypeEnum.avatar);

  const handleUpload = useCallback(
    (id) => {
      setValue(id);
    },
    [setValue]
  );

  return (
    <div className={s.EditAvatar}>
      <Avatar
        className={s.EditAvatar__avatar}
        src={avatarSrc}
        size={AvatarSize.extraLarge}
      />

      <div className={s.EditAvatar__upload}>
        <FileUploadButton
          buttonText={'Изменить'}
          buttonProps={{
            size: ButtonSize.small
          }}
          fileType={FileTypeEnum.avatar}
          onFileUploaded={handleUpload}
        />
      </div>
    </div>
  );
};
