import { IUseGraphqlQueryOptions } from '@proscom/prostore-apollo-react';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import {
  PaginationInput,
  PollsFilter,
  PollsPageInput,
  PollsPageType
} from '../../types';
import { QUERY_GET_POLLS_PAGE } from '../../queries/polls';
import { DefaultPaginationParams } from '../../../constants';

export interface UsePollsPageVars {
  input: PollsPageInput;
}

export interface UsePollsPageArgs {
  filter?: PollsFilter;
  pagination?: PaginationInput;
}

const queryOptions: IUseGraphqlQueryOptions<UsePollsPageVars, PollsPageType> = {
  query: QUERY_GET_POLLS_PAGE,
  mapData: (result) => result.pollsPage
};

export const usePollsPage = ({ filter, pagination }: UsePollsPageArgs) => {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: {
      input: {
        filter,
        pagination: {
          ...DefaultPaginationParams,
          ...pagination
        }
      }
    }
  });
};
