import { MutationOptions } from 'apollo-client';
import {
  ArticleCreateInput,
  ArticleType,
  ArticleUpdateInput
} from '../../graphql/types';
import { clearCache } from '../../graphql/queriesUtils';
import {
  MUTATION_CREATE_ARTICLE,
  MUTATION_DELETE_ARTICLE,
  MUTATION_UPDATE_ARTICLE
} from '../../graphql/mutations/articles';

export class Articles {
  private static refetchQueries = ['queryGetArticlesPage', 'queryGetArticle'];
  private static updateQueries = ['articlesPage', 'article'];
  private static updateFragments = ['ArticleType', 'ArticlesPageType'];

  public static createArticle: MutationOptions<
    {
      result: ArticleType;
    },
    {
      input: ArticleCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_ARTICLE,
    refetchQueries: Articles.refetchQueries,
    update: clearCache(Articles.updateQueries, Articles.updateFragments)
  };

  public static updateArticle: MutationOptions<
    {
      result: ArticleType;
    },
    {
      input: ArticleUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_ARTICLE,
    refetchQueries: Articles.refetchQueries,
    update: clearCache(Articles.updateQueries, Articles.updateFragments)
  };

  public static deleteArticle: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_DELETE_ARTICLE,
    refetchQueries: Articles.refetchQueries,
    update: clearCache(Articles.updateQueries, Articles.updateFragments)
  };
}
