import { PositionType } from '../../../../../graphql/types';
import { LayoutGrid } from '../../../Layout/Layout';
import { CardUserPosition } from '../../Cards/CardUserPosition/CardUserPosition';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import s from './UserPositions.module.scss';

export interface UserPositionsProps {
  userId: string;
  positions?: PositionType[] | null;
}

export function UserPositions({ userId, positions }: UserPositionsProps) {
  const { isDesktop } = useWindowSize();
  return (
    <LayoutGrid className={s.UserPositions}>
      {positions?.map((position) => {
        return (
          <LayoutGrid.GridItem
            cols={isDesktop ? 4 : 12}
            key={position.id}
            className={s.UserPositions__position}
          >
            <CardUserPosition userId={userId} position={position} />
          </LayoutGrid.GridItem>
        );
      })}
    </LayoutGrid>
  );
}
