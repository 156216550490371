import React from 'react';
import clsx from 'clsx';
import { Form, FormikProps } from 'formik';
import { FormTextField } from '../../../common/components/ui/Form/FormTextField/FormTextField';
import { TextFieldSize } from '../../../common/components/ui/TextField/TextField';
import {
  ActionButton,
  ActionType
} from '../../../common/components/ui/ActionButton/ActionButton';
import { SystemLinkFormValues } from '../../../store/systemLinks/SystemLinksUtils';
import { Loader } from '../../../common/components/ui/Loader/Loader';
import s from './SystemLinkForm.module.scss';

export interface SystemLinkFormProps extends FormikProps<SystemLinkFormValues> {
  onCancel: () => void;
  submissionLoading: boolean;
}

export const SystemLinkForm: React.FC<SystemLinkFormProps> = ({
  onCancel,
  submissionLoading
}) => {
  return (
    <Form>
      <div className={s.SystemLinkForm}>
        <FormTextField
          name={'name'}
          label={'Название информационной системы'}
          placeholder={'Введите название...'}
          size={TextFieldSize.large}
          className={s.SystemLinkForm__field}
        />
        <FormTextField
          name={'link'}
          label={'Ссылка на информационную систему'}
          placeholder={'https://...'}
          size={TextFieldSize.large}
          className={s.SystemLinkForm__field}
        />
        <FormTextField
          name={'priority'}
          label={'Приоритет'}
          type={'number'}
          size={TextFieldSize.large}
          className={clsx(s.SystemLinkForm__field, s.SystemLinkForm__priority)}
        />
        <div>
          <div className={s.SystemLinkForm__actions}>
            {submissionLoading ? (
              <Loader
                size={20}
                borderSize={2}
                className={s.SystemLinkForm__loader}
              />
            ) : (
              <ActionButton actionType={ActionType.approve} type={'submit'} />
            )}
            <ActionButton actionType={ActionType.cancel} onClick={onCancel} />
          </div>
        </div>
      </div>
    </Form>
  );
};
