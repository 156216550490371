import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useEvent } from 'react-use';
import { LandingLayoutContent } from '../../../common/components/Layout/Layout';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import s from './CampusMap.module.scss';

const mapEmbedSrc =
  'https://www.google.com/maps/d/embed?mid=134_O3HcndazfS8mc-hTCy73FTel2PqPs&hl=ru';

export const CampusMap = () => {
  const [coverActive, setCoverActive] = useState(false);

  const handleFrameLoaded = useCallback(() => {
    setCoverActive(true);
  }, []);

  const handleCoverClick = useCallback(() => {
    setCoverActive(false);
  }, []);

  const handleScrolled = useCallback(() => {
    setCoverActive(true);
  }, []);

  useEvent('scroll', handleScrolled);

  return (
    <LandingLayoutContent className={s.CampusMap}>
      <div className={s.CampusMap__frameBox}>
        <div
          className={clsx(s.CampusMap__frameCover, {
            [s.CampusMap__frameCover_active]: coverActive
          })}
          onClick={handleCoverClick}
        >
          <Title variant={TitleVariant.h3}>Нажмите для активации карты</Title>
        </div>
        <iframe
          className={s.CampusMap__frame}
          src={mapEmbedSrc}
          title="Карта кампусов"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope"
          loading="lazy"
          onLoad={handleFrameLoaded}
        />
      </div>
    </LandingLayoutContent>
  );
};
