import React from 'react';
import clsx from 'clsx';
import { IContentText } from '../../Form/FormContentEditor/Content';
import { SpecificContentBlockProps } from '../types';
import s from '../ContentBlock.module.scss';

export const TextBlock: React.FC<SpecificContentBlockProps<IContentText>> = ({
  data,
  className
}) => {
  return (
    <div
      className={clsx(s.TextBlock, className)}
      dangerouslySetInnerHTML={{
        __html: data.content || ''
      }}
    />
  );
};
