import { Routes, RouteType } from '../../common/components/utils/Routes';
import { RequireAuth } from '../RequireAuth';
import { hasPermission, UserPermission } from '../../utils/permissions';
import NotFoundPage from '../notFound/NotFoundPage';
import PollsPage from './list/PollsPage';
import PollCreatePage from './create/PollCreatePage';
import PollAttemptCreatePage from './attempts/create/PollAttemptCreatePage';
import PollEditPage from './edit/PollEditPage';
import PollAttemptsPage from './attempts/PollAttemptsPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: RequireAuth(PollsPage)
  },
  {
    path: '/external/create',
    exact: true,
    component: RequireAuth(PollCreatePage, (user) =>
      hasPermission(user, UserPermission.ExternalPollCrud)
    )
  },
  {
    path: '/external/:externalPollId/edit',
    exact: true,
    component: RequireAuth(PollEditPage, (user) =>
      hasPermission(user, UserPermission.ExternalPollCrud)
    )
  },
  {
    path: '/:pollId/attempts',
    exact: true,
    component: RequireAuth(PollAttemptsPage)
  },
  {
    path: '/:pollId/attempts/create',
    exact: true,
    component: RequireAuth(PollAttemptCreatePage)
  },
  {
    component: NotFoundPage
  }
];

const PollsRoutes = Routes(routes);

export default PollsRoutes;
