import gql from 'graphql-tag';
import { FRAGMENT_USER_SNIPPET } from './users';

export const FRAGMENT_PROJECT_SNIPPET = gql`
  fragment ProjectSnippet on ProjectType {
    id
    name
    status

    head_user {
      ...UserSnippet
    }
    head_user_id
    participants_count
  }

  ${FRAGMENT_USER_SNIPPET}
`;

export const FRAGMENT_PROJECT_FULL = gql`
  fragment ProjectFull on ProjectType {
    id
    name
    description
    status

    head_user {
      ...UserSnippet
    }
    head_user_id

    participants {
      participation_date
      user {
        ...UserSnippet
      }
      user_id
    }
    participants_count
  }

  ${FRAGMENT_USER_SNIPPET}
`;
