import {
  PersonnelReserveCreateInput,
  PersonnelReserveType,
  PersonnelReserveUpdateInput
} from '../../graphql/types';

export interface PersonnelReserveFormValues {
  id?: string;
  name: string;
  description: string;
  cover_file_id?: string;
  snippet: string;
}

export class PersonnelReserveUtils {
  static toForm(data?: PersonnelReserveType): PersonnelReserveFormValues {
    return {
      id: data?.id,
      name: data?.name || '',
      description: data?.description || '',
      cover_file_id: data?.cover_file_id || '',
      snippet: data?.snippet || ''
    };
  }

  static fromCreateForm({
    cover_file_id,
    ...formData
  }: PersonnelReserveFormValues): PersonnelReserveCreateInput {
    return {
      cover_file_id: cover_file_id || null,
      ...formData
    };
  }

  static fromUpdateForm(
    formData: PersonnelReserveFormValues
  ): PersonnelReserveUpdateInput {
    return {
      id: formData.id!,
      ...PersonnelReserveUtils.fromCreateForm(formData)
    };
  }
}
