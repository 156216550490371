import { useMemo } from 'react';
import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import debounce from 'debounce-promise';
import { PaginationInput } from '../../../../../graphql/types';
import { getAsyncEntityLoader } from './entities';
import { Entity, EntityFilters } from './types';

export function useAsyncEntityOptions<T extends Entity>(
  entity: T,
  filter?: EntityFilters[T],
  pagination?: PaginationInput
) {
  const client = useContextApolloClient('default');
  const [loadBySearch, loadByIds] = useMemo(
    () =>
      getAsyncEntityLoader({
        client,
        entity,
        filter,
        pagination
      }),
    [client, entity, filter, pagination]
  );

  const loadSearchDb = useMemo(() => debounce(loadBySearch, 500), [
    loadBySearch
  ]);

  return [loadSearchDb, loadByIds] as const;
}
