import { isNil, isUndefined, omitBy, without } from 'lodash-es';
import { SelectOptionProps } from '../common/components/ui/Select/Select';

export function clean(object: Object) {
  return omitBy(object, isUndefined);
}

type FilterTuple<T extends readonly unknown[], V> = T extends readonly [
  infer H,
  ...infer R
]
  ? H extends V
    ? FilterTuple<R, V>
    : readonly [H, ...FilterTuple<R, V>]
  : readonly [];

export function omitValues<T extends readonly any[], V extends any[]>(
  array: T,
  ...values: V
) {
  return (without(array, ...values) as unknown) as FilterTuple<T, V[number]>;
}

export function valueToArray<T>(value?: T | null): [T] | undefined {
  return !isNil(value) ? [value] : undefined;
}

// поздно понял, что пока не пригодятся, но пусть может полежат? :)
export function strToArr(
  string?: string,
  separator = ','
): string[] | undefined {
  return !isNil(string) ? string.split(separator) : undefined;
}

export function arrToStr(array?: any[], separator = ','): string | undefined {
  return !isNil(array) ? array.join(separator) : undefined;
}

export function optionsToStrArray(
  options?: SelectOptionProps[]
): string[] | undefined {
  return !isNil(options)
    ? options.map((opt) => (opt.value || '').toString())
    : undefined;
}

export function chunkArrayByHalf(
  array: any[],
  maxAmountNotToChunk: number = 0
) {
  if (array.length <= maxAmountNotToChunk) {
    return [array];
  } else {
    const half = Math.ceil(array.length / 2);
    const start = array.slice(0, half);
    const end = array.slice(half);
    return [start, end];
  }
}

export function makeObjectValuesUndefined<
  T extends Record<string | number, any>
>(
  object: T
): {
  [key in keyof T]: undefined;
} {
  return Object.values(object).reduce((prev, current) => {
    prev[current] = undefined;
    return prev;
  }, {});
}
