import React, { useCallback } from 'react';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import {
  TransformedQueryParams,
  URL_KEY_DIRECTOR_ID,
  URL_KEY_FILTER_SKILL_IDS,
  URL_KEY_PAGE,
  URL_KEY_SEARCH,
  URL_KEY_UNIT_ID
} from '../../../store/urlKeys';
import { STORE_LOCATION } from '../../../store/storeKeys';
import { FilterSkills } from '../../../common/components/utils/filters/FilterSkills';
import { FilterSearch } from '../../../common/components/ui/Filter/FilterSearch';
import { UserFilter } from '../../../graphql/types';
import { FilterDirector } from '../../../common/components/utils/filters/FilterDirector';
import { FilterUnit } from '../../../common/components/utils/filters/FilterUnit';
import { valueToArray } from '../../../utils/objects';
import s from './UsersPage.module.scss';

export const useUsersFilter = ({
  showSearchInput = true
}: {
  showSearchInput?: boolean;
}) => {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const query = (locationState.query || {}) as TransformedQueryParams;
  const changeQuery = locationStore.changeQuery;

  const filter: UserFilter = {
    search: query[URL_KEY_SEARCH],
    unit_ids: query[URL_KEY_UNIT_ID],
    director_ids: query[URL_KEY_DIRECTOR_ID],
    skill_ids: query[URL_KEY_FILTER_SKILL_IDS]
  };

  const handleFilterChange = useCallback(
    (key: string, valueConverter?: (value) => any) => {
      return (value) => {
        const val = valueConverter ? valueConverter(value) : value;

        changeQuery({
          [key]: val,
          [URL_KEY_PAGE]: 1
        });
      };
    },
    [changeQuery]
  );

  const filterSearchComponent: React.ReactNode = (
    <FilterSearch
      className={s.UsersFilter__filterSearch}
      classes={{
        filter: {
          content: s.UsersFilter__filterSearchContent
        }
      }}
      value={filter.search || ''}
      onChange={handleFilterChange(URL_KEY_SEARCH)}
    />
  );

  const filterComponent: React.ReactNode = (
    <div className={s.UsersFilter}>
      {showSearchInput && filterSearchComponent}
      <div className={s.UsersFilter__filters}>
        <FilterUnit
          className={s.UsersFilter__filter}
          label={'Отдел'}
          value={filter.unit_ids?.[0]}
          onChange={handleFilterChange(URL_KEY_UNIT_ID, valueToArray)}
          isClearable
        />

        <FilterDirector
          className={s.UsersFilter__filter}
          label={'Руководитель'}
          value={filter.director_ids?.[0]}
          onChange={handleFilterChange(URL_KEY_DIRECTOR_ID, valueToArray)}
          isClearable
        />

        <FilterSkills
          className={s.UsersFilter__filter}
          label={'Компетенции'}
          value={filter.skill_ids}
          onChange={handleFilterChange(URL_KEY_FILTER_SKILL_IDS)}
        />
      </div>
    </div>
  );

  return [filterComponent, filter, filterSearchComponent] as const;
};
