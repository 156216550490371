// Трансформатор для булева значения
import { IQueryTransformer } from '@proscom/prostore-react-router';

export const booleanTransformer: IQueryTransformer<boolean | undefined> = {
  parse(value) {
    return value === 'true' ? true : value === 'false' ? false : undefined;
  },
  stringify(value: boolean | undefined) {
    return value === true ? 'true' : value === false ? 'false' : undefined;
  }
};
