import React from 'react';
import clsx from 'clsx';
import { IContentVideo } from '../../Form/FormContentEditor/Content';
import { SpecificContentBlockProps } from '../types';
import { VideoBlockPreview } from './VideoBlockPreview';
import s from '../ContentBlock.module.scss';

export const VideoBlock: React.FC<SpecificContentBlockProps<IContentVideo>> = ({
  data,
  className
}) => {
  return (
    <div className={clsx(s.VideoBlock, className)}>
      <VideoBlockPreview url={data.url} />
    </div>
  );
};
