import React from 'react';
import { EMDASH } from '@proscom/ui-utils';
import { WorkExperienceType } from '../../../../../graphql/types';
import { Card, CardStyle } from '../../Card/Card';
import { LayoutGrid } from '../../../Layout/Layout';
import s from './UserJobs.module.scss';

const UserJob: React.FC<WorkExperienceType> = ({
  duties,
  place,
  position,
  year_start,
  year_end
}) => {
  return (
    <Card
      className={s.UserJobsCard}
      classes={{
        inner: s.UserJobsCard__inner
      }}
      cardStyle={CardStyle.bordered}
    >
      <div className={s.UserJobsCard__title}>
        {place} <br />
        {position}
      </div>
      <div className={s.UserJobsCard__years}>
        {year_start} - {year_end || 'наст.время'}
      </div>
      <div className={s.UserJobsCard__description}>{duties}</div>
    </Card>
  );
};

export interface UserJobsProps {
  jobs?: WorkExperienceType[] | null;
}

export const UserJobs: React.FC<UserJobsProps> = ({ jobs }) => {
  return (
    <LayoutGrid className={s.UserJobs}>
      {jobs && jobs.length > 0
        ? jobs.map((item, iItem) => (
            <LayoutGrid.GridItem key={iItem} cols={6}>
              <UserJob {...item} />
            </LayoutGrid.GridItem>
          ))
        : EMDASH}
    </LayoutGrid>
  );
};
