import React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import {
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { UserPermission } from '../../../utils/permissions';
import { useSystemLinks } from '../../../graphql/hooks/useSystemLinks';
import { removeTrailingSlash } from '../../../utils/url/url';
import { CardSystemLinks } from '../../../common/components/ui/Cards/CardSystemLinks/CardSystemLinks';
import {
  getCardPattern,
  Pattern
} from '../../../common/components/ui/Card/patterns';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { routeLinks } from '../../routeLinks';
import {
  getBreadcrumbRouteLink,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import s from './SystemLinksPage.module.scss';

const patterns: Pattern[] = [
  Pattern.rectangles,
  Pattern.circles,
  Pattern.triangles
];

export interface SystemLinksPageProps extends RouteComponentProps {}

export function getSystemLinksPageBreadcrumbs(): IBreadcrumbsItem[] {
  return [getBreadcrumbRouteLink(routeLinks.systemLinks)];
}

export default function SystemLinksPage({ match }: SystemLinksPageProps) {
  const systemLinksQuery = useSystemLinks();
  const systemLinks = systemLinksQuery.state.data;

  const { user } = useCurrentUser();
  const canCrud = useHasPermission(user, UserPermission.SystemLinkCrud);

  return (
    <PageLayout>
      <LayoutContent>
        <PageTitle
          className={s.SystemLinksPage__title}
          title={routeLinks.systemLinks.text}
          showHeading
        >
          {canCrud && (
            <div>
              <ButtonLink
                to={`${removeTrailingSlash(match.path)}/edit`}
                variant={ButtonVariant.secondary}
                size={ButtonSize.small}
              >
                Редактировать
              </ButtonLink>
            </div>
          )}
        </PageTitle>
        {queryLoader(systemLinksQuery) ||
          (!systemLinks || systemLinks.length === 0 ? (
            <NoData />
          ) : (
            <LayoutGrid>
              {systemLinks.map((item) => (
                <LayoutGrid.GridItem key={item.id} cols={4}>
                  <CardSystemLinks
                    pattern={getCardPattern(item.id, patterns)}
                    link={item.link}
                    name={item.name}
                  />
                </LayoutGrid.GridItem>
              ))}
            </LayoutGrid>
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
