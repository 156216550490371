import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useField } from 'formik';
import { DivPropsWithoutRef } from '../../../../utils/reactTypes';
import { Checkbox, CheckboxProps } from '../Checkbox/Checkbox';
import s from './CheckboxGroup.module.scss';

export type CheckboxGroupOption = {
  label: string;
  value: CheckboxProps['value'];
};

export interface CheckboxGroupProps
  extends Omit<CheckboxProps, 'onChange' | 'value'> {
  value?: CheckboxProps['value'][];
  options: CheckboxGroupOption[];
  onChange?: (selected: CheckboxProps['value'][]) => void;
  rootProps?: DivPropsWithoutRef;
}

/**
 * Контролируемый компонент, если передан onChange, иначе неконтролируемый
 * @param value
 * @param options
 * @param onChange
 * @param rootProps
 * @param props
 * @constructor
 */
export function CheckboxGroup({
  className,
  value: valueProp = [],
  options,
  onChange,
  rootProps,
  ...props
}: CheckboxGroupProps) {
  const [innerValue, setInnerValue] = useState(valueProp);

  const value = useMemo(() => {
    if (onChange) return valueProp;
    return innerValue;
  }, [valueProp, innerValue, onChange]);

  const onCheckboxChange = useCallback((checked, option) => {
    setInnerValue((prev) => {
      if (checked) return [...prev, option.value];
      return prev.filter((v) => v !== option.value);
    });
  }, []);

  useLayoutEffect(() => {
    if (onChange && innerValue !== valueProp) onChange(innerValue);
  }, [innerValue, onChange, valueProp]);

  return (
    <div className={clsx(s.CheckboxGroup, className)} {...rootProps}>
      {options.map((o) => {
        return (
          <Checkbox
            key={o.label}
            className={s.CheckboxGroup__checkbox}
            checked={value.includes(o.value)}
            label={o.label}
            onChange={(checked) => onCheckboxChange(checked, o)}
          />
        );
      })}
    </div>
  );
}

export interface FormCheckboxGroupProps
  extends Omit<CheckboxGroupProps, 'value' | 'onChange'> {
  name: string;
}

export function FormCheckboxGroup({ name, ...props }: FormCheckboxGroupProps) {
  const [{ value }, { error }, { setValue }] = useField(name);
  return (
    <div className={s.FormCheckboxGroup}>
      <CheckboxGroup value={value} onChange={setValue} {...props} />
      {error && <div className={s.FormCheckboxGroup__error}>{error}</div>}
    </div>
  );
}
