import { MutationOptions } from 'apollo-client';
import { PollAttemptCreateInput, PollAttemptType } from '../../graphql/types';
import { clearCache } from '../../graphql/queriesUtils';
import { MUTATION_CREATE_POLL_ATTEMPT } from '../../graphql/mutations/polls';

export class Polls {
  private static refetchQueries = ['queryGetPoll', 'queryGetPollsPage'];
  private static updateQueries = ['poll', 'pollsPage'];

  public static CreatePollAttempt: MutationOptions<
    {
      result: PollAttemptType;
    },
    {
      input: PollAttemptCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_POLL_ATTEMPT,
    refetchQueries: Polls.refetchQueries,
    update: clearCache(Polls.updateQueries)
  };
}
