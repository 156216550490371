import { useStore } from '@proscom/prostore-react';
import { STORE_AUTH } from '../../store/storeKeys';
import { AuthStore } from '../../store/AuthStore';

export function useCurrentUser() {
  const [auth, store] = useStore<AuthStore>(STORE_AUTH);
  const user = auth?.authData?.user;

  return {
    user
  };
}
