import React from 'react';
import clsx from 'clsx';
import { ResponsiveObject } from 'react-slick';
import {
  LandingGrid,
  LandingLayoutContent
} from '../../../common/components/Layout/Layout';
import imageSrc_1 from '../../../assets/img/welcome/history_1.png';
import imageSrc_2 from '../../../assets/img/welcome/history_2.png';
import imageSrc_3 from '../../../assets/img/welcome/history_3.png';
import sliderImgSrc_1 from '../../../assets/img/welcome/history_slide_1.jpg';
import sliderImgSrc_2 from '../../../assets/img/welcome/history_slide_2.jpg';
import sliderImgSrc_3 from '../../../assets/img/welcome/history_slide_3.jpg';
import { TextLink } from '../../../common/components/ui/TextLink/TextLink';
import { Slider } from '../../../common/components/ui/Slider/Slider';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { BREAKPOINT_VALUES, Breakpoints } from '../../../constants';
import s from './TechHistory.module.scss';

const sliderImages = [sliderImgSrc_1, sliderImgSrc_2, sliderImgSrc_3];

const sliderResponsive: ResponsiveObject[] = [
  {
    breakpoint: BREAKPOINT_VALUES[Breakpoints.tablet] - 1,
    settings: {
      slidesToShow: 1,
      arrows: false,
      dots: true
    }
  }
];

const HistorySlider = () => {
  return (
    <Slider
      className={s.HistorySlider}
      classes={{
        slick: s.HistorySlider__slick,
        button: s.HistorySlider__button,
        buttonPrev: s.HistorySlider__buttonPrev,
        buttonNext: s.HistorySlider__buttonNext
      }}
      config={{
        arrows: true,
        dots: true,
        responsive: sliderResponsive
      }}
    >
      {sliderImages.map((img, iImg) => (
        <div key={iImg} className={s.HistorySlider__slide}>
          <div
            className={s.HistorySlider__slideImage}
            style={{
              backgroundImage: `url(${img})`
            }}
          />
        </div>
      ))}
    </Slider>
  );
};

interface TechHistoryItemProps {
  className?: string;
  contentClassName?: string;
  extClassName?: string;
  title: React.ReactNode;
  content: React.ReactNode;
  extContent: React.ReactNode;
  isInverted?: boolean;
}

const TechHistoryItem: React.FC<TechHistoryItemProps> = ({
  title,
  content,
  extContent,
  isInverted
}) => {
  const { isMobile } = useWindowSize();

  return (
    <LandingGrid
      className={clsx(s.TechHistoryItem, {
        [s.TechHistoryItem_inverted]: isInverted
      })}
    >
      {isMobile ? (
        <>
          {title}
          {extContent}
          {content}
        </>
      ) : !isInverted ? (
        <>
          <div className={s.TechHistoryItem__content}>
            <div>
              {title}
              {content}
            </div>
          </div>
          <div className={s.TechHistoryItem__ext}>{extContent}</div>
        </>
      ) : (
        <>
          <div className={s.TechHistoryItem__ext}>{extContent}</div>
          <div className={s.TechHistoryItem__content}>
            <div>
              {title}
              {content}
            </div>
          </div>
        </>
      )}
    </LandingGrid>
  );
};

export const TechHistory = () => {
  return (
    <LandingLayoutContent className={s.TechHistory}>
      <div className={s.TechHistory__grid}>
        <TechHistoryItem
          title={
            <>
              <div className={s.TechHistory__tag}>История и факты МФТИ</div>
              <div className={s.TechHistory__title}>
                День рождения Физтеха — 25 ноября
              </div>
            </>
          }
          content={
            <div className={s.TechHistory__text}>
              <p>
                В этот день в 1946 году вышло постановление Совета Министров
                СССР о создании физико-технического факультета (ФТФ) МГУ,
                а через 5 лет на его базе был создан Московский
                физико-технический институт.
              </p>
              <p>
                Больше информации об истории Физтеха вы можете узнать
                в виртуальном музее физтеха{' '}
                <TextLink href={'https://mipt-museum.ru/'}>
                  перейти в музей
                </TextLink>
              </p>
            </div>
          }
          extContent={
            <div className={s.TechHistory__image}>
              <img
                src={imageSrc_1}
                alt=""
                style={{
                  maxWidth: 536
                }}
              />
            </div>
          }
        />

        <TechHistoryItem
          isInverted={true}
          title={<div className={s.TechHistory__title}>Отцы основатели</div>}
          content={
            <div className={s.TechHistory__text}>
              <p>
                Отцами-основателями Физтеха считаются три нобелевских лауреата:{' '}
                <b>Пётр Капица, Николай Семёнов и Лев Ландау</b>.
              </p>
              <p>
                <TextLink
                  href={
                    'https://mipt.ru/about/museum/fizteh/historical_review/'
                  }
                >
                  Историческая справка
                </TextLink>
              </p>
            </div>
          }
          extContent={
            <div className={s.TechHistory__image}>
              <img
                src={imageSrc_2}
                alt=""
                style={{
                  maxWidth: 523
                }}
              />
            </div>
          }
        />

        <TechHistoryItem
          title={<div className={s.TechHistory__title}>Выпускники МФТИ</div>}
          content={
            <div className={s.TechHistory__text}>
              <p>
                Многие выпускники МФТИ добились успехов в самых разных областях
                науки, бизнеса и даже искусства. 150 из них стали академиками и
                членами-корреспондентами РАН, а один - Президентом РАН. 6000
                получили звание доктора наук, 17000 стали кандидатами наук.
                Физтех дал миру двух нобелевских лауреатов — Андрея Гейма и
                Константина Новоселова, трех летчиков-космонавтов, трех
                министров по науке и одного из авторов архитектурных принципов
                построения вычислительных комплексов Бориса Бабаяна.
              </p>
            </div>
          }
          extContent={
            <div className={s.TechHistory__image}>
              <img
                src={imageSrc_3}
                alt=""
                style={{
                  maxWidth: 568
                }}
              />
            </div>
          }
        />

        <TechHistoryItem
          isInverted={true}
          title={<div className={s.TechHistory__title}>Физтех сегодня</div>}
          content={
            <div className={s.TechHistory__text}>
              <p>
                Это передовой научный центр. За последние годы здесь были
                открыты несколько десятков новых лабораторий, где работают
                ученые с мировым именем.
              </p>
              <p>
                Они занимаются проблемами старения и возрастных заболеваний,
                прикладной и фундаментальной физикой, нанооптикой, квантовыми
                вычислениями, фотоникой и многим другим.
              </p>
              <p>
                В МФТИ ежегодно учатся около 9 000 студентов, а преподают более
                80&nbsp;академиков и членов-корреспондентов РАН. Кстати, это
                дает самое большое число академиков на одного студента среди
                всех российских вузов.
              </p>
            </div>
          }
          extContent={
            <div className={s.TechHistory__slider}>
              <HistorySlider />
            </div>
          }
        />
      </div>
    </LandingLayoutContent>
  );
};
