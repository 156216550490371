import { parseUrl, stringify } from 'query-string';

const youtubeEmbedLink = 'https://www.youtube.com/embed';
const rutubeEmbedLink = 'https://rutube.ru/play/embed';

const parsers = [
  (url: string) => {
    if (!url) return null;

    const parsedUrl = parseUrl(url);
    const query = parsedUrl.query;

    let videoId = '';

    if (parsedUrl.url.indexOf('youtube.com') !== -1) {
      videoId = query?.['v'] as string;
    } else if (parsedUrl.url.indexOf('youtu.be') !== -1) {
      videoId = parsedUrl.url?.split('youtu.be/')?.[1];
    }

    const timeQuery = query?.t ? stringify({ start: query.t }) : '';

    return videoId ? `${youtubeEmbedLink}/${videoId}?${timeQuery}` : null;
  },
  (url: string) => {
    if (!url) return null;
    const parsedUrl = parseUrl(url);
    const query = parsedUrl.query;

    let videoId = '';

    if (parsedUrl.url.indexOf('rutube.ru/video/') !== -1) {
      videoId = parsedUrl.url.split('rutube.ru/video/')?.[1];
    } else if (parsedUrl.url.indexOf('rutube.ru/play/embed/') !== -1) {
      videoId = parsedUrl.url.split('rutube.ru/play/embed/')?.[1];
    }

    // чет не работает
    const timeQuery =
      query?.t || query?.bmstart
        ? stringify({ bmstart: query.t || query.bmstart })
        : '';

    return videoId
      ? `${rutubeEmbedLink}/${videoId}?autoplay=0&${timeQuery}`
      : null;
  }
];

export class VideoHostingParser {
  public static getEmbedUrl(url?: string | null) {
    if (!url) return null;

    let embedUrl = '';

    parsers.forEach((parser) => {
      const parsedUrl = parser(url);
      if (parsedUrl) {
        embedUrl = parsedUrl;
        return false;
      }
    });

    return embedUrl || null;
  }
}
