import gql from 'graphql-tag';
import { FRAGMENT_PAGINATION_HEADER } from '../fragments/common';
import {
  FRAGMENT_EXTERNAL_POLL_FULL,
  FRAGMENT_EXTERNAL_POLL_SNIPPET
} from '../fragments/polls';

export const QUERY_GET_EXTERNAL_POLLS_PAGE = gql`
  query queryGetExternalPollsPage($input: ExternalPollsPageInput) {
    externalPollsPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...ExternalPollSnippet
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
  ${FRAGMENT_EXTERNAL_POLL_SNIPPET}
`;

export const QUERY_GET_EXTERNAL_POLL = gql`
  query queryGetExternalPoll($id: ID!) {
    externalPoll(external_poll_id: $id) {
      ...ExternalPollFull
    }
  }

  ${FRAGMENT_EXTERNAL_POLL_FULL}
`;

export const QUERY_GET_POLLS_PAGE = gql`
  query queryGetPollsPage($input: PollsPageInput) {
    pollsPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        id
        name
        cover_file_id
        canAccess
        canViewAttempts
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
`;

export const QUERY_GET_POLL = gql`
  query queryGetPoll($id: ID!) {
    poll(poll_id: $id) {
      id
      name
      questions {
        id
        order
        type
        content
      }
    }
  }
`;

export const QUERY_GET_POLL_ATTEMPTS_PAGE = gql`
  query queryGetPollAttemptsPage($input: PollAttemptsPageInput) {
    pollAttemptsPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        id
        created_at
        answers {
          id
          question {
            id
            order
            content
          }
          value_scale
          value_text
        }
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
`;
