import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { routeLinks } from '../../routeLinks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { Users } from '../../../store/users/Users';
import { UserUtils } from '../../../store/users/UserUtils';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import {
  Breadcrumbs,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { UserForm } from '../../../common/components/ui/UserForm/UserForm';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { getProfilePageBreadcrumbs } from '../index/ProfilePage';
import { useUser } from '../../../graphql/hooks/useUser';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { ErrorMessage } from '../../../common/components/ui/ErrorMessage/ErrorMessage';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { UserPermission } from '../../../utils/permissions';
import s from './ProfileEditPage.module.scss';

export interface ProfileEditPageProps extends RouteComponentProps {
  history: AppHistoryType;
}

export function getProfileEditPageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    ...getProfilePageBreadcrumbs(),
    {
      name: 'Редактирование',
      path: `${routeLinks.profile.to}/edit`
    }
  ];
}

export default function ProfileEditPage({ history }: ProfileEditPageProps) {
  const { user: currentUser } = useCurrentUser();
  const canEditRoles = useHasPermission(currentUser, UserPermission.UserCrud);

  const userQuery = useUser(currentUser?.id);
  const user = userQuery.state.data;

  const updateUser = useGraphqlMutation(Users.updateUser);
  const updateUserRun = updateUser.run;
  const updateUserLoading = updateUser.loading;

  const breadcrumbs = getProfileEditPageBreadcrumbs();

  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
      setSubmitting(true);

      const input = UserUtils.fromUpdateForm(data, canEditRoles);

      updateUserRun({ variables: { input } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Личные данные обновлены');
            history.push(routeLinks.profile.to);
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при изменении личных данных. Попробуйте снова'
          );
        })
        .then(() => {
          setSubmitting(false);
        });
    },
    [updateUserRun, history, canEditRoles]
  );

  const handleCancel = useCallback(() => {
    history.goBack(routeLinks.profile.to);
  }, [history]);

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle
          className={s.ProfileEditPage__pageTitle}
          title={'Редактирование профиля'}
        />

        {queryLoader(userQuery) ||
          (!user ? (
            <ErrorMessage
              text={'Произошла ошибка'}
              description={'Не удалось загрузить данные'}
            />
          ) : (
            <UserForm
              user={user}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              submissionLoading={updateUserLoading}
            />
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
