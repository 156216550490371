import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import {
  Breadcrumbs,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { routeLinks } from '../../routeLinks';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { getInternshipsPageBreadcrumbs } from '../list/InternshipsPage';
import { Internships } from '../../../store/internships/Internships';
import { InternshipUtils } from '../../../store/internships/InternshipUtils';
import { InternshipForm } from '../InternshipForm/InternshipForm';
import s from './InternshipCreatePage.module.scss';

export interface InternshipCreatePageProps extends RouteComponentProps {
  history: AppHistoryType;
}

export function getInternshipCreatePageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    ...getInternshipsPageBreadcrumbs(),
    {
      name: 'Создание',
      path: `${routeLinks.internships.to}/create`
    }
  ];
}

export default function InternshipCreatePage({
  history
}: InternshipCreatePageProps) {
  const createInternship = useGraphqlMutation(Internships.createInternship);
  const createInternshipRun = createInternship.run;
  const createInternshipLoading = createInternship.loading;

  const breadcrumbs = useMemo(() => getInternshipCreatePageBreadcrumbs(), []);

  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
      setSubmitting(true);

      const input = InternshipUtils.fromCreateForm(data);

      createInternshipRun({ variables: { input } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }

          toast.success('Стажировка добавлена');
          history.push(`${routeLinks.internships.to}/${result.data.result.id}`);
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при создании стажировки. Попробуйте снова'
          );
        })
        .then(() => {
          setSubmitting(false);
        });
    },
    [createInternshipRun, history]
  );

  const handleCancel = useCallback(() => {
    if (window.confirm('Вы действительно хотите отменить редактирование?')) {
      history.goBack(routeLinks.internships.to);
    }
  }, [history]);

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle
          className={s.ProjectCreatePage__pageTitle}
          title={'Создание стажировки'}
          showHeading={true}
        />
        <InternshipForm
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          submissionLoading={createInternshipLoading}
        />
      </LayoutContent>
    </PageLayout>
  );
}
