import React, { useCallback } from 'react';
import { OptionTypeBase } from 'react-select/src/types';
import { AsyncSelect, AsyncSelectProps } from '../AsyncSelect';
import { Entity, EntityPageInput } from './types';
import { useAsyncEntityOptions } from './useAsyncEntityOptions';

export type AsyncEntitiesCreatableSelectSelfProps = {
  value?: OptionTypeBase[] | null;
};

export type AsyncEntitiesCreatableSelectProps<
  T extends Entity
> = AsyncEntitiesCreatableSelectSelfProps &
  Omit<AsyncSelectProps<true, true>, 'value' | 'isMulti' | 'isCreatable'> &
  EntityPageInput<T>;

export function AsyncEntitiesCreatableSelect<T extends Entity>({
  entity,
  value,
  filter,
  pagination,
  onChange,
  ...props
}: AsyncEntitiesCreatableSelectProps<T>) {
  const [loadOptions, loadByIds] = useAsyncEntityOptions(
    entity,
    filter,
    pagination
  );

  const handleChange = useCallback(
    (items: readonly OptionTypeBase[]) => {
      onChange?.(items);
    },
    [onChange]
  );

  const handleCreate = useCallback(
    (inputValue: string) => {
      const newItem = { label: inputValue };
      onChange?.(value ? [...value, newItem] : newItem);
    },
    [value, onChange]
  );

  return (
    <AsyncSelect
      value={value}
      loadOptions={loadOptions}
      defaultOptions
      cacheOptions
      isMulti
      isCreatable
      onChange={handleChange}
      onCreateOption={handleCreate}
      {...props}
    />
  );
}
