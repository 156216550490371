import React from 'react';
import clsx from 'clsx';
import { IconButton } from '../IconButton/IconButton';
import { ReactComponent as DotsIcon } from '../../../../assets/img/icons/dotsVertical.svg';
import { DropdownSlotProps } from '../Dropdown/Dropdown';
import { ButtonSize, ButtonVariant } from '../Button';
import s from './CardDotsButton.module.scss';

export interface CardDotsButtonProps extends DropdownSlotProps {
  className?: string;
}

export const CardDotsButton: React.FC<CardDotsButtonProps> = ({
  className,
  ...props
}) => (
  <IconButton
    className={className}
    classes={{
      root: clsx(s.CardDotsButton__actionsTrigger)
    }}
    icon={<DotsIcon />}
    size={ButtonSize.small}
    variant={props.isOpen ? ButtonVariant.primary : ButtonVariant.tertiary}
    onClick={() => {
      props?.onToggle?.(!props.isOpen);
    }}
  />
);
