import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as IconChevron } from '../../../../../assets/img/icons/Chevron.svg';
import { Title, TitleVariant } from '../../Title/Title';
import { Icon } from '../../Icon/Icon';
import s from './UserPageBlock.module.scss';

export enum UserPageBlockVariant {
  large = 'large',
  small = 'small'
}

export interface UserPageBlockClasses {
  root?: string;
  head?: string;
  title?: string;
  content?: string;
  contentInner?: string;
  actions?: string;
}

export interface UserPageBlockProps {
  className?: string;
  classes?: UserPageBlockClasses;
  title?: string;
  headContent?: React.ReactNode;
  actions?: React.ReactNode;
  variant?: UserPageBlockVariant;
  isCollapse?: boolean;
  isCollapsed?: boolean;
  onCollapsed?: (isCollapsed: boolean) => void;
  children?: React.ReactNode;
}

export const UserPageBlock: React.FC<UserPageBlockProps> = ({
  className,
  classes,
  title,
  headContent,
  actions,
  variant = UserPageBlockVariant.large,
  isCollapse,
  isCollapsed,
  onCollapsed,
  children
}) => {
  const [collapsed, setCollapsed] = useState(isCollapsed);

  const handleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
    onCollapsed?.(!collapsed);
  }, [collapsed, onCollapsed]);

  useEffect(() => {
    setCollapsed(isCollapsed);
  }, [isCollapsed]);

  const hasHead = !!title || !!headContent || isCollapse;

  return (
    <div
      className={clsx(
        s.UserPageBlock,
        s[`UserPageBlock_${variant}`],
        className,
        classes?.root
      )}
    >
      {hasHead && (
        <div
          className={clsx(
            s.UserPageBlock__head,
            s[`UserPageBlock__head_${variant}`],
            classes?.head,
            {
              [s.UserPageBlock__head_collapse]: isCollapse
            }
          )}
          onClick={isCollapse ? handleCollapsed : undefined}
        >
          <Title
            className={clsx(
              s.UserPageBlock__title,
              s[`UserPageBlock__title_${variant}`],
              classes?.title
            )}
            title={title}
            variant={
              variant === UserPageBlockVariant.large
                ? TitleVariant.h2
                : TitleVariant.h3
            }
          />
          {headContent}
          {isCollapse && (
            <Icon
              className={s.UserPageBlock__collapseArrow}
              iconClassName={clsx(s.UserPageBlock__collapseArrowIcon, {
                [s.UserPageBlock__collapseArrowIcon_collapsed]: collapsed
              })}
              icon={IconChevron}
              boxSize={44}
              iconSize={20}
            />
          )}
        </div>
      )}
      {!collapsed && (
        <div className={clsx(s.UserPageBlock__content, classes?.content)}>
          <div
            className={clsx(
              s.UserPageBlock__contentInner,
              classes?.contentInner
            )}
          >
            {children}
          </div>
          {actions && (
            <div className={clsx(s.UserPageBlock__actions, classes?.actions)}>
              {actions}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
