import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { TableRowProps } from '../../../common/components/ui/Table/Table';
import { ActionRow } from '../../../common/components/ui/Table/ActionRow/ActionRow';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { SystemLinks } from '../../../store/systemLinks/SystemLinks';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { useWindowSize } from '../../../common/hooks/useWindowSize';

export const SystemLinksRow: React.FC<TableRowProps> = (props) => {
  const deleteSystemLink = useGraphqlMutation(SystemLinks.deleteSystemLink);
  const deleteSystemLinkRun = deleteSystemLink.run;
  const handleDeleteSystemLink = useCallback(
    ({ row }) => {
      if (window.confirm('Вы действительно хотите удалить ссылку?')) {
        deleteSystemLinkRun({ variables: { id: row.id } })
          .then((result) => {
            if (!result.data?.result) {
              throw new Error('UnexpectedResult');
            }
            toast.success('Ссылка удалена');
          })
          .catch((err) => {
            if (err instanceof AsyncSingletonError) return;
            handleDefaultError(
              err,
              'Произошла ошибка при удалении ссылки. Попробуйте снова'
            );
          });
      }
    },
    [deleteSystemLinkRun]
  );

  const updateSystemLink = useGraphqlMutation(SystemLinks.updateSystemLink);
  const updateSystemLinkRun = updateSystemLink.run;
  const handleUpdateSystemLink = useCallback(
    ({ row, state, cancelEdit }) => {
      updateSystemLinkRun({
        variables: {
          input: {
            id: row.id,
            name: state.name,
            link: state.link,
            priority: +state.priority
          }
        }
      })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          cancelEdit();
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при изменении ссылки. Попробуйте снова'
          );
        });
    },
    [updateSystemLinkRun]
  );

  const { isMobile } = useWindowSize();
  return (
    <ActionRow
      onDeleteRow={handleDeleteSystemLink}
      onSubmitRow={handleUpdateSystemLink}
      showTitle={isMobile}
      {...props}
    />
  );
};
