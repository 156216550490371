import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { isEmpty } from 'lodash-es';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { ReactComponent as IconPlus } from '../../../assets/img/icons/plus.svg';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import {
  getBreadcrumbRouteLink,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { removeTrailingSlash } from '../../../utils/url/url';
import { routeLinks } from '../../routeLinks';
import { UserPermission } from '../../../utils/permissions';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { TabsList } from '../../../common/components/ui/Tabs/TabsList/TabsList';
import { STORE_LOCATION } from '../../../store/storeKeys';
import {
  TransformedQueryParams,
  URL_KEY_PAGE,
  URL_KEY_SHEET_VIEW,
  URL_KEY_SOURCE
} from '../../../store/urlKeys';
import { CourseSource } from '../../../graphql/types';
import { CoursesPageTabs } from '../types';
import { BottomSheet } from '../../../common/components/ui/BottomSheet/BottomSheet';
import { IconButton } from '../../../common/components/ui/IconButton/IconButton';
import { ReactComponent as FilterIcon } from '../../../assets/img/icons/Filter.svg';
import { SheetView } from '../../../common/types/sheet';
import {
  SwitchView,
  SwitchViewType
} from '../../../common/components/ui/Switches/SwitchView/SwitchView';
import { useCoursesFilter } from './useCoursesFilter';
import { CoursesList } from './CoursesList/CoursesList';
import { CoursesTiles } from './CoursesTiles/CoursesTiles';
import s from './CoursesPage.module.scss';

const tabs = [
  {
    id: CoursesPageTabs.ALL,
    title: 'Все'
  },
  {
    id: CoursesPageTabs.MIPT,
    title: 'От МФТИ'
  },
  {
    id: CoursesPageTabs.PARTNER,
    title: 'От партнеров'
  },
  {
    id: CoursesPageTabs.ARCHIVED,
    title: 'Черновики/Архив'
  }
];

const views = [
  {
    id: SheetView.LIST,
    type: SwitchViewType.LIST
  },
  {
    id: SheetView.GRID,
    type: SwitchViewType.GRID
  }
];

function isTabCourseSource(currentTab): boolean {
  return Object.values(CourseSource).includes(currentTab);
}

export function getCoursesPageBreadcrumbs(
  { type = CoursesPageTabs.ALL }: { type?: CoursesPageTabs } = {
    type: CoursesPageTabs.ALL
  }
): IBreadcrumbsItem[] {
  return [getBreadcrumbRouteLink(routeLinks.courses, `source=${type}`)];
}

export default function CoursesPage({ match, history }: RouteComponentProps) {
  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.CourseCrud);
  const { isDesktop, isMobile } = useWindowSize();

  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const changeQuery = locationStore.changeQuery;
  const query = useMemo<Partial<TransformedQueryParams>>(
    () => locationState.query || {},
    [locationState]
  );

  const availTabs = useMemo(() => {
    return canEdit
      ? tabs
      : tabs.filter((t) => t.id !== CoursesPageTabs.ARCHIVED);
  }, [canEdit]);

  const page = query[URL_KEY_PAGE] || 1;
  const source = query[URL_KEY_SOURCE];
  const view = query[URL_KEY_SHEET_VIEW];

  useEffect(() => {
    const queryObj = {};
    if (!source) {
      queryObj[URL_KEY_SOURCE] = CoursesPageTabs.ALL;
    }
    if (!view) {
      queryObj[URL_KEY_SHEET_VIEW] = SheetView.GRID;
    }
    if (!isEmpty(queryObj)) {
      changeQuery(queryObj);
    }
  }, [source, view, changeQuery]);

  const currentTab = source && CoursesPageTabs[source];
  const currentView = view && SheetView[view];

  const [filterComponent, filter] = useCoursesFilter();
  const currFilter = useMemo(() => {
    const currSource = (isTabCourseSource(source)
      ? source
      : undefined) as CourseSource;
    return {
      source: currSource,
      ...filter
    };
  }, [filter, source]);

  const handleTabChange = useCallback(
    (id) => {
      if (source !== id) {
        changeQuery({
          [URL_KEY_SOURCE]: id,
          [URL_KEY_PAGE]: 1
        });
      }
    },
    [source, changeQuery]
  );

  const handleViewChange = useCallback(
    (id) => {
      if (view !== id) {
        changeQuery({
          [URL_KEY_SHEET_VIEW]: id,
          [URL_KEY_PAGE]: 1
        });
      }
    },
    [view, changeQuery]
  );

  /**
   * переводим на вид "LIST",
   * если выбран таб "Черновики/Архив"
   */
  useEffect(() => {
    if (currentTab === CoursesPageTabs.ARCHIVED) {
      handleViewChange(SheetView.LIST);
    }
  }, [currentTab, handleViewChange]);

  const [showFilter, setShowFilter] = useState(false);

  return (
    <PageLayout>
      <LayoutContent>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={isDesktop ? 8 : 12}>
            <PageTitle
              className={s.CoursesPage__pageTitle}
              title={routeLinks.courses.text}
              showHeading={true}
              addButton={
                isMobile ? (
                  <IconButton
                    variant={
                      showFilter
                        ? ButtonVariant.primary
                        : ButtonVariant.secondary
                    }
                    icon={<FilterIcon />}
                    onClick={() => setShowFilter(true)}
                  />
                ) : null
              }
            >
              {!isDesktop && !isMobile && (
                <IconButton
                  variant={
                    showFilter ? ButtonVariant.primary : ButtonVariant.secondary
                  }
                  icon={<FilterIcon />}
                  onClick={() => setShowFilter(true)}
                />
              )}
              <BottomSheet
                title={'Фильтры'}
                open={!isDesktop && showFilter}
                onDismiss={() => setShowFilter(false)}
                snapPoints={({ minHeight }) => minHeight}
              >
                {filterComponent}
              </BottomSheet>
              {canEdit && (
                <div>
                  <ButtonLink
                    to={`${removeTrailingSlash(match.path)}/create`}
                    variant={ButtonVariant.secondary}
                    size={ButtonSize.small}
                    iconLeft={<IconPlus />}
                  >
                    Добавить курс
                  </ButtonLink>
                </div>
              )}
            </PageTitle>
            <div className={s.CoursesPage__head}>
              <TabsList
                className={s.CoursesPage__tabs}
                curTab={currentTab}
                tabs={availTabs}
                onChange={handleTabChange}
              />
              <SwitchView
                className={s.CoursesPage__viewSwitch}
                switches={views}
                selectedId={currentView}
                onChange={handleViewChange}
              />
            </div>
            <div className={s.CoursesPage__list}>
              {view === SheetView.LIST && (
                <CoursesList
                  isEditable={canEdit}
                  filter={currFilter}
                  page={page}
                  showArchived={source === CoursesPageTabs.ARCHIVED}
                />
              )}
              {view === SheetView.GRID && (
                <CoursesTiles coursesFilter={currFilter} page={page} />
              )}
            </div>
          </LayoutGrid.GridItem>
          {isDesktop && (
            <LayoutGrid.GridItem cols={4}>
              {filterComponent}
            </LayoutGrid.GridItem>
          )}
        </LayoutGrid>
      </LayoutContent>
    </PageLayout>
  );
}
