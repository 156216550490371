import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { ReactComponent as IconChevron } from '../../../../assets/img/icons/chevron-to-right.svg';
import { ReactComponent as IconArrowLink } from '../../../../assets/img/icons/ArrowLink.svg';
import s from './MobileMenu.module.scss';

export interface MobileMenuItemProps {
  text: React.ReactNode;
  onClick?: () => void;
  to?: string;
  href?: string;
  selected?: boolean;
  isExternal?: boolean;
}

export const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  text,
  onClick,
  to,
  href,
  selected,
  isExternal
}) => {
  if (href) {
    return (
      <a
        className={clsx(
          s.MobileMenuItem,
          selected && s.MobileMenuItem_selected
        )}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        {text}
        {isExternal && (
          <i className={s.MobileMenu__linkExternalArrow}>
            <IconArrowLink />
          </i>
        )}
      </a>
    );
  }
  if (to) {
    return (
      <Link
        className={clsx(
          s.MobileMenuItem,
          selected && s.MobileMenuItem_selected
        )}
        to={to}
      >
        {text}
        {isExternal && (
          <i className={s.NavLink__linkExternalArrow}>
            <IconArrowLink />
          </i>
        )}
      </Link>
    );
  }
  return (
    <div
      onClick={onClick}
      className={clsx(s.MobileMenuItem, selected && s.MobileMenuItem_selected)}
    >
      {text} <IconChevron />
    </div>
  );
};
