import { MutationOptions } from 'apollo-client';
import {
  SystemLinkCreateInput,
  SystemLinkType,
  SystemLinkUpdateInput
} from '../../graphql/types';
import { clearCache } from '../../graphql/queriesUtils';
import {
  MUTATION_CREATE_SYSTEM_LINK,
  MUTATION_DELETE_SYSTEM_LINK,
  MUTATION_UPDATE_SYSTEM_LINK
} from '../../graphql/mutations/systemLinks';

export class SystemLinks {
  private static refetchQueries = ['queryGetSystemLinks'];
  private static updateQueries = ['systemLinks'];
  private static updateFragments = ['SystemLinkType'];

  public static createSystemLink: MutationOptions<
    {
      result: SystemLinkType;
    },
    {
      input: SystemLinkCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_SYSTEM_LINK,
    refetchQueries: SystemLinks.refetchQueries,
    update: clearCache(SystemLinks.updateQueries, SystemLinks.updateFragments)
  };

  public static updateSystemLink: MutationOptions<
    {
      result: SystemLinkType;
    },
    {
      input: SystemLinkUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_SYSTEM_LINK,
    refetchQueries: SystemLinks.refetchQueries,
    update: clearCache(SystemLinks.updateQueries, SystemLinks.updateFragments)
  };

  public static deleteSystemLink: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_DELETE_SYSTEM_LINK,
    refetchQueries: SystemLinks.refetchQueries,
    update: clearCache(SystemLinks.updateQueries, SystemLinks.updateFragments)
  };
}
