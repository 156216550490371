import React from 'react';
import { Card, CardProps } from '../../../common/components/ui/Card/Card';
import {
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { CardPattern } from '../../../common/components/ui/Card/patterns/CardPattern/CardPattern';
import {
  FigureType,
  FigureVariant
} from '../../../common/components/ui/Figures/types';
import { Tag } from '../../../common/components/ui/Tag/Tag';
import { RouteLinkProps } from '../../routeLinks';
import s from './AboutHR.module.scss';

export interface AboutCardProps extends Omit<CardProps, 'title'> {
  title: string;
  links?: RouteLinkProps[];
  to?: string;
}

export const AboutCard: React.FC<AboutCardProps> = ({
  title,
  links,
  to,
  ...props
}) => {
  return (
    <Card
      className={s.AboutCard}
      actions={
        to && (
          <ButtonLink
            className={s.AboutCard__linkButton}
            variant={ButtonVariant.secondary}
            size={ButtonSize.medium}
            to={to}
          >
            {title}
          </ButtonLink>
        )
      }
      extContent={
        <CardPattern
          className={s.AboutCard__pattern}
          figures={[
            {
              className: s.AboutCardTriangle_front,
              type: FigureType.triangle,
              variant: FigureVariant.light,
              size: 280
            },
            {
              className: s.AboutCardTriangle_back,
              type: FigureType.triangle,
              size: 280
            }
          ]}
        />
      }
      {...props}
    >
      <Title variant={TitleVariant.h2} title={title} />

      {links && links.length > 0 && (
        <div className={s.AboutCard__tags}>
          {links.map(({ text, to }, iTag) => (
            <Tag key={iTag} to={to} className={s.AboutCard__tag}>
              {text}
            </Tag>
          ))}
        </div>
      )}
    </Card>
  );
};
