export const userFormNames = {
  id: 'id',

  avatar_file_id: 'avatar_file_id',
  name: 'name',
  email: 'email',
  phone: 'phone',
  mobile_phone: 'mobile_phone',
  work_phone: 'work_phone',
  campus_id: 'campus_id',

  vk: 'vk',
  instagram: 'instagram',
  facebook: 'facebook',

  degrees: 'degrees',
  publications: 'publications',

  work_experiences: 'work_experiences',
  skills: 'skills',
  areas_of_interest: 'areas_of_interest',
  about: 'about',

  roles: 'roles'
};
