import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Card } from '../../Card/Card';
import { ProjectType } from '../../../../../graphql/types';
import { TextLink } from '../../TextLink/TextLink';
import { Title, TitleVariant } from '../../Title/Title';
import {
  ContextMenuButton,
  ContextMenuButtonPosition
} from '../../ContextMenu/ContextMenuButton';
import { routeLinks } from '../../../../../routes/routeLinks';
import { UserStack } from '../../UserStack/UserStack';
import { mapProjectStatus } from '../../../../../routes/projects/utils';
import { StatusIndicator } from '../../StatusIndicator/StatusIndicator';
import s from './CardProjectPreview.module.scss';

export interface CardProjectPreviewProps
  extends Pick<
    ProjectType,
    'id' | 'name' | 'head_user' | 'participants_count' | 'status'
  > {
  className?: string;
  to?: string;
  isEditable?: boolean;
  onDeleteClick?: (id: string) => void;
}

export const CardProjectPreview: React.FC<CardProjectPreviewProps> = ({
  className,
  id,
  to,
  name,
  head_user,
  participants_count,
  status,
  isEditable,
  onDeleteClick
}) => {
  const editActions = useMemo(() => {
    return [
      {
        label: 'Редактировать',
        to: `${routeLinks.projects.to}/${id}/edit`
      },
      {
        label: 'Удалить',
        onClick: () => onDeleteClick?.(id)
      }
    ];
  }, [id, onDeleteClick]);

  const userStack = useMemo(() => {
    if (!head_user) return null;

    return {
      topUsers: [head_user],
      restUsersAmount: participants_count || 0
    };
  }, [participants_count, head_user]);

  const indicatorStatusObj = useMemo(() => {
    return mapProjectStatus(status);
  }, [status]);

  return (
    <Card
      className={clsx(s.CardProjectPreview, className)}
      classes={{
        inner: s.CardProjectPreview__inner,
        actions: s.CardProjectPreview__actions
      }}
      actions={
        <>
          <TextLink
            className={s.CardUserPreview__link}
            to={to || ''}
            noUnderline
            showArrow
          >
            Перейти
          </TextLink>

          {userStack && <UserStack {...userStack} />}
        </>
      }
    >
      <div className={s.CardProjectPreview__head}>
        <Link
          className={s.CardProjectPreview__title}
          to={to || ''}
          tabIndex={-1}
        >
          <StatusIndicator
            className={s.CardProjectPreview__status}
            {...indicatorStatusObj}
            text={''}
          />
          <Title title={name} variant={TitleVariant.h4} />
        </Link>

        {isEditable && (
          <ContextMenuButton
            className={s.CardProjectPreview__contextMenu}
            items={editActions}
            position={ContextMenuButtonPosition.left}
          />
        )}
      </div>
    </Card>
  );
};
