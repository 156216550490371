import React, { useCallback, useEffect, useState } from 'react';
import ru from 'date-fns/locale/ru';
import ReactDatePicker from 'react-datepicker';
import { DATE_FORMAT_RUSSIAN } from '@proscom/ui-utils-date';
import clsx from 'clsx';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import { convertToUtcStr, getDateUtc } from '../../../../utils/date/date';
import s from './DatePicker.module.scss';

export interface DatePickerClasses {
  root?: string;
  label?: string;
  input?: string;
  inputWrapper?: string;
  helperText?: string;
}

export type DatePickerValueType = string;
export type DatePickerSelectedType = Date | undefined;

export interface DatePickerProps {
  className?: string;
  classes?: DatePickerClasses;
  label?: string;
  value?: DatePickerValueType;
  minDate?: Date | null;
  maxDate?: Date | null;
  excludeDates?: Date[];
  disabled?: boolean;
  placeholder?: string;
  errorMessage?: string;
  hintMessage?: string;
  onChange?: (value?: string | undefined) => void;
  onBlur?: () => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  className,
  classes,
  label,
  value,
  minDate,
  maxDate,
  excludeDates,
  disabled,
  placeholder,
  hintMessage,
  errorMessage,
  onChange,
  onBlur
}) => {
  const [selected, setSelected] = useState<DatePickerSelectedType>(
    value ? new Date(getDateUtc(value)) : undefined
  );

  useEffect(() => {
    setSelected(value ? new Date(getDateUtc(value)) : undefined);
  }, [value]);

  const handleChange = useCallback(
    (val: Date | null) => {
      setSelected(val ?? undefined);
      onChange?.(convertToUtcStr(val) ?? undefined);
    },
    [onChange]
  );

  return (
    <label className={clsx(s.DatePicker, className, classes?.root)}>
      {label && (
        <div className={clsx(s.DatePicker__label, classes?.label)}>{label}</div>
      )}
      <ReactDatePicker
        className={clsx(s.DatePicker__picker, classes?.input)}
        wrapperClassName={clsx(
          s.DatePicker__pickerWrapper,
          classes?.inputWrapper
        )}
        selected={selected}
        startDate={selected}
        minDate={minDate}
        maxDate={maxDate}
        excludeDates={excludeDates}
        disabled={disabled}
        placeholderText={placeholder}
        dateFormat={DATE_FORMAT_RUSSIAN}
        locale={ru}
        shouldCloseOnSelect={true}
        onChange={handleChange}
        onBlur={onBlur}
      />
      <HelpText
        className={classes?.helperText}
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </label>
  );
};
