import React from 'react';
import {
  UserPageBlock,
  UserPageBlockVariant
} from '../../UserBlocks/UserPageBlock/UserPageBlock';
import { FormSection } from '../../Form/FormSection/FormSection';
import { userFormNames } from '../../../../../store/users/UserConstants';
import { Entity } from '../../AsyncSelect/entities/types';
import { FormAsyncEntitiesCreatableSelect } from '../../Form/FormAsyncEntitiesCreatableSelect/FormAsyncEntitiesCreatableSelect';

export const EditSkills = () => {
  return (
    <UserPageBlock variant={UserPageBlockVariant.small} title={'Компетенции'}>
      <FormSection>
        <FormAsyncEntitiesCreatableSelect
          name={userFormNames.skills}
          entity={Entity.skillsPage}
          placeholder={'Выберите компетенции'}
        />
      </FormSection>
    </UserPageBlock>
  );
};
