import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Breadcrumbs } from '../../../common/components/ui/Breadcrumbs';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  getOfferPageBreadcrumbs,
  GetOfferPageBreadcrumbsArgs
} from '../direct/OfferPage';
import { BasePageInterface } from '../../../common/components/utils/BasePageInterface';
import { useOffer } from '../../../graphql/hooks/offers/useOffer';
import {
  OffersFormValues,
  OffersUtils
} from '../../../store/offers/OffersUtils';
import { OfferForm } from '../OfferForm/OfferForm';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { tryAppendErrorId } from '../../../graphql/graphqlErrors';
import { Offers } from '../../../store/offers/Offers';
import { OffersValidationSchema } from '../../../store/validationSchema';
import { routeLinks } from '../../routeLinks';

export interface GetOfferEditPageBreadcrumbsArgs
  extends GetOfferPageBreadcrumbsArgs {}

export function getOfferEditPageBreadcrumbs({
  offer,
  offersSource
}: GetOfferEditPageBreadcrumbsArgs) {
  return [
    ...getOfferPageBreadcrumbs({ offer, offersSource }),
    {
      name: 'Редактирование',
      path: `${routeLinks.offers.to}/${offer.id}/edit`
    }
  ];
}

export default function OfferEditPage({
  match,
  history
}: BasePageInterface<{ offerId: string }>) {
  const { offerId } = match.params;
  const offerQuery = useOffer(offerId);
  const offer = offerQuery.state.data;

  const updateOp = useGraphqlMutation(Offers.UpdateOffer);
  const updateOpRun = updateOp.run;
  const handleSubmitForm = useCallback(
    (values: OffersFormValues) => {
      const input = OffersUtils.fromUpdateForm(values);
      updateOpRun({ variables: { input } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          history.push(`/offers/${result.data.result.id}`);
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            tryAppendErrorId(
              'Произошла ошибка при изменении бонуса. Попробуйте снова',
              err
            )
          );
        });
    },
    [history, updateOpRun]
  );

  const breadcrumbs = useMemo(
    () =>
      getOfferEditPageBreadcrumbs({
        offersSource: offer?.source,
        offer: { id: offerId, name: offer?.name }
      }),
    [offerId, offer]
  );
  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        {queryLoader(offerQuery) ||
          (offer && (
            <>
              <PageTitle title={[offer.name, 'Бонусы']} showHeading />
              <Formik
                validationSchema={OffersValidationSchema}
                initialValues={OffersUtils.toForm(offer)}
                onSubmit={handleSubmitForm}
              >
                {(props) => (
                  <OfferForm
                    {...props}
                    history={history}
                    offerId={offerId}
                    submissionLoading={updateOp.loading}
                  />
                )}
              </Formik>
            </>
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
