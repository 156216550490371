import { formatDateIso } from '@proscom/ui-utils-date';
import {
  ExternalPollCreateInput,
  ExternalPollType,
  ExternalPollUpdateInput,
  Maybe,
  UnitType
} from '../../graphql/types';
import { UserUtils } from '../users/UserUtils';

export interface Option {
  label: string;
  value: string;
}

export interface ExternalPollFormValues {
  id?: string;
  name: string;
  link: string;
  passcode?: string;
  cover_file_id?: string;
  participant: Option[];
  participants_all?: boolean;
  participating_units: (Option &
    UnitType & { include_children?: Maybe<boolean> })[];
  ends_at?: string | null;
  starts_at?: string | null;
}

export class ExternalPollsUtils {
  static toForm(data?: ExternalPollType): ExternalPollFormValues {
    return {
      id: data?.id,
      name: data?.name || '',
      link: data?.link || '',
      passcode: data?.passcode || '',
      cover_file_id: data?.cover_file_id || '',
      participants_all: data?.participants_all || false,
      participating_units:
        data?.participating_units?.map((u) => {
          return {
            ...u.unit,
            label: u.unit.name || '<без названия>',
            value: u.unit.id,
            include_children: u.include_children
          };
        }) || [],
      participant:
        data?.participants?.map((item) => ({
          label: UserUtils.getName(item),
          value: item.id
        })) || [],
      ends_at: data?.ends_at || null,
      starts_at: data?.starts_at || null
    };
  }

  static fromCreateForm({
    participant,
    cover_file_id,
    ends_at,
    starts_at,
    participating_units,
    ...formData
  }: ExternalPollFormValues): ExternalPollCreateInput {
    return {
      starts_at: starts_at
        ? formatDateIso(new Date(starts_at)) + 'T00:00:00Z'
        : null,
      ends_at: ends_at ? formatDateIso(new Date(ends_at)) + 'T23:59:59Z' : null,
      cover_file_id: !!cover_file_id ? cover_file_id : undefined,
      participant_ids: participant.map((item) => item.value),
      participating_units: participating_units.map((u) => ({
        unit_id: u.value,
        include_children: u.include_children
      })),
      ...formData
    };
  }

  static fromUpdateForm(
    formData: ExternalPollFormValues
  ): ExternalPollUpdateInput {
    return {
      id: formData.id!,
      ...ExternalPollsUtils.fromCreateForm(formData)
    };
  }
}
