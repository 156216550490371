import React from 'react';
import { Modal } from '../../../common/components/ui/Modal/Modal';
import { TourItemProps } from './types';
import s from './Tours.module.scss';

export interface TourModalProps {
  tour?: TourItemProps | null;
  modalActive: boolean;
  onClose: () => void;
}

export const TourModal: React.FC<TourModalProps> = ({
  tour,
  modalActive,
  onClose
}) => {
  const title = tour?.title;
  const frameLink = tour?.link;

  return (
    <Modal
      className={s.TourModal}
      classes={{
        head: s.TourModal__head,
        content: s.TourModal__content
      }}
      title={title}
      isOpen={modalActive}
      onClose={onClose}
    >
      {frameLink && (
        <iframe
          title={'3D-тур'}
          className={s.TourModal__frame}
          src={frameLink}
        />
      )}
    </Modal>
  );
};
