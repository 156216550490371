import React, { useEffect, useState } from 'react';
import { OptionTypeBase } from 'react-select/src/types';
import { AsyncSelect, AsyncSelectProps } from '../AsyncSelect';
import { handleDefaultError } from '../../../../../utils/handleDefaultError';
import { Entity, EntityPageInput } from './types';
import { useAsyncEntityOptions } from './useAsyncEntityOptions';

export interface AsyncEntitySelectSelfProps {
  moreResultsHint?: React.ReactNode;
  value?: string | null;
  onChange?: (item: OptionTypeBase | null | undefined) => void;
}

export type AsyncEntitySelectProps<
  T extends Entity
> = AsyncEntitySelectSelfProps &
  Omit<
    AsyncSelectProps<false, false>,
    'value' | 'isMulti' | 'isCreatable' | 'onChange'
  > &
  EntityPageInput<T>;

export function AsyncEntitySelect<T extends Entity>({
  entity,
  value: valueProp,
  filter,
  pagination,
  ...props
}: AsyncEntitySelectProps<T>) {
  const [value, setValue] = useState<OptionTypeBase | null | undefined>(null);

  const [loadBySearch, loadByIds] = useAsyncEntityOptions(
    entity,
    filter,
    pagination
  );

  useEffect(() => {
    let active = true;
    if (typeof valueProp === 'string') {
      loadByIds?.([valueProp])
        .then((result) => {
          if (!active) return;
          if (!result) throw new Error('Неожиданный результат');
          if (result[0]) {
            setValue(result[0]);
          } else {
            setValue({ label: '...', value: valueProp });
          }
        })
        .catch((err) => {
          if (!active) return;
          handleDefaultError(err, 'Произошла ошибка при получении данных');
        });
    } else {
      setValue(valueProp);
    }

    return () => {
      active = false;
    };
  }, [valueProp, loadByIds]);

  return (
    <AsyncSelect
      value={value}
      loadOptions={loadBySearch}
      defaultOptions
      cacheOptions
      {...props}
    />
  );
}
