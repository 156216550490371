import React, { useMemo } from 'react';
import { FilterSelect, FilterSelectProps } from '../../ui/Filter/FilterSelect';
import { convertDataListToSelectOptions } from '../../../../utils/form/formConverters';
import { useOfferKindsPage } from '../../../../graphql/hooks/offers/useOfferKindsPage';

export interface FilterKindsProps extends FilterSelectProps {}

export const FilterKinds: React.FC<FilterKindsProps> = ({
  value,
  ...props
}) => {
  const kindsQuery = useOfferKindsPage({});
  const queryKinds = kindsQuery.state.data?.list;
  const kindsLoading = kindsQuery.check.spinner;

  const options = useMemo(() => {
    return convertDataListToSelectOptions(queryKinds || [], {
      valueKey: 'id',
      labelKey: 'name'
    });
  }, [queryKinds]);

  return (
    <FilterSelect
      value={value}
      options={options}
      placeholder={props.placeholder || 'Выберите тип'}
      isLoading={kindsLoading}
      isMulti
      {...props}
    />
  );
};
