import React, { useCallback } from 'react';
import { Form, FormikProps } from 'formik';
import { OffersFormValues } from '../../../store/offers/OffersUtils';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { GridItem, LayoutGrid } from '../../../common/components/Layout/Layout';
import { FormCoverLoader } from '../../../common/components/ui/Form/FormCoverLoader/FormCoverLoader';
import { FormSection } from '../../../common/components/ui/Form/FormSection/FormSection';
import { FormTextField } from '../../../common/components/ui/Form/FormTextField/FormTextField';
import { TextFieldSize } from '../../../common/components/ui/TextField/TextField';
import { FormTextArea } from '../../../common/components/ui/Form/FormTextArea/FormTextArea';
import { Button, ButtonVariant } from '../../../common/components/ui/Button';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import { FormDatePicker } from '../../../common/components/ui/Form/FormDatePicker/FormDatePicker';
import { FormTextEditor } from '../../../common/components/ui/Form/FormTextEditor/FormTextEditor';
import { routeLinks } from '../../routeLinks';
import { FormFileLoader } from '../../../common/components/ui/Form/FormFileLoader/FormFileLoader';
import { OfferKindsSelect } from './OfferKindsSelect';
import { OfferCategoriesSelect } from './OfferCategoriesSelect';
import { OfferSourceSelect } from './OfferSourceSelect';
import s from './OfferForm.module.scss';

export interface OfferFormProps extends FormikProps<OffersFormValues> {
  history: AppHistoryType;
  submissionLoading: boolean;
  offerId?: string;
}

export const OfferForm: React.FC<OfferFormProps> = ({
  history,
  offerId,
  submissionLoading,
  values
}) => {
  const handleCancel = useCallback(() => {
    if (window.confirm('Вы действительно хотите отменить редактирование?')) {
      let path = routeLinks.offers.to;
      if (offerId) path += `/${offerId}`;
      history.goBack(path);
    }
  }, [history, offerId]);

  return (
    <Form>
      <LayoutGrid>
        <GridItem cols={8}>
          <FormSection>
            <OfferSourceSelect
              classes={{
                root: s.OfferForm__select,
                label: s.OfferForm__selectLabel
              }}
            />
          </FormSection>
          <FormSection>
            <OfferKindsSelect
              classes={{
                root: s.OfferForm__select,
                label: s.OfferForm__selectLabel
              }}
            />
          </FormSection>
          <FormSection>
            <OfferCategoriesSelect
              classes={{
                root: s.OfferForm__select,
                label: s.OfferForm__selectLabel
              }}
            />
          </FormSection>
          <FormSection>
            <FormCoverLoader
              name={'cover_file_id'}
              fileType={FileTypeEnum.offer_cover}
              classes={{
                title: s.OfferForm__loaderTitle
              }}
            />
          </FormSection>
          <FormSection
            description={
              <>
                Для корректного отображения размер <br />
                логотипа должен соответствовать 80х32пкс
              </>
            }
          >
            <FormCoverLoader
              name={'partner_logo_file_id'}
              fileType={FileTypeEnum.offer_partner_logo}
              label={'Лого партнера'}
              buttonText={'Загрузить лого'}
              classes={{
                title: s.OfferForm__loaderTitle,
                content: s.OfferForm__loaderContent
              }}
            />
          </FormSection>
          <FormSection>
            <FormDatePicker
              name={'expires_at'}
              label={'Срок действия'}
              placeholder={'Выберите дату'}
            />
          </FormSection>
          <FormSection>
            <FormTextField
              name={'name'}
              label={'Название'}
              size={TextFieldSize.large}
            />
          </FormSection>
          <FormSection>
            <FormTextArea
              name={'snippet'}
              label={'Краткое описание бонуса'}
              hintMessage={'Отображается в карточке бонуса под заголовком'}
            />
          </FormSection>
          <FormSection>
            <FormTextEditor label={'Описание'} name={'description'} />
          </FormSection>
          <FormSection>
            <FormTextEditor label={'Условия получения'} name={'conditions'} />
          </FormSection>
          <FormSection>
            <FormTextField label={'Ссылка для получения'} name={'link'} />
          </FormSection>
          <FormSection>
            <FormFileLoader
              name={'documents'}
              label={'Файлы'}
              buttonText={'Прикрепить файлы'}
              fileType={FileTypeEnum.offer_document}
              value={values?.document_files ?? undefined}
              multiple={true}
            />
          </FormSection>
        </GridItem>
      </LayoutGrid>
      <div className={s.OfferForm__actions}>
        <Button variant={ButtonVariant.secondary} onClick={handleCancel}>
          Отменить всё
        </Button>
        <Button type={'submit'} loading={submissionLoading}>
          Сохранить изменения
        </Button>
      </div>
    </Form>
  );
};
