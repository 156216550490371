import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { useClickOutside } from '@proscom/ui-react';
import { isTruthy } from '@proscom/ui-utils';
import { ReactComponent as IconChevron } from '../../../../assets/img/icons/Chevron.svg';
import { ReactComponent as IconArrowLink } from '../../../../assets/img/icons/ArrowLink.svg';
import { BaseLink, BaseLinkProps } from '../BaseLink/BaseLink';
import s from './NavLink.module.scss';

export interface NavLinkProps extends BaseLinkProps {
  linkClassName?: string;
  linkSubsOpenClassName?: string;
  subLinksBoxClassName?: string;
  subLinkClassName?: string;
  subLinks?: (BaseLinkProps | null)[];
  isExternal?: boolean;
}

export const NavLink: React.FC<NavLinkProps> = ({
  className,
  linkClassName,
  linkSubsOpenClassName = '',
  subLinksBoxClassName,
  subLinkClassName,
  to,
  href,
  text,
  subLinks,
  isExternal,
  children
}) => {
  const navLinkRef = useRef(null);
  const [subsOpen, setSubsOpen] = useState(false);

  const handleLinkClick = useCallback(() => {
    setSubsOpen((prevState) => !prevState);
  }, []);

  useClickOutside(navLinkRef, () => setSubsOpen(false));

  const hasSubs = subLinks && subLinks.filter(isTruthy).length > 0;

  return (
    <span ref={navLinkRef} className={clsx(s.NavLink, className)}>
      <BaseLink
        className={clsx(s.NavLink__link, linkClassName, {
          [s.NavLink__link_subsOpen]: subsOpen,
          [linkSubsOpenClassName]: subsOpen
        })}
        to={to}
        href={href}
        onClick={handleLinkClick}
      >
        {text}
        {children}
        {isExternal && (
          <i className={s.NavLink__linkExternalArrow}>
            <IconArrowLink />
          </i>
        )}
        {hasSubs && (
          <i className={s.NavLink__linkArrow}>
            <IconChevron />
          </i>
        )}
      </BaseLink>

      {hasSubs && (
        <div
          className={clsx(s.NavLink__subLinks, subLinksBoxClassName, {
            [s.NavLink__subLinks_open]: subsOpen
          })}
        >
          {subLinks?.filter(isTruthy).map((link, iLink) => (
            <div key={iLink} className={s.NavLink__subLinksItem}>
              <BaseLink
                className={clsx(s.NavLink__subLink, subLinkClassName)}
                {...link}
              >
                {link.text}
              </BaseLink>
            </div>
          ))}
        </div>
      )}
    </span>
  );
};
