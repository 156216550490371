import React from 'react';
import { LandingLayoutContent } from '../../../common/components/Layout/Layout';
import { Tag } from '../../../common/components/ui/Tag/Tag';
import s from './Banner.module.scss';

export const Banner = () => {
  return (
    <LandingLayoutContent className={s.Banner} innerClassName={s.Banner__box}>
      <div className={s.Banner__tags}>
        <Tag className={s.Banner__tag}>hr адаптация</Tag>
      </div>
      <div className={s.Banner__text}>
        Мы всегда рады талантливым специалистам и гордимся всеми участниками
        нашей дружной команды!
      </div>
    </LandingLayoutContent>
  );
};
