import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { BaseLink } from '../BaseLink/BaseLink';
import s from './Pagination.module.scss';

export interface PaginationArrowProps {
  icon: ReactNode;
  to?: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export const PaginationArrow: React.FC<PaginationArrowProps> = ({
  className,
  to,
  disabled,
  icon,
  ...props
}) => {
  return (
    <BaseLink
      to={to}
      className={clsx(className, s.PaginationArrow, {
        [s.PaginationArrow_disabled]: disabled
      })}
      {...props}
    >
      {icon}
    </BaseLink>
  );
};
