import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import clsx from 'clsx';
import { Card, CardStyle } from '../../Card/Card';
import { TextLink } from '../../TextLink/TextLink';
import { Title, TitleVariant } from '../../Title/Title';
import { useFileSrc } from '../../../../hooks/useFileSrc';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { ImagePreview } from '../../ImagePreview/ImagePreview';
import {
  ContextMenuButton,
  ContextMenuButtonPosition
} from '../../ContextMenu/ContextMenuButton';
import { routeLinks } from '../../../../../routes/routeLinks';
import { Tag } from '../../Tag/Tag';
import {
  CourseStatus,
  CourseType,
  CourseTypesEnum
} from '../../../../../graphql/types';
import { PartnerLogo } from '../../PartnerLogo/PartnerLogo';
import { useGraphqlMutation } from '../../../../hooks/utils/useGraphqlMutation';
import { Courses } from '../../../../../store/courses/Courses';
import { handleDefaultError } from '../../../../../utils/handleDefaultError';
import { Loader } from '../../Loader/Loader';
import { PriorityField } from '../../PriorityField/PriorityField';
import { CoursesStatusName } from '../../../../../routes/courses/types';
import s from './CardCourse.module.scss';

export interface CardCourseProps extends CourseType {
  to: string;
  isEditable?: boolean;
  onDeleteClick?: (id: string) => void;
  onTagClick?: (tagId: string) => void;
  onSubjectClick?: (tagId: string) => void;
  onLinkClick?: MouseEventHandler;
}

export const CardCourse: React.FC<CardCourseProps> = ({
  id,
  cover_file_id,
  name,
  description = '',
  tags,
  partner_logo_file_id,
  course_subject,
  type,
  priority,
  status,
  to,
  isEditable,
  onDeleteClick,
  onLinkClick,
  onTagClick,
  onSubjectClick
}) => {
  const courseCoverSrc = useFileSrc(cover_file_id, FileTypeEnum.course_image);
  const coursePartnerLogoSrc = useFileSrc(
    partner_logo_file_id,
    FileTypeEnum.course_image
  );
  const updateCourse = useGraphqlMutation(
    type === CourseTypesEnum.Longread
      ? Courses.updateLongreadCourse
      : Courses.updateAnnounceCourse
  );
  const updateCourseRun = updateCourse.run;

  const handleOrderChangeSubmit = useCallback(
    (priority: number | null) => {
      const input = {
        id,
        priority
      };

      updateCourseRun({ variables: { input } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Приоритет курса обновлен');
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при обновлении приоритета курса. Попробуйте снова'
          );
        });
    },
    [updateCourseRun, id]
  );

  const handleArchiveCourse = useCallback(() => {
    const input = {
      id,
      status: CourseStatus.Archived
    };

    updateCourseRun({ variables: { input } })
      .then((result) => {
        if (result.data?.result) {
          toast.success('Курс архивирован');
        }
      })
      .catch((err) => {
        if (err instanceof AsyncSingletonError) return;
        handleDefaultError(err, 'Произошла ошибка при архивации курса');
      });
  }, [updateCourseRun, id]);

  const statusPublished = status === CourseStatus.Published;
  const hasTags =
    (tags && tags.length > 0) || !!course_subject || !statusPublished;

  const editActions = useMemo(() => {
    const actions = [
      {
        label: 'Редактировать',
        to: `${routeLinks.courses.to}/${id}/edit`
      },
      {
        label: 'Удалить',
        onClick: () => onDeleteClick?.(id.toString())
      }
    ];
    if (statusPublished) {
      actions.push({
        label: 'Архивировать',
        onClick: handleArchiveCourse
      });
    }
    return actions;
  }, [id, statusPublished, onDeleteClick, handleArchiveCourse]);

  return (
    <Card
      className={s.CardCourse}
      classes={{
        inner: s.CardCourse__inner
      }}
      cardStyle={CardStyle.bordered}
    >
      <div>
        <Link to={to || ''} tabIndex={-1} onClick={onLinkClick}>
          <ImagePreview
            className={s.CardCourse__cover}
            classes={{
              image: s.CardCourse__coverImage
            }}
            imageSrc={courseCoverSrc}
            showBackfaceImage
          />
        </Link>
      </div>

      <div className={s.CardCourse__content}>
        <div
          className={clsx(s.CardCourse__head, {
            [s.CardCourse__head_hasTags]: hasTags
          })}
        >
          {hasTags && (
            <div className={s.CardCourse__tags}>
              {!statusPublished && (
                <Tag
                  className={clsx(s.CardCourse__tag, s.CardCourse__tag_status)}
                  isClickable={true}
                  isSelected={true}
                >
                  {CoursesStatusName[status]}
                </Tag>
              )}
              {course_subject && (
                <Tag
                  className={s.CardCourse__tag}
                  isClickable={true}
                  isSelected={true}
                  onClick={() => onSubjectClick?.(course_subject.id)}
                >
                  {course_subject.name}
                </Tag>
              )}
              {tags?.map((tag) => (
                <Tag
                  key={tag.id}
                  className={s.CardCourse__tag}
                  isClickable={true}
                  onClick={() => onTagClick?.(tag.id)}
                >
                  {tag.name}
                </Tag>
              ))}
            </div>
          )}

          {isEditable && (
            <ContextMenuButton
              className={s.CardCourse__contextMenu}
              items={editActions}
              position={ContextMenuButtonPosition.left}
            />
          )}

          <Link
            className={s.CardCourse__headLink}
            to={to || ''}
            tabIndex={-1}
            onClick={onLinkClick}
          >
            <Title title={name} variant={TitleVariant.h4} />

            {description && (
              <div className={s.CardCourse__description}>{description}</div>
            )}
          </Link>
        </div>

        <div className={s.CardCourse__actions}>
          <div className={s.CardCourse__actionsLeft}>
            {isEditable && (
              <div className={s.CardCourse__priority}>
                {updateCourse.loading ? (
                  <Loader />
                ) : (
                  <PriorityField
                    priority={priority}
                    onOrderChangeSubmit={handleOrderChangeSubmit}
                  />
                )}
              </div>
            )}

            {coursePartnerLogoSrc && (
              <div className={s.CardCourse__partnerLogo}>
                <PartnerLogo imageSrc={coursePartnerLogoSrc} />
              </div>
            )}
          </div>
          <div className={s.CardCourse__actionsRight}>
            <TextLink
              className={s.CardCourse__link}
              to={to || ''}
              noUnderline
              showArrow
              onClick={onLinkClick}
            >
              Перейти
            </TextLink>
          </div>
        </div>
      </div>
    </Card>
  );
};
