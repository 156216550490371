import { pick, flatten } from 'lodash-es';
import { eachDayOfInterval, isSameDay } from 'date-fns';
import {
  VacationApplicationInput,
  VacationScheduleInput,
  VacationScheduleItemType,
  VacationScheduleReplaceInput,
  VacationScheduleType,
  VacationStatusesEnum
} from '../../graphql/types';
import { convertToUtcStr, getDateUtc } from '../../utils/date/date';

export interface VacationAppProps extends Partial<VacationApplicationInput> {
  availableDaysAmount?: number | null;
  selectedDaysAmount?: number | null;
  isHeadPosition: boolean;
  deputy_position_id?: string;
}

export interface VacationSchedulesProps extends VacationScheduleType {}

export type VacationScheduleVacationType = {
  id: number;
  periods: (VacationScheduleItemType & { id: number })[];
};

export interface VacationSchedulesFormValuesProps
  extends Pick<
    VacationSchedulesProps,
    'position_id' | 'assigned_days' | 'unassigned_days'
  > {
  vacations: VacationScheduleVacationType[];
  selectedDates: Date[];
}

export class VacationsUtils {
  static VacationAppStatus = {
    [VacationStatusesEnum.Approved]: 'Approved',
    [VacationStatusesEnum.Complete]: 'Complete',
    [VacationStatusesEnum.NotReviewed]: 'NotReviewed',
    [VacationStatusesEnum.Registration]: 'Registration',
    [VacationStatusesEnum.Rejected]: 'Rejected',
    [VacationStatusesEnum.SentForApproval]: 'SentForApproval'
  };

  static VacationAppStatusTitle = {
    [VacationStatusesEnum.Approved]: 'Согласовано',
    [VacationStatusesEnum.Complete]: 'Приказ издан',
    [VacationStatusesEnum.NotReviewed]: 'Не рассмотрено',
    [VacationStatusesEnum.Registration]: 'На оформлении в ОК',
    [VacationStatusesEnum.Rejected]: 'Отклонено',
    [VacationStatusesEnum.SentForApproval]: 'Отправлено на согласование'
  };

  static getSelectedDates(periods: { start: string; end: string }[]): Date[] {
    if (!periods?.length) return [];

    try {
      return flatten(
        periods
          .map(({ start, end }) => {
            if (!start || !end) return null;
            const startDate = new Date(getDateUtc(start));
            const endDate = new Date(getDateUtc(end));
            if (startDate.getTime() > endDate.getTime()) return null;
            return eachDayOfInterval({
              start: startDate,
              end: endDate
            });
          })
          .filter(Boolean)
      ) as Date[];
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  static toForm(): VacationAppProps {
    return {
      position_id: '',
      deputy_id: '',
      deputy_position_id: '',
      start: '',
      end: '',
      availableDaysAmount: null,
      selectedDaysAmount: null,
      isHeadPosition: false
    };
  }

  static fromForm(data: VacationAppProps): VacationApplicationInput {
    return {
      position_id: data.position_id ?? '',
      deputy_id: data.deputy_position_id || undefined,
      start: data.start ?? '',
      end: data.end ?? ''
    };
  }

  static toSchedulesForm(
    data?: VacationSchedulesProps
  ): VacationSchedulesFormValuesProps {
    const vacations = (data?.items || []).map((item, iItem) => ({
      id: iItem,
      periods: [
        {
          ...item,
          id: 0,
          start: convertToUtcStr(item.start) || '',
          end: convertToUtcStr(item.end) || ''
        }
      ]
    }));

    const selectedDates = VacationsUtils.getSelectedDates(data?.items || []);

    return {
      position_id: data?.position.id?.toString() || '',
      unassigned_days: data?.unassigned_days || 0,
      assigned_days: data?.assigned_days || 0,

      // custom
      vacations: vacations.length
        ? vacations
        : [
            {
              id: 0,
              periods: [
                {
                  id: 0,
                  days: 0,
                  start: '',
                  end: '',
                  original_schedule_date: ''
                }
              ]
            }
          ],
      selectedDates
    };
  }

  static fromSchedulesForm(
    formData: VacationSchedulesFormValuesProps,
    initialData: VacationSchedulesFormValuesProps
  ): VacationScheduleInput | null {
    const formVacations = formData.vacations;
    const initVacations = initialData.vacations;
    const initVacationsIds = initVacations.map((v) => v.id);

    const newPlans = flatten(
      formVacations
        .filter((v) => !initVacationsIds.includes(v.id))
        .map((s) => s.periods.map((p) => pick(p, ['start', 'end'])))
    );

    const replacePlans = initVacations
      .map((initVac, iVac) => {
        const initPeriod = initVac.periods[0];
        const currVac = formVacations.find((fv) => fv.id === initVac.id);

        if (!currVac) {
          return {
            old_start: initPeriod.start,
            old_end: initPeriod.end,
            new_plans: [],
            original_schedule_date: initPeriod.original_schedule_date
          };
        } else {
          const currPeriods = currVac.periods.map((p) =>
            pick(p, ['start', 'end'])
          );

          if (
            currPeriods.length > 1 ||
            !isSameDay(
              new Date(currPeriods[0].start),
              new Date(initPeriod.start)
            ) ||
            !isSameDay(new Date(currPeriods[0].end), new Date(initPeriod.end))
          ) {
            return {
              old_start: initPeriod.start,
              old_end: initPeriod.end,
              new_plans: currPeriods,
              original_schedule_date: initPeriod.original_schedule_date
            };
          } else {
            return null;
          }
        }
      })
      .filter(Boolean) as VacationScheduleReplaceInput[];

    if (!newPlans.length && !replacePlans.length) return null;

    return {
      position_id: initialData.position_id,
      new_plans: newPlans.length ? newPlans : undefined,
      replace_plans: replacePlans.length ? replacePlans : undefined
    };
  }
}
