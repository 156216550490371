import React from 'react';
import {
  GridItem,
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import {
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { ReactComponent as PlusIcon } from '../../../assets/img/icons/plus.svg';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { IBreadcrumbsItem } from '../../../common/components/ui/Breadcrumbs';
import { BasePageInterface } from '../../../common/components/utils/BasePageInterface';
import { routeLinks } from '../../routeLinks';
import { UserPermission } from '../../../utils/permissions';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { URL_KEY_SEARCH } from '../../../store/urlKeys';
import { FilterSearch } from '../../../common/components/ui/Filter/FilterSearch';
import { IconButtonCreate } from '../../../common/components/ui/IconButtonCreate/IconButtonCreate';
import { useReserveFilter } from './ReserveFilter/useReserveFilter';
import { ReserveList } from './ReserveList';
import reserveFiltersStyles from './ReserveFilter/useReserveFilter.module.scss';
import s from './ReservesPage.module.scss';

export function getReservesPageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    {
      name: routeLinks.reserve.text,
      path: routeLinks.reserve.to
    }
  ];
}

export default function ReservesPage({ history }: BasePageInterface<{}>) {
  const [filterComponent, filter, handleFilterChange] = useReserveFilter();

  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.OfferCrud);

  const { isTablet, isMobile } = useWindowSize();

  return (
    <PageLayout>
      <LayoutContent>
        <LayoutGrid>
          <GridItem cols={8}>
            <PageTitle
              title={routeLinks.reserve.text}
              // caption={
              //   <>
              //     Здесь размещены бонусы, уникальные предложения и&nbsp;акции
              //     от&nbsp;МФТИ и&nbsp;партнеров университета.
              //   </>
              // }
              className={s.ReservesPage__title}
              captionClassName={s.ReservesPage__caption}
              showHeading
              addButton={
                isMobile ? (
                  <IconButtonCreate to={`${routeLinks.reserve.to}/create`} />
                ) : null
              }
            >
              {canEdit && !isMobile && (
                <ButtonLink
                  className={s.ReservesPage__addButton}
                  to={`${routeLinks.reserve.to}/create`}
                  iconLeft={<PlusIcon />}
                  variant={ButtonVariant.secondary}
                  size={ButtonSize.small}
                >
                  Добавить карточку
                </ButtonLink>
              )}
            </PageTitle>

            {isMobile && (
              <div className={s.ReservesPage__search}>
                <FilterSearch
                  value={filter.search ?? ''}
                  onChange={handleFilterChange(URL_KEY_SEARCH)}
                  placeholder={'Поиск'}
                  className={reserveFiltersStyles.ReserveFilter__filter}
                  classes={{
                    filter: { content: s.ReservesPage__filterRoot },
                    searchField: { root: s.ReservesPage__searchField }
                  }}
                />
              </div>
            )}

            <ReserveList filter={filter} history={history} />
          </GridItem>

          {!isMobile && (
            <GridItem cols={isTablet ? 3 : 4}>{filterComponent}</GridItem>
          )}
        </LayoutGrid>
      </LayoutContent>
    </PageLayout>
  );
}
