import gql from 'graphql-tag';
import { FRAGMENT_SYSTEM_LINK } from '../fragments/systemLinks';

export const QUERY_GET_SYSTEM_LINKS = gql`
  query queryGetSystemLinks {
    systemLinks {
      ...SystemLinkProps
    }
  }

  ${FRAGMENT_SYSTEM_LINK}
`;
