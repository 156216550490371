import React from 'react';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { PageTitle } from '../../common/components/ui/PageTitle/PageTitle';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../common/components/ui/Button';
import { useGraphqlMutation } from '../../common/hooks/utils/useGraphqlMutation';
import { ReactComponent as AddIcon } from '../../assets/img/icons/plus.svg';
import { Documents } from '../../store/documents/Documents';
import { tryAppendErrorId } from '../../graphql/graphqlErrors';
import { routeLinks } from '../routeLinks';
import { useWindowSize } from '../../common/hooks/useWindowSize';
import { IconButtonCreate } from '../../common/components/ui/IconButtonCreate/IconButtonCreate';
import s from './DocumentsPageTitle.module.scss';

export interface DocumentsPageTitleProps {
  showAddButton?: boolean;
}

export const DocumentsPageTitle: React.FC<DocumentsPageTitleProps> = ({
  showAddButton
}) => {
  const createDocumentSection = useGraphqlMutation(
    Documents.CreateDocumentSection
  );
  const createDocumentSectionRun = createDocumentSection.run;
  const handleAddNewSection = () => {
    createDocumentSectionRun({
      variables: {
        input: {
          name: 'Новый раздел'
        }
      }
    })
      .then((result) => {
        if (!result.data?.result) {
          throw new Error('UnexpectedResult');
        }
        toast.success('Раздел добавлен');
      })
      .catch((err) => {
        if (err instanceof AsyncSingletonError) return;
        tryAppendErrorId(
          err,
          'Произошла ошибка при создании раздела. Попробуйте снова'
        );
      });
  };

  const { isMobile } = useWindowSize();

  return (
    <PageTitle
      className={s.DocumentsPageTitle}
      title={routeLinks.documents.text}
      showHeading
      addButton={
        isMobile && showAddButton ? (
          <IconButtonCreate onClick={handleAddNewSection} />
        ) : null
      }
    >
      {showAddButton && !isMobile && (
        <Button
          variant={ButtonVariant.secondary}
          size={ButtonSize.small}
          iconLeft={<AddIcon />}
          classes={{
            root: s.DocumentSectionTitle__addButton
          }}
          onClick={handleAddNewSection}
          loading={createDocumentSection.loading}
        >
          Добавить раздел
        </Button>
      )}
    </PageTitle>
  );
};
