import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { FileUploadButton } from '../UploadField/FileUploadButton';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import { FileType } from '../../../../graphql/types';
import { FileLoaderItemProps, FileLoaderList } from './FileLoaderList';
import s from './FileLoader.module.scss';

export interface UploadFileType extends Pick<FileType, 'id' | 'name'> {}

export interface FileLoaderClasses {
  root?: string;
  label?: string;
  content?: string;
  list?: string;
  listItem?: FileLoaderItemProps['classes'];
  uploadButton?: string;
  helperText?: string;
}

export interface FileLoaderProps {
  className?: string;
  classes?: FileLoaderClasses;
  value?: UploadFileType[];
  fileType: FileTypeEnum;
  multiple?: boolean;
  label?: string;
  buttonText?: string;
  errorMessage?: string;
  hintMessage?: string;
  editable?: boolean;
  onChange: (files: UploadFileType[]) => void;
}

export const FileLoader: React.FC<FileLoaderProps> = ({
  className,
  classes,
  fileType,
  value,
  multiple,
  label,
  buttonText,
  errorMessage,
  hintMessage,
  editable = true,
  onChange
}) => {
  const [files, setFiles] = useState(value || []);
  const filesRef = useRef(value || []);

  const handleFileUploaded = useCallback(
    (fileId: string, file: File) => {
      filesRef.current = [...filesRef.current, { id: fileId, name: file.name }];
      setFiles(filesRef.current);
      onChange(filesRef.current);
    },
    [onChange]
  );

  const handleFileChange = useCallback(
    (fileId: string, name: string) => {
      const file = filesRef.current.find((f) => f.id === fileId);
      if (file) {
        file.name = name;
        setFiles(filesRef.current);
        onChange(filesRef.current);
      }
    },
    [onChange]
  );

  const handleFileDelete = useCallback(
    (fileId: string) => {
      const fileIndex = filesRef.current.findIndex((f) => f.id === fileId);
      if (fileIndex !== -1) {
        filesRef.current.splice(fileIndex, 1);
      }
      setFiles(filesRef.current);
      onChange(filesRef.current);
    },
    [onChange]
  );

  return (
    <div className={clsx(s.FileLoader, className, classes?.root)}>
      {label && (
        <span className={clsx(s.FileLoader__label, classes?.label)}>
          {label}
        </span>
      )}
      <div className={clsx(s.FileLoader__content, classes?.content)}>
        {files.length > 0 && (
          <FileLoaderList
            className={clsx(s.FileLoader__list, classes?.list)}
            classes={{
              item: classes?.listItem
            }}
            fileType={fileType}
            files={files}
            editable={true}
            onChange={editable ? handleFileChange : undefined}
            onDelete={handleFileDelete}
          />
        )}
        {(multiple || files.length === 0) && (
          <FileUploadButton
            className={clsx(s.FileLoader__uploadButton, classes?.uploadButton)}
            fileType={fileType}
            onFileUploaded={handleFileUploaded}
            buttonText={buttonText}
          />
        )}
      </div>
      <HelpText
        className={classes?.helperText}
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </div>
  );
};
