import React from 'react';
import clsx from 'clsx';
import { DivPropsWithoutRef } from '../../../utils/reactTypes';
import s from './Layout.module.scss';

export interface LayoutContentProps extends DivPropsWithoutRef {
  className?: string;
  innerClassName?: string;
  children?: React.ReactNode;
}

export const LayoutContent: React.FC<LayoutContentProps> = ({
  className,
  innerClassName,
  children,
  ...props
}) => {
  return (
    <div className={clsx(s.LayoutContent, className)} {...props}>
      <div className={clsx(s.LayoutContent__inner, innerClassName)}>
        {children}
      </div>
    </div>
  );
};

export const LandingLayoutContent: React.FC<LayoutContentProps> = ({
  className,
  innerClassName,
  children,
  ...props
}) => {
  return (
    <LayoutContent
      className={className}
      innerClassName={clsx(s.LandingLayoutContent__inner, innerClassName)}
      {...props}
    >
      {children}
    </LayoutContent>
  );
};

export interface GridItemProps {
  className?: string;
  cols?: number;
  rows?: number;
  children?: React.ReactNode;
}

export const GridItem: React.FC<GridItemProps> = ({
  className,
  cols = 12,
  rows = 1,
  children
}) => {
  return (
    <div
      className={clsx(
        s.GridItem,
        s[`GridItem_cols_${cols}`],
        s[`GridItem_rows_${rows}`],
        className
      )}
    >
      {children}
    </div>
  );
};

export interface LayoutGridProps {
  className?: string;
  children?: React.ReactNode;
}

export type LayoutGridType = React.FC<LayoutGridProps> & {
  GridItem: typeof GridItem;
};

export const LayoutGrid: LayoutGridType = ({ className, children }) => {
  return <div className={clsx(s.LayoutGrid, className)}>{children}</div>;
};

export const LandingGrid: LayoutGridType = ({ className, children }) => {
  return (
    <LayoutGrid className={clsx(s.LandingGrid, className)}>
      {children}
    </LayoutGrid>
  );
};

LayoutGrid.GridItem = GridItem;
LandingGrid.GridItem = GridItem;
