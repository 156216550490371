import { Routes, RouteType } from '../../common/components/utils/Routes';
import { RequireAuth } from '../RequireAuth';
import NotFoundPage from '../notFound/NotFoundPage';
import { hasPermission, UserPermission } from '../../utils/permissions';
import OffersPage from './list/OffersPage';
import OfferCreatePage from './create/OfferCreatePage';
import OfferPage from './direct/OfferPage';
import OfferEditPage from './edit/OfferEditPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: RequireAuth(OffersPage)
  },
  {
    path: '/create',
    exact: true,
    component: RequireAuth(OfferCreatePage, (user) =>
      hasPermission(user, UserPermission.OfferCrud)
    )
  },
  {
    path: '/:offerId',
    exact: true,
    component: RequireAuth(OfferPage)
  },
  {
    path: '/:offerId/edit',
    exact: true,
    component: RequireAuth(OfferEditPage, (user) =>
      hasPermission(user, UserPermission.OfferCrud)
    )
  },
  {
    component: NotFoundPage
  }
];

const OffersRoutes = Routes(routes);

export default OffersRoutes;
