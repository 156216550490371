import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { Card, CardClasses, CardProps } from '../Card/Card';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import { PointScaleButton } from './PointScaleButton';
import s from './PointScale.module.scss';

export interface PointScaleClasses {
  root?: string;
  card?: CardClasses;
  buttons?: string;
  helperText?: string;
}

export interface PointScaleProps
  extends Omit<
    CardProps,
    'actions' | 'actionsStart' | 'extContent' | 'to' | 'onClick'
  > {
  name: string;
  minValue: number;
  maxValue: number;
  onChange?: (value: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  errorMessage?: string;
  hintMessage?: string;
  classes?: PointScaleClasses;
  className?: string;
}

export const PointScale: React.FC<PointScaleProps> = React.memo(
  function PointScale({
    name,
    minValue,
    maxValue,
    value: valueProp,
    onChange,
    errorMessage,
    hintMessage,
    classes,
    className,
    children,
    ...props
  }) {
    if (minValue > maxValue) {
      throw new Error('minValue cannot be greater than maxValue');
    }

    const [value, setValue] = useState(valueProp);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(+e.target.value);
        onChange?.(+e.target.value, e);
      },
      [onChange]
    );

    const buttons: Array<JSX.Element> = [];
    for (let i = minValue; i <= maxValue; i++) {
      buttons.push(
        <PointScaleButton
          key={i}
          name={name}
          label={i}
          checked={value === i}
          onChange={handleChange}
          className={s.PointScale__button}
        />
      );
    }

    return (
      <Card
        className={clsx(s.PointScale, className, classes?.root)}
        classes={{
          title: clsx(s.PointScale__title, classes?.card?.title),
          ...classes?.card
        }}
        {...props}
      >
        <div className={clsx(s.PointScale__content, classes?.buttons)}>
          {buttons}
        </div>
        <HelpText
          className={classes?.helperText}
          text={errorMessage || hintMessage}
          type={errorMessage ? HelpTextType.error : HelpTextType.hint}
        />
      </Card>
    );
  }
);
