import {
  IUseGraphqlWatchQueryParams,
  useGraphqlWatchQuery
} from '@proscom/prostore-apollo-react';
import { useEffect, useRef } from 'react';
import { useStore } from '@proscom/prostore-react';
import { AuthStore } from '../../../store/AuthStore';
import { STORE_AUTH } from '../../../store/storeKeys';

/**
 * Обёртка над useGraphqlQuery, которая автоматически перевыполняет запрос,
 * если пользователь перелогинивается
 */
export function useAuthGraphqlQuery<Vars, Data>(
  params: IUseGraphqlWatchQueryParams<Vars, Data>
) {
  const [auth] = useStore<AuthStore>(STORE_AUTH);
  const query = useGraphqlWatchQuery(params);
  const queryLoad = query.load;
  const token = auth.authData?.refreshToken;
  const lastTokenRef = useRef(token);

  useEffect(() => {
    if (lastTokenRef.current === null && token) {
      queryLoad().catch(() => {});
    }
    lastTokenRef.current = token;
  }, [token, queryLoad]);

  return query;
}
