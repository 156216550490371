import React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { ReactComponent as IconPlus } from '../../../assets/img/icons/plus.svg';
import { useArticlesPage } from '../../../graphql/hooks/useArticlesPage';
import { useLocationQuery } from '../../../common/hooks/utils/useLocationQuery';
import { URL_KEY_PAGE } from '../../../store/urlKeys';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import {
  getBreadcrumbRouteLink,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { removeTrailingSlash } from '../../../utils/url/url';
import { QueryPagination } from '../../../common/components/ui/QueryPagination/QueryPagination';
import { routeLinks } from '../../routeLinks';
import { UserPermission } from '../../../utils/permissions';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { IconButtonCreate } from '../../../common/components/ui/IconButtonCreate/IconButtonCreate';
import { ArticlesList } from './ArticlesList/ArticlesList';
import { useArticlesFilter } from './useArticlesFilter';
import s from './ArticlesPage.module.scss';

export function getArticlesPageBreadcrumbs(): IBreadcrumbsItem[] {
  return [getBreadcrumbRouteLink(routeLinks.articles)];
}

export default function ArticlesPage({ match }: RouteComponentProps) {
  const [query] = useLocationQuery([URL_KEY_PAGE] as const);
  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.ArticleCrud);

  const [filterComponent, filter] = useArticlesFilter();

  const page = query[URL_KEY_PAGE] || 1;
  const articlesQuery = useArticlesPage({
    filter,
    pagination: { page }
  });
  const articles = articlesQuery.state.data?.list;
  const header = articlesQuery.state.data?.header;
  const hasArticles = articles && articles.length > 0;
  const { isDesktop, isMobile } = useWindowSize();

  return (
    <PageLayout>
      <LayoutContent>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={isDesktop ? 8 : 12}>
            <PageTitle
              className={s.ArticlesPage__titleBlock}
              titleClassName={s.ArticlesPage__title}
              title={routeLinks.articles.text}
              showHeading={true}
              addButton={
                canEdit && isMobile ? (
                  <IconButtonCreate
                    to={`${removeTrailingSlash(match.path)}/create`}
                  />
                ) : null
              }
            >
              {canEdit && !isMobile && (
                <div className={s.ArticlesPage__titleExt}>
                  <ButtonLink
                    to={`${removeTrailingSlash(match.path)}/create`}
                    variant={ButtonVariant.secondary}
                    size={ButtonSize.small}
                    iconLeft={<IconPlus />}
                  >
                    Добавить новость
                  </ButtonLink>
                </div>
              )}
              {!isDesktop && filterComponent}
            </PageTitle>

            {queryLoader(articlesQuery) ||
              (!hasArticles ? (
                <NoData />
              ) : (
                <div className={s.ArticlesPage__list}>
                  <ArticlesList data={articles} isEditable={canEdit} />
                </div>
              ))}
            {(hasArticles || page > 1) && header && (
              <QueryPagination header={header} pathname={'/articles'} />
            )}
          </LayoutGrid.GridItem>
          {isDesktop && (
            <LayoutGrid.GridItem cols={4}>
              {filterComponent}
            </LayoutGrid.GridItem>
          )}
        </LayoutGrid>
      </LayoutContent>
    </PageLayout>
  );
}
