import * as yup from 'yup';
import { FormikValues } from 'formik';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { isBeforeOrEqual } from '@proscom/ui-utils-date';
import { CourseTypesEnum } from '../graphql/types';
import { userFormNames } from './users/UserConstants';

export const REQUIRED_MESSAGE = 'Заполните поле';
export const EMAIL_MESSAGE = 'Некорректный адрес почты';
export const URL_MESSAGE = 'Некорректный адрес';
export const DATE_ORDER_MESSAGE =
  'Конечная дата не может быть раньше начальной';
export const DATE_START_REQUIRED = 'Укажите начальную дату';
export const DATE_END_REQUIRED = 'Укажите конечную дату';
export const PHONE_MESSAGE = 'Некорректный телефонный номер';

export const VACATION_MIN_DAYS_AMOUNT =
  'Количество дней отпуска не может быть меньше {days}';
export const VACATION_MAX_DAYS_AMOUNT = 'Количество дней отпуска превышено';

const urlRegexp = new RegExp(
  // '((https?)://)?(www.)?[a-z0-9-_]+(\\.[a-z-_]{2,}){1,3}(#?\\/?[a-zA-Z0-9-_#]+)*\\/?(\\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-_%]+&?)?$'
  // '((https?)://)?(www.)?[a-z0-9-_]+(\\.[a-z-_]{2,}){1,3}(#?\\/?[a-zA-Z0-9-_#]+)*\\/?(\\?[a-zA-Z0-9-_]+=.+&?)?$'
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*$/
);

const vkUrlRegexp = new RegExp(
  '^(https?://)?(www.)?vk.com/([A-Za-z0-9_\\\\-\\\\.]+)/?'
);
const instUrlRegexp = new RegExp(
  '^(https?://)?(www.)?instagram.com/([A-Za-z0-9_\\\\-\\\\.]+)/?'
);
const fbUrlRegexp = new RegExp(
  '^(https?://)?(www.)?facebook.com/([A-Za-z0-9_\\\\-\\\\.]+)/?'
);

function validatePhone(value, context) {
  const { path, createError } = context;
  if (!value) return true;
  return (
    isValidPhoneNumber(value) || createError({ path, message: PHONE_MESSAGE })
  );
}

export function lazyValidation(cb: (values: FormikValues) => any) {
  return yup.lazy((values) => cb(values));
}

export const urlValidationSchema = yup
  .string()
  .matches(urlRegexp, { message: URL_MESSAGE, excludeEmptyString: true });

export const LoginValidationSchema = yup.object().shape({
  login: yup.string().required(REQUIRED_MESSAGE),
  password: yup.string().required(REQUIRED_MESSAGE)
});

export const NoticesValidationSchema = yup.object().shape({
  title: yup.string().required(REQUIRED_MESSAGE),
  content: yup.string().required(REQUIRED_MESSAGE),
  contact_email: yup.string().email(EMAIL_MESSAGE)
});

export const SystemLinksValidationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_MESSAGE),
  link: yup.string().required(REQUIRED_MESSAGE).url(URL_MESSAGE)
});

export const OffersValidationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_MESSAGE),
  conditions: yup.string().required(REQUIRED_MESSAGE),
  description: yup.string().required(REQUIRED_MESSAGE),
  snippet: yup.string().required(REQUIRED_MESSAGE),
  category_id: yup.string().required(REQUIRED_MESSAGE),
  kind_id: yup.string().required(REQUIRED_MESSAGE),
  link: yup.string().required(REQUIRED_MESSAGE).url(URL_MESSAGE)
});

export const PersonnelReserveValidationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_MESSAGE),
  description: yup.string().required(REQUIRED_MESSAGE),
  snippet: yup.string().required(REQUIRED_MESSAGE)
});

export const UserFormValidationSchema = yup.object().shape({
  [userFormNames.vk]: yup
    .string()
    .matches(vkUrlRegexp, { message: URL_MESSAGE, excludeEmptyString: false }),
  // [userFormNames.instagram]: yup.string().matches(instUrlRegexp, {
  //   message: URL_MESSAGE,
  //   excludeEmptyString: false
  // }),
  // [userFormNames.facebook]: yup
  //   .string()
  //   .matches(fbUrlRegexp, { message: URL_MESSAGE, excludeEmptyString: false }),
  [userFormNames.work_experiences]: yup.array().of(
    yup.object().shape({
      duties: yup.string().required(REQUIRED_MESSAGE),
      place: yup.string().required(REQUIRED_MESSAGE),
      position: yup.string().required(REQUIRED_MESSAGE),
      year_start: yup.date().required(REQUIRED_MESSAGE),
      year_end: yup
        .date()
        .when(
          'year_start',
          (year_start, schema) =>
            year_start && schema.min(year_start, DATE_ORDER_MESSAGE)
        )
    })
  )
});

export const ExternalPollsValidationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_MESSAGE),
  link: yup.string().required(REQUIRED_MESSAGE).trim().url(URL_MESSAGE)
});

export const EventsValidationSchema = yup.object().shape({
  title: yup.string().required(REQUIRED_MESSAGE),
  date_start: yup
    .date()
    .required(REQUIRED_MESSAGE)
    .nullable()
    .default(undefined),
  date_end: yup
    .date()
    .nullable()
    .when('date_start', (date_start, schema) => {
      if (date_start) return schema.min(date_start, DATE_ORDER_MESSAGE);
      return schema;
    })
});

export const ProjectsValidationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_MESSAGE),
  participants: yup.array().of(
    yup.object().shape({
      user_id: yup.string().required(REQUIRED_MESSAGE)
    })
  )
});
export const ProjectsAdminValidationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_MESSAGE),
  head_user_id: yup.string().required(REQUIRED_MESSAGE),
  participants: yup.array().of(
    yup.object().shape({
      user_id: yup.string().required(REQUIRED_MESSAGE)
    })
  )
});

export const InternshipsValidationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_MESSAGE),
  description: yup.string().required(REQUIRED_MESSAGE),
  conditions: yup.string().required(REQUIRED_MESSAGE),
  link: yup.string().url(URL_MESSAGE)
});

export const CoursesPublicValidationSchema = yup.object().shape({
  // common fields
  type: yup.string(),
  name: yup.string().required(REQUIRED_MESSAGE),
  course_subject_id: yup
    .string()
    .typeError('Выберите тему курса')
    .required('Выберите тему курса'),
  cover_file_id: yup
    .string()
    .typeError('Загрузите обложку')
    .required('Загрузите обложку'),
  description: yup.string().required(REQUIRED_MESSAGE),
  tags: yup.array().min(1, 'Выберите теги').required('Выберите теги'),
  price: yup.number().nullable().min(1, 'Введите корректную цену'),
  discount_price: yup
    .number()
    .nullable()
    .min(1, 'Введите корректную цену')
    .lessThan(
      yup.ref('price'),
      'Цена со скидкой должна быть меньше первоначальной цены'
    ),
  learning_format: yup.string().nullable(),
  completion_proof: yup.string().nullable(),
  listener_category: yup.string().nullable(),
  // longread specific fields
  content: yup.array().when('type', {
    is: CourseTypesEnum.Longread,
    then: yup
      .array()
      .of(yup.object())
      .min(1, 'Добавьте содержимое')
      .required('Добавьте содержимое'),
    otherwise: yup.array().of(yup.object()).optional().nullable()
  }),
  // announce specific fields
  acquired_knowledge: yup.string().when('type', {
    is: CourseTypesEnum.Announce,
    then: yup.string().required('Введите приобретаемые навыки'),
    otherwise: yup.string().optional().nullable()
  }),
  course_link: yup
    .string()
    .url(URL_MESSAGE)
    .when('type', {
      is: CourseTypesEnum.Announce,
      then: yup.string().url(URL_MESSAGE).required('Добавьте ссылку на курс'),
      otherwise: yup.string().url(URL_MESSAGE).optional().nullable()
    })
  // teachers: yup
  //   .array()
  //   .of(yup.object())
  //   .when('type', {
  //     is: CourseTypesEnum.Announce,
  //     then: yup
  //       .array()
  //       .of(yup.object())
  //       .min(1, 'Добавьте учителей курса')
  //       .required('Добавьте учителей курса'),
  //     otherwise: yup.array().of(yup.object()).optional()
  //   })
});

export const CoursesDraftValidationSchema = yup.object().shape({
  type: yup.string(),
  name: yup.string().required(REQUIRED_MESSAGE)
});

export const FeedbackValidationSchema = yup.object().shape({
  phone: yup.string().test(validatePhone),
  topic: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .max(128, 'Не более 128 символов'),
  message: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .max(512, 'Не более 512 символов')
});

export const VacationFormValidationSchema = yup.object().shape({
  position_id: yup.string().required(REQUIRED_MESSAGE),
  deputy_id: yup.string().when('isHeadPosition', (isHeadPosition, schema) => {
    return isHeadPosition ? schema.required(REQUIRED_MESSAGE) : schema;
  }),
  deputy_position_id: yup
    .string()
    .when('isHeadPosition', (isHeadPosition, schema) => {
      return isHeadPosition ? schema.required(REQUIRED_MESSAGE) : schema;
    }),
  start: yup.date().required(REQUIRED_MESSAGE),
  end: yup
    .date()
    .when('start', (date_start, schema) => {
      if (date_start) {
        return schema.test('date-order', DATE_ORDER_MESSAGE, (value) => {
          return value ? isBeforeOrEqual(date_start, value) : true;
        });
      }
      return schema;
    })
    .required(REQUIRED_MESSAGE),
  selectedDaysAmount: yup
    .number()
    .nullable()
    .when('availableDaysAmount', (availableDaysAmount, schema) => {
      if (availableDaysAmount) {
        return schema.max(availableDaysAmount, VACATION_MAX_DAYS_AMOUNT);
      } else return schema;
    })
});

export const VacationSchedulesFormValidationSchema = yup.object().shape({
  position_id: yup.string().required(REQUIRED_MESSAGE),
  vacations: yup.array().of(
    yup.object({
      periods: yup.array().of(
        yup.object({
          start: yup.date().required(REQUIRED_MESSAGE),
          end: yup
            .date()
            .when('start', (date_start, schema) => {
              if (date_start) {
                return schema.test(
                  'date-order',
                  DATE_ORDER_MESSAGE,
                  (value) => {
                    return value ? isBeforeOrEqual(date_start, value) : true;
                  }
                );
              }
              return schema;
            })
            .required(REQUIRED_MESSAGE)
        })
      )
    })
  )
});
