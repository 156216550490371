import React, { ReactNode } from 'react';

export enum ButtonBaseColor {
  blue = 'blue',
  red = 'red',
  green = 'green',
  gray = 'gray'
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary'
}

export interface ButtonClasses {
  root?: string;
  iconLeft?: string;
  iconRight?: string;
  content?: string;
}

export interface BaseButtonBaseProps {
  className: string;
  children: React.ReactNode;
}

export interface BaseButtonProps {
  className?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  baseColor?: ButtonBaseColor;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  classes?: ButtonClasses;
  loading?: boolean;
  children?: React.ReactNode;
  renderBase: (props: BaseButtonBaseProps) => JSX.Element;
}

export const ButtonLoaderSize = {
  [ButtonSize.small]: 20,
  [ButtonSize.medium]: 24,
  [ButtonSize.large]: 28
};
