import { omit, pick } from 'lodash-es';
import React from 'react';

export type IntrinsicPropsWithoutRef<
  E extends keyof JSX.IntrinsicElements
> = React.PropsWithoutRef<JSX.IntrinsicElements[E]>;

export type AnyProps = { [key: string]: any };

export type AnchorPropsWithoutRef = IntrinsicPropsWithoutRef<'a'>;
export type DivPropsWithoutRef = IntrinsicPropsWithoutRef<'div'>;
export type SpanPropsWithoutRef = IntrinsicPropsWithoutRef<'span'>;
export type FooterPropsWithoutRef = IntrinsicPropsWithoutRef<'footer'>;
export type ImgPropsWithoutRef = IntrinsicPropsWithoutRef<'img'>;
export type ButtonPropsWithoutRef = IntrinsicPropsWithoutRef<'button'>;
export type InputPropsWithoutRef = IntrinsicPropsWithoutRef<'input'>;
export type SelectPropsWithoutRef = IntrinsicPropsWithoutRef<'select'>;
export type UListPropsWithoutRef = IntrinsicPropsWithoutRef<'ul'>;
export type LiPropsWithoutRef = IntrinsicPropsWithoutRef<'li'>;
export type SvgPropsWithoutRef = IntrinsicPropsWithoutRef<'svg'>;
export type FormPropsWithoutRef = IntrinsicPropsWithoutRef<'form'>;

export type StringKeys<Object extends object> = {
  [key in keyof Object]-?: key extends string ? key : never;
}[keyof Object];

export function addStaticProperties<T, P extends object>(obj: T, props: P) {
  for (const key of Object.keys(props)) {
    obj[key] = props[key];
  }
  return obj as T & P;
}

export function propsSplitter<T extends readonly string[]>(keys: T) {
  return function splitProps<T2 extends AnyProps>(props: T2) {
    return [
      pick(props, keys) as Pick<T2, T[number]>,
      omit(props, keys) as Omit<T2, T[number]>
    ] as const;
  };
}

export type PropsSplitter<Key extends string> = <P extends AnyProps>(
  props: P
) => readonly [Pick<P, Key>, Omit<P, Key>];

export function getComponentName(component: React.ElementType) {
  if (typeof component === 'string') {
    return component;
  }
  return component.displayName || component.name || 'Component';
}
