import { MutationOptions } from 'apollo-client';
import { clearCache } from '../../graphql/queriesUtils';
import {
  MUTATION_CREATE_INTERNSHIP,
  MUTATION_CREATE_INTERNSHIP_TEST_RESULT,
  MUTATION_DELETE_INTERNSHIP,
  MUTATION_UPDATE_INTERNSHIP
} from '../../graphql/mutations/internships';
import {
  InternshipCreateInput,
  InternshipType,
  InternshipUpdateInput
} from '../../graphql/types';

export class Internships {
  private static refetchQueries = ['queryGetInternships', 'queryGetInternship'];
  private static updateQueries = ['internshipsPage', 'internship'];
  private static updateFragments = ['InternshipType', 'InternshipsPageType'];

  public static createInternship: MutationOptions<
    {
      result: InternshipType;
    },
    {
      input: InternshipCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_INTERNSHIP,
    refetchQueries: Internships.refetchQueries,
    update: clearCache(Internships.updateQueries, Internships.updateFragments)
  };

  public static updateInternship: MutationOptions<
    {
      result: InternshipType;
    },
    {
      input: InternshipUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_INTERNSHIP,
    refetchQueries: Internships.refetchQueries,
    update: clearCache(Internships.updateQueries, Internships.updateFragments)
  };

  public static deleteInternship: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_DELETE_INTERNSHIP,
    refetchQueries: Internships.refetchQueries,
    update: clearCache(Internships.updateQueries, Internships.updateFragments)
  };

  public static createInternshipTestResult: MutationOptions<
    { result: boolean },
    { input: string }
  > = {
    mutation: MUTATION_CREATE_INTERNSHIP_TEST_RESULT,
    refetchQueries: ['queryGetInternshipTestResult'],
    update: clearCache(['internshipTestResult'])
  };
}
