import gql from 'graphql-tag';
import { FRAGMENT_MANUAL } from '../fragments/manuals';

export const QUERY_GET_MANUALS = gql`
  query queryGetManuals {
    manuals {
      ...ManualProps
    }
  }

  ${FRAGMENT_MANUAL}
`;
