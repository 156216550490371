import { IUseGraphqlQueryOptions } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { ArticleType } from '../types';
import { QUERY_GET_ARTICLE } from '../queries/articles';
import { useAuthGraphqlQuery } from '../../common/hooks/utils/useAuthGraphqlQuery';

export interface UseArticleVars {
  id: string;
}

const queryOptions: IUseGraphqlQueryOptions<
  UseArticleVars | null,
  ArticleType | null
> = {
  query: QUERY_GET_ARTICLE,
  mapData: (result) => result.article,
  skipQuery: skipIfNull(null)
};

export function useArticle(id?: string) {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: id ? { id } : null
  });
}
