import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import {
  Breadcrumbs,
  IBreadcrumbsEntity
} from '../../../common/components/ui/Breadcrumbs';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { ExternalPollsValidationSchema } from '../../../store/validationSchema';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  ExternalPollFormValues,
  ExternalPollsUtils
} from '../../../store/polls/ExternalPollsUtils';
import { BasePageInterface } from '../../../common/components/utils/BasePageInterface';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { tryAppendErrorId } from '../../../graphql/graphqlErrors';
import { getPollsPageBreadcrumbs } from '../list/PollsPage';
import { ExternalPolls } from '../../../store/polls/ExternalPolls';
import { ExternalPollForm } from '../forms/ExternalPollForm';
import { useExternalPoll } from '../../../graphql/hooks/polls/useExternalPoll';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { URL_KEY_TAB } from '../../../store/urlKeys';
import { routeLinks } from '../../routeLinks';

export function getPollEditPageBreadcrumbs({
  poll
}: {
  poll: IBreadcrumbsEntity;
}) {
  return [
    ...getPollsPageBreadcrumbs(),
    {
      name: 'Редактирование',
      path: `/polls/external/${poll.id}/edit`
    }
  ];
}

export default function PollEditPage({
  history,
  match
}: BasePageInterface<{ externalPollId: string }>) {
  const { externalPollId } = match.params;
  const externalPollQuery = useExternalPoll(externalPollId);
  const externalPoll = externalPollQuery.state.data;

  const updateOp = useGraphqlMutation(ExternalPolls.UpdateExternalPoll);
  const updateOpRun = updateOp.run;
  const handleSubmitForm = useCallback(
    (values: ExternalPollFormValues) => {
      const input = ExternalPollsUtils.fromUpdateForm(values);
      updateOpRun({ variables: { input } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          history.push(`/polls?${URL_KEY_TAB}=external`);
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            tryAppendErrorId(
              'Произошла ошибка при изменении опроса. Попробуйте снова',
              err
            )
          );
        });
    },
    [updateOpRun, history]
  );

  const breadcrumbs = useMemo(
    () =>
      getPollEditPageBreadcrumbs({
        poll: { id: externalPollId, name: externalPoll?.name }
      }),
    [externalPoll, externalPollId]
  );
  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        {queryLoader(externalPollQuery) ||
          (externalPoll && (
            <>
              <PageTitle
                title={[externalPoll.name, routeLinks.polls.text]}
                showHeading
              />
              <Formik
                validationSchema={ExternalPollsValidationSchema}
                initialValues={ExternalPollsUtils.toForm(externalPoll)}
                onSubmit={handleSubmitForm}
              >
                {(props) => (
                  <ExternalPollForm
                    {...props}
                    history={history}
                    submissionLoading={updateOp.loading}
                  />
                )}
              </Formik>
            </>
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
