import React from 'react';
import { FigureType, FigureVariant } from '../../../Figures/types';
import { CardPattern } from '../CardPattern/CardPattern';
import s from './TrianglesPattern.module.scss';

export const TrianglesPattern = React.memo(function TrianglesPattern() {
  return (
    <CardPattern
      className={s.TrianglesPattern}
      figures={[
        {
          className: s.TrianglesPattern_front,
          type: FigureType.triangle,
          variant: FigureVariant.light,
          size: 186
        },
        {
          className: s.TrianglesPattern_back,
          type: FigureType.triangle,
          variant: FigureVariant.dark,
          size: 186
        }
      ]}
    />
  );
});
