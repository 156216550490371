import React from 'react';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { ReactComponent as IconSearch } from '../../../assets/img/enrollee/simpleCards/i-search.svg';
import { ReactComponent as IconExt } from '../../../assets/img/enrollee/simpleCards/i-ext.svg';
import { ReactComponent as IconHat } from '../../../assets/img/enrollee/simpleCards/i-hat.svg';
import { ReactComponent as IconWand } from '../../../assets/img/enrollee/simpleCards/i-wand.svg';
import { Icon, IconProps } from '../../../common/components/ui/Icon/Icon';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import s from './Target.module.scss';

const items: { icon: IconProps['icon']; text: React.ReactNode }[] = [
  {
    icon: IconSearch,
    text: (
      <>
        Тщательный отбор одаренных и склонных <br />
        к творческой работе представителей <br />
        молодёжи.
      </>
    )
  },
  {
    icon: IconExt,
    text: (
      <>
        Индивидуальный подход к отдельным <br />
        студентам с целью развития их <br />
        творческих задатков.
      </>
    )
  },
  {
    icon: IconHat,
    text: (
      <>
        Участие в обучении ведущих научных <br />
        работников и тесном контакте с ними в их <br />
        творческой обстановке.
      </>
    )
  },
  {
    icon: IconWand,
    text: (
      <>
        Ведение воспитания с первых же шагов <br />
        в атмосфере технических исследований <br />
        и конструктивного творчества с использованием <br />
        для этого лучших лабораторий страны.
      </>
    )
  }
];

export const Target = () => {
  return (
    <LayoutContent className={s.Target}>
      <div className={s.Target__mission}>
        <LayoutGrid className={s.Mission__grid}>
          <LayoutGrid.GridItem cols={6}>
            <div className={s.Mission__column}>
              <Title variant={TitleVariant.h2} className={s.Mission__title}>
                Главная ценность Физтеха
              </Title>
              <div className={s.Mission__text}>
                Это физтехи. Образование направлено на воспитание в творческой и
                креативной атмосфере специалистов, которые решают уникальные
                задачи, как самостоятельно, так и став лидерами создаваемых ими
                команд. Особую ценность выпускникам Физтеха придает способность
                решать задачи из любой области, используя научный подход.
              </div>
            </div>
          </LayoutGrid.GridItem>
          <LayoutGrid.GridItem cols={6}>
            <div className={s.Mission__column}>
              <Title variant={TitleVariant.h2} className={s.Mission__title}>
                Стратегическая цель
              </Title>
              <div className={s.Enrollee__text}>
                Занять позицию одного из мировых лидеров в исследованиях и
                разработках и их коммерциализации по важнейшим для страны
                приоритетам научно-технологического развития. Достичь лидирующих
                позиций в ключевых сегментах мировой науки, технологий и
                образования, и обеспечить подготовку кадров мирового уровня для
                ведущих российских компаний.
              </div>
            </div>
          </LayoutGrid.GridItem>
        </LayoutGrid>
      </div>

      <div className={s.Target__simpleCards}>
        <LayoutGrid className={s.SimpleCards__grid}>
          {items.map((item, iItem) => (
            <LayoutGrid.GridItem key={iItem} cols={6}>
              <div className={s.SimpleCard}>
                <Icon
                  className={s.SimpleCard__icon}
                  icon={item.icon}
                  boxSize={'auto'}
                  iconSize={40}
                />
                <div className={s.SimpleCard__content}>{item.text}</div>
              </div>
            </LayoutGrid.GridItem>
          ))}
        </LayoutGrid>
      </div>
    </LayoutContent>
  );
};
