import { MutationOptions } from 'apollo-client';
import {
  ProjectCreateInput,
  ProjectType,
  ProjectUpdateInput
} from '../../graphql/types';
import {
  MUTATION_CREATE_PROJECT,
  MUTATION_DELETE_PROJECT,
  MUTATION_QUIT_PROJECT,
  MUTATION_SEND_PROJECT_APPLICATION,
  MUTATION_UPDATE_PROJECT
} from '../../graphql/mutations/projects';
import { clearCache } from '../../graphql/queriesUtils';

export class Projects {
  private static refetchQueries = ['queryGetProjects', 'queryGetProject'];
  private static updateQueries = ['projectsPage', 'project'];
  private static updateFragments = ['ProjectType', 'ProjectsPageType'];

  public static createProject: MutationOptions<
    {
      result: ProjectType;
    },
    {
      input: ProjectCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_PROJECT,
    refetchQueries: Projects.refetchQueries,
    update: clearCache(Projects.updateQueries, Projects.updateFragments)
  };

  public static updateProject: MutationOptions<
    {
      result: ProjectType;
    },
    {
      input: ProjectUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_PROJECT,
    refetchQueries: Projects.refetchQueries,
    update: clearCache(Projects.updateQueries, Projects.updateFragments)
  };

  public static deleteProject: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_DELETE_PROJECT,
    refetchQueries: Projects.refetchQueries,
    update: clearCache(Projects.updateQueries, Projects.updateFragments)
  };

  public static sendProjectApplication: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_SEND_PROJECT_APPLICATION,
    refetchQueries: Projects.refetchQueries,
    update: clearCache(Projects.updateQueries, Projects.updateFragments)
  };

  public static quitProject: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_QUIT_PROJECT,
    refetchQueries: Projects.refetchQueries,
    update: clearCache(Projects.updateQueries, Projects.updateFragments)
  };
}
