import { Routes, RouteType } from '../../common/components/utils/Routes';
import { RequireAuth } from '../RequireAuth';
import NotFoundPage from '../notFound/NotFoundPage';
import { SettingsPage } from './index/SettingsPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: RequireAuth(SettingsPage)
  },
  {
    component: NotFoundPage
  }
];

const SettingsRoutes = Routes(routes);

export default SettingsRoutes;
