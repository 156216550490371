import { SelectOptionProps } from '../../common/components/ui/Select/Select';

export function convertDataListToSelectOptions(
  data: any[] | null,
  options: {
    valueKey: string;
    labelKey: string | string[];
    delimiter?: string;
    isStringValue?: boolean;
  }
): SelectOptionProps[] {
  const { valueKey, labelKey, delimiter = ' ', isStringValue = true } = options;

  return (data || []).map((item) => {
    return {
      value: isStringValue ? item[valueKey]?.toString() : item[valueKey],
      label: Array.isArray(labelKey)
        ? labelKey
            .filter((key) => !!item[key])
            .map((key) => item[key])
            .join(delimiter)
        : item[labelKey]
    };
  });
}
