import { DocumentNode } from 'graphql';
import { EMDASH } from '@proscom/ui-utils';
import {
  QUERY_GET_AREAS_OF_INTEREST_OPTIONS,
  QUERY_GET_COURSE_SUBJECTS_OPTIONS,
  QUERY_GET_INTERNSHIP_SUBJECTS_OPTIONS,
  QUERY_GET_TAGS_OPTIONS,
  QUERY_GET_OFFER_CATEGORIES_PAGE_OPTIONS,
  QUERY_GET_OFFER_KINDS_PAGE_OPTIONS,
  QUERY_GET_SKILLS_OPTIONS,
  QUERY_GET_UNITS_OPTIONS,
  QUERY_GET_USERS_OPTIONS
} from '../../../../../graphql/queries/entitiesOptionsQueries';
import { SelectOptionProps } from '../../Select/Select';
import { DefaultPaginationParams } from '../../../../../constants';
import { handleDefaultError } from '../../../../../utils/handleDefaultError';
import { UserUtils } from '../../../../../store/users/UserUtils';
import { UnitUtils } from '../../../../../store/units/UnitUtils';
import { QUERY_GET_ROLES } from '../../../../../graphql/queries/roles';
import { QUERY_GET_POLLS_PAGE } from '../../../../../graphql/queries/polls';
import {
  Entity,
  GetAsyncEntityLoaderArgs,
  LoadQueryBase,
  LoadQueryOptions
} from './types';

export const getName = (entity: any): string => {
  // Сюда дописывать (по приоритету) поля потенциально содержащие название лейбла
  return (entity && (entity.title || UserUtils.getName(entity))) || EMDASH;
};

const mapDataToOptions = (getName: (entity: any) => string) => (
  result: any
): SelectOptionProps[] =>
  result.data.items.list.map((item) => ({
    ...item,
    label: getName(item),
    value: item.id
  }));

const loadQueryBase: LoadQueryBase = (
  query,
  mapData,
  client,
  filter,
  pagination
) =>
  client
    .query({
      query,
      variables: {
        input: {
          filter,
          pagination: {
            ...DefaultPaginationParams,
            ...pagination
          }
        },
        fetchPolicy: 'network-only'
      }
    })
    .then((result) => mapData(result))
    .catch((err) => {
      handleDefaultError(err, 'Произошла ошибка при загрузке значений');
    });

const loadOptionsSearch = <F>({
  query,
  mapData,
  client,
  filter,
  pagination
}: LoadQueryOptions<F>) => (search: string) =>
  loadQueryBase(query, mapData, client, { search, ...filter }, pagination);

const loadOptionsIds = <F>({
  query,
  mapData,
  client,
  pagination
}: LoadQueryOptions<F>) => (id: string | string[]) =>
  loadQueryBase(
    query,
    mapData,
    client,
    { ids: Array.isArray(id) ? id : [id] },
    pagination
  );

const loadQuery = <F>(args: LoadQueryOptions<F>) =>
  [loadOptionsSearch(args), loadOptionsIds(args)] as const;

export function getAsyncEntityLoader<T extends Entity>({
  entity,
  filter,
  client,
  pagination,
  mapDataFn = mapDataToOptions(getName)
}: GetAsyncEntityLoaderArgs<T>) {
  const createLoadOptions = (
    query: DocumentNode,
    mapData: (result: any) => SelectOptionProps[]
  ) =>
    loadQuery({
      query,
      mapData,
      filter,
      client,
      pagination
    });

  /*
   * Тип переменной input любой квери, что пихается сюда, должен удовлетворять интерфейсу:
   * { filter: { search: string, ids: string[] } }
   * Иначе работать не будет
   * */
  switch (entity) {
    case Entity.offerCategoriesPage:
      return createLoadOptions(
        QUERY_GET_OFFER_CATEGORIES_PAGE_OPTIONS,
        mapDataFn
      );
    case Entity.offerKindsPage:
      return createLoadOptions(QUERY_GET_OFFER_KINDS_PAGE_OPTIONS, mapDataFn);
    case Entity.skillsPage:
      return createLoadOptions(QUERY_GET_SKILLS_OPTIONS, mapDataFn);
    case Entity.areasOfInterestPage:
      return createLoadOptions(QUERY_GET_AREAS_OF_INTEREST_OPTIONS, mapDataFn);
    case Entity.usersPage:
      return createLoadOptions(
        QUERY_GET_USERS_OPTIONS,
        mapDataToOptions((u) => UserUtils.getName(u))
      );
    case Entity.unitsPage:
      return createLoadOptions(
        QUERY_GET_UNITS_OPTIONS,
        mapDataToOptions((u) => UnitUtils.getName(u))
      );
    case Entity.courseSubjectsPage:
      return createLoadOptions(
        QUERY_GET_COURSE_SUBJECTS_OPTIONS,
        mapDataToOptions((t) => t.name)
      );
    case Entity.internshipSubjectsPage:
      return createLoadOptions(
        QUERY_GET_INTERNSHIP_SUBJECTS_OPTIONS,
        mapDataFn
      );
    case Entity.tagsPage:
      return createLoadOptions(
        QUERY_GET_TAGS_OPTIONS,
        mapDataToOptions((t) => t.name)
      );
    case Entity.pollsPage:
      return createLoadOptions(QUERY_GET_POLLS_PAGE, (e) =>
        e.data.pollsPage.list.map((x) => ({
          label: x.name,
          value: x.id
        }))
      );
    case Entity.rolesPage:
      return createLoadOptions(QUERY_GET_ROLES, (e) => {
        return e.data.rolesPage.list.map((x) => ({
          label: x.name,
          value: x.id
        }));
      });
    default:
      return [undefined, undefined];
  }
}
