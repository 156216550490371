import { Routes, RouteType } from '../../common/components/utils/Routes';
import { RequireAuth } from '../RequireAuth';
import NotFoundPage from '../notFound/NotFoundPage';
import { hasPermission, UserPermission } from '../../utils/permissions';
import ProjectsPage from './list/ProjectsPage';
import ProjectPage from './index/ProjectPage';
import ProjectEditPage from './edit/ProjectEditPage';
import ProjectCreatePage from './create/ProjectCreatePage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: RequireAuth(ProjectsPage)
  },
  {
    path: '/create',
    exact: true,
    component: RequireAuth(ProjectCreatePage)
  },
  {
    path: '/:projectId',
    exact: true,
    component: RequireAuth(ProjectPage)
  },
  {
    path: '/:projectId/edit',
    exact: true,
    component: RequireAuth(ProjectEditPage, (user, props) => {
      return (
        hasPermission(user, UserPermission.ProjectCrud) ||
        hasPermission(user, UserPermission.ProjectCrudHead)
      );
    })
  },
  {
    component: NotFoundPage
  }
];

const ProjectsRoutes = Routes(routes);

export default ProjectsRoutes;
