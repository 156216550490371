import React, { useCallback, useEffect, useState } from 'react';
import { OptionTypeBase } from 'react-select/src/types';
import { AsyncSelect, AsyncSelectProps } from '../AsyncSelect';
import { handleDefaultError } from '../../../../../utils/handleDefaultError';
import { Entity, EntityPageInput } from './types';
import { useAsyncEntityOptions } from './useAsyncEntityOptions';

export type AsyncEntitiesSelectSelfProps = {
  value?: string[] | null;
};

export type AsyncEntitiesSelectProps<
  T extends Entity
> = AsyncEntitiesSelectSelfProps &
  Omit<AsyncSelectProps<true, false>, 'value' | 'isMulti' | 'isCreatable'> &
  EntityPageInput<T>;

export function AsyncEntitiesSelect<T extends Entity>({
  entity,
  value: valueProp,
  filter,
  pagination,
  onChange,
  ...props
}: AsyncEntitiesSelectProps<T>) {
  const [value, setValue] = useState<
    readonly OptionTypeBase[] | null | undefined
  >(null);

  const [loadOptions, loadByIds] = useAsyncEntityOptions(
    entity,
    filter,
    pagination
  );

  useEffect(() => {
    let active = true;
    if (valueProp) {
      loadByIds?.(valueProp)
        .then((result) => {
          if (!active) return;
          if (!result) throw new Error('Неожиданный результат');
          setValue(result);
        })
        .catch((err) => {
          if (!active) return;
          handleDefaultError(err, 'Произошла ошибка при получении данных');
        });
    } else {
      setValue(valueProp);
    }

    return () => {
      active = false;
    };
  }, [valueProp, loadByIds]);

  const handleChange = useCallback(
    (items) => {
      onChange(items?.map((i) => i.value));
    },
    [onChange]
  );

  return (
    <AsyncSelect
      value={value}
      loadOptions={loadOptions}
      defaultOptions
      cacheOptions
      isMulti
      onChange={handleChange}
      {...props}
    />
  );
}
