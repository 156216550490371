import { convertToUtcStr, getDateUtc } from '../../../../utils/date/date';

export const yearToDate = (value?: string | number | string[] | number[]) => {
  if (!value) return undefined;

  if (Array.isArray(value)) {
    return value.map(yearToDate);
  } else {
    const dateStr = value && convertToUtcStr(`${value}-01-01`);
    return dateStr && new Date(getDateUtc(dateStr));
  }
};

export const dateToYear = (
  value?: Date | Date[] | number | number[] | null
) => {
  if (!value) return undefined;

  if (Array.isArray(value)) {
    return value.map(dateToYear);
  } else {
    const dateStr = value && convertToUtcStr(value);
    return dateStr ? new Date(dateStr).getFullYear() : undefined;
  }
};
