import React, { useCallback } from 'react';
import { renderSlot, Slot } from '@proscom/ui-react';
import clsx from 'clsx';
import { useLocationQuery } from '../../../hooks/utils/useLocationQuery';
import { URL_KEY_TAB } from '../../../../store/urlKeys';
import { TabClasses } from './Tab/Tab';
import { TabsData, TabsList } from './TabsList/TabsList';
import s from './Tabs.module.scss';

export interface TabsClasses {
  root?: string;
  tab?: TabClasses;
  buttons?: string;
  body?: string;
}

export interface TabsProps {
  tabs: TabsData[];
  children: Slot<{ id: string }>;
  onChange?: (id: string) => void;
  defaultTab?: string;
  classes?: TabsClasses;
  className?: string;
}

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  onChange,
  defaultTab,
  className,
  children,
  classes
}) => {
  const [query, locationStore] = useLocationQuery([URL_KEY_TAB] as const);
  const curTab = query[URL_KEY_TAB] || defaultTab || tabs[0].id;

  const changeTabHandle = useCallback(
    (id: string) => {
      locationStore.changeQuery({ [URL_KEY_TAB]: id });
      onChange?.(id);
    },
    [locationStore, onChange]
  );

  return (
    <div className={clsx(s.Tabs, className, classes?.root)}>
      <TabsList
        tabs={tabs}
        curTab={curTab}
        onChange={changeTabHandle}
        classes={{
          root: classes?.buttons,
          tab: classes?.tab
        }}
      />
      <div className={clsx(s.Tabs__body, classes?.body)}>
        {renderSlot(children, { id: curTab })}
      </div>
    </div>
  );
};
