import gql from 'graphql-tag';
import { FRAGMENT_PAGINATION_HEADER } from '../fragments/common';
import { FRAGMENT_USER_FULL, FRAGMENT_USER_SNIPPET } from '../fragments/users';

export const QUERY_GET_USERS = gql`
  query queryGetUsers($input: UserPageInput) {
    usersPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        ...UserSnippet
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
  ${FRAGMENT_USER_SNIPPET}
`;

export const QUERY_GET_USER = gql`
  query queryGetUser($id: ID!) {
    user(id: $id) {
      ...UserFull
    }
  }

  ${FRAGMENT_USER_FULL}
`;
