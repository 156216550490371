import { tryNumber } from '@proscom/ui-utils';
import {
  SystemLinkCreateInput,
  SystemLinkType,
  SystemLinkUpdateInput
} from '../../graphql/types';

export interface SystemLinkFormValues {
  id?: string;
  name: string;
  link: string;
  priority: number | '';
}

export class SystemLinksUtils {
  static toForm(data?: SystemLinkType): SystemLinkFormValues {
    return {
      id: data?.id,
      name: data?.name || '',
      link: data?.link || '',
      priority: data?.priority ?? ''
    };
  }

  static fromCreateForm(formData: SystemLinkFormValues): SystemLinkCreateInput {
    return {
      ...formData,
      priority: tryNumber(formData.priority)
    };
  }

  static fromUpdateForm(formData: SystemLinkFormValues): SystemLinkUpdateInput {
    return {
      id: formData.id!,
      ...SystemLinksUtils.fromCreateForm(formData)
    };
  }
}
