import React, { useCallback, useState } from 'react';
import { Button, ButtonSize, ButtonVariant } from '../../Button';
import { Dropdown } from '../../Dropdown/Dropdown';
import { BlockAddMenu, BlockAddMenuProps } from './BlockAddMenu';

export interface BlockAddActionProps extends BlockAddMenuProps {}

export const BlockActionAdd: React.FC<BlockAddActionProps> = ({
  onBlockSelect,
  items
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleBlockSelect = useCallback(
    (props) => {
      setOpen(false);
      onBlockSelect(props);
    },
    [onBlockSelect]
  );

  const handleButtonClick = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  return (
    <Dropdown
      isOpen={isOpen}
      onToggle={setOpen}
      content={<BlockAddMenu onBlockSelect={handleBlockSelect} items={items} />}
    >
      <Button
        size={ButtonSize.small}
        variant={ButtonVariant.secondary}
        onClick={handleButtonClick}
      >
        Добавить блок
      </Button>
    </Dropdown>
  );
};
