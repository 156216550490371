import { IQueryTransformer } from '@proscom/prostore-react-router';
import { formatDateIso, tryParseIso } from '@proscom/ui-utils-date';

export const endDateTransformer: IQueryTransformer<string | undefined> = {
  parse(value) {
    if (value && typeof value === 'string') {
      if (tryParseIso(value)) {
        return value + 'T23:59:59Z';
      }
    }
    return undefined;
  },
  stringify(value: string | undefined) {
    return value ? formatDateIso(new Date(value)) : undefined;
  }
};
