import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { TitleVariant } from '../Title/Title';
import { ReactComponent as AddIcon } from '../../../../assets/img/icons/plus.svg';
import { Button, ButtonSize, ButtonVariant } from '../Button';
import { DocumentSectionType } from '../../../../graphql/types';
import { useGraphqlMutation } from '../../../hooks/utils/useGraphqlMutation';
import { LoadedFile } from '../FileBox/FileBox';
import { Documents } from '../../../../store/documents/Documents';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { EmptySection } from './EmptySection/EmptySection';
import { Document } from './Document/Document';
import { DocumentSectionTitle } from './DocumentSectionTitle/DocumentSectionTitle';
import { DocumentEditSubmitValuesType } from './Document/DocumentEdit';
import { DocumentUpload } from './Document/DocumentUpload';
import s from './DocumentSection.module.scss';

export interface DocumentSectionProps {
  data: DocumentSectionType;
  editable?: boolean;
}

export const DocumentSection: React.FC<DocumentSectionProps> = ({
  data,
  editable
}) => {
  const [uploadFileIsOpen, setUploadFileIsOpen] = useState(false);
  const { name, documents, id } = data;

  const handleOpenUploadField = () => {
    setUploadFileIsOpen(true);
  };

  const handleCloseUploadField = () => {
    setUploadFileIsOpen(false);
  };

  const createDocument = useGraphqlMutation(Documents.CreateDocument);
  const createDocumentRun = createDocument.run;
  const handleUploadFile = useCallback(
    (
      file: LoadedFile | null | undefined,
      fileName: string,
      priority: number | null
    ) => {
      if (file) {
        createDocumentRun({
          variables: {
            input: {
              document_section_id: id,
              file_id: String(file.fileId),
              name: fileName,
              priority
            }
          }
        })
          .then((result) => {
            if (!result.data?.result) {
              throw new Error('UnexpectedResult');
            }
            toast.success('Документ добавлен');
            handleCloseUploadField();
          })
          .catch((err) => {
            if (err instanceof AsyncSingletonError) return;
            handleDefaultError(
              err,
              'Произошла ошибка при создании документа. Попробуйте снова'
            );
          });
      }
    },
    [createDocumentRun, id]
  );

  const updateDocument = useGraphqlMutation(Documents.UpdateDocument);
  const updateDocumentRun = updateDocument.run;
  const handleChangeDoc = useCallback(
    ({ document, name, priority }: DocumentEditSubmitValuesType) => {
      const input = {
        id: document.id,
        document_section_id: id,
        file_id: document.file_id,
        name,
        priority
      };
      updateDocumentRun({
        variables: {
          input
        }
      })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при редактировании документа. Попробуйте снова'
          );
        });
    },
    [id, updateDocumentRun]
  );

  const deleteDocument = useGraphqlMutation(Documents.DeleteDocument);
  const deleteDocumentRun = deleteDocument.run;
  const handleDeleteDocument = (id: string) => {
    if (window.confirm('Вы действительно хотите удалить документ?')) {
      deleteDocumentRun({ variables: { id } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          toast.success('Документ удален');
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при удалении документа. Попробуйте снова'
          );
        });
    }
  };

  return (
    <div className={s.DocumentSection}>
      <DocumentSectionTitle
        title={name}
        sectionId={data.id}
        variant={TitleVariant.h3}
        editable={editable}
        classes={{
          documentSectionTitle: s.DocumentSection__title
        }}
      />
      {documents && documents.length > 0 ? (
        <>
          {documents.map((doc) => (
            <Document
              key={doc.id}
              editable={editable}
              onDelete={handleDeleteDocument}
              onChangeSubmit={handleChangeDoc}
              {...doc}
            />
          ))}
          {uploadFileIsOpen && (
            <DocumentUpload
              className={s.DocumentSection__uploadField}
              label={'Название документа'}
              fileType={FileTypeEnum.document}
              loading={createDocument.loading}
              onCancel={handleCloseUploadField}
              onUpload={handleUploadFile}
            />
          )}
          {editable && !uploadFileIsOpen && (
            <Button
              variant={ButtonVariant.secondary}
              size={ButtonSize.small}
              iconLeft={<AddIcon />}
              classes={{
                root: s.DocumentSection__addButton
              }}
              onClick={handleOpenUploadField}
            >
              Добавить документ
            </Button>
          )}
        </>
      ) : uploadFileIsOpen ? (
        <DocumentUpload
          label={'Название документа'}
          fileType={FileTypeEnum.document}
          loading={createDocument.loading}
          onCancel={handleCloseUploadField}
          onUpload={handleUploadFile}
        />
      ) : (
        <EmptySection
          editable={editable}
          onAddDocument={handleOpenUploadField}
        />
      )}
    </div>
  );
};
