import React, { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '@proscom/prostore-react';
import { EMDASH } from '@proscom/ui-utils';
import {
  LandingLayoutContent,
  LayoutContent,
  LayoutGrid
} from '../../Layout/Layout';
import { ReactComponent as LogoSvg } from '../../../../assets/img/logo.svg';
import { ReactComponent as IconVk } from '../../../../assets/img/icons/socials/vk.svg';
import { ReactComponent as IconTg } from '../../../../assets/img/icons/socials/telegram.svg';
import appStoreSrc from '../../../../assets/img/app-store-button.png';
import googlePlaySrc from '../../../../assets/img/google-play-button.png';
import { NavLink, NavLinkProps } from '../NavLink/NavLink';
import { BaseLink } from '../BaseLink/BaseLink';
import { Icon } from '../Icon/Icon';
import { routeLinks } from '../../../../routes/routeLinks';
import { AuthStore } from '../../../../store/AuthStore';
import { STORE_AUTH } from '../../../../store/storeKeys';
import { hideOnEnv } from '../../../../utils/hideOnEnv';
import { servicesRouteLink } from '../Header/Header';
import s from './Footer.module.scss';

interface NavItemsProps {
  title?: ReactNode;
  links?: NavLinkProps[];
}

const showPersonnelReserve = !hideOnEnv().personnelReserve;

const navItems: NavItemsProps[] = [
  {
    title: 'Адаптация',
    links: [
      routeLinks.manuals,
      routeLinks.offers,
      servicesRouteLink,
      routeLinks.vacation,
      routeLinks.newcomers
    ]
  },
  {
    title: 'Коммуникации',
    links: [
      routeLinks.articles,
      routeLinks.polls,
      // routeLinks.notices,
      // routeLinks.events,
      routeLinks.projects
    ]
  },
  {
    title: 'Компетенции',
    links: showPersonnelReserve
      ? [
          routeLinks.courses,
          routeLinks.internships,
          // routeLinks.rate360,
          routeLinks.reserve
        ]
      : [
          routeLinks.courses,
          routeLinks.internships
          // routeLinks.rate360
        ]
  }
];

const extNavItems: NavLinkProps[] = [
  routeLinks.users,
  routeLinks.documents,
  routeLinks.onboarding,
  routeLinks.feedback
];

interface FooterNavProps {
  items: NavItemsProps[];
}

const FooterNav: React.FC<FooterNavProps> = ({ items }) => {
  return (
    <div className={s.FooterNav}>
      {items.map((item, iItem) => (
        <div key={iItem} className={s.FooterNav__col}>
          <div className={s.FooterNav__title}>{item.title}</div>
          <div className={s.FooterNav__list}>
            {item.links?.map((link, iLink) => (
              <div key={iLink} className={s.FooterNav__item}>
                <NavLink linkClassName={s.FooterNav__link} {...link} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

interface FooterExtNavProps {
  items: NavLinkProps[];
}

const FooterExtNav: React.FC<FooterExtNavProps> = ({ items }) => {
  return (
    <div className={s.FooterExtNav}>
      {items.map((link, iLink) => (
        <div key={iLink} className={s.FooterExtNav__item}>
          <NavLink linkClassName={s.FooterExtNav__link} {...link} />
        </div>
      ))}
    </div>
  );
};

const Socials = () => {
  return (
    <div className={s.Socials}>
      <BaseLink href={'https://vk.com/miptru'} className={s.Socials__link}>
        <Icon icon={IconVk} iconSize={32} />
      </BaseLink>
      {/* <BaseLink
        href={'https://www.instagram.com/mipt.ru/'}
        className={s.Socials__link}
      >
        <Icon icon={IconInst} iconSize={32} />
      </BaseLink>
      <BaseLink
        href={'https://www.facebook.com/MIPT.rus'}
        className={s.Socials__link}
      >
        <Icon icon={IconFb} iconSize={32} />
      </BaseLink> */}
      <BaseLink href={'https://t.me/miptru'} className={s.Socials__link}>
        <Icon icon={IconTg} iconSize={32} />
      </BaseLink>
    </div>
  );
};

export const Footer = ({ isLanding }: { isLanding }) => {
  const [auth] = useStore<AuthStore>(STORE_AUTH);
  const user = auth.authData?.user;
  const dates = useMemo(() => {
    const startYear = 2020;
    const currentYear = new Date().getFullYear();
    if (startYear > currentYear) return startYear;
    return `${startYear}${EMDASH}${currentYear}`;
  }, []);
  const LayoutComp = isLanding ? LandingLayoutContent : LayoutContent;
  return (
    <div className={s.Footer}>
      <LayoutComp>
        <LayoutGrid className={s.Footer__main}>
          <div className={s.Footer__logoBox}>
            <Link className={s.Footer__logo} to={user ? '/main' : '/'}>
              <LogoSvg />
            </Link>
          </div>
          <div className={s.Footer__nav}>
            <FooterNav items={navItems} />
          </div>
          <div className={s.Footer__extNav}>
            <FooterExtNav items={extNavItems} />
          </div>
        </LayoutGrid>

        <div className={s.Footer__ext}>
          <div className={s.Footer__copy}>МФТИ. НR Университет, {dates}</div>
          <div className={s.Footer__download}>
            <BaseLink
              className={s.Footer__appButton}
              href={process.env.REACT_APP_APP_STORE_LINK}
            >
              <img src={appStoreSrc} alt="" />
            </BaseLink>
            <BaseLink
              className={s.Footer__appButton}
              href={process.env.REACT_APP_GOOGLE_PLAY_LINK}
            >
              <img src={googlePlaySrc} alt="" />
            </BaseLink>
          </div>
          <div className={s.Footer__socials}>
            <Socials />
          </div>
        </div>
      </LayoutComp>
    </div>
  );
};
