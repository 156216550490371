import React from 'react';
import { ErrorMessage, ErrorMessageProps } from '../ErrorMessage/ErrorMessage';
import { getGraphqlErrorMessage } from '../../../../graphql/graphqlErrors';
import { Button, ButtonSize, ButtonVariant } from '../Button';

export interface QueryErrorMessageProps extends ErrorMessageProps {
  error?: any;
  onReload?: () => void;
}

export function QueryErrorMessage({
  text = 'Ошибка запроса',
  error,
  onReload,
  children,
  ...props
}: QueryErrorMessageProps) {
  const errorMessage = getGraphqlErrorMessage(error) || undefined;

  return (
    <ErrorMessage text={text} description={errorMessage} {...props}>
      {onReload && (
        <Button
          variant={ButtonVariant.secondary}
          size={ButtonSize.small}
          type="button"
          onClick={onReload}
        >
          Обновить
        </Button>
      )}
      {children}
    </ErrorMessage>
  );
}
