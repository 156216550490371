import React from 'react';
import { IconButton, IconButtonProps } from '../IconButton/IconButton';
import { ReactComponent as DeleteIcon } from '../../../../assets/img/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../../assets/img/icons/edit.svg';
import { ReactComponent as ApproveIcon } from '../../../../assets/img/icons/check.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/img/icons/cross.svg';
import { ReactComponent as AddIcon } from '../../../../assets/img/icons/Add.svg';
import { ButtonBaseColor, ButtonVariant } from '../Button';

export enum ActionType {
  add = 'add',
  edit = 'edit',
  delete = 'delete',
  approve = 'approve',
  cancel = 'cancel'
}

export interface ActionButtonProps
  extends Omit<IconButtonProps, 'icon' | 'variant'> {
  actionType: ActionType;
}

type IActionTypeProps = {
  [key in ActionType]: IconButtonProps;
};

const ActionTypeProps: IActionTypeProps = {
  [ActionType.add]: {
    baseColor: ButtonBaseColor.green,
    icon: <AddIcon />
  },
  [ActionType.edit]: {
    baseColor: ButtonBaseColor.blue,
    icon: <EditIcon />
  },
  [ActionType.delete]: {
    baseColor: ButtonBaseColor.red,
    icon: <DeleteIcon />
  },
  [ActionType.approve]: {
    baseColor: ButtonBaseColor.green,
    icon: <ApproveIcon />
  },
  [ActionType.cancel]: {
    baseColor: ButtonBaseColor.gray,
    icon: <CrossIcon />
  }
};

export const ActionButton: React.FC<ActionButtonProps> = ({
  actionType,
  ...props
}) => {
  return (
    <IconButton
      variant={ButtonVariant.secondary}
      {...ActionTypeProps[actionType]}
      {...props}
    />
  );
};
