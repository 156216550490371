import React, { useState } from 'react';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { ReactComponent as IconLink } from '../../../assets/img/enrollee/link-pic.svg';
import { ReactComponent as IconLinkExt } from '../../../assets/img/enrollee/link-pic-ext.svg';
import {
  Icon,
  IconBoxSize,
  IconProps
} from '../../../common/components/ui/Icon/Icon';
import { chunkArrayByHalf } from '../../../utils/objects';
import { BaseLink } from '../../../common/components/ui/BaseLink/BaseLink';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { Modal } from '../../../common/components/ui/Modal/Modal';
import s from './Links.module.scss';

interface LinkItemProps {
  title: string;
  link?: string;
  icon?: IconProps['icon'];
  isMobile?: boolean;
  onClick?: () => void;
}

const linksTemp: LinkItemProps[] = [
  {
    title: 'Презентация МФТИ программы «Приоритет – 2030».',
    link:
      'https://mipt.ru/science/prioritet2030/dokumenty/%D0%9C%D0%A4%D0%A2%D0%98%20-%20%D0%BF%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F%20%D0%9F%D0%A0%20%D0%A1%D0%BE%D0%B2%D0%B5%D1%82-13.pdf'
  },
  {
    title: 'Карта кампуса',
    link:
      'https://www.google.com/maps/d/viewer?mid=134_O3HcndazfS8mc-hTCy73FTel2PqPs&hl=ru&ll=55.930255996961854%2C37.519690656997184&z=17'
  },
  {
    title: 'Личный кабинет сотрудника',
    link: 'https://profile.mipt.ru/'
  },
  {
    title: 'Официальный сайт МФТИ',
    link: 'https://mipt.ru/'
  },
  {
    title: 'Телеграмм канал',
    link: 'https://t.me/miptru'
  },
  // {
  //   title: 'Фейсбук',
  //   link: 'https://www.facebook.com/MIPT.rus/'
  // },
  {
    title: 'Телефонный справочник',
    link: 'https://mipt.ru/about/general/contacts/phones'
  },
  {
    title: 'Цифровые сервисы МФТИ',
    link: 'https://mipt.ru/it/'
  },
  {
    title: 'Брендбук МФТИ',
    link: 'https://mipt.ru/about/general/Brandbook_MIPT.php'
  },
  {
    title: 'Новости',
    link: 'https://mipt.ru/news/'
  },
  {
    title: 'Физтехкнига',
    link: 'http://fizmatkniga.org/magazin/'
  },
  {
    title: 'Журнал «За Науку»',
    link: 'https://zanauku.mipt.ru/'
  },
  {
    title: 'Музей истории МФТИ',
    link: 'https://mipt-museum.ru/'
  },
  {
    title: 'Электронная библиотека МФТИ',
    link: 'http://books.mipt.ru/'
  },
  {
    title: 'Официальный канал',
    link: 'https://rutube.ru/channel/23787794/'
  },
  {
    title: 'Дистанционные занятия МФТИ',
    link: 'https://www.youtube.com/channel/UCEEhbFAvl3fOW5geICQbMcg/featured'
  },
  {
    title: 'Проект Физтех Science',
    link: 'https://www.youtube.com/channel/UCodmNkCYGc7Taf2Arz7T44A/featured'
  },
  {
    title: 'Чат-бот «АшНюша» в Telegram',
    link: 'https://t.me/miptassistbot'
  },
  {
    title: 'Сокращения, принятые в МФТИ',
    icon: IconLinkExt
  }
];

const linksChunks = chunkArrayByHalf(linksTemp, 5);

const modalItems = [
  {
    title: 'АК',
    text: 'Аудиторный корпус'
  },
  {
    title: 'ГК',
    text: 'Главный корпус'
  },
  {
    title: 'ЛК',
    text: 'Лабораторный корпус'
  },
  {
    title: 'НК',
    text: 'Новый корпус (Корпус микроэлектроники, Физтех-квант)'
  },
  {
    title: 'КПМ',
    text: 'Корпус прикладной математики'
  },
  {
    title: 'Физтех БИО',
    text: 'Научно- инжинириновый комплекс в области живых систем'
  },
  {
    title: 'КСП',
    text: 'комбинат студенческого питания'
  },
  {
    title: 'АУП',
    text: 'Административно- управленческий персонал'
  },
  {
    title: 'ППС',
    text: 'Профессорско- преподавательский состав'
  },
  {
    title: 'НИЧ',
    text: 'Научно – исследовательская часть'
  },
  {
    title: 'УРП',
    text: 'Управление по работе с персоналом'
  },
  {
    title: 'ЦДПО',
    text: 'Центр дополнительного профессионального образования'
  }
];

const LinkItem: React.FC<LinkItemProps> = ({
  title,
  link,
  icon,
  isMobile,
  onClick
}) => {
  const Wrapper = isMobile
    ? 'div'
    : (props) => <BaseLink {...props} href={link} isClickable={!!link} />;
  const LinkWrapper = isMobile
    ? (props) => <BaseLink {...props} href={link} isClickable={!!link} />
    : 'div';

  return (
    <Wrapper className={s.LinkItem} onClick={onClick}>
      <Icon
        className={s.LinkItem__icon}
        icon={icon || IconLink}
        boxSize={IconBoxSize.small}
      />
      <div className={s.LinkItem__title}>{title}</div>
      <LinkWrapper className={s.LinkItem__link}>Перейти</LinkWrapper>
    </Wrapper>
  );
};

export const Links = () => {
  const [modalActive, setModalActive] = useState(false);

  const { isMobile } = useWindowSize();

  return (
    <LayoutContent id={'links'} className={s.Links}>
      <Title variant={TitleVariant.h2} className={s.Links__title}>
        Полезные ссылки
      </Title>

      <div className={s.Links__grid}>
        {linksChunks.map((chunk, iChunk) => (
          <div key={iChunk} className={s.Links__gridCol}>
            {chunk.map((tour, iTour) => (
              <LinkItem
                key={iTour}
                isMobile={isMobile}
                {...tour}
                onClick={!tour.link ? () => setModalActive(true) : undefined}
              />
            ))}
          </div>
        ))}
      </div>

      <Modal
        title={'Сокращения, принятые в МФТИ'}
        className={s.LinksModal}
        isOpen={modalActive}
        onClose={() => setModalActive(false)}
      >
        {modalItems.map((item, iItem) => (
          <div key={iItem} className={s.LinksModal__item}>
            <span className={s.LinksModal__itemTitle}>{item.title}</span> -{' '}
            {item.text}
          </div>
        ))}
      </Modal>
    </LayoutContent>
  );
};
