import React from 'react';
import {
  CardArticleOther,
  CardArticleOtherProps
} from '../../../../common/components/ui/Cards/CardArticleOther/CardArticleOther';
import s from './ArticlesOthers.module.scss';

export interface ArticlesOthersProps {
  data?: CardArticleOtherProps[];
}

export const ArticlesOthers: React.FC<ArticlesOthersProps> = ({ data }) => {
  return (
    <div className={s.ArticlesOthers}>
      {data?.map((item, iItem) => (
        <div key={iItem} className={s.ArticlesOthers__item}>
          <CardArticleOther {...item} />
        </div>
      ))}
    </div>
  );
};
