import React from 'react';
import clsx from 'clsx';
import s from './Title.module.scss';

export enum TitleVariant {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4'
}

export interface TitleProps {
  title?: string;
  variant: TitleVariant;
  isHero?: boolean;
  className?: string;
  children?: React.ReactNode;
  withMargin?: boolean;
}

export const Title: React.FC<TitleProps> = ({
  title,
  variant,
  isHero,
  className,
  withMargin,
  children
}) => {
  const Component = variant;

  return (
    <Component
      className={clsx(
        s.Title,
        s[`Title_${variant}`],
        {
          [s.Title_hero]: isHero,
          [s.Title_withMargin]: withMargin
        },
        className
      )}
      title={title}
    >
      {children || title}
    </Component>
  );
};
