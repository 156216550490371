import gql from 'graphql-tag';

export const FRAGMENT_EXTERNAL_POLL_SNIPPET = gql`
  fragment ExternalPollSnippet on ExternalPollType {
    id
    name
    cover_file_id
    link
    passcode
    ends_at
    starts_at
    created_at
  }
`;

export const FRAGMENT_EXTERNAL_POLL_FULL = gql`
  fragment ExternalPollFull on ExternalPollType {
    id
    name
    cover_file_id
    link
    passcode
    ends_at
    starts_at
    participants_all
    participating_units {
      unit_id
      unit {
        id
        name
      }
      include_children
    }
    participants {
      id
      name
      surname
      patronymic
      email
    }
  }
`;
