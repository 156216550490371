import gql from 'graphql-tag';

export const FRAGMENT_OFFER_SNIPPET = gql`
  fragment OfferSnippet on OfferType {
    id
    name
    snippet
    cover_file_id
    expires_at
    partner_logo_file_id
    kind {
      id
      name
    }
  }
`;

export const FRAGMENT_OFFER_FULL = gql`
  fragment OfferFull on OfferType {
    id
    category {
      id
      name
    }
    category_id
    conditions
    cover_file_id
    description
    expires_at
    link
    kind {
      id
      name
    }
    kind_id
    name
    partner_logo_file_id
    snippet
    source
    document_files {
      id
      name
    }
  }
`;
