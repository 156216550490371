import React from 'react';
import clsx from 'clsx';
import s from './ArticleForm.module.scss';

export interface ArticleFormBlockProps {
  className?: string;
  label?: string;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}

export const ArticleFormBlock: React.FC<ArticleFormBlockProps> = ({
  className,
  label,
  actions,
  children
}) => {
  return (
    <div className={clsx(s.ArticleFormBlock, className)}>
      {label && <div className={s.ArticleFormBlock__label}>{label}</div>}

      <div className={s.ArticleFormBlock__content}>{children}</div>

      {actions && <div className={s.ArticleFormBlock__actions}>{actions}</div>}
    </div>
  );
};
