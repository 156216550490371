import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import {
  Breadcrumbs,
  getBreadcrumbItemEntity,
  IBreadcrumbsEntity,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { routeLinks } from '../../routeLinks';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { getProjectsPageBreadcrumbs } from '../list/ProjectsPage';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { ProjectContent } from '../ProjectContent/ProjectContent';
import { ProjectParticipants } from '../ProjectParticipants/ProjectParticipants';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { useProject } from '../../../graphql/hooks/projects/useProject';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { ProjectActions } from '../ProjectActions/ProjectActions';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { Projects } from '../../../store/projects/Projects';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { quitProjectWarning } from '../constants';
import { UserPermission } from '../../../utils/permissions';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import s from './ProjectPage.module.scss';

export interface ProjectPageProps
  extends RouteComponentProps<{ projectId: string }> {
  history: AppHistoryType;
}

export function getProjectPageBreadcrumbs({
  project
}: {
  project: IBreadcrumbsEntity;
}): IBreadcrumbsItem[] {
  return [
    ...getProjectsPageBreadcrumbs(),
    getBreadcrumbItemEntity(routeLinks.projects.to, project, 50)
  ];
}

export default function ProjectPage({ history, match }: ProjectPageProps) {
  const { projectId } = match.params;
  const { user } = useCurrentUser();
  const userId = user?.id;
  const canEditAll = useHasPermission(user, UserPermission.ProjectCrud);
  const canEditOwn = useHasPermission(user, UserPermission.ProjectCrudHead);

  const deleteProject = useGraphqlMutation(Projects.deleteProject);
  const deleteProjectRun = deleteProject.run;
  const deleteProjectLoading = deleteProject.loading;

  const sendProjectAppl = useGraphqlMutation(Projects.sendProjectApplication);
  const sendProjectApplRun = sendProjectAppl.run;
  const sendProjectApplLoading = sendProjectAppl.loading;

  const quitProject = useGraphqlMutation(Projects.quitProject);
  const quitProjectRun = quitProject.run;
  const quitProjectLoading = quitProject.loading;

  const projectQuery = useProject(projectId);
  const project = projectQuery.state.data;
  const headUserId = project?.head_user_id;
  const participants = project?.participants;

  const isProjectOwner = userId === headUserId;
  const canEditProject = canEditAll || (canEditOwn && isProjectOwner);

  const isParticipant = useMemo(() => {
    return !!participants?.find((p) => p.user_id === userId);
  }, [participants, userId]);

  const canParticipate = userId !== headUserId;

  const breadcrumbs = useMemo(
    () =>
      getProjectPageBreadcrumbs({
        project: {
          id: projectId,
          name: project?.name
        }
      }),
    [projectId, project]
  );

  const handleDeleteClick = useCallback(() => {
    if (window.confirm('Вы действительно хотите удалить проект?')) {
      deleteProjectRun({ variables: { id: projectId } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Проект удален');
            history.replace(routeLinks.projects.to);
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(err, 'Произошла ошибка при удалении проекта');
        });
    }
  }, [deleteProjectRun, history, projectId]);

  const handleSendProjectApplicationClick = useCallback(() => {
    sendProjectApplRun({ variables: { id: projectId } })
      .then((result) => {
        if (result.data?.result) {
          toast.success('Заявка на участие в проекте успешно отправлена');
        }
      })
      .catch((err) => {
        if (err instanceof AsyncSingletonError) return;
        handleDefaultError(
          err,
          'Произошла ошибка при отправке заявки на участие в проекте'
        );
      });
  }, [sendProjectApplRun, projectId]);

  const handleQuitProjectClick = useCallback(() => {
    if (window.confirm(quitProjectWarning)) {
      quitProjectRun({ variables: { id: projectId } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Вы вышли из проекта');
            history.goBack(routeLinks.projects.to);
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(err, 'Произошла ошибка при выходе из проекта');
        });
    }
  }, [quitProjectRun, history, projectId]);

  const { isMobile, isTablet } = useWindowSize();

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={8}>
            {queryLoader(projectQuery) ||
              (!project ? <NoData /> : <ProjectContent {...project} />)}
          </LayoutGrid.GridItem>

          {project && (
            <LayoutGrid.GridItem cols={isMobile ? 8 : isTablet ? 3 : 4}>
              <ProjectActions
                project={project}
                canEdit={canEditProject}
                canParticipate={canParticipate}
                isParticipant={isParticipant}
                deleteLoading={deleteProjectLoading}
                sendApplicationLoading={sendProjectApplLoading}
                quitLoading={quitProjectLoading}
                onDeleteClick={handleDeleteClick}
                onSendApplicationClick={handleSendProjectApplicationClick}
                onQuitProjectClick={handleQuitProjectClick}
              />
            </LayoutGrid.GridItem>
          )}
        </LayoutGrid>

        {participants && participants.length > 0 && (
          <div className={s.ProjectPage__participants}>
            <Title
              className={s.ProjectPage__participantsTitle}
              variant={TitleVariant.h2}
              title={'Участники'}
            />
            <ProjectParticipants participants={participants} />
          </div>
        )}
      </LayoutContent>
    </PageLayout>
  );
}
