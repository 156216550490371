import React from 'react';
import {
  LandingGrid,
  LandingLayoutContent
} from '../../../common/components/Layout/Layout';
import { TextLink } from '../../../common/components/ui/TextLink/TextLink';
import personSrc from '../../../assets/img/welcome/contacts_person.png';
import {
  ContactsCard,
  ContactsCardPerson,
  ContactsCardSectionProps
} from './ContactsCard';
import s from './Contacts.module.scss';

export interface ContactsGridProps {
  title: string;
  cardSections: ContactsCardSectionProps[];
}

const contactsTemp: ContactsGridProps[] = [
  {
    title: 'Управление по работе с персоналом (УРП)',
    cardSections: [
      {
        title: (
          <ContactsCardPerson
            avatarSrc={personSrc}
            profession={'Начальник управления'}
            name={'Деева Елена Юрьевна'}
          />
        ),
        contacts: [
          {
            title: 'Телефон',
            content: '+7 (485) 713‒91‒98'
          },
          {
            title: 'Кампус',
            content: 'Аудиторный корпус, каб. 321'
          }
        ]
      },
      {
        title: 'Специалисты',
        contacts: [
          {
            title: 'Телефон',
            content: '+7 (498) 744-65-51'
          },
          {
            title: 'Кампус',
            content: 'Аудиторный корпус, каб. 110'
          },
          {
            title: 'Почта',
            content: (
              <TextLink noUnderline href={'mailto:hr@mipt.ru'}>
                hr@mipt.ru
              </TextLink>
            )
          }
        ]
      },
      {
        title: 'Вакансии',
        contacts: [
          {
            title: 'Телефон',
            content: '+7 (498) 744-65-51'
          }
        ]
      },
      {
        title: 'Техническая поддержка сайта',
        contacts: [
          {
            title: 'Телефон',
            content: '+7 (498) 713-91-44'
          }
        ]
      }
    ]
  },
  {
    title: 'Отдел кадров',
    cardSections: [
      {
        title: 'Начальник отдела',
        contacts: [
          {
            title: 'Телефон',
            content: '+7 (495) 408-49-88'
          }
        ]
      },
      {
        title: 'Заместитель начальника отдела',
        contacts: [
          {
            title: 'Телефон',
            content: '+7 (498) 744-65-39'
          }
        ]
      },
      {
        title: 'Конкурс ППС',
        contacts: [
          {
            title: 'Телефон',
            content: '+7 (495) 576-52-11'
          }
        ]
      },
      {
        title: 'Отдел развития персонала',
        contacts: [
          {
            title: 'Телефон',
            content: '+7 (498) 744-65-42'
          }
        ]
      },
      {
        title: 'Обучение',
        contacts: [
          {
            title: 'Телефон',
            content: '+7 (498) 713-91-44'
          }
        ]
      }
    ]
  }
];

export const Contacts = () => {
  return (
    <LandingLayoutContent className={s.Contacts}>
      <LandingGrid className={s.Contacts__headGrid}>
        <LandingGrid.GridItem cols={6}>
          <div className={s.Contacts__title}>Телефоны справочных служб</div>
        </LandingGrid.GridItem>
        <LandingGrid.GridItem cols={6}>
          <div className={s.Contacts__text}>
            <p>
              На сайте{' '}
              <TextLink
                href={'https://mipt.ru/about/general/contacts/phones.php'}
              >
                mipt.ru
              </TextLink>{' '}
              в разделе Телефонный справочник Вы найдете контактные данные
              сотрудников МФТИ, с указанием имён, должностей, телефонных
              номеров, добавочных телефонных номеров и местоположение
              структурных подразделений.
            </p>
            <p>
              <b>Справочная МФТИ:</b> +7 (495) 408 45-54
            </p>
          </div>
        </LandingGrid.GridItem>
      </LandingGrid>

      <LandingGrid className={s.Contacts__itemsGrid}>
        {contactsTemp.map((item, iItem) => (
          <LandingGrid.GridItem key={iItem} cols={1}>
            <div className={s.Contacts__sectionTitle}>{item.title}</div>
            <ContactsCard sections={item.cardSections} />
          </LandingGrid.GridItem>
        ))}
      </LandingGrid>
    </LandingLayoutContent>
  );
};
