import React from 'react';
import { get } from 'lodash-es';
import { TableRowData } from '../../../../common/components/ui/Table/Table';
import { useFormatDate } from '../../../../common/hooks/useFormatDate';

export interface FileLinkProps {
  row: TableRowData;
  field: string;
}

export const PollDate: React.FC<FileLinkProps> = ({ row, field }) => {
  const date = useFormatDate(get(row, field, undefined));
  return <>{date}</>;
};
