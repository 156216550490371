import React, { useMemo } from 'react';
import { ProjectType } from '../../../graphql/types';
import { mapProjectStatus } from '../utils';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { ProjectDirector } from '../ProjectDirector/ProjectDirector';
import { StatusIndicator } from '../../../common/components/ui/StatusIndicator/StatusIndicator';
import s from './ProjectContent.module.scss';

export interface ProjectContentProps
  extends Pick<ProjectType, 'status' | 'head_user' | 'name' | 'description'> {}

export const ProjectContent: React.FC<ProjectContentProps> = ({
  status,
  head_user,
  name,
  description
}) => {
  const indicatorStatusObj = useMemo(() => {
    return mapProjectStatus(status);
  }, [status]);

  return (
    <div className={s.ProjectContent}>
      <div className={s.ProjectContent__head}>
        <Title
          className={s.ProjectContent__title}
          variant={TitleVariant.h2}
          title={name}
        />
        {status && (
          <div className={s.ProjectContent__status}>
            <StatusIndicator {...indicatorStatusObj} />
          </div>
        )}
      </div>
      <div
        className={s.ProjectContent__description}
        dangerouslySetInnerHTML={{
          __html: description || ''
        }}
      />

      {head_user && (
        <div className={s.ProjectContent__director}>
          <Title
            className={s.ProjectContent__directorTitle}
            variant={TitleVariant.h3}
            title={'Руководитель проекта'}
          />
          <ProjectDirector
            className={s.ProjectContent__directorPerson}
            user={head_user}
          />
        </div>
      )}
    </div>
  );
};
