import React, { useCallback, useEffect, useMemo } from 'react';
import { Formik, Form, FormikValues } from 'formik';
import add from 'date-fns/add';
import * as yup from 'yup';
import { FormDateRangePicker } from '../../../common/components/ui/Form/FormDateRangePicker/FormDateRangePicker';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { ReactComponent as IconDoc } from '../../../assets/img/icons/Document.svg';
import { convertToUtcStr } from '../../../utils/date/date';
import {
  DATE_END_REQUIRED,
  DATE_ORDER_MESSAGE,
  DATE_START_REQUIRED
} from '../../../store/validationSchema';
import { useExportFeedbacks } from './useExportFeedbacks';
import s from './FeedbackExport.module.scss';

const validationSchema = yup.object().shape({
  start_from: yup.date().required(DATE_START_REQUIRED),
  end_at: yup
    .date()
    .required(DATE_END_REQUIRED)
    .when(
      'start_from',
      (start_from, schema) =>
        start_from && schema.min(start_from, DATE_ORDER_MESSAGE)
    )
});

export interface FeedbackExportProps {
  exportDisabled?: boolean;
  onFormChange?: (values: FormikValues) => void;
}

interface FeedbackExportFormProps extends FeedbackExportProps {
  values: FormikValues;
  loading?: boolean;
}

const FeedbackExportForm: React.FC<FeedbackExportFormProps> = ({
  values,
  loading,
  exportDisabled,
  onFormChange
}) => {
  useEffect(() => {
    onFormChange?.(values);
  }, [values, onFormChange]);

  return (
    <Form className={s.FeedbackExport__form}>
      <FormDateRangePicker
        classes={{
          root: s.FeedbackExport__datePicker,
          input: s.FeedbackExport__datePickerInput
        }}
        startField={'start_from'}
        endField={'end_at'}
      />
      <Button
        className={s.FeedbackExport__button}
        type={'submit'}
        variant={ButtonVariant.secondary}
        size={ButtonSize.small}
        iconLeft={<IconDoc />}
        loading={loading}
        disabled={exportDisabled}
      >
        Экспортировать в xlsx
      </Button>
    </Form>
  );
};

export const FeedbackExport: React.FC<FeedbackExportProps> = ({
  exportDisabled,
  onFormChange
}) => {
  const {
    run: exportListToXlsx,
    loading: exportListToXlsxIsLoading
  } = useExportFeedbacks();

  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
      exportListToXlsx(data);
    },
    [exportListToXlsx]
  );

  const initialValues = useMemo(() => {
    const today = new Date();
    return {
      start_from: convertToUtcStr(today),
      end_at: convertToUtcStr(add(today, { days: 1 }))
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <FeedbackExportForm
          loading={exportListToXlsxIsLoading}
          exportDisabled={exportDisabled}
          onFormChange={onFormChange}
          {...props}
        />
      )}
    </Formik>
  );
};
