import {
  OfferCreateInput,
  OfferDocument,
  OfferSourcesEnum,
  OfferType,
  OfferUpdateInput
} from '../../graphql/types';

export interface OffersFormValues {
  id?: string;
  name: string;
  description: string;
  conditions: string;
  kind_id?: string;
  category_id?: string;
  cover_file_id?: string;
  partner_logo_file_id?: string;
  expires_at?: string;
  snippet: string;
  link?: string;
  source: OfferSourcesEnum;
  documents?: OfferDocument[];
  document_files?: OfferType['document_files'];
}

export class OffersUtils {
  static toForm(data?: OfferType): OffersFormValues {
    return {
      id: data?.id,
      name: data?.name || '',
      description: data?.description || '',
      conditions: data?.conditions || '',
      kind_id: data?.kind_id || '',
      category_id: data?.category_id || '',
      cover_file_id: data?.cover_file_id || '',
      partner_logo_file_id: data?.partner_logo_file_id || '',
      expires_at: data?.expires_at || '',
      snippet: data?.snippet || '',
      link: data?.link || '',
      source: data?.source || OfferSourcesEnum.Mipt,
      document_files: data?.document_files
    };
  }

  static fromCreateForm({
    cover_file_id,
    partner_logo_file_id,
    expires_at,
    documents,
    document_files,
    ...formData
  }: OffersFormValues): OfferCreateInput {
    return {
      cover_file_id: cover_file_id || null,
      partner_logo_file_id: partner_logo_file_id || null,
      expires_at: expires_at || null,
      documents: documents || null,
      ...formData
    };
  }

  static fromUpdateForm(formData: OffersFormValues): OfferUpdateInput {
    return {
      id: formData.id!,
      ...OffersUtils.fromCreateForm(formData)
    };
  }
}
