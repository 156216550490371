import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { ProjectParticipantType, UserType } from '../../../graphql/types';
import { chunkArrayByHalf } from '../../../utils/objects';
import { UserUtils } from '../../../store/users/UserUtils';
import {
  TextLink,
  TextLinkVariant
} from '../../../common/components/ui/TextLink/TextLink';
import { routeLinks } from '../../routeLinks';
import { BaseLink } from '../../../common/components/ui/BaseLink/BaseLink';
import {
  ActionButton,
  ActionType
} from '../../../common/components/ui/ActionButton/ActionButton';
import s from './ProjectParticipants.module.scss';

export interface ProjectParticipantProps extends UserType {
  className?: string;
  isEditable?: boolean;
  onDeleteClick?: (id: string) => void;
}

export const ProjectParticipant: React.FC<ProjectParticipantProps> = ({
  className,
  isEditable,
  onDeleteClick,
  ...user
}) => {
  const userId = user.id;

  const userName = useMemo(() => {
    return UserUtils.getName(user);
  }, [user]);

  const link = `${routeLinks.users.to}/${userId}`;

  const handleDeleteClick = useCallback(() => {
    onDeleteClick?.(userId);
  }, [onDeleteClick, userId]);

  return isEditable ? (
    <div className={s.ProjectParticipant}>
      <div className={s.ProjectParticipant__name}>
        <TextLink to={link} variant={TextLinkVariant.black} noUnderline>
          {userName}
        </TextLink>
      </div>
      <div className={s.ProjectParticipant__actions}>
        <ActionButton
          actionType={ActionType.delete}
          onClick={handleDeleteClick}
        />
      </div>
    </div>
  ) : (
    <BaseLink
      to={link}
      className={clsx(s.ProjectParticipant, s.ProjectParticipant_link)}
    >
      <div className={s.ProjectParticipant__name}>{userName}</div>
      <div className={s.ProjectParticipant__actions}>
        <TextLink
          className={s.ProjectParticipant__actionsLink}
          isClickable={false}
          showArrow
          noUnderline
        >
          Посмотреть профиль
        </TextLink>
      </div>
    </BaseLink>
  );
};

export interface ProjectParticipantsProps {
  participants?: ProjectParticipantType[];
  isEditable?: boolean;
  onDeleteClick?: (id: string) => void;
}

export const ProjectParticipants: React.FC<ProjectParticipantsProps> = ({
  participants,
  isEditable,
  onDeleteClick
}) => {
  const usersChunks = useMemo(() => {
    if (!participants) return [];
    return chunkArrayByHalf(participants, 5);
  }, [participants]);

  return (
    <div className={s.ProjectParticipants}>
      {usersChunks.map((chunk, iChunk) => (
        <div key={iChunk} className={s.ProjectParticipants__column}>
          {chunk.map((participant) => (
            <div
              key={participant.user.id}
              className={s.ProjectParticipants__item}
            >
              <ProjectParticipant
                isEditable={isEditable}
                onDeleteClick={onDeleteClick}
                {...participant.user}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
