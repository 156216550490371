import { CustomError } from '../error';

export interface FetchErrorResponse {
  statusCode: number;
  statusText: string;
  body: any;
}

export class FetchError extends CustomError {
  constructor(message: string, public response: FetchErrorResponse) {
    super(message + ' ' + JSON.stringify(response));
  }

  toJSON() {
    return {
      ...super.toJSON(),
      response: this.response
    };
  }
}

export async function ensureFetch(input: RequestInfo, init?: RequestInit) {
  const response = await fetch(input, init);
  if (!response.ok) {
    throw new FetchError('Fetch resulted in non 200 status code', {
      statusCode: response.status,
      statusText: response.statusText,
      body: (await response.json()) || response.body?.toString()
    });
  }
  return response;
}
