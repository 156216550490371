import React, { useCallback, useEffect, useState } from 'react';
import { DATE_FORMAT_RUSSIAN } from '@proscom/ui-utils-date';
import clsx from 'clsx';
import ReactDatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import { convertToUtcStr, getDateUtc } from '../../../../utils/date/date';
import { DatePickerProps } from './DatePicker';
import s from './DatePicker.module.scss';

export type DateRangePickerValueType = [string, string];
export type DateRangePickerSelectedType = [Date | undefined, Date | undefined];
export type DateRangePickerValueConverter = (
  values?: DateRangePickerValueType
) => DateRangePickerSelectedType | undefined;
export type DateRangePickerReturnConverter = (
  values?: [Date, Date] | null
) => any;

export interface DateRangePickerProps
  extends Pick<
    DatePickerProps,
    | 'className'
    | 'classes'
    | 'label'
    | 'minDate'
    | 'maxDate'
    | 'excludeDates'
    | 'disabled'
    | 'hintMessage'
    | 'errorMessage'
    | 'onBlur'
  > {
  dayClassName?: (date: Date) => string | null;
  value?: DateRangePickerValueType;
  isYearPicker?: boolean;
  inline?: boolean;
  valueConverter?: DateRangePickerValueConverter;
  returnConverter?: DateRangePickerReturnConverter;
  onChange?: (values?: any | undefined) => void;
  onMonthChange?: (date: Date) => void;
}

export function convertPickerValues(
  values?: DateRangePickerValueType,
  converter?: DateRangePickerValueConverter
): DateRangePickerSelectedType | undefined {
  const vals = converter ? converter(values) : values;

  return vals?.map((v) => {
    const valStr = v && convertToUtcStr(v);
    return valStr ? new Date(getDateUtc(valStr)) : undefined;
  }) as DateRangePickerSelectedType;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  className,
  classes,
  label,
  value,
  isYearPicker,
  valueConverter,
  returnConverter,
  inline,
  dayClassName,
  onMonthChange,
  minDate,
  maxDate,
  excludeDates,
  disabled,
  hintMessage,
  errorMessage,
  onChange,
  onBlur
}) => {
  const [selected, setSelected] = useState<
    DateRangePickerSelectedType | undefined
  >(convertPickerValues(value, valueConverter));

  useEffect(() => {
    setSelected(convertPickerValues(value, valueConverter));
  }, [value, valueConverter]);

  const handleChange = useCallback(
    (values: [Date, Date]) => {
      setSelected(values ?? undefined);

      const vals = returnConverter ? returnConverter(values) : values;
      const valStrings = vals?.map(convertToUtcStr);

      onChange?.(
        valStrings?.some((v) => !!v)
          ? (valStrings as [string, string])
          : undefined
      );
    },
    [onChange, returnConverter]
  );

  const Wrapper = inline ? 'div' : 'label';

  return (
    <Wrapper className={clsx(s.DatePicker, className, classes?.root)}>
      {label && (
        <div className={clsx(s.DatePicker__label, classes?.label)}>{label}</div>
      )}
      <ReactDatePicker
        className={clsx(s.DatePicker__picker, classes?.input, {
          [s.DatePicker__picker_disabled]: disabled
        })}
        wrapperClassName={clsx(
          s.DatePicker__pickerWrapper,
          classes?.inputWrapper
        )}
        dayClassName={dayClassName}
        // value={valueStr || ''}
        selected={selected?.[0]}
        startDate={selected?.[0]}
        endDate={selected?.[1]}
        minDate={minDate}
        maxDate={maxDate}
        excludeDates={excludeDates}
        disabled={disabled}
        dateFormat={isYearPicker ? 'yyyy' : DATE_FORMAT_RUSSIAN}
        locale={ru}
        shouldCloseOnSelect={true}
        selectsRange={true}
        showYearPicker={isYearPicker}
        inline={inline}
        onChange={handleChange}
        onMonthChange={onMonthChange}
        onBlur={onBlur}
      />
      <HelpText
        className={classes?.helperText}
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </Wrapper>
  );
};
