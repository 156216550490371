import React from 'react';
import { Entity } from '../../ui/AsyncSelect/entities/types';
import {
  FilterSelectAsyncMulti,
  FilterSelectAsyncMultiProps
} from '../../ui/Filter/FilterSelectAsyncMulti';

export interface FilterSkillsProps
  extends Omit<FilterSelectAsyncMultiProps<Entity.skillsPage>, 'entity'> {}

export const FilterSkills: React.FC<FilterSkillsProps> = ({
  value,
  ...props
}) => {
  return (
    <FilterSelectAsyncMulti
      entity={Entity.skillsPage}
      value={value}
      placeholder={props.placeholder || `Выберите компетенции`}
      {...props}
    />
  );
};
