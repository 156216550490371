import React, { useCallback } from 'react';
import clsx from 'clsx';
import { SpecificContentBlockEditableProps } from '../types';
import { IContentTextImage } from '../../Form/FormContentEditor/Content';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { FileUploadButton } from '../../UploadField/FileUploadButton';
import { ImageBlockPreview } from '../ImageBlock/ImageBlockPreview';
import { TextEditor } from '../../TextEditor/TextEditor';
import { TextArea } from '../../TextField/TextArea';
import s from '../ContentBlock.module.scss';

export const TextImageBlockEditable: React.FC<
  SpecificContentBlockEditableProps<IContentTextImage>
> = ({ data, onChange, className, meta }) => {
  const { fileId, imageCaption, content } = data;

  const handleTextChange = useCallback(
    (content?: string) => {
      onChange({
        ...data,
        content
      });
    },
    [onChange, data]
  );

  const handleImageChange = useCallback(
    (fileId?: string) => {
      onChange({
        ...data,
        fileId
      });
    },
    [onChange, data]
  );

  const handleCaptionChange = useCallback(
    (imageCaption?: string) => {
      onChange({
        ...data,
        imageCaption
      });
    },
    [onChange, data]
  );

  return (
    <div className={clsx(s.TextImageBlock, className)}>
      <TextEditor
        className={s.TextImageBlock__content}
        value={content || ''}
        onChange={handleTextChange}
      />
      <div className={s.TextImageBlock__imagePreview}>
        <ImageBlockPreview
          fileId={fileId}
          isEditable={!!fileId}
          fileType={meta?.fileType || FileTypeEnum.article_image}
          onRemoveClick={handleImageChange}
        />
        {!fileId && (
          <FileUploadButton
            className={s.TextImageBlock__uploadButton}
            buttonText={'Загрузите изображение'}
            fileType={meta?.fileType || FileTypeEnum.article_image}
            onFileUploaded={handleImageChange}
          />
        )}
        <TextArea
          className={s.TextImageBlock__imageCaptionEditor}
          placeholder={'Описание изображения'}
          value={imageCaption || ''}
          rows={2}
          onChange={handleCaptionChange}
        />
      </div>
    </div>
  );
};
