import React, { useEffect, useMemo, useRef } from 'react';
import {
  addDays,
  isSameDay
} from 'date-fns';
import { useField, useFormikContext } from 'formik';
import { differenceWith, min } from 'lodash-es';
import { FormSection } from '../../../../../common/components/ui/Form/FormSection/FormSection';
import { FormDatePicker } from '../../../../../common/components/ui/Form/FormDatePicker/FormDatePicker';
import {
  Title,
  TitleVariant
} from '../../../../../common/components/ui/Title/Title';
import {
  Button,
  ButtonBaseColor,
  ButtonSize,
  ButtonVariant
} from '../../../../../common/components/ui/Button';
import { TextFieldPreview } from '../../../../../common/components/ui/TextFieldPreview/TextFieldPreview';
import {
  useDayOffs,
  useVacationDaysAmount
} from '../../../../../common/hooks/useDayOffs';
import { getDateUtc } from '../../../../../utils/date/date';
import {
  PositionType,
  VacationScheduleItemType
} from '../../../../../graphql/types';
import {
  VacationSchedulesFormValuesProps,
  VacationsUtils
} from '../../../../../store/vacations/VacationsUtils';
import { vacationMaxStartDate, vacationMinStartDate } from '../../../constants';
import s from './VacationPeriod.module.scss';

export interface VacationPeriodProps
  extends Pick<VacationScheduleItemType, 'start' | 'end'> {
  index: number;
  position?: PositionType;
  blockName: string;
  isNewPeriod: boolean;
  isRemovable: boolean;
  onRemovePeriod: (index: number) => void;
}

export const VacationPeriod = ({
  index,
  position,
  start,
  end,
  blockName,
  isNewPeriod,
  isRemovable,
  onRemovePeriod
}: VacationPeriodProps) => {
  const {
    values,
    setFieldValue
  } = useFormikContext<VacationSchedulesFormValuesProps>();

  const selectedDates = values.selectedDates;

  const fieldName = `${blockName}.periods[${index}]`;
  const [{ value: daysSelected }] = useField(`${fieldName}.days`);
  const [{ value: daysAvailable }] = useField('unassigned_days');

  const selectedDaysAmount = useVacationDaysAmount({
    startDate: start,
    endDate: end
  });

  const startDate = useMemo(() => {
    return start ? new Date(getDateUtc(start)) : undefined;
  }, [start]);
  const startDateRef = useRef(startDate);

  const holidayIntervalEndDate = useMemo(() => {
    return startDate ? addDays(startDate, daysAvailable || 0) : undefined;
  }, [startDate, daysAvailable]);

  const { daysOff } = useDayOffs({
    startDate: startDate ?? undefined,
    endDate: holidayIntervalEndDate
  });

  const maxStartDate = useMemo(() => {
    const positionEndDate = position?.c_end_date
      ? new Date(getDateUtc(position.c_end_date))
      : undefined;

    return min([positionEndDate, vacationMaxStartDate]);
  }, [position]);

  const minEndDate = useMemo(() => {
    return startDate || vacationMinStartDate;
  }, [startDate]);

  const maxEndDate = useMemo(() => {
    const availEndDate = startDate
      ? addDays(
          startDate,
          (daysAvailable || 0) + (daysSelected || 0) + daysOff.length - 1
        )
      : undefined;

    return min([availEndDate, maxStartDate]);
  }, [daysAvailable, daysSelected, daysOff, startDate, maxStartDate]);

  /**
   * Блокируем все выбранные ранее даты, кроме дат текущего периода,
   * чтобы даты текущего периода не блокировались, и можно было их менять
   */
  const excludedDates = useMemo(() => {
    if (!selectedDates.length) return [];
    if (!start || !end) return selectedDates;
    const currInterval = VacationsUtils.getSelectedDates([{ start, end }]);
    return differenceWith(selectedDates, currInterval, isSameDay);
  }, [selectedDates, start, end]);

  useEffect(() => {
    if (startDateRef.current !== startDate) {
      startDateRef.current = startDate;
      setFieldValue(`${fieldName}.end`, undefined);
    }
  }, [fieldName, startDate, setFieldValue]);

  useEffect(() => {
    setFieldValue(`${fieldName}.days`, selectedDaysAmount || 0);
  }, [fieldName, selectedDaysAmount, setFieldValue]);

  return (
    <div className={s.VacationPeriod}>
      <div className={s.VacationPeriod__title}>
        <Title
          className={s.VacationPeriod__titleText}
          variant={TitleVariant.h3}
        >
          {isNewPeriod ? 'Новый период' : 'Определите новые даты отпуска'}
        </Title>

        {isRemovable && (
          <Button
            variant={ButtonVariant.secondary}
            baseColor={ButtonBaseColor.red}
            size={ButtonSize.small}
            onClick={() => onRemovePeriod(index)}
          >
            Удалить
          </Button>
        )}
      </div>

      <FormSection>
        <FormDatePicker
          name={`${fieldName}.start`}
          label={'Дата начала отпуска *'}
          placeholder={'Выберите дату'}
          minDate={vacationMinStartDate}
          maxDate={maxStartDate}
          excludeDates={excludedDates}
          // disabled={!daysAvailable}
        />
      </FormSection>

      <FormSection>
        <FormDatePicker
          name={`${fieldName}.end`}
          label={'Дата окончания отпуска *'}
          placeholder={'Выберите дату'}
          minDate={minEndDate}
          maxDate={maxEndDate}
          excludeDates={excludedDates}
          // disabled={!daysAvailable}
        />
      </FormSection>

      <FormSection>
        <TextFieldPreview
          title={'Количество календарных дней отпуска:'}
          simpleView
        >
          {selectedDaysAmount || 0}
        </TextFieldPreview>
      </FormSection>
    </div>
  );
};
