import React, { useMemo } from 'react';
import { FormSelect } from '../../../common/components/ui/Form/FormSelect/FormSelect';
import { useOfferKindsPage } from '../../../graphql/hooks/offers/useOfferKindsPage';
import { SelectClasses } from '../../../common/components/ui/Select/Select';

export interface OfferKindsSelectProps {
  classes?: SelectClasses;
}

export const OfferKindsSelect: React.FC<OfferKindsSelectProps> = ({
  classes
}) => {
  const kindsQuery = useOfferKindsPage({});
  const kindsOptions = useMemo(() => {
    if (!kindsQuery.state.data) {
      return [];
    }
    return kindsQuery.state.data.list.map((kind) => ({
      label: kind.name,
      value: kind.id
    }));
  }, [kindsQuery.state.data]);

  return (
    <FormSelect
      label={'Тип бонуса'}
      name={'kind_id'}
      options={kindsOptions}
      isLoading={kindsQuery.check.spinner}
      classes={classes}
      placeholder={'Выберите тип бонуса'}
    />
  );
};
