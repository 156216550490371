import React from 'react';
import clsx from 'clsx';
import { ReactComponent as IconQuotes } from '../../../../assets/img/icons/quotes.svg';
import { Icon } from '../Icon/Icon';
import s from './Blockquote.module.scss';

export interface BlockquoteClasses {
  root?: string;
  content?: string;
}

export interface BlockquoteProps {
  className?: string;
  classes?: BlockquoteClasses;
  children?: React.ReactNode;
}

export const Blockquote: React.FC<BlockquoteProps> = ({
  className,
  classes,
  children
}) => {
  return (
    <div className={clsx(s.Blockquote, className, classes?.root)}>
      <Icon className={s.Blockquote__icon} icon={IconQuotes} boxSize={18} />
      <div className={clsx(s.Blockquote__content, classes?.content)}>
        {children}
      </div>
    </div>
  );
};
