import React, { useMemo } from 'react';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { MainSlider } from '../MainSlider/MainSlider';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import { useExternalPollsPage } from '../../../graphql/hooks/polls/useExternalPollsPage';
import { CardPollFooter } from '../../../common/components/ui/Cards/CardPoll/CardPollFooter';
import { Pagination } from '../constants';
import { CardMainWideProps } from '../../../common/components/ui/Cards/CardMainWide/CardMainWide';
import { CardStyle } from '../../../common/components/ui/Card/Card';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import {
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { BaseLink } from '../../../common/components/ui/BaseLink/BaseLink';
import { routeLinks } from '../../routeLinks';
import s from './ExternalPollsSlider.module.scss';

// ну да, выглядит как костыль, ну а шо ты тут сделаешь - все не перепишешь
export const MFTISlide: CardMainWideProps = {
  className: s.MFTISlideCard,
  classes: {
    inner: s.MFTISlideCard__inner
  },
  cardStyle: CardStyle.gradient,
  children: (
    <>
      <BaseLink
        className={s.MFTISlideCard__title}
        to={routeLinks.polls.to}
        tabIndex={-1}
      >
        <Title
          title={'Оцените работу административных служб МФТИ'}
          variant={TitleVariant.h2}
        />
      </BaseLink>
      <ButtonLink
        className={s.MFTISlideCard__linkButton}
        variant={ButtonVariant.secondary}
        size={ButtonSize.medium}
        to={routeLinks.polls.to}
      >
        Подробнее
      </ButtonLink>
    </>
  )
};

const useExternalPollsArgs = {
  pagination: Pagination
};

export const ExternalPollsSlider: React.FC = () => {
  const externalPollsQuery = useExternalPollsPage(useExternalPollsArgs);

  const externalPollSlides = useMemo(() => {
    if (!externalPollsQuery.state.data) return [];

    const slides = externalPollsQuery.state.data.list.map((externalPoll) => ({
      title: externalPoll.name,
      imageType: FileTypeEnum.external_poll_cover,
      coverFileId: externalPoll.cover_file_id ?? undefined,
      link: externalPoll.link,
      isExternal: true,
      additionalContent: (
        <CardPollFooter
          passcode={externalPoll.passcode ?? undefined}
          href={externalPoll.link}
        />
      )
    }));

    return [MFTISlide, ...slides.slice(0, 2)];
  }, [externalPollsQuery.state.data]);

  return (
    queryLoader(externalPollsQuery) || (
      <MainSlider slides={externalPollSlides} />
    )
  );
};
