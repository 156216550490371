import React, { useCallback, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { compareSimple, makeComparator } from '@proscom/ui-utils';
import {
  Breadcrumbs,
  getBreadcrumbItemEntity,
  IBreadcrumbsEntity,
  IBreadcrumbsItem
} from '../../../../common/components/ui/Breadcrumbs';
import { getPollsPageBreadcrumbs } from '../../list/PollsPage';
import { BasePageInterface } from '../../../../common/components/utils/BasePageInterface';
import { PageLayout } from '../../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../../common/components/Layout/Layout';
import { PollForm } from '../../forms/PollForm';
import { Card, CardStyle } from '../../../../common/components/ui/Card/Card';
import { PageTitle } from '../../../../common/components/ui/PageTitle/PageTitle';
import { queryLoader } from '../../../../common/components/utils/queryLoader';
import { NoData } from '../../../../common/components/ui/NoData/NoData';
import { usePoll } from '../../../../graphql/hooks/polls/usePoll';
import { PollFormValues, PollsUtils } from '../../../../store/polls/PollsUtils';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import { Polls } from '../../../../store/polls/Polls';
import { useGraphqlMutation } from '../../../../common/hooks/utils/useGraphqlMutation';
import { REQUIRED_MESSAGE } from '../../../../store/validationSchema';
import {
  PollQuestionType,
  PollQuestionTypesEnum
} from '../../../../graphql/types';
import { PollAttemptSuccess } from './PollAttemptSuccess/PollAttemptSuccess';
import s from './PollAttemptCreatePage.module.scss';

export function getPollAttemptCreatePageBreadcrumbs({
  poll
}: {
  poll: IBreadcrumbsEntity;
}): IBreadcrumbsItem[] {
  return [
    ...getPollsPageBreadcrumbs(),
    getBreadcrumbItemEntity('/polls', poll)
  ];
}

export default function PollAttemptCreatePage({
  match
}: BasePageInterface<{ pollId: string }>) {
  const { pollId } = match.params;
  const pollQuery = usePoll(pollId);
  const poll = useMemo(() => {
    if (!pollQuery.state.data) return null;
    return {
      ...pollQuery.state.data,
      questions: pollQuery.state.data.questions
        ?.slice()
        .sort(
          makeComparator(compareSimple(1), (r: PollQuestionType) => r.order)
        )
    };
  }, [pollQuery.state.data]);

  const [success, setSuccess] = useState(false);

  const createPollAttempt = useGraphqlMutation(Polls.CreatePollAttempt);
  const createPollAttemptRun = createPollAttempt.run;
  const handleSubmit = useCallback(
    (values: PollFormValues) => {
      const input = PollsUtils.fromCreateForm(values);
      createPollAttemptRun({ variables: { input } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          setSuccess(true);
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при прохождении опроса. Попробуйте снова'
          );
        });
    },
    [createPollAttemptRun]
  );

  const breadcrumbs = useMemo(
    () =>
      getPollAttemptCreatePageBreadcrumbs({
        poll: {
          id: pollId,
          name: poll?.name
        }
      }),
    [poll, pollId]
  );

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent innerClassName={s.PollAttemptCreatePage__innerClassName}>
        {queryLoader(pollQuery) ||
          (!poll ? (
            <NoData />
          ) : success ? (
            <PollAttemptSuccess />
          ) : (
            <>
              <Card
                cardStyle={CardStyle.bordered}
                className={s.PollAttemptCreatePage__titleCard}
              >
                <PageTitle title={poll.name} showHeading />
                <div className={s.PollAttemptCreatePage__titleInfo}>
                  После получения заполненной анкеты мы оцениваем все ваши
                  ответы и делаем выводы. <br /> Оцените каждый вопрос по шкале
                  от 1 до 10, где 1 это минимальный балл, а 10 это максимальный
                  балл
                </div>
              </Card>
              <Formik
                validate={(values) => {
                  const errors: {
                    answers?: Array<{ value_scale?: string }>;
                  } = {};
                  for (let i = 0; i < values.answers.length; i++) {
                    if (
                      values.answers[i].questionType ===
                      PollQuestionTypesEnum.Scale
                    ) {
                      if (!values.answers[i].value_scale) {
                        if (!errors.answers) {
                          errors.answers = [];
                        }
                        errors['answers'][i] = {
                          value_scale: REQUIRED_MESSAGE
                        };
                      }
                    }
                  }
                  return errors;
                }}
                initialValues={PollsUtils.toForm(poll)}
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <PollForm
                    {...props}
                    submissionLoading={createPollAttempt.loading}
                    poll={poll}
                  />
                )}
              </Formik>
            </>
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
