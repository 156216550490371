import React from 'react';
import clsx from 'clsx';
import InputMask from 'react-input-mask';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import s from './TextField.module.scss';

export interface InputMaskProps {
  mask?: string;
  maskChar?: string;
  alwaysShowMask?: boolean;
  beforeMaskedValueChange?: (...props: any) => void;
}

export enum TextFieldSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export interface TextFieldClasses {
  root?: string;
  label?: string;
  input?: string;
  helperText?: string;
}

export interface TextFieldProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'size'
  > {
  size?: TextFieldSize;
  label?: string;
  errorMessage?: string;
  hintMessage?: string;
  classes?: TextFieldClasses;
  maskProps?: InputMaskProps;
  inputRef?: React.ForwardedRef<HTMLInputElement>;
  onChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextField: React.FC<TextFieldProps> = ({
  size = TextFieldSize.medium,
  type,
  label,
  errorMessage,
  hintMessage,
  classes,
  className,
  maskProps,
  inputRef,
  onChange,
  ...inputProps
}) => {
  const InputComp = maskProps ? InputMask : 'input';

  // const props = {
  //   ref: inputRef,
  //   type: type || 'text',
  //   className: clsx(
  //     s.TextField__input,
  //     s[`TextField__input_${size}`],
  //     s[`TextField__input_${type}`],
  //     {
  //       [s.TextField__input_error]: !!errorMessage
  //     },
  //     classes?.input
  //   ),
  //
  // };

  return (
    <label className={clsx(s.TextField, className, classes?.root)}>
      {label && (
        <span className={clsx(s.TextField__label, classes?.label)}>
          {label}
        </span>
      )}
      <InputComp
        ref={inputRef}
        type={type || 'text'}
        className={clsx(
          s.TextField__input,
          s[`TextField__input_${size}`],
          s[`TextField__input_${type}`],
          {
            [s.TextField__input_error]: !!errorMessage
          },
          classes?.input
        )}
        onChange={(e) => {
          onChange?.(e.target.value, e);
        }}
        {...inputProps}
        {...maskProps}
      />
      <HelpText
        className={classes?.helperText}
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </label>
  );
};
