import React from 'react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import {
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { routeLinks } from '../../routeLinks';
import s from './FeedbackSuccessPage.module.scss';

export default function FeedbackSuccessPage() {
  return (
    <PageLayout isVerticalCentered={true}>
      <LayoutContent innerClassName={s.FeedbackSuccessPage__content}>
        <PageTitle
          className={s.FeedbackSuccessPage__pageTitle}
          title={'Обращение успешно отправлено'}
          // caption={'Какой-то текст'}
          showHeading
          isCentered
        />
        <div className={s.FeedbackSuccessPage__actions}>
          <div>
            <ButtonLink
              className={s.FeedbackSuccessPage__actionButton}
              size={ButtonSize.large}
              to={routeLinks.main.to}
            >
              Вернуться на главную
            </ButtonLink>
          </div>
          <div>
            <ButtonLink
              className={s.FeedbackSuccessPage__actionButton}
              size={ButtonSize.large}
              variant={ButtonVariant.secondary}
              to={routeLinks.feedback.to}
            >
              Создать новое обращение
            </ButtonLink>
          </div>
        </div>
      </LayoutContent>
    </PageLayout>
  );
}
