import { define } from '@proscom/ui-utils';

export interface RouteLinkProps {
  text: string;
  to: string;
}

export const routeLinks = define<Record<string, RouteLinkProps>>()({
  login: {
    text: 'Вход',
    to: '/login'
  },
  main: {
    text: 'Главная',
    to: '/main'
  },
  competences: {
    text: 'Компетенции',
    to: '/competences'
  },
  offers: {
    text: 'Бонусы',
    to: '/offers'
  },
  rate360: {
    text: 'Оценка 360',
    to: '/rate360'
  },
  documents: {
    text: 'Документы',
    to: '/documents'
  },
  about: {
    text: 'Об университете',
    to: '/about'
  },
  newcomers: {
    text: 'Для новых сотрудников',
    to: '/newcomers'
  },
  faq: {
    text: 'Вопросы и ответы',
    to: '/faq'
  },
  articles: {
    text: 'Новости',
    to: '/articles'
  },
  polls: {
    text: 'Опросы',
    to: '/polls'
  },
  notices: {
    text: 'Объявления',
    to: '/adverts'
  },
  community: {
    text: 'Сообщества',
    to: '/community'
  },
  calendar: {
    text: 'Календарь МФТИ',
    to: '/calendar'
  },
  courses: {
    text: 'Курсы',
    to: '/courses'
  },
  internships: {
    text: 'Стажировки',
    to: '/internships'
  },
  contests: {
    text: 'Конкурсы',
    to: '/contests'
  },
  reserve: {
    text: 'Кадровый резерв',
    to: '/reserve'
  },
  events: {
    text: 'События',
    to: '/events'
  },
  projects: {
    text: 'Проекты',
    to: '/projects'
  },
  systemLinks: {
    text: 'Сервисы МФТИ',
    to: '/systemLinks'
  },
  users: {
    text: 'Сотрудники',
    to: '/users'
  },
  manuals: {
    text: 'Инструкции',
    to: '/guides'
  },
  profile: {
    text: 'Профиль',
    to: '/profile'
  },
  settings: {
    text: 'Настройки',
    to: '/settings'
  },
  onboarding: {
    text: 'О вузе',
    to: '/'
  },
  feedback: {
    text: 'Обратная связь',
    to: '/feedback'
  },
  vacation: {
    text: 'Заявление на отпуск',
    to: '/vacation'
  }
});
