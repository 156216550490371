import { Form, FormikProps } from 'formik';
import React from 'react';
import { Button } from '../../../common/components/ui/Button';
import { CardStyle } from '../../../common/components/ui/Card/Card';
import { FormPointScale } from '../../../common/components/ui/Form/FormPointScale/FormPointScale';
import {
  FormSection,
  FormSectionVariant
} from '../../../common/components/ui/Form/FormSection/FormSection';
import { FormTextArea } from '../../../common/components/ui/Form/FormTextArea/FormTextArea';
import { ReactComponent as ArrowRightIcon } from '../../../assets/img/icons/ArrowRight.svg';
import { PollQuestionTypesEnum, PollType } from '../../../graphql/types';
import { PollFormValues } from '../../../store/polls/PollsUtils';
import s from './PollForm.module.scss';

export interface PollFormProps extends FormikProps<PollFormValues> {
  submissionLoading: boolean;
  poll: PollType;
}

export const PollForm: React.FC<PollFormProps> = ({
  poll,
  submissionLoading
}) => {
  return (
    <Form>
      {poll.questions?.map((question, iQuestion) => (
        <FormSection key={iQuestion} variant={FormSectionVariant.small}>
          {question.type === PollQuestionTypesEnum.Scale ? (
            <FormPointScale
              label={question.content}
              name={`answers[${iQuestion}].value_scale`}
              cardStyle={CardStyle.bordered}
              minValue={1}
              maxValue={10}
            />
          ) : (
            <FormTextArea
              label={question.content}
              name={`answers[${iQuestion}].value_text`}
              classes={{ root: s.PollForm__textarea }}
            />
          )}
        </FormSection>
      ))}
      <div className={s.PollForm__actions}>
        <Button
          type={'submit'}
          iconRight={<ArrowRightIcon />}
          loading={submissionLoading}
        >
          Отправить
        </Button>
      </div>
    </Form>
  );
};
