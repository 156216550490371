import React from 'react';
import clsx from 'clsx';
import { IContentImage } from '../../Form/FormContentEditor/Content';
import { SpecificContentBlockProps } from '../types';
import { ImageBlockPreview } from './ImageBlockPreview';
import s from '../ContentBlock.module.scss';

export const ImageBlock: React.FC<SpecificContentBlockProps<IContentImage>> = ({
  data,
  className,
  meta
}) => {
  const { fileId, imageCaption } = data;

  return (
    <div className={clsx(s.ImageBlock, className)}>
      <ImageBlockPreview
        fileId={fileId}
        fileType={meta?.fileType}
        placeholderProps={{
          iconProps: {
            boxSize: 64
          }
        }}
      />

      {imageCaption && (
        <div className={s.ImageBlock__imageCaption}>{imageCaption}</div>
      )}
    </div>
  );
};
