import React from 'react';
import {
  UserPageBlock,
  UserPageBlockVariant
} from '../../UserBlocks/UserPageBlock/UserPageBlock';
import { LayoutGrid } from '../../../Layout/Layout';
import { FormSection } from '../../Form/FormSection/FormSection';
import { FormTextField } from '../../Form/FormTextField/FormTextField';
import { TextFieldSize } from '../../TextField/TextField';
import { userFormNames } from '../../../../../store/users/UserConstants';

export interface EditSocialsProps {}

export const EditSocials: React.FC<EditSocialsProps> = () => {
  return (
    <UserPageBlock
      variant={UserPageBlockVariant.small}
      title={'Социальные сети'}
    >
      <LayoutGrid>
        <LayoutGrid.GridItem cols={6}>
          <FormSection>
            <FormTextField
              size={TextFieldSize.large}
              name={userFormNames.vk}
              label={'Vkontakte'}
              placeholder={'https://vk.com/...'}
            />
          </FormSection>
        </LayoutGrid.GridItem>
        {/*<LayoutGrid.GridItem cols={6}>*/}
        {/*  <FormSection>*/}
        {/*    <FormTextField*/}
        {/*      size={TextFieldSize.large}*/}
        {/*      name={userFormNames.facebook}*/}
        {/*      label={'Facebook'}*/}
        {/*      placeholder={'https://facebook.com/...'}*/}
        {/*    />*/}
        {/*  </FormSection>*/}
        {/*</LayoutGrid.GridItem>*/}
        {/*<LayoutGrid.GridItem cols={6}>*/}
        {/*  <FormSection>*/}
        {/*    <FormTextField*/}
        {/*      size={TextFieldSize.large}*/}
        {/*      name={userFormNames.instagram}*/}
        {/*      label={'Instagram'}*/}
        {/*      placeholder={'https://instagram.com/...'}*/}
        {/*    />*/}
        {/*  </FormSection>*/}
        {/*</LayoutGrid.GridItem>*/}
      </LayoutGrid>
    </UserPageBlock>
  );
};
