import React from 'react';
import clsx from 'clsx';
import s from './Filter.module.scss';

export interface FilterClasses {
  root?: string;
  label?: string;
  content?: string;
}

export interface FilterProps {
  className?: string;
  classes?: FilterClasses;
  label?: string;
  children?: React.ReactNode;
}

export const Filter: React.FC<FilterProps> = ({
  className,
  classes,
  label,
  children
}) => {
  return (
    <div className={clsx(s.Filter, className, classes?.root)}>
      {label && (
        <div className={clsx(s.Filter__label, classes?.label)}>{label}</div>
      )}
      <div className={clsx(s.Filter__content, classes?.content)}>
        {children}
      </div>
    </div>
  );
};
