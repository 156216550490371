import React from 'react';
import seedrandom from 'seedrandom';
import { RectanglesPattern } from './RectanglesPattern/RectanglesPattern';
import { CirclesPattern } from './CirclesPattern/CirclesPattern';
import { TrianglesPattern } from './TrianglesPattern/TrianglesPattern';

export enum Pattern {
  circles = 'circles',
  rectangles = 'rectangles',
  triangles = 'triangles'
}

export const PatternsComponent = {
  [Pattern.rectangles]: <RectanglesPattern />,
  [Pattern.circles]: <CirclesPattern />,
  [Pattern.triangles]: <TrianglesPattern />
};

export function getCardPattern(cardId: string, patterns: Array<Pattern>) {
  const iPattern = Math.floor(seedrandom(cardId)() * patterns.length);
  return patterns[iPattern];
}
