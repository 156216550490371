import { IUseGraphqlQueryOptions } from '@proscom/prostore-apollo-react';
import {
  ArticleFilter,
  ArticlePageInput,
  ArticlesPageType,
  PaginationInput
} from '../types';
import { QUERY_GET_ARTICLES_PAGE } from '../queries/articles';
import { useAuthGraphqlQuery } from '../../common/hooks/utils/useAuthGraphqlQuery';
import { ONE_PAGE_ITEMS } from '../../constants';

export interface UseArticlesPageVars {
  input: ArticlePageInput;
}

export interface UseArticlesPageArgs {
  filter?: ArticleFilter;
  pagination?: PaginationInput;
}

const queryOptions: IUseGraphqlQueryOptions<
  UseArticlesPageVars,
  ArticlesPageType
> = {
  query: QUERY_GET_ARTICLES_PAGE,
  mapData: (result) => result.articlesPage
};

export function useArticlesPage({ filter, pagination }: UseArticlesPageArgs) {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: {
      input: {
        filter,
        pagination: {
          onePage: ONE_PAGE_ITEMS,
          ...pagination
        }
      }
    }
  });
}
