import { Maybe, UserSettingsType } from '../../graphql/types';

export class UserSettingsUtils {
  static toForm(data?: Maybe<UserSettingsType>) {
    return {
      article_notifications: data?.article_notifications || [],
      poll_notifications: data?.poll_notifications || [],
      course_notifications: data?.course_notifications || [],
      event_notifications: data?.event_notifications || [],
      internship_notifications: data?.internship_notifications || [],
      offer_notifications: data?.offer_notifications || [],
      project_notifications: data?.project_notifications || []
    };
  }

  static fromForm(data: UserSettingsType) {
    return data;
  }
}
