import { IUseGraphqlQueryOptions } from '@proscom/prostore-apollo-react';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import {
  PaginationInput,
  PollAttemptsFilter,
  PollAttemptsPage,
  PollAttemptsPageInput
} from '../../types';
import { QUERY_GET_POLL_ATTEMPTS_PAGE } from '../../queries/polls';
import { DefaultPaginationParams } from '../../../constants';

export interface UsePollAttemptsPageVars {
  input: PollAttemptsPageInput;
}

export interface UsePollAttemptsPageArgs {
  filter?: PollAttemptsFilter;
  pagination?: PaginationInput;
}

const queryOptions: IUseGraphqlQueryOptions<
  UsePollAttemptsPageVars,
  PollAttemptsPage
> = {
  query: QUERY_GET_POLL_ATTEMPTS_PAGE,
  mapData: (result) => result.pollAttemptsPage
};

export const usePollAttemptsPage = ({
  filter,
  pagination
}: UsePollAttemptsPageArgs) => {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: {
      input: {
        filter,
        pagination: {
          ...DefaultPaginationParams,
          ...pagination
        }
      }
    }
  });
};
