import { IUseGraphqlQueryOptions } from '@proscom/prostore-apollo-react';
import {
  CoursesFilter,
  CourseSubjectsPageInput,
  CourseSubjectsPageType
} from '../../types';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import { QUERY_GET_COURSE_SUBJECTS_PAGE } from '../../queries/courses';
import { ONE_PAGE_ITEMS } from '../../../constants';

export interface CourseSubjectsPageFilter {
  input: CourseSubjectsPageInput;
  coursesFilter?: CoursesFilter;
  coursesLimit?: number;
}

const queryOptions: IUseGraphqlQueryOptions<
  CourseSubjectsPageFilter,
  CourseSubjectsPageType
> = {
  query: QUERY_GET_COURSE_SUBJECTS_PAGE,
  mapData: (result) => result.courseSubjectsPage
};

export function useCourseSubjectsPage({
  input,
  coursesFilter,
  coursesLimit
}: CourseSubjectsPageFilter) {
  const { filter, pagination } = input;
  return useAuthGraphqlQuery({
    queryOptions,
    variables: {
      input: {
        filter,
        pagination: {
          onePage: ONE_PAGE_ITEMS,
          ...pagination
        }
      },
      coursesFilter,
      coursesLimit
    }
  });
}
