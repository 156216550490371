import React from 'react';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { BaseLink } from '../../../common/components/ui/BaseLink/BaseLink';
import { Icon } from '../../../common/components/ui/Icon/Icon';
import { ReactComponent as IconArrowLink } from '../../../assets/img/icons/ArrowLink.svg';
import s from './PhysTech.module.scss';

const items: { title: string; link: string; text: React.ReactNode }[] = [
  {
    title: 'ФРКТ',
    link: 'https://mipt.ru/education/departments/frkt/',
    text: <>Физтех-школа Радиотехники и компьютерных технологий</>
  },
  {
    title: 'ЛФИ',
    link: 'https://mipt.ru/education/departments/lpr/',
    text: <>Физтех-школа Физики и ислледований им. Ландау</>
  },
  {
    title: 'ФАКТ',
    link: 'http://falt.tilda.ws/',
    text: <>Физтех-школа Аэрокосмических технологий</>
  },
  {
    title: 'ФЭФМ',
    link: 'https://mipt.ru/education/departments/fefm/',
    text: <>Физтех-школа Электроники, фотоники и молекуляронй физики</>
  },
  {
    title: 'ФПМИ',
    link: 'https://mipt.ru/education/departments/fpmi/',
    text: <>Физтех-школа прикладной математики и информатики</>
  },
  {
    title: 'ФБМФ',
    link: 'https://mipt.ru/education/departments/fbmf/',
    text: <>Физтех-школа биологической и медицинской физики</>
  },
  {
    title: 'ИНБИКСТ',
    link: 'https://mipt.ru/dnbic/',
    text: (
      <>
        Институт нано-, био-, информационных, когнитивных и социогуманитарных
        наук и технологий
      </>
    )
  },
  {
    title: 'ФБВТ',
    link: 'https://bs.mipt.ru/master',
    text: <>Физтех-школа бизнеса и высоких технологий</>
  },
  {
    title: 'ВШПИ',
    link: 'https://hsse.mipt.ru/',
    text: <>Высшая школа программной инженерии</>
  }
];

export const PhysTech = () => {
  return (
    <LayoutContent id={'phystech'} className={s.PhysTech}>
      <Title variant={TitleVariant.h2} className={s.PhysTech__title}>
        Физтех-школы МФТИ
      </Title>

      <div className={s.PhysTech__grid}>
        {items.map((item, iItem) => (
          <BaseLink key={iItem} className={s.PhysTechCard} href={item.link}>
            <div className={s.PhysTechCard__head}>
              <Title
                variant={TitleVariant.h3}
                className={s.PhysTechCard__title}
              >
                {item.title}
              </Title>
              <div className={s.PhysTechCard__text}>{item.text}</div>
            </div>

            <div className={s.PhysTechCard__ext}>
              <Icon
                className={s.PhysTechCard__icon}
                icon={IconArrowLink}
                boxSize={18}
              />
            </div>
          </BaseLink>
        ))}
      </div>
    </LayoutContent>
  );
};
