import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { lowerFirst } from 'lodash-es';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import {
  Breadcrumbs,
  getBreadcrumbItemEntity,
  IBreadcrumbsEntity,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { routeLinks } from '../../routeLinks';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { UserPermission } from '../../../utils/permissions';
import { getInternshipsPageBreadcrumbs } from '../list/InternshipsPage';
import { Internships } from '../../../store/internships/Internships';
import { useInternship } from '../../../graphql/hooks/internships/useInternship';
import {
  Button,
  ButtonBaseColor,
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import {
  TextLink,
  TextLinkIconPosition
} from '../../../common/components/ui/TextLink/TextLink';
import { ReactComponent as IconDoc } from '../../../assets/img/icons/Document.svg';
import { TextBlock } from '../../../common/components/ui/ContentBlock/TextBlock/TextBlock';
import { useFileSrc } from '../../../common/hooks/useFileSrc';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import {
  internshipContestLabelMap,
  internshipDurationLabelMap,
  internshipFinancialConditionsLabelMap,
  internshipLocationViewLabelMap,
  internshipPurposeLabelMap,
  internshipTraineeCategoryLabelMap
} from '../../../store/internships/InternshipTest';
import { Banner } from './Banner/Banner';
import s from './InternshipPage.module.scss';

export interface InternshipPageProps
  extends RouteComponentProps<{ internshipId: string }> {
  history: AppHistoryType;
}

export function getInternshipPageBreadcrumbs({
  internship
}: {
  internship: IBreadcrumbsEntity;
}): IBreadcrumbsItem[] {
  return [
    ...getInternshipsPageBreadcrumbs(),
    getBreadcrumbItemEntity(routeLinks.internships.to, internship, 50)
  ];
}

export default function InternshipPage({
  history,
  match
}: InternshipPageProps) {
  const { internshipId } = match.params;
  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.InternshipCrud);

  const deleteInternship = useGraphqlMutation(Internships.deleteInternship);
  const deleteInternshipRun = deleteInternship.run;
  const deleteInternshipLoading = deleteInternship.loading;

  const internshipQuery = useInternship(internshipId);
  const internship = internshipQuery.state.data;
  const conditions = internship?.conditions;
  const instructionLink = useFileSrc(
    internship?.manual_file_id,
    FileTypeEnum.internship_manual
  );

  const breadcrumbs = useMemo(
    () =>
      getInternshipPageBreadcrumbs({
        internship: {
          id: internshipId,
          name: internship?.name
        }
      }),
    [internshipId, internship]
  );

  const handleDeleteClick = useCallback(() => {
    if (window.confirm('Вы действительно хотите удалить стажировку?')) {
      deleteInternshipRun({ variables: { id: internshipId } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }

          toast.success('Стажировка удалена');
          history.replace(routeLinks.internships.to);
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(err, 'Произошла ошибка при удалении стажировки');
        });
    }
  }, [deleteInternshipRun, internshipId, history]);

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        {queryLoader(internshipQuery) ||
          (!internship ? (
            <NoData />
          ) : (
            <>
              <LayoutGrid className={s.Head}>
                <LayoutGrid.GridItem cols={10}>
                  <Banner {...internship} />
                </LayoutGrid.GridItem>

                <LayoutGrid.GridItem cols={2}>
                  {canEdit && (
                    <div className={s.Actions}>
                      <ButtonLink
                        className={s.ActionButton}
                        variant={ButtonVariant.secondary}
                        size={ButtonSize.small}
                        to={`${routeLinks.internships.to}/${internshipId}/edit`}
                      >
                        Редактировать
                      </ButtonLink>
                      <Button
                        className={s.ActionButton}
                        variant={ButtonVariant.secondary}
                        size={ButtonSize.small}
                        baseColor={ButtonBaseColor.red}
                        onClick={handleDeleteClick}
                        loading={deleteInternshipLoading}
                      >
                        Удалить
                      </Button>
                    </div>
                  )}
                </LayoutGrid.GridItem>
              </LayoutGrid>

              <LayoutGrid>
                <LayoutGrid.GridItem cols={10}>
                  {conditions && (
                    <div className={s.Conditions}>
                      <Title
                        className={s.Conditions__title}
                        variant={TitleVariant.h2}
                        title={'Условия'}
                      />
                      <ul>
                        {internship.financial_conditions && (
                          <li>
                            {
                              internshipFinancialConditionsLabelMap[
                                internship.financial_conditions
                              ]
                            }
                          </li>
                        )}
                        {internship.location && (
                          <li>
                            {
                              internshipLocationViewLabelMap[
                                internship.location
                              ]
                            }
                          </li>
                        )}
                        {internship.duration && (
                          <li>
                            {internshipDurationLabelMap[internship.duration]}
                          </li>
                        )}
                        {internship.contest && (
                          <li>
                            {internshipContestLabelMap[internship.contest]}
                          </li>
                        )}
                        {internship.purpose && (
                          <li>
                            Цель:{' '}
                            {lowerFirst(
                              internshipPurposeLabelMap[internship.purpose]
                            )}
                          </li>
                        )}
                        {internship.trainee_category && (
                          <li>
                            Категория стажирующихся:{' '}
                            {lowerFirst(
                              internshipTraineeCategoryLabelMap[
                                internship.trainee_category
                              ]
                            )}
                          </li>
                        )}
                      </ul>
                      <TextBlock data={{ content: conditions }} />
                    </div>
                  )}

                  {instructionLink && (
                    <div className={s.Instructions}>
                      <TextLink
                        href={instructionLink}
                        icon={IconDoc}
                        iconPosition={TextLinkIconPosition.left}
                        iconProps={{ iconSize: 16 }}
                      >
                        Инструкция по подаче заявки
                      </TextLink>
                    </div>
                  )}
                </LayoutGrid.GridItem>
              </LayoutGrid>
            </>
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
