import { IUseGraphqlWatchQueryOptions } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { InternshipType } from '../../types';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import { QUERY_GET_INTERNSHIP } from '../../queries/internships';

const queryOptions: IUseGraphqlWatchQueryOptions<
  { id: string } | null,
  InternshipType | null
> = {
  query: QUERY_GET_INTERNSHIP,
  skipQuery: skipIfNull(null),
  mapData: (result) => result.internship
};

export function useInternship(id?: string) {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: id
      ? {
          id
        }
      : null
  });
}
