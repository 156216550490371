import React from 'react';
import { ResponsiveObject } from 'react-slick';
import { EMDASH } from '@proscom/ui-utils';
import { PublicationType } from '../../../../../graphql/types';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { DATE_FORMAT_FULL } from '../../../../../utils/date/date';
import { Card } from '../../Card/Card';
import { TextLink } from '../../TextLink/TextLink';
import { CardPattern } from '../../Card/patterns/CardPattern/CardPattern';
import { FigureType, FigureVariant } from '../../Figures/types';
import { SliderConvertible } from '../../Slider/Slider';
import { LayoutGrid } from '../../../Layout/Layout';
import s from './UserPublish.module.scss';

interface PublishCardProps extends PublicationType {}

const PublishCard: React.FC<PublishCardProps> = ({ name, date, link }) => {
  const formattedDate = useFormatDate(date, DATE_FORMAT_FULL);

  return (
    <Card
      className={s.PublishCard}
      classes={{
        title: s.PublishCard__title
      }}
      title={name || EMDASH}
      actions={
        link && (
          <TextLink
            href={link} //todo: ссылка на публикацию внутренняя или внешняя?
            iconProps={{
              boxSize: 12
            }}
            noUnderline
            showArrow
          >
            Перейти
          </TextLink>
        )
      }
      extContent={
        <CardPattern
          className={s.PublishCardPattern}
          figures={[
            {
              className: s.PublishCardPattern_front,
              type: FigureType.rectangle,
              variant: FigureVariant.dark,
              size: 195
            },
            {
              className: s.PublishCardPattern_back,
              type: FigureType.rectangle,
              variant: FigureVariant.light,
              size: 195
            }
          ]}
        />
      }
    >
      <div className={s.PublishCard__date}>{formattedDate}</div>
    </Card>
  );
};

const sliderResponsive: ResponsiveObject[] = [
  {
    breakpoint: 1050,
    settings: {
      slidesToShow: 2
    }
  },
  {
    breakpoint: 750,
    settings: {
      slidesToShow: 1
    }
  }
];

export interface UserPublishProps {
  publications?: PublicationType[] | null;
}

export const UserPublish: React.FC<UserPublishProps> = ({ publications }) => {
  return (
    <div className={s.UserPublish}>
      {publications && publications.length > 0 ? (
        <SliderConvertible
          config={{
            slidesToShow: 3,
            arrows: true,
            responsive: sliderResponsive
          }}
          convertedContent={
            <LayoutGrid>
              {publications.map((item, iItem) => (
                <LayoutGrid.GridItem key={iItem} cols={4}>
                  <PublishCard {...item} />
                </LayoutGrid.GridItem>
              ))}
            </LayoutGrid>
          }
        >
          {publications.map((item, iItem) => (
            <div key={iItem}>
              <PublishCard {...item} />
            </div>
          ))}
        </SliderConvertible>
      ) : (
        EMDASH
      )}
    </div>
  );
};
