import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { SpecificContentBlockEditableProps } from '../types';
import { TextField } from '../../TextField/TextField';
import { IContentVideo } from '../../Form/FormContentEditor/Content';
import { URL_MESSAGE } from '../../../../../store/validationSchema';
import { VideoBlockPreview } from './VideoBlockPreview';
import s from '../ContentBlock.module.scss';

export const VideoBlockEditable: React.FC<
  SpecificContentBlockEditableProps<IContentVideo>
> = ({ data, onChange, onError, className }) => {
  const [error, setError] = useState<string | undefined>();

  const handleError = useCallback(
    (error) => {
      setError(error ? URL_MESSAGE : undefined);
      onError?.(error ? URL_MESSAGE : undefined);
    },
    [onError]
  );

  const handleChange = useCallback(
    (content) => {
      setError(undefined);
      onChange({ ...data, url: content });
    },
    [data, onChange]
  );

  return (
    <div className={clsx(s.VideoBlock, className)}>
      <div className={s.VideoBlock__input}>
        <TextField
          value={data.url || ''}
          errorMessage={error}
          onChange={handleChange}
        />
      </div>
      {data.url && (
        <div className={s.VideoBlock__content}>
          <VideoBlockPreview url={data.url} onError={handleError} />
        </div>
      )}
    </div>
  );
};
