import React, { useMemo } from 'react';
import { joinNonEmpty } from '@proscom/ui-utils';
import { Card } from '../../../../common/components/ui/Card/Card';
import { UserUtils } from '../../../../store/users/UserUtils';
import { useFormatDate } from '../../../../common/hooks/useFormatDate';
import { DATETIME_FORMAT_SHORT } from '../../../../utils/date/date';
import {
  VacationScheduleItemType,
  VacationScheduleType
} from '../../../../graphql/types';
import s from './VacationSchedules.module.scss';

interface VacationProps extends VacationScheduleItemType {}

const Vacation = ({ start, end, days }: VacationProps) => {
  const startDate = useFormatDate(start, DATETIME_FORMAT_SHORT);
  const endDate = useFormatDate(end, DATETIME_FORMAT_SHORT);

  return (
    <div className={s.VacationScheduleCard__grid}>
      <div className={s.VacationScheduleCard__gridTitle}>
        <span>Календарных дней:</span>
      </div>
      <div className={s.VacationScheduleCard__gridContent}>
        <div className={s.Vacation__content}>
          {days || 0}

          <div className={s.Vacation__dates}>
            с {startDate} по {endDate}
          </div>
        </div>
      </div>
    </div>
  );
};

export interface VacationScheduleCardProps extends VacationScheduleType {}

export const VacationScheduleCard = ({
  position,
  assigned_days,
  unassigned_days,
  items
}: VacationScheduleCardProps) => {
  const positionStr = useMemo(() => {
    const posName = position?.c_position_name;
    const unitName = UserUtils.getPositionUnit(position);
    return joinNonEmpty([posName, unitName && `(${unitName})`], ' ');
  }, [position]);

  return (
    <Card className={s.VacationScheduleCard}>
      <div className={s.VacationScheduleCard__grid}>
        <div className={s.VacationScheduleCard__gridTitle}>Должность:</div>
        <div className={s.VacationScheduleCard__gridContent}>{positionStr}</div>
      </div>

      <div className={s.VacationScheduleCard__grid}>
        <div className={s.VacationScheduleCard__gridTitle}>
          Всего календарных дней отпуска, запланированных в&nbsp;графике:
        </div>
        <div className={s.VacationScheduleCard__gridContent}>
          {assigned_days}
        </div>
      </div>

      {!!items?.length && (
        <>
          <div className={s.VacationScheduleCard__vacationsTitle}>
            <span>В том числе</span>
          </div>

          {items.map((item, iItem) => (
            <div key={iItem} className={s.VacationScheduleCard__vacation}>
              <Vacation {...item} />
            </div>
          ))}
        </>
      )}

      <div className={s.VacationScheduleCard__grid}>
        <div className={s.VacationScheduleCard__gridTitle}>
          <span>Дополнительно по заявлению можно оформить:</span>
        </div>
        <div className={s.VacationScheduleCard__gridContent}>
          {unassigned_days}
        </div>
      </div>
    </Card>
  );
};
