import React from 'react';
import clsx from 'clsx';
import {
  LandingGrid,
  LayoutContent
} from '../../../common/components/Layout/Layout';
import { Tag } from '../../../common/components/ui/Tag/Tag';
import s from './Welcome.module.scss';

export const Welcome = () => {
  return (
    <LayoutContent className={s.Welcome}>
      <div className={s.Welcome__box}>
        <LandingGrid className={s.Welcome__grid}>
          <div className={s.Welcome__content}>
            <div className={s.Welcome__tags}>
              <Tag className={s.Welcome__tag}>девиз физтеха</Tag>
            </div>
            <div className={s.Welcome__title}>
              Sapere aude!
              <br />
              <div style={{ opacity: 0.5 }}>(Дерзай знать!)</div>
            </div>
          </div>

          <div className={s.Welcome__content}>
            <div className={s.Welcome__text}>
              Здесь обучают фундаментальной и прикладной физике, математике,
              информатике, химии, биологии, компьютерным технологиям и другим
              естественным и точным наукам.
            </div>
          </div>
        </LandingGrid>

        <div className={clsx(s.Welcome__quot, s.Welcome__quot_left)}>
          &rdquo;
        </div>
        <div className={clsx(s.Welcome__quot, s.Welcome__quot_right)}>
          &ldquo;
        </div>
      </div>
    </LayoutContent>
  );
};
