import {
  ProjectCreateInput,
  ProjectParticipantInput,
  ProjectParticipantType,
  ProjectStatusEnum,
  ProjectType,
  ProjectUpdateInput,
  UserType
} from '../../graphql/types';

export interface ProjectProps {
  id: string;
  status?: ProjectStatusEnum;
  name: string;
  description: string;
  head_user?: UserType;
  head_user_id: string;
  participants?: ProjectParticipantType[] | ProjectParticipantInput[];
}

export class ProjectUtils {
  static toForm(data?: ProjectType): ProjectProps {
    return {
      id: data?.id || '',
      status: data?.status || ProjectStatusEnum.Active,
      name: data?.name || '',
      description: data?.description || '',
      head_user: data?.head_user || undefined,
      head_user_id: data?.head_user_id || '',
      participants: data?.participants || undefined
    };
  }

  static fromCreateForm(formData: ProjectProps): ProjectCreateInput {
    return {
      status: formData.status!,
      name: formData.name,
      description: formData.description,
      head_user_id: formData.head_user_id,
      participants: formData.participants?.map((p) => ({ user_id: p.user_id }))
    };
  }

  static fromUpdateForm(formData: ProjectProps): ProjectUpdateInput {
    return {
      id: formData.id,
      status: formData.status,
      name: formData.name,
      description: formData.description,
      head_user_id: formData.head_user_id,
      participants: formData.participants?.map((p) => ({ user_id: p.user_id }))
    };
  }
}
