import { IUseGraphqlQueryOptions } from '@proscom/prostore-apollo-react';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import {
  OfferCategoriesFilter,
  OfferCategoriesPageInput,
  OfferCategoriesPageType,
  PaginationInput
} from '../../types';
import { QUERY_GET_OFFER_CATEGORIES_PAGE } from '../../queries/offers';

export interface UseOfferCategoriesPageVars {
  input: OfferCategoriesPageInput;
}

export interface UseOfferCategoriesPageArgs {
  filter?: OfferCategoriesFilter;
  pagination?: PaginationInput;
}

const queryOptions: IUseGraphqlQueryOptions<
  UseOfferCategoriesPageVars,
  OfferCategoriesPageType
> = {
  query: QUERY_GET_OFFER_CATEGORIES_PAGE,
  mapData: (result) => result.offerCategoriesPage
};

export const useOfferCategoriesPage = ({
  filter,
  pagination
}: UseOfferCategoriesPageArgs) => {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: {
      input: {
        filter,
        pagination: {
          ...pagination
        }
      }
    }
  });
};
