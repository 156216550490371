import React, { useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { ProjectType } from '../../../graphql/types';
import {
  ProjectProps,
  ProjectUtils
} from '../../../store/projects/ProjectUtils';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { FormSection } from '../../../common/components/ui/Form/FormSection/FormSection';
import { FormTextField } from '../../../common/components/ui/Form/FormTextField/FormTextField';
import { FormTextEditor } from '../../../common/components/ui/Form/FormTextEditor/FormTextEditor';
import { FormSelect } from '../../../common/components/ui/Form/FormSelect/FormSelect';
import { TitleVariant } from '../../../common/components/ui/Title/Title';
import {
  ProjectsAdminValidationSchema,
  ProjectsValidationSchema
} from '../../../store/validationSchema';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { ProjectDirector } from '../ProjectDirector/ProjectDirector';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import NoAccessPage from '../../noAccess/NoAccessPage';
import { statusSelectOptions } from '../constants';
import { UserPermission } from '../../../utils/permissions';
import { ProjectFormParticipants } from './ProjectFormParticipants/ProjectFormParticipants';
import { ProjectFormDirector } from './ProjectFormDirector/ProjectFormDirector';
import s from './ProjectForm.module.scss';

export interface ProjectFormProps {
  project?: ProjectType;
  onSubmit: (data: ProjectProps, helpers: FormikHelpers<ProjectProps>) => void;
  onCancel: () => void;
  submissionLoading?: boolean;
}

export const ProjectForm: React.FC<ProjectFormProps> = ({
  project,
  onSubmit,
  onCancel,
  submissionLoading
}) => {
  const { user } = useCurrentUser();
  const canEditAll = useHasPermission(user, UserPermission.ProjectCrud);
  const canEditHeadUser = canEditAll;

  const head_user = project?.head_user;
  const head_user_id = project?.head_user_id;

  const isProjectOwner = user?.id === head_user_id;
  const canEditProject = canEditAll || isProjectOwner;

  const initialValues = useMemo(() => {
    return ProjectUtils.toForm(project);
  }, [project]);

  if (project && !canEditProject) {
    return <NoAccessPage />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        canEditHeadUser
          ? ProjectsAdminValidationSchema
          : ProjectsValidationSchema
      }
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form>
            <div className={s.ProjectForm__head}>
              <FormSection>
                <FormTextField name={'name'} label={'Название проекта'} />
              </FormSection>

              <FormSection>
                <FormTextEditor
                  name={'description'}
                  label={'Описание проекта'}
                />
              </FormSection>

              <FormSection
                title={'Статус проекта'}
                titleVariant={TitleVariant.h3}
              >
                <FormSelect
                  className={s.ProjectForm__statusSelect}
                  name={'status'}
                  options={statusSelectOptions}
                />
              </FormSection>

              {(canEditHeadUser || head_user) && (
                <FormSection
                  title={'Руководитель проекта'}
                  titleVariant={TitleVariant.h3}
                >
                  {canEditHeadUser && (
                    <ProjectFormDirector
                      name={'head_user_id'}
                      values={values}
                    />
                  )}
                  {!canEditHeadUser && head_user && (
                    <ProjectDirector
                      className={s.ProjectForm__director}
                      user={head_user}
                    />
                  )}
                </FormSection>
              )}
            </div>

            <div className={s.ProjectForm__participants}>
              <ProjectFormParticipants name={'participants'} values={values} />
            </div>

            <div className={s.ProjectForm__actions}>
              <Button
                className={s.ProjectForm__actionButton}
                size={ButtonSize.large}
                variant={ButtonVariant.secondary}
                onClick={onCancel}
              >
                Отменить всё
              </Button>
              <Button
                className={s.ProjectForm__actionButton}
                size={ButtonSize.large}
                type={'submit'}
                loading={submissionLoading}
              >
                Сохранить изменения
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
