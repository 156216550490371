import { IUseGraphqlWatchQueryOptions } from '@proscom/prostore-apollo-react';
import { CampusFilter, CampusType } from '../../types';
import { QUERY_GET_CAMPUSES } from '../../queries/campuses';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';

const queryOptions: IUseGraphqlWatchQueryOptions<
  { filter: CampusFilter },
  CampusType[]
> = {
  query: QUERY_GET_CAMPUSES,
  mapData: (result) => result.campuses
};

export function useCampuses(filter?: CampusFilter) {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: filter && {
      filter
    }
  });
}
