import { Routes, RouteType } from '../../common/components/utils/Routes';
import { RequireAuth } from '../RequireAuth';
import NotFoundPage from '../notFound/NotFoundPage';
import ProfilePage from './index/ProfilePage';
import ProfileEditPage from './edit/ProfileEditPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: RequireAuth(ProfilePage)
  },
  {
    path: '/edit',
    exact: true,
    component: RequireAuth(ProfileEditPage)
  },
  {
    component: NotFoundPage
  }
];

const ProfileRoutes = Routes(routes);

export default ProfileRoutes;
