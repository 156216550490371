import { Routes, RouteType } from '../../common/components/utils/Routes';
import { RequireAuth } from '../RequireAuth';
import NotFoundPage from '../notFound/NotFoundPage';
import { hasPermission, UserPermission } from '../../utils/permissions';
import CoursesPage from './list/CoursesPage';
import CoursePage from './index/CoursePage';
import CourseEditPage from './edit/CourseEditPage';
import CourseCreatePage from './create/CourseCreatePage';
import { CoursesTestPage } from './test/CoursesTestPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: RequireAuth(CoursesPage)
  },
  {
    path: '/create',
    exact: true,
    component: RequireAuth(CourseCreatePage, (user) =>
      hasPermission(user, UserPermission.CourseCrud)
    )
  },
  {
    path: '/test',
    exact: true,
    component: RequireAuth(CoursesTestPage)
  },
  {
    path: '/:courseId',
    exact: true,
    component: RequireAuth(CoursePage)
  },
  {
    path: '/:courseId/edit',
    exact: true,
    component: RequireAuth(CourseEditPage, (user) =>
      hasPermission(user, UserPermission.CourseCrud)
    )
  },
  {
    component: NotFoundPage
  }
];

const CoursesRoutes = Routes(routes);

export default CoursesRoutes;
