import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  ImagePlaceholder,
  ImagePlaceholderProps
} from '../ImagePlaceholder/ImagePlaceholder';
import { Loader, LoaderPositions, LoaderProps } from '../Loader/Loader';
import { DivPropsWithoutRef } from '../../../../utils/reactTypes';
import s from './ImagePreview.module.scss';

export interface ImagePreviewClasses {
  root?: string;
  placeholder?: string;
  image?: string;
  backfaceImage?: string;
  loader?: string;
}

export interface ImagePreviewProps extends DivPropsWithoutRef {
  className?: string;
  classes?: ImagePreviewClasses;
  imageSrc?: string | null;
  showLoader?: boolean;
  showPlaceholder?: boolean;
  showBackfaceImage?: boolean;
  placeholderProps?: Omit<ImagePlaceholderProps, 'className'>;
  loaderProps?: Omit<LoaderProps, 'className' | 'position'>;
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({
  className,
  classes,
  imageSrc,
  showLoader = true,
  showPlaceholder = true,
  showBackfaceImage,
  placeholderProps,
  loaderProps,
  ...props
}) => {
  const [loadState, setLoadState] = useState({
    loaded: false,
    error: false
  });
  const loaded = loadState.loaded;
  const error = loadState.error;

  const handleImgLoaded = useCallback(() => {
    setLoadState({
      loaded: true,
      error: false
    });
  }, []);

  const handleImgFailed = useCallback(() => {
    setLoadState({
      loaded: false,
      error: true
    });
  }, []);

  useEffect(() => {
    const img = new Image();
    setLoadState({
      loaded: false,
      error: false
    });

    if (imageSrc) {
      img.src = imageSrc;
      img.addEventListener('load', handleImgLoaded);
      img.addEventListener('error', handleImgFailed);
    }

    return () => {
      img.removeEventListener('load', handleImgLoaded);
      img.removeEventListener('error', handleImgFailed);
    };
  }, [imageSrc, handleImgLoaded, handleImgFailed]);

  const imgLoaded = loaded && !error;
  const imgLoading = !!imageSrc && !loaded && !error;
  const imgFailed = !imageSrc || error;

  const imageStyle =
    imageSrc && imgLoaded
      ? {
          backgroundImage: `url(${imageSrc})`
        }
      : {};

  return (
    <div className={clsx(s.ImagePreview, className, classes?.root)} {...props}>
      {showPlaceholder && imgFailed && (
        <ImagePlaceholder
          className={clsx(s.ImagePreview__placeholder, classes?.placeholder)}
          {...placeholderProps}
        />
      )}
      {showLoader && imgLoading && (
        <Loader
          className={clsx(s.ImagePreview__loader, classes?.loader)}
          position={LoaderPositions.absolute}
          {...loaderProps}
        />
      )}
      {showBackfaceImage && (
        <div
          className={clsx(
            s.ImagePreview__backfaceImage,
            classes?.backfaceImage,
            {
              [s.ImagePreview__image_loaded]: imgLoaded
            }
          )}
          style={imageStyle}
        />
      )}
      <div
        className={clsx(s.ImagePreview__image, classes?.image, {
          [s.ImagePreview__image_loaded]: imgLoaded
        })}
        style={imageStyle}
      />
    </div>
  );
};
