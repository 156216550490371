import React from 'react';
import { get } from 'lodash-es';
import { TableRow } from '../../../../common/components/ui/Table/TableRow/TableRow';
import { TableRowProps } from '../../../../common/components/ui/Table/Table';
import { TableCell } from '../../../../common/components/ui/Table/TableCell/TableCell';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';

import s from '../PollAttemptsPage.module.scss';

export const PollAttemptsRow: React.FC<TableRowProps> = ({ columns, row }) => {
  const { isMobile } = useWindowSize();
  return (
    <TableRow>
      {columns.map((col, iCol) => {
        const FieldComponent = col.fieldComponent;
        console.log(col);
        const TitleComponent = col.titleComponent;
        return (
          <TableCell key={iCol}>
            {isMobile && (
              <div className={s.PollAttemptsRow}>
                {TitleComponent ? (
                  <TitleComponent
                    data={[]}
                    field={col.field}
                    title={col.title}
                  />
                ) : (
                  <div>{col.title}</div>
                )}
              </div>
            )}
            {FieldComponent ? (
              <FieldComponent row={row} field={col.field} />
            ) : (
              get(row, col.field, undefined)
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
