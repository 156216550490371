import React from 'react';
import { queryLoader } from '../../../../common/components/utils/queryLoader';
import { NoData } from '../../../../common/components/ui/NoData/NoData';
import {
  GridItem,
  LayoutGrid
} from '../../../../common/components/Layout/Layout';
import { QueryPagination } from '../../../../common/components/ui/QueryPagination/QueryPagination';
import { usePollsPage } from '../../../../graphql/hooks/polls/usePollsPage';
import { URL_KEY_PAGE } from '../../../../store/urlKeys';
import { useLocationQuery } from '../../../../common/hooks/utils/useLocationQuery';
import { routeLinks } from '../../../routeLinks';
import { CardPoll } from '../../../../common/components/ui/Cards/CardPoll/CardPoll';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { PollActions } from './PollActions/PollActions';

export interface PollsProps {}

export const Polls: React.FC<PollsProps> = () => {
  const [query] = useLocationQuery([URL_KEY_PAGE] as const);
  const pollsQuery = usePollsPage({
    pagination: { page: query[URL_KEY_PAGE] }
  });
  const polls = pollsQuery.state.data?.list;
  const header = pollsQuery.state.data?.header;

  const { isMobile, isTablet } = useWindowSize();

  return (
    queryLoader(pollsQuery) ||
    (!polls?.length ? (
      <NoData />
    ) : (
      <>
        <LayoutGrid>
          {polls.map((poll) => (
            <GridItem key={poll.id} cols={isMobile ? 12 : isTablet ? 5 : 6}>
              <CardPoll
                data={{
                  name: poll.name,
                  cover_file_id: poll.cover_file_id || undefined
                }}
                link={
                  poll.canAccess
                    ? `${routeLinks.polls.to}/${poll.id}/attempts/create`
                    : undefined
                }
                actions={poll.canViewAttempts && <PollActions id={poll.id} />}
                imageType={FileTypeEnum.poll_cover}
              />
            </GridItem>
          ))}
        </LayoutGrid>
        {header && (
          <QueryPagination header={header} pathname={routeLinks.polls.to} />
        )}
      </>
    ))
  );
};
