import {
  CourseCompletionProof,
  CourseLearningFormat,
  CourseListenerCategory,
  Maybe
} from '../../../graphql/types';
import {
  courseCompletionProofLabelMap,
  courseListenerCategoryLabelMap,
  learningFormatLabelMap
} from '../../../store/courses/CourseTest';
import s from './CoursePage.module.scss';

export interface CourseCommonFieldsProps {
  price?: Maybe<number | string>;
  has_discount?: Maybe<boolean>;
  learning_format?: Maybe<CourseLearningFormat>;
  completion_proof?: Maybe<CourseCompletionProof>;
  listener_category?: Maybe<CourseListenerCategory>;
  showTitle?: boolean;
}

interface FieldProps {
  title?: string;
  value?: string;
}

function Field({ title, value }: FieldProps) {
  return (
    <div className={s.CourseCommonField}>
      <div className={s.CourseCommonField__title}>{title}:</div>
      <div className={s.CourseCommonField__value}>{value}</div>
    </div>
  );
}

export function CourseCommonFields({
  price,
  has_discount,
  learning_format,
  completion_proof,
  listener_category,
  showTitle
}: CourseCommonFieldsProps) {
  const hasAnyProps = Boolean(
    price ||
      has_discount ||
      learning_format ||
      completion_proof ||
      listener_category
  );
  return (
    <div className={s.CourseCommonFields}>
      {showTitle && hasAnyProps && (
        <div className={s.CourseCommonFields__title}>Информация о курсе:</div>
      )}
      {price && (
        <Field
          title={`Цена ${has_discount ? ' со скидкой' : ''}`}
          value={`${price} руб.`}
        />
      )}
      {learning_format && (
        <Field
          title={'Формат обучения'}
          value={learningFormatLabelMap[learning_format]}
        />
      )}
      {completion_proof && (
        <Field
          title={'Доказательство прохождения'}
          value={courseCompletionProofLabelMap[completion_proof]}
        />
      )}
      {listener_category && (
        <Field
          title={'Категория слушателей'}
          value={courseListenerCategoryLabelMap[listener_category]}
        />
      )}
    </div>
  );
}
