import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useField, useFormikContext } from 'formik';
import { Option } from '@proscom/ui-utils';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import {
  BasePermissionType,
  PermissionType,
  PermissionTypeEnum
} from '../../../../../graphql/types';
import { isAdmin, UserPermission } from '../../../../../utils/permissions';
import { UserProps } from '../../../../../store/users/UserUtils';
import { TableRow } from '../../Table/TableRow/TableRow';
import { TableCell } from '../../Table/TableCell/TableCell';
import { FormSelect } from '../FormSelect/FormSelect';
import { TextFieldSize } from '../../TextField/TextField';
import { BaseCheckbox } from '../../Checkbox/Checkbox';
import { ActionButton, ActionType } from '../../ActionButton/ActionButton';
import { ButtonSize } from '../../Button';
import { PollSelector } from './PermissionObjectSelectors';
import { PermissionMap } from './UserPermissionsUtils';
import s from './Permissions.module.scss';

const makePermissionOption = (
  permission: BasePermissionType
): Option<string, string> => ({
  label: permission.name,
  value: permission.code
});

const filterByPermission = (permission: BasePermissionType) => (
  valPerm?: PermissionType | null
) => {
  if (!valPerm || valPerm.object_id) {
    return false;
  }
  return permission?.code === valPerm.base_permission_code;
};

export interface PermissionInputsProps {
  onDelete: (permissionIndex: number) => void;
  permissions: BasePermissionType[];
  userPermissionsMap: PermissionMap;
}

export const PermissionInputs = React.memo(function PermissionInputs({
  onDelete,
  permissions,
  userPermissionsMap
}: PermissionInputsProps) {
  const [{ value: roles }] = useField<UserProps['roles']>('roles');
  const [{ value: formPermissions }] = useField<UserProps['permissions']>(
    'permissions'
  );

  //

  const { user: currentUser } = useCurrentUser();
  const isCurrentUserIsAdmin = currentUser ? isAdmin(currentUser) : undefined;
  const rolePermissions = useMemo(
    () => roles?.map((r) => r.permissions)?.flat(),
    [roles]
  );

  const permissionsOptions = useMemo(() => {
    return permissions
      ?.filter((permission) => {
        if (isCurrentUserIsAdmin || userPermissionsMap[permission.code]) {
          return true;
        }
        const hasPermissionInPermissions = !formPermissions?.some(
          filterByPermission(permission)
        );
        const hasPermissionInRole = !rolePermissions?.some(
          filterByPermission(permission)
        );
        return hasPermissionInPermissions || hasPermissionInRole;
      })
      .map(makePermissionOption)
      .filter(Boolean);
  }, [
    permissions,
    isCurrentUserIsAdmin,
    userPermissionsMap,
    formPermissions,
    rolePermissions
  ]);
  return (
    <>
      {formPermissions?.map((permission, iPermission) => (
        <PermissionRow
          key={iPermission}
          index={iPermission}
          permissions={permissions}
          permissionsOptions={permissionsOptions}
          permission={permission}
          onDelete={onDelete}
        />
      ))}
    </>
  );
});

interface PermissionRowProps {
  permissions: BasePermissionType[];
  permissionsOptions: Option<string, string>[];
  permission: PermissionType;
  index: number;
  onDelete: (index: number) => void;
}

const checkboxRootProps = {
  className: s.CheckboxIsAll
};

const PermissionRow = React.memo(function PermissionRow({
  permissions,
  permissionsOptions,
  permission,
  index,
  onDelete
}: PermissionRowProps) {
  const { values, setFieldValue } = useFormikContext<UserProps>();
  const basePermission = permissions.find(
    (p) => p.code === permission.base_permission_code
  );

  const showObjectField = basePermission?.type === PermissionTypeEnum.Object;

  let permOptions = permissionsOptions;

  const currentPerm = permissions.find(
    (perm) => perm.code === permission.base_permission_code
  );
  if (currentPerm) {
    permOptions = [makePermissionOption(currentPerm), ...permissionsOptions];
  }

  const isAll = values.permissions?.[index].object_type === 'all';
  const toggleIsAll = () => {
    if (isAll) {
      setFieldValue(`permissions[${index}].object_type`, null);
      setFieldValue(`permissions[${index}].object_id`, null);
    } else {
      setFieldValue(`permissions[${index}].object_type`, 'all');
      setFieldValue(`permissions[${index}].object_id`, null);
    }
  };
  const showPollSelector =
    permission.base_permission_code === UserPermission.PollAccess ||
    permission.base_permission_code === UserPermission.PollViewAttempts;

  return (
    <TableRow>
      <TableCell className={s.Cell} colSpan={showObjectField ? 1 : 2}>
        <FormSelect
          size={TextFieldSize.large}
          name={`permissions[${index}].base_permission_code`}
          label={`Право ${index + 1}`}
          isClearable={true}
          options={permOptions}
        />
      </TableCell>
      {showObjectField && (
        <TableCell className={s.Cell}>
          {showPollSelector && (
            <PollSelector
              name={`permissions[${index}].object_id`}
              disabled={isAll}
            />
          )}
          <BaseCheckbox
            rootProps={checkboxRootProps}
            checked={isAll}
            label={'Все'}
            onChange={toggleIsAll}
          />
        </TableCell>
      )}
      <TableCell className={clsx(s.Cell, s.Cell_alignMiddle)}>
        <ActionButton
          className={s.DeleteButton}
          size={ButtonSize.large}
          actionType={ActionType.delete}
          onClick={() => onDelete(index)}
        />
      </TableCell>
    </TableRow>
  );
});
