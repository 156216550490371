import React, { useCallback } from 'react';
import clsx from 'clsx';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { FileBox, FileBoxProps } from '../FileBox/FileBox';
import { Title, TitleVariant } from '../Title/Title';
import { useFileDownload } from '../../../hooks/useFileDownload';
import { fileDownloadUrl } from '../../../../config';
import { UploadFileType } from './FileLoader';
import s from './FileLoader.module.scss';

export interface FileLoaderItemProps
  extends UploadFileType,
    Pick<FileBoxProps, 'editable' | 'classes'> {
  className?: string;
  fileType: FileTypeEnum;
  onChange?: (id: string, name: string) => void;
  onDelete?: (id: string) => void;
}

export const FileLoaderItem: React.FC<FileLoaderItemProps> = ({
  className,
  classes,
  id,
  name,
  fileType,
  editable,
  onChange,
  onDelete
}) => {
  const { handle: handleOpenDocument } = useFileDownload(
    fileDownloadUrl,
    fileType
  );

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      handleOpenDocument(id)();
    },
    [id, handleOpenDocument]
  );

  const handleChange = useCallback(
    (name) => {
      onChange?.(id, name);
    },
    [id, onChange]
  );

  if (!id) return null;

  return (
    <FileBox
      className={clsx(s.FileLoaderItem, className, classes?.root)}
      classes={classes}
      fileName={name}
      editable={editable}
      onClick={handleClick}
      onChange={onChange ? handleChange : undefined}
      onDelete={() => onDelete?.(id)}
    />
  );
};

export interface FileLoaderListClasses {
  root?: string;
  title?: string;
  item?: FileLoaderItemProps['classes'];
}

export interface FileLoaderListProps
  extends Pick<
    FileLoaderItemProps,
    'fileType' | 'onDelete' | 'onChange' | 'editable'
  > {
  className?: string;
  classes?: FileLoaderListClasses;
  title?: string;
  titleVariant?: TitleVariant;
  files?: UploadFileType[];
}

export const FileLoaderList: React.FC<FileLoaderListProps> = ({
  className,
  classes,
  title,
  titleVariant = TitleVariant.h3,
  files,
  ...props
}) => {
  return (
    <div className={clsx(s.FileLoaderList, className, classes?.root)}>
      {title && (
        <Title
          className={clsx(s.FileLoaderList__title, classes?.title)}
          variant={titleVariant}
          title={title}
        />
      )}
      {files?.map((file, iFile) => (
        <FileLoaderItem
          key={iFile}
          classes={classes?.item}
          {...props}
          {...file}
        />
      ))}
    </div>
  );
};
