import React from 'react';
import clsx from 'clsx';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { AnyProps } from '../../../../../utils/reactTypes';
import s from './TableCell.module.scss';

export interface TableCellProps extends AnyProps {
  className?: string;
}

export const TableCell: React.FC<TableCellProps> = ({
  className,
  children,
  ...props
}) => {
  const { isMobile } = useWindowSize();
  const Component = isMobile ? 'div' : 'td';
  return (
    <Component className={clsx(s.TableCell, className)} {...props}>
      {children}
    </Component>
  );
};
