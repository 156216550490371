import React, { useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { FormSection } from '../../../common/components/ui/Form/FormSection/FormSection';
import { FormTextField } from '../../../common/components/ui/Form/FormTextField/FormTextField';
import { FormTextEditor } from '../../../common/components/ui/Form/FormTextEditor/FormTextEditor';
import { InternshipsValidationSchema } from '../../../store/validationSchema';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import NoAccessPage from '../../noAccess/NoAccessPage';
import { UserPermission } from '../../../utils/permissions';
import {
  InternshipProps,
  InternshipUtils
} from '../../../store/internships/InternshipUtils';
import { FormCoverLoader } from '../../../common/components/ui/Form/FormCoverLoader/FormCoverLoader';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import { InternshipType } from '../../../graphql/types';
import { FormAsyncEntitiesCreatableSelect } from '../../../common/components/ui/Form/FormAsyncEntitiesCreatableSelect/FormAsyncEntitiesCreatableSelect';
import { Entity } from '../../../common/components/ui/AsyncSelect/entities/types';
import { FormAsyncEntitySelect } from '../../../common/components/ui/Form/FormAsyncEntitySelect/FormAsyncEntitySelect';
import { FormFileLoader } from '../../../common/components/ui/Form/FormFileLoader/FormFileLoader';
import { FormSelect } from '../../../common/components/ui/Form/FormSelect/FormSelect';
import {
  internshipContestOptions,
  internshipDurationOptions,
  internshipFinancialConditionsOptions,
  internshipLocationOptions,
  internshipPurposeOptions,
  internshipTraineeCategoryOptions
} from '../../../store/internships/InternshipTest';
import s from './InternshipForm.module.scss';

export interface InternshipFormProps {
  internship?: InternshipType;
  onSubmit: (
    data: InternshipProps,
    helpers: FormikHelpers<InternshipProps>
  ) => void;
  onCancel: () => void;
  submissionLoading?: boolean;
}

export const InternshipForm: React.FC<InternshipFormProps> = ({
  internship,
  onSubmit,
  onCancel,
  submissionLoading
}) => {
  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.InternshipCrud);

  const initialValues = useMemo(() => {
    return InternshipUtils.toForm(internship);
  }, [internship]);

  if (internship && !canEdit) {
    return <NoAccessPage />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={InternshipsValidationSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        const manualFiles = values['manual_file'];

        return (
          <Form className={s.InternshipForm}>
            <FormSection>
              <FormCoverLoader
                name={'cover_file_id'}
                fileType={FileTypeEnum.internship_cover}
              />
            </FormSection>

            <FormSection>
              <FormAsyncEntitySelect
                name={'internship_subject_id'}
                label={'Тип'}
                entity={Entity.internshipSubjectsPage}
                placeholder={'Выберите тип'}
              />
            </FormSection>

            <FormSection>
              <FormAsyncEntitiesCreatableSelect
                name={'tags'}
                label={'Теги'}
                entity={Entity.tagsPage}
                placeholder={'Выберите теги'}
              />
            </FormSection>

            <FormSection>
              <FormSelect
                label={'Категория стажирующихся'}
                options={internshipTraineeCategoryOptions}
                name={'trainee_category'}
              />
            </FormSection>
            <FormSection>
              <FormSelect
                label={'Цель стажировки'}
                options={internshipPurposeOptions}
                name={'purpose'}
              />
            </FormSection>
            <FormSection>
              <FormSelect
                label={'Продолжительность стажировки'}
                options={internshipDurationOptions}
                name={'duration'}
              />
            </FormSection>
            <FormSection>
              <FormSelect
                label={'Место стажировки'}
                options={internshipLocationOptions}
                name={'location'}
              />
            </FormSection>
            <FormSection>
              <FormSelect
                label={'Наличие конкурса на программу стажировки'}
                options={internshipContestOptions}
                name={'contest'}
              />
            </FormSection>
            <FormSection>
              <FormSelect
                label={'Финансовые условия стажировки'}
                options={internshipFinancialConditionsOptions}
                name={'financial_conditions'}
              />
            </FormSection>

            <FormSection>
              <FormTextField name={'name'} label={'Название'} />
            </FormSection>

            <FormSection>
              <FormTextEditor
                className={s.InternshipForm__description}
                name={'description'}
                label={'Описание'}
              />
            </FormSection>

            <FormSection>
              <FormTextEditor
                className={s.InternshipForm__conditions}
                name={'conditions'}
                label={'Условия'}
              />
            </FormSection>

            <FormSection>
              <FormTextField name={'link'} label={'Ссылка для подачи заявки'} />
            </FormSection>

            <FormSection>
              <FormFileLoader
                name={'manual_file_id'}
                label={'Инструкция по подаче заявки'}
                fileType={FileTypeEnum.internship_manual}
                value={manualFiles && [manualFiles]}
                buttonText={'Загрузить инструкцию'}
              />
            </FormSection>

            <div className={s.InternshipForm__actions}>
              <Button
                className={s.InternshipForm__actionButton}
                size={ButtonSize.large}
                variant={ButtonVariant.secondary}
                onClick={onCancel}
              >
                Отменить всё
              </Button>
              <Button
                className={s.InternshipForm__actionButton}
                size={ButtonSize.large}
                type={'submit'}
                loading={submissionLoading}
              >
                Сохранить изменения
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
