import { IntrinsicPropsWithoutRef } from '../../../utils/reactTypes';

export function HtmlButton(props: IntrinsicPropsWithoutRef<'button'>) {
  return (
    <button type="button" {...props}>
      {props.children}
    </button>
  );
}

export function HtmlAnchor(props: IntrinsicPropsWithoutRef<'a'>) {
  return <a {...props}>{props.children}</a>;
}
