import { IUseGraphqlWatchQueryOptions } from '@proscom/prostore-apollo-react';
import {
  VacationApplicationPageInput,
  VacationApplicationsPageType
} from '../../types';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import { ONE_PAGE_ITEMS } from '../../../constants';
import { QUERY_GET_VACATION_APPLICATIONS_PAGE } from '../../queries/vacations';

const queryOptions: IUseGraphqlWatchQueryOptions<
  { input: VacationApplicationPageInput },
  VacationApplicationsPageType
> = {
  query: QUERY_GET_VACATION_APPLICATIONS_PAGE,
  mapData: (result) => result.vacationApplicationsPage
};

export function useVacationApplicationsPage({
  filter,
  pagination
}: VacationApplicationPageInput) {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: {
      input: {
        filter,
        pagination: {
          onePage: ONE_PAGE_ITEMS,
          ...pagination
        }
      }
    }
  });
}
