import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { groupBy } from 'lodash-es';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { Table } from '../../../common/components/ui/Table/Table';
import {
  UploadField,
  UploadFieldUploadPropsType,
  UploadFieldVariant
} from '../../../common/components/ui/UploadField/UploadField';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { Manuals } from '../../../store/manuals/Manuals';
import { TextField } from '../../../common/components/ui/TextField/TextField';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { useManuals } from '../../../graphql/hooks/useManuals';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import {
  Breadcrumbs,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import { getManualsPageBreadcrumbs } from '../ManualsPage';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { manualsGroupTitles } from '../manuals.contants';
import { ManualType, ManualTypeEnum } from '../../../graphql/types';
import { FileLink } from './FileLink';
import { ManualsRow } from './ManualsRow';
import { ExternalLink } from './ExternalLink';
import s from '../ManualsPage.module.scss';

function getManualsEditPageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    ...getManualsPageBreadcrumbs(),
    {
      name: 'Управление инструкциями',
      path: '/guides/edit'
    }
  ];
}

const manualsTableColumns = {
  [ManualTypeEnum.Manual]: [
    {
      title: 'Название Инструкции',
      field: 'name',
      editComponent: ({ state, onChange }) => {
        return <TextField value={state.name} onChange={onChange} />;
      }
    },
    {
      className: s.ManualsTableContentCell,
      title: 'Файл',
      field: 'file.name',
      fieldComponent: FileLink
    }
  ],
  [ManualTypeEnum.Infographics]: [
    {
      title: 'Название Инфографики',
      field: 'name',
      editComponent: ({ state, onChange }) => {
        return <TextField value={state.name} onChange={onChange} />;
      }
    },
    {
      className: s.ManualsTableContentCell,
      title: 'Файл',
      field: 'file.name',
      fieldComponent: FileLink
    }
  ],
  [ManualTypeEnum.Video]: [
    {
      title: 'Название Видеоролика',
      field: 'name',
      editComponent: ({ state, onChange }) => {
        return <TextField value={state.name} onChange={onChange} />;
      }
    },
    {
      className: s.ManualsTableContentCell,
      title: 'Ссылка',
      field: 'external_link',
      fieldComponent: ExternalLink
    }
  ]
};

interface ManualEditGroupProps {
  type: ManualTypeEnum;
  manuals: ManualType[];
  loading: boolean;
  onUpload: (
    type: ManualTypeEnum,
    props: UploadFieldUploadPropsType
  ) => Promise<boolean>;
}

const ManualEditGroup = ({
  type,
  manuals,
  loading,
  onUpload
}: ManualEditGroupProps) => {
  const { isMobile } = useWindowSize();
  const [upload, setUpload] = useState(false);

  const handleCloseUploadField = () => {
    setUpload(false);
  };

  const handleOpenUploadField = () => {
    setUpload(true);
  };

  const handleUpload = async (props: UploadFieldUploadPropsType) => {
    const uploaded = await onUpload(type, props);
    if (uploaded) {
      setUpload(false);
    }
  };

  const uploadVariant = (() => {
    switch (type) {
      case ManualTypeEnum.Video:
        return UploadFieldVariant.Link;
      default:
        return UploadFieldVariant.File;
    }
  })();

  const uploadTitle = (() => {
    switch (type) {
      case ManualTypeEnum.Manual:
        return 'Название инструкции';
      case ManualTypeEnum.Infographics:
        return 'Название инфографики';
      case ManualTypeEnum.Video:
        return 'Название видеоролика';
      default:
        return 'Название';
    }
  })();

  return (
    <>
      <div className={s.ManualEditGroup__title}>
        <Title variant={TitleVariant.h3}>{manualsGroupTitles[type]}</Title>
        <Button
          variant={ButtonVariant.secondary}
          size={ButtonSize.small}
          onClick={handleOpenUploadField}
        >
          Добавить
        </Button>
      </div>

      <UploadField
        label={uploadTitle}
        fileType={FileTypeEnum.manual}
        variant={uploadVariant}
        onCancel={handleCloseUploadField}
        onUpload={handleUpload}
        loading={loading}
        classes={{
          root: clsx(s.ManualEditGroup__upload, {
            [s.ManualEditGroup__upload_open]: upload
          })
        }}
      />

      {!manuals.length ? (
        <NoData />
      ) : (
        <Table
          columns={manualsTableColumns[type]}
          data={manuals}
          rowComponent={ManualsRow}
          hasActions
          hideHeader={isMobile}
        />
      )}
    </>
  );
};

export default function ManualsEditPage() {
  const manualsQuery = useManuals();
  const manuals = manualsQuery.state.data;

  const groups = useMemo(() => {
    const object = {
      [ManualTypeEnum.Manual]: [] as ManualType[],
      [ManualTypeEnum.Infographics]: [] as ManualType[],
      [ManualTypeEnum.Video]: [] as ManualType[]
    };
    if (!manuals?.length) return object;
    return {
      ...object,
      ...groupBy(manuals, (m) => m.type)
    };
  }, [manuals]);

  const createManual = useGraphqlMutation(Manuals.CreateManual);
  const createManualRun = createManual.run;
  const createManualLoading = createManual.loading;
  const handleUploadManual = useCallback(
    async (
      type: ManualTypeEnum,
      { fileName, file, link }: UploadFieldUploadPropsType
    ) => {
      if (!file && !link) return false;

      const toastText = (() => {
        switch (type) {
          case ManualTypeEnum.Manual:
            return 'Инструкция добавлена';
          case ManualTypeEnum.Infographics:
            return 'Инфографика добавлена';
          case ManualTypeEnum.Video:
            return 'Видеоролик добавлен';
          default:
            return 'Инструкция добавлена';
        }
      })();

      try {
        const result = await createManualRun({
          variables: {
            input: {
              type,
              name: fileName,
              external_link: link || undefined,
              file_id: file ? String(file.fileId) : undefined
            }
          }
        });
        if (!result.data?.result) {
          throw new Error('UnexpectedResult');
        }
        toast.success(toastText);
        return true;
      } catch (err) {
        if (!(err instanceof AsyncSingletonError)) {
          console.error(err);
        }
        return false;
      }
    },
    [createManualRun]
  );

  const breadcrumbs = useMemo(() => getManualsEditPageBreadcrumbs(), []);

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle title={'Управление инструкциями'} showHeading />

        {queryLoader(manualsQuery) ||
          (!manuals || manuals.length === 0 ? (
            <NoData />
          ) : (
            Object.entries(groups).map(([key, items], iGroup) => (
              <div key={iGroup} className={s.ManualsGroup}>
                <ManualEditGroup
                  type={key as ManualTypeEnum}
                  manuals={items}
                  loading={createManualLoading}
                  onUpload={handleUploadManual}
                />
              </div>
            ))
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
