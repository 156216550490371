import React, { useMemo } from 'react';
import { compareSimple, makeComparator } from '@proscom/ui-utils';
import { multiQueryLoader } from '../../../common/components/utils/queryLoader';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { Table, TableColumn } from '../../../common/components/ui/Table/Table';
import { usePollAttemptsPage } from '../../../graphql/hooks/polls/usePollAttemptsPage';
import { BasePageInterface } from '../../../common/components/utils/BasePageInterface';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import {
  Breadcrumbs,
  IBreadcrumbsEntity,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { getPollsPageBreadcrumbs } from '../list/PollsPage';
import { usePoll } from '../../../graphql/hooks/polls/usePoll';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { QueryPagination } from '../../../common/components/ui/QueryPagination/QueryPagination';
import { useLocationQuery } from '../../../common/hooks/utils/useLocationQuery';
import { URL_KEY_PAGE } from '../../../store/urlKeys';
import { PollAnswerType } from '../../../graphql/types';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { useExportPollAttempts } from '../../../common/hooks/useExportPollAttempts';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { ReactComponent as IconDoc } from '../../../assets/img/icons/Document.svg';
import { PollAttemptsRow } from './pollTableParts/PollAttemptsRow';
import { PollDate } from './pollTableParts/PollDate';
import { PollAnswer } from './pollTableParts/PollAnswer';
import { PollQuestionTitle } from './pollTableParts/PollQuestionTitle';
import s from './PollAttemptsPage.module.scss';

export function getPollAttemptsPageBreadcrumbs({
  poll
}: {
  poll: IBreadcrumbsEntity;
}): IBreadcrumbsItem[] {
  return [
    ...getPollsPageBreadcrumbs(),
    {
      name: poll.name || '...',
      path: '/polls/' + poll.id + '/attempts'
    }
  ];
}

export default function PollAttemptsPage({
  match
}: BasePageInterface<{ pollId: string }>) {
  const { pollId } = match.params;
  const { handle: handleExportPollAttempts } = useExportPollAttempts();

  const [query] = useLocationQuery([URL_KEY_PAGE] as const);
  const pollAttemptsQuery = usePollAttemptsPage({
    filter: { poll_ids: [pollId] },
    pagination: { page: query[URL_KEY_PAGE] }
  });
  const pollAttempts = useMemo(() => {
    if (!pollAttemptsQuery.state.data) return null;

    return pollAttemptsQuery.state.data.list.map((attempt) => ({
      answers: attempt.answers
        ?.slice()
        .sort(
          makeComparator(
            compareSimple(1),
            (r: PollAnswerType) => r.question.order
          )
        ),
      ...attempt
    }));
  }, [pollAttemptsQuery.state.data]);
  const header = pollAttemptsQuery.state.data?.header;

  const pollQuery = usePoll(pollId);
  const poll = pollQuery.state.data;
  const breadcrumbs = useMemo(
    () =>
      getPollAttemptsPageBreadcrumbs({
        poll: {
          id: pollId,
          name: poll?.name
        }
      }),
    [poll, pollId]
  );

  const PollAttemptsColumns = useMemo(() => {
    if (!poll) return null;
    const questions: TableColumn[] = poll.questions
      ? poll.questions?.map((question, iQuestion) => ({
          title: '',
          titleComponent: () => (
            <PollQuestionTitle
              questionOrder={question.order ?? undefined}
              title={question.content}
            />
          ),
          field: `answers[${iQuestion}]`,
          fieldComponent: PollAnswer
        }))
      : [];
    return [
      {
        title: 'Дата ответа',
        field: 'created_at',
        fieldComponent: PollDate
      },
      ...questions
    ];
  }, [poll]);

  const hasData =
    pollAttempts && pollAttempts.length > 0 && PollAttemptsColumns;

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        {poll && <PageTitle title={poll.name} showHeading />}

        <Title
          className={s.PollAttemptsPage__title}
          variant={TitleVariant.h3}
          withMargin
        >
          Результаты
          {hasData && (
            <Button
              variant={ButtonVariant.secondary}
              size={ButtonSize.small}
              iconLeft={<IconDoc />}
              onClick={() => handleExportPollAttempts(pollId)}
            >
              Экспортировать результаты в xlsx
            </Button>
          )}
        </Title>

        {multiQueryLoader([pollAttemptsQuery, pollQuery]) ||
          (!pollAttempts ||
          !PollAttemptsColumns ||
          pollAttempts.length === 0 ? (
            <NoData />
          ) : (
            <Table
              columns={PollAttemptsColumns}
              data={pollAttempts}
              rowComponent={PollAttemptsRow}
            />
          ))}

        {header && (
          <QueryPagination
            header={header}
            pathname={`/polls/${pollId}/attempts`}
          />
        )}
      </LayoutContent>
    </PageLayout>
  );
}
