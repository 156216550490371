import { CSSProperties } from 'react';

export enum FigureType {
  rectangle = 'rectangle',
  circle = 'circle',
  triangle = 'triangle'
}

export enum FigureVariant {
  dark = 'dark',
  light = 'light'
}

export interface FigureProps {
  className?: string;
  variant?: FigureVariant;
  size?: number | 'auto';
  unit?: string;
  color?: string;
  style?: CSSProperties;
}
