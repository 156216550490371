import React from 'react';
import { ReactComponent as IconAdd } from '../../../../assets/img/icons/Add.svg';
import { ButtonVariant } from '../Button';
import { IconButton, IconButtonLink } from '../IconButton/IconButton';

export const IconButtonCreate = ({
  onClick,
  to
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  to?: string;
}) =>
  Boolean(onClick) ? (
    <IconButton
      onClick={onClick}
      icon={<IconAdd />}
      variant={ButtonVariant.secondary}
    />
  ) : (
    <IconButtonLink
      to={to as string}
      icon={<IconAdd />}
      variant={ButtonVariant.secondary}
    />
  );
