import { IUseGraphqlQueryOptions } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { CoursesPageInput, CoursesPageType } from '../../types';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import { ONE_PAGE_ITEMS } from '../../../constants';
import {
  QUERY_GET_COURSES_PAGE,
  QUERY_GET_UNPUBLISHED_COURSES_PAGE
} from '../../queries/courses';

const coursesPageQueryOptions: IUseGraphqlQueryOptions<
  { input: CoursesPageInput } | null,
  CoursesPageType | null
> = {
  query: QUERY_GET_COURSES_PAGE,
  mapData: (result) => result.coursesPage,
  skipQuery: skipIfNull(null)
};

const unpublishedCoursesPageQueryOptions: IUseGraphqlQueryOptions<
  { input: CoursesPageInput } | null,
  CoursesPageType | null
> = {
  query: QUERY_GET_UNPUBLISHED_COURSES_PAGE,
  mapData: (result) => result.unpublishedCoursesPage,
  skipQuery: skipIfNull(null)
};

export function useCoursesPage(
  input: CoursesPageInput | null,
  archived?: boolean
) {
  const { filter, pagination } = input || {};
  return useAuthGraphqlQuery({
    queryOptions: archived
      ? unpublishedCoursesPageQueryOptions
      : coursesPageQueryOptions,
    variables: input
      ? {
          input: {
            filter,
            pagination: {
              onePage: ONE_PAGE_ITEMS,
              ...pagination
            }
          }
        }
      : null
  });
}
