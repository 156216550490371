import React, { useCallback, useEffect, useRef } from 'react';
import { TextField, TextFieldProps } from '../TextField/TextField';

export interface SearchFieldProps extends TextFieldProps {
  onSearch?: (value: string) => void;
}

export const SearchField: React.FC<SearchFieldProps> = ({
  onSearch,
  placeholder,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = useCallback(
    (e) => {
      onSearch?.(e.target.value);
    },
    [onSearch]
  );

  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      input.addEventListener('search', handleSearch);
      return () => {
        input.removeEventListener('search', handleSearch);
      };
    }
  }, [handleSearch]);

  return (
    <TextField
      type={'search'}
      placeholder={placeholder || 'Поиск'}
      inputRef={inputRef}
      {...props}
    />
  );
};
