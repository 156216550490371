import React from 'react';
import clsx from 'clsx';
import s from './StatusIndicator.module.scss';

export enum StatusIndicatorActivity {
  active = 'active',
  inactive = 'inactive',
  default = 'default'
}

export interface StatusIndicatorClasses {
  root?: string;
  dot?: string;
  text?: string;
}

export interface StatusIndicatorProps {
  className?: string;
  classes?: StatusIndicatorClasses;
  status?: StatusIndicatorActivity;
  text?: string;
}

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  className,
  classes,
  status = StatusIndicatorActivity.default,
  text,
  children
}) => {
  return (
    <div
      className={clsx(
        s.StatusIndicator,
        className,
        classes?.root,
        s[`StatusIndicator_${status}`]
      )}
    >
      <div
        className={clsx(
          s.StatusIndicator__dot,
          classes?.dot,
          s[`StatusIndicator__dot_${status}`]
        )}
      />
      {text && (
        <div
          className={clsx(
            s.StatusIndicator__text,
            classes?.text,
            s[`StatusIndicator__text_${status}`]
          )}
        >
          {text}
        </div>
      )}
      {children}
    </div>
  );
};
