import { Routes, RouteType } from '../../common/components/utils/Routes';
import { RequireAuth } from '../RequireAuth';
import NotFoundPage from '../notFound/NotFoundPage';
import { hasPermission, UserPermission } from '../../utils/permissions';
import UsersPage from './list/UsersPage';
import UserPage from './index/UserPage';
import UserEditPage from './edit/UserEditPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: RequireAuth(UsersPage)
  },
  {
    path: '/:userId',
    exact: true,
    component: RequireAuth(UserPage)
  },
  {
    path: '/:userId/edit',
    exact: true,
    component: RequireAuth(UserEditPage, (user, props) => {
      return (
        hasPermission(user, UserPermission.UserCrud) ||
        props.match.params.userId === user.id
      );
    })
  },
  {
    component: NotFoundPage
  }
];

const UsersRoutes = Routes(routes);

export default UsersRoutes;
