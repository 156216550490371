import { useAsyncOperation } from '@proscom/prostore-react';
import { useCallback } from 'react';
import { tryParseJson } from '@proscom/ui-utils';
import { useMixinClassRef } from '@proscom/ui-react';
import { toast } from 'react-toastify';
import { UserAuthTokenType } from '../../graphql/types';
import { AuthSocialMixin } from '../../store/AuthSocialMixin';
import { AuthStore, AuthStoreState } from '../../store/AuthStore';
import { YandexMetrikaUtils } from '../components/utils/YandexMetrika';

export function useLoginOps(auth: AuthStoreState, authStore: AuthStore) {
  const loginOp = useAsyncOperation(authStore.loginWithEmail);
  const loginWithIdOp = useAsyncOperation(authStore.loginWithId);
  const loginSocialOp = useAsyncOperation(authStore.loginFromSocial);
  const logoutOp = useAsyncOperation(authStore.logOut, { singleton: true });
  const clientWithAuth = authStore.clientWithAuth;

  const loginOpRun = loginOp.run;
  const loginWithIdOpRun = loginWithIdOp.run;
  const loginSocialOpRun = loginSocialOp.run;

  const handleAuthComplete = useCallback(() => {
    YandexMetrikaUtils.reachGoal('login');
    toast.success('Добро пожаловать!');
  }, []);

  const handleEmailAuth = useCallback(
    async (data: { login: string; password: string }) => {
      const authData = await loginOpRun(data.login, data.password);
      if (!authData?.refreshToken) {
        return;
      }
      handleAuthComplete();
    },
    [loginOpRun, handleAuthComplete]
  );

  const handleWithIdAuth = useCallback(
    async (id: string | number) => {
      const authData = await loginWithIdOpRun(id);
      if (!authData?.refreshToken) {
        return;
      }
      await clientWithAuth.resetStore();
      handleAuthComplete();
    },
    [loginWithIdOpRun, handleAuthComplete, clientWithAuth]
  );

  const handleSocialAuth = useCallback(
    async (data) => {
      const body = tryParseJson<any>(data.body);
      if (!body?.token) {
        throw new Error('Empty authToken');
      }
      const refreshToken = body.token as UserAuthTokenType;
      await loginSocialOpRun(refreshToken);
      handleAuthComplete();
    },
    [loginSocialOpRun, handleAuthComplete]
  );

  const socialHandler = useMixinClassRef(
    () =>
      new AuthSocialMixin({
        onAuthComplete: handleSocialAuth
      })
  );

  return {
    loginOp: {
      loading: loginOp.loading || loginWithIdOp.loading || loginSocialOp.loading
    },
    logoutOp,
    authWithEmail: handleEmailAuth,
    authWithId: handleWithIdAuth,
    authWithSocial: socialHandler.handleSocialAuth
  };
}
