import React from 'react';
import { ResponsiveObject } from 'react-slick';
import {
  LandingGrid,
  LandingLayoutContent
} from '../../../common/components/Layout/Layout';
import { Slider } from '../../../common/components/ui/Slider/Slider';
import { routeLinks } from '../../routeLinks';
import { BREAKPOINT_VALUES, Breakpoints } from '../../../constants';
import { AboutCard, AboutCardProps } from './AboutCard';
import s from './AboutHR.module.scss';

const cardTemp: AboutCardProps[] = [
  {
    title: 'Коммуникации',
    links: [
      routeLinks.articles,
      routeLinks.polls,
      // routeLinks.notices,
      // routeLinks.events,
      routeLinks.projects
    ]
  },
  {
    title: 'Компетенции',
    links: [
      routeLinks.courses,
      routeLinks.internships
      // routeLinks.rate360
    ]
  },
  {
    title: 'Структура МФТИ',
    links: [routeLinks.systemLinks, routeLinks.users]
  },
  {
    title: routeLinks.manuals.text,
    to: routeLinks.manuals.to
  },
  {
    title: routeLinks.documents.text,
    to: routeLinks.documents.to
  },
  {
    title: routeLinks.offers.text,
    to: routeLinks.offers.to
  }
];

const sliderResponsive: ResponsiveObject[] = [
  {
    breakpoint: BREAKPOINT_VALUES[Breakpoints.tablet] - 1,
    settings: {
      slidesToShow: 1,
      arrows: false,
      dots: true
    }
  },
  {
    breakpoint: BREAKPOINT_VALUES[Breakpoints.desktop] - 1,
    settings: {
      slidesToShow: 2
    }
  }
];

const AboutSlider = () => {
  return (
    <Slider
      classes={{
        buttonPrev: s.AboutSlider__prevButton,
        buttonNext: s.AboutSlider__nextButton
      }}
      config={{
        slidesToShow: 3,
        arrows: true,
        responsive: sliderResponsive
      }}
    >
      {cardTemp.map((card, iCard) => (
        <div key={iCard}>
          <AboutCard {...card} />
        </div>
      ))}
    </Slider>
  );
};

export const AboutHR = () => {
  return (
    <LandingLayoutContent className={s.AboutHR}>
      <div className={s.AboutHR__header}>
        <LandingGrid>
          <LandingGrid.GridItem cols={4}>
            <div className={s.AboutHR__title}>Об HR cервисах</div>
          </LandingGrid.GridItem>
          <LandingGrid.GridItem cols={8}>
            <div className={s.AboutHR__text}>
              Экосистема цифровых HR-сервисов МФТИ позволит вам эффективно
              адаптироваться на&nbsp;новом рабочем месте, получать
              дополнительное образование и&nbsp;участвовать в&nbsp;стажировках,
              обмениваться информацией с&nbsp;коллегами и&nbsp;быть в&nbsp;курсе
              самых интересных событий университета.
            </div>
          </LandingGrid.GridItem>
        </LandingGrid>
      </div>

      <div className={s.AboutHR__slider}>
        <AboutSlider />
      </div>
    </LandingLayoutContent>
  );
};
