import { ApolloLink } from 'apollo-link';
import { switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { AuthStore } from '../../store/AuthStore';
import { wrapForwardOperation } from './wrapForwardOperation';

export function createWaitForTokenRefreshLink(
  authStore: AuthStore
): ApolloLink {
  return new ApolloLink((operation, forward): any => {
    if (authStore.isRefreshingToken()) {
      return from(authStore.refreshToken()).pipe(
        switchMap(() => wrapForwardOperation(forward, operation))
      );
    } else {
      return forward(operation);
    }
  });
}
