import React from 'react';
import clsx from 'clsx';
import { Icon } from '../Icon/Icon';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/img/icons/ArrowRight.svg';
import { BaseLink } from '../BaseLink/BaseLink';
import s from './BlueLink.module.scss';

export interface BlueLinkProps {
  to?: string;
  href?: string;
  hasArrow?: boolean;
  text?: string;
  children?: React.ReactNode;
  className?: string;
  textClassName?: string;
}

export const BlueLink: React.FC<BlueLinkProps> = ({
  to,
  href,
  hasArrow,
  text = 'Перейти',
  children,
  className,
  textClassName
}) => {
  return (
    <BaseLink to={to} href={href} className={clsx(s.BlueLink, className)}>
      <div className={clsx(s.BlueLink__text, textClassName)}>
        {children || text}
      </div>
      {hasArrow && <Icon icon={ArrowRightIcon} boxSize={12} />}
    </BaseLink>
  );
};
