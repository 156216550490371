import React from 'react';
import clsx from 'clsx';
import {
  AsyncEntitiesSelect,
  AsyncEntitiesSelectProps
} from '../AsyncSelect/entities/AsyncEntitiesSelect';
import { Entity } from '../AsyncSelect/entities/types';
import { Filter, FilterProps } from './Filter';
import { FilterSelectAsyncClasses } from './FilterSelectAsync';
import s from './Filter.module.scss';

export type FilterSelectAsyncMultiProps<E extends Entity> = Omit<
  FilterProps,
  'classes'
> &
  AsyncEntitiesSelectProps<E> & {
    classes?: FilterSelectAsyncClasses;
  };

export function FilterSelectAsyncMulti<E extends Entity>({
  className,
  label,
  classes,
  ...props
}: FilterSelectAsyncMultiProps<E>) {
  return (
    <Filter
      className={clsx(s.FilterSelect, className)}
      classes={classes?.filter}
      label={label}
    >
      <AsyncEntitiesSelect {...props} classes={classes?.select} />
    </Filter>
  );
}
