import { useCallback, useMemo } from 'react';
import {
  addDays,
  startOfToday,
  startOfDay,
  isWithinInterval,
  differenceInCalendarDays
} from 'date-fns';
import { useStoreState } from '@proscom/prostore-react';
import { STORE_PROD_CALENDAR } from '../../store/storeKeys';
import { ProdCalendarStoreState } from '../../store/ProdCalendarStore';

export interface UseDaysProps {
  startDate?: Date | string | number;
  endDate?: Date | string | number;
  daysPeriod?: number;
}

export function useDayOffs({
  startDate,
  endDate,
  daysPeriod
}: UseDaysProps = {}) {
  const {
    holidayDates,
    error,
    loading,
    loaded
  } = useStoreState<ProdCalendarStoreState>(STORE_PROD_CALENDAR);

  const calculateDays = useCallback(
    ({ startDate, endDate, daysPeriod }: UseDaysProps) => {
      if (!holidayDates.length) return [];

      const dateStart = startDate
        ? startOfDay(new Date(startDate))
        : startOfToday();
      const dateEnd = addDays(
        endDate ? startOfDay(new Date(endDate)) : dateStart,
        daysPeriod || 0
      );

      return dateStart <= dateEnd
        ? holidayDates.filter((hDate) => {
            return isWithinInterval(hDate, { start: dateStart, end: dateEnd });
          })
        : [];
    },
    [holidayDates]
  );

  const daysOff = useMemo(() => {
    return calculateDays({ startDate, endDate, daysPeriod });
  }, [startDate, endDate, daysPeriod, calculateDays]);

  return {
    holidayDates,
    daysOff,
    calculateDays,
    initialLoading: !loaded && loading,
    loading,
    loaded,
    error
  };
}

export interface UseVacationDaysAmountProps
  extends Pick<UseDaysProps, 'startDate' | 'endDate'> {}

export function useVacationDaysAmount({
  startDate,
  endDate
}: UseVacationDaysAmountProps) {
  const { calculateDays } = useDayOffs();

  return useMemo(() => {
    if (!startDate || !endDate) return null;

    const dateStart = startOfDay(new Date(startDate));
    const dateEnd = startOfDay(new Date(endDate));
    const daysOffBetweenDates = calculateDays({
      startDate: dateStart,
      endDate: dateEnd
    });

    return (
      Math.abs(differenceInCalendarDays(dateStart, dateEnd)) +
      1 -
      daysOffBetweenDates.length
    );
  }, [startDate, endDate, calculateDays]);
}
