import React from 'react';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { QueryPagination } from '../../../common/components/ui/QueryPagination/QueryPagination';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { CardOffer } from '../../../common/components/ui/Cards/CardOffer/CardOffer';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { GridItem, LayoutGrid } from '../../../common/components/Layout/Layout';
import { useOffersPage } from '../../../graphql/hooks/offers/useOffersPage';
import { OffersFilter } from '../../../graphql/types';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { routeLinks } from '../../routeLinks';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { UserPermission } from '../../../utils/permissions';

export interface OffersProps {
  filter?: OffersFilter;
  history: AppHistoryType;
}

export const Offers: React.FC<OffersProps> = ({ filter, history }) => {
  const offersQuery = useOffersPage({ filter });
  const offers = offersQuery.state.data?.list;
  const header = offersQuery.state.data?.header;

  const { user: currentUser } = useCurrentUser();
  const canEdit = useHasPermission(currentUser, UserPermission.OfferCrud);

  return (
    queryLoader(offersQuery) ||
    (!offers?.length ? (
      <NoData />
    ) : (
      <>
        <LayoutGrid>
          {offers.map((offer) => (
            <GridItem key={offer.id} cols={12}>
              <CardOffer {...offer} isEditable={canEdit} />
            </GridItem>
          ))}
        </LayoutGrid>
        {header && (
          <QueryPagination header={header} pathname={routeLinks.offers.to} />
        )}
      </>
    ))
  );
};
