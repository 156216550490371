import React from 'react';
import { useFormatDate } from '../../../common/hooks/useFormatDate';
import s from './ArticleDate.module.scss';

export interface ArticleDateProps {
  data: string | null | undefined;
}

export const ArticleDate: React.FC<ArticleDateProps> = ({ data }) => {
  const date = useFormatDate(data);
  return <div className={s.ArticleDate}>{date}</div>;
};
