import React from 'react';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import s from './Numbers.module.scss';

export const Numbers = () => {
  return (
    <LayoutContent className={s.Numbers}>
      <div className={s.Numbers__box}>
        <Title variant={TitleVariant.h3} className={s.Numbers__title}>
          Физтех в цифрах
        </Title>

        <div className={s.Numbers__grid}>
          <div className={s.NumberCard}>
            <div className={s.NumberCard__title}>9000</div>
            <div className={s.NumberCard__text}>
              Общее число <br />
              студентов
            </div>
          </div>

          <div className={s.NumberCard}>
            <div className={s.NumberCard__title}>120+</div>
            <div className={s.NumberCard__text}>
              Научных <br />
              лабораторий
            </div>
          </div>

          <div className={s.NumberCard}>
            <div className={s.NumberCard__title}>46</div>
            <div className={s.NumberCard__text}>
              Член– <br />
              корреспондентов <br />
              РАН
            </div>
          </div>

          <div className={s.NumberCard}>
            <div className={s.NumberCard__title}>25</div>
            <div className={s.NumberCard__text}>
              Академиков <br />
              РАН
            </div>
          </div>

          <div className={s.NumberCard}>
            <div className={s.NumberCard__title}>20</div>
            <div className={s.NumberCard__text}>
              Научно-технических <br />
              и инженеринговых <br />
              центров
            </div>
          </div>
        </div>
      </div>
    </LayoutContent>
  );
};
