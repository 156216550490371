import React from 'react';
import {
  UserPageBlock,
  UserPageBlockVariant
} from '../../UserBlocks/UserPageBlock/UserPageBlock';
import { FormSection } from '../../Form/FormSection/FormSection';
import { userFormNames } from '../../../../../store/users/UserConstants';
import { Entity } from '../../AsyncSelect/entities/types';
import { FormAsyncEntitiesCreatableSelect } from '../../Form/FormAsyncEntitiesCreatableSelect/FormAsyncEntitiesCreatableSelect';

export const EditInterests = () => {
  return (
    <UserPageBlock
      variant={UserPageBlockVariant.small}
      title={'Сферы профессиональных интересов'}
    >
      <FormSection>
        <FormAsyncEntitiesCreatableSelect
          name={userFormNames.areas_of_interest}
          entity={Entity.areasOfInterestPage}
          placeholder={'Выберите профессиональные интересы'}
        />
      </FormSection>
    </UserPageBlock>
  );
};
