import React from 'react';
import { Card, CardStyle } from '../../../../common/components/ui/Card/Card';
import { Tag } from '../../../../common/components/ui/Tag/Tag';
import {
  Title,
  TitleVariant
} from '../../../../common/components/ui/Title/Title';
import { TextBlock } from '../../../../common/components/ui/ContentBlock/TextBlock/TextBlock';
import {
  ButtonAnchor,
  ButtonSize,
  ButtonVariant
} from '../../../../common/components/ui/Button';
import { ImagePreview } from '../../../../common/components/ui/ImagePreview/ImagePreview';
import { useFileSrc } from '../../../../common/hooks/useFileSrc';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { InternshipType } from '../../../../graphql/types';
import s from './Banner.module.scss';

export interface InternshipBannerProps extends InternshipType {}

export const Banner: React.FC<InternshipBannerProps> = ({
  name,
  cover_file_id,
  internship_subject,
  tags,
  description,
  link
}) => {
  const imageSrc = useFileSrc(cover_file_id, FileTypeEnum.internship_cover);

  return (
    <Card
      className={s.Banner}
      classes={{
        inner: s.Banner__inner
      }}
      cardStyle={CardStyle.gradient}
    >
      {imageSrc && (
        <div className={s.Banner__image}>
          <div className={s.Banner__imageInner}>
            <ImagePreview
              className={s.Banner__cover}
              classes={{
                image: s.Banner__coverImage
              }}
              imageSrc={imageSrc}
            />
            <div className={s.Banner__imageEffect} />
          </div>
        </div>
      )}

      <div className={s.Banner__content}>
        <div className={s.Banner__body}>
          {(tags || internship_subject) && (
            <div className={s.Banner__tags}>
              {internship_subject && (
                <>
                  <Tag className={s.Banner__tag}>{internship_subject.name}</Tag>
                  {tags && tags.length > 0 && (
                    <div className={s.Banner__tagDot} />
                  )}
                </>
              )}
              {tags?.map((tag) => (
                <Tag key={tag.id} className={s.Banner__tag}>
                  {tag.name}
                </Tag>
              ))}
            </div>
          )}

          <Title
            className={s.Banner__title}
            variant={TitleVariant.h2}
            title={name}
          />

          {description && (
            <TextBlock
              className={s.Banner__description}
              data={{ content: description }}
            />
          )}
        </div>

        {link && (
          <div className={s.Banner__actions}>
            <ButtonAnchor
              className={s.Banner__action}
              variant={ButtonVariant.secondary}
              size={ButtonSize.medium}
              href={link}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              Подать заявку
            </ButtonAnchor>
          </div>
        )}
      </div>
    </Card>
  );
};
