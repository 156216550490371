import React, { useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  UserPageBlock,
  UserPageBlockVariant
} from '../UserBlocks/UserPageBlock/UserPageBlock';
import { RolesPageType, UserType } from '../../../../graphql/types';
import { UserProps, UserUtils } from '../../../../store/users/UserUtils';
import { UserFormValidationSchema } from '../../../../store/validationSchema';
import { Button, ButtonSize, ButtonVariant } from '../Button';
import { EditAvatar } from '../UserEditBlocks/EditAvatar/EditAvatar';
import { EditGeneral } from '../UserEditBlocks/EditGeneral/EditGeneral';
import { EditSocials } from '../UserEditBlocks/EditSocials/EditSocials';
import { EditSkills } from '../UserEditBlocks/EditSkills/EditSkills';
import { EditInterests } from '../UserEditBlocks/EditInterests/EditInterests';
import { EditJobs } from '../UserEditBlocks/EditJobs/EditJobs';
import { EditPublish } from '../UserEditBlocks/EditPublish/EditPublish';
import { EditAbout } from '../UserEditBlocks/EditAbout/EditAbout';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { UserPermission } from '../../../../utils/permissions';
import { useAuthGraphqlQuery } from '../../../hooks/utils/useAuthGraphqlQuery';
import { QUERY_GET_ROLES } from '../../../../graphql/queries/roles';
import { PermissionsEditField } from '../Form/Permissions/PermissionsEditField';
import { Entity } from '../AsyncSelect/entities/types';
import { FormAsyncEntitiesCreatableSelect } from '../Form/FormAsyncEntitiesCreatableSelect/FormAsyncEntitiesCreatableSelect';
import s from './UserForm.module.scss';

export interface UserFormProps {
  user: UserType;
  onSubmit: (data: UserProps, helpers: FormikHelpers<UserProps>) => void;
  onCancel: () => void;
  submissionLoading?: boolean;
}

const queryOptions = {
  query: QUERY_GET_ROLES,
  mapData: (d) => d.rolesPage
};

export const UserForm: React.FC<UserFormProps> = ({
  user,
  onSubmit,
  onCancel,
  submissionLoading
}) => {
  const { user: currentUser } = useCurrentUser();
  const canEditPublications = useHasPermission(
    currentUser,
    UserPermission.UserEditPublications
  );
  const canEditWorkExperiences = useHasPermission(
    currentUser,
    UserPermission.UserEditWorkExperiences
  );
  const canEditSkillIds = useHasPermission(
    currentUser,
    UserPermission.UserEditSkillIds
  );
  const canEditAreasOfInterestIds = useHasPermission(
    currentUser,
    UserPermission.UserEditAreasOfInterestIds
  );
  const canEditAbout = useHasPermission(
    currentUser,
    UserPermission.UserEditAbout
  );
  const canEditPermissions = useHasPermission(
    currentUser,
    UserPermission.UserEditPermissions
  );
  const canEditRoles = useHasPermission(
    currentUser,
    UserPermission.UserEditRoles
  );

  const initialValues = useMemo(() => {
    return UserUtils.toForm(user);
  }, [user]);

  const rolesQuery = useAuthGraphqlQuery<any, RolesPageType>({
    queryOptions
  });

  const roles = useMemo(() => rolesQuery.state.data?.list, [rolesQuery.state]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, setFieldValue }) => {
        return (
          <Form>
            <UserPageBlock
              className={s.UserForm__avatar}
              variant={UserPageBlockVariant.small}
              title={'Фото'}
            >
              <EditAvatar fallbackId={user.c_avatar_file_id} />
            </UserPageBlock>

            <UserPageBlock title={'Общая информация'} isCollapse={true}>
              <EditGeneral
                birthday={user.birthday}
                email={user.email}
                degrees={user.degrees}
                contacts={user.contacts}
                workPhone={user.work_phone}
                mobilePhone={user.mobile_phone}
              />

              <EditSocials />
            </UserPageBlock>

            {canEditPublications && (
              <UserPageBlock
                title={'Публикации и исследования'}
                isCollapse={true}
              >
                <EditPublish values={values} />
              </UserPageBlock>
            )}

            {(canEditWorkExperiences ||
              canEditSkillIds ||
              canEditAreasOfInterestIds ||
              canEditAbout) && (
              <UserPageBlock
                title={'Профессиональные достижения'}
                isCollapse={true}
              >
                {canEditWorkExperiences && <EditJobs values={values} />}
                {canEditSkillIds && <EditSkills />}
                {canEditAreasOfInterestIds && <EditInterests />}
                {canEditAbout && <EditAbout />}
              </UserPageBlock>
            )}
            {canEditRoles && (
              <UserPageBlock title={'Управление ролями'}>
                <FormAsyncEntitiesCreatableSelect
                  entity={Entity.rolesPage}
                  name={'roles'}
                  isCreatable={false}
                />
              </UserPageBlock>
            )}
            {canEditPermissions && (
              <UserPageBlock title={'Управление правами'}>
                <PermissionsEditField
                  name={'permissions'}
                  roles={roles}
                  user={user}
                />
              </UserPageBlock>
            )}

            <div className={s.UserForm__actions}>
              <Button
                className={s.UserForm__actionButton}
                size={ButtonSize.large}
                variant={ButtonVariant.secondary}
                onClick={onCancel}
              >
                Отменить всё
              </Button>
              <Button
                className={s.UserForm__actionButton}
                size={ButtonSize.large}
                type={'submit'}
                loading={submissionLoading}
              >
                Сохранить изменения
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
