import { useStore } from '@proscom/prostore-react';
import { Redirect } from 'react-router';
import React from 'react';
import type { LocationDescriptor } from 'history';
import { AuthStore } from '../store/AuthStore';
import { STORE_AUTH } from '../store/storeKeys';
import { routeLinks } from './routeLinks';

export default function RedirectIfLoggedIn<Props>(
  Component: React.ComponentType<Props>,
  redirectTo: LocationDescriptor = routeLinks.main.to
) {
  return function RedirectIfLoggedIn(props: Props) {
    const [auth, authStore] = useStore<AuthStore>(STORE_AUTH);
    const user = auth.authData?.user;
    if (!authStore.isLoggedIn() || !user || auth.error)
      {return <Component {...props} />;}
    return <Redirect to={redirectTo} />;
  };
}
