import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { EMDASH } from '@proscom/ui-utils';
import { Card, CardStyle } from '../../Card/Card';
import { Title, TitleVariant } from '../../Title/Title';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { DATE_FORMAT_FULL, getDateUtc } from '../../../../../utils/date/date';
import { OfferType } from '../../../../../graphql/types';
import { useFileSrc } from '../../../../hooks/useFileSrc';
import { useOfferDelete } from '../../../../hooks/useOfferDelete';
import {
  ContextMenuButton,
  ContextMenuButtonPosition
} from '../../ContextMenu/ContextMenuButton';
import { routeLinks } from '../../../../../routes/routeLinks';
import { ImagePreview } from '../../ImagePreview/ImagePreview';
import { TextLink } from '../../TextLink/TextLink';
import { PartnerLogo } from '../../PartnerLogo/PartnerLogo';
import s from './CardOffer.module.scss';

export interface CardOfferProps extends OfferType {
  isEditable?: boolean;
}

export const CardOffer: React.FC<CardOfferProps> = ({
  id,
  expires_at,
  cover_file_id,
  name,
  snippet,
  partner_logo_file_id,
  isEditable
}) => {
  const coverImgSrc = useFileSrc(cover_file_id, FileTypeEnum.offer_cover);
  const partnerLogoSrc = useFileSrc(
    partner_logo_file_id,
    FileTypeEnum.offer_partner_logo
  );
  const date = useFormatDate(getDateUtc(expires_at), DATE_FORMAT_FULL);
  const link = `${routeLinks.offers.to}/${id}`;

  const { handleDelete } = useOfferDelete(id);

  const editActions = useMemo(() => {
    return [
      {
        label: 'Редактировать',
        to: `${routeLinks.offers.to}/${id}/edit`
      },
      {
        label: 'Удалить',
        onClick: () => handleDelete()
      }
    ];
  }, [id, handleDelete]);

  return (
    <Card
      cardStyle={CardStyle.bordered}
      classes={{
        root: s.CardOffer,
        inner: s.CardOffer__inner
      }}
    >
      <div>
        <Link to={link}>
          <ImagePreview
            className={s.CardOffer__cover}
            classes={{
              image: s.CardOffer__coverImage
            }}
            imageSrc={coverImgSrc}
            showBackfaceImage
          />
        </Link>
      </div>

      <div className={s.CardOffer__right}>
        <div>
          <div className={s.CardOffer__head}>
            <Link to={link} tabIndex={-1} className={s.CardOffer__title}>
              <Title title={name} variant={TitleVariant.h4} />
            </Link>

            {isEditable && (
              <ContextMenuButton
                className={s.CardOffer__contextMenu}
                items={editActions}
                position={ContextMenuButtonPosition.left}
              />
            )}
          </div>

          <div className={s.CardOffer__snippet}>{snippet}</div>
        </div>

        <div
          className={clsx(s.CardOffer__footer, {
            [s.CardOffer__footer_withPartner]: !!partnerLogoSrc
          })}
        >
          {partnerLogoSrc && <PartnerLogo imageSrc={partnerLogoSrc} />}
          <div className={s.CardOffer__date}>
            Срок действия:{' '}
            <span className={s.CardOffer__dateDate}>
              {date ? `до ${date}` : EMDASH}
            </span>
          </div>
          <div>
            <TextLink
              className={s.CardOffer__link}
              to={link || ''}
              noUnderline
              showArrow
            >
              Перейти
            </TextLink>
          </div>
        </div>
      </div>
    </Card>
  );
};
