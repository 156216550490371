import { IUseGraphqlWatchQueryOptions } from '@proscom/prostore-apollo-react';
import { QUERY_GET_PROJECTS } from '../../queries/projects';
import { ProjectPageInput, ProjectsPageType } from '../../types';
import { useAuthGraphqlQuery } from '../../../common/hooks/utils/useAuthGraphqlQuery';
import { ONE_PAGE_ITEMS } from '../../../constants';

const queryOptions: IUseGraphqlWatchQueryOptions<
  { input: ProjectPageInput },
  ProjectsPageType
> = {
  query: QUERY_GET_PROJECTS,
  mapData: (result) => result.projectsPage
};

export function useProjects({ filter, pagination }: ProjectPageInput) {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: {
      input: {
        filter,
        pagination: {
          onePage: ONE_PAGE_ITEMS,
          ...pagination
        }
      }
    }
  });
}
