import React from 'react';
import clsx from 'clsx';
import { Title, TitleVariant } from '../../Title/Title';
import s from './FormSection.module.scss';

export enum FormSectionVariant {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export interface FormSectionProps {
  className?: string;
  variant?: FormSectionVariant;
  title?: string;
  titleVariant?: TitleVariant;
  description?: React.ReactNode;
}

export const FormSection: React.FC<FormSectionProps> = ({
  className,
  variant = FormSectionVariant.medium,
  title,
  titleVariant = TitleVariant.h2,
  description,
  children
}) => {
  return (
    <div
      className={clsx(s.FormSection, s[`FormSection_${variant}`], className)}
    >
      {title && (
        <Title
          title={title}
          variant={titleVariant}
          className={s.FormSection__title}
        />
      )}
      {children}
      {description && (
        <div className={s.FormSection__description}>{description}</div>
      )}
    </div>
  );
};
