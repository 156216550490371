import React from 'react';
import clsx from 'clsx';
import { ResponsiveObject } from 'react-slick';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { GridItem, LayoutGrid } from '../../../common/components/Layout/Layout';
import {
  CardMainWide,
  CardMainWideProps
} from '../../../common/components/ui/Cards/CardMainWide/CardMainWide';
import { SliderConvertible } from '../../../common/components/ui/Slider/Slider';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import s from './MainSlider.module.scss';

export interface MainSliderSlide extends CardMainWideProps {}

export interface MainSliderProps {
  slides: MainSliderSlide[];
}

const sliderResponsive: ResponsiveObject[] = [
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 2
    }
  },
  {
    breakpoint: 750,
    settings: {
      slidesToShow: 1
    }
  }
];
const sliderCfg = {
  slidesToShow: 3,
  arrows: true,
  dots: true,
  infinite: false,
  responsive: sliderResponsive
};

export const MainSlider: React.FC<MainSliderProps> = ({ slides }) => {
  const { isTablet, isMobile } = useWindowSize();

  if (!slides || slides.length < 1) {
    return <NoData />;
  }

  if (isMobile) {
    return (
      <div className={s.MainSlider_mobile}>
        {slides.map((slide, iSlide) => (
          <CardMainWide key={iSlide} {...slide} />
        ))}
      </div>
    );
  }

  return (
    <SliderConvertible
      className={s.MainSlider}
      classes={{
        buttonPrev: s.MainSlider__sliderButton,
        buttonNext: s.MainSlider__sliderButton
      }}
      config={sliderCfg}
      convertedContent={
        <LayoutGrid className={s.MainSlider__grid}>
          {slides.map((slide, iSlide) => (
            <GridItem key={iSlide} cols={4}>
              <CardMainWide
                {...slide}
                className={clsx(s.MainSlider__card, slide.className)}
              />
            </GridItem>
          ))}
        </LayoutGrid>
      }
    >
      {slides.map((slide, iSlide) => (
        <div key={iSlide}>
          <CardMainWide
            {...slide}
            className={clsx(s.MainSlider__card, slide.className)}
          />
        </div>
      ))}
    </SliderConvertible>
  );
};
