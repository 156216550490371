import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { Formik } from 'formik';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import {
  Breadcrumbs,
  IBreadcrumbsEntity
} from '../../../common/components/ui/Breadcrumbs';
import { getArticlePageBreadcrumbs } from '../index/ArticlePage';
import { ArticleForm } from '../ArticleForm/ArticleForm';
import { useArticle } from '../../../graphql/hooks/useArticle';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { Articles } from '../../../store/articles/Articles';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import {
  ArticleProps,
  ArticleUtils
} from '../../../store/articles/ArticleUtils';
import { routeLinks } from '../../routeLinks';
import s from './ArticleEditPage.module.scss';

export interface ArticleEditPageProps
  extends RouteComponentProps<{ articleId: string }> {
  history: AppHistoryType;
}

export function getArticleEditPageBreadcrumbs({
  article
}: {
  article: IBreadcrumbsEntity;
}) {
  return [
    ...getArticlePageBreadcrumbs({ article }),
    {
      name: 'Редактирование',
      path: `${routeLinks.articles.to}/${article.id}/edit`
    }
  ];
}

export default function ArticleEditPage({
  match,
  history
}: ArticleEditPageProps) {
  const { articleId } = match.params;
  const updateArticle = useGraphqlMutation(Articles.updateArticle);
  const updateArticleRun = updateArticle.run;

  const articleQuery = useArticle(articleId);
  const articleData = articleQuery.state.data;
  const article = useMemo(
    () => articleData && ArticleUtils.toForm(articleData),
    [articleData]
  );

  const breadcrumbs = useMemo(
    () =>
      getArticleEditPageBreadcrumbs({
        article: { id: articleId, name: article?.title }
      }),
    [articleId, article]
  );

  const handleSubmit = useCallback(
    (data: ArticleProps) => {
      const input = ArticleUtils.fromUpdateForm(data);

      updateArticleRun({ variables: { input } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Новость обновлена');
            history.push(`/articles/${result.data.result.id}`);
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при изменении новости. Попробуйте снова'
          );
        });
    },
    [updateArticleRun, history]
  );

  const handleCancel = useCallback(() => {
    if (window.confirm('Вы действительно хотите отменить редактирование?')) {
      history.goBack(`${routeLinks.articles.to}/${articleId}`);
    }
  }, [articleId, history]);

  return (
    <PageLayout
      breadcrumbs={<Breadcrumbs items={breadcrumbs} />}
      contentClassName={s.ArticleEditPage}
    >
      <LayoutContent>
        <div className={s.ArticleEditPage__content}>
          <PageTitle
            className={s.ArticleEditPage__pageTitle}
            title={'Редактирование новости'}
            showHeading
          />

          {article && (
            <Formik
              onSubmit={handleSubmit}
              initialValues={article}
              validationSchema={ArticleUtils.validationSchema}
            >
              {(props) => (
                <ArticleForm
                  onCancel={handleCancel}
                  submissionLoading={updateArticle.loading}
                  {...props}
                />
              )}
            </Formik>
          )}
        </div>
      </LayoutContent>
    </PageLayout>
  );
}
