import React from 'react';
import { EMDASH } from '@proscom/ui-utils';
import { TextBlock } from '../../ContentBlock/TextBlock/TextBlock';

export interface UserAboutProps {
  about?: string | null;
}

export const UserAbout: React.FC<UserAboutProps> = ({ about }) => {
  return (
    <TextBlock
      data={{
        content: about || EMDASH
      }}
    />
  );
};
