import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Card, CardStyle } from '../../Card/Card';
import {
  ContextMenuButton,
  ContextMenuButtonPosition
} from '../../ContextMenu/ContextMenuButton';
import { routeLinks } from '../../../../../routes/routeLinks';
import { ImagePreview } from '../../ImagePreview/ImagePreview';
import { TextLink } from '../../TextLink/TextLink';
import { useFileSrc } from '../../../../hooks/useFileSrc';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { Tag } from '../../Tag/Tag';
import { InternshipType } from '../../../../../graphql/types';
import { Title, TitleVariant } from '../../Title/Title';
import { HTMLTextContainer } from '../../HTMLTextContainer/HTMLTextContainer';
import s from './CardInternship.module.scss';

export interface CardInternshipProps extends InternshipType {
  to: string;
  isEditable?: boolean;
  onDeleteClick?: (id: string) => void;
  onTagClick?: (tagId: string) => void;
  onSubjectClick?: (tagId: string) => void;
}

export const CardInternship: React.FC<CardInternshipProps> = ({
  id,
  cover_file_id,
  name,
  description,
  tags,
  internship_subject,
  to,
  isEditable,
  onDeleteClick,
  onTagClick,
  onSubjectClick
}) => {
  const coverImgSrc = useFileSrc(cover_file_id, FileTypeEnum.internship_cover);

  const editActions = useMemo(() => {
    return [
      {
        label: 'Редактировать',
        to: `${routeLinks.internships.to}/${id}/edit`
      },
      {
        label: 'Удалить',
        onClick: () => onDeleteClick?.(id)
      }
    ];
  }, [id, onDeleteClick]);

  const hasTags = (tags && tags.length > 0) || internship_subject;

  return (
    <Card
      cardStyle={CardStyle.bordered}
      classes={{
        root: s.CardInternship,
        inner: s.CardInternship__inner
      }}
    >
      <div>
        <Link to={to} tabIndex={-1}>
          <ImagePreview
            className={s.CardInternship__cover}
            classes={{
              image: s.CardInternship__coverImage
            }}
            imageSrc={coverImgSrc}
            placeholderProps={{
              iconProps: {
                boxSize: 40
              }
            }}
            showBackfaceImage
          />
        </Link>
      </div>

      <div className={s.CardInternship__content}>
        <div
          className={clsx(s.CardInternship__head, {
            [s.CardInternship__head_hasTags]: hasTags
          })}
        >
          {hasTags && (
            <div className={s.CardInternship__tags}>
              {internship_subject && (
                <Tag
                  className={s.CardInternship__tag}
                  isClickable={true}
                  isSelected={true}
                  onClick={() => onSubjectClick?.(internship_subject.id)}
                >
                  {internship_subject.name}
                </Tag>
              )}
              {tags?.map((tag) => (
                <Tag
                  key={tag.id}
                  className={s.CardInternship__tag}
                  isClickable={true}
                  onClick={() => onTagClick?.(tag.id)}
                >
                  {tag.name}
                </Tag>
              ))}
            </div>
          )}

          {isEditable && (
            <ContextMenuButton
              className={s.CardInternship__contextMenu}
              items={editActions}
              position={ContextMenuButtonPosition.left}
            />
          )}

          <Link to={to} tabIndex={-1} className={s.CardInternship__headLink}>
            <Title title={name} variant={TitleVariant.h4} />
            <HTMLTextContainer
              className={s.CardInternship__description}
              htmlString={description}
            />
          </Link>
        </div>

        <div className={s.CardInternship__footer}>
          <TextLink
            className={s.CardInternship__link}
            to={to || ''}
            noUnderline
            showArrow
          >
            Перейти
          </TextLink>
        </div>
      </div>
    </Card>
  );
};
