import { MutationOptions } from 'apollo-client';
import {
  DocumentCreateInput,
  DocumentSectionCreateInput,
  DocumentSectionType,
  DocumentSectionUpdateInput,
  DocumentUpdateInput
} from '../../graphql/types';
import {
  MUTATION_CREATE_DOCUMENT,
  MUTATION_CREATE_DOCUMENT_SECTION,
  MUTATION_DELETE_DOCUMENT,
  MUTATION_DELETE_DOCUMENT_SECTION,
  MUTATION_UPDATE_DOCUMENT,
  MUTATION_UPDATE_DOCUMENT_SECTION
} from '../../graphql/mutations/documents';
import { clearCache } from '../../graphql/queriesUtils';

export class Documents {
  private static refetchQueries = ['queryGetDocumentSections'];
  private static updateQueries = ['documentSections'];
  private static updateFragments = ['DocumentType', 'DocumentSectionType'];

  public static CreateDocumentSection: MutationOptions<
    {
      result: DocumentSectionType;
    },
    {
      input: DocumentSectionCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_DOCUMENT_SECTION,
    refetchQueries: Documents.refetchQueries,
    update: clearCache(Documents.updateQueries, Documents.updateFragments)
  };

  public static UpdateDocumentSection: MutationOptions<
    {
      result: DocumentSectionType;
    },
    {
      input: DocumentSectionUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_DOCUMENT_SECTION,
    refetchQueries: Documents.refetchQueries,
    update: clearCache(Documents.updateQueries, Documents.updateFragments)
  };

  public static DeleteDocumentSection: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_DELETE_DOCUMENT_SECTION,
    refetchQueries: Documents.refetchQueries,
    update: clearCache(Documents.updateQueries, Documents.updateFragments)
  };

  public static CreateDocument: MutationOptions<
    {
      result: DocumentType;
    },
    {
      input: DocumentCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_DOCUMENT,
    refetchQueries: Documents.refetchQueries,
    update: clearCache(Documents.updateQueries, Documents.updateFragments)
  };

  public static UpdateDocument: MutationOptions<
    {
      result: DocumentType;
    },
    {
      input: DocumentUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_DOCUMENT,
    refetchQueries: Documents.refetchQueries,
    update: clearCache(Documents.updateQueries, Documents.updateFragments)
  };

  public static DeleteDocument: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_DELETE_DOCUMENT,
    refetchQueries: Documents.refetchQueries,
    update: clearCache(Documents.updateQueries, Documents.updateFragments)
  };
}
