import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { EMDASH } from '@proscom/ui-utils';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes';
import { ErrorBoundary } from './common/components/utils/ErrorBoundary';
import { ErrorPage } from './common/components/ui/ErrorPage/ErrorPage';
import { useScrollTopOnRouteChange } from './common/hooks/useScrollTopOnRouteChange';
import { YandexMetrika } from './common/components/utils/YandexMetrika';
import { isReactNativeApp } from './utils/native';

const siteTitle = 'HR-портал МФТИ';

export function App() {
  useScrollTopOnRouteChange();

  useEffect(() => {
    document.documentElement.classList.toggle('mobile-app', isReactNativeApp());
  }, []);

  return (
    <Fragment>
      <Helmet
        defaultTitle={siteTitle}
        titleTemplate={`%s ${EMDASH} ${siteTitle}`}
      />
      <ErrorBoundary component={ErrorPage}>
        <AppRoutes />
      </ErrorBoundary>
      <ToastContainer />
      <YandexMetrika />
    </Fragment>
  );
}
