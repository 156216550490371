import React from 'react';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { BlueLink } from '../../../common/components/ui/BlueLink/BlueLink';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import s from './MainPageSection.module.scss';

export interface MainPageSectionProps {
  title: string;
  link?: string;
}

export const MainPageSection: React.FC<MainPageSectionProps> = ({
  title,
  link,
  children
}) => {
  const { isMobile } = useWindowSize();

  return (
    <div className={s.MainPageSection}>
      <div className={s.MainPageSection__head}>
        <Title variant={TitleVariant.h2} title={title} />
        {!isMobile && link && (
          <BlueLink to={link} text={'Смотреть все'} hasArrow />
        )}
      </div>
      <div>{children}</div>

      {isMobile && link && (
        <div className={s.MainPageSection__mobileLink}>
          <BlueLink to={link} text={'Смотреть все'} hasArrow />
        </div>
      )}
    </div>
  );
};
