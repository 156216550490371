import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardStyle } from '../../Card/Card';
import { Title, TitleVariant } from '../../Title/Title';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { useFileSrc } from '../../../../hooks/useFileSrc';
import {
  ContextMenuButton,
  ContextMenuButtonPosition
} from '../../ContextMenu/ContextMenuButton';
import { routeLinks } from '../../../../../routes/routeLinks';
import { ImagePreview } from '../../ImagePreview/ImagePreview';
import { TextLink } from '../../TextLink/TextLink';
import { PersonnelReserveType } from '../../../../../graphql/types';
import s from './CardReserve.module.scss';

export interface CardReserveProps extends PersonnelReserveType {
  to: string;
  isEditable?: boolean;
  onDeleteClick?: (id: string) => void;
}

export const CardReserve: React.FC<CardReserveProps> = ({
  id,
  to,
  name,
  cover_file_id,
  snippet,
  isEditable,
  onDeleteClick
}) => {
  const coverImgSrc = useFileSrc(
    cover_file_id,
    FileTypeEnum.personnel_reserve_cover
  );

  const editActions = useMemo(() => {
    return [
      {
        label: 'Редактировать',
        to: `${routeLinks.reserve.to}/${id}/edit`
      },
      {
        label: 'Удалить',
        onClick: () => onDeleteClick?.(id)
      }
    ];
  }, [id, onDeleteClick]);

  return (
    <Card
      cardStyle={CardStyle.bordered}
      classes={{
        root: s.CardReserve,
        inner: s.CardReserve__inner
      }}
    >
      <div>
        <Link to={to}>
          <ImagePreview
            className={s.CardReserve__cover}
            classes={{
              image: s.CardReserve__coverImage
            }}
            imageSrc={coverImgSrc}
            showBackfaceImage
          />
        </Link>
      </div>

      <div className={s.CardReserve__right}>
        <div>
          <div className={s.CardReserve__head}>
            <Link to={to} tabIndex={-1} className={s.CardReserve__title}>
              <Title title={name} variant={TitleVariant.h4} />
            </Link>

            {isEditable && (
              <ContextMenuButton
                className={s.CardReserve__contextMenu}
                items={editActions}
                position={ContextMenuButtonPosition.left}
              />
            )}
          </div>

          <div className={s.CardReserve__snippet}>{snippet}</div>
        </div>

        <div className={s.CardReserve__footer}>
          <TextLink
            className={s.CardReserve__link}
            to={to}
            noUnderline
            showArrow
          >
            Перейти
          </TextLink>
        </div>
      </div>
    </Card>
  );
};
