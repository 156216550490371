import { useCallback } from 'react';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { MutationOptions } from 'apollo-client';
import { MUTATION_CREATE_OTT_FOR_FILE_DOWNLOAD } from '../../graphql/mutations/oneTimeTokens';
import { fileDownloadUrl } from '../../config';
import { handleDefaultError } from '../../utils/handleDefaultError';
import { FileTypeEnum } from '../../store/FileTypeEnum';
import { useGraphqlMutation } from './utils/useGraphqlMutation';

export type FileDownloadOTTMutationObjectType = MutationOptions<
  { result: string },
  { fileId: string }
>;

const fileDownloadOtt: FileDownloadOTTMutationObjectType = {
  mutation: MUTATION_CREATE_OTT_FOR_FILE_DOWNLOAD
};

export interface FileDownloadProps {
  apiURl?: string;
  type?: FileTypeEnum;
  ottMutation?: FileDownloadOTTMutationObjectType;
}

export const useFileDownload = (
  apiURl: string = fileDownloadUrl,
  type: FileTypeEnum = FileTypeEnum.files,
  ottMutation: FileDownloadOTTMutationObjectType = fileDownloadOtt
) => {
  const { run, loading } = useGraphqlMutation(ottMutation);
  const handleDownloadFile = useCallback(
    (fileId) => () => {
      run({
        variables: {
          fileId
        }
      })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error(
              'Unexpected response. Expected to have { result: string }'
            );
          }

          const fileUrl = `${apiURl}/${type}/${fileId}?token=${result.data?.result}`;
          const fileWindow = window.open(fileUrl);

          // Если не получилось открыть ссылку в новом окне, открываем в текущем
          if (fileWindow === null) {
            window.location.href = fileUrl;
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(err, 'Невозможно скачать файл. Попробуйте снова');
        });
    },
    [run, apiURl, type]
  );
  return {
    loading,
    handle: handleDownloadFile
  };
};
