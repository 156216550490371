import { MutationOptions } from 'apollo-client';
import { UserSettingsInput, UserSettingsType } from '../../graphql/types';
import { MUTATION_UPDATE_USER_SETTINGS } from '../../graphql/mutations/userSettings';
import { clearCache } from '../../graphql/queriesUtils';

export class UserSettings {
  private static refetchQueries = ['queryGetUsers', 'queryGetUser'];
  private static updateQueries = ['usersPage', 'user'];
  private static updateFragments = ['UserType', 'UserPageType'];

  public static updateSettings: MutationOptions<
    { result: UserSettingsType },
    { input: UserSettingsInput }
  > = {
    mutation: MUTATION_UPDATE_USER_SETTINGS,
    refetchQueries: UserSettings.refetchQueries,
    update: clearCache(UserSettings.updateQueries, UserSettings.updateFragments)
  };
}
