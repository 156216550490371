import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Extends } from '@proscom/ui-utils';
import { BaseButton, BaseButtonProps, ButtonSize } from '../Button';
import {
  addStaticProperties,
  propsSplitter
} from '../../../../utils/reactTypes';
import { omitValues } from '../../../../utils/objects';
import s from './IconButton.module.scss';

export interface IconButtonClasses {
  root?: string;
  icon?: string;
}

export interface IconButtonSelfProps {
  icon: ReactNode;
  classes?: IconButtonClasses;
  rounded?: boolean;
}

export type BaseIconButtonProps = Extends<
  IconButtonSelfProps,
  Omit<BaseButtonProps, 'iconLeft' | 'iconRight'>
>;

const buttonOmittedKeys = omitValues(
  BaseButton.baseKeys,
  'iconLeft' as const,
  'iconRight' as const
);

const baseKeys = [...buttonOmittedKeys, 'icon', 'rounded'] as const;

const splitProps = propsSplitter(baseKeys);

export const BaseIconButton = addStaticProperties(
  function BaseIconButton({
    size = ButtonSize.medium,
    rounded,
    classes,
    icon,
    variant,
    ...props
  }: BaseIconButtonProps) {
    return (
      <BaseButton
        classes={{
          root: clsx(
            s.IconButton,
            s[`IconButton_${size}`],
            s[`IconButton_${variant}`],
            {
              [s.IconButton_rounded]: rounded
            },
            classes?.root
          ),
          iconLeft: clsx(s.IconButton__icon, classes?.icon)
        }}
        size={size}
        variant={variant}
        iconLeft={icon}
        {...props}
      />
    );
  },
  {
    splitProps,
    baseKeys
  }
);
