import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { QueryPagination } from '../../../common/components/ui/QueryPagination/QueryPagination';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { GridItem, LayoutGrid } from '../../../common/components/Layout/Layout';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { routeLinks } from '../../routeLinks';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { UserPermission } from '../../../utils/permissions';
import { CardReserve } from '../../../common/components/ui/Cards/CardReserve/CardReserve';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { usePersonnelReserves } from '../../../graphql/hooks/personnelReserve/usePersonnelReservePage';
import {
  PersonnelReserveFilter,
  PersonnelReserveSortingFieldsEnum,
  SortingDirectionEnum
} from '../../../graphql/types';
import { PersonnelReserve } from '../../../store/personnelReserve/PersonnelReserve';

export interface ReserveListProps {
  filter?: PersonnelReserveFilter;
  history: AppHistoryType;
}

export const ReserveList: React.FC<ReserveListProps> = ({
  filter,
  history
}) => {
  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.PersonnelReserveCrud);

  const ReserveListQuery = usePersonnelReserves({
    filter,
    sorting: [
      {
        field: PersonnelReserveSortingFieldsEnum.CreatedAt,
        direction: SortingDirectionEnum.Desc
      }
    ]
  });
  const ReserveList = ReserveListQuery.state.data?.list;
  const header = ReserveListQuery.state.data?.header;
  const hasReserves = ReserveList && ReserveList.length > 0;

  const deleteReserve = useGraphqlMutation(PersonnelReserve.deleteReserve);
  const deleteReserveRun = deleteReserve.run;

  const handleDeleteClick = useCallback(
    (id) => {
      if (window.confirm('Вы действительно хотите удалить кадровый резерв?')) {
        deleteReserveRun({ variables: { id } })
          .then((result) => {
            if (!result.data?.result) {
              throw new Error('UnexpectedResult');
            }

            toast.success('Кадровый резерв удален');
          })
          .catch((err) => {
            if (err instanceof AsyncSingletonError) return;
            handleDefaultError(
              err,
              'Произошла ошибка при удалении кадрового резерва'
            );
          });
      }
    },
    [deleteReserveRun]
  );

  return (
    queryLoader(ReserveListQuery) ||
    (!hasReserves && <NoData />) || (
      <>
        <LayoutGrid>
          {ReserveList?.map((item) => (
            <GridItem key={item.id} cols={12}>
              <CardReserve
                {...item}
                to={`${routeLinks.reserve.to}/${item.id}`}
                onDeleteClick={handleDeleteClick}
                isEditable={canEdit}
              />
            </GridItem>
          ))}
        </LayoutGrid>
        {header && (
          <QueryPagination header={header} pathname={routeLinks.reserve.to} />
        )}
      </>
    )
  );
};
