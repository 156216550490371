import React from 'react';
import {
  FilterSelectAsync,
  FilterSelectAsyncProps
} from '../../ui/Filter/FilterSelectAsync';
import { Entity } from '../../ui/AsyncSelect/entities/types';

export interface FilterDirectorsProps
  extends Omit<FilterSelectAsyncProps<Entity.usersPage>, 'entity'> {}

export const FilterDirector: React.FC<FilterDirectorsProps> = ({
  value,
  ...props
}) => {
  return (
    <FilterSelectAsync
      entity={Entity.usersPage}
      filter={{
        is_director: true
      }}
      value={value}
      placeholder={props.placeholder || `Выберите руководителя`}
      {...props}
    />
  );
};
