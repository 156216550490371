import React, { useCallback } from 'react';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import {
  TransformedQueryParams,
  URL_KEY_PAGE,
  URL_KEY_SEARCH
} from '../../../store/urlKeys';
import { STORE_LOCATION } from '../../../store/storeKeys';
import { FilterSearch } from '../../../common/components/ui/Filter/FilterSearch';
import { ProjectFilter } from '../../../graphql/types';
import s from './ProjectsPage.module.scss';

export const useProjectsFilter = () => {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const query = (locationState.query || {}) as TransformedQueryParams;
  const changeQuery = locationStore.changeQuery;

  const filter: ProjectFilter = {
    search: query[URL_KEY_SEARCH]
    // participant_ids: query[URL_KEY_PARTICIPANT]
  };

  const handleFilterChange = useCallback(
    (key: string, valueConverter?: (value) => any) => {
      return (value) => {
        const val = valueConverter ? valueConverter(value) : value;

        changeQuery({
          [key]: val,
          [URL_KEY_PAGE]: 1
        });
      };
    },
    [changeQuery]
  );

  const filterComponent: React.ReactNode = (
    <div className={s.ProjectsFilter}>
      <FilterSearch
        value={filter.search || ''}
        onChange={handleFilterChange(URL_KEY_SEARCH)}
      />
      {/*<div className={s.ProjectsFilter__filters}>*/}
      {/*  <FilterProjectStatus*/}
      {/*    className={s.ProjectsFilter__filter}*/}
      {/*    label={'Статус проекта'}*/}
      {/*    value={filter.status}*/}
      {/*    onChange={handleFilterChange(URL_KEY_PROJECT_STATUS)}*/}
      {/*    isClearable*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );

  return [filterComponent, filter] as const;
};
