import React from 'react';
import { SpecificContentBlockEditableProps } from '../types';
import { TextArea } from '../../TextField/TextArea';
import { IContentBlockquote } from '../../Form/FormContentEditor/Content';

export const BlockquoteBlockEditable: React.FC<
  SpecificContentBlockEditableProps<IContentBlockquote>
> = ({ data, className, onChange }) => {
  return (
    <TextArea
      className={className}
      rows={3}
      value={data.content || ''}
      onChange={(content) => onChange({ content })}
    />
  );
};
