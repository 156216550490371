import React from 'react';
import {
  FilterSelectAsync,
  FilterSelectAsyncProps
} from '../../ui/Filter/FilterSelectAsync';
import { Entity } from '../../ui/AsyncSelect/entities/types';

export interface FilterUnitsProps
  extends Omit<FilterSelectAsyncProps<Entity.usersPage>, 'entity'> {}

export const FilterUnit: React.FC<FilterUnitsProps> = ({ value, ...props }) => {
  return (
    <FilterSelectAsync
      entity={Entity.unitsPage}
      value={value}
      placeholder={props.placeholder || `Выберите отдел`}
      {...props}
    />
  );
};
