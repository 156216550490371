import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import {
  GridItem,
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  Breadcrumbs,
  getBreadcrumbItemEntity,
  IBreadcrumbsEntity,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import {
  Button,
  ButtonBaseColor,
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { UserPermission } from '../../../utils/permissions';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { getReservesPageBreadcrumbs } from '../list/ReservesPage';
import { routeLinks } from '../../routeLinks';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { usePersonnelReserve } from '../../../graphql/hooks/personnelReserve/usePersonnelReserve';
import { PersonnelReserve } from '../../../store/personnelReserve/PersonnelReserve';
import { Reserve } from './Reserve/Reserve';
import s from './ReservePage.module.scss';

export interface ReservePageProps
  extends RouteComponentProps<{ reserveId: string }> {
  history: AppHistoryType;
}

export interface GetReservePageBreadcrumbsArgs {
  reserve: IBreadcrumbsEntity;
}

export function getReservePageBreadcrumbs({
  reserve
}: GetReservePageBreadcrumbsArgs): IBreadcrumbsItem[] {
  return [
    ...getReservesPageBreadcrumbs(),
    getBreadcrumbItemEntity(routeLinks.reserve.to, reserve)
  ];
}

export default function ReservePage({ match, history }: ReservePageProps) {
  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.PersonnelReserveCrud);
  const { isMobile, isTablet } = useWindowSize();

  const { reserveId } = match.params;
  const reserveQuery = usePersonnelReserve(reserveId);
  const reserve = reserveQuery.state.data;

  const deleteReserve = useGraphqlMutation(PersonnelReserve.deleteReserve);
  const deleteReserveRun = deleteReserve.run;
  const deleteReserveLoading = deleteReserve.loading;

  const breadcrumbs = useMemo(
    () =>
      getReservePageBreadcrumbs({
        reserve: {
          id: reserveId,
          name: reserve?.name
        }
      }),
    [reserveId, reserve?.name]
  );

  const handleDeleteClick = useCallback(() => {
    if (window.confirm('Вы действительно хотите удалить кадровый резерв?')) {
      deleteReserveRun({ variables: { id: reserveId } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }

          toast.success('Кадровый резерв удален');
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при удалении кадрового резерва'
          );
        });
    }
  }, [deleteReserveRun, reserveId]);

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        {queryLoader(reserveQuery) || (!reserve && <NoData />) || (
          <LayoutGrid>
            <GridItem cols={isMobile ? 12 : 8}>
              {reserve && <Reserve {...reserve} />}
            </GridItem>
            <GridItem cols={isMobile ? 12 : isTablet ? 3 : 4}>
              {canEdit && reserve && (
                <div className={s.ReservePage__actions}>
                  <ButtonLink
                    to={`${routeLinks.reserve.to}/${reserveId}/edit`}
                    className={s.ReservePage__action}
                    size={ButtonSize.small}
                    variant={ButtonVariant.secondary}
                  >
                    Редактировать
                  </ButtonLink>
                  <Button
                    className={s.ReservePage__action}
                    size={ButtonSize.small}
                    variant={ButtonVariant.secondary}
                    baseColor={ButtonBaseColor.red}
                    onClick={handleDeleteClick}
                    loading={deleteReserveLoading}
                  >
                    Удалить
                  </Button>
                </div>
              )}
            </GridItem>
          </LayoutGrid>
        )}
      </LayoutContent>
    </PageLayout>
  );
}
