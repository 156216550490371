import React from 'react';
import { useField } from 'formik';
import { CoverLoader, CoverLoaderProps } from '../../CoverLoader/CoverLoader';

export interface FormCoverLoaderProps
  extends Omit<
    CoverLoaderProps,
    'coverImageId' | 'onImageUploaded' | 'onImageRemoved'
  > {
  name: string;
}

export const FormCoverLoader: React.FC<FormCoverLoaderProps> = ({
  name,
  ...props
}) => {
  const [{ value }, meta, { setValue }] = useField(name);
  const { error, touched } = meta;

  return (
    <CoverLoader
      coverImageId={value}
      onImageUploaded={(id) => {
        setValue(id);
      }}
      onImageRemoved={() => {
        setValue(null);
      }}
      errorMessage={touched && error ? error : undefined}
      {...props}
    />
  );
};
