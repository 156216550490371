import ReactDOM from 'react-dom';

/**
 * Создаёт группировщик для обновлений React.
 * Позволяет оптимизировать ситуации, когда
 * много компонентов обновляются одновременно.
 */
export function createReactDOMBatcher() {
  const batchQueue: Array<() => void> = [];
  let batchTimeout: any = null;

  function executeQueue() {
    batchTimeout = null;
    ReactDOM.unstable_batchedUpdates(() => {
      for (let cb of batchQueue) {
        cb();
      }
    });
    batchQueue.length = 0;
  }

  function queueFunction(cb) {
    batchQueue.push(cb);
    if (!batchTimeout) {
      batchTimeout = setTimeout(executeQueue, 0);
    }
  }

  return queueFunction;
}
