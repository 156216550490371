import React, { useCallback, useEffect, useState } from 'react';
import { useLiveInput } from '@proscom/ui-react';
import clsx from 'clsx';
import { SearchField, SearchFieldProps } from '../SearchField/SearchField';
import { TextFieldClasses, TextFieldSize } from '../TextField/TextField';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { Filter, FilterClasses, FilterProps } from './Filter';
import s from './Filter.module.scss';

export interface FilterSearchClasses {
  filter?: FilterClasses;
  searchField?: TextFieldClasses;
}

export interface FilterSearchProps
  extends Omit<FilterProps, 'classes'>,
    Omit<
      SearchFieldProps,
      'value' | 'classes' | 'label' | 'onSearch' | 'onChange'
    > {
  value?: string;
  onChange?: (value: string) => void;
  searchDelay?: number;
  classes?: FilterSearchClasses;
}

export const FilterSearch: React.FC<FilterSearchProps> = ({
  className,
  label,
  classes,
  value,
  onChange,
  searchDelay = 1000,
  ...props
}) => {
  const [search, setSearch] = useState(value);
  const [isNewInput, submit] = useLiveInput((value: string) => {
    onChange?.(value);
  }, searchDelay);

  useEffect(() => {
    setSearch(value);
  }, [value]);

  const handleChange = useCallback(
    (value) => {
      setSearch(value);
      submit(value, true);
    },
    [submit]
  );

  const handleSearch = useCallback(
    (value) => {
      submit(value, false);
    },
    [submit]
  );
  const { isMobile } = useWindowSize();
  // todo: разобраться, нужно ли именно в этом компоненте выставлять размер инпуту
  return (
    <Filter
      className={clsx(s.FilterSearch, className)}
      classes={classes?.filter}
      label={label}
    >
      <SearchField
        size={isMobile ? TextFieldSize.small : TextFieldSize.medium}
        className={clsx(s.FilterSearch__input, className)}
        value={search}
        onChange={handleChange}
        onSearch={handleSearch}
        classes={classes?.searchField}
        {...props}
      />
    </Filter>
  );
};
