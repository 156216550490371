import React, { useCallback, useMemo } from 'react';
import { Form, FormikProps } from 'formik';
import { skipIfNull } from '@proscom/prostore';
import { ExternalPollFormValues } from '../../../store/polls/ExternalPollsUtils';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { GridItem, LayoutGrid } from '../../../common/components/Layout/Layout';
import { FormSection } from '../../../common/components/ui/Form/FormSection/FormSection';
import { FormCoverLoader } from '../../../common/components/ui/Form/FormCoverLoader/FormCoverLoader';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import { FormTextField } from '../../../common/components/ui/Form/FormTextField/FormTextField';
import { TextFieldSize } from '../../../common/components/ui/TextField/TextField';
import { Button, ButtonVariant } from '../../../common/components/ui/Button';
import { FormDatePicker } from '../../../common/components/ui/Form/FormDatePicker/FormDatePicker';
import { TextLink } from '../../../common/components/ui/TextLink/TextLink';
import { routeLinks } from '../../routeLinks';
import { QUERY_GET_UNITS_OPTIONS } from '../../../graphql/queries/entitiesOptionsQueries';

import { Participants } from './Participants/Participants';
import { ParticipatingUnits } from './ParticipatingUnits/ParticipatingUnits';
import s from '../../offers/OfferForm/OfferForm.module.scss';

const queryOptions = {
  mapData: (result) => result.items,
  skipQuery: skipIfNull(null),
  query: QUERY_GET_UNITS_OPTIONS,
  initialData: []
};

const descriptions = {
  link: (
    <>
      Создайте опрос с использованием сервисов{' '}
      <TextLink href={'http://forms.google.com/'} noUnderline>
        Google Формы
      </TextLink>{' '}
      или{' '}
      <TextLink href={'https://forms.yandex.ru/cloud/admin'} noUnderline>
        Яндекс Формы
      </TextLink>{' '}
      и вставьте в это поле ссылку на его прохождение.
    </>
  ),
  passcode: (
    <>
      Чтобы контролировать доступ к опросу, придумайте кодовое слово и при
      создании опроса в Google Формах попросите респондента первым вопросом
      ввести его. В настройках вопроса в форме пропишите валидацию на это слово,
      тогда Google Форма не позволит пользователю пройти дальше до тех пор, пока
      он не введет указанное вами кодовое слово.
    </>
  )
};

export interface ExternalPollFormProps
  extends FormikProps<ExternalPollFormValues> {
  history: AppHistoryType;
  submissionLoading: boolean;
  externalPollId?: string;
}

export const ExternalPollForm: React.FC<ExternalPollFormProps> = ({
  externalPollId,
  history,
  submissionLoading
}) => {
  const handleCancel = useCallback(() => {
    if (window.confirm('Вы действительно хотите отменить редактирование?')) {
      let path = routeLinks.polls.to;
      if (externalPollId) path += `/${externalPollId}`;
      history.goBack(path);
    }
  }, [history, externalPollId]);

  const textFieldClasses = useMemo(
    () => ({ root: s.OfferForm__textField }),
    []
  );

  return (
    <Form>
      <LayoutGrid>
        <GridItem cols={8}>
          <FormSection>
            <FormCoverLoader
              name={'cover_file_id'}
              fileType={FileTypeEnum.external_poll_cover}
            />
          </FormSection>
          <FormSection>
            <FormTextField
              name={'name'}
              label={'Название опроса'}
              size={TextFieldSize.large}
              classes={textFieldClasses}
            />
          </FormSection>
          <FormSection description={descriptions.link}>
            <FormTextField
              name={'link'}
              label={'Ссылка на опрос'}
              size={TextFieldSize.large}
              classes={textFieldClasses}
            />
          </FormSection>
          <FormSection description={descriptions.passcode}>
            <FormTextField
              name={'passcode'}
              label={'Кодовое слово (для доступа к опросу)'}
              size={TextFieldSize.large}
              classes={textFieldClasses}
            />
          </FormSection>
          <FormSection>
            <FormDatePicker name={'starts_at'} label={'Дата начала опроса'} />
          </FormSection>
          <FormSection>
            <FormDatePicker name={'ends_at'} label={'Дата окончания опроса'} />
          </FormSection>
          <FormSection title={'Права доступа'}>
            <Participants name={'participant'} />
            <ParticipatingUnits name={'participating_units'} />
          </FormSection>
        </GridItem>
      </LayoutGrid>
      <div className={s.OfferForm__actions}>
        <Button variant={ButtonVariant.secondary} onClick={handleCancel}>
          Отменить все
        </Button>
        <Button type={'submit'} loading={submissionLoading}>
          Сохранить изменения
        </Button>
      </div>
    </Form>
  );
};
