import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { Title, TitleProps } from '../../Title/Title';
import { CardDotsButton } from '../../CardDotsButton/CardDotsButton';
import { ButtonClasses } from '../../Button';
import { Dropdown } from '../../Dropdown/Dropdown';
import { useGraphqlMutation } from '../../../../hooks/utils/useGraphqlMutation';
import { Documents } from '../../../../../store/documents/Documents';
import { ContextMenu } from '../../ContextMenu/ContextMenu';
import { handleDefaultError } from '../../../../../utils/handleDefaultError';
import { DocumentSectionTitleEdit } from './DocumentSectionTitleEdit';
import s from './DocumentSectionTitle.module.scss';

export interface DocumentSectionTitleClasses {
  documentSectionTitle?: string;
  title?: string;
  button?: ButtonClasses;
}

export interface DocumentSectionTitleProps
  extends Omit<TitleProps, 'className'> {
  sectionId: string;
  mode?: 'view' | 'edit';
  editable?: boolean;
  classes?: DocumentSectionTitleClasses;
}

export const DocumentSectionTitle: React.FC<DocumentSectionTitleProps> = ({
  sectionId,
  mode = 'view',
  editable,
  classes,
  title,
  ...titleProps
}) => {
  const [editMode, setEditMode] = useState(mode === 'edit');
  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const updateDocumentSection = useGraphqlMutation(
    Documents.UpdateDocumentSection
  );
  const updateDocumentSectionRun = updateDocumentSection.run;
  const handleSubmitTitle = useCallback(
    (value: string) => {
      updateDocumentSectionRun({
        variables: {
          input: {
            id: sectionId,
            name: value
          }
        }
      })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          handleCancelEdit();
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при редактировании раздела. Попробуйте снова'
          );
        });
    },
    [sectionId, updateDocumentSectionRun]
  );

  const deleteDocumentSection = useGraphqlMutation(
    Documents.DeleteDocumentSection
  );
  const deleteDocumentSectionRun = deleteDocumentSection.run;
  const handleDeleteSection = () => {
    if (window.confirm('Вы действительно хотите удалить раздел?')) {
      deleteDocumentSectionRun({ variables: { id: sectionId } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          toast.success('Раздел удален');
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при удалении раздела. Попробуйте снова'
          );
        });
    }
  };

  if (editMode) {
    return (
      <DocumentSectionTitleEdit
        defaultValue={title}
        loading={updateDocumentSection.loading}
        onCancel={handleCancelEdit}
        onSubmit={handleSubmitTitle}
        classes={{
          documentSectionTitleEdit: s.DocumentSectionTitle__edit
        }}
      />
    );
  }

  return (
    <div
      className={clsx(s.DocumentSectionTitle, classes?.documentSectionTitle)}
    >
      <Title className={classes?.title} title={title} {...titleProps} />
      <div className={s.DocumentSectionTitle__actions}>
        {editable && (
          <Dropdown
            content={() => (
              <ContextMenu
                items={[
                  {
                    label: 'Редактировать раздел',
                    onClick: () => {
                      setEditMode(true);
                    }
                  },
                  {
                    label: 'Удалить',
                    onClick: handleDeleteSection
                  }
                ]}
              />
            )}
            classes={{
              content: s.DocumentSectionTitle__dropdownContent
            }}
          >
            {(props) => <CardDotsButton {...props} />}
          </Dropdown>
        )}
      </div>
    </div>
  );
};
