import React from 'react';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { TextLink } from '../../../common/components/ui/TextLink/TextLink';
import { BaseLink } from '../../../common/components/ui/BaseLink/BaseLink';
import s from './Structure.module.scss';

interface StructureItemProps {
  id: number;
  title: string;
  text: string;
  href: string;
}

const structItems: StructureItemProps[] = [
  {
    id: 1,
    title: 'Сообщество выпускников ФАЛТ МФТИ',
    text:
      'Это сообщество людей, кому близок ФАЛТ МФТИ. Базис семьи ФАЛТ – взаимопомощь и всесторонняя поддержка друг друга и alma mater – ФАЛТ МФТИ.',
    href: 'https://faltfamily.ru/'
  },
  {
    id: 2,
    title: 'Профсоюзный комитет МФТИ',
    text:
      'Главной целью профсоюза является представительство и защита профессиональных, трудовых, социально-экономических прав и интересов своих членов. Членом профсоюза может стать любой студент, аспирант или сотрудник МФТИ. Ежемесячное отчисление составляет 1% от заработной платы.',
    href: 'https://mipt.ru/profkom/'
  },
  {
    id: 3,
    title: 'Физтех-Союз',
    text:
      'Это cообщество инициативных выпускников МФТИ, созданное с целью содействия модернизации Московского физико-технического института (с апреля 2019 Союз «Физтех-Союз»).  Более 7 лет Физтех-Союз развивает инфраструктуру вокруг МФТИ и поддерживает значимые социальные проекты. ',
    href: 'https://phystech-union.org/'
  }
];

const StructureItem: React.FC<StructureItemProps> = ({ title, text, href }) => {
  return (
    <BaseLink className={s.StructureItem} href={href}>
      <div className={s.StructureItem__head}>
        <Title title={title} variant={TitleVariant.h3} />
      </div>

      <div className={s.StructureItem__content}>
        <div className={s.StructureItem__text}>{text}</div>

        <div className={s.StructureItem__linkBox}>
          <TextLink
            iconProps={{
              boxSize: 12
            }}
            isClickable={false}
            noHover
            showArrow
          >
            Подробнее
          </TextLink>
        </div>
      </div>
    </BaseLink>
  );
};

export const Structure = () => {
  return (
    <LayoutContent id={'structure'} className={s.Structure}>
      <Title variant={TitleVariant.h2} className={s.Structure__title}>
        Объединения МФТИ
      </Title>
      <LayoutGrid className={s.Structure__grid}>
        {structItems.map((item, iItem) => (
          <StructureItem key={iItem} {...item} />
        ))}
      </LayoutGrid>
    </LayoutContent>
  );
};
