import React from 'react';
import { ReactComponent as CircleLight } from '../../../../assets/img/figure/circleLight.svg';
import { ReactComponent as CircleDark } from '../../../../assets/img/figure/circleDark.svg';
import { COLORS } from '../../../../store/colors';
import { FigureProps, FigureVariant } from './types';

export const FigureCircle: React.FC<FigureProps> = ({
  className,
  variant = FigureVariant.dark,
  size = 100,
  unit = 'px',
  color,
  style
}) => {
  const figureStyle = {
    width: size !== 'auto' ? size + unit : undefined,
    height: size !== 'auto' ? size + unit : undefined,
    color: color || COLORS.colorAccent100,
    ...style
  };

  if (color || variant === FigureVariant.light) {
    return <CircleLight className={className} style={figureStyle} />;
  }
  return <CircleDark className={className} style={figureStyle} />;
};
