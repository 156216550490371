import React from 'react';
import clsx from 'clsx';
import { ImagePreview, ImagePreviewProps } from '../ImagePreview/ImagePreview';
import s from './PartnerLogo.module.scss';

export interface PartnerLogoProps
  extends Omit<ImagePreviewProps, 'showLoader' | 'showPlaceholder'> {}

export const PartnerLogo = React.memo(function PartnerLogo({
  className,
  ...props
}: PartnerLogoProps) {
  return (
    <ImagePreview
      className={clsx(s.PartnerLogo, className)}
      classes={{
        image: s.PartnerLogo__image
      }}
      showLoader={false}
      showPlaceholder={false}
      {...props}
    />
  );
});
