import gql from 'graphql-tag';
import { FRAGMENT_PAGINATION_HEADER } from '../fragments/common';

export const QUERY_GET_VACATION_APPLICATIONS_PAGE = gql`
  query queryGetVacationApplicationsPage($input: VacationApplicationPageInput) {
    vacationApplicationsPage(input: $input) {
      header {
        ...PaginationHeader
      }
      list {
        created_at
        reviewed_at
        status
        start
        end
        review_comment
        position {
          vacations_disabled
          id
          c_position_name
          is_head
          unit {
            id
            name
          }
        }
      }
    }
  }

  ${FRAGMENT_PAGINATION_HEADER}
`;

export const QUERY_GET_VACATION_SCHEDULES = gql`
  query queryGetVacationSchedules {
    vacationSchedules {
      assigned_days
      unassigned_days
      position {
        id
        c_position_name
        c_combine_position_work
        c_category_pps
        c_end_date
        is_head
        vacations_disabled
        unit {
          id
          name
        }
      }
      items {
        days
        start
        end
        original_schedule_date
      }
    }
  }
`;
