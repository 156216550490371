import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import s from './TextField.module.scss';

export enum TextAreaSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export interface TextAreaClasses {
  root?: string;
  label?: string;
  input?: string;
  helperText?: string;
}

export interface TextAreaProps
  extends Omit<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    'onChange' | 'size'
  > {
  size?: TextAreaSize;
  label?: string;
  errorMessage?: string;
  hintMessage?: string;
  classes?: TextAreaClasses;
  autoResize?: boolean;
  maxRows?: number;
  minRows?: number;
  onChange?: (value: string, e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea: React.FC<TextAreaProps> = ({
  size = TextAreaSize.medium,
  label,
  errorMessage,
  hintMessage,
  classes,
  className,
  autoResize = true,
  minRows,
  maxRows,
  onChange,
  ...inputProps
}) => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const input = inputRef.current;
    if (autoResize && input) {
      input.style.resize = 'none';
    }
  }, [autoResize]);

  const handleChange = useCallback(
    (e) => {
      onChange?.(e.target.value, e);
    },
    [onChange]
  );

  return (
    <label className={clsx(s.TextArea, className, classes?.root)}>
      {label && (
        <span className={clsx(s.TextArea__label, classes?.label)}>{label}</span>
      )}
      {autoResize ? (
        <TextareaAutosize
          ref={inputRef}
          className={clsx(
            s.TextArea__input,
            s[`TextArea__input_${size}`],
            {
              [s.TextArea__input_error]: !!errorMessage
            },
            classes?.input
          )}
          {...inputProps}
          cacheMeasurements
          minRows={minRows || inputProps?.rows}
          onChange={handleChange}
          style={
            inputProps?.style
              ? {
                  ...inputProps.style,
                  height: parseFloat((inputProps.style.height || '').toString())
                }
              : {}
          }
        />
      ) : (
        <textarea
          ref={inputRef}
          className={clsx(
            s.TextArea__input,
            s[`TextArea__input_${size}`],
            {
              [s.TextArea__input_error]: !!errorMessage
            },
            classes?.input
          )}
          rows={inputProps.rows || 1}
          onChange={handleChange}
          {...inputProps}
        />
      )}
      <HelpText
        className={classes?.helperText}
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </label>
  );
};
