import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  LandingGrid,
  LandingLayoutContent
} from '../../../common/components/Layout/Layout';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { TextLink } from '../../../common/components/ui/TextLink/TextLink';
import { Modal } from '../../../common/components/ui/Modal/Modal';
import s from './Documents.module.scss';

function createDocLink(name: string) {
  return name ? `${process.env.PUBLIC_URL}/docs/${name}` : '/';
}

interface DocumentItemProps {
  title: string;
  href: string;
}

interface DocumentsSectionProps {
  id: number;
  title: string;
  items?: DocumentItemProps[];
  allItems?: DocumentItemProps[][];
  onClick?: () => void;
}

const items = [
  //Локальные нормативные акты
  [
    {
      title: 'Устав',
      href: 'https://mipt.ru/docs/download.php?code=ustav_s_izmeneniyami'
    },
    {
      title: 'Кодекс этики университетского сообщества МФТИ',
      href:
        'https://mipt.ru/docs/download.php?code=kodeks_etiki_universitetskogo_soobshchestva_mfti'
    },
    {
      title: 'Положение о режиме коммерческой тайны',
      href:
        'https://mipt.ru/docs/download.php?code=polozhenie_o_rezhime_kommercheskoy_tayny'
    },
    {
      title:
        'Положение о системе менеджмента качества, политике в области качества',
      href:
        'https://mipt.ru/docs/download.php?code=polozhenie_o_sisteme_menedzhmenta_kachestva_politike_v_oblasti_kachestva'
    },
    {
      title: 'Положение об антикоррупционной политике МФТИ',
      href:
        'https://mipt.ru/docs/download.php?code=polozhenie_ob_antikorruptsionnoy_politike_mfti'
    },
    {
      title: 'Положение о пропускном и внутриобъектовом режиме в МФТИ',
      href:
        'https://mipt.ru/docs/download.php?code=polozhenie_o_propusknom_i_vnutriobektovom_rezhime_v_mfti'
    },
    {
      title: 'Коллективный договор',
      href: createDocLink('Коллективный_договор_2023.pdf')
    },
    {
      title: 'Правила внутреннего трудового распорядка',
      href:
        'https://mipt.ru/docs/download.php?code=pravila_vnutrennego_trudovogo_rasporyadka_mfti'
    },
    {
      title: 'Порядок регулирования дистанционной работы',
      href:
        'https://mipt.ru/docs/download.php?code=poryadok_regulirovaniya_distantsionnoy_raboty_s_izmeneniyami'
    },
    {
      title:
        'Положение по организации работы в области создания, правовой охраны и использования результатов интеллектуальной деятельности',
      href:
        'https://mipt.ru/docs/download.php?code=polozhenie_po_organizatsii_raboty_v_oblasti_sozdaniya_pravovoy_okhrany_i_ispolzovaniya_rezultatov_in'
    },
    {
      title: 'Положение о защите персональных данных МФТИ',
      href:
        'https://mipt.ru/docs/download.php?code=polozhenie_o_zashchite_personalnykh_dannykh_mfti'
    }
  ],
  //Документы для трудоустройства
  [
    {
      title: 'Перечень документов для трудоустройства',
      href: createDocLink('Перечень_документов_для_трудоустройства.pdf')
    },
    {
      title: 'Трудовой договор – эффективный контракт (ППС)',
      href: createDocLink('Трудовой договор – эффективный контракт (ППС).docx')
    },
    {
      title: 'Заявление о приеме по основному месту работы (ППС)',
      href: createDocLink(
        'Заявление о приеме по основному месту работы (ППС).docx'
      )
    },
    {
      title: 'Заявление о приеме по совместительству (ППС)',
      href: createDocLink('Заявление о приеме по совместительству (ППС).doc')
    },
    {
      title: 'Трудовой договор (НИЧ)',
      href: createDocLink('Трудовой договор (НИЧ).docx')
    },
    {
      title: 'Заявление о приеме по основному месту работы (НИЧ)',
      href: createDocLink(
        'Заявление о приеме по основному месту работы (НИЧ).doc'
      )
    },
    {
      title: 'Заявление о приеме на работу по совместительству (НИЧ)',
      href: createDocLink(
        'Заявление о приеме на работу по совместительству (НИЧ).doc'
      )
    },
    {
      title: 'Трудовой договор (УВП, АУП, ХОП)',
      href: createDocLink('Трудовой договор (УВП, АУП, ХОП).doc')
    },
    {
      title: 'Заявление о приеме по основному месту работы (УВП, АУП, ХОП)',
      href: createDocLink(
        'Заявление о приеме по основному месту работы (УВП, АУП, ХОП).doc'
      )
    },
    {
      title: 'Заявление о приеме на работу по совместительству (УВП, АУП, ХОП)',
      href: createDocLink(
        'Заявление о приеме на работу по совместительству (УВП, АУП, ХОП).doc'
      )
    },
    {
      title: 'Согласие на обработку персональных данных',
      href: createDocLink('Согласие на обработку персональных данных.docx')
    },
    {
      title: 'Согласие на распространение персональных данных',
      href: createDocLink(
        'Согласие на распространение персональных данных.docx'
      )
    }
  ]
];

const sections: DocumentsSectionProps[] = [
  {
    id: 1,
    title: 'Локальные нормативные акты',
    items: items[0].slice(0, 6),
    allItems: [items[0]]
  },
  {
    id: 2,
    title: 'Документы для трудоустройства',
    items: items[1].slice(0, 6),
    allItems: [items[1]]
  }
];

interface DocumentsModalContentProps
  extends Pick<DocumentsSectionProps, 'allItems'> {}

const DocumentsModalContent: React.FC<DocumentsModalContentProps> = ({
  allItems
}) => {
  return (
    <div className={s.DocumentsModal__grid}>
      {allItems?.map((chunk, iChunk) => (
        <div key={iChunk}>
          {chunk.map((item, iItem) => (
            <div key={iItem} className={s.DocumentsModal__item}>
              <TextLink key={iItem} href={item.href} isExternal={true}>
                {item.title}
              </TextLink>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  title,
  items,
  allItems
}) => {
  const [modalActive, setModalActive] = useState(false);
  const hasList = items && items.length > 0;

  const handleItemClick = useCallback((e) => {
    e.preventDefault();
    setModalActive(true);
  }, []);

  return (
    <>
      <Link
        className={clsx(s.DocumentsSection, {
          [s.DocumentsSection_clickable]: allItems && allItems.length > 0
        })}
        to={''}
        onClick={handleItemClick}
      >
        <div
          className={clsx(s.DocumentsSection__head, {
            [s.DocumentsSection__head_noList]: !hasList
          })}
        >
          <Title title={title} variant={TitleVariant.h3} />
        </div>

        {hasList && (
          <div className={s.DocumentsSection__content}>
            {hasList && (
              <div className={s.DocumentsSection__list}>
                {items?.map((item, iItem) => (
                  <div key={iItem} className={s.DocumentsSection__item}>
                    {item.title}
                  </div>
                ))}
              </div>
            )}
            <div className={s.DocumentsSection__linkBox}>
              <TextLink
                iconProps={{
                  boxSize: 12
                }}
                isClickable={false}
                noHover
                showArrow
              >
                Подробнее
              </TextLink>
            </div>
          </div>
        )}
      </Link>

      {hasList && (
        <Modal
          title={title}
          isOpen={modalActive}
          onClose={() => setModalActive(false)}
          size={
            allItems?.length
              ? {
                  maxWidth: allItems.length === 1 ? 668 : 960
                }
              : undefined
          }
        >
          <DocumentsModalContent allItems={allItems} />
        </Modal>
      )}
    </>
  );
};

export const Documents = () => {
  return (
    <LandingLayoutContent className={s.Documents}>
      <Title className={s.Documents__title} variant={TitleVariant.h2}>
        Трудоустройство в МФТИ
      </Title>
      <div>
        <TextLink
          className={s.Documents__videoLink}
          href={'https://rutube.ru/video/2a6c7def475e5c79c8a847b58cc6fe84/'}
          noUnderline
        >
          Видеоролик &laquo;Трудоустройство в МФТИ&raquo;
        </TextLink>
      </div>
      <LandingGrid className={s.Documents__grid}>
        {sections.map((item, iItem) => (
          <DocumentsSection key={iItem} {...item} />
        ))}
      </LandingGrid>
    </LandingLayoutContent>
  );
};
