import { stringify } from 'query-string';
import { clean } from '../utils/objects';
import { socialAuthOrigin, socialAuthUrl } from '../config';
import { handleDefaultError } from '../utils/handleDefaultError';
import {
  isReactNativeApp,
  NATIVE_MESSAGE_AUTH,
  postMessageNative
} from '../utils/native';

export type BuildAuthUrl = (options: {
  network: string;
  queryParams: Record<string, any>;
  mobile_app: boolean;
}) => string;

export class AuthSocialMixin {
  popup;
  onAuthComplete;

  constructor({ onAuthComplete }) {
    this.onAuthComplete = onAuthComplete;
  }

  onWillUnmount(): void {
    this.removeMessageHandler();
  }

  registerMessageHandler = (): void => {
    window.addEventListener('message', this.handleAuthComplete);
  };

  removeMessageHandler = (): void => {
    window.removeEventListener('message', this.handleAuthComplete);
  };

  buildAuthUrl: BuildAuthUrl = ({
    network,
    queryParams,
    mobile_app = false
  }) => {
    const query = stringify(
      clean({
        ...queryParams,
        mobile_app,
        origin: socialAuthOrigin
      })
    );
    return socialAuthUrl + '/auth/' + network + '?' + query;
  };

  handleSocialAuth = (network: string, queryParams = {}) => {
    const authUrl = this.buildAuthUrl({
      network,
      queryParams,
      mobile_app: isReactNativeApp()
    });

    if (isReactNativeApp()) {
      console.log('Initializing native auth event');
      postMessageNative(NATIVE_MESSAGE_AUTH, authUrl);
    } else {
      console.log('Trying to open auth popup');
      this.popup = window.open(
        authUrl,
        'Аутентификация',
        'width=800,height=600'
      );
    }

    this.registerMessageHandler();
  };

  handleAuthComplete = async (e) => {
    try {
      if (!(e && e.data && e.data.type === 'refreshToken')) {
        return;
      }

      if (this.popup) {
        this.popup.close();
        this.popup = null;
      }
      this.removeMessageHandler();

      await this.onAuthComplete(e.data);
    } catch (e) {
      handleDefaultError(e, 'Произошла ошибка входа. Попробуйте снова');
    }
  };
}
