import React, { useMemo } from 'react';
import { CourseSubjectType, CourseType } from '../../../../../graphql/types';
import { Card, CardProps, CardStyle } from '../../Card/Card';
import { TextLink, TextLinkVariant } from '../../TextLink/TextLink';
import { routeLinks } from '../../../../../routes/routeLinks';
import { Title, TitleVariant } from '../../Title/Title';
import { chunkArrayByHalf } from '../../../../../utils/objects';
import s from './CardCourseSubject.module.scss';

export interface CardCourseSubjectProps
  extends CourseSubjectType,
    Pick<CardProps, 'onClick'> {}

export const CardCourseSubject: React.FC<CardCourseSubjectProps> = ({
  name,
  courses,
  count_courses,
  onClick,
  ...props
}) => {
  const coursesChunks: CourseType[][] = useMemo(() => {
    return courses && courses.length > 0 ? chunkArrayByHalf(courses, 2) : [];
  }, [courses]);

  return (
    <Card
      className={s.CardCourseSubject}
      classes={{
        actions: s.CardCourseSubject__actions
      }}
      cardStyle={CardStyle.bordered}
      actions={
        <TextLink isClickable={false} noUnderline onClick={onClick}>
          Посмотреть все ({courses ? courses.length : ''})
        </TextLink>
      }
      {...props}
    >
      <div className={s.CardCourseSubject__title} onClick={onClick}>
        <Title
          className={s.CardCourseSubject__titleText}
          variant={TitleVariant.h4}
          title={name}
        />
      </div>
      <div className={s.CardCourseSubject__coursesGrid}>
        {coursesChunks.map((chunk, iChunk) => (
          <div key={iChunk} className={s.CardCourseSubject__coursesCol}>
            {chunk?.map((course, iCourse) => {
              const link = `${routeLinks.courses.to}/${course.id}`;

              return (
                <div key={iCourse} className={s.CardCourseSubject__coursesItem}>
                  <TextLink
                    className={s.CardCourseSubject__course}
                    to={link}
                    variant={TextLinkVariant.black}
                    noUnderline
                  >
                    {course.name}
                  </TextLink>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </Card>
  );
};
