import React from 'react';
import clsx from 'clsx';
import s from './CourseForm.module.scss';

export interface CourseFormBlockProps {
  className?: string;
  label?: string;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}

export const CourseFormBlock: React.FC<CourseFormBlockProps> = ({
  className,
  label,
  actions,
  children
}) => {
  return (
    <div className={clsx(s.CourseFormBlock, className)}>
      {label && <div className={s.CourseFormBlock__label}>{label}</div>}

      <div className={s.CourseFormBlock__content}>{children}</div>

      {actions && <div className={s.CourseFormBlock__actions}>{actions}</div>}
    </div>
  );
};
