import { IUseGraphqlWatchQueryOptions } from '@proscom/prostore-apollo-react';
import { SystemLinkType } from '../types';
import { QUERY_GET_SYSTEM_LINKS } from '../queries/systemLinks';
import { useAuthGraphqlQuery } from '../../common/hooks/utils/useAuthGraphqlQuery';

const queryOptions: IUseGraphqlWatchQueryOptions<{}, SystemLinkType[]> = {
  query: QUERY_GET_SYSTEM_LINKS,
  mapData: (result) => result.systemLinks
};

export const useSystemLinks = () => {
  return useAuthGraphqlQuery({
    queryOptions,
    variables: {}
  });
};
