import React, { useMemo } from 'react';
import {
  GridItem,
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  Breadcrumbs,
  getBreadcrumbItemEntity,
  IBreadcrumbsEntity,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import {
  getOffersPageBreadcrumbs,
  GetOffersPageBreadcrumbsArgs
} from '../list/OffersPage';
import { BasePageInterface } from '../../../common/components/utils/BasePageInterface';
import { useOffer } from '../../../graphql/hooks/offers/useOffer';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import {
  Button,
  ButtonBaseColor,
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import { useOfferDelete } from '../../../common/hooks/useOfferDelete';
import { UserPermission } from '../../../utils/permissions';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { Offer } from './Offer/Offer';
import s from './OfferPage.module.scss';

export interface GetOfferPageBreadcrumbsArgs
  extends GetOffersPageBreadcrumbsArgs {
  offer: IBreadcrumbsEntity;
}

export function getOfferPageBreadcrumbs({
  offer,
  offersSource
}: GetOfferPageBreadcrumbsArgs): IBreadcrumbsItem[] {
  return [
    ...getOffersPageBreadcrumbs({ offersSource }),
    getBreadcrumbItemEntity('/offers', offer)
  ];
}

export default function OfferPage({
  match,
  history
}: BasePageInterface<{ offerId: string }>) {
  const { offerId } = match.params;
  const offerQuery = useOffer(offerId);
  const offer = offerQuery.state.data;

  const { handleDelete, loading } = useOfferDelete(offerId, () => {
    history.replace('/offers');
  });

  const breadcrumbs = useMemo(
    () =>
      getOfferPageBreadcrumbs({
        offersSource: offer?.source,
        offer: {
          id: offerId,
          name: offer?.name
        }
      }),
    [offer?.source, offer?.name, offerId]
  );

  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.OfferCrud);
  const { isMobile, isTablet } = useWindowSize();

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      {queryLoader(offerQuery) || (
        <LayoutContent>
          <LayoutGrid>
            <GridItem cols={isMobile ? 12 : 8}>
              {!offer ? <NoData /> : <Offer {...offer} />}
            </GridItem>
            <GridItem cols={isMobile ? 12 : isTablet ? 3 : 4}>
              {canEdit && offer && (
                <div className={s.EventPage__actions}>
                  <ButtonLink
                    to={`/offers/${offerId}/edit`}
                    className={s.OfferPage__action}
                    size={ButtonSize.small}
                    variant={ButtonVariant.secondary}
                  >
                    Редактировать
                  </ButtonLink>
                  <Button
                    className={s.OfferPage__action}
                    size={ButtonSize.small}
                    variant={ButtonVariant.secondary}
                    baseColor={ButtonBaseColor.red}
                    onClick={handleDelete}
                    loading={loading}
                  >
                    Удалить
                  </Button>
                </div>
              )}
            </GridItem>
          </LayoutGrid>
        </LayoutContent>
      )}
    </PageLayout>
  );
}
