import { toast } from 'react-toastify';
import { tryAppendErrorId } from '../graphql/graphqlErrors';

/**
 * Простейший обработчик ошибки запрос.
 * Логирует её в консоль и показывает тост пользователю вместе с кодом ошибки,
 * если он есть
 *
 * @param err - ошибка запроса
 * @param message - сообщение для отображения пользователю
 */
export function handleDefaultError(err: any, message: string) {
  console.error(err);
  toast.error(tryAppendErrorId(message, err));
}
