import React from 'react';
import clsx from 'clsx';
import MDSpinner from 'react-md-spinner';
import { Props as MDSpinnerProps } from 'react-md-spinner/dist/MDSpinner';
import { COLORS } from '../../../../store/colors';
import s from './Loader.module.scss';

export interface LoaderSpinnerProps extends Omit<MDSpinnerProps, 'ref'> {}

export enum LoaderPositions {
  absolute = 'absolute',
  static = 'static',
  fixed = 'fixed'
}

export interface LoaderProps extends LoaderSpinnerProps {
  position?: LoaderPositions;
  className?: string;
}

const defaultProps = {
  size: 40,
  singleColor: COLORS.colorSurfaceAccent,
  borderSize: 4
};

export const Loader: React.FC<LoaderProps> = ({
  position = LoaderPositions.static,
  className,
  ...props
}) => {
  return (
    <div className={clsx(s.Loader, s[`Loader_${position}`], className)}>
      <MDSpinner {...defaultProps} {...props} />
    </div>
  );
};
