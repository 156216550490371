import React, { useCallback, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Portal } from 'react-portal';
import { useClickOutside } from '@proscom/ui-react';
import { isTruthy } from '@proscom/ui-utils';
import { NavLinkProps } from '../NavLink/NavLink';
import { ReactComponent as DismissIcon } from '../../../../assets/img/icons/dismiss.svg';
import { ReactComponent as IconArrow } from '../../../../assets/img/icons/Arrow.svg';
import { Icon } from '../Icon/Icon';
import { usePreventWindowScroll } from '../../../hooks/usePreventWindowScroll';
import { MobileMenuItem } from './MobileMenuItem';
import s from './MobileMenu.module.scss';

export interface MobileMenuProps {
  links: NavLinkProps[];
  isOpen?: boolean;
  onClose?: () => void;
}
export const MobileMenu: React.FC<MobileMenuProps> = ({
  isOpen,
  onClose,
  links
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [selectedItem, setSelectedItem] = useState(-1);

  const selectedLink = useMemo(() => {
    if (selectedItem === -1) return null;
    return links[selectedItem] ?? null;
  }, [links, selectedItem]);

  const onReset = useCallback(() => {
    setSelectedItem(-1);
  }, []);

  usePreventWindowScroll(isOpen);

  useClickOutside(menuRef, () => {
    if (isOpen) {
      onClose?.();
      setSelectedItem(-1);
    }
  });

  return (
    <Portal>
      <div
        className={clsx(s.MobileMenu__overlay, {
          [s.MobileMenu__overlay_active]: isOpen
        })}
      />
      <div
        ref={menuRef}
        className={clsx(s.MobileMenu, {
          [s.MobileMenu_open]: isOpen,
          [s.MobileMenu_showSubitems]:
            selectedLink?.subLinks && selectedLink.subLinks.length > 0
        })}
      >
        <div className={s.MobileMenu__inner}>
          <div className={s.MobileMenu__leftPanel}>
            <div className={s.MobileMenu__actionButton}>
              <Icon
                className={s.MobileMenu__closeIcon}
                icon={DismissIcon}
                onClick={onClose}
                iconSize={24}
              />
            </div>
            {links.map((link, iLink) => (
              <MobileMenuItem
                key={iLink}
                onClick={() => setSelectedItem(iLink)}
                text={link.text}
                to={link.to}
                href={link.href}
                isExternal={link.isExternal}
              />
            ))}
          </div>
          <div className={s.MobileMenu__rightPanel}>
            <div className={s.MobileMenu__actionButton}>
              <Icon
                className={s.MobileMenu__backIcon}
                icon={IconArrow}
                onClick={onReset}
                iconSize={24}
              />
              {selectedLink?.text}
            </div>
            {selectedLink?.subLinks?.filter(isTruthy).map((link, iLink) => (
              <MobileMenuItem key={iLink} text={link.text} to={link.to} />
            ))}
          </div>
        </div>
      </div>
    </Portal>
  );
};
