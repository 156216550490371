import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldArray, Form, Formik, FormikConfig, FormikProps } from 'formik';
import { EMDASH } from '@proscom/ui-utils';
import { toast } from 'react-toastify';
import { flatten } from 'lodash-es';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { Modal } from '../../../../common/components/ui/Modal/Modal';
import { PositionType, VacationScheduleType } from '../../../../graphql/types';
import { VacationSchedulesFormValidationSchema } from '../../../../store/validationSchema';
import {
  VacationSchedulesFormValuesProps,
  VacationsUtils
} from '../../../../store/vacations/VacationsUtils';
import { convertDataListToSelectOptions } from '../../../../utils/form/formConverters';
import { UserUtils } from '../../../../store/users/UserUtils';
import {
  SelectOptionProps,
  SelectSize
} from '../../../../common/components/ui/Select/Select';
import { FormSection } from '../../../../common/components/ui/Form/FormSection/FormSection';
import { FormSelect } from '../../../../common/components/ui/Form/FormSelect/FormSelect';
import { TextFieldPreview } from '../../../../common/components/ui/TextFieldPreview/TextFieldPreview';
import { Button, ButtonVariant } from '../../../../common/components/ui/Button';
import { useGraphqlMutation } from '../../../../common/hooks/utils/useGraphqlMutation';
import { Vacations } from '../../../../store/vacations/Vacations';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import {
  HelpText,
  HelpTextType
} from '../../../../common/components/ui/HelpText/HelpText';
import { ReactComponent as IconInfo } from '../../../../assets/img/icons/Info.svg';
import { VacationBlock } from './VacationBlock/VacationBlock';
import s from './VacationSchedulesModal.module.scss';

const formStatus = {
  submitAttempt: 'FORM_SUBMIT_ATTEMPT'
};

interface VacationSchedulesFormProps
  extends FormikProps<VacationSchedulesFormValuesProps> {
  positionsSelectOptions: SelectOptionProps[];
  position?: PositionType;
  initialDaysAvailable: number;
  onPositionChange: (positionId: string) => void;
}

const VacationSchedulesForm = ({
  positionsSelectOptions,
  position,
  initialDaysAvailable,
  values,
  initialValues,
  errors,
  status,
  setStatus,
  setFieldValue,
  onPositionChange
}: VacationSchedulesFormProps) => {
  const positionIdValue = values.position_id;
  const daysAvailable = values.unassigned_days || 0;
  const vacations = values.vacations;
  const lastVacationId = vacations[vacations.length - 1]?.id || 0;

  const initVacationsIds = initialValues.vacations.map((v) => v.id);

  const canAddVacation = !!(
    position &&
    !position.vacations_disabled &&
    daysAvailable &&
    !errors?.vacations
  );

  useEffect(() => {
    if (status === formStatus.submitAttempt) {
      if (Object.keys(errors).length) {
        toast.error(
          'Невозможно отправить график на согласование. Проверьте правильность ввода всех полей'
        );
        setStatus(undefined);
      }
    }
  }, [errors, status, setStatus]);

  useEffect(() => {
    onPositionChange(positionIdValue || '');
  }, [positionIdValue, onPositionChange]);

  useEffect(() => {
    const allSelectedDays = vacations.reduce((summ, curr) => {
      return summ + curr.periods.reduce((s, c) => s + (c.days || 0), 0);
    }, 0);
    setFieldValue('unassigned_days', initialDaysAvailable - allSelectedDays);
    setFieldValue(
      'selectedDates',
      VacationsUtils.getSelectedDates(flatten(vacations.map((v) => v.periods)))
    );
  }, [setFieldValue, vacations, initialDaysAvailable]);

  return (
    <Form>
      <FormSection className={s.VacationSchedulesForm__positionSection}>
        <FormSelect
          name={'position_id'}
          label={'Должность'}
          placeholder={'Выберите должность для изменения графика отпусков'}
          options={positionsSelectOptions}
          size={SelectSize.large}
        />
      </FormSection>

      <FormSection>
        <TextFieldPreview title={'Подразделение:'} simpleView>
          {UserUtils.getPositionUnit(position) || EMDASH}
        </TextFieldPreview>
      </FormSection>

      <FormSection>
        <TextFieldPreview
          title={'Доступное количество дней отпуска для распределения:'}
          simpleView
        >
          {daysAvailable}
        </TextFieldPreview>
      </FormSection>

      <div className={s.VacationSchedulesForm__blocks}>
        <FieldArray name={'vacations'}>
          {({ push, remove }) => (
            <>
              {vacations.map((item, iItem) => (
                <div key={item.id} className={s.VacationSchedulesForm__block}>
                  <VacationBlock
                    index={iItem}
                    position={position}
                    daysAvailable={daysAvailable}
                    isNewBlock={!initVacationsIds.includes(item.id)}
                    isRemovable={vacations.length > 1}
                    errors={errors}
                    onRemoveBlock={remove}
                  />
                </div>
              ))}

              <Button
                className={s.VacationSchedulesForm__addBlockButton}
                variant={ButtonVariant.secondary}
                disabled={!canAddVacation}
                onClick={() =>
                  push({
                    id: lastVacationId + 1,
                    periods: [{ id: 0, start: '', end: '' }]
                  })
                }
              >
                Добавить ещё один планируемый отпуск
              </Button>
            </>
          )}
        </FieldArray>
      </div>
    </Form>
  );
};

export interface VacationSchedulesModalProps {
  vacationsSchedules: VacationScheduleType[];
  isOpen: boolean;
  onClose: () => void;
}

export const VacationSchedulesModal = ({
  vacationsSchedules,
  isOpen,
  onClose
}: VacationSchedulesModalProps) => {
  const updateVacSchedule = useGraphqlMutation(
    Vacations.updateVacationSchedule
  );
  const updateVacScheduleRun = updateVacSchedule.run;
  const updateVacScheduleLoading = updateVacSchedule.loading;

  const [positionId, setPositionId] = useState<string | null>(
    vacationsSchedules[0].position.id.toString() || null
  );

  const positions = useMemo(() => {
    return vacationsSchedules.map((v) => v.position);
  }, [vacationsSchedules]);

  const selectedPosition = positions.find(
    (p) => p.id.toString() === positionId
  );
  const isVacationsDisabled = selectedPosition?.vacations_disabled;

  const positionsSelectOptions = useMemo(() => {
    return convertDataListToSelectOptions(
      (positions || []).map((pos) => ({
        id: pos.id.toString(),
        name: `${pos.c_position_name} (${pos.c_combine_position_work})`
      })),
      {
        valueKey: 'id',
        labelKey: 'name'
      }
    );
  }, [positions]);

  const currVacSchedule = useMemo(() => {
    return vacationsSchedules.find(
      (p) => p.position.id.toString() === positionId
    );
  }, [vacationsSchedules, positionId]);

  useEffect(() => {
    if (isOpen && currVacSchedule && !currVacSchedule.unassigned_days) {
      toast.warning(
        `Для выбранной должности нет свободных дней для распределния.
        Вы можете перераспределить дни в утвержденных отпусках`,
        { autoClose: 15000 }
      );
    }
  }, [isOpen, currVacSchedule]);

  const initialUnassignedDays = currVacSchedule?.unassigned_days || 0;
  const initialAssignedDays = currVacSchedule?.assigned_days || 0;
  const initialDaysAvailable = initialUnassignedDays + initialAssignedDays;

  const initialValues = useMemo(() => {
    return VacationsUtils.toSchedulesForm(currVacSchedule);
  }, [currVacSchedule]);

  const handleSubmit: FormikConfig<VacationSchedulesFormValuesProps>['onSubmit'] =
    useCallback(
      (data, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        setStatus(undefined);

        const input = VacationsUtils.fromSchedulesForm(data, initialValues);

        if (!input) {
          setSubmitting(false);
          return;
        }

        updateVacScheduleRun({ variables: { input } })
          .then((result) => {
            if (result.data?.result) {
              onClose();
              toast.success('График отпусков отправлен на согласование');
            }
          })
          .catch((err) => {
            if (err instanceof AsyncSingletonError) return;
            handleDefaultError(
              err,
              'Произошла ошибка при отправке графика отпусков. Попробуйте снова'
            );
          })
          .then(() => {
            setSubmitting(false);
          });
      },
      [updateVacScheduleRun, initialValues, onClose]
    );

  return (
    <Modal
      className={s.VacationSchedulesModal}
      classes={{
        offsetContent: s.VacationSchedulesModal__offsetContent,
        head: s.VacationSchedulesModal__head,
        content: s.VacationSchedulesModal__content
      }}
      title={'Внесение изменений в график отпусков'}
      headContent={
        <HelpText
          className={s.VacationSchedulesModal__hint}
          type={HelpTextType.hint}
          icon={IconInfo}
        >
          Внимание! Необходимо вносить все планируемые изменения в&nbsp;графике
          отпусков за&nbsp;один раз. Скорректируйте все периоды, которые вы
          хотите изменить, после чего отправьте заявление на&nbsp;согласование.
          Дата начала ближайшего периода отпуска не&nbsp;может быть ранее, чем
          через 10&nbsp;дней.
        </HelpText>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={VacationSchedulesFormValidationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(props) => {
          const canSubmit =
            selectedPosition &&
            !isVacationsDisabled &&
            !Object.keys(props.errors).length &&
            // если распределены как минимум все дни из утвержденных отпусков
            props.values.unassigned_days - initialUnassignedDays <= 0;

          return (
            <>
              <div className={s.VacationSchedulesModal__form}>
                <VacationSchedulesForm
                  positionsSelectOptions={positionsSelectOptions}
                  position={selectedPosition}
                  initialDaysAvailable={initialDaysAvailable}
                  onPositionChange={setPositionId}
                  {...props}
                />
              </div>

              <div className={s.VacationSchedulesModal__actions}>
                <Button variant={ButtonVariant.secondary} onClick={onClose}>
                  Отмена
                </Button>
                <Button
                  disabled={
                    !props.dirty || updateVacScheduleLoading || !canSubmit
                  }
                  onClick={() => {
                    if (canSubmit) {
                      props.setStatus(formStatus.submitAttempt);
                      props.submitForm();
                    }
                  }}
                >
                  Отправить заявление
                </Button>
              </div>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
