import gql from 'graphql-tag';

export const FRAGMENT_FEEDBACK_FULL = gql`
  fragment FeedbackFull on FeedbackType {
    created_at
    creator {
      name
      surname
      patronymic
      email
    }
    creator_id
    id
    message
    phone
    topic
    document_files {
      id
      name
    }
  }
`;
