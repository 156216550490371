import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  LandingGrid,
  LandingLayoutContent
} from '../../../common/components/Layout/Layout';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { Modal } from '../../../common/components/ui/Modal/Modal';
import { TextLink } from '../../../common/components/ui/TextLink/TextLink';
import { chunkArrayByHalf } from '../../../utils/objects';
import s from './Structure.module.scss';

interface ItemProps {
  title: string;
  href: string;
}

interface StructureItemProps {
  id: number;
  title: string;
  items?: ItemProps[];
  allItems?: ItemProps[][];
  onClick?: () => void;
}

const items: ItemProps[][] = [
  [
    {
      title:
        'Институт нано-, био-, информационных, когнитивных и социогуманитарных наук и технологий',
      href: 'https://mipt.ru/dnbic/'
    },
    {
      title: 'Физтех-школа Аэрокосмических Технологий',
      href: 'https://mipt.ru/education/departments/fakt/'
    },
    {
      title: 'Физтех-школа бизнеса высоких технологий',
      href:
        'https://mipt.ru/education/departments/fiztekh-shkola-biznesa-vysokikh-tekhnologiy/'
    },
    {
      title: 'Физтех-школа Биологической и Медицинской Физики',
      href: 'https://mipt.ru/education/departments/fbmf/'
    },
    {
      title: 'Физтех-школа Прикладной Математики и Информатики',
      href: 'https://mipt.ru/education/departments/fpmi/'
    },
    {
      title: 'Физтех-школа Радиотехники и Компьютерных Технологий',
      href: 'https://mipt.ru/education/departments/frkt/'
    },
    {
      title: 'Физтех-школа физики и исследований им. Ландау',
      href: 'https://lpr.mipt.ru/'
    },
    {
      title: 'Физтех-школа Электроники, Фотоники и Молекулярной Физики',
      href: 'https://mipt.ru/education/departments/fefm/'
    },
    {
      title: 'ВШПИ "Высшая школа программной инженерии”',
      href: 'https://hsse.mipt.ru/'
    }
  ],
  [
    {
      title:
        'Военный учебный Центр при федеральном государственном автономном образовательном учреждении высшего образования Московский физико-технический институт',
      href: 'https://mipt.ru/education/chair/military/'
    },
    {
      title: 'Департамент иностранных языков',
      href: 'https://mipt.ru/education/chair/foreign_languages/'
    },
    {
      title: 'Департамент физической культуры и спорта',
      href: 'https://mipt.ru/education/chair/sport/'
    },
    {
      title: 'Кафедра высшей математики',
      href: 'https://mipt.ru/education/chair/mathematics/'
    },
    {
      title: 'Кафедра вычислительной физики',
      href: 'https://mipt.ru/education/chair/computational_physics/'
    },
    {
      title: 'Кафедра информатики и вычислительной математики',
      href: 'http://cs.mipt.ru/?id=33'
    },
    {
      title: 'Кафедра общей физики',
      href: 'https://mipt.ru/education/chair/physics/'
    },
    {
      title: 'Кафедра теоретической механики',
      href: 'https://mipt.ru/education/chair/theoretical_mechanics/'
    },
    {
      title: 'Кафедра теоретической физики',
      href: 'https://mipt.ru/education/chair/theoretical_physics/'
    },
    {
      title: 'Учебно-научный Центр гуманитарных и социальных наук',
      href:
        'https://mipt.ru/education/chair/uchebno-nauchnyy-tsentr-gumanitarnykh-i-sotsialnykh-nauk/'
    },
    {
      title: 'Департамент истории',
      href:
        'https://mipt.ru/education/chair/uchebno-nauchnyy-tsentr-gumanitarnykh-i-sotsialnykh-nauk/departament-istorii/'
    },
    {
      title: 'Департамент культурологии',
      href:
        'https://mipt.ru/education/chair/uchebno-nauchnyy-tsentr-gumanitarnykh-i-sotsialnykh-nauk/departament-istorii/'
    },
    {
      title: 'Департамент системного анализа экономики',
      href: 'https://mipt.ru/education/chair/economics/'
    },
    {
      title: 'Департамент социально-политических наук',
      href:
        'https://mipt.ru/education/chair/uchebno-nauchnyy-tsentr-gumanitarnykh-i-sotsialnykh-nauk/departament-sotsialno-politicheskikh-nauk/'
    },
    {
      title: 'Департамент философии',
      href: 'https://mipt.ru/education/chair/philosophy/'
    },
    {
      title: 'Центр дополнительного профессионального образования',
      href: 'https://mipt.ru/cdpo/'
    },
    {
      title: 'Кафедра инновационного менеджмента',
      href: 'https://mipt.ru/education/facultychair/f_dzu8d/a_y3q8d.php'
    },
    {
      title: 'Заочная физико-техническая школа',
      href: 'https://zftsh.online/?class=11'
    },
    {
      title: 'Центр языковой подготовки и тестирования',
      href: 'https://mipt.ru/about/departments/language_center/'
    },
    {
      title: 'Центр «Высшая школа системного инжиниринга МФТИ»',
      href:
        'https://mipt.ru/education/chair/vysshaya-shkola-sistemnogo-inzhiniringa/'
    }
  ],
  [
    {
      title: 'Научно-технический комплекс "Природа"',
      href: 'https://www.cet-mipt.ru/o-kompanii'
    },
    {
      title:
        'Научно-исследовательский и инжиниринговый Центр инновационных технологий МФТИ',
      href:
        'https://mipt.ru/about/departments/innovatsionno-tekhnologicheskiy-tsentr-mfti-/'
    },
    {
      title: 'Научно-технический комплекс "Прикладная генетика"',
      href: 'https://genetics.mipt.ru/'
    },
    {
      title: 'Центр внедрения геномных технологий',
      href: 'https://mipt.ru/science/labs/laboratoriya-genomnoy-inzhenerii/'
    },
    {
      title: 'Центр живых систем и биофарминжиниринга',
      href: 'https://mipt.ru/news/rare_ill_exp'
    },
    {
      title:
        'Научно-технический Центр геофизики и изучения минеральных ресурсов',
      href: 'https://ifz.ru/obrazovanie/mipt'
    },
    {
      title:
        'Научно-технический Центр цифровых систем управления производством',
      href: 'https://www.cet-mipt.ru/'
    },
    {
      title: 'Научно-технологический Центр автономной энергетики',
      href:
        'https://mipt.ru/science/labs/nauchno-tekhnicheskiy-tsentr-avtonomnoy-energetiki/'
    },
    {
      title: 'Научно-технологический Центр экстремальной и неотложной медицины',
      href: 'https://mipt.ru/science/labs/extreme_medicine/'
    },
    {
      title: 'Центр геномных технологий и биоинформатики',
      href: 'https://mipt.ru/science/labs/%20translational_genomic_bio_info/'
    },
    {
      title: 'Научно-образовательный Центр когнитивного моделирования',
      href: 'https://cogmodel.mipt.ru/'
    },
    {
      title: 'Центр обучения проектированию и разработке игр',
      href: 'https://gamedev.mipt.ru/'
    },
    {
      title: 'Центр спортивного программирования ICPC',
      href: 'https://it-edu.mipt.ru/oosh2021/'
    },
    {
      title:
        'Центр исследований молекулярных механизмов старения и возрастных заболеваний',
      href:
        'https://mipt.ru/science/labs/tsentr-issledovaniy-molekulyarnykh-mekhanizmov-stareniya-i-vozrastnykh-zabolevaniy-/?clear_cache=Y'
    },
    {
      title: 'Центр испытаний функциональных материалов',
      href:
        'https://mipt.ru/science/labs/tsentr-ispytaniy-funktsionalnykh-materialov/'
    },
    {
      title:
        'Центр коллективного пользования уникальным научным оборудованием в области нанотехнологий',
      href: 'https://mipt.ru/about/departments/ckpn/'
    },
    {
      title: 'Центр науки и технологий искусственного интеллекта',
      href: 'https://ai.mipt.ru/'
    },
    {
      title: 'Центр проектов цифровой трансформации',
      href:
        'https://mipt.ru/education/chairs/tppi/high-information-technologies.php'
    },
    {
      title: 'Центр трансфера технологий',
      href: 'https://mipt.ru/about/departments/technology-transfer-center/'
    },
    {
      title: 'Центр фотоники и двумерных материалов',
      href: 'https://mipt.ru/science/labs/nanooptoelectronics.php'
    },
    {
      title:
        'Научно- технический Центр мониторинга окружающей среды и экологии',
      href: 'https://arctic.mipt.ru/'
    }
  ]
];

const structItems: StructureItemProps[] = [
  {
    id: 1,
    title: 'Физтех-школы',
    items: items[0].slice(0, 3),
    allItems: [items[0]]
  },
  {
    id: 2,
    title: 'Институтские кафедры, департаменты, учебные подразделения',
    items: items[1].slice(0, 3),
    allItems: [items[1]]
  },
  {
    id: 3,
    title: 'Научно-технические и научно-исследовательские центры',
    items: items[2].slice(0, 4),
    allItems: chunkArrayByHalf(items[2])
  }
];

interface StructureModalContentProps
  extends Pick<StructureItemProps, 'allItems'> {}

const StructureModalContent: React.FC<StructureModalContentProps> = ({
  allItems
}) => {
  return (
    <div className={s.StructureModal__grid}>
      {allItems?.map((chunk, iChunk) => (
        <div key={iChunk}>
          {chunk.map((item, iItem) => (
            <div key={iItem} className={s.StructureModal__item}>
              <TextLink key={iItem} href={item.href} isExternal={true}>
                {item.title}
              </TextLink>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const StructureItem: React.FC<StructureItemProps> = ({
  title,
  items,
  allItems
}) => {
  const [modalActive, setModalActive] = useState(false);
  const hasList = items && items.length > 0;

  const handleItemClick = useCallback((e) => {
    e.preventDefault();
    setModalActive(true);
  }, []);

  return (
    <>
      <Link
        className={clsx(s.StructureItem, {
          [s.StructureItem_clickable]: allItems && allItems.length > 0
        })}
        to={''}
        onClick={handleItemClick}
      >
        <div
          className={clsx(s.StructureItem__head, {
            [s.StructureItem__head_noList]: !hasList
          })}
        >
          <Title title={title} variant={TitleVariant.h3} />
        </div>

        {hasList && (
          <div className={s.StructureItem__content}>
            {hasList && (
              <div className={s.StructureItem__list}>
                {items?.map((item, iItem) => (
                  <div key={iItem} className={s.StructureItem__item}>
                    {item.title}
                  </div>
                ))}
              </div>
            )}
            <div className={s.StructureItem__linkBox}>
              <TextLink
                iconProps={{
                  boxSize: 12
                }}
                isClickable={false}
                noHover
                showArrow
              >
                Подробнее
              </TextLink>
            </div>
          </div>
        )}
      </Link>

      {hasList && (
        <Modal
          title={title}
          isOpen={modalActive}
          onClose={() => setModalActive(false)}
          size={
            allItems?.length
              ? {
                  maxWidth: allItems.length === 1 ? 668 : 960
                }
              : undefined
          }
        >
          <StructureModalContent allItems={allItems} />
        </Modal>
      )}
    </>
  );
};

export const Structure = () => {
  return (
    <LandingLayoutContent className={s.Structure}>
      <div className={s.Structure__title}>Структура МФТИ</div>
      <LandingGrid className={s.Structure__grid}>
        {structItems.map((item, iItem) => (
          <StructureItem key={iItem} {...item} />
        ))}
      </LandingGrid>
    </LandingLayoutContent>
  );
};
