import React, { useCallback, useState } from 'react';
import {
  UploadField,
  UploadFieldProps,
  UploadFieldUploadPropsType
} from '../../UploadField/UploadField';
import { PriorityField } from '../../PriorityField/PriorityField';
import { LoadedFile } from '../../FileBox/FileBox';
import s from './DocumentUpload.module.scss';

export interface DocumentUploadProps
  extends Omit<UploadFieldProps, 'onUpload'> {
  onUpload: (
    file: LoadedFile | null | undefined,
    fileName: string,
    priority: number | null
  ) => void;
}

export const DocumentUpload: React.FC<DocumentUploadProps> = ({
  onUpload,
  ...props
}) => {
  const [priority, setPriority] = useState<number | null>(null);

  const handleUpload = useCallback(
    ({ file, fileName }: UploadFieldUploadPropsType) => {
      onUpload(file, fileName, priority);
    },
    [onUpload, priority]
  );

  return (
    <UploadField onUpload={handleUpload} {...props}>
      <PriorityField
        classes={{
          root: s.DocumentUpload__priority,
          label: s.DocumentUpload__priorityLabel
        }}
        onOrderChange={setPriority}
        showActions={false}
      />
    </UploadField>
  );
};
