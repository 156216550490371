import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { AuthStore } from '../../store/AuthStore';

export function createSetAccessTokenContextLink(
  authStore: AuthStore
): ApolloLink {
  return setContext((_, { headers }) => {
    const { authData } = authStore.state;
    const token = authData && authData.accessToken;
    if (token) {
      return {
        headers: {
          ...headers,
          Authorization: 'Bearer ' + token
        }
      };
    }
    return {};
  });
}
