import gql from 'graphql-tag';
import { FRAGMENT_INTERNSHIP_FULL } from '../fragments/internships';

export const MUTATION_CREATE_INTERNSHIP = gql`
  mutation mutationCreateInternship($input: InternshipCreateInput!) {
    result: createInternship(input: $input) {
      ...InternshipFull
    }
  }

  ${FRAGMENT_INTERNSHIP_FULL}
`;

export const MUTATION_UPDATE_INTERNSHIP = gql`
  mutation mutationUpdateInternship($input: InternshipUpdateInput!) {
    result: updateInternship(input: $input) {
      ...InternshipFull
    }
  }

  ${FRAGMENT_INTERNSHIP_FULL}
`;

export const MUTATION_DELETE_INTERNSHIP = gql`
  mutation mutationDeleteInternship($id: ID!) {
    result: deleteInternship(internship_id: $id)
  }
`;

export const MUTATION_CREATE_INTERNSHIP_TEST_RESULT = gql`
  mutation mutationCreateInternshipTestResult($input: String!) {
    result: createInternshipTestResult(input: $input)
  }
`;
