import { FeedbackInput, FileType } from '../../graphql/types';

export class FeedbackUtils {
  static formInitialValues: FeedbackInput & { document_files?: FileType[] } = {
    phone: '',
    topic: '',
    message: '',
    document_files: undefined
  };

  static fromForm = (data): FeedbackInput => {
    return {
      phone: data.phone || undefined,
      topic: data.topic,
      message: data.message,
      documents: data.documents || null
    };
  };
}
