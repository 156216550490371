import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import {
  Breadcrumbs,
  IBreadcrumbsEntity,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { routeLinks } from '../../routeLinks';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { getProjectPageBreadcrumbs } from '../index/ProjectPage';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { Projects } from '../../../store/projects/Projects';
import { useProject } from '../../../graphql/hooks/projects/useProject';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { ProjectForm } from '../ProjectForm/ProjectForm';
import { ProjectUtils } from '../../../store/projects/ProjectUtils';
import s from './ProjectEditPage.module.scss';

export interface ProjectEditPageProps
  extends RouteComponentProps<{ projectId: string }> {
  history: AppHistoryType;
}

export function getProjectEditPageBreadcrumbs({
  project
}: {
  project: IBreadcrumbsEntity;
}): IBreadcrumbsItem[] {
  return [
    ...getProjectPageBreadcrumbs({ project }),
    {
      name: 'Редактирование',
      path: `${routeLinks.projects.to}/${project.id}/edit`
    }
  ];
}

export default function ProjectEditPage({
  match,
  history
}: ProjectEditPageProps) {
  const { projectId } = match.params;

  const updateProject = useGraphqlMutation(Projects.updateProject);
  const updateProjectRun = updateProject.run;
  const updateProjectLoading = updateProject.loading;

  const projectQuery = useProject(projectId);
  const project = projectQuery.state.data;
  const projectName = project?.name;

  const breadcrumbs = useMemo(
    () =>
      getProjectEditPageBreadcrumbs({
        project: {
          id: projectId,
          name: projectName
        }
      }),
    [projectId, projectName]
  );

  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
      setSubmitting(true);

      const input = ProjectUtils.fromUpdateForm(data);

      updateProjectRun({ variables: { input } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Проект обновлен');
            history.push(`${routeLinks.projects.to}/${result.data.result.id}`);
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при изменении проекта. Попробуйте снова'
          );
        })
        .then(() => {
          setSubmitting(false);
        });
    },
    [updateProjectRun, history]
  );

  const handleCancel = useCallback(() => {
    if (window.confirm('Вы действительно хотите отменить редактирование?')) {
      history.goBack(`${routeLinks.projects.to}/${projectId}`);
    }
  }, [projectId, history]);

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle
          className={s.ProjectEditPage__pageTitle}
          title={'Редактирование проекта'}
          showHeading={true}
        />

        {queryLoader(projectQuery) ||
          (project && (
            <ProjectForm
              project={project}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              submissionLoading={updateProjectLoading}
            />
          ))}
      </LayoutContent>
    </PageLayout>
  );
}
