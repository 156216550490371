import { useStore } from '@proscom/prostore-react';
import React from 'react';
import { useLocation } from 'react-router';
import { Form, Formik } from 'formik';
import { AuthStore } from '../../store/AuthStore';
import { STORE_AUTH } from '../../store/storeKeys';
import { useLoginOps } from '../../common/hooks/useLoginOps';
import { getDefaultAuthProvider } from '../../constants';
import {
  Button,
  ButtonLink,
  ButtonVariant
} from '../../common/components/ui/Button';
import { PageLayout } from '../../common/components/Layout/PageLayout';
import { PageTitle } from '../../common/components/ui/PageTitle/PageTitle';
import { LayoutContent } from '../../common/components/Layout/Layout';
import { FormTextField } from '../../common/components/ui/Form/FormTextField/FormTextField';
import { FormSection } from '../../common/components/ui/Form/FormSection/FormSection';
import { LoginValidationSchema } from '../../store/validationSchema';
import s from './LoginPage.module.scss';

const initialValues = {
  login: '',
  password: ''
};

export default function LoginPage() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const sessionExpired = searchParams.has('session-expired');

  const [auth, authStore] = useStore<AuthStore>(STORE_AUTH);
  const { authWithEmail, authWithSocial } = useLoginOps(auth, authStore);

  const authSocialProvider = getDefaultAuthProvider();

  const handleSubmit = async (data) => {
    if (authSocialProvider) {
      authWithSocial(authSocialProvider);
    } else {
      await authWithEmail(data);
    }
  };

  return (
    <PageLayout isVerticalCentered>
      <PageTitle
        className={s.LoginPage__title}
        title={'Требуется вход'}
        caption={
          sessionExpired
            ? 'Время сессии истекло, пройдите повторную авторизацию'
            : ''
        }
        showHeading
        isCentered
      />

      <LayoutContent>
        {authSocialProvider ? (
          <div className={s.LoginPage__actions}>
            <ButtonLink
              className={s.LoginPage__action}
              to={'/'}
              variant={ButtonVariant.secondary}
            >
              Вернуться на главную
            </ButtonLink>
            <Button className={s.LoginPage__action} onClick={handleSubmit}>
              Войти
            </Button>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={LoginValidationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form className={s.LoginPage__form}>
                <FormSection>
                  <FormTextField
                    name={'login'}
                    label={'Логин корпоративного аккаунта'}
                    placeholder={'Введите логин'}
                  />
                </FormSection>
                <FormSection>
                  <FormTextField
                    name={'password'}
                    type={'password'}
                    label={'Пароль корпоративного аккаунта'}
                    placeholder={'Введите пароль'}
                  />
                </FormSection>
                <FormSection className={s.LoginPage__actions}>
                  <ButtonLink
                    className={s.LoginPage__action}
                    to={'/'}
                    variant={ButtonVariant.secondary}
                  >
                    Вернуться на главную
                  </ButtonLink>
                  <Button
                    className={s.LoginPage__action}
                    type={'submit'}
                    // onClick={ops.handler?.handleSocialAuth(getDefaultAuthProvider())}
                    // onClick={props.submitForm}
                  >
                    Войти
                  </Button>
                </FormSection>
              </Form>
            )}
          </Formik>
        )}
      </LayoutContent>
    </PageLayout>
  );
}
