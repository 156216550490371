import { MutationOptions } from 'apollo-client';
import { FeedbackInput } from '../../graphql/types';
import { clearCache } from '../../graphql/queriesUtils';
import { MUTATION_LEAVE_FEEDBACK } from '../../graphql/mutations/feedback';

export class Feedback {
  private static refetchQueries = ['queryGetFeedbackPage', 'queryGetFeedback'];
  private static updateQueries = ['feedbacksPage', 'feedback'];
  private static updateFragments = ['FeedbacksPageType', 'FeedbackType'];

  public static leaveFeedback: MutationOptions<
    {
      result: boolean;
    },
    {
      input: FeedbackInput;
    }
  > = {
    mutation: MUTATION_LEAVE_FEEDBACK,
    refetchQueries: Feedback.refetchQueries,
    update: clearCache(Feedback.updateQueries, Feedback.updateFragments)
  };
}
