import React from 'react';
import { EMDASH } from '@proscom/ui-utils';
import { AreaOfInterestType } from '../../../../../graphql/types';
import { Tag } from '../../Tag/Tag';
import s from './UserInterests.module.scss';

export interface UserInterestsProps {
  interests?: AreaOfInterestType[] | null;
}

export const UserInterests: React.FC<UserInterestsProps> = ({ interests }) => {
  return (
    <div className={s.UserInterests}>
      {interests && interests.length > 0 ? (
        <div className={s.UserInterests__list}>
          {interests.map(({ id, name }) => (
            <Tag key={id} className={s.UserInterests__tag}>
              {name}
            </Tag>
          ))}
        </div>
      ) : (
        EMDASH
      )}
    </div>
  );
};
