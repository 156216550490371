import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import {
  Breadcrumbs,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { routeLinks } from '../../routeLinks';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { PageTitle } from '../../../common/components/ui/PageTitle/PageTitle';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { Projects } from '../../../store/projects/Projects';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { ProjectForm } from '../ProjectForm/ProjectForm';
import { getProjectsPageBreadcrumbs } from '../list/ProjectsPage';
import { ProjectUtils } from '../../../store/projects/ProjectUtils';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import s from './ProjectCreatePage.module.scss';

export interface ProjectCreatePageProps extends RouteComponentProps {
  history: AppHistoryType;
}

export function getProjectCreatePageBreadcrumbs(): IBreadcrumbsItem[] {
  return [
    ...getProjectsPageBreadcrumbs(),
    {
      name: 'Создание',
      path: `${routeLinks.projects.to}/create`
    }
  ];
}

export default function ProjectCreatePage({ history }: ProjectCreatePageProps) {
  const { user } = useCurrentUser();
  const userId = user?.id;

  const createProject = useGraphqlMutation(Projects.createProject);
  const createProjectRun = createProject.run;
  const createProjectLoading = createProject.loading;

  const breadcrumbs = useMemo(() => getProjectCreatePageBreadcrumbs(), []);

  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
      setSubmitting(true);

      const input = ProjectUtils.fromCreateForm({
        ...data,
        head_user_id: data.head_user_id || userId
      });

      createProjectRun({ variables: { input } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Проект добавлен');
            history.push(`${routeLinks.projects.to}/${result.data.result.id}`);
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(
            err,
            'Произошла ошибка при создании проекта. Попробуйте снова'
          );
        })
        .then(() => {
          setSubmitting(false);
        });
    },
    [userId, createProjectRun, history]
  );

  const handleCancel = useCallback(() => {
    if (window.confirm('Вы действительно хотите отменить редактирование?')) {
      history.goBack(routeLinks.projects.to);
    }
  }, [history]);

  return (
    <PageLayout breadcrumbs={<Breadcrumbs items={breadcrumbs} />}>
      <LayoutContent>
        <PageTitle
          className={s.ProjectCreatePage__pageTitle}
          title={'Создание проекта'}
          showHeading={true}
        />

        <ProjectForm
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          submissionLoading={createProjectLoading}
        />
      </LayoutContent>
    </PageLayout>
  );
}
