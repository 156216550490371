import React from 'react';
import clsx from 'clsx';
import { ActionButton, ActionType } from '../ActionButton/ActionButton';
import { ImagePreview, ImagePreviewProps } from '../ImagePreview/ImagePreview';
import s from './CoverLoaderPreview.module.scss';

export interface CoverLoaderPreviewImageProps
  extends Omit<ImagePreviewProps, 'imageSrc'> {}

export interface CoverLoaderPreviewProps {
  imageSrc?: string | null;
  onRemoveClick?: () => void;
  previewProps?: CoverLoaderPreviewImageProps;
}

export const CoverLoaderPreview: React.FC<CoverLoaderPreviewProps> = ({
  imageSrc,
  onRemoveClick,
  previewProps
}) => {
  return (
    <div className={s.CoverLoaderPreview}>
      <ImagePreview
        {...previewProps}
        className={clsx(s.CoverLoaderPreview__cover, previewProps?.className)}
        classes={{
          image: clsx(
            s.CoverLoaderPreview__coverImage,
            previewProps?.classes?.image
          )
        }}
        imageSrc={imageSrc}
      />
      {imageSrc && (
        <ActionButton
          className={s.CoverLoaderPreview__removeButton}
          actionType={ActionType.delete}
          onClick={onRemoveClick}
        />
      )}
    </div>
  );
};
