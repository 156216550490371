import clsx from 'clsx';
import React from 'react';
import { Helmet } from 'react-helmet';
import { EMDASH } from '@proscom/ui-utils';
import { Title, TitleVariant } from '../Title/Title';
import s from './PageTitle.module.scss';

export interface PageTitleProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'title'> {
  title: string[] | string;
  caption?: React.ReactNode;
  showHeading?: boolean;
  className?: string;
  titleClassName?: string;
  captionClassName?: string;
  isHero?: boolean;
  isCentered?: boolean;
  variant?: TitleVariant;
  addButton?: React.ReactNode;
}

export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  caption,
  showHeading,
  children,
  className,
  titleClassName,
  captionClassName,
  isHero,
  isCentered,
  variant = TitleVariant.h2,
  addButton,
  ...props
}) => {
  const htmlTitle = Array.isArray(title)
    ? title.map((t: string): string => t || '...').join(` ${EMDASH} `)
    : title;
  const pageTitle = Array.isArray(title) ? title[0] : title;
  return (
    <div
      className={clsx(s.PageTitle, className, {
        [s.PageTitle_centered]: isCentered
      })}
      {...props}
    >
      <Helmet title={htmlTitle} />
      {showHeading && (
        <div className={s.PageTitle__titleWrapper}>
          <div className={s.PageTitle__titleWrapperTitle}>
            <Title
              title={pageTitle}
              variant={variant}
              className={clsx(s.PageTitle__title, titleClassName)}
              isHero={isHero}
            />
            {caption && (
              <div
                className={clsx(
                  s.PageTitle__titleWrapperCaption,
                  captionClassName
                )}
              >
                {caption}
              </div>
            )}
          </div>
          {addButton}
        </div>
      )}
      {children}
    </div>
  );
};
