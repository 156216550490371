import React from 'react';
import { Form, FormikProps, useFormikContext } from 'formik';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';

import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { FormContentEditor } from '../../../common/components/ui/Form/FormContentEditor/FormContentEditor';
import { FormTextField } from '../../../common/components/ui/Form/FormTextField/FormTextField';
import { FormTextArea } from '../../../common/components/ui/Form/FormTextArea/FormTextArea';
import { FormCoverLoader } from '../../../common/components/ui/Form/FormCoverLoader/FormCoverLoader';
import { FileTypeEnum } from '../../../store/FileTypeEnum';
import { FormFileLoader } from '../../../common/components/ui/Form/FormFileLoader/FormFileLoader';
import { ArticleProps } from '../../../store/articles/ArticleUtils';
import { ArticleFormBlock } from './ArticleFormBlock';
import s from './ArticleForm.module.scss';

export interface ArticleFormProps extends FormikProps<ArticleProps> {
  onCancel: () => void;
  submissionLoading?: boolean;
}

export const ArticleForm: React.FC<ArticleFormProps> = ({
  values,
  submissionLoading,
  onCancel
}) => {
  const { isMobile } = useWindowSize();
  const { isValid } = useFormikContext();

  return (
    <Form className={s.ArticleForm}>
      <div className={s.ArticleForm__content}>
        <ArticleFormBlock className={s.ArticleFormBlock_cover}>
          <FormCoverLoader
            name={'coverImageId'}
            fileType={FileTypeEnum.article_cover}
          />
        </ArticleFormBlock>

        <ArticleFormBlock label={'Приоритет выдачи'}>
          <FormTextField
            name={'priority'}
            type={'number'}
            hintMessage={
              'Если задана приоритетность, то новость закрепляется и отображается выше других. Чем больше значение приоритетности, тем выше отображается новость'
            }
          />
        </ArticleFormBlock>

        <ArticleFormBlock label={'Заголовок новости *'}>
          <FormTextArea name={'title'} rows={2} />
        </ArticleFormBlock>

        <ArticleFormBlock label={'Краткое содержание новости'}>
          <FormTextArea
            name={'snippet'}
            hintMessage={'Отображается в карточке новости под заголовком'}
          />
        </ArticleFormBlock>

        <ArticleFormBlock label={'Содержимое новости'}>
          <FormContentEditor name={'content'} />
        </ArticleFormBlock>

        <ArticleFormBlock label={'Файлы'}>
          <FormFileLoader
            name={'documents'}
            buttonText={'Прикрепить файлы'}
            fileType={FileTypeEnum.article_document}
            value={values?.document_files ?? undefined}
            multiple={true}
          />
        </ArticleFormBlock>
      </div>
      <div className={s.ArticleForm__actions}>
        <Button
          className={s.ArticleForm__actionButton}
          size={isMobile ? ButtonSize.medium : ButtonSize.large}
          variant={ButtonVariant.secondary}
          onClick={onCancel}
        >
          Отменить всё
        </Button>
        <Button
          className={s.ArticleForm__actionButton}
          size={isMobile ? ButtonSize.medium : ButtonSize.large}
          type={'submit'}
          loading={submissionLoading}
          disabled={!isValid}
        >
          Сохранить изменения
        </Button>
      </div>
    </Form>
  );
};
