import React, { ComponentType, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { GroupTypeBase, OptionTypeBase } from 'react-select/src/types';
import ReactSelectAsync, { Props as AsyncProps } from 'react-select/async';
import { components, MenuListComponentProps } from 'react-select';
import ReactSelectAsyncCreatable, {
  Props as CreatableProps
} from 'react-select/async-creatable';
import { SelectComponents } from 'react-select/src/components';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import {
  selectDefaults,
  selectMultiAdditionalProps,
  SelectProps
} from '../Select/Select';
import { ONE_PAGE_ITEMS } from '../../../../constants';
import s from './AsyncSelect.module.scss';

export enum AsyncSelectSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export interface AsyncSelectClasses {
  root?: string;
  label?: string;
  input?: string;
  helperText?: string;
}

export interface AsyncSelectSelfProps<IsCreatable extends boolean> {
  size?: AsyncSelectSize;
  errorMessage?: string;
  hintMessage?: string;
  label?: string;
  className?: string;
  classes?: AsyncSelectClasses;
  isCreatable?: IsCreatable;
  hideValues?: boolean;
  disabled?: boolean;
  moreResultsHint?: React.ReactNode;
  optionsLimit?: number;
}

export type AsyncSelectProps<
  IsMulti extends boolean,
  IsCreatable extends boolean
> = AsyncSelectSelfProps<IsCreatable> &
  (IsCreatable extends true
    ? CreatableProps<OptionTypeBase, IsMulti>
    : AsyncProps<OptionTypeBase, IsMulti>);

export function AsyncSelect<
  IsMulti extends boolean = false,
  IsCreatable extends boolean = false
>({
  size = AsyncSelectSize.medium,
  errorMessage,
  hintMessage,
  label,
  className,
  classes,
  isMulti,
  isCreatable,
  optionsLimit = ONE_PAGE_ITEMS,
  disabled,
  hideValues,
  moreResultsHint = 'Для просмотра остальных опций уточните поисковый запрос',
  components,
  ...props
}: AsyncSelectProps<IsMulti, IsCreatable>) {
  const MenuListWithHint = useCallback(
    (props) => {
      const menuListProps = { ...props } || {};
      if (!menuListProps.selectProps.components.MenuListFooter) {
        menuListProps.selectProps.components.MenuListFooter = (props) => {
          if (moreResultsHint && props.children.length >= optionsLimit) {
            return <MenuListHint>{moreResultsHint}</MenuListHint>;
          }
          return null;
        };
      }
      return <MenuList {...menuListProps} />;
    },
    [moreResultsHint, optionsLimit]
  );

  const compProps = useMemo(() => {
    let currentProps = {
      components: {
        MenuList: MenuListWithHint,
        ...components
      },
      className: clsx(
        s.AsyncSelect__input,
        s[`AsyncSelect__input_${size}`],
        {
          [s.AsyncSelect_hideValues]: hideValues,
          [s.AsyncSelect__input_error]: !!errorMessage
        },
        classes?.input
      ),
      classNamePrefix: 'hr-asyncSelect',
      closeMenuOnSelect: !isMulti,
      isCreatable,
      isMulti,
      ...selectDefaults,
      ...props
    };
    if (isMulti) {
      const multiProps = selectMultiAdditionalProps(currentProps.components);
      currentProps = { ...currentProps, ...multiProps };
    }
    return currentProps;
  }, [
    MenuListWithHint,
    classes?.input,
    components,
    errorMessage,
    hideValues,
    isCreatable,
    isMulti,
    props,
    size
  ]);

  return (
    <label
      className={clsx(s.AsyncSelect, className, classes?.root, {
        [s.AsyncSelect_disabled]: disabled
      })}
    >
      {label && (
        <span className={clsx(s.AsyncSelect__label, classes?.label)}>
          {label}
        </span>
      )}
      {isCreatable ? (
        <ReactSelectAsyncCreatable {...compProps} />
      ) : (
        <ReactSelectAsync {...compProps} />
      )}
      <HelpText
        className={classes?.helperText}
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </label>
  );
}

type MenuListProps<
  OptionType,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType>
> = Omit<
  MenuListComponentProps<OptionType, IsMulti, GroupType>,
  'selectProps'
> & {
  selectProps: Partial<
    Omit<SelectProps<IsMulti>, 'components'> & {
      components: Partial<
        SelectComponents<OptionType, IsMulti, GroupType> & {
          MenuListHeader?: ComponentType<
            MenuListProps<OptionType, IsMulti, GroupType>
          >;
          MenuListFooter?: ComponentType<
            MenuListProps<OptionType, IsMulti, GroupType>
          >;
        }
      >;
    }
  >;
};

const MenuList = React.memo(function MenuList<
  OptionType,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType>
>(props: MenuListProps<OptionType, IsMulti, GroupType>) {
  const { MenuListHeader, MenuListFooter } =
    props?.selectProps.components || {};
  const hasChildren = React.Children.count(props.children) >= 0;
  return (
    <components.MenuList {...props}>
      {hasChildren && MenuListHeader && <MenuListHeader {...props} />}
      {props.children}
      {hasChildren && MenuListFooter && <MenuListFooter {...props} />}
    </components.MenuList>
  );
});

function MenuListHint({ children }: { children: React.ReactNode }) {
  return <div className={s.AsyncSelect__moreResultsHint}>{children}</div>;
}
