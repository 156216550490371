import React, { useState } from 'react';
import { PageLayout } from '../../common/components/Layout/PageLayout';
import {
  LayoutContent,
  LayoutGrid
} from '../../common/components/Layout/Layout';
import {
  TextField,
  TextFieldSize
} from '../../common/components/ui/TextField/TextField';
import { queryLoader } from '../../common/components/utils/queryLoader';
import { NoData } from '../../common/components/ui/NoData/NoData';
import { useCurrentUser } from '../../common/hooks/useCurrentUser';
import { useWindowSize } from '../../common/hooks/useWindowSize';
import { useHasPermission } from '../../common/hooks/useHasPermission';
import { UserPermission } from '../../utils/permissions';
import { DocumentsPageTitle } from './DocumentsPageTitle';
import { useDocuments } from './useDocuments';
import { SectionsList } from './SectionsList/SectionsList';
import s from './DocumentsPage.module.scss';

export default function DocumentsPage() {
  const [search, setSearch] = useState('');

  const documentSectionsQuery = useDocuments(search);
  const documentSections = documentSectionsQuery.state.data;

  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.DocumentCrud);
  const ws = useWindowSize();
  const { isMobile, isTablet } = ws;

  const searchField = (
    <>
      {/* TODO надо сделать отдельный компонент для поиска? Проработать когда поиск появится на бэке */}
      <TextField
        className={s.DocumentsPage__search}
        size={TextFieldSize.small}
        placeholder={'Поиск'}
        onChange={(value) => setSearch(value)}
        value={search}
      />
    </>
  );

  return (
    <PageLayout>
      <LayoutContent>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={8}>
            <DocumentsPageTitle showAddButton={canEdit} />
            {isMobile && (
              <div className={s.DocumentsPage__searchField}>{searchField}</div>
            )}
            {queryLoader(documentSectionsQuery) ||
              (documentSections && documentSections.length > 0 ? (
                <SectionsList
                  documentSections={documentSections}
                  editable={canEdit}
                  ws={ws}
                />
              ) : (
                <NoData />
              ))}
          </LayoutGrid.GridItem>
          {!isMobile && (
            <LayoutGrid.GridItem cols={isTablet ? 3 : 4}>
              {searchField}
            </LayoutGrid.GridItem>
          )}
        </LayoutGrid>
      </LayoutContent>
    </PageLayout>
  );
}
