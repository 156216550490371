import React, { useMemo } from 'react';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import {
  TransformedQueryParams,
  URL_KEY_PAGE
} from '../../../../store/urlKeys';
import { Pagination } from '../Pagination/Pagination';
import { STORE_LOCATION } from '../../../../store/storeKeys';
import { PaginationHeaderType } from '../../../../graphql/types';

export interface QueryPaginationProps {
  header: PaginationHeaderType;
  pathname: string;
  adjacentPages?: number;
}

export const QueryPagination = React.memo(function QueryPagination({
  header,
  pathname,
  adjacentPages
}: QueryPaginationProps) {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const urlQuery = locationState.query as TransformedQueryParams;
  const urlPreserver = locationStore.createUrlPreserver(urlQuery);

  const lastPageNumber = useMemo(() => {
    if (
      !header ||
      !header?.totalCount ||
      !header?.onePage ||
      header.totalCount < header.onePage
    ) {
      return 1;
    }
    return Math.ceil(header.totalCount / header.onePage);
  }, [header]);

  // todo прятать пагинацию после того как всё сделаем
  const shouldDisplay = true;
  // (urlQuery?.page > 1 || lastPageNumber > 1);

  return (
    shouldDisplay && (
      <Pagination
        currentPage={urlQuery?.page || 1}
        lastPageNumber={lastPageNumber}
        adjacentPages={adjacentPages}
        urlFunc={(page) => urlPreserver(pathname, { [URL_KEY_PAGE]: page })}
        onNavigate={(page) => {
          locationStore.changeQuery({ [URL_KEY_PAGE]: page });
        }}
      />
    )
  );
});
