import NotFoundPage from '../notFound/NotFoundPage';
import { RequireAuth } from '../RequireAuth';
import { Routes } from '../../common/components/utils/Routes';
import { hasPermission, UserPermission } from '../../utils/permissions';
import ManualsPage from './ManualsPage';
import ManualsEditPage from './edit/ManualsEditPage';

const routes = [
  {
    component: RequireAuth(ManualsPage),
    path: '/',
    exact: true
  },
  {
    component: RequireAuth(ManualsEditPage, (user) =>
      hasPermission(user, UserPermission.ManualCrud)
    ),
    path: '/edit',
    exact: true
  },
  {
    component: NotFoundPage
  }
];

const ManualsRoutes = Routes(routes);

export default ManualsRoutes;
