import { MutationOptions } from 'apollo-client';
import {
  ExternalPollCreateInput,
  ExternalPollType,
  ExternalPollUpdateInput
} from '../../graphql/types';
import { clearCache } from '../../graphql/queriesUtils';
import {
  MUTATION_CREATE_EXTERNAL_POLL,
  MUTATION_DELETE_EXTERNAL_POLL,
  MUTATION_UPDATE_EXTERNAL_POLL
} from '../../graphql/mutations/polls';

export class ExternalPolls {
  private static refetchQueries = [
    'queryGetExternalPollsPage',
    'queryGetExternalPoll'
  ];
  private static updateQueries = ['externalPollsPage', 'externalPoll'];
  private static updateFragments = ['ExternalPollType'];

  public static CreateExternalPoll: MutationOptions<
    {
      result: ExternalPollType;
    },
    {
      input: ExternalPollCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_EXTERNAL_POLL,
    refetchQueries: ExternalPolls.refetchQueries,
    update: clearCache(
      ExternalPolls.updateQueries,
      ExternalPolls.updateFragments
    )
  };

  public static UpdateExternalPoll: MutationOptions<
    {
      result: ExternalPollType;
    },
    {
      input: ExternalPollUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_EXTERNAL_POLL,
    refetchQueries: ExternalPolls.refetchQueries,
    update: clearCache(
      ExternalPolls.updateQueries,
      ExternalPolls.updateFragments
    )
  };

  public static DeleteExternalPoll: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_DELETE_EXTERNAL_POLL,
    refetchQueries: ExternalPolls.refetchQueries,
    update: clearCache(
      ExternalPolls.updateQueries,
      ExternalPolls.updateFragments
    )
  };
}
