import React, { useEffect, useRef } from 'react';
import { TextField } from '../../../common/components/ui/TextField/TextField';
import { REQUIRED_MESSAGE, URL_MESSAGE } from '../../../store/validationSchema';
import { isUrlValid } from '../../../utils/url/url';

export const LinkEdit = ({ state, onChange, error, setError }) => {
  const ref = useRef(setError);
  useEffect(() => {
    ref.current();
  }, []);

  return (
    <TextField
      value={state.link}
      onChange={onChange}
      onBlur={(event) => {
        if (!event.target.value) {
          setError(REQUIRED_MESSAGE);
        } else if (!isUrlValid(event.target.value)) {
          setError(URL_MESSAGE);
        } else {
          setError();
        }
      }}
      errorMessage={error}
    />
  );
};
