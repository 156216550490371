export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
};

export type AreaOfInterestCreateInput = {
  name: Scalars['String'];
};

export type AreaOfInterestFilter = {
  ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
};

export type AreaOfInterestType = {
  __typename?: 'AreaOfInterestType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AreasOfInterestPageInput = {
  filter?: Maybe<AreaOfInterestFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type AreasOfInterestPageType = {
  __typename?: 'AreasOfInterestPageType';
  header: PaginationHeaderType;
  list: Array<AreaOfInterestType>;
};

export type ArticleCreateInput = {
  content: Scalars['String'];
  cover_file_id?: Maybe<Scalars['ID']>;
  documents?: Maybe<Array<ArticleDocumentInput>>;
  posted_at?: Maybe<Scalars['DateTime']>;
  priority?: Maybe<Scalars['Int']>;
  snippet: Scalars['String'];
  title: Scalars['String'];
};

export type ArticleDocumentInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ArticleFilter = {
  date_range?: Maybe<OptionalDateRangeType>;
  search?: Maybe<Scalars['String']>;
};

export type ArticlePageInput = {
  filter?: Maybe<ArticleFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type ArticleType = {
  __typename?: 'ArticleType';
  content: Scalars['String'];
  cover_file?: Maybe<FileType>;
  cover_file_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['DateTime']>;
  document_files?: Maybe<Array<FileType>>;
  id: Scalars['ID'];
  image_files?: Maybe<Array<FileType>>;
  posted_at?: Maybe<Scalars['DateTime']>;
  priority?: Maybe<Scalars['Int']>;
  snippet: Scalars['String'];
  title: Scalars['String'];
};

export type ArticleUpdateInput = {
  content?: Maybe<Scalars['String']>;
  cover_file_id?: Maybe<Scalars['ID']>;
  documents?: Maybe<Array<ArticleDocumentInput>>;
  id: Scalars['ID'];
  posted_at?: Maybe<Scalars['DateTime']>;
  priority?: Maybe<Scalars['Int']>;
  snippet?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ArticlesPageType = {
  __typename?: 'ArticlesPageType';
  header: PaginationHeaderType;
  list: Array<ArticleType>;
};

export type AssessmentReportType = {
  __typename?: 'AssessmentReportType';
  completed_count: Scalars['Int'];
  id: Scalars['ID'];
  profile_name: Scalars['String'];
  sent_date: Scalars['DateTime'];
  testing_count: Scalars['Int'];
};

export type AssessmentType = {
  __typename?: 'AssessmentType';
  assessed_name: Scalars['String'];
  sent_date: Scalars['DateTime'];
  status: EmployeeTestStatusesEnum;
  url: Scalars['String'];
};

export type AuthResponseType = {
  __typename?: 'AuthResponseType';
  accessToken: Scalars['String'];
  refreshToken: UserAuthTokenType;
  user?: Maybe<UserType>;
};

export type BasePermissionType = {
  __typename?: 'BasePermissionType';
  code: Scalars['ID'];
  ip_secured: Scalars['Boolean'];
  name: Scalars['String'];
  type: PermissionTypeEnum;
};

export type CampusCreateInput = {
  name: Scalars['String'];
};

export type CampusFilter = {
  search?: Maybe<Scalars['String']>;
};

export type CampusType = {
  __typename?: 'CampusType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CampusUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CourseAnnounceCreateInput = {
  acquired_knowledge?: Maybe<Scalars['String']>;
  completion_proof?: Maybe<CourseCompletionProof>;
  course_link?: Maybe<Scalars['String']>;
  course_subject_id?: Maybe<Scalars['ID']>;
  cover_file_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  discount_price?: Maybe<Scalars['Int']>;
  documents?: Maybe<Array<CourseDocumentInput>>;
  learning_format?: Maybe<CourseLearningFormat>;
  listener_category?: Maybe<CourseListenerCategory>;
  name: Scalars['String'];
  partner_logo_file_id?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  source: CourseSource;
  status: CourseStatus;
  tags?: Maybe<Array<Scalars['String']>>;
  teachers?: Maybe<Array<CourseTeacherInput>>;
};

export type CourseAnnounceUpdateInput = {
  acquired_knowledge?: Maybe<Scalars['String']>;
  completion_proof?: Maybe<CourseCompletionProof>;
  course_link?: Maybe<Scalars['String']>;
  course_subject_id?: Maybe<Scalars['ID']>;
  cover_file_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  discount_price?: Maybe<Scalars['Int']>;
  documents?: Maybe<Array<CourseDocumentInput>>;
  id: Scalars['ID'];
  learning_format?: Maybe<CourseLearningFormat>;
  listener_category?: Maybe<CourseListenerCategory>;
  name?: Maybe<Scalars['String']>;
  partner_logo_file_id?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  source?: Maybe<CourseSource>;
  status?: Maybe<CourseStatus>;
  tags?: Maybe<Array<Scalars['String']>>;
  teachers?: Maybe<Array<CourseTeacherInput>>;
};

export enum CourseCompletionProof {
  AdvancedTrainingCertificate = 'AdvancedTrainingCertificate',
  Certificate = 'Certificate',
  None = 'None',
  ProfessionalRetrainingCertificate = 'ProfessionalRetrainingCertificate',
  ProfessionalRetrainingDiploma = 'ProfessionalRetrainingDiploma'
}

export type CourseDocumentInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum CourseLearningFormat {
  FullTime = 'FullTime',
  FullTimeWithRemote = 'FullTimeWithRemote',
  Remote = 'Remote'
}

export enum CourseListenerCategory {
  AdministrativeManagementStaff = 'AdministrativeManagementStaff',
  All = 'All',
  EngineeringStaff = 'EngineeringStaff',
  ProfessorsTeachersStaff = 'ProfessorsTeachersStaff',
  ResearchStaff = 'ResearchStaff',
  ScientificPedagogicalStaff = 'ScientificPedagogicalStaff'
}

export type CourseLongreadCreateInput = {
  completion_proof?: Maybe<CourseCompletionProof>;
  content?: Maybe<Scalars['String']>;
  course_subject_id?: Maybe<Scalars['ID']>;
  cover_file_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  discount_price?: Maybe<Scalars['Int']>;
  documents?: Maybe<Array<CourseDocumentInput>>;
  learning_format?: Maybe<CourseLearningFormat>;
  listener_category?: Maybe<CourseListenerCategory>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  source: CourseSource;
  status: CourseStatus;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type CourseLongreadUpdateInput = {
  completion_proof?: Maybe<CourseCompletionProof>;
  content?: Maybe<Scalars['String']>;
  course_subject_id?: Maybe<Scalars['ID']>;
  cover_file_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  discount_price?: Maybe<Scalars['Int']>;
  documents?: Maybe<Array<CourseDocumentInput>>;
  id: Scalars['ID'];
  learning_format?: Maybe<CourseLearningFormat>;
  listener_category?: Maybe<CourseListenerCategory>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  source?: Maybe<CourseSource>;
  status?: Maybe<CourseStatus>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export enum CourseSource {
  Mipt = 'MIPT',
  Partner = 'PARTNER'
}

export enum CourseStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type CourseSubjectType = {
  __typename?: 'CourseSubjectType';
  count_courses: Scalars['Int'];
  courses?: Maybe<Array<CourseType>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CourseSubjectTypeCoursesArgs = {
  filter?: Maybe<CoursesFilter>;
  limit?: Maybe<Scalars['Int']>;
};

export type CourseSubjectsFilter = {
  ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
};

export type CourseSubjectsPageInput = {
  filter?: Maybe<CourseSubjectsFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type CourseSubjectsPageType = {
  __typename?: 'CourseSubjectsPageType';
  header: PaginationHeaderType;
  list: Array<CourseSubjectType>;
};

export type CourseTeacherInput = {
  name: Scalars['String'];
  position?: Maybe<Scalars['String']>;
  speciality?: Maybe<Scalars['String']>;
};

export type CourseTeacherType = {
  __typename?: 'CourseTeacherType';
  name: Scalars['String'];
  position?: Maybe<Scalars['String']>;
  speciality?: Maybe<Scalars['String']>;
};

export type CourseType = {
  __typename?: 'CourseType';
  acquired_knowledge?: Maybe<Scalars['String']>;
  completion_proof?: Maybe<CourseCompletionProof>;
  content?: Maybe<Scalars['String']>;
  course_link?: Maybe<Scalars['String']>;
  course_subject?: Maybe<CourseSubjectType>;
  course_subject_id?: Maybe<Scalars['ID']>;
  cover_file?: Maybe<FileType>;
  cover_file_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  discount_price?: Maybe<Scalars['Int']>;
  document_files?: Maybe<Array<FileType>>;
  has_discount?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  learning_format?: Maybe<CourseLearningFormat>;
  listener_category?: Maybe<CourseListenerCategory>;
  name: Scalars['String'];
  partner_logo_file?: Maybe<FileType>;
  partner_logo_file_id?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  source: CourseSource;
  status: CourseStatus;
  tags?: Maybe<Array<TagType>>;
  teachers?: Maybe<Array<CourseTeacherType>>;
  type: CourseTypesEnum;
};

export enum CourseTypesEnum {
  Announce = 'ANNOUNCE',
  Longread = 'LONGREAD'
}

export type CoursesFilter = {
  completion_proof?: Maybe<Array<CourseCompletionProof>>;
  course_subject_ids?: Maybe<Array<Scalars['ID']>>;
  has_discount?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  learning_format?: Maybe<Array<CourseLearningFormat>>;
  listener_category?: Maybe<Array<CourseListenerCategory>>;
  price_max?: Maybe<Scalars['Int']>;
  price_min?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  source?: Maybe<CourseSource>;
  tag_ids?: Maybe<Array<Scalars['ID']>>;
  type?: Maybe<CourseTypesEnum>;
};

export type CoursesPageInput = {
  filter?: Maybe<CoursesFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type CoursesPageType = {
  __typename?: 'CoursesPageType';
  header: PaginationHeaderType;
  list: Array<CourseType>;
};

export type DateRangeType = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type DegreeType = {
  __typename?: 'DegreeType';
  c_date?: Maybe<Scalars['DateTime']>;
  c_name?: Maybe<Scalars['String']>;
  c_type?: Maybe<Scalars['String']>;
};

export type DeviceInput = {
  token: Scalars['String'];
};

export type DocumentCreateInput = {
  document_section_id: Scalars['ID'];
  file_id: Scalars['ID'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
};

export type DocumentSectionCreateInput = {
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
};

export type DocumentSectionType = {
  __typename?: 'DocumentSectionType';
  documents?: Maybe<Array<DocumentType>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
};

export type DocumentSectionUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  document_section_id: Scalars['ID'];
  file: FileType;
  file_id: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
};

export type DocumentUpdateInput = {
  document_section_id?: Maybe<Scalars['ID']>;
  file_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
};

export type EducationType = {
  __typename?: 'EducationType';
  c_date_end?: Maybe<Scalars['DateTime']>;
  c_educational_institution?: Maybe<Scalars['String']>;
  c_kind_of_education?: Maybe<Scalars['String']>;
  c_name_training?: Maybe<Scalars['String']>;
  c_specialization?: Maybe<Scalars['String']>;
};

export enum EmployeeTestStatusesEnum {
  Sent = 'SENT',
  Started = 'STARTED'
}

export type EventCreateInput = {
  content: Scalars['String'];
  cover_file_id?: Maybe<Scalars['ID']>;
  date_end?: Maybe<Scalars['DateTime']>;
  date_start: Scalars['DateTime'];
  priority?: Maybe<Scalars['Int']>;
  snippet?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type EventFilter = {
  range?: Maybe<OptionalDateRangeType>;
  search?: Maybe<Scalars['String']>;
};

export type EventPageInput = {
  filter?: Maybe<EventFilter>;
  pagination?: Maybe<PaginationInput>;
  sorting?: Maybe<Array<EventPageSorting>>;
};

export type EventPageSorting = {
  direction?: Maybe<SortingDirectionEnum>;
  field?: Maybe<EventSortingFieldsEnum>;
  nulls?: Maybe<SortingNullsEnum>;
};

export enum EventSortingFieldsEnum {
  DateEnd = 'date_end',
  DateStart = 'date_start',
  Priority = 'priority'
}

export type EventType = {
  __typename?: 'EventType';
  content: Scalars['String'];
  cover_file_id?: Maybe<Scalars['ID']>;
  date_end?: Maybe<Scalars['DateTime']>;
  date_start: Scalars['DateTime'];
  id: Scalars['ID'];
  priority?: Maybe<Scalars['Int']>;
  snippet?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type EventUpdateInput = {
  content?: Maybe<Scalars['String']>;
  cover_file_id?: Maybe<Scalars['ID']>;
  date_end?: Maybe<Scalars['DateTime']>;
  date_start?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  priority?: Maybe<Scalars['Int']>;
  snippet?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type EventsPageType = {
  __typename?: 'EventsPageType';
  header: PaginationHeaderType;
  list: Array<EventType>;
};

export type ExternalPollCreateInput = {
  cover_file_id?: Maybe<Scalars['ID']>;
  ends_at?: Maybe<Scalars['DateTime']>;
  link: Scalars['String'];
  name: Scalars['String'];
  participant_ids?: Maybe<Array<Scalars['ID']>>;
  participants_all?: Maybe<Scalars['Boolean']>;
  participating_units?: Maybe<Array<ExternalPollUnitInput>>;
  passcode?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['DateTime']>;
};

export enum ExternalPollSortingFieldsEnum {
  CreatedAt = 'created_at',
  EndsAt = 'ends_at',
  StartsAt = 'starts_at'
}

export type ExternalPollType = {
  __typename?: 'ExternalPollType';
  cover_file_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['DateTime']>;
  ends_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  link: Scalars['String'];
  name: Scalars['String'];
  participants?: Maybe<Array<UserType>>;
  participants_all?: Maybe<Scalars['Boolean']>;
  participating_units?: Maybe<Array<ExternalPollUnitType>>;
  passcode?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['DateTime']>;
};

export type ExternalPollUnitInput = {
  include_children?: Maybe<Scalars['Boolean']>;
  unit_id: Scalars['ID'];
};

export type ExternalPollUnitType = {
  __typename?: 'ExternalPollUnitType';
  include_children?: Maybe<Scalars['Boolean']>;
  unit: UnitType;
  unit_id: Scalars['ID'];
};

export type ExternalPollUpdateInput = {
  cover_file_id?: Maybe<Scalars['ID']>;
  ends_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  participant_ids?: Maybe<Array<Scalars['ID']>>;
  participants_all?: Maybe<Scalars['Boolean']>;
  participating_units?: Maybe<Array<ExternalPollUnitInput>>;
  passcode?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['DateTime']>;
};

export type ExternalPollsFilter = {
  participant_ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
  tense?: Maybe<TensesEnum>;
};

export type ExternalPollsPageInput = {
  filter?: Maybe<ExternalPollsFilter>;
  pagination?: Maybe<PaginationInput>;
  sorting?: Maybe<Array<ExternalPollsPageSorting>>;
};

export type ExternalPollsPageSorting = {
  direction?: Maybe<SortingDirectionEnum>;
  field?: Maybe<ExternalPollSortingFieldsEnum>;
  nulls?: Maybe<SortingNullsEnum>;
};

export type ExternalPollsPageType = {
  __typename?: 'ExternalPollsPageType';
  header: PaginationHeaderType;
  list: Array<ExternalPollType>;
};

export type FeedbackDocumentInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FeedbackFilter = {
  date_range?: Maybe<OptionalDateRangeType>;
};

export type FeedbackInput = {
  documents?: Maybe<Array<FeedbackDocumentInput>>;
  message: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  topic: Scalars['String'];
};

export type FeedbackPageInput = {
  filter?: Maybe<FeedbackFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type FeedbackType = {
  __typename?: 'FeedbackType';
  created_at: Scalars['DateTime'];
  creator: UserType;
  creator_id?: Maybe<Scalars['ID']>;
  document_files?: Maybe<Array<FileType>>;
  id: Scalars['ID'];
  message: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  topic: Scalars['String'];
};

export type FeedbacksPageType = {
  __typename?: 'FeedbacksPageType';
  header: PaginationHeaderType;
  list: Array<FeedbackType>;
};

export type FileType = {
  __typename?: 'FileType';
  id: Scalars['ID'];
  mimetype?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  size: Scalars['Int'];
  type: FileTypesEnum;
};

export enum FileTypesEnum {
  ArticleCover = 'ARTICLE_COVER',
  ArticleDocument = 'ARTICLE_DOCUMENT',
  ArticleImage = 'ARTICLE_IMAGE',
  Avatar = 'AVATAR',
  Common = 'COMMON',
  CourseDocument = 'COURSE_DOCUMENT',
  CourseImage = 'COURSE_IMAGE',
  Document = 'DOCUMENT',
  EventCover = 'EVENT_COVER',
  ExternalPollCover = 'EXTERNAL_POLL_COVER',
  FeedbackDocument = 'FEEDBACK_DOCUMENT',
  InternshipCover = 'INTERNSHIP_COVER',
  InternshipManual = 'INTERNSHIP_MANUAL',
  Manual = 'MANUAL',
  NoticeImage = 'NOTICE_IMAGE',
  OfferCover = 'OFFER_COVER',
  OfferDocument = 'OFFER_DOCUMENT',
  OfferPartnerLogo = 'OFFER_PARTNER_LOGO',
  PersonnelReserveCover = 'PERSONNEL_RESERVE_COVER',
  PollCover = 'POLL_COVER'
}

export enum InternshipContest {
  No = 'No',
  Yes = 'Yes'
}

export type InternshipCreateInput = {
  conditions: Scalars['String'];
  contest?: Maybe<InternshipContest>;
  cover_file_id?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  duration?: Maybe<InternshipDuration>;
  financial_conditions?: Maybe<InternshipFinancialConditions>;
  internship_subject_id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  location?: Maybe<InternshipLocation>;
  manual_file_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  purpose?: Maybe<InternshipPurpose>;
  tags?: Maybe<Array<Scalars['String']>>;
  trainee_category?: Maybe<InternshipTraineeCategory>;
};

export enum InternshipDuration {
  LongTerm = 'LongTerm',
  MidTerm = 'MidTerm',
  ShortTerm = 'ShortTerm'
}

export enum InternshipFinancialConditions {
  WithSalary = 'WithSalary',
  WithSalaryAtMainJob = 'WithSalaryAtMainJob'
}

export enum InternshipLocation {
  ForeignInCis = 'ForeignInCis',
  ForeignOutsideCis = 'ForeignOutsideCis',
  MoscowAndMoscowRegion = 'MoscowAndMoscowRegion',
  RussiaRegions = 'RussiaRegions'
}

export enum InternshipPurpose {
  ExchangeOfExperience = 'ExchangeOfExperience',
  FormationOfManagementSkills = 'FormationOfManagementSkills',
  FormationOfProfessionalSkills = 'FormationOfProfessionalSkills',
  ParticipationInJointResearch = 'ParticipationInJointResearch'
}

export type InternshipSubjectType = {
  __typename?: 'InternshipSubjectType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InternshipSubjectsFilter = {
  ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
};

export type InternshipSubjectsPageType = {
  __typename?: 'InternshipSubjectsPageType';
  header: PaginationHeaderType;
  list: Array<InternshipSubjectType>;
};

export enum InternshipTraineeCategory {
  AdministrativeManagementStaff = 'AdministrativeManagementStaff',
  ScientificPedagogicalStaff = 'ScientificPedagogicalStaff',
  TalentPoolProgramMembers = 'TalentPoolProgramMembers'
}

export type InternshipType = {
  __typename?: 'InternshipType';
  conditions: Scalars['String'];
  contest?: Maybe<InternshipContest>;
  cover_file?: Maybe<FileType>;
  cover_file_id?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  duration?: Maybe<InternshipDuration>;
  financial_conditions?: Maybe<InternshipFinancialConditions>;
  id: Scalars['ID'];
  internship_subject?: Maybe<InternshipSubjectType>;
  internship_subject_id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  location?: Maybe<InternshipLocation>;
  manual_file?: Maybe<FileType>;
  manual_file_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  purpose?: Maybe<InternshipPurpose>;
  tags?: Maybe<Array<TagType>>;
  trainee_category?: Maybe<InternshipTraineeCategory>;
};

export type InternshipUpdateInput = {
  conditions?: Maybe<Scalars['String']>;
  contest?: Maybe<InternshipContest>;
  cover_file_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<InternshipDuration>;
  financial_conditions?: Maybe<InternshipFinancialConditions>;
  id: Scalars['ID'];
  internship_subject_id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  location?: Maybe<InternshipLocation>;
  manual_file_id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  purpose?: Maybe<InternshipPurpose>;
  tags?: Maybe<Array<Scalars['String']>>;
  trainee_category?: Maybe<InternshipTraineeCategory>;
};

export type InternshipsFilter = {
  contest?: Maybe<Array<InternshipContest>>;
  duration?: Maybe<Array<InternshipDuration>>;
  financial_conditions?: Maybe<Array<InternshipFinancialConditions>>;
  ids?: Maybe<Array<Scalars['ID']>>;
  internship_subject_ids?: Maybe<Array<Scalars['ID']>>;
  location?: Maybe<Array<InternshipLocation>>;
  purpose?: Maybe<Array<InternshipPurpose>>;
  search?: Maybe<Scalars['String']>;
  tag_ids?: Maybe<Array<Scalars['ID']>>;
  trainee_category?: Maybe<Array<InternshipTraineeCategory>>;
};

export type InternshipsPageInput = {
  filter?: Maybe<InternshipsFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type InternshipsPageType = {
  __typename?: 'InternshipsPageType';
  header: PaginationHeaderType;
  list: Array<InternshipType>;
};

export type InternshipsSubjectsPageInput = {
  filter?: Maybe<InternshipSubjectsFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type ManualCreateInput = {
  external_link?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type?: ManualTypeEnum;
};

export type ManualType = {
  __typename?: 'ManualType';
  external_link?: Maybe<Scalars['String']>;
  file?: Maybe<FileType>;
  file_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ManualTypeEnum;
};

export enum ManualTypeEnum {
  Infographics = 'INFOGRAPHICS',
  Manual = 'MANUAL',
  Video = 'VIDEO'
}

export type ManualUpdateInput = {
  external_link?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: ManualTypeEnum;
};

export type Mutation = {
  __typename?: 'Mutation';
  addDevice: Scalars['Boolean'];
  createAreaOfInterest: AreaOfInterestType;
  createArticle: ArticleType;
  createCampus: CampusType;
  createCourseAnnounce: CourseType;
  createCourseLongread: CourseType;
  createCourseTestResult: Scalars['Boolean'];
  createDocument: DocumentType;
  createDocumentSection: DocumentSectionType;
  createEvent: EventType;
  createExternalPoll: ExternalPollType;
  createInternship: InternshipType;
  createInternshipTestResult: Scalars['Boolean'];
  createManual: ManualType;
  createNotice: NoticeType;
  createOffer: OfferType;
  createOttForArticleCoverUpload: Scalars['String'];
  createOttForArticleDocumentUpload: Scalars['String'];
  createOttForArticleImageUpload: Scalars['String'];
  createOttForAssessmentReportDownload: Scalars['String'];
  createOttForAvatarUpload: Scalars['String'];
  createOttForCourseDocumentUpload: Scalars['String'];
  createOttForCourseImageUpload: Scalars['String'];
  createOttForDocumentUpload: Scalars['String'];
  createOttForEventCoverUpload: Scalars['String'];
  createOttForExternalPollCoverUpload: Scalars['String'];
  createOttForFeedbackDocumentUpload: Scalars['String'];
  createOttForFeedbacksExportDownload: Scalars['String'];
  createOttForFileDownload: Scalars['String'];
  createOttForInternshipCoverUpload: Scalars['String'];
  createOttForInternshipManualUpload: Scalars['String'];
  createOttForManualUpload: Scalars['String'];
  createOttForNoticeImageUpload: Scalars['String'];
  createOttForOfferCoverUpload: Scalars['String'];
  createOttForOfferDocumentUpload: Scalars['String'];
  createOttForOfferPartnerLogoUpload: Scalars['String'];
  createOttForPersonnelReserveCoverUpload: Scalars['String'];
  createOttForPollAttemptsReportDownload: Scalars['String'];
  createOttForUsersExportDownload: Scalars['String'];
  createPersonnelReserve?: Maybe<PersonnelReserveType>;
  createPollAttempt: PollAttemptType;
  createProject: ProjectType;
  createRole: RoleType;
  createSystemLink: SystemLinkType;
  deleteArticle: Scalars['Boolean'];
  deleteCampus: Scalars['Boolean'];
  deleteCourse: Scalars['Boolean'];
  deleteDocument: Scalars['Boolean'];
  deleteDocumentSection: Scalars['Boolean'];
  deleteEvent: Scalars['Boolean'];
  deleteExternalPoll: Scalars['Boolean'];
  deleteInternship: Scalars['Boolean'];
  deleteManual: Scalars['Boolean'];
  deleteNotice: Scalars['Boolean'];
  deleteOffer: Scalars['Boolean'];
  deletePersonnelReserve: Scalars['Boolean'];
  deleteProject: Scalars['Boolean'];
  deleteRole: Scalars['Boolean'];
  deleteSystemLink: Scalars['Boolean'];
  leaveFeedback: Scalars['Boolean'];
  leaveVacationApplication: VacationApplicationType;
  login: AuthResponseType;
  loginWithId: AuthResponseType;
  logout: Scalars['Boolean'];
  quitProject: Scalars['Boolean'];
  sendProjectApplication: Scalars['Boolean'];
  submitVacationSchedule: Array<VacationScheduleType>;
  updateArticle: ArticleType;
  updateCampus: CampusType;
  updateCourseAnnounce: CourseType;
  updateCourseLongread: CourseType;
  updateDocument: DocumentType;
  updateDocumentSection: DocumentSectionType;
  updateEvent: EventType;
  updateExternalPoll: ExternalPollType;
  updateInternship: InternshipType;
  updateManual: ManualType;
  updateNotice: NoticeType;
  updateOffer: OfferType;
  updatePersonnelReserve: PersonnelReserveType;
  updateProject?: Maybe<ProjectType>;
  updateRole: RoleType;
  updateSettings: UserSettingsType;
  updateSystemLink: SystemLinkType;
  updateUser: UserType;
  useRefreshToken: AuthResponseType;
};

export type MutationAddDeviceArgs = {
  input: DeviceInput;
};

export type MutationCreateAreaOfInterestArgs = {
  input: AreaOfInterestCreateInput;
};

export type MutationCreateArticleArgs = {
  input: ArticleCreateInput;
};

export type MutationCreateCampusArgs = {
  input: CampusCreateInput;
};

export type MutationCreateCourseAnnounceArgs = {
  input: CourseAnnounceCreateInput;
};

export type MutationCreateCourseLongreadArgs = {
  input: CourseLongreadCreateInput;
};

export type MutationCreateCourseTestResultArgs = {
  input: Scalars['String'];
};

export type MutationCreateDocumentArgs = {
  input: DocumentCreateInput;
};

export type MutationCreateDocumentSectionArgs = {
  input: DocumentSectionCreateInput;
};

export type MutationCreateEventArgs = {
  input: EventCreateInput;
};

export type MutationCreateExternalPollArgs = {
  input: ExternalPollCreateInput;
};

export type MutationCreateInternshipArgs = {
  input: InternshipCreateInput;
};

export type MutationCreateInternshipTestResultArgs = {
  input: Scalars['String'];
};

export type MutationCreateManualArgs = {
  input: ManualCreateInput;
};

export type MutationCreateNoticeArgs = {
  input: NoticeCreateInput;
};

export type MutationCreateOfferArgs = {
  input: OfferCreateInput;
};

export type MutationCreateOttForAssessmentReportDownloadArgs = {
  assessment_report_id: Scalars['ID'];
};

export type MutationCreateOttForFileDownloadArgs = {
  file_id: Scalars['ID'];
};

export type MutationCreateOttForPollAttemptsReportDownloadArgs = {
  poll_id: Scalars['ID'];
};

export type MutationCreatePersonnelReserveArgs = {
  input: PersonnelReserveCreateInput;
};

export type MutationCreatePollAttemptArgs = {
  input: PollAttemptCreateInput;
};

export type MutationCreateProjectArgs = {
  input: ProjectCreateInput;
};

export type MutationCreateRoleArgs = {
  input: RoleCreateInput;
};

export type MutationCreateSystemLinkArgs = {
  input: SystemLinkCreateInput;
};

export type MutationDeleteArticleArgs = {
  article_id: Scalars['ID'];
};

export type MutationDeleteCampusArgs = {
  campus_id: Scalars['ID'];
};

export type MutationDeleteCourseArgs = {
  course_id: Scalars['ID'];
};

export type MutationDeleteDocumentArgs = {
  document_id: Scalars['ID'];
};

export type MutationDeleteDocumentSectionArgs = {
  document_section_id: Scalars['ID'];
};

export type MutationDeleteEventArgs = {
  event_id: Scalars['ID'];
};

export type MutationDeleteExternalPollArgs = {
  external_poll_id: Scalars['ID'];
};

export type MutationDeleteInternshipArgs = {
  internship_id: Scalars['ID'];
};

export type MutationDeleteManualArgs = {
  manual_id: Scalars['ID'];
};

export type MutationDeleteNoticeArgs = {
  notice_id: Scalars['ID'];
};

export type MutationDeleteOfferArgs = {
  offer_id: Scalars['ID'];
};

export type MutationDeletePersonnelReserveArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProjectArgs = {
  project_id: Scalars['ID'];
};

export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteSystemLinkArgs = {
  system_link_id: Scalars['ID'];
};

export type MutationLeaveFeedbackArgs = {
  input: FeedbackInput;
};

export type MutationLeaveVacationApplicationArgs = {
  input: VacationApplicationInput;
};

export type MutationLoginArgs = {
  emailOrLogin: Scalars['String'];
  password: Scalars['String'];
};

export type MutationLoginWithIdArgs = {
  id: Scalars['Int'];
};

export type MutationLogoutArgs = {
  token: Scalars['String'];
};

export type MutationQuitProjectArgs = {
  project_id: Scalars['ID'];
};

export type MutationSendProjectApplicationArgs = {
  project_id: Scalars['ID'];
};

export type MutationSubmitVacationScheduleArgs = {
  input: VacationScheduleInput;
};

export type MutationUpdateArticleArgs = {
  input: ArticleUpdateInput;
};

export type MutationUpdateCampusArgs = {
  input: CampusUpdateInput;
};

export type MutationUpdateCourseAnnounceArgs = {
  input: CourseAnnounceUpdateInput;
};

export type MutationUpdateCourseLongreadArgs = {
  input: CourseLongreadUpdateInput;
};

export type MutationUpdateDocumentArgs = {
  input: DocumentUpdateInput;
};

export type MutationUpdateDocumentSectionArgs = {
  input: DocumentSectionUpdateInput;
};

export type MutationUpdateEventArgs = {
  input: EventUpdateInput;
};

export type MutationUpdateExternalPollArgs = {
  input: ExternalPollUpdateInput;
};

export type MutationUpdateInternshipArgs = {
  input: InternshipUpdateInput;
};

export type MutationUpdateManualArgs = {
  input: ManualUpdateInput;
};

export type MutationUpdateNoticeArgs = {
  input: NoticeUpdateInput;
};

export type MutationUpdateOfferArgs = {
  input: OfferUpdateInput;
};

export type MutationUpdatePersonnelReserveArgs = {
  input: PersonnelReserveUpdateInput;
};

export type MutationUpdateProjectArgs = {
  input: ProjectUpdateInput;
};

export type MutationUpdateRoleArgs = {
  input: RoleUpdateInput;
};

export type MutationUpdateSettingsArgs = {
  input: UserSettingsInput;
};

export type MutationUpdateSystemLinkArgs = {
  input: SystemLinkUpdateInput;
};

export type MutationUpdateUserArgs = {
  input: UserUpdateInput;
};

export type MutationUseRefreshTokenArgs = {
  token: Scalars['String'];
};

export type NoticeCreateInput = {
  contact_campus?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_phone?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  cover_file_id?: Maybe<Scalars['ID']>;
  image_file_ids?: Maybe<Array<Scalars['ID']>>;
  title: Scalars['String'];
};

export type NoticeFilter = {
  search?: Maybe<Scalars['String']>;
  user_is_creator?: Maybe<Scalars['Boolean']>;
};

export type NoticePageInput = {
  filter?: Maybe<NoticeFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type NoticeType = {
  __typename?: 'NoticeType';
  contact_campus?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_phone?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  cover_file?: Maybe<FileType>;
  cover_file_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['DateTime']>;
  creator: UserType;
  creator_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  image_files?: Maybe<Array<FileType>>;
  title: Scalars['String'];
};

export type NoticeUpdateInput = {
  contact_campus?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_phone?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  cover_file_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  image_file_ids?: Maybe<Array<Scalars['ID']>>;
  title: Scalars['String'];
};

export type NoticesPageType = {
  __typename?: 'NoticesPageType';
  header: PaginationHeaderType;
  list: Array<NoticeType>;
};

export enum NotificationTypesEnum {
  Email = 'EMAIL'
}

export type OfferCategoriesFilter = {
  ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
};

export type OfferCategoriesPageInput = {
  filter?: Maybe<OfferCategoriesFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type OfferCategoriesPageType = {
  __typename?: 'OfferCategoriesPageType';
  header: PaginationHeaderType;
  list: Array<OfferCategoryType>;
};

export type OfferCategoryType = {
  __typename?: 'OfferCategoryType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OfferCreateInput = {
  category_id?: Maybe<Scalars['ID']>;
  conditions: Scalars['String'];
  cover_file_id?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  documents?: Maybe<Array<OfferDocument>>;
  expires_at?: Maybe<Scalars['DateTime']>;
  kind_id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  partner_logo_file_id?: Maybe<Scalars['ID']>;
  snippet: Scalars['String'];
  source: OfferSourcesEnum;
};

export type OfferDocument = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OfferKindType = {
  __typename?: 'OfferKindType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OfferKindsFilter = {
  ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
};

export type OfferKindsPageInput = {
  filter?: Maybe<OfferKindsFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type OfferKindsPageType = {
  __typename?: 'OfferKindsPageType';
  header: PaginationHeaderType;
  list: Array<OfferKindType>;
};

export enum OfferSourcesEnum {
  Mipt = 'MIPT',
  Partner = 'PARTNER'
}

export type OfferType = {
  __typename?: 'OfferType';
  category?: Maybe<OfferCategoryType>;
  category_id?: Maybe<Scalars['ID']>;
  conditions: Scalars['String'];
  cover_file?: Maybe<FileType>;
  cover_file_id?: Maybe<Scalars['ID']>;
  created_at: Scalars['DateTime'];
  description: Scalars['String'];
  document_files?: Maybe<Array<FileType>>;
  expires_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  kind?: Maybe<OfferKindType>;
  kind_id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  partner_logo_file?: Maybe<FileType>;
  partner_logo_file_id?: Maybe<Scalars['ID']>;
  snippet: Scalars['String'];
  source: OfferSourcesEnum;
};

export type OfferUpdateInput = {
  category_id?: Maybe<Scalars['ID']>;
  conditions?: Maybe<Scalars['String']>;
  cover_file_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<OfferDocument>>;
  expires_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  kind_id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  partner_logo_file_id?: Maybe<Scalars['ID']>;
  snippet?: Maybe<Scalars['String']>;
  source?: Maybe<OfferSourcesEnum>;
};

export type OffersFilter = {
  active?: Maybe<Scalars['Boolean']>;
  category_ids?: Maybe<Array<Scalars['ID']>>;
  kind_ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
  source?: Maybe<OfferSourcesEnum>;
};

export type OffersPageInput = {
  filter?: Maybe<OffersFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type OffersPageType = {
  __typename?: 'OffersPageType';
  header: PaginationHeaderType;
  list: Array<OfferType>;
};

export type OptionalDateRangeType = {
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type PaginationHeaderType = {
  __typename?: 'PaginationHeaderType';
  hasNext?: Maybe<Scalars['Boolean']>;
  onePage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginationInput = {
  onePage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type PermissionInput = {
  base_permission_code: Scalars['String'];
  object_id?: Maybe<Scalars['Int']>;
  object_type?: Maybe<Scalars['String']>;
};

export type PermissionType = {
  __typename?: 'PermissionType';
  base_permission: BasePermissionType;
  base_permission_code: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  object_id?: Maybe<Scalars['Int']>;
  object_type?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['ID']>;
};

export enum PermissionTypeEnum {
  Global = 'GLOBAL',
  Object = 'OBJECT'
}

export type PersonnelReserveCreateInput = {
  cover_file_id?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  name: Scalars['String'];
  snippet: Scalars['String'];
};

export type PersonnelReserveFilter = {
  search?: Maybe<Scalars['String']>;
};

export type PersonnelReservePageInput = {
  filter?: Maybe<PersonnelReserveFilter>;
  pagination?: Maybe<PaginationInput>;
  sorting?: Maybe<Array<PersonnelReservePageSorting>>;
};

export type PersonnelReservePageSorting = {
  direction?: Maybe<SortingDirectionEnum>;
  field?: Maybe<PersonnelReserveSortingFieldsEnum>;
  nulls?: Maybe<SortingNullsEnum>;
};

export type PersonnelReservePageType = {
  __typename?: 'PersonnelReservePageType';
  header: PaginationHeaderType;
  list: Array<PersonnelReserveType>;
};

export enum PersonnelReserveSortingFieldsEnum {
  CreatedAt = 'created_at'
}

export type PersonnelReserveType = {
  __typename?: 'PersonnelReserveType';
  cover_file_id?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  snippet: Scalars['String'];
};

export type PersonnelReserveUpdateInput = {
  cover_file_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['String']>;
};

export type PollAnswerInput = {
  question_id: Scalars['ID'];
  value_scale?: Maybe<Scalars['Int']>;
  value_text?: Maybe<Scalars['String']>;
};

export type PollAnswerType = {
  __typename?: 'PollAnswerType';
  attempt: PollAttemptType;
  attempt_id: Scalars['ID'];
  id: Scalars['ID'];
  question: PollQuestionType;
  question_id: Scalars['ID'];
  value_scale?: Maybe<Scalars['Int']>;
  value_text?: Maybe<Scalars['String']>;
};

export type PollAttemptCreateInput = {
  answers: Array<PollAnswerInput>;
  poll_id: Scalars['ID'];
};

export type PollAttemptType = {
  __typename?: 'PollAttemptType';
  answers?: Maybe<Array<PollAnswerType>>;
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  poll: PollType;
  poll_id: Scalars['ID'];
};

export type PollAttemptsFilter = {
  poll_ids?: Maybe<Array<Scalars['ID']>>;
};

export type PollAttemptsPage = {
  __typename?: 'PollAttemptsPage';
  header: PaginationHeaderType;
  list: Array<PollAttemptType>;
};

export type PollAttemptsPageInput = {
  filter?: Maybe<PollAttemptsFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type PollQuestionType = {
  __typename?: 'PollQuestionType';
  content: Scalars['String'];
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  poll: PollType;
  poll_id: Scalars['ID'];
  type: PollQuestionTypesEnum;
};

export enum PollQuestionTypesEnum {
  Scale = 'SCALE',
  Text = 'TEXT'
}

export type PollType = {
  __typename?: 'PollType';
  canAccess: Scalars['Boolean'];
  canViewAttempts: Scalars['Boolean'];
  cover_file?: Maybe<FileType>;
  cover_file_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions?: Maybe<Array<PollQuestionType>>;
};

export type PollsFilter = {
  ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
};

export type PollsPageInput = {
  filter?: Maybe<PollsFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type PollsPageType = {
  __typename?: 'PollsPageType';
  header: PaginationHeaderType;
  list: Array<PollType>;
};

export type PositionType = {
  __typename?: 'PositionType';
  c_category: Scalars['String'];
  c_category_pps: Scalars['Boolean'];
  c_category_scientist: Scalars['Boolean'];
  c_combine_position_in: Scalars['Boolean'];
  c_combine_position_out: Scalars['Boolean'];
  c_combine_position_work: Scalars['String'];
  c_end_date?: Maybe<Scalars['DateTime']>;
  c_id_department: Scalars['String'];
  c_id_director?: Maybe<Scalars['String']>;
  c_id_wage_rate: Scalars['String'];
  c_position_name: Scalars['String'];
  c_start_date?: Maybe<Scalars['DateTime']>;
  c_stuff_uid: Scalars['String'];
  c_worker_id: Scalars['String'];
  director?: Maybe<UserType>;
  director_id?: Maybe<Scalars['ID']>;
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  is_head: Scalars['Boolean'];
  unit?: Maybe<UnitType>;
  unit_id: Scalars['ID'];
  user?: Maybe<UserType>;
  user_id: Scalars['ID'];
  vacations_disabled: Scalars['Boolean'];
};

export type ProjectCreateInput = {
  description: Scalars['String'];
  head_user_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  participants?: Maybe<Array<ProjectParticipantInput>>;
  status: ProjectStatusEnum;
};

export type ProjectFilter = {
  search?: Maybe<Scalars['String']>;
  status?: Maybe<ProjectStatusEnum>;
  user_is_creator?: Maybe<Scalars['Boolean']>;
  user_is_participating?: Maybe<Scalars['Boolean']>;
};

export type ProjectPageInput = {
  filter?: Maybe<ProjectFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type ProjectParticipantInput = {
  participation_date?: Maybe<Scalars['DateTime']>;
  user_id: Scalars['ID'];
};

export type ProjectParticipantType = {
  __typename?: 'ProjectParticipantType';
  participation_date?: Maybe<Scalars['DateTime']>;
  user: UserType;
  user_id: Scalars['ID'];
};

export enum ProjectStatusEnum {
  Active = 'ACTIVE',
  Completed = 'COMPLETED'
}

export type ProjectType = {
  __typename?: 'ProjectType';
  canDelete: Scalars['Boolean'];
  canUpdate: Scalars['Boolean'];
  description: Scalars['String'];
  head_user?: Maybe<UserType>;
  head_user_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  participants?: Maybe<Array<ProjectParticipantType>>;
  participants_count?: Maybe<Scalars['Int']>;
  status: ProjectStatusEnum;
};

export type ProjectUpdateInput = {
  description?: Maybe<Scalars['String']>;
  head_user_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<ProjectParticipantInput>>;
  status?: Maybe<ProjectStatusEnum>;
};

export type ProjectsPageType = {
  __typename?: 'ProjectsPageType';
  header: PaginationHeaderType;
  list: Array<ProjectType>;
};

export type PublicationInput = {
  date?: Maybe<Scalars['DateTime']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type PublicationType = {
  __typename?: 'PublicationType';
  date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  user_id: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  areasOfInterestPage?: Maybe<AreasOfInterestPageType>;
  article?: Maybe<ArticleType>;
  articlesPage?: Maybe<ArticlesPageType>;
  assessmentReports: Array<AssessmentReportType>;
  assessments: Array<AssessmentType>;
  basePermissions: Array<BasePermissionType>;
  campuses: Array<CampusType>;
  course?: Maybe<CourseType>;
  courseSubjects: Array<CourseSubjectType>;
  courseSubjectsPage: CourseSubjectsPageType;
  courseTestResult?: Maybe<Scalars['String']>;
  coursesPage?: Maybe<CoursesPageType>;
  documentSections?: Maybe<Array<DocumentSectionType>>;
  event?: Maybe<EventType>;
  eventDays: Array<Scalars['DateTime']>;
  eventsPage?: Maybe<EventsPageType>;
  externalPoll?: Maybe<ExternalPollType>;
  externalPollsPage?: Maybe<ExternalPollsPageType>;
  feedback: FeedbackType;
  feedbacksPage?: Maybe<FeedbacksPageType>;
  internship: InternshipType;
  internshipSubjectsPage: InternshipSubjectsPageType;
  internshipTestResult?: Maybe<Scalars['String']>;
  internshipsPage: InternshipsPageType;
  isSecureIp: Scalars['Boolean'];
  manuals?: Maybe<Array<ManualType>>;
  notice?: Maybe<NoticeType>;
  noticesPage?: Maybe<NoticesPageType>;
  offer?: Maybe<OfferType>;
  offerCategoriesPage?: Maybe<OfferCategoriesPageType>;
  offerKindsPage?: Maybe<OfferKindsPageType>;
  offersPage?: Maybe<OffersPageType>;
  personnelReserve?: Maybe<PersonnelReserveType>;
  personnelReservePage?: Maybe<PersonnelReservePageType>;
  poll?: Maybe<PollType>;
  pollAttempt?: Maybe<PollAttemptType>;
  pollAttemptsPage: PollAttemptsPage;
  pollsPage: PollsPageType;
  project?: Maybe<ProjectType>;
  projectsPage?: Maybe<ProjectsPageType>;
  role: RoleType;
  rolesPage: RolesPageType;
  skillsPage?: Maybe<SkillsPageType>;
  systemLinks?: Maybe<Array<SystemLinkType>>;
  tagsPage: TagsPageType;
  unitsPage: UnitsPageType;
  unpublishedCoursesPage?: Maybe<CoursesPageType>;
  user?: Maybe<UserType>;
  usersPage?: Maybe<UserPageType>;
  vacationApplications: Array<VacationApplicationType>;
  vacationApplicationsPage?: Maybe<VacationApplicationsPageType>;
  vacationBalance: Array<VacationBalanceType>;
  vacationSchedules: Array<VacationScheduleType>;
};

export type QueryAreasOfInterestPageArgs = {
  input?: Maybe<AreasOfInterestPageInput>;
};

export type QueryArticleArgs = {
  id: Scalars['ID'];
};

export type QueryArticlesPageArgs = {
  input?: Maybe<ArticlePageInput>;
};

export type QueryCampusesArgs = {
  filter?: Maybe<CampusFilter>;
};

export type QueryCourseArgs = {
  course_id: Scalars['ID'];
};

export type QueryCourseSubjectsArgs = {
  filter?: Maybe<CourseSubjectsFilter>;
};

export type QueryCourseSubjectsPageArgs = {
  input?: Maybe<CourseSubjectsPageInput>;
};

export type QueryCoursesPageArgs = {
  input?: Maybe<CoursesPageInput>;
};

export type QueryEventArgs = {
  id: Scalars['ID'];
};

export type QueryEventDaysArgs = {
  range: DateRangeType;
};

export type QueryEventsPageArgs = {
  input?: Maybe<EventPageInput>;
};

export type QueryExternalPollArgs = {
  external_poll_id: Scalars['ID'];
};

export type QueryExternalPollsPageArgs = {
  input?: Maybe<ExternalPollsPageInput>;
};

export type QueryFeedbackArgs = {
  id: Scalars['ID'];
};

export type QueryFeedbacksPageArgs = {
  input?: Maybe<FeedbackPageInput>;
};

export type QueryInternshipArgs = {
  internship_id: Scalars['ID'];
};

export type QueryInternshipSubjectsPageArgs = {
  input?: Maybe<InternshipsSubjectsPageInput>;
};

export type QueryInternshipsPageArgs = {
  input?: Maybe<InternshipsPageInput>;
};

export type QueryNoticeArgs = {
  notice_id: Scalars['ID'];
};

export type QueryNoticesPageArgs = {
  input?: Maybe<NoticePageInput>;
};

export type QueryOfferArgs = {
  id: Scalars['ID'];
};

export type QueryOfferCategoriesPageArgs = {
  input?: Maybe<OfferCategoriesPageInput>;
};

export type QueryOfferKindsPageArgs = {
  input?: Maybe<OfferKindsPageInput>;
};

export type QueryOffersPageArgs = {
  input?: Maybe<OffersPageInput>;
};

export type QueryPersonnelReserveArgs = {
  id: Scalars['ID'];
};

export type QueryPersonnelReservePageArgs = {
  input?: Maybe<PersonnelReservePageInput>;
};

export type QueryPollArgs = {
  poll_id: Scalars['ID'];
};

export type QueryPollAttemptArgs = {
  poll_attempt_id: Scalars['ID'];
};

export type QueryPollAttemptsPageArgs = {
  input?: Maybe<PollAttemptsPageInput>;
};

export type QueryPollsPageArgs = {
  input?: Maybe<PollsPageInput>;
};

export type QueryProjectArgs = {
  id: Scalars['ID'];
};

export type QueryProjectsPageArgs = {
  input?: Maybe<ProjectPageInput>;
};

export type QueryRoleArgs = {
  id: Scalars['ID'];
};

export type QueryRolesPageArgs = {
  input?: Maybe<RolesPageInput>;
};

export type QuerySkillsPageArgs = {
  input?: Maybe<SkillsPageInput>;
};

export type QueryTagsPageArgs = {
  input?: Maybe<TagsPageInput>;
};

export type QueryUnitsPageArgs = {
  input?: Maybe<UnitsPageInput>;
};

export type QueryUnpublishedCoursesPageArgs = {
  input?: Maybe<CoursesPageInput>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUsersPageArgs = {
  input?: Maybe<UserPageInput>;
};

export type QueryVacationApplicationsArgs = {
  filter?: Maybe<VacationApplicationFilter>;
};

export type QueryVacationApplicationsPageArgs = {
  input?: Maybe<VacationApplicationPageInput>;
};

export type RoleCreateInput = {
  name: Scalars['String'];
  permissions?: Maybe<Array<PermissionInput>>;
};

export type RoleType = {
  __typename?: 'RoleType';
  code?: Maybe<RolesEnum>;
  created_at?: Maybe<Scalars['DateTime']>;
  creator_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<PermissionType>>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type RoleUpdateInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<PermissionInput>>;
};

export enum RolesEnum {
  Admin = 'ADMIN',
  Default = 'DEFAULT'
}

export type RolesFilter = {
  creator_user_id?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
};

export type RolesPageInput = {
  filter?: Maybe<RolesFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type RolesPageType = {
  __typename?: 'RolesPageType';
  header: PaginationHeaderType;
  list: Array<RoleType>;
};

export type SkillFilter = {
  ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
};

export type SkillInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type SkillType = {
  __typename?: 'SkillType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SkillsPageInput = {
  filter?: Maybe<SkillFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type SkillsPageType = {
  __typename?: 'SkillsPageType';
  header: PaginationHeaderType;
  list: Array<SkillType>;
};

export enum SortingDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortingNullsEnum {
  First = 'FIRST',
  Last = 'LAST'
}

export type SystemLinkCreateInput = {
  link: Scalars['String'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
};

export type SystemLinkType = {
  __typename?: 'SystemLinkType';
  id: Scalars['ID'];
  link: Scalars['String'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
};

export type SystemLinkUpdateInput = {
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
};

export type TagType = {
  __typename?: 'TagType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TagsFilter = {
  ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
};

export type TagsPageInput = {
  filter?: Maybe<TagsFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type TagsPageType = {
  __typename?: 'TagsPageType';
  header: PaginationHeaderType;
  list: Array<TagType>;
};

export enum TensesEnum {
  Future = 'FUTURE',
  Past = 'PAST',
  Present = 'PRESENT'
}

export enum TokenType {
  Refresh = 'REFRESH'
}

export type UnitType = {
  __typename?: 'UnitType';
  child_units?: Maybe<Array<UnitType>>;
  head_user?: Maybe<UserType>;
  head_user_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parent_ids?: Maybe<Array<Scalars['Int']>>;
  parent_unit?: Maybe<UnitType>;
  parent_unit_id?: Maybe<Scalars['ID']>;
  prorector_user?: Maybe<UserType>;
  prorector_user_id?: Maybe<Scalars['ID']>;
};

export type UnitsFilter = {
  ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
};

export type UnitsPageInput = {
  filter?: Maybe<UnitsFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type UnitsPageType = {
  __typename?: 'UnitsPageType';
  header: PaginationHeaderType;
  list: Array<UnitType>;
};

export type UserAreaOfInterestInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserAuthTokenType = {
  __typename?: 'UserAuthTokenType';
  expires_at?: Maybe<Scalars['DateTime']>;
  token: Scalars['String'];
  type: TokenType;
};

export type UserContactType = {
  __typename?: 'UserContactType';
  id: Scalars['String'];
  internal_phone?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type UserFilter = {
  area_of_interest_ids?: Maybe<Array<Scalars['ID']>>;
  director_ids?: Maybe<Array<Scalars['ID']>>;
  exclude_ids?: Maybe<Array<Scalars['ID']>>;
  ids?: Maybe<Array<Scalars['ID']>>;
  is_director?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  skill_ids?: Maybe<Array<Scalars['ID']>>;
  unit_ids?: Maybe<Array<Scalars['ID']>>;
};

export type UserPageInput = {
  filter?: Maybe<UserFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type UserPageType = {
  __typename?: 'UserPageType';
  header: PaginationHeaderType;
  list: Array<UserType>;
};

export type UserSettingsInput = {
  article_notifications?: Maybe<Array<NotificationTypesEnum>>;
  course_notifications?: Maybe<Array<NotificationTypesEnum>>;
  event_notifications?: Maybe<Array<NotificationTypesEnum>>;
  internship_notifications?: Maybe<Array<NotificationTypesEnum>>;
  offer_notifications?: Maybe<Array<NotificationTypesEnum>>;
  poll_notifications?: Maybe<Array<NotificationTypesEnum>>;
  project_notifications?: Maybe<Array<NotificationTypesEnum>>;
};

export type UserSettingsType = {
  __typename?: 'UserSettingsType';
  article_notifications?: Maybe<Array<NotificationTypesEnum>>;
  course_notifications?: Maybe<Array<NotificationTypesEnum>>;
  event_notifications?: Maybe<Array<NotificationTypesEnum>>;
  internship_notifications?: Maybe<Array<NotificationTypesEnum>>;
  offer_notifications?: Maybe<Array<NotificationTypesEnum>>;
  poll_notifications?: Maybe<Array<NotificationTypesEnum>>;
  project_notifications?: Maybe<Array<NotificationTypesEnum>>;
};

export type UserType = {
  __typename?: 'UserType';
  about?: Maybe<Scalars['String']>;
  areas_of_interest?: Maybe<Array<AreaOfInterestType>>;
  avatar_file?: Maybe<FileType>;
  avatar_file_id?: Maybe<Scalars['ID']>;
  birthday?: Maybe<Scalars['DateTime']>;
  c_avatar_file?: Maybe<FileType>;
  c_avatar_file_id?: Maybe<Scalars['ID']>;
  campus?: Maybe<CampusType>;
  campus_id?: Maybe<Scalars['ID']>;
  contacts?: Maybe<Array<UserContactType>>;
  count_subordinates?: Maybe<Scalars['Int']>;
  course_test_result?: Maybe<Scalars['String']>;
  degrees?: Maybe<Array<DegreeType>>;
  educations?: Maybe<Array<EducationType>>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instagram?: Maybe<Scalars['String']>;
  internship_test_result?: Maybe<Scalars['String']>;
  is_director?: Maybe<Scalars['Boolean']>;
  login?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  other_emails_json?: Maybe<Scalars['String']>;
  other_phones_json?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<PermissionType>>;
  phone?: Maybe<Scalars['String']>;
  positions?: Maybe<Array<PositionType>>;
  project_participations?: Maybe<Array<ProjectType>>;
  publications?: Maybe<Array<PublicationType>>;
  roles?: Maybe<Array<RoleType>>;
  settings?: Maybe<UserSettingsType>;
  skills?: Maybe<Array<SkillType>>;
  surname?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  vk?: Maybe<Scalars['String']>;
  work_experiences?: Maybe<Array<WorkExperienceType>>;
  work_phone?: Maybe<Scalars['String']>;
};

export type UserUpdateInput = {
  about?: Maybe<Scalars['String']>;
  areas_of_interest?: Maybe<Array<UserAreaOfInterestInput>>;
  avatar_file_id?: Maybe<Scalars['ID']>;
  campus_id?: Maybe<Scalars['ID']>;
  course_test_result?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instagram?: Maybe<Scalars['String']>;
  internship_test_result?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  new_password?: Maybe<Scalars['String']>;
  other_emails_json?: Maybe<Scalars['String']>;
  other_phones_json?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<PermissionInput>>;
  phone?: Maybe<Scalars['String']>;
  publications?: Maybe<Array<PublicationInput>>;
  role_ids?: Maybe<Array<Scalars['ID']>>;
  skills?: Maybe<Array<SkillInput>>;
  surname?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  vk?: Maybe<Scalars['String']>;
  work_experiences?: Maybe<Array<WorkExperienceInput>>;
  work_phone?: Maybe<Scalars['String']>;
};

export type VacationApplicationFilter = {
  range?: Maybe<DateRangeType>;
};

export type VacationApplicationInput = {
  deputy_id?: Maybe<Scalars['ID']>;
  end: Scalars['DateTime'];
  position_id: Scalars['ID'];
  start: Scalars['DateTime'];
};

export type VacationApplicationPageInput = {
  filter?: Maybe<VacationApplicationFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type VacationApplicationType = {
  __typename?: 'VacationApplicationType';
  created_at: Scalars['DateTime'];
  deputy_id?: Maybe<Scalars['ID']>;
  end: Scalars['DateTime'];
  position: PositionType;
  position_id: Scalars['ID'];
  review_comment?: Maybe<Scalars['String']>;
  reviewed_at?: Maybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
  status: VacationStatusesEnum;
  updated_at: Scalars['DateTime'];
};

export type VacationApplicationsPageType = {
  __typename?: 'VacationApplicationsPageType';
  header: PaginationHeaderType;
  list: Array<VacationApplicationType>;
};

export type VacationBalanceType = {
  __typename?: 'VacationBalanceType';
  amount: Scalars['Int'];
  position: PositionType;
  position_id: Scalars['Int'];
};

export type VacationScheduleInput = {
  new_plans?: Maybe<Array<VacationScheduleItemInput>>;
  position_id: Scalars['ID'];
  replace_plans?: Maybe<Array<VacationScheduleReplaceInput>>;
};

export type VacationScheduleItemInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type VacationScheduleItemType = {
  __typename?: 'VacationScheduleItemType';
  days: Scalars['Int'];
  end: Scalars['DateTime'];
  original_schedule_date: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type VacationScheduleReplaceInput = {
  new_plans: Array<VacationScheduleItemInput>;
  old_end: Scalars['DateTime'];
  old_start: Scalars['DateTime'];
  original_schedule_date: Scalars['DateTime'];
};

export type VacationScheduleType = {
  __typename?: 'VacationScheduleType';
  assigned_days: Scalars['Int'];
  items: Array<VacationScheduleItemType>;
  position: PositionType;
  position_id: Scalars['ID'];
  unassigned_days: Scalars['Int'];
};

export enum VacationStatusesEnum {
  Approved = 'APPROVED',
  Complete = 'COMPLETE',
  NotReviewed = 'NOT_REVIEWED',
  Registration = 'REGISTRATION',
  Rejected = 'REJECTED',
  SentForApproval = 'SENT_FOR_APPROVAL'
}

export type WorkExperienceInput = {
  duties: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  place: Scalars['String'];
  position: Scalars['String'];
  year_end?: Maybe<Scalars['Int']>;
  year_start: Scalars['Int'];
};

export type WorkExperienceType = {
  __typename?: 'WorkExperienceType';
  duties: Scalars['String'];
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  place: Scalars['String'];
  position: Scalars['String'];
  user_id: Scalars['Int'];
  year_end?: Maybe<Scalars['Int']>;
  year_start: Scalars['Int'];
};
