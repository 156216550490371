import React, { useMemo, useState } from 'react';
import {
  LandingGrid,
  LandingLayoutContent
} from '../../../common/components/Layout/Layout';
import { ReactComponent as IconSphere } from '../../../assets/img/icons/Sphere.svg';
import IconInfoSrc from '../../../assets/img/welcome/icon-tour-info.png';
import { Icon, IconBoxSize } from '../../../common/components/ui/Icon/Icon';
import { chunkArrayByHalf } from '../../../utils/objects';
import { TourModal } from './TourModal';
import { TourItemProps } from './types';
import s from './Tours.module.scss';

function createTourLink(name: string) {
  return name ? `${process.env.PUBLIC_URL}/tours/${name}/tour.html` : '/';
}

const toursTemp: TourItemProps[] = [
  {
    title: 'Бассейн',
    link: createTourLink('pool')
  },
  {
    title: 'Зал единоборств',
    link: createTourLink('sport')
  },
  {
    title: 'Стадион',
    link: createTourLink('stadium')
  },
  {
    title: 'Лаборатория лазерных навигационных систем',
    link: createTourLink('lazer')
  },
  {
    title: 'Музей МФТИ',
    link: createTourLink('muzeum')
  },
  {
    title: 'Лаборатория комбинаторных и геометрических структур',
    link: createTourLink('geolaba')
  },
  {
    title: 'Лабораторный корпус, холл',
    link: createTourLink('hall')
  },
  {
    title: 'Доска почета на этаже ректората',
    link: createTourLink('rector')
  },
  {
    title: 'Комбинат студенческого питания (КСП)',
    link: createTourLink('1')
  },
  {
    title:
      'Центр изучения молекулярных механизмов старения и возрастных заболеваний',
    link: createTourLink('2')
  },
  {
    title:
      'Центр изучения молекулярных механизмов старения и возрастных заболеваний',
    link: createTourLink('3')
  },
  {
    title: 'Коворкинг пространство инновационно-технологического центра мфти',
    link: createTourLink('4')
  },
  {
    title: 'Лаборатория нанобиотехнологий МФТИ',
    link: createTourLink('5')
  },
  {
    title: 'Лаборатория волновых процессов и систем управления',
    link: createTourLink('6')
  },
  {
    title: 'Поточная аудитория. Арктика',
    link: createTourLink('7')
  },
  {
    title: 'Научно-технологический центр автономной энергетики',
    link: createTourLink('8')
  },
  {
    title: 'Лаборатория нанооптики и плазмоники',
    link: createTourLink('9')
  },
  {
    title: 'Лаборатория нанооптики и плазмоники',
    link: createTourLink('10')
  },
  {
    title: 'Лаборатория терагерцовой спектроскопии',
    link: createTourLink('11')
  },
  {
    title: 'Центр языковой подготовки и тестирования',
    link: createTourLink('12')
  }
];

const TourItem: React.FC<TourItemProps> = ({ title, onClick }) => {
  return (
    <div className={s.TourItem} onClick={onClick}>
      <Icon
        className={s.TourItem__icon}
        icon={IconSphere}
        boxSize={IconBoxSize.small}
      />
      <div className={s.TourItem__title}>{title}</div>
      <div className={s.TourItem__link}>Перейти</div>
    </div>
  );
};

export const Tours = () => {
  const [activeTour, setActiveTour] = useState(null);
  const tours = toursTemp;

  const toursChunks = useMemo(() => {
    return chunkArrayByHalf(tours, 5);
  }, [tours]);

  return (
    <LandingLayoutContent className={s.Tours}>
      <div className={s.Tours__title}>
        Виртуальные туры по лабораториям и социальным объектам МФТИ
      </div>
      <div className={s.Tours__description}>
        По ссылкам ниже можно ознакомиться с панорамными фотографиями
        лабораторий и социальных объектов МФТИ. <br />
        Нажатие на кнопку{' '}
        <i className={s.Tours__descriptionIcon}>
          <img src={IconInfoSrc} alt="" />
        </i>{' '}
        на странице тура выведет дополнительную информацию об объекте.
      </div>
      <LandingGrid className={s.Tours__grid}>
        {toursChunks.map((chunk, iChunk) => (
          <div key={iChunk} className={s.Tours__gridCol}>
            {chunk.map((tour, iTour) => (
              <TourItem
                key={iTour}
                {...tour}
                onClick={() => setActiveTour(tour)}
              />
            ))}
          </div>
        ))}
      </LandingGrid>

      <TourModal
        tour={activeTour}
        modalActive={!!activeTour}
        onClose={() => setActiveTour(null)}
      />
    </LandingLayoutContent>
  );
};
