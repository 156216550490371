import React, { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { DateRangePickerValueType } from '../../DatePicker/DateRangePicker';
import {
  DateRangePickerSeparated,
  DateRangePickerSeparatedProps
} from '../../DatePicker/DateRangePickerSeparated';

export interface FormDatePickerProps
  extends Pick<
    DateRangePickerSeparatedProps,
    | 'isYearPicker'
    | 'valueConverter'
    | 'returnConverter'
    | 'classes'
    | 'label'
    | 'startLabel'
    | 'endLabel'
    | 'startPlaceholder'
    | 'endPlaceholder'
  > {
  className?: string;
  startField: string;
  endField: string;
}

export const FormDateRangePicker: React.FC<FormDatePickerProps> = ({
  className,
  startField,
  endField,
  ...props
}) => {
  const [
    { value: startValue },
    { error: errStart, touched: touchedStart },
    { setValue: setStartValue, setTouched: setStartTouched }
  ] = useField(startField);
  const [
    { value: endValue },
    { error: errEnd, touched: touchedEnd },
    { setValue: setEndValue, setTouched: setEndTouched }
  ] = useField(endField);

  const error = errStart || errEnd;
  const touched = touchedStart || touchedEnd;

  const handleChange = useCallback(
    (dates) => {
      setStartValue(dates?.[0]);
      setEndValue(dates?.[1]);
    },
    [setStartValue, setEndValue]
  );

  const handleBlur = useCallback(() => {
    setStartTouched(true);
    setEndTouched(true);
  }, [setStartTouched, setEndTouched]);

  const value = useMemo(() => {
    return [startValue, endValue];
  }, [startValue, endValue]);

  return (
    <DateRangePickerSeparated
      className={className}
      value={value as DateRangePickerValueType}
      errorMessage={touched && error ? error : undefined}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
};
