import React from 'react';
import enrolleeFigureSrc from '../../../assets/img/enrollee/main.png';
import { LayoutContent } from '../../../common/components/Layout/Layout';
import { ButtonVariant } from '../../../common/components/ui/Button';
import { ButtonHash } from '../../../common/components/ui/Button/ButtonHash';
import s from './Main.module.scss';

export const Main = () => {
  return (
    <LayoutContent
      className={s.Adaptation}
      innerClassName={s.Adaptation__inner}
    >
      <div className={s.Adaptation__content}>
        <div className={s.Adaptation__text}>
          Дорогой коллега, <br />
          рады приветствовать <br />
          вас в МФТИ!
        </div>
        <div className={s.Adaptation__smalltext}>
          Физтех стабильно входит в первую пятерку <br /> лучших вузов России
        </div>

        <div className={s.Adaptation__button}>
          <ButtonHash to={'#about'} variant={ButtonVariant.primary}>
            Узнать подробнее
          </ButtonHash>
        </div>
      </div>

      <div className={s.Adaptation__figure}>
        <img src={enrolleeFigureSrc} alt="" />
      </div>
    </LayoutContent>
  );
};
