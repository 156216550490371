import { parse as parseQuery } from 'query-string';
import { socialProvider } from './config';
import breakpoints from './_export.module.scss';

export enum Breakpoints {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop'
}

export const BREAKPOINT_VALUES = {
  [Breakpoints.mobile]: parseFloat(breakpoints.mediaWidthMobile),
  [Breakpoints.tablet]: parseFloat(breakpoints.mediaWidthTablet),
  [Breakpoints.desktop]: parseFloat(breakpoints.mediaWidthDesktop)
};

export const ARROW_LEFT = '\u2190'; // ←
export const ARROW_RIGHT = '\u2192'; // →
export const MAC_OPTION = '\u2325'; // ⌥
export const ENDASH = '–';

export const ONE_PAGE_ITEMS = 24;

export const DefaultPaginationParams = {
  page: 1,
  onePage: ONE_PAGE_ITEMS
};

function getAuthProviderFromUrl() {
  const query = parseQuery(window.location.search);
  if (
    query.auth &&
    typeof query.auth === 'string' &&
    ['google', 'mipt'].includes(query.auth)
  ) {
    return query.auth;
  }
  return null;
}

export const getDefaultAuthProvider = (): string | null =>
  (window as any).authProvider ||
  getAuthProviderFromUrl() ||
  socialProvider ||
  null;
