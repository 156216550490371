import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { PageLayout } from '../../../common/components/Layout/PageLayout';
import {
  LayoutContent,
  LayoutGrid
} from '../../../common/components/Layout/Layout';
import {
  Button,
  ButtonBaseColor,
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button';
import { useArticle } from '../../../graphql/hooks/useArticle';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { useHasPermission } from '../../../common/hooks/useHasPermission';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { NoData } from '../../../common/components/ui/NoData/NoData';
import {
  Breadcrumbs,
  getBreadcrumbItemEntity,
  IBreadcrumbsEntity,
  IBreadcrumbsItem
} from '../../../common/components/ui/Breadcrumbs';
import { getArticlesPageBreadcrumbs } from '../list/ArticlesPage';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { Articles } from '../../../store/articles/Articles';
import { AppHistoryType } from '../../../utils/createAppHistory';
import { useLocationQuery } from '../../../common/hooks/utils/useLocationQuery';
import { URL_KEY_PAGE } from '../../../store/urlKeys';
import { useArticlesPage } from '../../../graphql/hooks/useArticlesPage';
import { Title, TitleVariant } from '../../../common/components/ui/Title/Title';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { ArticleUtils } from '../../../store/articles/ArticleUtils';
import { routeLinks } from '../../routeLinks';
import { UserPermission } from '../../../utils/permissions';
import { ArticlesOthers } from './ArticlesOthers/ArticlesOthers';
import { Article } from './Article/Article';
import s from './ArticlePage.module.scss';

export interface ArticlePageProps
  extends RouteComponentProps<{ articleId: string }> {
  history: AppHistoryType;
}

export function getArticlePageBreadcrumbs({
  article
}: {
  article: IBreadcrumbsEntity;
}): IBreadcrumbsItem[] {
  return [
    ...getArticlesPageBreadcrumbs(),
    getBreadcrumbItemEntity('/articles', article, 50)
  ];
}

export default function ArticlePage({ match, history }: ArticlePageProps) {
  const { articleId } = match.params;
  const { user } = useCurrentUser();
  const canEdit = useHasPermission(user, UserPermission.ArticleCrud);

  // todo запрос похожих статей на основе priority
  const query = useLocationQuery([URL_KEY_PAGE] as const);
  const articlesQuery = useArticlesPage({
    pagination: { page: query[URL_KEY_PAGE] }
  });
  const articles = articlesQuery.state.data?.list;
  const otherArticles = useMemo(() => {
    return (articles || []).filter((item) => item.id !== articleId).slice(0, 3);
  }, [articles, articleId]);

  const articleQuery = useArticle(articleId);
  const articleData = articleQuery.state.data;
  const article = useMemo(
    () => articleData && ArticleUtils.toForm(articleData),
    [articleData]
  );

  const deleteArticle = useGraphqlMutation(Articles.deleteArticle);
  const deleteArticleRun = deleteArticle.run;

  const handleRemoveClick = useCallback(() => {
    if (window.confirm('Вы действительно хотите удалить новость?')) {
      deleteArticleRun({ variables: { id: articleId } })
        .then((result) => {
          if (result.data?.result) {
            toast.success('Новость удалена');
            history.goBack(routeLinks.articles.to);
          }
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(err, 'Произошла ошибка при удалении новости');
        });
    }
  }, [articleId, deleteArticleRun, history]);

  const breadcrumbs = useMemo(
    () =>
      getArticlePageBreadcrumbs({
        article: {
          id: articleId,
          name: article?.title
        }
      }),
    [articleId, article]
  );

  const articleExists = articleQuery.state.loading || article;
  const otherArticlesExist =
    articlesQuery.state.loading || otherArticles.length > 0;

  return (
    <PageLayout
      breadcrumbs={<Breadcrumbs items={breadcrumbs} />}
      contentClassName={s.ArticlePage}
    >
      <LayoutContent>
        <LayoutGrid>
          <LayoutGrid.GridItem cols={8}>
            {queryLoader(articleQuery) ||
              (!article ? <NoData /> : <Article {...article} />)}
          </LayoutGrid.GridItem>

          {articleExists && (
            <LayoutGrid.GridItem cols={4}>
              {canEdit && (
                <div className={s.ArticlePage__actions}>
                  <ButtonLink
                    to={`/articles/${articleId}/edit`}
                    className={clsx(s.ActionButton, s.ActionButton_edit)}
                    size={ButtonSize.small}
                    variant={ButtonVariant.secondary}
                  >
                    Редактировать
                  </ButtonLink>
                  <Button
                    className={clsx(s.ActionButton, s.ActionButton_remove)}
                    size={ButtonSize.small}
                    variant={ButtonVariant.secondary}
                    baseColor={ButtonBaseColor.red}
                    onClick={handleRemoveClick}
                    loading={deleteArticle.loading}
                  >
                    Удалить
                  </Button>
                </div>
              )}
              {!canEdit && otherArticlesExist && (
                <div className={s.ArticlePage__otherArticles}>
                  <Title
                    className={s.ArticlePage__otherArticles__title}
                    title={'Другие новости'}
                    variant={TitleVariant.h3}
                  />
                  <ArticlesOthers data={otherArticles} />
                </div>
              )}
            </LayoutGrid.GridItem>
          )}
        </LayoutGrid>
      </LayoutContent>
    </PageLayout>
  );
}
