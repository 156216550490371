import { declineUnit, NBSP, Unit } from '@proscom/ui-utils';
import { isNil } from 'lodash-es';

export function prettyNumber(number: number, unit?: Unit) {
  let result = Intl.NumberFormat('ru-RU').format(number);
  if (!unit) {
    return result;
  }
  result += NBSP + declineUnit(number, unit);
  return result;
}

export const validateNumberRegexp = new RegExp(
  /^([-+]?)([0-9]*)((\.[0-9]*)?)$/
);

export function isNumberLike(n: any) {
  return !isNaN(parseFloat(n)) && !!n.toString().match(validateNumberRegexp);
}

export function tryNumberLike(
  value: any,
  defaultValue?: any,
  convertToNumber = false
) {
  if (isNumberLike(value)) {
    return convertToNumber ? +value : value;
  }
  return defaultValue;
}

export function toFixed(value: any, digits?: number, defaultValue?: any) {
  if (!isNumberLike(value)) return defaultValue ?? null;
  return !isNil(digits) ? (+value).toFixed(digits) : value;
}
