import React from 'react';
import clsx from 'clsx';
import { Omit } from 'react-router';
import { Icon, IconProps } from '../Icon/Icon';
import s from './HelpText.module.scss';

export enum HelpTextType {
  error = 'error',
  hint = 'hint'
}

export interface HelpTextProps {
  className?: string;
  type: HelpTextType;
  text?: string;
  icon?: IconProps['icon'];
  iconProps?: Omit<IconProps, 'icon' | 'onClick'>;
}

export const HelpText: React.FC<HelpTextProps> = ({
  className,
  type,
  text,
  icon,
  iconProps,
  children
}) => {
  if (text || children) {
    return (
      <div className={clsx(s.HelpText, s[`HelpText_${type}`], className)}>
        {icon && (
          <Icon
            className={s.HelpText__icon}
            icon={icon}
            boxSize={24}
            iconSize={12}
            {...iconProps}
          />
        )}
        <div className={s.HelpText__content}>
          {text}
          {children}
        </div>
      </div>
    );
  }
  return null;
};
