import React from 'react';
import { Link } from 'react-router-dom';
import { GenericComponent } from '../../utils/GenericComponent';
import { HtmlAnchor, HtmlButton } from '../../html';
import { BaseButton } from './BaseButton';
import { BaseButtonBaseProps, BaseButtonProps } from './types';

const ButtonGeneric = GenericComponent<BaseButtonBaseProps, BaseButtonProps>(
  BaseButton
);

export const Button = ButtonGeneric(HtmlButton);
export type ButtonProps = React.ComponentProps<typeof Button>;

export const ButtonAnchor = ButtonGeneric(HtmlAnchor);
export type ButtonAnchorProps = React.ComponentProps<typeof ButtonAnchor>;

export const ButtonLink = ButtonGeneric(Link);
export type ButtonLinkProps = React.ComponentProps<typeof ButtonLink>;
