import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { routeLinks } from '../../../routeLinks';
import { CardInternship } from '../../../../common/components/ui/Cards/CardInternship/CardInternship';
import {
  URL_KEY_PAGE,
  URL_KEY_SUBJECT_IDS,
  URL_KEY_TAG_IDS
} from '../../../../store/urlKeys';
import { useLocationQuery } from '../../../../common/hooks/utils/useLocationQuery';
import { useGraphqlMutation } from '../../../../common/hooks/utils/useGraphqlMutation';
import { Internships } from '../../../../store/internships/Internships';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import { QueryPagination } from '../../../../common/components/ui/QueryPagination/QueryPagination';
import { InternshipType } from '../../../../graphql/types';
import { NoData } from '../../../../common/components/ui/NoData/NoData';
import s from './InternshipsList.module.scss';

export interface InternshipsListProps {
  header?: any; // todo type me
  internships?: InternshipType[];
  isEditable?: boolean;
}

export const InternshipsList: React.FC<InternshipsListProps> = ({
  header,
  internships,
  isEditable
}) => {
  const [query, locationState] = useLocationQuery([URL_KEY_PAGE] as const);

  const deleteInternship = useGraphqlMutation(Internships.deleteInternship);
  const deleteInternshipRun = deleteInternship.run;

  const hasInternships = internships && internships.length > 0;

  const handleTagSelect = useCallback(
    (id) => {
      locationState.changeQuery({
        [URL_KEY_TAG_IDS]: [id],
        [URL_KEY_PAGE]: 1
      });
    },
    [locationState]
  );

  const handleSubjectSelect = useCallback(
    (id) => {
      locationState.changeQuery({
        [URL_KEY_SUBJECT_IDS]: [id],
        [URL_KEY_PAGE]: 1
      });
    },
    [locationState]
  );

  const handleDeleteClick = useCallback(
    (id) => {
      if (window.confirm('Вы действительно хотите удалить стажировку?')) {
        deleteInternshipRun({ variables: { id } })
          .then((result) => {
            if (!result.data?.result) {
              throw new Error('UnexpectedResult');
            }

            toast.success('Стажировка удалена');
          })
          .catch((err) => {
            if (err instanceof AsyncSingletonError) return;
            handleDefaultError(err, 'Произошла ошибка при удалении стажировки');
          });
      }
    },
    [deleteInternshipRun]
  );

  return (
    <div className={s.InternshipsList}>
      {(!hasInternships && <NoData />) ||
        internships?.map((item) => (
          <div key={item.id} className={s.InternshipsList__item}>
            <CardInternship
              {...item}
              to={`${routeLinks.internships.to}/${item.id}`}
              onTagClick={handleTagSelect}
              onSubjectClick={handleSubjectSelect}
              onDeleteClick={handleDeleteClick}
              isEditable={isEditable}
            />
          </div>
        ))}

      {hasInternships && header && (
        <QueryPagination header={header} pathname={routeLinks.internships.to} />
      )}
    </div>
  );
};
