import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Card, CardProps, CardStyle } from '../../Card/Card';
import { Title, TitleVariant } from '../../Title/Title';
import { BaseLink } from '../../BaseLink/BaseLink';
import { FileTypeEnum } from '../../../../../store/FileTypeEnum';
import { useFileSrc } from '../../../../hooks/useFileSrc';
import { ImagePreview } from '../../ImagePreview/ImagePreview';
import { HTMLTextContainer } from '../../HTMLTextContainer/HTMLTextContainer';
import s from './CardMainWide.module.scss';

export interface CardMainWideData {
  title?: string;
  imageType?: FileTypeEnum;
  link?: string;
  isExternal?: boolean;
  coverFileId?: string;
  snippet?: string;
  additionalContent?: ReactNode;
}

export interface CardMainWideProps
  extends Omit<CardProps, 'title'>,
    CardMainWideData {}

export const CardMainWide: React.FC<CardMainWideProps> = ({
  className,
  coverFileId,
  imageType,
  title,
  link,
  snippet,
  additionalContent,
  isExternal,
  children,
  ...props
}) => {
  const linkProps = isExternal ? { href: link } : { to: link };
  const imageSrc = useFileSrc(coverFileId, imageType);

  return (
    <Card
      cardStyle={CardStyle.bordered}
      className={clsx(s.CardMainWide, className)}
      classes={{
        inner: s.CardMainWide__inner
      }}
      {...props}
    >
      {children || (
        <>
          <div>
            <BaseLink
              className={s.CardMainWide__link}
              {...linkProps}
              tabIndex={-1}
            >
              <ImagePreview
                className={s.CardMainWide__cover}
                classes={{
                  image: s.CardMainWide__coverImage
                }}
                imageSrc={imageSrc}
              />
              <Title
                title={title}
                variant={TitleVariant.h4}
                className={s.CardMainWide__title}
              />

              <HTMLTextContainer
                className={s.CardMainWide__snippet}
                htmlString={snippet}
              />
            </BaseLink>
          </div>

          {additionalContent && <div>{additionalContent}</div>}
        </>
      )}
    </Card>
  );
};
