import React from 'react';
import successImageSrc from '../../../../../assets/img/success.png';
import { ButtonLink } from '../../../../../common/components/ui/Button';
import s from './PollAttemptSuccess.module.scss';

export const PollAttemptSuccess = () => {
  return (
    <div className={s.PollAttemptSuccess}>
      <img
        src={successImageSrc}
        alt=""
        className={s.PollAttemptSuccess__image}
      />
      <div className={s.PollAttemptSuccess__text}>Опрос успешно пройден</div>
      <ButtonLink to={'/polls'}>Вернуться к опросам</ButtonLink>
    </div>
  );
};
