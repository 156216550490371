import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { DivPropsWithoutRef } from '../../../../utils/reactTypes';
import {
  Button,
  ButtonAnchor,
  ButtonBaseColor,
  ButtonLink,
  ButtonSize,
  ButtonVariant
} from '../../../../common/components/ui/Button';
import { Tag } from '../../../../common/components/ui/Tag/Tag';
import {
  Title,
  TitleVariant
} from '../../../../common/components/ui/Title/Title';
import { Card } from '../../../../common/components/ui/Card/Card';
import { useCourse } from '../../../../graphql/hooks/courses/useCourse';
import { queryLoader } from '../../../../common/components/utils/queryLoader';
import { useFileSrc } from '../../../../common/hooks/useFileSrc';
import { FileTypeEnum } from '../../../../store/FileTypeEnum';
import { ContentBlock } from '../../../../common/components/ui/ContentBlock/ContentBlock';
import { CourseUtils } from '../../../../store/courses/CourseUtils';
import { ContentBlockVariant } from '../../../../common/components/ui/Form/FormContentEditor/Content';
import { PartnerLogo } from '../../../../common/components/ui/PartnerLogo/PartnerLogo';
import {
  CourseCommonFields,
  CourseCommonFieldsProps
} from '../CourseCommonFields';
import {
  CourseStatus,
  CourseType,
  CourseTypesEnum
} from '../../../../graphql/types';
import { useUpdateCourseStatus } from '../../useUpdateCourseStatus';
import { routeLinks } from '../../../routeLinks';
import { FileLoaderList } from '../../../../common/components/ui/FileLoader/FileLoaderList';
import s from './CourseAnnounce.module.scss';

export interface CourseAnnounceProps
  extends DivPropsWithoutRef,
    CourseCommonFieldsProps {
  showActions?: boolean;
  isEmbedded?: boolean;
  id: string;
  title?: string;
  description?: string;
  courseSubject?: string;
  partnerLogoFileId?: string;
  coverFileId?: string | null;
  acquiredKnowledge?: string;
  courseLink?: string;
  tags: string[];
  teachers: { name?: string; position?: string; specialization?: string }[];
  status: CourseStatus;
  courseData: CourseType;
  document_files: CourseType['document_files'];
}

export function CourseAnnounceCard({
  className,
  children,
  showActions,
  isEmbedded,
  id,
  title,
  description,
  courseSubject,
  partnerLogoFileId,
  coverFileId,
  acquiredKnowledge,
  tags,
  courseLink,
  teachers,
  status,
  document_files,
  courseData,
  ...props
}: CourseAnnounceProps) {
  const partnerLogoSrc = useFileSrc(
    partnerLogoFileId,
    FileTypeEnum.course_image
  );
  const cover = useFileSrc(coverFileId, FileTypeEnum.course_image);
  const statusArchived = status !== CourseStatus.Published;

  const { updateWithValidation, updateStatusLoading } = useUpdateCourseStatus(
    id,
    CourseTypesEnum.Announce
  );

  const handleArchiveClick = useCallback(async () => {
    updateWithValidation(
      courseData,
      statusArchived ? CourseStatus.Published : CourseStatus.Archived
    );
  }, [statusArchived, courseData, updateWithValidation]);

  return (
    <div className={clsx(className, s.CourseAnnounce)} {...props}>
      <div className={s.CourseAnnounce__titleBlock}>
        <div className={s.CourseAnnounce__coverImageContainer}>
          <img
            className={s.CourseAnnounce__coverImage}
            src={cover || ''}
            alt={title}
          />
        </div>
        <div className={s.CourseAnnounce__titleBlockRight}>
          <div className={s.CourseAnnounce__topbar}>
            {partnerLogoSrc && (
              <PartnerLogo
                className={s.CourseAnnounce__partnerLogo}
                title={title || ''}
                imageSrc={partnerLogoSrc}
              />
            )}
            {Boolean(courseSubject) && (
              <Tag className={s.CourseAnnounce__subject} isSelected>
                {courseSubject}
              </Tag>
            )}
          </div>
          <Title variant={TitleVariant.h3} className={s.CourseAnnounce__title}>
            {title}
          </Title>
          <div className={s.CourseAnnounce__description}>{description}</div>
        </div>
      </div>
      {!tags?.length ? null : (
        <div className={s.CourseAnnounce__section}>
          <div className={s.CourseAnnounce__skills}>
            {tags.map((tag) => (
              <Tag key={tag} className={s.CourseAnnounce__skill}>
                {tag}
              </Tag>
            ))}
          </div>
        </div>
      )}
      <div className={s.CourseAnnounce__section}>
        <Title
          variant={TitleVariant.h4}
          className={s.CourseAnnounce__sectionTitle}
        >
          Информация о курсе
        </Title>
        <CourseCommonFields
          price={props.price}
          has_discount={props.has_discount}
          learning_format={props.learning_format}
          completion_proof={props.completion_proof}
          listener_category={props.listener_category}
          showTitle={false}
        />
      </div>
      <div className={s.CourseAnnounce__section}>
        <Title
          variant={TitleVariant.h4}
          className={s.CourseAnnounce__sectionTitle}
        >
          Чему вы научитесь
        </Title>
        <div className={s.CourseAnnounce__whatYouLearn}>
          <ContentBlock
            meta={{ fileType: FileTypeEnum.course_image }}
            block={{
              variant: ContentBlockVariant.text,
              data: { content: acquiredKnowledge }
            }}
          />
        </div>
      </div>
      {!teachers?.length ? null : (
        <div className={s.CourseAnnounce__section}>
          <Title
            variant={TitleVariant.h4}
            className={s.CourseAnnounce__sectionTitle}
          >
            Преподаватели
          </Title>
          <div className={s.CourseAnnounce__teachers}>
            {teachers.map((teacher) => (
              <Card
                key={teacher.name}
                className={s.CourseAnnounce__teacherCard}
              >
                <div className={s.CourseAnnounce__teacherCardTitle}>
                  {teacher.name}
                  {teacher.specialization
                    ? `, ${teacher.specialization}`
                    : null}
                </div>
                <div className={s.CourseAnnounce__teacherCardPosition}>
                  {teacher.position}
                </div>
              </Card>
            ))}
          </div>
        </div>
      )}

      {document_files && document_files.length > 0 && (
        <div className={s.CourseAnnounce__section}>
          <Title
            variant={TitleVariant.h4}
            className={s.CourseAnnounce__sectionTitle}
          >
            Файлы
          </Title>

          <FileLoaderList
            fileType={FileTypeEnum.course_document}
            files={document_files}
          />
        </div>
      )}

      <div className={s.CourseAnnounce__actions}>
        {showActions && (
          <>
            <Button
              className={s.CourseAnnounce__actionButton}
              size={ButtonSize.medium}
              variant={ButtonVariant.secondary}
              baseColor={
                statusArchived ? ButtonBaseColor.green : ButtonBaseColor.gray
              }
              onClick={handleArchiveClick}
              loading={updateStatusLoading}
            >
              {statusArchived ? 'Опубликовать' : 'Архивировать'}
            </Button>
            <ButtonLink
              className={s.CourseAnnounce__actionButton}
              to={`${routeLinks.courses.to}/${id}/edit`}
              size={ButtonSize.medium}
              variant={ButtonVariant.secondary}
              loading={updateStatusLoading}
            >
              {'Редактировать'}
            </ButtonLink>
          </>
        )}
        {courseLink && (
          <ButtonAnchor
            className={clsx(
              s.CourseAnnounce__actionButton,
              isEmbedded && s.CourseAnnounce__actionButton_embedded
            )}
            size={ButtonSize.medium}
            href={courseLink}
            target="_blank"
          >
            Перейти на сайт курса
          </ButtonAnchor>
        )}
      </div>
    </div>
  );
}

export function CourseAnnounce({
  id,
  isEmbedded = false,
  showActions = false
}) {
  const courseQuery = useCourse(id);
  const courseData = courseQuery.state.data;

  const announce = useMemo(() => {
    return CourseUtils.toAnnounce(courseData);
  }, [courseData]);

  return (
    queryLoader(courseQuery) ||
    (courseData && announce && (
      <CourseAnnounceCard
        id={id}
        isEmbedded={isEmbedded}
        showActions={showActions}
        courseData={courseData}
        {...announce}
      />
    ))
  );
}
