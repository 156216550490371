import React from 'react';
import { TextLink } from '../../TextLink/TextLink';
import s from './CardPollFooter.module.scss';

export interface CardPollFooterProps {
  passcode?: string;
  href?: string;
  to?: string;
}

export const CardPollFooter: React.FC<CardPollFooterProps> = ({
  passcode,
  href,
  to
}) => {
  return (
    <div className={s.CardPoll__footer}>
      {passcode && (
        <div className={s.CardPoll__pass}>
          <>
            Пароль: <span className={s.CardPoll__passcode}>{passcode}</span>
          </>
        </div>
      )}
      {(href || to) && (
        <TextLink
          className={s.CardPoll__link}
          href={href || ''}
          to={to || ''}
          noUnderline
          showArrow
        >
          Перейти
        </TextLink>
      )}
    </div>
  );
};
