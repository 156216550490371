import { IQueryTransformer } from '@proscom/prostore-react-router';

export const numberTransformer: IQueryTransformer<number> = {
  parse(value) {
    return (typeof value === 'string' && +value) || 0;
  },
  stringify(value: number) {
    return value ? String(value) : undefined;
  }
};
