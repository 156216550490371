import React from 'react';
import noFilesImageAdmin from '../../../../../assets/img/noFilesAdmin.png';
import noFilesImageUser from '../../../../../assets/img/noFilesUser.png';
import { ReactComponent as AddIcon } from '../../../../../assets/img/icons/plus.svg';
import { Button } from '../../Button/Button';
import { ButtonSize, ButtonVariant } from '../../Button/types';
import s from './EmptySection.module.scss';

export interface EmptySectionProps {
  onAddDocument: (e: React.MouseEvent<HTMLButtonElement>) => void;
  editable?: boolean;
}

export const EmptySection: React.FC<EmptySectionProps> = ({
  editable,
  onAddDocument
}) => {
  return (
    <div className={s.EmptySection}>
      {editable ? (
        <>
          <img src={noFilesImageAdmin} alt="no files" />
          <div className={s.EmptySection__text}>
            В этот раздел еще не добавили документы
          </div>
          <Button
            variant={ButtonVariant.secondary}
            size={ButtonSize.small}
            iconLeft={<AddIcon />}
            classes={{
              root: s.EmptySection__addButton
            }}
            onClick={onAddDocument}
          >
            Добавить документ
          </Button>
        </>
      ) : (
        <>
          <img src={noFilesImageUser} alt="no files" />
          <div className={s.EmptySection__text}>Скоро добавим документы</div>
        </>
      )}
    </div>
  );
};
