import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DATE_FORMAT_RUSSIAN } from '@proscom/ui-utils-date';
import clsx from 'clsx';
import ReactDatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { EMDASH } from '@proscom/ui-utils';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import { convertToUtcStr } from '../../../../utils/date/date';
import { convertPickerValues, DateRangePickerProps } from './DateRangePicker';
import s from './DatePicker.module.scss';

export interface DateRangePickerSeparatedProps
  extends Omit<DateRangePickerProps, 'inline'> {
  startLabel?: string;
  endLabel?: string;
  startPlaceholder?: string;
  endPlaceholder?: string;
}

export const DateRangePickerSeparated: React.FC<DateRangePickerSeparatedProps> = ({
  className,
  classes,
  label,
  startLabel,
  endLabel,
  value,
  isYearPicker,
  valueConverter,
  returnConverter,
  dayClassName,
  minDate,
  maxDate,
  hintMessage,
  errorMessage,
  startPlaceholder,
  endPlaceholder,
  onChange,
  onBlur
}) => {
  const convertedValues = useMemo(() => {
    return convertPickerValues(value, valueConverter);
  }, [value, valueConverter]);

  const [startValue, setStartValue] = useState(convertedValues?.[0]);
  const [endValue, setEndValue] = useState(convertedValues?.[1]);

  useEffect(() => {
    setStartValue(convertedValues?.[0]);
    setEndValue(convertedValues?.[1]);
  }, [convertedValues]);

  const handleValuesChange = useCallback(
    (values: [Date, Date] | null) => {
      const vals = returnConverter ? returnConverter(values) : values;
      const valStrings = vals?.map(convertToUtcStr);

      onChange?.(
        valStrings?.some((v) => !!v)
          ? (valStrings as [string, string])
          : undefined
      );
    },
    [onChange, returnConverter]
  );

  const handleStartChange = useCallback(
    (value: Date | null) => {
      const values = [value, endValue] as [Date, Date];
      setStartValue(value ?? undefined);
      handleValuesChange(values);
    },
    [handleValuesChange, endValue]
  );

  const handleEndChange = useCallback(
    (value: Date | null) => {
      const values = [startValue, value] as [Date, Date];
      setEndValue(value ?? undefined);
      handleValuesChange(values);
    },
    [handleValuesChange, startValue]
  );

  const config = {
    className: clsx(s.DatePicker__picker, classes?.input),
    wrapperClassName: clsx(s.DatePicker__pickerWrapper, classes?.inputWrapper),
    dayClassName,
    minDate,
    maxDate,
    dateFormat: isYearPicker ? 'yyyy' : DATE_FORMAT_RUSSIAN,
    locale: ru,
    shouldCloseOnSelect: true,
    showYearPicker: isYearPicker,
    onBlur
  };

  return (
    <div className={clsx(s.DatePicker, className, classes?.root)}>
      {label && (
        <div className={clsx(s.DatePicker__label, classes?.label)}>{label}</div>
      )}
      <div className={s.DatePicker__grid}>
        <label>
          {startLabel && (
            <div className={clsx(s.DatePicker__label, classes?.label)}>
              {startLabel}
            </div>
          )}
          <ReactDatePicker
            selected={startValue}
            startDate={startValue}
            endDate={endValue}
            onChange={handleStartChange}
            selectsStart
            placeholderText={startPlaceholder}
            {...config}
          />
        </label>
        {EMDASH}
        <label>
          {endLabel && (
            <div className={clsx(s.DatePicker__label, classes?.label)}>
              {endLabel}
            </div>
          )}
          <ReactDatePicker
            selected={endValue}
            startDate={startValue}
            endDate={endValue}
            onChange={handleEndChange}
            selectsEnd
            placeholderText={endPlaceholder}
            {...config}
          />
        </label>
      </div>
      <HelpText
        className={classes?.helperText}
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </div>
  );
};
