import React, { useEffect, useMemo } from 'react';
import { FormikProps } from 'formik';
import { EMDASH, joinNonEmpty } from '@proscom/ui-utils';
import { isNil } from 'lodash-es';
import { FormSection } from '../../../../common/components/ui/Form/FormSection/FormSection';
import { FormSelect } from '../../../../common/components/ui/Form/FormSelect/FormSelect';
import { TextFieldPreview } from '../../../../common/components/ui/TextFieldPreview/TextFieldPreview';
import { FormDatePicker } from '../../../../common/components/ui/Form/FormDatePicker/FormDatePicker';
import { VacationAppProps } from '../../../../store/vacations/VacationsUtils';
import { UserUtils } from '../../../../store/users/UserUtils';
import { SelectSize } from '../../../../common/components/ui/Select/Select';
import { PositionType } from '../../../../graphql/types';
import { convertDataListToSelectOptions } from '../../../../utils/form/formConverters';
import {
  HelpText,
  HelpTextType
} from '../../../../common/components/ui/HelpText/HelpText';
import { DatePickerProps } from '../../../../common/components/ui/DatePicker/DatePicker';
import { Entity } from '../../../../common/components/ui/AsyncSelect/entities/types';
import { FormAsyncEntitySelect } from '../../../../common/components/ui/Form/FormAsyncEntitySelect/FormAsyncEntitySelect';
import { useUser } from '../../../../graphql/hooks/useUser';
import s from './VacationAppForm.module.scss';

export interface VacationAppFormMainProps
  extends FormikProps<VacationAppProps> {
  minStartDate: DatePickerProps['minDate'];
  maxStartDate: DatePickerProps['maxDate'];
  minEndDate: DatePickerProps['minDate'];
  maxEndDate: DatePickerProps['maxDate'];
  positions: PositionType[];
  selectedPositionId: number | null;
  daysAvailable: number | null;
  selectedDaysAmount: number | null;
  isVacationsDisabled: boolean;
}

export const VacationAppFormMain: React.FC<VacationAppFormMainProps> = ({
  minStartDate,
  maxStartDate,
  minEndDate,
  maxEndDate,
  positions,
  selectedPositionId,
  daysAvailable,
  selectedDaysAmount,
  isVacationsDisabled,
  values,
  errors,
  setFieldValue
}) => {
  const deputyId = values.deputy_id;
  const isHeadPosition = values.isHeadPosition;
  const startDate = values.start;

  const deputyQuery = useUser(deputyId || '');
  const deputy = deputyQuery.state.data;
  const deputyLoading = deputyQuery.check.spinner;

  const deputyPositionsSelectOptions = useMemo(() => {
    if (!deputy?.positions?.length) return [];

    return convertDataListToSelectOptions(
      deputy.positions.map((pos) => {
        const posUnit = UserUtils.getPositionUnit(pos);
        return {
          id: pos.id,
          // name: joinNonEmpty([posUnit, `${pos.c_position_name} (${pos.c_combine_position_work})`], ' - ')
          name: `${pos.c_position_name} (${joinNonEmpty(
            [posUnit, pos.c_combine_position_work],
            ', '
          )})`
        };
      }),
      {
        valueKey: 'id',
        labelKey: 'name'
      }
    );
  }, [deputy]);

  const positionsSelectOptions = useMemo(() => {
    return convertDataListToSelectOptions(
      (positions || []).map((pos) => ({
        id: pos.id,
        name: `${pos.c_position_name} (${pos.c_combine_position_work})`
      })),
      {
        valueKey: 'id',
        labelKey: 'name'
      }
    );
  }, [positions]);

  const selectedPositionUnit = useMemo(() => {
    if (isNil(selectedPositionId)) return null;
    const selectedPosition = positions.find((p) => p.id === selectedPositionId);
    return UserUtils.getPositionUnit(selectedPosition);
  }, [positions, selectedPositionId]);

  const selectedDaysAmountError = errors.selectedDaysAmount;

  useEffect(() => {
    if (deputyPositionsSelectOptions.length === 1) {
      setFieldValue(
        'deputy_position_id',
        deputyPositionsSelectOptions[0].value
      );
    }
  }, [deputyPositionsSelectOptions, setFieldValue]);

  useEffect(() => {
    setFieldValue('end', undefined);
  }, [startDate, setFieldValue]);

  return (
    <>
      <FormSection className={s.VacationAppFormMain__positionSection}>
        <FormSelect
          name={'position_id'}
          label={'Должность'}
          placeholder={'Выберите должность'}
          options={positionsSelectOptions}
          size={SelectSize.large}
        />
      </FormSection>

      <FormSection>
        <TextFieldPreview
          className={s.VacationAppFormMain__textFieldPreview}
          classes={{
            title: s.VacationAppFormMain__textFieldPreviewTitle,
            content: s.VacationAppFormMain__textFieldPreviewContent
          }}
          title={'Подразделение:'}
        >
          {selectedPositionUnit || EMDASH}
        </TextFieldPreview>
      </FormSection>

      <FormSection>
        <FormDatePicker
          name={`start`}
          label={'Дата начала отпуска'}
          placeholder={!selectedPositionId ? 'Сначала выберите должность' : ''}
          minDate={minStartDate}
          maxDate={maxStartDate}
          disabled={!daysAvailable}
        />
      </FormSection>

      <FormSection className={s.VacationAppFormMain__endDateSection}>
        <FormDatePicker
          name={`end`}
          label={'Дата окончания отпуска'}
          placeholder={!selectedPositionId ? 'Сначала выберите должность' : ''}
          minDate={minEndDate}
          maxDate={maxEndDate}
          disabled={!daysAvailable || isVacationsDisabled}
        />
      </FormSection>

      <FormSection>
        <TextFieldPreview
          className={s.VacationAppFormMain__textFieldPreview}
          classes={{
            title: s.VacationAppFormMain__textFieldPreviewTitle,
            content: s.VacationAppFormMain__textFieldPreviewContent
          }}
          title={'Количество календарных дней отпуска:'}
        >
          {selectedDaysAmount ?? EMDASH}
        </TextFieldPreview>
        <HelpText
          className={s.VacationAppFormMain__errorText}
          type={HelpTextType.error}
        >
          {selectedDaysAmountError && (
            <>
              Ваше заявление не может быть отправлено.
              <br />
              {selectedDaysAmountError}
            </>
          )}
        </HelpText>
      </FormSection>

      {isHeadPosition && (
        <>
          <FormSection>
            <FormAsyncEntitySelect
              name={'deputy_id'}
              label={
                'Сотрудник, который будет исполнять обязанности руководителя на период отпуска'
              }
              placeholder={'Начните вводить ФИО сотрудника'}
              size={SelectSize.large}
              entity={Entity.usersPage}
              disabled={isVacationsDisabled}
              isCreatable={false}
            />
          </FormSection>

          <FormSection>
            <FormSelect
              name={'deputy_position_id'}
              label={'Должность заместителя'}
              placeholder={'Выберите должность заместителя'}
              options={deputyPositionsSelectOptions}
              size={SelectSize.large}
              isLoading={deputyLoading}
              isDisabled={
                deputyPositionsSelectOptions.length <= 1 || isVacationsDisabled
              }
            />
          </FormSection>
        </>
      )}
    </>
  );
};
