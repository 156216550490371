import React from 'react';
import clsx from 'clsx';
import { Avatar, AvatarProps, AvatarSize } from '../Avatar/Avatar';
import s from './Person.module.scss';

export interface PersonClasses {
  root?: string;
  avatar?: string;
  content?: string;
  name?: string;
  description?: string;
}

export interface PersonProps extends Pick<AvatarProps, 'size'> {
  className?: string;
  classes?: PersonClasses;
  name?: string;
  description?: string;
  avatarSrc?: string;
  children?: React.ReactNode;
}

export const Person: React.FC<PersonProps> = ({
  className,
  classes,
  name,
  description,
  avatarSrc,
  size = AvatarSize.medium,
  children
}) => {
  return (
    <div className={clsx(s.Person, className, classes?.root)}>
      <Avatar
        className={clsx(s.Person__ava, classes?.avatar)}
        src={avatarSrc}
        size={size}
      />
      <div className={clsx(s.Person__content, classes?.content)}>
        <div className={clsx(s.Person__name, classes?.name)}>{name}</div>
        {description && (
          <div className={clsx(s.Person__description, classes?.description)}>
            {description}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
