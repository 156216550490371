import { ProjectStatusEnum } from '../../graphql/types';
import { StatusIndicatorActivity } from '../../common/components/ui/StatusIndicator/StatusIndicator';
import { MailtoOptionsProps } from '../../utils/url/createMailtoString';

export function mapProjectStatus(status?: ProjectStatusEnum) {
  let obj = {
    text: '',
    status: StatusIndicatorActivity.default
  };

  switch (status) {
    case ProjectStatusEnum.Active:
      obj.text = 'Активен';
      obj.status = StatusIndicatorActivity.active;
      break;
    case ProjectStatusEnum.Completed:
      obj.text = 'Завершен';
      obj.status = StatusIndicatorActivity.inactive;
      break;
  }

  return obj;
}

export function getParticipateMailObject(
  projectName: string,
  userName: string
): MailtoOptionsProps {
  return {
    subject: `Запрос на участие в проекте "${projectName}"`,
    body: `Пожалуйста, добавьте меня (${userName}) как участника проекта "${projectName}" на платформе team.mipt.ru`
  };
}
