import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { useGraphqlMutation } from '../../../../../common/hooks/utils/useGraphqlMutation';
import { ExternalPolls } from '../../../../../store/polls/ExternalPolls';
import { routeLinks } from '../../../../routeLinks';
import { handleDefaultError } from '../../../../../utils/handleDefaultError';
import {
  ContextMenuButton,
  ContextMenuButtonPosition
} from '../../../../../common/components/ui/ContextMenu/ContextMenuButton';

export interface ExternalPollActionsProps {
  id: string;
}

export const ExternalPollActions: React.FC<ExternalPollActionsProps> = ({
  id
}) => {
  const deletePoll = useGraphqlMutation(ExternalPolls.DeleteExternalPoll);
  const deletePollRun = deletePoll.run;

  const handleDeletePoll = useCallback(() => {
    if (window.confirm('Вы действительно хотите удалить опрос?')) {
      deletePollRun({ variables: { id } })
        .then((result) => {
          if (!result.data?.result) {
            throw new Error('UnexpectedResult');
          }
          toast.success('Опрос удален');
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;
          handleDefaultError(err, 'Произошла ошибка при удалении опрса');
        });
    }
  }, [deletePollRun, id]);

  const actions = useMemo(() => {
    return [
      {
        label: 'Редактировать',
        to: `${routeLinks.polls.to}/external/${id}/edit`
      },
      {
        label: 'Удалить',
        onClick: handleDeletePoll
      }
    ];
  }, [id, handleDeletePoll]);

  return (
    <ContextMenuButton
      items={actions}
      position={ContextMenuButtonPosition.left}
    />
  );
};
