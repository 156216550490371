import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { addDays, startOfToday } from 'date-fns';
import {
  DateRangePicker,
  DateRangePickerProps
} from '../../../../common/components/ui/DatePicker/DateRangePicker';
import {
  Title,
  TitleVariant
} from '../../../../common/components/ui/Title/Title';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../../common/components/ui/Button';
import { ReactComponent as CrossIcon } from '../../../../assets/img/icons/cross.svg';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import s from './Calendar.module.scss';

export interface CalendarClasses {
  root?: string;
  title?: string;
  picker?: string;
}

export interface CalendarProps
  extends Pick<
    DateRangePickerProps,
    | 'dayClassName'
    | 'value'
    | 'minDate'
    | 'maxDate'
    | 'excludeDates'
    | 'disabled'
    | 'onChange'
    | 'onMonthChange'
  > {
  className?: string;
  classes?: CalendarClasses;
  title?: React.ReactNode;
  showEvents?: boolean;
  onReset?: () => void;
}

export const Calendar: React.FC<CalendarProps> = ({
  className,
  classes,
  title,
  value,
  minDate,
  maxDate,
  disabled,
  showEvents,
  onReset,
  ...props
}) => {
  const { isMobile } = useWindowSize();

  /**
   * Костыль для автомат. переключения месяца при смене minDate и disabled
   */
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(!!disabled);
  }, [disabled]);

  const calMinDate = useMemo(() => {
    return isDisabled ? startOfToday() : minDate;
  }, [isDisabled, minDate]);
  const calMaxDate = useMemo(() => {
    return isDisabled ? addDays(startOfToday(), -1) : maxDate;
  }, [isDisabled, maxDate]);

  const hasValue = !!value?.some(Boolean);
  const showTitle = title || hasValue;

  const resetButton = (
    <Button
      classes={{
        root: s.Calendar__reset,
        iconRight: s.Calendar__resetIcon
      }}
      iconRight={<CrossIcon />}
      variant={ButtonVariant.tertiary}
      size={ButtonSize.small}
      onClick={onReset}
    >
      Сбросить
    </Button>
  );

  return (
    <div className={clsx(s.Calendar, className, classes?.root)}>
      {showTitle && (
        <Title
          variant={TitleVariant.h3}
          className={clsx(s.Calendar__title, classes?.title)}
        >
          {title && <div>{title}</div>}
          {!isMobile && hasValue && resetButton}
        </Title>
      )}
      <div
        className={clsx(s.Calendar__dateRangePicker, classes?.picker, {
          [s.Calendar__dateRangePicker_disabled]: disabled
        })}
      >
        <DateRangePicker
          value={value}
          minDate={calMinDate}
          maxDate={calMaxDate}
          disabled={isDisabled}
          inline
          {...props}
        />
        {isMobile && resetButton}
      </div>
    </div>
  );
};
