import gql from 'graphql-tag';
import { FRAGMENT_PERMISSION, FRAGMENT_ROLE } from '../mutations/auth';

export const FRAGMENT_USER_NOTIFICATIONS_SETTINGS = gql`
  fragment UserNotificationsSettings on UserSettingsType {
    article_notifications
    poll_notifications
    course_notifications
    event_notifications
    internship_notifications
    offer_notifications
    project_notifications
  }
`;

export const FRAGMENT_USER_BASE = gql`
  fragment UserBase on UserType {
    id
    avatar_file_id
    c_avatar_file_id
    name
    surname
    patronymic
    email
    positions {
      id
      c_position_name
      c_combine_position_work
      vacations_disabled
      unit {
        id
        name
      }
    }
    settings {
      ...UserNotificationsSettings
    }
  }

  ${FRAGMENT_USER_NOTIFICATIONS_SETTINGS}
`;

export const FRAGMENT_USER_SNIPPET = gql`
  fragment UserSnippet on UserType {
    ...UserBase
    skills {
      id
      name
    }
  }

  ${FRAGMENT_USER_BASE}
`;

export const FRAGMENT_USER_FULL = gql`
  fragment UserFull on UserType {
    ...UserBase
    work_phone
    mobile_phone
    birthday
    campus {
      id
      name
    }
    contacts {
      label
      phone
      internal_phone
    }

    vk
    instagram
    facebook

    count_subordinates
    about

    work_experiences {
      id
      user_id
      duties
      place
      position
      year_start
      year_end
    }
    publications {
      date
      link
      name
    }
    skills {
      id
      name
    }
    areas_of_interest {
      id
      name
    }
    positions {
      id
      c_position_name
      director_id
      unit_id
      vacations_disabled
      unit {
        id
        name
      }
      director {
        id
        name
        surname
        patronymic
        email
        avatar_file_id
        c_avatar_file_id
      }
    }
    degrees {
      c_name
      c_date
      c_type
    }
    educations {
      c_educational_institution
      c_specialization
      c_name_training
      c_date_end
      c_kind_of_education
    }

    permissions {
      ...PermissionProps
    }
    roles {
      ...RoleProps
    }
  }
  ${FRAGMENT_PERMISSION}
  ${FRAGMENT_ROLE}
  ${FRAGMENT_USER_BASE}
`;
