import { IUseGraphqlWatchQueryOptions } from '@proscom/prostore-apollo-react';
import { QUERY_GET_DOCUMENT_SECTIONS } from '../queries/documentSections';
import { DocumentSectionType } from '../types';
import { useAuthGraphqlQuery } from '../../common/hooks/utils/useAuthGraphqlQuery';

const documentSectionsQueryOptions: IUseGraphqlWatchQueryOptions<
  {},
  DocumentSectionType[]
> = {
  query: QUERY_GET_DOCUMENT_SECTIONS,
  mapData: (result) => result.documentSections
};

export const useDocumentSections = () => {
  return useAuthGraphqlQuery({
    queryOptions: documentSectionsQueryOptions,
    variables: {}
  });
};
